import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysCategory } from './queryKeys';

export type CategoryIcon = Record<string, unknown>;

/**
 * Fetches categoryIcon data from the API.
 * @param categoryIconId - The ID of the categoryIcon to fetch.
 * @returns The categoryIcon data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/get_article_category_icon_article_master_category__category_uuid__icon_get
 */
export const fetchCategoryIcon = async (
  categoryIconId: UUID,
): Promise<CategoryIcon> => {
  try {
    return await vestigasApi
      .get(ENDPOINT.CATEGORY.GET_ICON(categoryIconId), { responseType: 'blob' })
      .json<CategoryIcon>();
  } catch (error) {
    Log.error('Error fetching categoryIcon', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for a categoryIcon with a given categoryIconId.
 * @param categoryIconId - The ID of the categoryIcon to fetch.
 * @param options - Additional options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
export const useQueryCategoryIcon = (
  categoryIconId: UUID,
  options: Omit<UseQueryOptions<CategoryIcon>, 'queryKey' | 'queryFn'> = {},
) => {
  return useQuery<CategoryIcon>({
    enabled: Boolean(categoryIconId),
    queryFn: async () => fetchCategoryIcon(categoryIconId),
    queryKey: queryKeysCategory.get(categoryIconId),
    ...options,
  });
};
