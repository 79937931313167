import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysSite } from './queryKeys';

type CostCenterIds = UUID[];

type MutationCostCenterArguments = {
  siteId: UUID;
  costCenterIds: CostCenterIds;
};

/**
 * Updates the cost centers (aka accounting references) of a given site.
 *
 * @param {string} siteId - The ID of the site to update.
 * @param {string[]} costCenterIds - Array of cost center IDs to assign to the site.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/bulk_add_cost_center_to_site_site__site_uuid__accounting_reference_post
 */
export const updateSiteCostCenters = async (
  siteId: UUID,
  costCenterIds: CostCenterIds,
) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.SITE.UPDATE_COST_CENTERS(siteId), {
        json: { ids: costCenterIds },
      })
      .json<{ ids: CostCenterIds }>();

    return response;
  } catch (error) {
    Log.error('Error updating site cost centers', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating the cost centers (aka accounting references) of a given site.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationUpdateSiteCostCenters = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      siteId,
      costCenterIds,
    }: MutationCostCenterArguments) =>
      updateSiteCostCenters(siteId, costCenterIds),
    async onError(_error) {
      Log.productAnalyticsEvent(
        'Failed to update site cost centers',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(data, { siteId }) {
      queryClient.invalidateQueries({
        queryKey: queryKeysSite.get(siteId),
      });
    },
    ...options,
  });
};
