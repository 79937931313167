import { useQuery } from '@tanstack/react-query';

import ArticleMasterService from '~/services/articleMaster.service';

import { queryKeysCategory } from './queryKeys';

export const useQueryCategoriesLegacy = (options) =>
  useQuery({
    queryFn: ArticleMasterService.getAllCategories,
    queryKey: queryKeysCategory.getAllLegacy(),
    ...options,
  });
