import { useMutation } from '@tanstack/react-query';

import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';

import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';

import { addToOrganizationalUnit } from './useMutationAddToOrganizationalUnit';
import { deleteFromOrganizationalUnit } from './useMutationDeleteFromOrganizationalUnit';

/**
 * Type of organizational unit update operation.
 * - updateParentOrganizationalUnits: Adds new units before deleting old ones
 * - updateOrganizationalUnitEntities: Deletes old units before adding new ones
 */
type UpdateType =
  | 'updateParentOrganizationalUnits'
  | 'updateOrganizationalUnitEntities';

/**
 * Parameters for updating organizational units of an entity.
 * @property {UUID[]} addedMembers - Array of organizational unit IDs to add
 * @property {UUID[]} deletedMembers - Array of organizational unit IDs to remove
 * @property {UUID} entityId - ID of the entity being updated
 * @property {string} entityType - Type of entity (e.g., 'user')
 * @property {UpdateType} updateType - Determines the order of add/delete operations
 */
type UpdateOrganizationalUnitsParams = {
  addedMembers: UUID[];
  deletedMembers: UUID[];
  entityId: UUID;
  entityType: EntityType;
  updateType: UpdateType;
};

/**
 * Updates the organizational unit memberships for an entity by making API calls to add and remove members.
 *
 * @param {UpdateOrganizationalUnitsParams} params - Parameters for the update operation
 * @throws {Error} If the API calls fail
 * @returns {Promise<void>}
 *
 * @remarks
 * The order of operations (add then delete, or delete then add) depends on the updateType:
 * - For 'updateParentOrganizationalUnits': Adds new units first to prevent having an empty list
 * - For 'updateOrganizationalUnitEntities': Removes old units first before adding new ones
 */
const updateOrganizationalUnits = async ({
  addedMembers,
  deletedMembers,
  entityType,
  entityId,
  updateType = 'updateOrganizationalUnitEntities',
}: UpdateOrganizationalUnitsParams): Promise<void> => {
  try {
    let promisesAdd: Array<Promise<void>> = [];
    let promisesDelete: Array<Promise<void>> = [];

    if (updateType === 'updateParentOrganizationalUnits') {
      promisesAdd = addedMembers.map(async (parentOrganizationalUnitId) =>
        addToOrganizationalUnit(
          parentOrganizationalUnitId,
          entityType,
          entityId,
        ),
      );
      promisesDelete = deletedMembers.map(async (parentOrganizationalUnitId) =>
        deleteFromOrganizationalUnit(
          parentOrganizationalUnitId,
          entityType,
          entityId,
        ),
      );
    } else {
      promisesAdd = addedMembers.map(async (memberId) =>
        addToOrganizationalUnit(entityId, entityType, memberId),
      );
      promisesDelete = deletedMembers.map(async (memberId) =>
        deleteFromOrganizationalUnit(entityId, entityType, memberId),
      );
    }

    await Promise.all(promisesAdd);
    await Promise.all(promisesDelete);
  } catch (error) {
    Log.error('Error updating organizational units', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for managing organizational unit updates using react-query mutations.
 *
 * @param {Parameters<typeof useMutation>[0]} [options] - Optional react-query mutation options
 * @returns {UseMutationResult} Mutation object for handling the update operation
 *
 * @example
 * ```tsx
 * const mutation = useMutationUpdateOrganizationalUnits();
 *
 * const handleUpdate = () => {
 *   mutation.mutate({
 *     addedMembers: ['new1', 'new2'],
 *     deletedMembers: ['old1', 'old2'],
 *     entityId: '123',
 *     entityType: 'user',
 *     updateType: 'updateOrganizationalUnitEntities',
 *   });
 * };
 * ```
 */
export const useMutationUpdateOrganizationalUnits = (
  options?: Parameters<typeof useMutation>[0],
) =>
  useMutation({
    async mutationFn({
      addedMembers,
      deletedMembers,
      entityId,
      entityType,
      updateType = 'updateOrganizationalUnitEntities',
    }: UpdateOrganizationalUnitsParams) {
      return updateOrganizationalUnits({
        addedMembers,
        deletedMembers,
        entityId,
        entityType,
        updateType,
      });
    },
    onError(error: unknown) {
      Log.productAnalyticsEvent(
        'Failed to update organizational units',
        Log.FEATURE.ORGANIZATIONAL_UNIT,
        Log.TYPE.ERROR,
      );

      ToastService.httpError(['Failed to update organizational units'], error);
    },
    ...options,
  });
