import { MASTERDATA } from '~/constants/Masterdata';

export const selectUsers = (data) => ({
  rows:
    data.data?.map(({ companyName, email, id, isActive }) => ({
      companyName,
      email,
      id,
      isActive: isActive ? MASTERDATA.IS_ACTIVE.YES : MASTERDATA.IS_ACTIVE.NO,
    })) ?? [],
  totalCount: data.totalCount ?? 0,
  users: data.data ?? [],
});
