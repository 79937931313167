import { useQueryOrganizationalUnitsInfinite } from '~/data/organizationalUnit';

import { type UUID } from '~/types/common';

import { MultiItemsManager } from './MultiItemsManager';
import { type EntityFilter } from './types';
import { getEntityFilter } from './utils';

type P = {
  isCreatingNewEntity?: boolean;
  filterByEntity?: EntityFilter;
  label?: string;
  onChange?: (selectedIds: UUID[]) => void;
  onSaveSelection?: ({
    added,
    removed,
  }: {
    added: UUID[];
    removed: UUID[];
  }) => void;
  onSelectedItemClick?: (id: UUID) => void;
  parentOrganizationalUnitId?: UUID;
};

export const MultiItemsManagerOrganizationalUnits = ({
  isCreatingNewEntity = false,
  filterByEntity,
  label = 'Organisations-Gruppen',
  onChange,
  onSaveSelection,
  onSelectedItemClick,
  parentOrganizationalUnitId,
}: P) => {
  const filterEntityIn = getEntityFilter(
    filterByEntity,
    'filterContainsEntity',
  );
  const filterEntityNotIn = getEntityFilter(
    filterByEntity,
    'filterNotContainsEntity',
  );

  const getterFunctionSelectedItems = (searchString: string) => {
    const query = useQueryOrganizationalUnitsInfinite({
      ...filterEntityIn,
      filterParentOu: parentOrganizationalUnitId ?? undefined,
      searchString,
    });

    if (!isCreatingNewEntity) {
      return query;
    }

    return Promise.resolve({
      data: [],
      fetchNextPage() {},
      hasNextPage: false,
      isFetchingNextPage: false,
      isLoading: false,
      totalCount: 0,
    });
  };

  const getterFunctionSelectableItems = (searchString: string) =>
    useQueryOrganizationalUnitsInfinite({
      ...filterEntityNotIn,
      filterNotParentOu: parentOrganizationalUnitId ?? undefined,
      searchString,
    });

  return (
    <MultiItemsManager
      fieldName="name"
      getterFunctionSelectableItems={getterFunctionSelectableItems}
      getterFunctionSelectedItems={getterFunctionSelectedItems}
      label={label}
      onChange={onChange}
      onSaveSelection={onSaveSelection}
      onSelectedItemClick={onSelectedItemClick}
    />
  );
};
