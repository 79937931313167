import { Fragment } from 'react';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';

import { LOADING_STATE } from '~/constants/LoadingState';

import { ExcelIcon } from '~/assets/icons';

import { Spinner } from '~/components/Spinner';

import { KanbanColumn } from './KanbanColumn';
import { SortFilter, ResponsiblePersonFilter } from './SuppliersFilter';
import { SupplierDetailModal } from './SupplierDetailModal';

import { useStyles } from './styles';
import { useSuppliers } from './useSuppliers';

export const Suppliers = () => {
  const classes = useStyles();

  const {
    allCompanies,
    createNewDataExchange,
    dataExchanges,
    exportExcel,
    handleInputChange,
    handleSearchClear,
    isCreatingNewDataExchange,
    isFilteringSupplier,
    kanbanColumns,
    searchValue,
    selectedSupplier,
    setSelectedSupplier,
    showSupplierDetailModal,
    updateSupplier,
    isLoadingExportExcel,
  } = useSuppliers();

  const handleOpenForm = () => {
    window.open(
      'https://forms.office.com/Pages/ResponsePage.aspx?id=1UAW0kDQqkGt4sKE-3PphPcjK6gJ-rpNlfnVHLPHkNZUN0FQR0cwMUpCUThTMjhTNEY4R0VCWkE3Mi4u&embed=true',
      '_blank',
    );
  };

  if (
    dataExchanges.suppliersLoading === LOADING_STATE.LOADING ||
    dataExchanges.suppliersLoading === LOADING_STATE.NOT_LOADED
  ) {
    return <Spinner />;
  }

  return (
    <>
      {showSupplierDetailModal && (
        <SupplierDetailModal
          isCreatingNewDataExchange={
            isCreatingNewDataExchange === LOADING_STATE.LOADING
          }
          supplier={selectedSupplier}
          buyerCompany={
            allCompanies.find(
              (company) =>
                company.id === selectedSupplier.dataExchange.receiverId,
            ) ?? null
          }
          onSupplierChange={updateSupplier}
          onClose={() => {
            setSelectedSupplier(null);
          }}
        />
      )}
      <div className={classes.container}>
        <div className={classes.filtersContainer}>
          <div className="left-filters flex items-center justify-center gap-4">
            <SortFilter />
            <Divider orientation="vertical" flexItem />
            <div className="flex-c-c gap-2">
              <ResponsiblePersonFilter />
            </div>
          </div>
          <div className="right-filters flex items-center justify-center gap-4">
            <TextField
              value={searchValue}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        if (searchValue.length > 0) {
                          handleSearchClear();
                        }
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.textField}
              placeholder="Tippen zum Suchen..."
            />
            <IconButton
              onClick={exportExcel}
              className="excel-icon"
              size="large"
              disabled={isLoadingExportExcel}
            >
              {isLoadingExportExcel ? <Spinner /> : <ExcelIcon />}
            </IconButton>

            <Tooltip placement="top" title="Lieferant vorschlagen">
              <IconButton
                onClick={handleOpenForm}
                className="form-icon"
                size="medium"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Box marginTop={1} paddingLeft={2} paddingRight={2}>
          <Divider />
        </Box>

        <div
          className={`${classes.kanbanContainer} ${isFilteringSupplier === LOADING_STATE.LOADING ? 'is-loading-indicator' : ''}`}
        >
          {isFilteringSupplier === LOADING_STATE.LOADING && (
            <div className="loading-indicator">
              <Spinner />
            </div>
          )}

          {kanbanColumns.length > 0
            ? kanbanColumns.map((section, index) => {
                return (
                  <Fragment key={index}>
                    <KanbanColumn
                      onSupplierCardClick={(supplier) => {
                        if (!supplier.dataExchange) {
                          createNewDataExchange(supplier);
                          return;
                        }

                        setSelectedSupplier(supplier);
                      }}
                      section={section}
                    />
                    <Divider
                      className="kanban-divider"
                      orientation="vertical"
                    />
                  </Fragment>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
};
