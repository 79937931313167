import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { useQueryCompaniesLegacy } from '~/data/company';
import { useQueryIgnoredArticles } from '~/data/ignoredArticles';

import LocalStorageService from '~/services/localStorage.service';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns } from './constants';
import { selectIgnoredArticles } from './selectIgnoredArticles';

export const InvoiceCheckIgnoredArticlesTable = withErrorBoundary(
  memo(() => {
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
    });
    const [sortModel, setSortModel] = useState([
      {
        field: 'name',
        sort: 'asc',
      },
    ]);
    const [searchString, setSearchString] = useState(undefined);

    const dataExchanges = useSelector((state) => state.dataExchanges);

    const { data: companies } = useQueryCompaniesLegacy();

    const { data, isPending, isFetching, isPlaceholderData, refetch } =
      useQueryIgnoredArticles(
        {
          limit: paginationModel.pageSize,
          offset: paginationModel.page * paginationModel.pageSize,
          orderBy: sortModel[0]?.field,
          searchString,
          sort: sortModel[0]?.sort,
        },
        {
          select: (data) =>
            selectIgnoredArticles(data, dataExchanges, companies),
        },
      );

    const handlePaginationModelChange = useCallback((newModel) => {
      setPaginationModel(newModel);
    }, []);

    const handleSortModelChange = useCallback((newModel) => {
      setSortModel(newModel);
    }, []);

    const handleSearchStringChange = useCallback((newSearchString) => {
      // Show results from start when search string changes.
      setPaginationModel({
        ...paginationModel,
        page: 0,
      });
      setSearchString(newSearchString);
    }, []);

    const filterFunction = useCallback((string) => string, []); // We filter server side -> prevent the DataGrid from filtering.

    return (
      <SettingsTable
        entity="invoiceCheckIgnoredArticle"
        title="Ignorierte Artikel"
        columns={columns}
        filterFunction={filterFunction}
        items={data?.customerSubscriptions ?? []}
        isLoading={isPending || (isFetching && isPlaceholderData)}
        localStorageKey={
          LocalStorageService.INVOICE_CHECK_IGNORED_ARTICLE_TABLE
        }
        onPaginationModelChange={handlePaginationModelChange}
        onSearchStringChange={handleSearchStringChange}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        refreshData={refetch}
        sortModel={sortModel}
        rows={data?.rows ?? []}
        sortBy="name"
        totalRowCount={data?.totalCount ?? 0}
      />
    );
  }),
  'Benutzer-Gruppen konnten nicht geladen werden.',
);

InvoiceCheckIgnoredArticlesTable.displayName =
  'InvoiceCheckIgnoredArticlesTable';
