import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import Log from '~/utils/Log';

import { queryKeysPdfTemplates } from './queryKeys';

export type PdfTemplatesQueryParams = {
  companyId?: string;
};

export type PdfTemplatesListItem = {
  id: string;
  filename: string;
  contentType: string;
  companyAccountId: string;
  companyId: string | undefined;
  type: string;
  createdById: string;
  modifiedById: string;
  createdOn: string;
  modifiedOn: string;
};

type PdfTemplatesResponse = {
  templates: readonly PdfTemplatesListItem[];
};

const defaultQueryParams: PdfTemplatesQueryParams = {
  companyId: undefined,
};

/**
 * Generates the query options for the PdfTemplates query so they can be shared between the useQuery hook and the prefetching.
 */
export const getPdfTemplatesQueryOptions = ({
  queryParams,
  options = {},
}: {
  queryParams: Partial<PdfTemplatesQueryParams>;
  options?: Omit<
    UseQueryOptions<PdfTemplatesResponse, Error>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryFn: async () => fetchPdfTemplates(qp),
    queryKey: queryKeysPdfTemplates.getAll(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all PdfTemplates from the API.
 * @param queryParams - The query parameters for fetching PdfTemplates.
 * @returns The category data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/PDF-Template/operation/get_all_pdf_templates_pdf_template_get
 */
export const fetchPdfTemplates = async (
  queryParams: Partial<PdfTemplatesQueryParams>,
): Promise<PdfTemplatesResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  for (const key of Object.keys(qp)) {
    if (qp[key as keyof PdfTemplatesQueryParams] === undefined) {
      delete qp[key as keyof PdfTemplatesQueryParams];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.PDF_TEMPLATES.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<PdfTemplatesResponse>();

    return (
      response ?? {
        data: [],
      }
    );
  } catch (error) {
    Log.error('Error fetching PdfTemplates', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all PdfTemplates with given query parameters.
 * Handles pagination and prefetches the next page of PdfTemplates for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching PdfTemplates.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryPdfTemplates = (
  queryParams: Partial<PdfTemplatesQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getPdfTemplatesQueryOptions({
        options,
        queryParams,
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery<PdfTemplatesResponse, Error>({
    ...getPdfTemplatesQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all PdfTemplates with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching PdfTemplates.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQueryPdfTemplatesInfinite = (
  queryParams: Partial<PdfTemplatesQueryParams>,
  options: InfiniteQueryOptionsType<PdfTemplatesResponse, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async () => fetchPdfTemplates(qp),
    queryKey: queryKeysPdfTemplates.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<PdfTemplatesResponse>(qp, infiniteQueryOptions);
};
