import { type IgnoredArticlesQueryParams } from './useQueryIgnoredArticles';

/**
 * Query key factory for customFields-related queries.
 */
export const queryKeysIgnoredArticle = {
  base: () => 'ignoredArticles' as const,
  getAll: (queryParams: Partial<IgnoredArticlesQueryParams>) =>
    [queryKeysIgnoredArticle.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<IgnoredArticlesQueryParams>) =>
    [queryKeysIgnoredArticle.base(), 'list', 'infinite', queryParams] as const,
} as const;
