export const columns = [
  {
    cellClassName: 'name-cell',
    field: 'name',
    headerName: 'Organisations-Gruppe',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    cellClassName: 'id-cell',
    field: 'id',
    filterable: false,
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
];
