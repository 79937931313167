import clsx from 'clsx';
import { PersonAdd as PersonAddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import DeliveryNote from '~/models/deliveries/DeliveryNote';
import UserAction from '~/models/userActions/UserAction';

import { withErrorBoundary } from '~/ui/atoms';

import { LightTooltipWide } from '~/utils/componentUtils';
import Log from '~/utils/Log';

import { UserSignatureIcon } from '~/assets/icons';

import { DeliveryNoteActionMetaData } from '../deliveryNoteActions/DeliveryNoteActionMetaData';

const DeliveryListActions = ({
  params,
  onRequestSignatures,
  onShareDeliveryNote,
}) => {
  const handleRequestSignatures = (event, id) => {
    event.stopPropagation();

    Log.info('Open request signature form');
    Log.productAnalyticsEvent(
      'Open request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    onRequestSignatures(id);
  };

  const handleShareDeliveryNote = (event, id) => {
    event.stopPropagation();

    Log.info('Open share delivery note form');
    Log.productAnalyticsEvent(
      'Open share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    onShareDeliveryNote(id);
  };

  const getRequestSignatureForm = (processState, userActions) => {
    if (processState === DeliveryNote.PROCESS_STATE.DELIVERED.STRING) {
      return null;
    }

    const requestedUsers = UserAction.getRequestedUsers([userActions]);

    return (
      <LightTooltipWide
        title={
          requestedUsers.length > 0 ? (
            <DeliveryNoteActionMetaData
              title="Signatur bereits angefordert von:"
              users={requestedUsers}
            />
          ) : (
            'Signatur von einem Benutzer anfordern.'
          )
        }
      >
        <Button
          variant="outlined"
          onClick={(event) => handleRequestSignatures(event, params.id)}
          className="h-full"
          {...(requestedUsers.length > 0 ? {} : { color: 'primary' })}
        >
          <UserSignatureIcon
            className={clsx('icon-small', {
              'text-gray-400': requestedUsers.length > 0,
            })}
          />
        </Button>
      </LightTooltipWide>
    );
  };

  const getShareDeliveryNoteForm = (
    toSiteRecipient,
    permittedToSites,
    permittedCostCenters,
  ) => {
    if (toSiteRecipient || permittedToSites || permittedCostCenters) {
      return null;
    }

    return (
      <LightTooltipWide title="Lieferung mit einem Benutzer teilen.">
        <Button
          variant="outlined"
          color="primary"
          onClick={(event) => handleShareDeliveryNote(event, params.id)}
          className="h-full"
        >
          <PersonAddIcon />
        </Button>
      </LightTooltipWide>
    );
  };

  return (
    <div className="flex h-full items-center gap-1 py-0.5">
      {getRequestSignatureForm(
        params.value.split(';')[0],
        JSON.parse(
          params.value.split(';')[4]?.match(/[{}]/g)?.length >= 2
            ? params.value.split(';')[4]
            : '{"requestSignatures":[],"shareDeliveryNote":[]}', // Handle broken user data that caused errors
        ),
      )}
      {getShareDeliveryNoteForm(
        params.value.split(';')[1],
        params.value.split(';')[2],
        params.value.split(';')[3],
      )}
    </div>
  );
};

export default withErrorBoundary(DeliveryListActions, null);
