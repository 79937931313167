import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysUserGroup } from './queryKeys';

import { type UserGroupData } from './useMutationCreateUserGroup';

type MutationUserGroupArguments = {
  userGroupId: UUID;
  userGroupData: UserGroupData;
};

/**
 * Updates a given user group.
 *
 * @param {string} userGroupId - The ID of the user group to update.
 * @param {Object} userGroupData - An object containing the user group data to update.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/update_user_group_access_control_user_group__user_group_id__put
 */
export const updateUserGroup = async (
  userGroupId: UUID,
  userGroupData: UserGroupData,
) => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.USER_GROUP.UPDATE(userGroupId), {
        json: userGroupData,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating user group settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating the settings of a given user group.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */

export const useMutationUpdateUserGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      userGroupId,
      userGroupData,
    }: MutationUserGroupArguments) =>
      updateUserGroup(userGroupId, userGroupData),
    async onError(error) {
      Log.productAnalyticsEvent(
        'Failed to update user group settings',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(data, { userGroupId }) {
      queryClient.invalidateQueries(queryKeysUserGroup.get(userGroupId));
    },
  });
};
