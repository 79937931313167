import * as React from 'react';

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import BasicForm from './BasicForm';
import { promiseHandler } from '~/utils/promiseHandler';
import ImageUtils from '~/utils/imageUtils';
import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';
import { MissingPermissionsTooltip } from '~/utils/componentUtils';

export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      controlledInputValue: '',
      crop: null,
      formOpen: false,
      hovered: false,
      selectedImage: null, // if input value is uncontrolled, it isn't possible to select the same image two times in a row
      submittingForm: false,
    };

    // Crop images before uploading because too big ones make problems.
    this.MAX_IMAGE_HEIGHT_AND_WIDTH = 800;
  }

  handleInputChange = (event) => {
    Log.info('Upload image', null, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Upload image', Log.FEATURE.IMAGE_UPLOAD);

    const imageFile = event.target.files[0];

    // Get the image resolution to crop it to max 800px height and width.
    ImageUtils.getImageResolution(imageFile, (imageResolution) => {
      const heightFactor =
        imageResolution.height / this.MAX_IMAGE_HEIGHT_AND_WIDTH;
      const widthFactor =
        imageResolution.width / this.MAX_IMAGE_HEIGHT_AND_WIDTH;

      // Crop depending on whether image is higher or wider.
      let reductionPercentage =
        heightFactor > widthFactor ? 100 / heightFactor : 100 / widthFactor;
      if (reductionPercentage > 100) {
        reductionPercentage = 100;
      }

      ImageUtils.reduceImageResolution(
        imageFile,
        reductionPercentage,
        (reducedImage) => {
          // Handle the reduced image (e.g., upload it or display it)
          if (this.props.withCrop) {
            this.setState({
              formOpen: true,
              selectedImage: reducedImage,
            });
          } else {
            this.props.setImage(reducedImage);
          }
        },
      );
    });
  };
  handleMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };
  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };
  closeForm = () => {
    this.setState({
      controlledInputValue: '',
      crop: null,
      formOpen: false,
      selectedImage: null,
    });
  };
  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    Log.info(
      'Crop profile image',
      this.state.crop,
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.IMAGE_UPLOAD);

    const [croppedImage, error] = await promiseHandler(
      ImageUtils.getCroppedImg(
        this.state.selectedImage,
        this.state.crop,
        'image.jpg',
      ),
    );

    if (error) {
      Log.error('Failed to crop image.', error);
      ToastService.error(['Bild konnte nicht gespeichert werden.']);
      Log.productAnalyticsEvent(
        'Failed to submit form',
        Log.FEATURE.IMAGE_UPLOAD,
        Log.TYPE.ERROR,
      );
      this.setState({
        submittingForm: false,
      });
      this.closeForm();
    }

    this.setState({
      submittingForm: false,
    });

    this.props.setImage(croppedImage);

    this.closeForm();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.IMAGE_UPLOAD);
    this.closeForm();
  };
  handleCropChange = (crop) => {
    this.setState({
      crop,
    });
  };
  setDefaultCrop = (e) => {
    if (this.state.crop !== null) {
      return;
    }

    const { naturalHeight: height, naturalWidth: width } = e.currentTarget;

    this.setState({
      crop: centerCrop(
        makeAspectCrop({ unit: 'px', width: width * 0.9 }, 1, width, height),
        width,
        height,
      ),
    });
  };

  getAddButton() {
    if (this.props.missingPermissionsToWrite) {
      return (
        <MissingPermissionsTooltip>
          <IconButton component="span" disabled size="large">
            <AddIcon />
          </IconButton>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <label htmlFor="input-image-upload">
        <input
          type="file"
          accept="image/*,video/*,.jpg,.jpeg,.png"
          className="hidden"
          id="input-image-upload"
          value={this.state.controlledInputValue}
          onChange={this.handleInputChange}
        />
        <IconButton component="span" size="large">
          <AddIcon />
        </IconButton>
      </label>
    );
  }

  getDeleteButton() {
    if (this.props.missingPermissionsToWrite) {
      return (
        <MissingPermissionsTooltip>
          <IconButton component="span" disabled size="large">
            <DeleteIcon />
          </IconButton>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <IconButton component="span" onClick={this.props.onDelete} size="large">
        <DeleteIcon />
      </IconButton>
    );
  }

  render() {
    const displayImage = this.props.image?.size > 0;

    return (
      <>
        <div
          className={
            'flex-c-c upload-image-container relative ' +
            (this.props.profilePicture ? 'circle' : 'rounded-10px border')
          }
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {displayImage ? (
            <img
              className={
                'upload-image-size ' +
                (this.props.profilePicture ? 'circle' : '')
              }
              src={URL.createObjectURL(this.props.image)}
            />
          ) : (
            <div className="flex-c-c main-text h-100px p-5px text-center">
              {this.props.uploadText}
            </div>
          )}
          <div
            className={
              'transparent-overlay absolute ' +
              (this.props.profilePicture
                ? 'h-full w-full'
                : 'upload-image-overlay') +
              (!this.props.image || this.state.hovered ? 'flex-c-c' : 'hidden')
            }
          >
            {this.getAddButton()}
            {this.getDeleteButton()}
          </div>
        </div>
        <BasicForm
          title="Bild zuschneiden"
          open={this.state.formOpen}
          formAbort={this.formAbort}
          formSuccess={this.formSuccess}
          submittingForm={this.state.submittingForm}
        >
          {this.state.selectedImage?.size > 0 ? (
            <ReactCrop
              crop={this.state.crop}
              onChange={this.handleCropChange}
              aspect={1}
            >
              <img
                src={URL.createObjectURL(this.state.selectedImage)}
                onLoad={this.setDefaultCrop}
              />
            </ReactCrop>
          ) : null}
        </BasicForm>
      </>
    );
  }
}
