import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

import { UserSignatureIcon } from '~/assets/icons';

import UserAction from '~/models/userActions/UserAction';

import { withErrorBoundary } from '~/ui/atoms';
import { LightTooltipWide } from '~/utils/componentUtils';
import Log from '~/utils/Log';

import { DeliveryNoteActionMetaData } from '../../deliveries/deliveryNoteActions/DeliveryNoteActionMetaData';

export const InvoiceColumnUnsignedDeliveryNoteIds = withErrorBoundary(
  ({ params, onRequestSignatures }) => {
    const userinfo = useSelector((state) => state.userinfo);

    const handleRequestSignatures = (event, id, unsignedDeliveryNoteIds) => {
      event.stopPropagation();

      Log.info('Open request signature form');
      Log.productAnalyticsEvent(
        'Open request signature form',
        Log.FEATURE.NOTIFICATIONS,
      );

      onRequestSignatures(id, unsignedDeliveryNoteIds);
    };

    const unsignedDeliveryNoteIds = params.value.split(';');

    // Catch weird design of .split() function as it returns an array with an empty string as only item.
    // -> https://stackoverflow.com/questions/5164883/the-confusion-about-the-split-function-of-javascript-with-an-empty-string
    if (unsignedDeliveryNoteIds[0] === '') {
      return null;
    }

    // Collect user actions and signature requests for each delivery note
    const userActionsList = [];
    const signatureRequestForDeliveryNoteIds = [];

    for (const deliveryNoteId of unsignedDeliveryNoteIds) {
      if (!userinfo.userActions[deliveryNoteId]) {
        continue;
      }

      userActionsList.push(userinfo.userActions[deliveryNoteId]);

      // If there has been a signature requested for the delivery note, push it to the array.
      if (
        userinfo.userActions[deliveryNoteId].requestSignatures.length > 0 &&
        !signatureRequestForDeliveryNoteIds.includes(deliveryNoteId)
      ) {
        signatureRequestForDeliveryNoteIds.push(deliveryNoteId);
      }
    }

    // Determine if signatures have been requested for all delivery notes
    const requestedUsers = UserAction.getRequestedUsers(userActionsList);
    const signaturesForAllDeliveryNotesHaveBeenRequested =
      signatureRequestForDeliveryNoteIds.length ===
      unsignedDeliveryNoteIds.length;

    return (
      <div className="flex h-full items-center gap-1 py-0.5">
        <LightTooltipWide
          title={
            signaturesForAllDeliveryNotesHaveBeenRequested ? (
              <DeliveryNoteActionMetaData
                title="Signaturen bereits angefordert von:"
                users={requestedUsers}
              />
            ) : (
              'Signaturen für die nicht signierten Lieferungen von einem anderen Benutzer anfordern.'
            )
          }
        >
          <Button
            variant="outlined"
            color={
              signaturesForAllDeliveryNotesHaveBeenRequested
                ? undefined
                : 'primary'
            }
            onClick={(event) =>
              handleRequestSignatures(event, params.id, unsignedDeliveryNoteIds)
            }
            className="h-30px"
            startIcon={
              <UserSignatureIcon
                className={clsx(
                  'icon-small ml-2',
                  signaturesForAllDeliveryNotesHaveBeenRequested
                    ? 'text-grey400'
                    : '',
                )}
              />
            }
          />
        </LightTooltipWide>
      </div>
    );
  },
  null,
);
