import { useMutation } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';
import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

type User = Record<string, unknown>; // TODO

type UpdateUserResponse = {
  code: number;
  id: UUID;
  message: string;
};

/**
 * Updates a given user.
 *
 * @param {string} userId - The ID of the user to update.
 * @param {Object} featureFlags - An object containing the feature flags to update.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Admin/operation/update_user_settings_admin_user__id__settings_post
 */
export const updateUser = async (
  userId: UUID,
  userData: User,
): Promise<UpdateUserResponse> => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.USER.UPDATE(userId), { json: userData })
      .json<UpdateUserResponse>();

    return response;
  } catch (error) {
    Log.error('Error updating user', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating a given user.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationUpdateUser = (
  options?: Parameters<typeof useMutation>[0],
) => {
  return useMutation({
    mutationFn: async ({
      userId,
      userData,
    }: {
      userId: UUID;
      userData: User;
    }) => updateUser(userId, userData),

    onError(error) {
      Log.productAnalyticsEvent(
        'Failed to update user',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );

      const lines = ['xxxxxxxx'];
      ToastService.httpError(lines, error.response);
    },
    ...options,
  });
};
