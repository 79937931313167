export default class UserFeatureFlags {
  constructor(featureFlags) {
    this.accessPermittedSites =
      featureFlags?.access_permitted_sites ??
      featureFlags?.accessPermittedSites ??
      false;
    this.concreteDiary =
      featureFlags?.concrete_diary ?? featureFlags?.concreteDiary ?? false;
    this.concreteEditingEnabled =
      featureFlags?.concrete_editing_enabled ??
      featureFlags?.concreteEditingEnabled ??
      false;
    this.ocrEnabled =
      featureFlags?.ocr_enabled ?? featureFlags?.ocrEnabled ?? false;
    this.lineItemDetailsEditingDisabled =
      featureFlags?.line_item_details_editing_disabled ??
      featureFlags?.lineItemDetailsEditingDisabled ??
      false;
    this.recipientSetArrived =
      featureFlags?.recipient_set_arrived ??
      featureFlags?.recipientSetArrived ??
      false;
    this.showOrderAtPreview =
      featureFlags?.show_order_at_preview ??
      featureFlags?.showOrderAtPreview ??
      false;
    this.userCannotSetAccepted =
      featureFlags?.user_cannot_set_accepted ??
      featureFlags?.userCannotSetAccepted ??
      false;
    this.userCannotSetDeclined =
      featureFlags?.user_cannot_set_declined ??
      featureFlags?.userCannotSetDeclined ??
      false;
    this.userCannotSetDelivered =
      featureFlags?.user_cannot_set_delivered ??
      featureFlags?.userCannotSetDelivered ??
      false;
    this.userMetrics =
      featureFlags?.user_metrics ?? featureFlags?.userMetrics ?? false;
  }

  static getDifferentValues(userFeatureFlagsA, userFeatureFlagsB) {
    const featureFlagMap = {
      accessPermittedSites:
        'Nutzer kann berechtigte Lieferorte und Kostenstellen einsehen',
      concreteDiary: 'Nutzer hat Zugriff auf Betontagebuch',
      concreteEditingEnabled: 'Nutzer kann Betondaten bearbeiten',
      lineItemDetailsEditingDisabled:
        'Nutzer kann Artikeldetails nicht bearbeiten',
      ocrEnabled: 'Nutzer kann Lieferscheine mittels OCR einlesen',
      recipientSetArrived: 'Empfänger kann das "Angekommen" Event setzen',
      showOrderAtPreview:
        'Nutzer kann Bestellreferenz in Vorschau anzeigen (mobile App)',
      userCannotSetAccepted: 'Nutzer kann das "Angenommen" Event nicht setzen',
      userCannotSetDeclined: 'Nutzer kann das "Abgelehnt" Event nicht setzen',
      userCannotSetDelivered: 'Nutzer kann das "Geliefert" Event nicht setzen',
      userMetrics: 'Nutzer kann Benutzerstatistiken einsehen',
    };

    const compareFeatureFlags = (flagA, flagB, key) => {
      if (flagA?.[key] !== flagB?.[key]) {
        return featureFlagMap[key];
      }

      return null;
    };

    return Object.keys(featureFlagMap)
      .map((key) =>
        compareFeatureFlags(userFeatureFlagsA, userFeatureFlagsB, key),
      )
      .filter(Boolean);
  }
}
