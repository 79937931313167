import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysArticle } from './queryKeys';

type ArticleData = Record<string, unknown>;

type MutationArticleArgument = {
  articleId?: UUID;
  articleData: ArticleData;
};

/**
 * Creates a new article.
 *
 * @param {CreateCostCenterPayload} articleData - The data for creating the article
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/create_article_article_master_article_post
 */
export const createArticle = async (articleData: MutationArticleArgument) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.ARTICLE.CREATE(), {
        json: articleData,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating create settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for creating an article.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationCreateArticle = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (articleData: MutationArticleArgument) =>
      createArticle(articleData),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeysArticle.getAll({}),
      });

      Log.productAnalyticsEvent(
        'Create article success',
        Log.FEATURE.COST_CENTER,
      );
    },
    ...options,
  });
};
