import React from 'react';
import { connect } from 'react-redux';
import FeatureService from '~/services/feature.service';
import { LightTooltip } from '~/utils/componentUtils';
import { PackageBasicRestrictionMessage } from './packageBasicRestrictionMessage';

const mapStateToProps = (state) => ({
  companyAccount: state.companyAccount,
});

class PackageBasicRestrictionTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  setOpen = () => {
    this.setState({
      open: true,
    });
  };
  setClosed = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    if (!this.props.children) {
      return this.props.children;
    }

    if (!FeatureService.packageBasicRestriction()) {
      return this.props.children;
    }

    // <span> is needed because otherwise empty component would be passed into Tooltip which leads to error: https://github.com/mui/material-ui/issues/18119
    return (
      <LightTooltip
        title={<PackageBasicRestrictionMessage />}
        enterDelay={1500}
        enterNextDelay={1500}
        open={this.state.open}
        onOpen={this.setOpen}
        onClose={this.setClosed}
      >
        <span className={this.props.className} onClick={this.setOpen}>
          {this.props.children}
        </span>
      </LightTooltip>
    );
  }
}

export default connect(mapStateToProps)(PackageBasicRestrictionTooltip);
