import React from 'react';

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import Log from '~/utils/Log';

export default class PasswordTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidePassword: true,
    };
  }

  handleClick = () => {
    if (this.state.hidePassword) {
      Log.productAnalyticsEvent('Display password', Log.FEATURE.USER);
    } else {
      Log.productAnalyticsEvent('Hide password', Log.FEATURE.USER);
    }

    this.setState({
      hidePassword: !this.state.hidePassword,
    });
  };

  render() {
    return (
      <TextField
        {...this.props}
        id={this.props.id ?? 'password-input'}
        name={this.props.name ?? 'password'}
        label={this.props.label ?? 'Passwort'}
        type={this.state.hidePassword ? 'password' : 'text'}
        inputProps={{
          'data-testid': this.props.testId,
          minLength: 8,
        }}
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div className="mr-10px">
                <IconButton onClick={this.handleClick} edge="end" size="large">
                  {this.state.hidePassword ? (
                    <VisibilityIcon fontSize="small" />
                  ) : (
                    <VisibilityOffIcon fontSize="small" />
                  )}
                </IconButton>
              </div>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}
