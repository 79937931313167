import React from 'react';

import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Chip, Grid } from '@mui/material';

import DashboardChart from './DashboardChart';
import DashboardDetailOverview from './DashboardDetailOverview';
import { connect } from 'react-redux';
import DashboardPieChart from './DashboardPieChart';
import { withErrorBoundary } from '~/ui/atoms';
import {
  setDashboard_selectedArticleInDetailOverview,
  setDashboard_tab,
} from '~/redux/filtersSlice';
import { TAB } from '~/constants/Tab';
import Log from '~/utils/Log';

const mapStateToProps = (state) => ({
  deliveryNotes: state.deliveryNotes,
  selectedDateRange: state.filters.dashboard_selectedDateRange,
  selectedUnit: state.filters.dashboard_selectedUnit,
});
const mapDispatchToProps = () => ({
  setDashboard_selectedArticleInDetailOverview,
  setDashboard_tab,
});

class DashboardCharts extends React.Component {
  constructor(props) {
    super(props);
  }

  // Callback from DetailOverview so that Dashboard selectedArticleInDetailOverview is up-to-date
  handleSelectedArticleInDetailOverviewChange = (
    selectedArticleInDetailOverview,
  ) => {
    this.props.onSelectedArticleInDetailOverviewChange(
      selectedArticleInDetailOverview,
    );
  };
  openDashboardReports = () => {
    Log.productAnalyticsEvent('Open reports tab', Log.FEATURE.DASHBOARD);
    this.props.setDashboard_tab(TAB.DASHBOARD.REPORT.INDEX);
  };

  render() {
    if (!this.props.selectedUnit) {
      return (
        <div className="flexdir-column flex-c-c h-400px flex w-full">
          <div>
            Bitte wähle eine bestimmte Einheit aus, für die die Diagramme
            erstellt werden sollen.
          </div>
          <div className="mt-5px mb-10px">
            Alternativ kannst du die Statistik über alle Einheiten im Bereich{' '}
            <span className="bold">Berichte</span> einsehen.
          </div>
          <Chip
            label="Zu den Berichten"
            onClick={this.openDashboardReports}
            icon={<ChevronRightIcon />}
          />
        </div>
      );
    }

    return (
      <>
        <div>
          <div className="text-16px bold pb-10px">Übersicht</div>
          <Grid container spacing={4} justifyContent="space-between">
            <Grid item xs={12} lg={6}>
              <DashboardDetailOverview
                data={this.props.preArticleFilteredData}
                isArchiveMode={this.props.isArchiveMode}
                archiveAnalyticsData={
                  this.props.deliveryNotes.archiveAnalyticsData
                }
                archiveAnalyticsDataVersion={
                  this.props.deliveryNotes.archiveAnalyticsDataVersion
                }
                onSelectedRowChange={
                  this.handleSelectedArticleInDetailOverviewChange
                }
                selectedUnit={this.props.selectedUnit}
              />
            </Grid>
            <Grid item xs={12} lg={6} className="flex-c-c min-h-300px">
              <div className="flex-c-c p-20px h-full w-full">
                <DashboardPieChart
                  data={this.props.filteredData}
                  dataVersion={this.props.filteredDataVersion}
                  isArchiveMode={this.props.isArchiveMode}
                  archiveAnalyticsData={
                    this.props.deliveryNotes.archiveAnalyticsData
                  }
                  archiveAnalyticsDataVersion={
                    this.props.deliveryNotes.archiveAnalyticsDataVersion
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="mt-30px">
          <div className="text-16px bold pb-10px">Zeitlicher Verlauf</div>
          <DashboardChart
            data={this.props.filteredData}
            dataVersion={this.props.filteredDataVersion}
            isArchiveMode={this.props.isArchiveMode}
            archiveAnalyticsData={this.props.deliveryNotes.archiveAnalyticsData}
            archiveAnalyticsDataVersion={
              this.props.deliveryNotes.archiveAnalyticsDataVersion
            }
            selectedDateRange={this.props.selectedDateRange}
            selectedUnit={this.props.selectedUnit}
          />
        </div>
      </>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(DashboardCharts),
  'Diagramme konnten nicht geladen werden.',
);
