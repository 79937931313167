import { useQueries } from '@tanstack/react-query';

import PushNotificationSettings from '~/models/masterdata/PushNotificationSettings';
import SignatureRoles from '~/models/masterdata/SignatureRoles';
import User from '~/models/masterdata/User';
import UserFeatureFlags from '~/models/masterdata/UserFeatureFlags';

import UserUtils from '~/utils/userUtils';

import { type UUID } from '~/types/common';

import { fetchUser } from './useQueryUser';
import { fetchUserPushNotificationSettings } from './useQueryUserPushNotificationSettings';
import { fetchUserSettings } from './useQueryUserSettings';
import { fetchUserSignatureRoles } from './useQueryUserSignatureRoles';
import { queryKeysUser } from './queryKeys';

/**
 * Custom hook to fetch the full user data including company, user details, signature roles, feature flags, and push notifications.
 *
 * @param {string|null} userId - The ID of the user to fetch data for.
 * @param {string|null|undefined} userCompanyId - The ID of the user's company to fetch data for.
 * @param {Array<Record<string, unknown>>} placeholderData - Array of placeholder data objects for each query. Defaults to empty array.
 * @returns {Object} An object containing:
 *   - data: Combined user data including user, company, user feature flags,
 *     push notification settings, and signature roles.
 *   - isError: Boolean indicating if any query resulted in an error.
 *   - isLoading: Boolean indicating if any query is still loading.
 *   - isSuccess: Boolean indicating if all authorized queries were successful.
 *   - refetch: Function to refetch all queries.
 */
export const useQueryUserFull = (
  userId: UUID,
  userCompanyId?: UUID,
  placeholderData: Array<Record<string, unknown>> = [],
) => {
  if (!userId) {
    return {
      data: {},
      isError: false,
      isLoading: false,
      isSuccess: false,
      async refetch() {},
    };
  }

  const canManagePushNotifications =
    UserUtils.isVestigasAccount() || UserUtils.isUserWriteAllowedUser();

  const queries = useQueries({
    queries: [
      {
        placeholderData: placeholderData[0],
        queryFn: async () => fetchUser(userId),
        queryKey: queryKeysUser.get(userId),
      },
      {
        placeholderData: placeholderData[1],
        queryFn: async () => fetchUserSignatureRoles(userId),
        queryKey: queryKeysUser.getSignatureRoles(userId),
      },
      {
        placeholderData: placeholderData[2],
        queryFn: async () => fetchUserSettings(userId),
        queryKey: queryKeysUser.getSettings(userId),
      },
      {
        enabled: canManagePushNotifications,
        placeholderData: placeholderData[3],
        queryFn: async () => fetchUserPushNotificationSettings(userId),
        queryKey: queryKeysUser.getPushNotificationSettings(userId),
      },
    ],
  });

  const [
    userQuery,
    signatureRolesQuery,
    settingsQuery,
    pushNotificationsQuery,
  ] = queries;

  const isLoading = queries.some(({ isLoading }) => isLoading);
  const isError = queries.some(({ isError }) => isError);
  const isSuccess = queries
    .filter(
      (_, index) =>
        canManagePushNotifications ||
        index !== queries.indexOf(pushNotificationsQuery),
    )
    .every(({ isSuccess }) => isSuccess);

  const data = {
    ...new User(userQuery.data),
    companyId: userCompanyId ?? userQuery.data?.companyId,
    featureFlags: UserUtils.isVestigasAccount()
      ? new UserFeatureFlags(settingsQuery.data?.featureFlags)
      : new UserFeatureFlags(),
    pushNotificationSettings: new PushNotificationSettings(
      pushNotificationsQuery.data,
    ),
    signatureRoles: {
      ...new SignatureRoles(signatureRolesQuery.data),
      ...(settingsQuery.data?.defaultSignatureRole && {
        defaultSignatureRole: settingsQuery.data.defaultSignatureRole,
      }),
    },
  };

  const refetch = async () => {
    await Promise.all(queries.map((query) => query.refetch()));
  };

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};
