import React from 'react';
import { connect } from 'react-redux';
import {
  setInvoice_filterGroupOpen,
  setInvoice_filterGroups,
  setInvoice_filterModel,
  setInvoice_filterRows,
  setInvoice_selectedFilterGroup,
} from '~/redux/filtersSlice';
import DatagridUtils from '~/utils/datagridUtils';
import Log from '~/utils/Log';
import cloneDeep from 'lodash/cloneDeep';
import ArrayUtils from '~/utils/arrayUtils';
import FilterGroups from '../../filterBar/FilterGroups';
import Invoice from '~/models/invoices/Invoice';
import { promiseHandler } from '~/utils/promiseHandler';
import UserService from '~/services/user.service';
import ToastService from '~/services/toast.service';
import InvoicesService from '~/services/invoices.service';
import FilterGroupFilter from '~/models/filters/FilterGroupFilter';

const mapStateToProps = (state) => ({
  filterGroupOpen: state.filters.invoice_filterGroupOpen,
  filterGroups: state.filters.invoice_filterGroups,
  filterModel: state.filters.invoice_filterModel,
  filterRows: state.filters.invoice_filterRows,
  selectedBuyer: state.filters.invoice_selectedBuyer,
  selectedFilterGroup: state.filters.invoice_selectedFilterGroup,
  selectedNumber: state.filters.invoice_selectedNumber,
  selectedSeller: state.filters.invoice_selectedSeller,
  selectedStatus: state.filters.invoice_selectedStatus,
  selectedToSite: state.filters.invoice_selectedToSite,
});
const mapDispatchToProps = () => ({
  setInvoice_filterGroupOpen,
  setInvoice_filterGroups,
  setInvoice_filterModel,
  setInvoice_filterRows,
  setInvoice_selectedFilterGroup,
});

class InvoiceFilterGroups extends React.Component {
  componentDidMount() {
    this.initFilterModel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedFilterGroup !== prevProps.selectedFilterGroup) {
      this.initFilterModel();
    }
  }

  // Analogous to initSelectedTab from DeliveryFilterGroups
  initFilterModel() {
    const index = this.props.filterGroups.findIndex(
      (filterGroup) => filterGroup.id === this.props.selectedFilterGroup,
    );

    if (index === -1) {
      return;
    }

    this.props.setInvoice_filterModel(
      this.props.filterGroups[index]?.filterModel ??
        DatagridUtils.EMPTY_FILTER_MODEL,
    );
  }

  onClickFilterGroup = (id) => {
    if (id === this.props.selectedFilterGroup) {
      return;
    }

    this.props.setInvoice_selectedFilterGroup(id);

    Log.productAnalyticsEvent(
      'Open custom invoice filter group',
      Log.FEATURE.FILTER_GROUPS,
    );
  };
  onClickExpandFilterGroup = () => {
    this.props.setInvoice_filterGroupOpen(!this.props.filterGroupOpen);
  };

  getFilterGroupObject(id, name, filterRows = [], emptyFilterGroupObject) {
    const filterGroup = this.props.filterGroups.find(
      (filterGroup) => filterGroup.id === id,
    );

    let newFilterRows = [...filterRows];
    // If no filter rows have been provided and a corresponding filter is given, take the filter rows from the filter group.
    if (newFilterRows.length === 0 && filterGroup) {
      newFilterRows = [...filterGroup.filterRows];
    }

    // If no filter rows have been provided or the filter group should be empty anyway, return a list of filter row with one entry.
    // Take hardcoded FilterGroupFilter.FILTER.SELECTED_STATUS and not getSelectableFilters() to improve performance.
    if (newFilterRows.length === 0 || emptyFilterGroupObject) {
      newFilterRows = [FilterGroupFilter.FILTER.SELECTED_STATUS];
    } // Default value

    return {
      filterModel: emptyFilterGroupObject
        ? DatagridUtils.EMPTY_FILTER_MODEL
        : this.props.filterModel,

      filterRows: newFilterRows,
      // ? operator needed as this.props.filterGroups are not set when initializing InvoiceOverview.
      filters: {
        selectedBuyer: emptyFilterGroupObject ? [] : this.props.selectedBuyer,
        selectedNumber: emptyFilterGroupObject ? [] : this.props.selectedNumber,
        selectedSeller: emptyFilterGroupObject ? [] : this.props.selectedSeller,
        selectedStatus: emptyFilterGroupObject ? [] : this.props.selectedStatus,
        selectedToSite: emptyFilterGroupObject ? [] : this.props.selectedToSite,
      },
      id,
      name: name ?? filterGroup?.name,
    };
  }

  saveNewFilterGroup = async (id, name, filterRows, emptyFilterGroupObject) => {
    const newFilterGroups = [
      ...this.props.filterGroups,
      this.getFilterGroupObject(id, name, filterRows, emptyFilterGroupObject),
    ];

    this.props.setInvoice_filterGroups(newFilterGroups);
    this.props.setInvoice_selectedFilterGroup(id);
    this.props.setInvoice_filterGroupOpen(true);

    const [response, error] = await promiseHandler(
      UserService.updateInvoiceFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to save new filter group.', error);
      ToastService.error(['Filter konnte nicht hinzugefügt werden.']);
    }
  };
  updateFilterGroupName = async (id, name) => {
    const newFilterGroups = cloneDeep(this.props.filterGroups);

    for (const filterGroup of newFilterGroups) {
      if (filterGroup.id === id) {
        filterGroup.name = name;
      }
    }

    this.props.setInvoice_filterGroups(newFilterGroups);

    const [response, error] = await promiseHandler(
      UserService.updateInvoiceFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to update filter group name.', error);
      ToastService.error(['Filter konnte nicht umbenannt werden.']);
    }
  };
  updateFilterGroup = async (filterRows) => {
    let newFilterGroups = cloneDeep(this.props.filterGroups);

    newFilterGroups = ArrayUtils.updateByKey(
      this.props.filterGroups,
      'id',
      this.props.selectedFilterGroup,
      this.getFilterGroupObject(
        this.props.selectedFilterGroup,
        null,
        filterRows,
      ),
    );

    this.props.setInvoice_filterGroups(newFilterGroups);

    const [response, error] = await promiseHandler(
      UserService.updateInvoiceFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to update filter group.', error);
      ToastService.error(['Filter konnte nicht geändert werden.']);
    }
  };
  updateFilterRows = (filterRows) => {
    this.props.setInvoice_filterRows(filterRows);
  };
  deleteFilterGroup = async () => {
    let newFilterGroups = cloneDeep(this.props.filterGroups);

    newFilterGroups = ArrayUtils.removeByKey(
      this.props.filterGroups,
      'id',
      this.props.selectedFilterGroup,
    );

    this.props.setInvoice_filterGroups(newFilterGroups);
    this.props.setInvoice_selectedFilterGroup(this.props.filterGroups[0].id);

    const [response, error] = await promiseHandler(
      UserService.updateInvoiceFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to delete filter group.', error);
      ToastService.error(['Filter konnte nicht gelöscht werden.']);
    }
  };

  getInvoiceStatusOptions() {
    return [
      Invoice.FILTER_CATEGORY.CORRECT,
      Invoice.FILTER_CATEGORY.DELAYED_SIGNED,
      Invoice.FILTER_CATEGORY.NO_CHECKING_POSSIBLE,
      Invoice.FILTER_CATEGORY.FORMAL_CHECK_ERROR,
      Invoice.FILTER_CATEGORY.DLN_CHECK_ERROR,
      Invoice.FILTER_CATEGORY.SIGNATURE_CHECK_ERROR,
      Invoice.FILTER_CATEGORY.ARTICLE_EXISTS_CHECK_ERROR,
      Invoice.FILTER_CATEGORY.AMOUNT_CHECK_CHECK_ERROR,
      Invoice.FILTER_CATEGORY.AMOUNT_APPROVED_CHECK_ERROR,
    ];
  }

  getFilteredOptions(name) {
    if (this.props.data.length === 0) {
      return [];
    }

    const data = {
      rows: this.props.data,
      selectedBuyer:
        name === Invoice.PROPERTY.BUYER.STRING ? [] : this.props.selectedBuyer,
      selectedNumber:
        name === Invoice.PROPERTY.NUMBER.STRING
          ? []
          : this.props.selectedNumber,
      selectedSeller:
        name === Invoice.PROPERTY.SELLER.STRING
          ? []
          : this.props.selectedSeller,
      selectedStatus:
        name === Invoice.PROPERTY.STATUS.STRING
          ? []
          : this.props.selectedStatus,
      selectedToSite:
        name === Invoice.PROPERTY.TO_SITE.STRING
          ? []
          : this.props.selectedToSite,
    };

    const filteredData = InvoicesService.filterRows(data);

    switch (name) {
      case Invoice.PROPERTY.STATUS.STRING: {
        return this.getInvoiceStatusOptions();
      }

      case Invoice.PROPERTY.SELLER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          Invoice.PROPERTY.SELLER.KEY,
        );
      }

      case Invoice.PROPERTY.BUYER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          Invoice.PROPERTY.BUYER.KEY,
        );
      }

      case Invoice.PROPERTY.NUMBER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          Invoice.PROPERTY.NUMBER.KEY,
        );
      }

      case Invoice.PROPERTY.TO_SITE.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          Invoice.PROPERTY.TO_SITE.KEY,
        );
      }
    }
  }

  getAllOptions(name) {
    if (this.props.data.length === 0) {
      return [];
    }

    switch (name) {
      case Invoice.PROPERTY.STATUS.STRING: {
        return this.getInvoiceStatusOptions();
      }

      case Invoice.PROPERTY.SELLER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          Invoice.PROPERTY.SELLER.KEY,
        );
      }

      case Invoice.PROPERTY.BUYER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          Invoice.PROPERTY.BUYER.KEY,
        );
      }

      case Invoice.PROPERTY.NUMBER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          Invoice.PROPERTY.NUMBER.KEY,
        );
      }

      case Invoice.PROPERTY.TO_SITE.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          Invoice.PROPERTY.TO_SITE.KEY,
        );
      }
    }
  }

  getSelectableFilters() {
    const selectableFilters = [
      {
        id: FilterGroupFilter.FILTER.SELECTED_STATUS,
        name: Invoice.PROPERTY.STATUS.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_SELLER,
        name: Invoice.PROPERTY.SELLER.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_BUYER,
        name: Invoice.PROPERTY.BUYER.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_NUMBER,
        name: Invoice.PROPERTY.NUMBER.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_TO_SITE,
        name: Invoice.PROPERTY.TO_SITE.STRING,
      },
    ];

    return selectableFilters.map((item) => {
      return {
        ...item,
        allOptions: this.getAllOptions(item.name),
        filteredOptions: this.getFilteredOptions(item.name),
      };
    });
  }

  render() {
    return (
      <FilterGroups
        withExpandableFilterGroup
        filterGroups={this.props.filterGroups}
        selectedFilterGroup={this.props.selectedFilterGroup}
        filterGroupOpen={this.props.filterGroupOpen}
        filterRows={this.props.filterRows}
        onClickFilterGroup={this.onClickFilterGroup}
        onClickExpandFilterGroup={this.onClickExpandFilterGroup}
        saveNewFilterGroup={this.saveNewFilterGroup}
        updateFilterGroupName={this.updateFilterGroupName}
        deleteFilterGroup={this.deleteFilterGroup}
        updateFilterGroup={this.updateFilterGroup}
        updateFilterRows={this.updateFilterRows}
        currentFilterGroupObject={this.getFilterGroupObject(
          this.props.selectedFilterGroup,
        )}
        originalFilterGroupObject={this.props.filterGroups.find(
          (filterGroup) => filterGroup.id === this.props.selectedFilterGroup,
        )}
        resetSelectedFilterGroup={() =>
          this.props.setInvoice_selectedFilterGroup(null)
        }
        selectableFilters={this.getSelectableFilters()}
        onChangeValue={this.props.onChangeValue}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(InvoiceFilterGroups);
