import { type UUID } from '~/types/common';

import { type UserGroupsQueryParams } from './useQueryUserGroups';

/**
 * Query key factory for user group-related queries.
 */
export const queryKeysUserGroup = {
  base: () => 'userGroups' as const,
  get: (userGroupId: UUID) =>
    [queryKeysUserGroup.base(), 'detail', { userGroupId }] as const,
  getAll: (queryParams: Partial<UserGroupsQueryParams>) =>
    [queryKeysUserGroup.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<UserGroupsQueryParams>) =>
    [queryKeysUserGroup.base(), 'list', 'infinite', queryParams] as const,
  updateSettings: (userGroupId: UUID) =>
    [queryKeysUserGroup.base(), 'update', userGroupId] as const,
} as const;
