import React, { useState } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';

import { SettingsTestIds } from '~/constants/test-ids';

import { useQueryCompaniesLegacy } from '~/data/company';
import { useQueryPdfTemplates } from '~/data/pdfTemplate';

import PdfsettingsService from '~/services/pdfsettings.service';
import ToastService from '~/services/toast.service';

import PdfTemplate from '~/models/masterdata/PdfTemplate';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';
import { MultiSelectCompanies } from '~/ui/molecules/SelectServerDriven';

import PdfTemplateForm from './PdfTemplateForm';
import {
  TEST_TEMPLATE_FILE_TYPE,
  TestPdfTemplateForm,
} from './TestPdfTemplateForm';
import PdfTemplateTile from './PdfTemplateTile';
import { PdfTestTemplateTile } from './PdfTestTemplateTile';

const PdfSettings = () => {
  const [pdfTemplateFormOpen, setPdfTemplateFormOpen] = useState(false);
  const [newPdfTemplate, setNewPdfTemplate] = useState(new PdfTemplate());
  const [dlnToInvoiceCompanies, setDlnToInvoiceCompanies] = useState([]);
  const [protocolToInvoiceCompanies, setProtocolToInvoiceCompanies] = useState(
    [],
  );

  const [testPdfTemplateFormOpen, setTestPdfTemplateFormOpen] = useState(false);
  const [testPdfDataJSON, setTestPdfDataJSON] = useState(null);
  const [testPdfTemplate, setTestPdfTemplate] = useState(null);
  const [testPdfTemplateId, setTestPdfTemplateId] = useState(null);
  const [testPdfAssetIds, setTestPdfAssetIds] = useState('');

  const COMPANY_ACCOUNT_OPTION = {
    ID: 'company-account',
    NAME: 'Gesamter Firmenverbund',
  };

  const {
    data: companiesData,
    isLoading: isLoadingCompanies,
    isError: isErrorCompanies,
  } = useQueryCompaniesLegacy();

  const {
    data: pdfTemplatesData,
    isLoading: isLoadingPdfTemplates,
    isError: isErrorPdfTemplates,
  } = useQueryPdfTemplates();

  const openForm = (type) => {
    Log.info(
      'Open pdf template create form',
      null,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent('Open create form', Log.FEATURE.PDF_TEMPLATE);

    const template = new PdfTemplate();
    template.type = type;
    template.company.id = COMPANY_ACCOUNT_OPTION.ID;

    setPdfTemplateFormOpen(true);
    setNewPdfTemplate(template);
  };

  const closeForm = () => {
    Log.info('Close pdf template form', null, Log.BREADCRUMB.FORM_OPEN.KEY);

    setPdfTemplateFormOpen(false);
    setNewPdfTemplate(new PdfTemplate());
  };

  const handleDlnToInvoiceCompaniesChange = async (companies) => {
    const companyIds = new Set(companies.map(({ id }) => id));

    Log.productAnalyticsEvent(
      'Update invoice pdf dln setting',
      Log.FEATURE.PDF_SETTINGS,
    );

    for (let index = 0; index < companiesData.companies.length; index++) {
      const [response, error] = await promiseHandler(
        PdfsettingsService.putCompanyDlnToInvoice(
          companiesData.companies[index].id,
          companyIds.has(companiesData.companies[index].id),
        ),
      );

      if (error) {
        ToastService.error([
          'PDF Einstellungen für Rechnungen konnten nicht vollständig aktualisiert werden.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        Log.error(
          'Failed to update invoice pdf dln setting for company. company id: ' +
            companiesData.companies[index].id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to update invoice pdf dln setting',
          Log.FEATURE.PDF_SETTINGS,
          Log.TYPE.ERROR,
        );
      }
    }

    setDlnToInvoiceCompanies(companies);
  };

  const handleProtocolToInvoiceCompaniesChange = async (companies) => {
    const companyIds = new Set(companies.map(({ id }) => id));

    Log.productAnalyticsEvent(
      'Update invoice pdf protocol setting',
      Log.FEATURE.PDF_SETTINGS,
    );

    for (let index = 0; index < companiesData.companies.length; index++) {
      const [response, error] = await promiseHandler(
        PdfsettingsService.putInvoiceProtocolToInvoice(
          companiesData.companies[index].id,
          companyIds.has(companiesData.companies[index].id),
        ),
      );

      if (error) {
        ToastService.error([
          'PDF Einstellungen für Rechnungen konnten nicht vollständig aktualisiert werden.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        Log.error(
          'Failed to update invoice pdf protocol setting for company. company id: ' +
            companiesData.companies[index].id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to update invoice pdf protocol setting',
          Log.FEATURE.PDF_SETTINGS,
          Log.TYPE.ERROR,
        );
      }
    }

    setProtocolToInvoiceCompanies(companies);
  };

  const openTestPdfForm = () => {
    Log.info(
      'Open test pdf template create form',
      null,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent(
      'Open test pdf create form',
      Log.FEATURE.PDF_TEMPLATE,
    );

    setTestPdfTemplateFormOpen(true);
  };

  const closeTestPdfForm = () => {
    Log.info(
      'Close test pdf template create form',
      null,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );

    setTestPdfTemplateFormOpen(false);
    setTestPdfTemplate(null);
    setTestPdfTemplateId(null);
  };

  const handleTestPdfFileChange = ({ type, value }) => {
    if (type === TEST_TEMPLATE_FILE_TYPE.DOCX) {
      setTestPdfTemplate(value);
    } else if (type === TEST_TEMPLATE_FILE_TYPE.JSON) {
      setTestPdfDataJSON(value);
    } else {
      setTestPdfAssetIds(value);
    }
  };

  const handleTestPdfFormSubmit = async () => {
    setTestPdfTemplateId(null);

    const body = {
      asset_file: testPdfDataJSON,
      asset_ids: testPdfAssetIds?.length > 0 ? testPdfAssetIds?.split(',') : [],
      template_file: testPdfTemplate,
    };

    Log.info(
      'Submit test pdf tempalte form',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Submit test template form',
      Log.FEATURE.PDF_TEMPLATE,
    );

    const [response, error] = await promiseHandler(
      PdfsettingsService.createTestPdfTemplate(body),
    );

    if (error) {
      ToastService.httpError(
        [ToastService.MESSAGE.PDF_TEMPLATE_CREATION_FAILED],
        error.response,
      );
      Log.error('Failed to create test pdf template', error);
      Log.productAnalyticsEvent(
        'Failed to create test template',
        Log.FEATURE.PDF_TEMPLATE,
        Log.TYPE.ERROR,
      );
      return;
    }

    if (response?.data?.id) {
      setTestPdfTemplateId(response?.data?.id);
    }

    setTestPdfTemplateFormOpen(false);
  };

  const showTestPdfTile = !testPdfTemplateFormOpen && testPdfTemplateId;

  return (
    <div className="ml-2rem mr-2rem">
      <div data-testid={SettingsTestIds.HEADER.TITLE} className="main-header">
        PDF Einstellungen
      </div>
      <div className="text-24px bold mt-20px mb-20px">PDF Rechnungen</div>
      <div className="rounded-5px box-shadow-blue p-20px mb-20px flex-s-c gap-20px w-full bg-white">
        <div className="w-full">
          <div className="bold">Lieferungen in der Rechnung anhängen</div>
          {isErrorCompanies ? (
            <div className="mt-20px">
              Einstellungen konnten nicht geladen werden.
            </div>
          ) : isLoadingCompanies || isLoadingPdfTemplates ? (
            <div className="mt-20px inline-block">
              <Spinner />
            </div>
          ) : (
            <MultiSelectCompanies
              placeholder="für die Firmen..."
              value={dlnToInvoiceCompanies}
              onChange={handleDlnToInvoiceCompaniesChange}
            />
          )}
        </div>
        <div className="w-full">
          <div className="bold">Prüfprotokoll in der Rechnung anhängen</div>
          {isErrorPdfTemplates ? (
            <div className="mt-20px">
              Einstellungen konnten nicht geladen werden.
            </div>
          ) : isLoadingCompanies || isLoadingPdfTemplates ? (
            <div className="mt-20px inline-block">
              <Spinner />
            </div>
          ) : (
            <MultiSelectCompanies
              placeholder="für die Firmen..."
              value={protocolToInvoiceCompanies}
              onChange={handleProtocolToInvoiceCompaniesChange}
            />
          )}
        </div>
      </div>
      <div className="h-1px bg-grey400 w-full" />
      <div className="text-24px bold mt-20px mb-20px">
        PDF Vorlagen für Lieferungen
      </div>
      <Grid
        container
        columnSpacing="40px"
        justifyContent="space-between"
        className="mb-20px"
      >
        <Grid item xs={6}>
          <div className="text-20px bold mb-10px">
            {PdfTemplate.TYPE.DEFAULT.STRING}
          </div>
          <div className="flex-s-s flexdir-column gap-20px">
            {isLoadingPdfTemplates ? <Spinner /> : null}
            {isErrorPdfTemplates
              ? 'PDF Vorlagen konnten nicht geladen werden.'
              : null}
            {pdfTemplatesData
              ? pdfTemplatesData.templates
                  .filter(
                    (pdfTemplate) =>
                      pdfTemplate.type === PdfTemplate.TYPE.DEFAULT.KEY,
                  )
                  .map((pdfTemplate) => (
                    <PdfTemplateTile
                      key={pdfTemplate.id}
                      pdfTemplate={pdfTemplate}
                      companyAccountOption={COMPANY_ACCOUNT_OPTION}
                    />
                  ))
              : null}
            <Button
              className="primary-button"
              startIcon={<AddIcon />}
              onClick={() => openForm(PdfTemplate.TYPE.DEFAULT.KEY)}
            >
              Neue Vorlage
            </Button>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="text-20px bold mb-10px">
            {PdfTemplate.TYPE.CONCRETE.STRING}
          </div>
          <div className="flex-s-s flexdir-column gap-20px">
            {isLoadingPdfTemplates ? <Spinner /> : null}
            {isErrorPdfTemplates
              ? 'PDF Vorlagen konnten nicht geladen werden.'
              : null}
            {pdfTemplatesData
              ? pdfTemplatesData.templates
                  .filter(
                    (pdfTemplate) =>
                      pdfTemplate.type === PdfTemplate.TYPE.CONCRETE.KEY,
                  )
                  .map((pdfTemplate) => (
                    <PdfTemplateTile
                      key={pdfTemplate.id}
                      pdfTemplate={pdfTemplate}
                      companyAccountOption={COMPANY_ACCOUNT_OPTION}
                    />
                  ))
              : null}
            <Button
              className="primary-button"
              startIcon={<AddIcon />}
              onClick={() => openForm(PdfTemplate.TYPE.CONCRETE.KEY)}
            >
              Neue Vorlage
            </Button>
          </div>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>

      <div className="h-1px bg-grey400 w-full" />
      <div className="text-24px bold mt-20px mb-20px">PDF Vorlagen testen</div>
      <Grid
        container
        columnSpacing="40px"
        justifyContent="space-between"
        className="mb-20px"
      >
        <Grid item xs={6}>
          {showTestPdfTile ? (
            <PdfTestTemplateTile
              onClick={openTestPdfForm}
              id={testPdfTemplateId}
              files={{
                json: testPdfDataJSON,
                template: testPdfTemplate,
              }}
              onDelete={() => {
                setTestPdfDataJSON(null);
                setTestPdfTemplate(null);
              }}
            />
          ) : (
            <Button
              className="primary-button"
              startIcon={<AddIcon />}
              onClick={openTestPdfForm}
            >
              Vorlage testen
            </Button>
          )}
        </Grid>
      </Grid>

      <PdfTemplateForm
        open={pdfTemplateFormOpen}
        closeForm={closeForm}
        pdfTemplate={newPdfTemplate}
        companyAccountOption={COMPANY_ACCOUNT_OPTION}
        testPdfAssetIds={testPdfAssetIds}
      />

      <TestPdfTemplateForm
        loadingState={isLoadingPdfTemplates}
        open={testPdfTemplateFormOpen}
        handleOnFileChange={handleTestPdfFileChange}
        closeForm={closeTestPdfForm}
        handleFormSubmit={handleTestPdfFormSubmit}
        files={{
          json: testPdfDataJSON,
          template: testPdfTemplate,
        }}
      />
    </div>
  );
};

export default withErrorBoundary(
  PdfSettings,
  'PDF Einstellungen konnten nicht geladen werden.',
);
