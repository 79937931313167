import { useState, useCallback, useEffect, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useQueryClient } from '@tanstack/react-query';

import { useMutationUpdateCostCenter } from '~/data/costCenter';
import { useMutationUpdateOrganizationalUnits } from '~/data/organizationalUnit';
import {
  queryKeysSite,
  useMutationCreateSite,
  useMutationUpdateSite,
  useMutationUpdateSiteCostCenters,
  useQuerySite,
} from '~/data/site';
import { useQueryUserData } from '~/data/user';

import ToastService from '~/services/toast.service';

import Address from '~/models/masterdata/Address';
import Site from '~/models/masterdata/Site';

import { useDelayedValue } from '~/hooks/useDelayedValue';

import Log from '~/utils/Log';

import { handleSubmit as onSubmit } from './utils';

export const useSiteForm = ({ closeForm, siteId, type }) => {
  const isCreatingSite = type === 'create';

  const queryClient = useQueryClient();

  const {
    data: currentUser,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
    isSuccess: isSuccessUserData,
  } = useQueryUserData(true);

  const userPermissions = currentUser?.userPermissions ?? [];
  const companyInfo = currentUser?.companyInfo ?? {};

  const {
    data: site,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchSite,
  } = useQuerySite(siteId, {
    enabled: !isCreatingSite && Boolean(siteId),
    placeholderData() {
      // Try to get some basic data from the list item cache to improve the rendering experience.
      const queriesData = queryClient.getQueriesData({
        queryKey: queryKeysSite.getAll().filter(Boolean),
      });

      for (const [, data] of queriesData) {
        if (Array.isArray(data?.data)) {
          const listItem = data?.data?.find(({ id }) => id === siteId);

          if (listItem) {
            const { address, companyId, id, isActive, name } = listItem;

            return new Site({ address, companyId, id, isActive, name });
          }
        }
      }

      return undefined;
    },
    select(data) {
      const site = new Site(data);

      site.companyId ||= companyInfo?.id; // Set the user's companyId as default, if companyId is not set.

      return site;
    },
  });

  const { mutateAsync: createSiteMutation, isPending: isPendingCreateSite } =
    useMutationCreateSite({
      onError(error) {
        Log.error('createSiteMutation error');
      },
    });

  const { mutateAsync: updateSiteMutation, isPending: isPendingUpdateSite } =
    useMutationUpdateSite({
      onError(error) {
        Log.error('updateSiteSettingsMutation error');
      },
    });

  const {
    mutateAsync: updateSiteCostCentersMutation,
    isPending: isPendingUpdateSiteCostCenters,
  } = useMutationUpdateSiteCostCenters({
    onError(error) {
      Log.error('updateSiteCostCentersMutation error');
    },
  });

  const {
    mutateAsync: updateCostCenterMutation,
    isPending: isPendingUpdateCostCenter,
  } = useMutationUpdateCostCenter({
    onError(error) {
      Log.error('updateCostCenterMutation error');
    },
  });

  const {
    mutateAsync: updateOrganizationalUnitsMutation,
    isPending: isPendingUpdateOrganizationalUnits,
  } = useMutationUpdateOrganizationalUnits({
    onError(error) {
      Log.error('updateOrganizationalUnitsMutation error');

      ToastService.httpError(
        ['Organisations-Gruppen konnten nicht geändert werden.'],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to update organisational groups',
        Log.FEATURE.COST_CENTER,
        Log.TYPE.ERROR,
      );
    },
  });

  const isSubmitting =
    isPendingCreateSite ||
    isPendingUpdateCostCenter ||
    isPendingUpdateOrganizationalUnits ||
    isPendingUpdateSite ||
    isPendingUpdateSiteCostCenters;

  const refetchSites = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: queryKeysSite.getAll({}),
    });
  }, [queryClient]);

  const getDefaultSite = () => {
    const site = new Site();
    site.companyId = companyInfo?.id;
    site.type = Site.getSiteTypes()[0].id;
    site.address.country = Address.DEFAULT_COUNTRY_CODE.DE;

    return site;
  };

  const [state, setState] = useState({
    site: isCreatingSite ? getDefaultSite() : new Site(site),
  });

  const [isOpenCostCenterForm, setIsOpenCostCenterForm] = useState(false);
  const [deactivateCoupledCostCenters, setDeactivateCoupledCostCenters] =
    useState(false);
  const [
    deactivateCoupledCostCentersFormOpen,
    setDeactivateCoupledCostCentersFormOpen,
  ] = useState(false);

  const [syncCoupledPermissionGrants, setSyncCoupledPermissionGrants] =
    useState(false);
  const [
    syncCoupledPermissionGrantsFormOpen,
    setSyncCoupledPermissionGrantsFormOpen,
  ] = useState(false);

  const resetForm = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      site: site ?? getDefaultSite(),
    }));
  }, [site, getDefaultSite]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit({
      closeForm,
      createSiteMutation,
      deactivateCoupledCostCenters,
      isCreatingSite,
      originalData: site,
      queryClient,
      refetchSites,
      resetForm,
      site: state.site,
      syncCoupledPermissionGrants,
      updateCostCenterMutation,
      updateOrganizationalUnitsMutation,
      updateSiteCostCentersMutation,
      updateSiteMutation,
    });
  };

  const handleCancel = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.SITE);
    closeForm();
    resetForm();
  };

  const handleChangeCompany = (companyId) => {
    const newSite = cloneDeep(state.site);
    newSite.companyId = companyId;

    Log.info(
      'Change form value of company',
      {
        from: state.site.companyId,
        to: newSite.companyId,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change companyId', Log.FEATURE.SITE);

    setState((previousState) => ({
      ...previousState,
      site: newSite,
    }));
  };

  const handleChangeSiteType = (event) => {
    const newSite = cloneDeep(state.site);
    newSite.type = event.target.value;

    Log.info(
      'Change form value of type',
      {
        from: state.site.type,
        to: newSite.type,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change type', Log.FEATURE.SITE);

    setState((previousState) => ({
      ...previousState,
      site: newSite,
    }));
  };

  const handleChangeDate = (date, eventName) => {
    const newSite = cloneDeep(state.site);
    newSite[eventName] = new Date(
      new Date(date).setUTCHours(0, 0, 0, 0),
    ).toISOString();

    Log.info(
      `Change form value of ${eventName} date`,
      {
        from: state.site[eventName],
        to: newSite[eventName],
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(`Change ${eventName} date`, Log.FEATURE.SITE);

    setState((previousState) => ({
      ...previousState,
      site: newSite,
    }));
  };

  const handleChangeCostCenters = (costCenters) => {
    const newSite = cloneDeep(state.site);
    newSite.costCenters = costCenters.map((costCenter) => costCenter.id);

    Log.info(
      'Change form value of cost centers',
      {
        from: state.site.costCenters,
        to: newSite.costCenters,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change cost centers', Log.FEATURE.SITE);

    setState((previousState) => ({
      ...previousState,
      site: newSite,
    }));
  };

  const handleInputChange = (event) => {
    const newSite = cloneDeep(state.site);

    switch (event.target.name) {
      case 'name': {
        newSite.name = event.target.value;
        Log.info(
          'Change form value of name',
          {
            from: state.site.name,
            to: newSite.name,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'street_name': {
        newSite.address.streetName = event.target.value;
        Log.info(
          'Change form value of street name',
          {
            from: state.site.address?.streetName,
            to: newSite.address.streetName,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'building_number': {
        newSite.address.buildingNumber = event.target.value;
        Log.info(
          'Change form value of building number',
          {
            from: state.site.address?.buildingNumber,
            to: newSite.address.buildingNumber,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'city': {
        newSite.address.city = event.target.value;
        Log.info(
          'Change form value of city',
          {
            from: state.site.address?.city,
            to: newSite.address.city,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'post_code': {
        newSite.address.postCode = event.target.value;
        Log.info(
          'Change form value of post code',
          {
            from: state.site.address?.postCode,
            to: newSite.address.postCode,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'latitude': {
        newSite.coords.latitude = event.target.value;
        Log.info(
          'Change form value of latitude',
          {
            from: state.site.coords?.latitude,
            to: newSite.coords.latitude,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      case 'longitude': {
        newSite.coords.longitude = event.target.value;
        Log.info(
          'Change form value of longitude',
          {
            from: state.site.coords?.longitude,
            to: newSite.coords.longitude,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        break;
      }

      default: {
        break;
      }
    }

    setState((previousState) => ({
      ...previousState,
      site: newSite,
    }));
  };

  const handleCheckboxChange = (event) => {
    const newSite = cloneDeep(state.site);
    newSite.isActive = event.target.checked;

    Log.info(
      'Change form value of isActive',
      {
        from: state.site.isActive,
        to: newSite.isActive,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change isActive checkbox', Log.FEATURE.SITE);

    setState((previousState) => ({
      ...previousState,
      site: newSite,
    }));
  };

  const handleChangeOrganisationalGroups = ({ added, removed }, setState) => {
    setState((previousState) => {
      const newSite = cloneDeep(previousState.site);

      const removedIds = new Set(removed.map(({ id }) => id));
      const addedIds = new Set(added.map(({ id }) => id));

      newSite.organisationalGroups = [
        ...new Set([
          ...newSite.organisationalGroups.filter((id) => !removedIds.has(id)),
          ...addedIds,
        ]),
      ];

      Log.info(
        'Change form value of organisational groups',
        {
          from: previousState.site.organisationalGroups,
          to: newSite.organisationalGroups,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change organisational groups',
        Log.FEATURE.SITE,
      );

      return {
        ...previousState,
        site: newSite,
      };
    });
  };

  const handleOpenCostCenterForm = () => {
    Log.info(
      'Open cost center create form',
      null,
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    Log.productAnalyticsEvent('Open form', Log.FEATURE.COST_CENTER);

    setIsOpenCostCenterForm(true);
  };

  const handleCloseCostCenterForm = () => {
    Log.info('Close cost center form', null, Log.BREADCRUMB.FORM_CLOSE.KEY);

    setIsOpenCostCenterForm(false);
  };

  const setCostCenter = (id) => {
    const newSite = cloneDeep(state.site);
    newSite.costCenters.push(id);

    setState((previousState) => ({
      ...previousState,
      site: newSite,
    }));
  };

  const unsavedChanges = useMemo(() => {
    if (isCreatingSite) {
      return [];
    }

    if (!isSuccess || !site || !state.site) {
      return [];
    }

    return Site.getDifferentValues(site, state.site);
  }, [
    JSON.stringify(site),
    JSON.stringify(state.site),
    isCreatingSite,
    isSuccess,
  ]);

  const delayedUnsavedChanges = useDelayedValue(unsavedChanges);

  useEffect(() => {
    resetForm();
  }, [isSuccess, isError, JSON.stringify(site)]);

  return {
    deactivateCoupledCostCentersFormOpen,
    handleCancel,
    handleChangeCompany,
    handleChangeCostCenters,
    handleChangeDate,
    handleChangeOrganisationalGroups: ({ added, removed }) =>
      handleChangeOrganisationalGroups({ added, removed }, setState),
    handleChangeSiteType,
    handleCheckboxChange,
    handleCloseCostCenterForm,
    handleInputChange,
    handleOpenCostCenterForm,
    handleSubmit,
    isCreatingSite,
    isLoading,
    isOpenCostCenterForm,
    isSubmitting,
    refetchSite,
    setCostCenter,
    setDeactivateCoupledCostCenters,
    setDeactivateCoupledCostCentersFormOpen,
    setSyncCoupledPermissionGrants,
    setSyncCoupledPermissionGrantsFormOpen,
    site: state.site,
    syncCoupledPermissionGrantsFormOpen,
    unsavedChanges: delayedUnsavedChanges,
    userPermissions,
  };
};
