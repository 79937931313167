import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysSite } from './queryKeys';

type SiteData = Record<string, unknown>;

type MutationSiteArguments = {
  siteId: UUID;
  siteData: SiteData;
};

/**
 * Updates a given site.
 *
 * @param {string} siteId - The ID of the site to update.
 * @param {Object} siteData - An object containing the site data to update.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/update_site_site__site_uuid__put
 */
export const updateSite = async (siteId: UUID, siteData: SiteData) => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.SITE.UPDATE(siteId), {
        json: siteData,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating site settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating a given site.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationUpdateSite = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ siteId, siteData }: MutationSiteArguments) =>
      updateSite(siteId, siteData),
    async onError(_error) {
      Log.productAnalyticsEvent(
        'Failed to update site settings',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(data, { siteId }) {
      queryClient.invalidateQueries(queryKeysSite.get(siteId));
    },
    ...options,
  });
};
