import { useMutation } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';
import ToastService from '~/services/toast.service';

import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';

import Log from '~/utils/Log';

type AddToUserGroupParams = {
  memberId: UUID;
  memberType: Pick<EntityType, 'user' | 'user_group'>;
  userGroupId: UUID;
};

/**
 * Adds a member (user or user group) to a user group.
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/add_member_to_user_group_access_control_user_group__user_group_id___member_type__post
 * @param userGroupId - UUID of the user group to add the member to
 * @param memberType - Type of member being added ('user' or 'user_group')
 * @param memberId - UUID of the member being added
 * @returns Promise containing the response with the created relationship ID
 * @throws Will throw an error if the API call fails
 */
export const addToUserGroup = async (
  userGroupId: UUID,
  memberType: Pick<EntityType, 'user' | 'user_group'>,
  memberId: UUID,
) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.USER_GROUP.ADD_TO(userGroupId, memberType), {
        json: { memberId },
      })
      .json<{ id: UUID }>();

    return response;
  } catch (error) {
    Log.error('Error adding member to user group', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query mutation hook for adding a member (user or user group) to a user group.
 * @param options - Optional React Query mutation options
 * @returns Mutation object containing mutation function and state
 */
export const useMutationAddToUserGroup = (
  options?: Parameters<typeof useMutation>[0],
) =>
  useMutation({
    mutationFn: async ({
      memberId,
      memberType,
      userGroupId,
    }: AddToUserGroupParams) =>
      addToUserGroup(userGroupId, memberType, memberId),
    onError(error: unknown) {
      Log.productAnalyticsEvent(
        'Failed to add member to user group',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.ERROR,
      );

      ToastService.httpError(['Failed to add member to user group'], error);
    },
    ...options,
  });
