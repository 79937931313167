import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

const siteReferencesSlice = createSlice({
  initialState: {
    assignedSiteReferences: [],
    assignedSiteReferencesLoading: LOADING_STATE.NOT_LOADED,
    siteReferences: [],
    siteReferencesLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'siteReferences',
  reducers: {
    replaceAssignedSiteReferences(state, parameter) {
      const { payload } = parameter;

      state.assignedSiteReferences = payload;
      state.assignedSiteReferencesLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceSiteReferences(state, parameter) {
      const { payload } = parameter;

      state.siteReferences = payload;
      state.siteReferencesLoading = LOADING_STATE.SUCCEEDED;
    },
    setAssignedSiteReferencesLoading(state, parameter) {
      const { payload } = parameter;

      state.assignedSiteReferencesLoading = payload;
    },
    setSiteReferencesLoading(state, parameter) {
      const { payload } = parameter;

      state.siteReferencesLoading = payload;
    },
  },
});

const { actions, reducer } = siteReferencesSlice;

export const {
  replaceAssignedSiteReferences,
  replaceSiteReferences,
  setAssignedSiteReferencesLoading,
  setSiteReferencesLoading,
} = actions;

export default reducer;
