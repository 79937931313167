import BasicModal from '~/components/BasicModal';

import { ArticleForm } from '../admin/articleMaster/ArticleForm';
import { CategoryForm } from '../admin/articleMaster/CategoryForm';
import { CompanyForm } from '../masterData/company/CompanyForm';
import { CostCenterForm } from '../masterData/costCenter/CostCenterForm';
import CustomerSubscriptionForm from '../admin/customerSubscription/CustomerSubscriptionForm';
import { CustomFieldForm } from '../admin/customField/CustomFieldForm';
import InvoiceCheckIgnoredArticleForm from '../admin/invoiceCheckIgnoredArticle/InvoiceCheckIgnoredArticleForm';
import { OrganizationalUnitForm } from '../masterData/organizationalUnit/OrganizationalUnitForm/OrganizationalUnitForm';
import { SignatureFieldForm } from '../admin/signatureField/SignatureFieldForm';
import { SiteForm } from '../masterData/site/SiteForm';
import { UserForm } from '../masterData/users/UserForm';
import { UserGroupForm } from '../masterData/userGroup/UserGroupForm';
import { VehicleForm } from '../masterData/vehicle/VehicleForm';

import { ErrorBoundary } from '~/ui/atoms';

/**
 * FormComponent renders different forms based on the provided entity type and state.
 * It uses an ErrorBoundary to handle errors and display a fallback modal if data cannot be loaded.
 * The settings pages are built in a way that you can open a form from inside of a form,
 * which will then be rendered in the open modal
 * So if a specific item (company, costCenter, organisationalGroup, site, user, userGroup, vehicle)
 * is set in state, we should render the corresponding form for this item.
 * Otherwise we render the form based on the entity type, which is the data that the page is about.
 *
 * @param refreshData - Refresh the data displayed in the table
 */
export const FormComponent = ({
  closeForm,
  entity,
  onOpenCompany,
  onOpenCostCenter,
  onOpenOrganisationalGroup,
  onOpenSite,
  onOpenUser,
  onOpenUserGroup,
  onOpenVehicle,
  refreshEntities,
  refreshData,
  setState,
  state: {
    company,
    costCenter,
    isFormOpen,
    formType,
    item,
    organisationalGroup,
    site,
    user,
    userGroup,
    vehicle,
  },
}) => {
  if (!isFormOpen) {
    return null;
  }

  const fallback = (
    <BasicModal
      title="Fehler"
      open={isFormOpen}
      closeModal={closeForm}
      fullWidth
    >
      <div className="h-24 w-full text-center">
        Daten konnten nicht geladen werden.
      </div>
    </BasicModal>
  );

  if (company) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <CompanyForm
          key={company?.id}
          open={isFormOpen}
          closeForm={closeForm}
          companyId={company?.id}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((previousState) => ({
              ...previousState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
        />
      </ErrorBoundary>
    );
  }

  if (costCenter) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <CostCenterForm
          key={costCenter?.id}
          open={isFormOpen}
          closeForm={closeForm}
          costCenterId={costCenter?.id}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((previousState) => ({
              ...previousState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
        />
      </ErrorBoundary>
    );
  }

  if (organisationalGroup) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <OrganizationalUnitForm
          key={organisationalGroup?.id}
          open={isFormOpen}
          closeForm={closeForm}
          organizationalUnitId={organisationalGroup?.id}
          type={formType}
          onOpenUser={onOpenUser}
          onOpenSite={onOpenSite}
          onOpenCostCenter={onOpenCostCenter}
          onOpenVehicle={onOpenVehicle}
          onOpenCompany={onOpenCompany}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onOpenUserGroup={onOpenUserGroup}
          onUpdatedUsersChange={(users) =>
            setState((previousState) => ({
              ...previousState,
              updatedUsers: users,
            }))
          }
          onUpdatedSitesChange={(sites) =>
            setState((previousState) => ({
              ...previousState,
              updatedSites: sites,
            }))
          }
          onUpdatedCostCentersChange={(costCenters) =>
            setState((previousState) => ({
              ...previousState,
              updatedCostCenters: costCenters,
            }))
          }
          onUpdatedVehiclesChange={(vehicles) =>
            setState((previousState) => ({
              ...previousState,
              updatedVehicles: vehicles,
            }))
          }
          onUpdatedCompaniesChange={(companies) =>
            setState((previousState) => ({
              ...previousState,
              updatedCompanies: companies,
            }))
          }
          // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
          onUpdatedUserGroupsChange={(userGroups) =>
            setState((previousState) => ({
              ...previousState,
              updatedUserGroups: userGroups,
            }))
          }
          onRefreshEntities={refreshEntities}
        />
      </ErrorBoundary>
    );
  }

  if (site) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <SiteForm
          key={site?.id}
          open={isFormOpen}
          closeForm={closeForm}
          siteId={site?.id}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((previousState) => ({
              ...previousState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
        />
      </ErrorBoundary>
    );
  }

  if (user) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <UserForm
          key={user?.id}
          open={isFormOpen}
          closeForm={closeForm}
          userId={user?.id}
          userCompanyId={user?.companyId}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onOpenUserGroup={onOpenUserGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) => {
            setState((previousState) => ({
              ...previousState,
              updatedOrganisationalGroups: organisationalGroups,
            }));
          }}
        />
      </ErrorBoundary>
    );
  }

  if (userGroup) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <UserGroupForm
          key={userGroup?.id}
          open={isFormOpen}
          closeForm={closeForm}
          userGroupId={userGroup?.id}
          type={formType}
          onOpenUser={onOpenUser}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onOpenUserGroup={onOpenUserGroup}
          onUpdatedUsersChange={(users) =>
            setState((previousState) => ({
              ...previousState,
              updatedUsers: users,
            }))
          }
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((previousState) => ({
              ...previousState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
          // Not needed to refresh user groups because all user groups are already refreshed when submitting the form.
          onRefreshEntities={refreshEntities}
        />
      </ErrorBoundary>
    );
  }

  if (vehicle) {
    return (
      <ErrorBoundary fallback={() => fallback}>
        <VehicleForm
          key={vehicle?.id}
          open={isFormOpen}
          closeForm={closeForm}
          vehicleId={vehicle?.id}
          type={formType}
          onOpenOrganisationalGroup={onOpenOrganisationalGroup}
          onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
            setState((previousState) => ({
              ...previousState,
              updatedOrganisationalGroups: organisationalGroups,
            }))
          }
        />
      </ErrorBoundary>
    );
  }

  switch (entity) {
    case 'user': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <UserForm
            key={item?.id}
            open={isFormOpen}
            closeForm={closeForm}
            userId={item?.id}
            userCompanyId={item?.companyId}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onOpenUserGroup={onOpenUserGroup}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) => {
              setState((previousState) => ({
                ...previousState,
                updatedOrganisationalGroups: organisationalGroups,
              }));
            }}
          />
        </ErrorBoundary>
      );
    }

    case 'site': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <SiteForm
            key={item?.id}
            open={isFormOpen}
            closeForm={closeForm}
            siteId={item?.id}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((previousState) => ({
                ...previousState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
          />
        </ErrorBoundary>
      );
    }

    case 'costCenter': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CostCenterForm
            key={item?.id}
            open={isFormOpen}
            closeForm={closeForm}
            costCenterId={item?.id}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((previousState) => ({
                ...previousState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
          />
        </ErrorBoundary>
      );
    }

    case 'vehicle': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <VehicleForm
            key={item?.id}
            open={isFormOpen}
            closeForm={closeForm}
            vehicleId={item?.id}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((previousState) => ({
                ...previousState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
          />
        </ErrorBoundary>
      );
    }

    case 'company': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CompanyForm
            key={item?.id}
            open={isFormOpen}
            closeForm={closeForm}
            companyId={item?.id}
            type={formType}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((previousState) => ({
                ...previousState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
          />
        </ErrorBoundary>
      );
    }

    case 'organisationalGroup': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <OrganizationalUnitForm
            key={item?.id}
            open={isFormOpen}
            closeForm={closeForm}
            organizationalUnitId={item?.id}
            type={formType}
            onOpenUser={onOpenUser}
            onOpenSite={onOpenSite}
            onOpenCostCenter={onOpenCostCenter}
            onOpenVehicle={onOpenVehicle}
            onOpenCompany={onOpenCompany}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onOpenUserGroup={onOpenUserGroup}
            onUpdatedUsersChange={(users) =>
              setState((previousState) => ({
                ...previousState,
                updatedUsers: users,
              }))
            }
            onUpdatedSitesChange={(sites) =>
              setState((previousState) => ({
                ...previousState,
                updatedSites: sites,
              }))
            }
            onUpdatedCostCentersChange={(costCenters) =>
              setState((previousState) => ({
                ...previousState,
                updatedCostCenters: costCenters,
              }))
            }
            onUpdatedVehiclesChange={(vehicles) =>
              setState((previousState) => ({
                ...previousState,
                updatedVehicles: vehicles,
              }))
            }
            onUpdatedCompaniesChange={(companies) =>
              setState((previousState) => ({
                ...previousState,
                updatedCompanies: companies,
              }))
            }
            // Not needed to refresh organisational groups because all organisational groups are already refreshed when submitting the form.
            onUpdatedUserGroupsChange={(userGroups) =>
              setState((previousState) => ({
                ...previousState,
                updatedUserGroups: userGroups,
              }))
            }
            onRefreshEntities={refreshEntities}
          />
        </ErrorBoundary>
      );
    }

    case 'userGroup': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <UserGroupForm
            key={item?.id}
            open={isFormOpen}
            closeForm={closeForm}
            userGroupId={item?.id}
            type={formType}
            onOpenUser={onOpenUser}
            onOpenOrganisationalGroup={onOpenOrganisationalGroup}
            onOpenUserGroup={onOpenUserGroup}
            onUpdatedUsersChange={(users) =>
              setState((previousState) => ({
                ...previousState,
                updatedUsers: users,
              }))
            }
            onUpdatedOrganisationalGroupsChange={(organisationalGroups) =>
              setState((previousState) => ({
                ...previousState,
                updatedOrganisationalGroups: organisationalGroups,
              }))
            }
            // Not needed to refresh user groups because all user groups are already refreshed when submitting the form.
            onRefreshEntities={refreshEntities}
          />
        </ErrorBoundary>
      );
    }

    case 'customField': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CustomFieldForm
            open={isFormOpen}
            closeForm={closeForm}
            customField={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'signatureField': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <SignatureFieldForm
            open={isFormOpen}
            closeForm={closeForm}
            signatureField={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'article': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <ArticleForm
            open={isFormOpen}
            closeForm={closeForm}
            initialArticle={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'category': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CategoryForm
            open={isFormOpen}
            closeForm={closeForm}
            initialCategory={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'customerSubscription': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <CustomerSubscriptionForm
            open={isFormOpen}
            closeForm={closeForm}
            customerSubscription={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    case 'invoiceCheckIgnoredArticle': {
      return (
        <ErrorBoundary fallback={() => fallback}>
          <InvoiceCheckIgnoredArticleForm
            open={isFormOpen}
            closeForm={closeForm}
            invoiceCheckIgnoredArticle={item}
            type={formType}
          />
        </ErrorBoundary>
      );
    }

    default: {
      return <ErrorBoundary fallback={() => fallback}>{null}</ErrorBoundary>;
    }
  }
};
