import React from 'react';
import ExportService from '~/services/export.service';
import { connect } from 'react-redux';
import { ROUTE } from '~/constants/Route';
import Invoice from '~/models/invoices/Invoice';
import Log from '~/utils/Log';
import {
  setInvoice_filterModel,
  setInvoice_sortModel,
  setInvoice_query,
  setInvoice_selectedStatus,
  setInvoice_selectedSeller,
  setInvoice_selectedBuyer,
  setInvoice_selectedNumber,
  setInvoice_selectedToSite,
} from '~/redux/filtersSlice';
import { withErrorBoundary } from '~/ui/atoms';
import unitUtils from '~/utils/unitUtils';
import { dateUtils, parseDate } from '~/utils/dateUtils';
import BasicTable from '../../BasicTable';
import LocalStorageService from '~/services/localStorage.service';
import BrowserUtils from '~/utils/browserUtils';
import { LOADING_STATE } from '~/constants/LoadingState';
import { setPageTitle } from '~/redux/menuSlice';
import InvoiceFilterBar from './InvoiceFilterBar';
import ContextMenu from '../../menu/ContextMenu';
import { replaceBrowsableInvoices } from '~/redux/invoicesSlice';
import { InvoiceColumnCombinedStatus } from '../invoiceColumns/InvoiceColumnCombinedStatus';
import { InvoiceColumnUnsignedDeliveryNoteIds } from '../invoiceColumns/InvoiceColumnUnsignedDeliveryNoteIds';
import { RequestSignatureForm } from '../../deliveries/deliveryNoteActions/RequestSignatureForm';
import ArrayUtils from '~/utils/arrayUtils';
import UserUtils from '~/utils/userUtils';
import ToastService from '~/services/toast.service';
import InvoiceFilterGroups from './InvoiceFilterGroups';
import InvoicesService from '~/services/invoices.service';
import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import { gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid';

const mapStateToProps = (state) => ({
  dateRange: state.filters.invoice_dateRange,
  filterGroups: state.filters.invoice_filterGroups,
  filterModel: state.filters.invoice_filterModel,
  invoices: state.invoices,
  query: state.filters.invoice_query,
  selectedBuyer: state.filters.invoice_selectedBuyer,
  selectedFilterGroup: state.filters.invoice_selectedFilterGroup,
  selectedNumber: state.filters.invoice_selectedNumber,
  selectedSeller: state.filters.invoice_selectedSeller,
  selectedStatus: state.filters.invoice_selectedStatus,
  selectedToSite: state.filters.invoice_selectedToSite,
  selectField: state.filters.invoice_selectField,
  sortModel: state.filters.invoice_sortModel,
});
const mapDispatchToProps = () => ({
  replaceBrowsableInvoices,
  setInvoice_filterModel,
  setInvoice_query,
  setInvoice_selectedBuyer,
  setInvoice_selectedNumber,
  setInvoice_selectedSeller,
  setInvoice_selectedStatus,
  setInvoice_selectedToSite,
  setInvoice_sortModel,
  setPageTitle,
});

class InvoiceOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contextMenu: null,
      excelData: [],
      filteredRows: [],
      invoiceSum: 0,
      isLoading: false,
      requestDeliveryNoteSignatureFormOpenDeliveryNoteIds: [],
      requestDeliveryNoteSignatureFormOpenInvoiceId: null,
      rowSelectionModel: [],
    };
  }

  async componentDidMount() {
    this.filterRows();
    this.setPageTitle();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.match.path !== prevProps.match.path ||
      JSON.stringify(this.props.selectedStatus) !==
        JSON.stringify(prevProps.selectedStatus) ||
      JSON.stringify(this.props.selectedSeller) !==
        JSON.stringify(prevProps.selectedSeller) ||
      JSON.stringify(this.props.selectedBuyer) !==
        JSON.stringify(prevProps.selectedBuyer) ||
      JSON.stringify(this.props.selectedNumber) !==
        JSON.stringify(prevProps.selectedNumber) ||
      JSON.stringify(this.props.selectedToSite) !==
        JSON.stringify(prevProps.selectedToSite) ||
      this.props.selectField !== prevProps.selectField ||
      this.props.query !== prevProps.query ||
      JSON.stringify(this.props.dateRange) !==
        JSON.stringify(prevProps.dateRange) ||
      (this.isIncoming() &&
        this.props.invoices.filteredIncomingInvoicesVersion !==
          prevProps.invoices.filteredIncomingInvoicesVersion) ||
      (!this.isIncoming() &&
        this.props.invoices.filteredOutgoingInvoicesVersion !==
          prevProps.invoices.filteredOutgoingInvoicesVersion)
    ) {
      this.filterRows();
    }

    // This should also check for changes of JSON.stringify(this.state.filteredRows)
    // but as JSON.stringify is too expensive for large datasets, it is omitted.
    if (
      this.state.rowSelectionModel.length !== prevState.rowSelectionModel.length
    ) {
      this.setState({
        excelData: this.state.filteredRows.filter((row) =>
          this.state.rowSelectionModel.includes(row.id),
        ),
      });
    }

    if (this.props.match.path !== prevProps.match.path) {
      this.setPageTitle();
    }

    if (
      this.props.selectedFilterGroup &&
      this.props.selectedFilterGroup !== prevProps.selectedFilterGroup
    ) {
      this.handleChangeFilterGroup();
    }
  }

  setPageTitle() {
    const pageTitle = this.isIncoming()
      ? 'Eingangsrechnungen'
      : 'Ausgangsrechnungen';

    this.props.setPageTitle(pageTitle);
    document.title = 'VESTIGAS - ' + pageTitle;
  }

  filterRows() {
    this.setState({
      isLoading: true,
    });

    const data = {
      dateRange: this.props.dateRange,
      query: this.props.query,
      rows: this.isIncoming()
        ? this.props.invoices.filteredIncomingInvoiceRows
        : this.props.invoices.filteredOutgoingInvoiceRows,
      selectedBuyer: this.props.selectedBuyer,
      selectedNumber: this.props.selectedNumber,
      selectedSeller: this.props.selectedSeller,
      selectedStatus: this.props.selectedStatus,
      selectedToSite: this.props.selectedToSite,
      selectField: this.props.selectField,
    };

    const filteredRows = InvoicesService.filterRows(data);

    this.setState({
      filteredRows,
      invoiceSum: ArrayUtils.sumByKey(
        filteredRows,
        Invoice.PROPERTY.TOTAL_PRICE_GROSS.KEY,
      ),
      isLoading: false,
    });
  }

  // Could also be done in the InvoiceFilterGroups component but for consistency with DashboardOverview, this code is kept in InvoiceOverview.
  handleChangeFilterGroup() {
    const filterGroup = this.props.filterGroups.find(
      (filterGroup) => filterGroup.id === this.props.selectedFilterGroup,
    );

    // ?? [] -> Operator is needed because if we introduce a new filter selectedXXX,
    // filterGroup.filters.selectedXXX will be undefined which would lead to an error in filtersSlice.js
    this.props.setInvoice_selectedStatus(
      filterGroup.filters.selectedStatus ?? [],
    );
    this.props.setInvoice_selectedSeller(
      filterGroup.filters.selectedSeller ?? [],
    );
    this.props.setInvoice_selectedBuyer(
      filterGroup.filters.selectedBuyer ?? [],
    );
    this.props.setInvoice_selectedNumber(
      filterGroup.filters.selectedNumber ?? [],
    );
    this.props.setInvoice_selectedToSite(
      filterGroup.filters.selectedToSite ?? [],
    );
  }

  // Could also be done in the InvoiceFilterGroups component but for consistency with DashboardOverview, this code is kept in InvoiceOverview.
  handleFilterChange = (type, filterValue) => {
    switch (type) {
      case FilterGroupFilter.FILTER.SELECTED_STATUS: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedStatus, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter status',
          Log.FEATURE.DELIVERY_OVERVIEW,
        );

        this.props.setInvoice_selectedStatus(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_SELLER: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedSeller, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter seller',
          Log.FEATURE.DELIVERY_OVERVIEW,
        );

        this.props.setInvoice_selectedSeller(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_BUYER: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedBuyer, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter buyer',
          Log.FEATURE.DELIVERY_OVERVIEW,
        );

        this.props.setInvoice_selectedBuyer(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_NUMBER: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedNumber, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter number',
          Log.FEATURE.DELIVERY_OVERVIEW,
        );

        this.props.setInvoice_selectedNumber(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_TO_SITE: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedToSite, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter to site',
          Log.FEATURE.DELIVERY_OVERVIEW,
        );

        this.props.setInvoice_selectedToSite(filterValue);

        break;
      }
    }
  };
  // Could also be done in the InvoiceFilterGroups component but for consistency with DashboardOverview, this code is kept in InvoiceOverview.
  resetFilters = () => {
    this.props.setInvoice_selectedStatus([]);
    this.props.setInvoice_selectedSeller([]);
    this.props.setInvoice_selectedBuyer([]);
    this.props.setInvoice_selectedNumber([]);
    this.props.setInvoice_selectedToSite([]);
  };

  isIncoming() {
    return this.props.match.path === ROUTE.INCOMING_INVOICES.ROUTE;
  }

  getColumns() {
    return [
      {
        field: 'combinedStatus',
        headerName: 'Status',
        renderCell: (params) => <InvoiceColumnCombinedStatus params={params} />,
        sortable: true,
        width: 130,
      },
      {
        field: Invoice.PROPERTY.SELLER.KEY,
        headerName: Invoice.PROPERTY.SELLER.STRING,
        resizableText: true,
        sortable: true,
        width: 400,
      },
      {
        field: Invoice.PROPERTY.BUYER.KEY,
        headerName: Invoice.PROPERTY.BUYER.STRING,
        resizableText: true,
        sortable: true,
        width: 400,
      },
      {
        field: Invoice.PROPERTY.NUMBER.KEY,
        headerName: Invoice.PROPERTY.NUMBER.STRING,
        resizableText: true,
        sortable: true,
        width: 200,
      },
      {
        field: Invoice.PROPERTY.TOTAL_PRICE_GROSS.KEY,
        headerName: Invoice.PROPERTY.TOTAL_PRICE_GROSS.STRING,
        renderCell: (params) =>
          unitUtils.formatDeMoneyAmount_safe(params.value),
        resizableText: true,
        sortable: true,
        type: 'number',
        width: 150,
      },
      {
        field: Invoice.PROPERTY.CURRENCY.KEY,
        headerName: Invoice.PROPERTY.CURRENCY.STRING,
        resizableText: true,
        sortable: true,
        width: 100,
      },
      {
        field: Invoice.PROPERTY.DATE.KEY,
        headerName: Invoice.PROPERTY.DATE.STRING,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
        resizableText: true,
        sortable: true,
        type: 'date',
        valueGetter: parseDate,
        width: 150,
      },
      {
        field: Invoice.PROPERTY.PARSED_DATE.KEY,
        headerName: Invoice.PROPERTY.PARSED_DATE.STRING,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
        resizableText: true,
        sortable: true,
        type: 'date',
        valueGetter: parseDate,
        width: 150,
      },
      {
        field: Invoice.PROPERTY.TO_SITE.KEY,
        headerName: Invoice.PROPERTY.TO_SITE.STRING,
        resizableText: true,
        sortable: true,
        width: 300,
      },
      {
        field: 'unsignedDeliveryNoteIds',
        headerName: 'Aktionen',
        renderCell: (params) => (
          <InvoiceColumnUnsignedDeliveryNoteIds
            params={params}
            onRequestSignatures={this.onRequestSignatures}
          />
        ),
        sortable: true,
        width: 100,
      },
    ];
  }

  getDefaultHiddenColumns() {
    const defaultHiddenColumns = [Invoice.PROPERTY.PARSED_DATE.KEY];

    if (this.isIncoming()) {
      defaultHiddenColumns.push(Invoice.PROPERTY.BUYER.KEY);
    } else {
      defaultHiddenColumns.push(Invoice.PROPERTY.SELLER.KEY);
    }

    return defaultHiddenColumns;
  }

  onPdfExport = async (downloadOption) => {
    ExportService.exportInvoices(this.state.rowSelectionModel, downloadOption);
  };
  onExcelInvoiceExport = async () => {
    if (this.state.rowSelectionModel.length === 0) {
      ToastService.info([
        'Bitte wähle für den Excel-Download mindestens einen Eintrag aus der Tabelle aus.',
      ]);
      Log.productAnalyticsEvent(
        'No row selected in invoices Excel-Download',
        Log.FEATURE.EXCEL_DOWNLOAD,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    Log.productAnalyticsEvent(
      'Download invoices as Excel',
      Log.FEATURE.EXCEL_DOWNLOAD,
    );

    ExportService.exportInvoicesAsExcelZip(
      this.state.rowSelectionModel,
      this.isIncoming()
        ? Invoice.DIRECTION.INCOMING
        : Invoice.DIRECTION.OUTGOING,
    );
  };
  onRowSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected invoices',
      { from: this.state.rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('(De)select invoice', Log.FEATURE.INVOICE_LIST);

    this.setState({
      rowSelectionModel: event,
    });
  };
  onFilterModelChange = (event) => {
    Log.info(
      'Change filter value of filter model',
      { from: this.props.filterModel, to: event },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter', Log.FEATURE.INVOICE_LIST);
    this.props.setInvoice_filterModel(event);
  };
  onSortModelChange = (event) => {
    Log.productAnalyticsEvent('Sort', Log.FEATURE.INVOICE_LIST);
    this.props.setInvoice_sortModel(event);
  };
  onOpenInvoice = (id) => {
    Log.productAnalyticsEvent('Open invoice', Log.FEATURE.INVOICE_LIST);
    this.props.replaceBrowsableInvoices(this.state.rowSelectionModel);
    this.props.history.push(this.getInvoiceUrl() + '/' + id);
  };
  onOpenInvoiceInNewTab = () => {
    Log.productAnalyticsEvent(
      'Open invoice in new tab',
      Log.FEATURE.INVOICE_LIST,
    );
    this.props.replaceBrowsableInvoices(this.state.rowSelectionModel);
    BrowserUtils.openNewTab(
      this.getInvoiceUrl() + '/' + this.state.contextMenu.id,
    );
    this.onCloseContextMenu();
  };
  onOpenContextMenu = (event) => {
    event.preventDefault();

    if (this.state.contextMenu) {
      // Repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu. Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context men
      this.setState({
        contextMenu: null,
      });
      return;
    }

    Log.productAnalyticsEvent('Open context menu', Log.FEATURE.MENU);
    this.setState({
      contextMenu: {
        id: event.currentTarget.dataset.id,
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      },
    });
  };
  onCloseContextMenu = () => {
    Log.productAnalyticsEvent('Close context menu', Log.FEATURE.MENU);
    this.setState({
      contextMenu: null,
    });
  };
  onRequestSignatures = (id, deliveryNoteIds) => {
    this.setState({
      requestDeliveryNoteSignatureFormOpenDeliveryNoteIds: deliveryNoteIds,
      requestDeliveryNoteSignatureFormOpenInvoiceId: id,
    });
  };
  // When the filter in the data grid is changed, the invoice sum in the footer should be updated.
  onStateChange = (state) => {
    // We need to filter out the undefined rows because otherwise the InvoiceOverview crashes when we change a filter
    // in the filter group. For some reason, the filtered rows from the filter group filter are still inside the
    // gridFilteredSortedRowEntriesSelector but are undefined.
    const filteredRows = gridFilteredSortedRowEntriesSelector(state)
      .map((row) => row.model)
      .filter((row) => row !== undefined);
    const invoiceSum = ArrayUtils.sumByKey(
      filteredRows,
      Invoice.PROPERTY.TOTAL_PRICE_GROSS.KEY,
    );

    if (this.state.invoiceSum === invoiceSum) {
      return;
    }

    this.setState({
      invoiceSum,
    });
  };

  getInvoiceUrl() {
    if (this.isIncoming()) {
      return ROUTE.INCOMING_INVOICE.ROUTE;
    }

    return ROUTE.OUTGOING_INVOICE.ROUTE;
  }

  getLoadingState() {
    if (this.state.isLoading) {
      return LOADING_STATE.LOADING;
    }

    return this.isIncoming()
      ? this.props.invoices.incomingInvoicesLoading
      : this.props.invoices.outgoingInvoicesLoading;
  }

  render() {
    return (
      <div className="main-padding flexdir-column flex h-full">
        <InvoiceFilterGroups
          onChangeValue={(id, customField, filterValue) =>
            this.handleFilterChange(id, filterValue)
          }
          resetFilters={this.resetFilters}
          data={
            this.isIncoming()
              ? this.props.invoices.filteredIncomingInvoiceRows
              : this.props.invoices.filteredOutgoingInvoiceRows
          }
        />
        <div className="mt-20px mb-20px min-h-1px bg-grey200 w-full" />
        <InvoiceFilterBar />
        {/* Data grid table should only be rendered when columns are set in state. Otherwise, table is rendered with no columns and filter model is reset. */}
        {this.getColumns().length > 0 ? (
          <div className="mt-20px rounded-5px box-shadow-blue h-[600px] flex-1 bg-white">
            <BasicTable
              rows={this.state.filteredRows}
              columns={this.getColumns()}
              checkboxSelection
              onRowClick={(rowData) => this.onOpenInvoice(rowData.row.id)}
              onRowRightClick={this.onOpenContextMenu}
              onRowSelectionModelChange={this.onRowSelectionModelChange}
              rowSelectionModel={this.state.rowSelectionModel}
              onFilterModelChange={this.onFilterModelChange}
              filterModel={this.props.filterModel}
              withFilterModel
              onSortModelChange={this.onSortModelChange}
              sortModel={this.props.sortModel}
              onPdfExport={this.onPdfExport}
              multiplePdfDownload={this.state.rowSelectionModel.length > 1}
              excelData={this.state.excelData}
              excelColumns={this.getColumns()}
              onExcelInvoiceExport={
                UserUtils.isInvoiceExcelAllowedUser()
                  ? this.onExcelInvoiceExport
                  : null
              }
              loading={this.getLoadingState()}
              onStateChange={this.onStateChange}
              localStorageKey={LocalStorageService.INVOICE_OVERVIEW}
              productAnalyticsFeature={Log.FEATURE.INVOICE_LIST}
              defaultHiddenColumns={this.getDefaultHiddenColumns()}
              footerText={
                'Rechnungsbeträge gesamt: ' +
                unitUtils.formatDeMoneyAmount_safe(this.state.invoiceSum) +
                ' €'
              }
            />
            <ContextMenu
              contextMenu={this.state.contextMenu}
              onClose={this.onCloseContextMenu}
              onOpen={() => this.onOpenInvoice(this.state.contextMenu.id)}
              onOpenInNewTab={this.onOpenInvoiceInNewTab}
            />
            {/* For an explanation why the form is not in the InvoiceColumnUnsignedDeliveryNoteIds component, check the comments in DeliveryList.js */}
            {this.state.requestDeliveryNoteSignatureFormOpenInvoiceId ? (
              <RequestSignatureForm
                permittedUserIds={[]}
                deliveryNoteIds={
                  this.state.requestDeliveryNoteSignatureFormOpenDeliveryNoteIds
                }
                open={this.state.requestDeliveryNoteSignatureFormOpenInvoiceId}
                closeForm={() =>
                  this.setState({
                    requestDeliveryNoteSignatureFormOpenDeliveryNoteIds: [],
                    requestDeliveryNoteSignatureFormOpenInvoiceId: null,
                  })
                }
              />
            ) : null}
          </div>
        ) : null}
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(InvoiceOverview),
  'Rechnungen konnten nicht geladen werden.',
);
