import { useCallback, useEffect, useMemo, useState } from 'react';

import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import { useFormInteractions } from './useFormInteractions';
import { useSyncServerData } from './useSyncServerData';
import { useTableInteractions } from './useTableInteractions';

const formStateDefaultValues = {
  formType: 'create',
  isFormOpen: false,
  item: null,
};

export const formDataDefaultValues = {
  company: null,
  costCenter: null,
  organisationalGroup: null,
  site: null,
  user: null,
  userGroup: null,
  vehicle: null,
};

const changesDefaultValues = {
  updatedCompanies: [],
  updatedCostCenters: [],
  updatedOrganisationalGroups: [],
  updatedSites: [],
  updatedUserGroups: [],
  updatedUsers: [],
  updatedVehicles: [],
};

export const useSettingsTable = ({
  entity,
  filterFunction,
  hasActiveStateFilter,
  hasMultiPermissionGrantEditing,
  items,
  onSearchStringChange,
  rows,
  sortBy,
}) => {
  const [state, setState] = useState({
    ...formStateDefaultValues,
    ...formDataDefaultValues,
    ...changesDefaultValues,
  });

  const { tableActions, tableState, setTableState } = useTableInteractions({
    entity,
    filterFunction,
    hasActiveStateFilter,
    items,
    onSearchStringChange,
    rows,
    sortBy,
  });

  const getItemById = useCallback(
    (ItemId) => items?.find(({ id }) => id === ItemId),
    [items],
  );

  useEffect(() => {
    if (!items || !state.item) {
      return;
    }

    const item = getItemById(state.item.id);

    if (item) {
      setState((previousState) => ({
        ...previousState,
        item,
      }));
    }
  }, [items, state.item]);

  const formActions = useFormInteractions(setState);

  const eventHandlers = {
    ...tableActions,
    ...formActions,
    closeForm: useCallback(() => {
      Log.info(`Close ${entity} form`, null, Log.BREADCRUMB.FORM_CLOSE.KEY);

      setState((previousState) => ({
        ...previousState,
        ...formDataDefaultValues,
        isFormOpen: false,
      }));
    }, [entity]),
    closeMultiPermissionGrantEdit: useCallback(() => {
      Log.info(
        'Close multi permission grant form',
        null,
        Log.BREADCRUMB.FORM_CLOSE.KEY,
      );

      setState((previousState) => ({
        ...previousState,
        showMultiPermissionGrantEdit: false,
      }));
    }, []),
    handleMultiPermissionGrantEdit: useCallback(() => {
      return hasMultiPermissionGrantEditing &&
        UserUtils.isPermissionGrantAllowedUser()
        ? () => eventHandlers.setShowMultiPermissionGrantEdit(true)
        : null;
    }, [hasMultiPermissionGrantEditing]),
    onOpenForm: useCallback(
      (element) => {
        const item = element ? getItemById(element.id) : null;
        const action = item ? 'edit' : 'create';

        Log.info(
          `Open ${entity} ${action} form`,
          item,
          Log.BREADCRUMB.FORM_OPEN.KEY,
        );
        Log.productAnalyticsEvent(
          `Open ${entity} ${action} form`,
          Log.FEATURE.SETTINGS_TABLE,
        );

        setState((previousState) => ({
          ...previousState,
          formType: action,
          isFormOpen: true,
          item,
        }));
      },
      [tableActions],
    ),
    openMultiPermissionGrantEdit: useCallback(() => {
      Log.info(
        'Open multi user edit form',
        { rowSelectionModel: state.rowSelectionModel },
        Log.BREADCRUMB.FORM_OPEN.KEY,
      );
      Log.productAnalyticsEvent(
        `Open ${entity} multi permission grant edit form`,
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((previousState) => ({
        ...previousState,
        showMultiPermissionGrantEdit: true,
      }));
    }, [entity, state.rowSelectionModel]),
    setShowMultiPermissionGrantEdit: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          showMultiPermissionGrantEdit: value,
        })),
      [],
    ),
  };

  const refreshEntities = useSyncServerData(state);

  const updateStates = useCallback((updates) => {
    setState(updates); // update the form state

    // update the table state
    const tableStateKeys = new Set([
      'activeStateFilter',
      'freeTextFilter',
      'rowSelectionModel',
      'sortModel',
    ]);

    const tableUpdates = Object.fromEntries(
      Object.entries(updates).filter(([key]) => tableStateKeys.has(key)),
    );

    if (Object.keys(tableUpdates).length > 0) {
      setTableState((previousState) => ({
        ...previousState,
        ...tableUpdates,
      }));
    }
  }, []);

  const returnValue = useMemo(
    () => ({
      eventHandlers,
      refreshEntities,
      setState: updateStates,
      state: {
        ...state,
        ...tableState,
      },
    }),
    [eventHandlers, refreshEntities, updateStates, state, tableState],
  );

  return returnValue;
};
