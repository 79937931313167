export const SiteNameAddressPair = ({
  costCenters,
  permissionGrantOnCostCenters,
  site,
}) => {
  const address = site.address.getConcatenatedAddress();

  let mergedAddressAndCostCenters = null;

  if (address && costCenters) {
    mergedAddressAndCostCenters = (
      <span className="text-grey400 h-full pl-[1ch]">
        {address}
        <span> | </span>
        {permissionGrantOnCostCenters
          ? 'zus. Berechtigung auf ' + costCenters
          : costCenters}
      </span>
    );
  } else if (address) {
    mergedAddressAndCostCenters = (
      <span className="text-grey400 pl-4">{address}</span>
    );
  } else if (costCenters) {
    mergedAddressAndCostCenters = (
      <span className="text-grey400 pl-4">
        {permissionGrantOnCostCenters
          ? 'zus. Berechtigung auf ' + costCenters
          : costCenters}
      </span>
    );
  }

  return (
    <div className="py-1 leading-5">
      {site.name}
      {mergedAddressAndCostCenters}
    </div>
  );
};
