import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useMutationUpdateOrganizationalUnits } from '~/data/organizationalUnit';
import { useQueryUserData } from '~/data/user';
import {
  queryKeysVehicle,
  useMutationCreateVehicle,
  useMutationUpdateVehicle,
  useQueryVehicle,
} from '~/data/vehicle';

import ToastService from '~/services/toast.service';

import Vehicle from '~/models/masterdata/Vehicle';

import { useDelayedValue } from '~/hooks/useDelayedValue';

import Log from '~/utils/Log';
import FunctionUtils from '~/utils/functionUtils';

import { handleSubmit as onSubmit } from './utils';

export const useVehicleForm = ({ closeForm, type, vehicleId }) => {
  const isCreatingVehicle = type === 'create';

  const queryClient = useQueryClient();

  const {
    data: currentUser,
    isError: isErrorUserInfo,
    isLoading: isLoadingUserInfo,
    isSuccess: isSuccessUserInfo,
  } = useQueryUserData(true);

  const companyInfo = currentUser?.companyInfo ?? {};

  const {
    data: vehicle,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchVehicle,
  } = useQueryVehicle(vehicleId, {
    enabled: !isCreatingVehicle && Boolean(vehicleId),
    placeholderData() {
      // Try to get some basic data from the list item cache to improve the rendering experience.
      const queriesData = queryClient.getQueriesData({
        queryKey: queryKeysVehicle.getAll().filter(Boolean),
      });

      for (const [, data] of queriesData) {
        if (Array.isArray(data?.data)) {
          const listItem = data?.data?.find(({ id }) => id === vehicleId);

          if (listItem) {
            const { companyId, id, isActive, licensePlate, name } = listItem;

            return new Vehicle({ companyId, id, isActive, licensePlate, name });
          }
        }
      }

      return undefined;
    },
    select: (data) => new Vehicle(data),
  });

  const {
    mutateAsync: updateVehicleMutation,
    isPending: isPendingUpdateVehicle,
  } = useMutationUpdateVehicle();

  const {
    mutateAsync: createVehicleMutation,
    isPending: isPendingCreateVehicle,
  } = useMutationCreateVehicle();

  const {
    mutateAsync: updateOrganizationalUnitsMutation,
    isPending: isPendingUpdateOrganizationalUnits,
  } = useMutationUpdateOrganizationalUnits({
    onError(error) {
      Log.error('updateOrganizationalUnitsMutation error');

      ToastService.httpError(
        ['Organisations-Gruppen konnten nicht geändert werden.'],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to update organisational groups',
        Log.FEATURE.VEHICLE,
        Log.TYPE.ERROR,
      );
    },
  });

  const isSubmitting =
    isPendingUpdateVehicle ||
    isPendingCreateVehicle ||
    isPendingUpdateOrganizationalUnits;

  const refetchVehicles = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: queryKeysVehicle.getAll({}),
    });
  }, [queryClient]);

  const getDefaultVehicle = useCallback(() => {
    const vehicle = new Vehicle();
    if (companyInfo?.id) {
      vehicle.companyId = companyInfo.id;
    }

    return vehicle;
  }, [companyInfo?.id]);

  const [state, setState] = useState({
    vehicle: getDefaultVehicle(),
  });

  const resetForm = useCallback(() => {
    setState({
      vehicle: vehicle ?? getDefaultVehicle(),
    });
  }, [getDefaultVehicle, vehicle]);

  const handleInputChange = useCallback(
    (event) => {
      const newVehicle = cloneDeep(state.vehicle);

      switch (event.target.name) {
        case 'licensePlateCity': {
          newVehicle.licensePlate.city = event.target.value?.toUpperCase();

          Log.info(
            'Change form value of city',
            {
              from: state.vehicle.licensePlate.city,
              to: newVehicle.licensePlate.city,
            },
            Log.BREADCRUMB.FORM_CHANGE.KEY,
          );
          FunctionUtils.delayFunction(
            // FIXME: remove this crap
            'licensePlateCity',
            Log.productAnalyticsEvent,
            ['Change license plate city', Log.FEATURE.VEHICLE],
          );

          break;
        }

        case 'licensePlateLetters': {
          newVehicle.licensePlate.letters = event.target.value?.toUpperCase();

          Log.info(
            'Change form value of letters',
            {
              from: state.vehicle.licensePlate.letters,
              to: newVehicle.licensePlate.letters,
            },
            Log.BREADCRUMB.FORM_CHANGE.KEY,
          );
          FunctionUtils.delayFunction(
            // FIXME: remove this crap
            'licensePlateLetters',
            Log.productAnalyticsEvent,
            ['Change license plate letters', Log.FEATURE.VEHICLE],
          );

          break;
        }

        case 'licensePlateNumbers': {
          newVehicle.licensePlate.numbers = event.target.value;

          Log.info(
            'Change form value of numbers',
            {
              from: state.vehicle.licensePlate.numbers,
              to: newVehicle.licensePlate.numbers,
            },
            Log.BREADCRUMB.FORM_CHANGE.KEY,
          );
          FunctionUtils.delayFunction(
            // FIXME: remove this crap
            'licensePlateNumbers',
            Log.productAnalyticsEvent,
            ['Change license plate numbers', Log.FEATURE.VEHICLE],
          );

          break;
        }

        default: {
          break;
        }
      }

      setState((previousState) => ({
        ...previousState,
        vehicle: newVehicle,
      }));
    },
    [state.vehicle],
  );

  const handleChangeCompany = useCallback(
    (value) => {
      const newVehicle = cloneDeep(state.vehicle);

      newVehicle.companyId = value;

      Log.info(
        'Change form value of companyId',
        {
          from: state.vehicle.companyId,
          to: newVehicle.companyId,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change companyId', Log.FEATURE.VEHICLE);

      setState((previousState) => ({
        ...previousState,
        vehicle: newVehicle,
      }));
    },
    [state.vehicle],
  );

  const handleChangeOrganisationalGroups = ({ added, removed }, setState) => {
    setState((previousState) => {
      const newVehicle = cloneDeep(previousState.vehicle);

      const removedIds = new Set(removed.map(({ id }) => id));
      const addedIds = new Set(added.map(({ id }) => id));

      newVehicle.organisationalGroups = [
        ...new Set([
          ...newVehicle.organisationalGroups.filter(
            (id) => !removedIds.has(id),
          ),
          ...addedIds,
        ]),
      ];

      Log.info(
        'Change form value of organisational groups',
        {
          from: previousState.vehicle.organisationalGroups,
          to: newVehicle.organisationalGroups,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change organisational groups',
        Log.FEATURE.VEHICLE,
      );

      return {
        ...previousState,
        vehicle: newVehicle,
      };
    });
  };

  const handleActiveCheckboxChange = useCallback(
    (event) => {
      const newVehicle = cloneDeep(state.vehicle);
      newVehicle.isActive = event.target.checked;

      Log.info(
        'Change form value of isActive',
        {
          from: state.vehicle.isActive,
          to: newVehicle.isActive,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change isActive checkbox',
        Log.FEATURE.VEHICLE,
      );

      setState((previousState) => ({
        ...previousState,
        vehicle: newVehicle,
      }));
    },
    [state.vehicle],
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      onSubmit({
        closeForm,
        createVehicleMutation,
        isCreatingVehicle,
        originalData: vehicle,
        queryClient,
        refetchVehicles,
        updateOrganizationalUnitsMutation,
        updateVehicleMutation,
        vehicle: state.vehicle,
      });
    },
    [
      closeForm,
      createVehicleMutation,
      isCreatingVehicle,
      queryClient,
      state.vehicle,
      updateOrganizationalUnitsMutation,
      updateVehicleMutation,
      vehicle,
    ],
  );

  const handleCancel = useCallback(() => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.VEHICLE);

    closeForm();
  }, [closeForm]);

  const unsavedChanges = useMemo(() => {
    if (isCreatingVehicle) {
      return [];
    }

    if (!isSuccess || !vehicle || !state.vehicle) {
      return [];
    }

    return Vehicle.getDifferentValues(vehicle, state.vehicle);
  }, [
    JSON.stringify(vehicle),
    JSON.stringify(state.vehicle),
    isCreatingVehicle,
  ]);

  const delayedUnsavedChanges = useDelayedValue(unsavedChanges);

  useEffect(() => {
    resetForm();
  }, [isSuccess, isError, JSON.stringify(vehicle)]);

  return {
    handleActiveCheckboxChange,
    handleCancel,
    handleChangeCompany,
    handleChangeOrganisationalGroups: ({ added, removed }) =>
      handleChangeOrganisationalGroups({ added, removed }, setState),
    handleInputChange,
    handleSubmit,
    isCreatingVehicle,
    isError,
    isLoading,
    isSubmitting,
    refetchVehicle,
    unsavedChanges: delayedUnsavedChanges,
    vehicle: state.vehicle,
  };
};
