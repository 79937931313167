import { Grid, Stack } from '@mui/material';

import { cn } from '~/utils/tailwind';

import { withErrorBoundary } from '~/ui/atoms';

import { SiteIdentifiersTableComponent } from './SiteIdentifiersTableComponent';
import { useSiteIdentifiers } from './useSiteIdentifiers';

export const SiteIdentifiersTable = withErrorBoundary(
  ({ className, siteId, style }) => {
    const {
      blacklistedSites,
      handleBlacklistSites,
      handleDeleteMappings,
      handleSelect,
      handleWhitelistSites,
      isInProgress,
      isLoading,
      selected,
      whitelistedSites,
    } = useSiteIdentifiers(siteId);

    return (
      <Stack
        direction="column"
        gap={4}
        className={cn('w-full p-2.5', className)}
        style={style}
      >
        <Grid item xs={12}>
          <SiteIdentifiersTableComponent
            blacklistStatus="whitelisted"
            isInProgress={isInProgress}
            isLoading={isLoading}
            onBlacklistSite={handleBlacklistSites}
            onDeleteMappings={handleDeleteMappings}
            onSelectSite={handleSelect}
            selectedSites={selected.whitelistedSites}
            siteId={siteId}
            sites={whitelistedSites}
          />
        </Grid>
        <Grid item xs={12}>
          <SiteIdentifiersTableComponent
            blacklistStatus="blacklisted"
            isInProgress={isInProgress}
            isLoading={isLoading}
            onBlacklistSite={handleWhitelistSites}
            onDeleteMappings={handleDeleteMappings}
            onSelectSite={handleSelect}
            selectedSites={selected.blacklistedSites}
            siteId={siteId}
            sites={blacklistedSites}
          />
        </Grid>
      </Stack>
    );
  },
  'Standortbezeichnungen konnten nicht geladen werden.',
);
