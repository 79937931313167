import cloneDeep from 'lodash/cloneDeep';
import { useState, useEffect, useCallback } from 'react';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import ToastService from '~/services/toast.service';

import Permissions from '~/models/masterdata/Permissions';

import Log from '~/utils/Log';

export const usePermissionForm = ({
  onCancel,
  onSubmit,
  propertyPermissions,
}) => {
  const [permissions, setPermissions] = useState(new Permissions());

  useEffect(() => {
    resetForm();
  }, [propertyPermissions]);

  const resetForm = () => {
    setPermissions(propertyPermissions ?? new Permissions());
  };

  const handleChange = useCallback(
    (event) => {
      const newPermissions = cloneDeep(permissions);

      const { name, checked } = event.target;
      const [permissionType, permission] = name.split('_');

      if (!permissionType || !permission || !newPermissions[permissionType]) {
        Log.error(
          null,
          new EnumValueNotFoundException(`Invalid checkbox name: ${name}`),
        );

        return;
      }

      newPermissions[permissionType][permission] = checked;

      Log.info(
        `Change form value of ${name}`,
        {
          from: permissions[permissionType][permission],
          to: checked,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        `Change ${permissionType}.${permission} checkbox`,
        Log.FEATURE.PERMISSIONS,
      );

      setPermissions(newPermissions);
    },
    [permissions, setPermissions],
  );

  const handleChangeRole = (event) => {
    Log.info(
      'Change form value of role',
      {
        from: permissions.getDefaultRoleName(),
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change role (' + event.target.value + ')',
      Log.FEATURE.PERMISSIONS,
    );

    const newPermissions = new Permissions();

    if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
      ToastService.info([
        'Um individuelle Berechtigungen zu vergeben, kannst du diese in den Kästchen auswählen und mit "Speichern" bestätigen.',
      ]);

      return;
    }

    newPermissions.initWithDefaultRole(event.target.value);

    setPermissions(newPermissions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    Log.info(
      'Submit permission form',
      permissions,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.PERMISSIONS);

    onSubmit(permissions);
  };

  const handleCancel = () => {
    Log.info('Abort permission form', null, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.PERMISSIONS);

    resetForm();
    onCancel();
  };

  return {
    handleCancel,
    handleChange,
    handleChangeRole,
    handleSubmit,
    permissions,
  };
};
