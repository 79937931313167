import Config from '~/Config';
import {
  saveDeliveryNotes,
  replaceDeliveryNoteTemplates,
  setDeliveryNoteTemplatesLoading,
  updateDeliveryNotes,
  replaceDeliveryNotes,
} from '~/redux/deliveryNotesSlice';
import axios from '~/utils/api-client';
import store from '~/redux/store';
import ToastService from './toast.service';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';
import { promiseHandler } from '~/utils/promiseHandler';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import CacheService from './cache.service';
import cloneDeep from 'lodash/cloneDeep';
import Company from '~/models/masterdata/Company';
import { LOADING_STATE } from '~/constants/LoadingState';
import DeliveryNoteValidationResult from '~/models/deliveries/DeliveryNoteValidationResult';
import { dateUtils } from '~/utils/dateUtils';
import FileReaderUtils from '~/utils/fileReaderUtils';
import ArrayUtils from '~/utils/arrayUtils';
import qs from 'qs';
import {
  setOldestFilteredDlnDate,
  setOldestFilteredDlnDateLoading,
  setDashboard_selectedDateRange,
  setDelivery_dateRange,
  setDashboard_individualDateRange,
  setDelivery_individualDateRange,
  setHome_selectedDateRange,
  setHome_individualDateRange,
} from '~/redux/filtersSlice';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import moment from 'moment';
import DashboardService from './dashboard.service';
import UserUtils from '~/utils/userUtils';
import MuiDataGridFilter from '~/models/filters/MuiDataGridFilter';
import BackendFilter from '~/models/filters/BackendFilter';
import FilterNew from '~/models/filters/FilterNew';
import PromiseUtils from '~/utils/promiseUtils';
import ObjectUtils from '~/utils/objectUtils';
import SiteService from './site.service';
import CostCenterService from './costCenter.service';
import UserService from './user.service';
import CustomFieldService from './customField.service';
import FeatureService from './feature.service';

import { mapDeliveryRow } from '~/components/deliveries/utils';

const apiUrl = Config.apiUrl;
const couchbaseSyncgatewayUrl = Config.sgwUrl;
const waitTime = 5000;
const onErrorWaitTime = 300_000;
const maxErrorCount = 10;

class DeliveriesService {
  constructor() {
    this.DELIVERY_NOTES_PAGE_SIZE = 1000;

    this.dlnCursor = null;
    this.dlnErrorCount = 0;
    // this.loadedDlnPages = 0;
    // To not overwrite the dlns when the user changes the sites or cost centers and thus the dlns have to be reloaded.
    this.dlnRequestId = 0;

    this.cachedChainsByDlns = [];

    // This variable is needed as a workaround to fix a certain bug.
    // Bug: When passing a dln directly via history.push to the delivery note form, an error was thrown.
    // Workaround bug fix: Write the dln to this variable and pass a flag (passDeliveryNote) to the delivery note form.
    // In the form, read the dln from this variable (this.deliveryNoteForCreationForm).
    this.deliveryNoteForCreationForm = null;

    this.DLN_PAGINATION_SIZE = 1000;
    this.MAX_DLN_COUNT = 6000;
  }

  getMaxDlnCount() {
    // FIXME: remove customer specific settings and set values in loadDlnsAmount in admin app
    if (UserUtils.isHtiSupportUser()) {
      return 20_000;
    }

    if (UserUtils.isMaxiKaunUser() || UserUtils.isStrabagSupportUser()) {
      return 30_000;
    }

    return FeatureService.loadDlnsAmount() || this.MAX_DLN_COUNT;
  }

  initStoredDlns(withDlnUpdater, permittedToSites, permittedCostCenters) {
    this.dlnRequestId += 1;

    const initArchiveModePromise = this.initArchiveMode(
      permittedToSites,
      permittedCostCenters,
      this.dlnRequestId,
    ); // TODO: VGSD-3794 add fromSite
    const loadFirstDlnBulkPromise = this.loadFirstDlnBulk(
      withDlnUpdater,
      permittedToSites,
      permittedCostCenters,
      this.dlnRequestId,
    );

    return PromiseUtils.allResolved([
      initArchiveModePromise,
      loadFirstDlnBulkPromise,
    ]).catch((error) => {
      Log.error('Failed to initialize stored delivery notes.', error);
      Log.productAnalyticsEvent(
        'Failed to initialize stored delivery notes',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );

      store.dispatch(setOldestFilteredDlnDateLoading(LOADING_STATE.FAILED));

      throw error;
    });
  }

  // Find the data of the oldest DLN and determine, if there is an archive mode and what DLN data is included / excluded.
  async initArchiveMode(permittedToSites, permittedCostCenters, dlnRequestId) {
    store.dispatch(setOldestFilteredDlnDateLoading(LOADING_STATE.LOADING));

    // Fetch results count for current filter settings
    const [count, error] = await promiseHandler(
      this.countDlns(
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        permittedToSites,
        permittedCostCenters,
        [],
        [],
        null,
        null,
      ), // TODO: VGSD-3794 add fromSite
    );

    // Cancel query if the request has been canceled
    if (this.dlnRequestId !== dlnRequestId) {
      return;
    }

    // If there are less than 6000 delivery notes, there is no archive mode.
    if (count <= this.getMaxDlnCount()) {
      this.removeArchiveMode();
      return;
    }

    if (error) {
      throw error;
    }

    // We do have an archive mode: load the DLN that is just outside the archive and in the normal mode (i.e. will be taken from the Redux store instead of fetched on demand)
    const [response, error2] = await promiseHandler(
      axios.post(apiUrl + '/asset/delivery_note/search', {
        filter_config: {
          filter_aggr: FilterNew.BOOLEAN_OPERATOR.AND,
          filter_groups: [
            this.getFilterGroupForPermittedSitesAndCostCenters(
              permittedToSites,
              permittedCostCenters,
            ),
          ],
        },
        limit: 1,
        offset: this.getMaxDlnCount() - 1,
        order_by: 'dln_date',
      }),
    );

    if (this.dlnRequestId !== dlnRequestId) {
      return;
    }

    if (error2) {
      throw error2;
    }

    const deliveryNote = new DeliveryNote(response.data.assets[0]);

    this.setArchiveMode(deliveryNote);
  }

  async loadFirstDlnBulk(
    withDlnUpdater,
    permittedToSites,
    permittedCostCenters,
    dlnRequestId,
  ) {
    // TODO: VGSD-3794 add fromSite
    const [response, error] = await promiseHandler(
      axios.post(apiUrl + '/asset/delivery_note/search', {
        filter_config: {
          filter_aggr: FilterNew.BOOLEAN_OPERATOR.AND,
          filter_groups: [
            this.getFilterGroupForPermittedSitesAndCostCenters(
              permittedToSites,
              permittedCostCenters,
            ),
          ],
        },
        limit: this.DLN_PAGINATION_SIZE,
        order_by: 'dln_date',
      }),
    );

    if (!this.dlnCursor) {
      this.dlnCursor = response.data.sequence_number;
      this.runDlnUpdater();
    }

    if (this.dlnRequestId !== dlnRequestId) {
      return;
    }

    if (error) {
      throw error;
    }

    const [deliveryNotes, error2] = await promiseHandler(
      this.initDlns(response.data.assets),
    );

    if (this.dlnRequestId !== dlnRequestId) {
      return;
    }

    if (error2) {
      throw error2;
    }

    store.dispatch(replaceDeliveryNotes(deliveryNotes));

    if (deliveryNotes.length < this.DLN_PAGINATION_SIZE) {
      // If there were less than 1000 delivery notes in the first bulk, we know there are no more delivery notes.
      return;
    }

    const [response3, error3] = await promiseHandler(
      // TODO: VGSD-3794 add fromSite
      this.loadDlnBulk(
        this.DLN_PAGINATION_SIZE,
        permittedToSites,
        permittedCostCenters,
        dlnRequestId,
      ),
    );

    if (error3) {
      throw error3;
    }
  }

  async loadDlnBulk(
    offset,
    permittedToSites,
    permittedCostCenters,
    dlnRequestId,
  ) {
    // TODO: VGSD-3794 add fromSite?
    const [response, error] = await promiseHandler(
      axios.post(apiUrl + '/asset/delivery_note/search', {
        filter_config: {
          filter_aggr: FilterNew.BOOLEAN_OPERATOR.AND,
          filter_groups: [
            this.getFilterGroupForPermittedSitesAndCostCenters(
              permittedToSites,
              permittedCostCenters,
            ),
          ],
        },
        limit: this.DLN_PAGINATION_SIZE,
        offset,
        order_by: 'dln_date',
      }),
    );

    if (this.dlnRequestId !== dlnRequestId) {
      return;
    }

    if (error) {
      throw error;
    }

    const [deliveryNotes, error2] = await promiseHandler(
      this.initDlns(response.data.assets),
    );

    if (this.dlnRequestId !== dlnRequestId) {
      return;
    }

    if (error2) {
      throw error2;
    }

    store.dispatch(saveDeliveryNotes(deliveryNotes));

    // If there were less than 1000 delivery notes in this bulk, we know that there are no more delivery notes.
    if (deliveryNotes.length < this.DLN_PAGINATION_SIZE) {
      return;
    }

    // If the maximum number of delivery notes is reached (6000), we don't want to load more.
    if (offset + this.DLN_PAGINATION_SIZE > this.getMaxDlnCount()) {
      return;
    }

    return this.loadDlnBulk(
      offset + this.DLN_PAGINATION_SIZE,
      permittedToSites,
      permittedCostCenters,
      dlnRequestId,
    );
  }

  getFilteredDlnsForDeliveryChanges(
    permittedToSites,
    permittedCostCenters,
    dateRange,
    offset,
    paginationSize,
  ) {
    const filterGroups = [];

    filterGroups.push(
      this.getFilterGroupForPermittedSitesAndCostCenters(
        permittedToSites,
        permittedCostCenters,
      ),
    );

    filterGroups.push({
      filter_aggr: FilterNew.BOOLEAN_OPERATOR.AND,
      filter_clauses: [
        {
          comp: BackendFilter.OPERATOR.GREATER_THAN_OR_EQUALS,
          name: 'dln_modified_on',
          value: dateUtils.getFormattedDate(
            moment(dateRange[0]),
            dateUtils.DATE_FORMAT.YYYY_MM_DD_ISO,
          ),
        },
        {
          comp: BackendFilter.OPERATOR.LESS_THAN_OR_EQUALS,
          name: 'dln_modified_on',
          // Add 1 day to the end date to include the whole day
          value: dateUtils.getFormattedDate(
            moment(dateRange[1]).add(1, 'days'),
            dateUtils.DATE_FORMAT.YYYY_MM_DD_ISO,
          ),
        },
      ],
    });

    const url = apiUrl + '/asset/delivery_note/search';
    const body = {
      filter_config: {
        filter_aggr: FilterNew.BOOLEAN_OPERATOR.AND,
        filter_groups: filterGroups,
      },
      limit: paginationSize,
      offset,
      order_by: 'modified_on',
    };

    const cacheKey = url + JSON.stringify(body);

    const [cachedValue, error] = CacheService.getCached(cacheKey);
    if (cachedValue) {
      return Promise.resolve(cachedValue);
    }

    if (error) {
      return Promise.reject(error);
    }

    return axios
      .post(apiUrl + '/asset/delivery_note/search', {
        filter_config: {
          filter_aggr: FilterNew.BOOLEAN_OPERATOR.AND,
          filter_groups: filterGroups,
        },
        limit: paginationSize,
        offset,
        order_by: 'modified_on',
      })
      .then(async (response) => {
        const [deliveryNotes, error] = await promiseHandler(
          this.initDlns(response.data.assets),
        );

        if (error) {
          throw error;
        }

        CacheService.setCached(cacheKey, deliveryNotes);

        return deliveryNotes;
      })
      .catch((error) => {
        CacheService.setError(cacheKey, error);
        throw error;
      });
  }

  loadFirstDlnPage({
    acceptStates,
    article,
    dateRange,
    filterModel,
    fromSite,
    permittedCostCenters,
    permittedToSites,
    processStates,
    recipients,
    selectedCostCenters,
    selectedSites,
    settledStatus,
    suppliers,
    toSiteRecipient,
    toSiteSupplier,
  }) {
    return axios
      .post(
        apiUrl + '/asset/delivery_note/search',
        this.getDlnPageSearchBody({
          acceptStates,
          article,
          dateRange,
          filterModel,
          fromSite,
          page: 0,
          permittedCostCenters,
          permittedToSites,
          processStates,
          recipients,
          selectedCostCenters,
          selectedSites,
          settledStatus,
          suppliers,
          toSiteRecipient,
          toSiteSupplier,
        }),
      )
      .then(async (response) => {
        const data = response.data;

        const [deliveryNotes, error] = await promiseHandler(
          this.initDlns(data.assets),
        );

        if (error) {
          throw error;
        }

        return {
          deliveryRows: deliveryNotes.map(mapDeliveryRow),
          page: 0,
        };
      })
      .catch((error) => {
        Log.error('Failed to load delivery notes.', error);
        throw error;
      });
  }

  loadDlnPage({ dateRange, filterModel, filters, page }) {
    const {
      acceptStates,
      article,
      fromSite,
      permittedCostCenters,
      permittedToSites,
      processStates,
      recipients,
      selectedCostCenters,
      selectedSites,
      settledStatus,
      suppliers,
      toSiteRecipient,
      toSiteSupplier,
    } = filters;

    return axios
      .post(
        apiUrl + '/asset/delivery_note/search',
        this.getDlnPageSearchBody({
          acceptStates,
          article,
          dateRange,
          filterModel,
          fromSite,
          page,
          permittedCostCenters,
          permittedToSites,
          processStates,
          recipients,
          selectedCostCenters,
          selectedSites,
          settledStatus,
          suppliers,
          toSiteRecipient,
          toSiteSupplier,
        }),
      )
      .then(async ({ data }) => {
        const [deliveryNotes, error] = await promiseHandler(
          this.initDlns(data.assets),
        );

        if (error) {
          throw error;
        }

        return {
          deliveryRows: deliveryNotes.map(mapDeliveryRow),
          page,
        };
      })
      .catch((error) => {
        Log.error('Failed to load delivery notes.', error);
        throw error;
      });
  }

  /**
   * Loads possible values (suggestions) for a filter (filter row) within a filter group from the backend.
   * Currently only required for archive mode, in the all filtering will be done in the backend though.
   *
   * @see https://app.dev.vestigas.com/redoc#tag/Analytics/operation/compute_dln_analytics_suggestions_analytics_suggestions_post
   *
   * @param {object} options - Options for loading filter suggestions
   * @param {string} options.dateRange - The date range for which to load filter suggestions
   * @param {string} options.entity - The entity for which to load filter suggestions
   * @param {object} options.filterGroups - The filter groups to consider when loading filter suggestions
   * @param {number} options.offset - The offset of the suggestions that have been loaded already
   * @param {string} options.searchValue - The free text search value
   * @return {Promise} A promise that resolves with the loaded filter suggestions
   */
  loadDlnFilterSuggestions({
    dateRange,
    entity,
    filterGroups: {
      processStates = [],
      acceptStates = [],
      settledStatus = [],
      toSiteRecipients = [],
      toSiteSuppliers = [],
      articles = [],
      suppliers = [],
      recipients = [],
      permittedToSites = [],
      permittedCostCenters = [],
      selectedSites = [],
      selectedCostCenters = [],
    },
    offset,
    searchValue,
  }) {
    if (entity === BackendFilter.FILTER.PROCESS_STATE) {
      // Shortcut for process state - given the finite amount of options, we don't need to query the API.
      return Promise.resolve(DeliveryNote.getProcessStateOptions());
    }

    if (entity === BackendFilter.FILTER.DLN_ACCEPT_STATE) {
      // Shortcut for accept state - given the finite amount of options, we don't need to query the API.
      return Promise.resolve(AcceptStateCalculator.getAcceptStates());
    }

    // Currently the filters are not influencing the suggestions of each other.
    // This could be implemented in the future if the backend supports it.
    const searchBody = this.getSearchBody({
      filterGroups: {
        dateRange,
        filterModel: null,
      },
    });
    searchBody.limit = 100;
    searchBody.offset = offset;
    if (searchValue) {
      searchBody.search_value = searchValue;
    }

    delete searchBody.filter_aggr;

    searchBody.entity = entity;

    return axios
      .post(apiUrl + '/analytics/suggestions', searchBody)
      .then(async ({ data }) => data.items)
      .catch((error) => {
        Log.error('Failed to load delivery note filter suggestions.', error);
        throw error;
      });
  }

  countDlns(
    processStates,
    acceptStates,
    settledStatus,
    toSiteRecipient,
    toSiteSupplier,
    article,
    suppliers,
    recipients,
    permittedToSites,
    permittedCostCenters,
    selectedSites,
    selectedCostCenters,
    dateRange,
    filterModel,
    fromSite,
  ) {
    return axios
      .post(
        apiUrl + '/asset/delivery_note/search/count',
        this.getSearchBody({
          filterGroups: {
            acceptStates,
            article,
            dateRange,
            filterModel,
            fromSite,
            permittedCostCenters,
            permittedToSites,
            processStates,
            recipients,
            selectedCostCenters,
            selectedSites,
            settledStatus,
            suppliers,
            toSiteRecipient,
            toSiteSupplier,
          },
        }),
      )
      .then(async ({ data }) => data.total_assets)
      .catch((error) => {
        Log.error('Failed to count delivery notes.', error);
        Log.productAnalyticsEvent(
          'Failed to count delivery notes',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        throw error;
      });
  }

  getAnalyticsData(
    processStates,
    acceptStates,
    settledStatus,
    toSiteRecipient,
    toSiteSupplier,
    article,
    suppliers,
    recipients,
    permittedToSites,
    permittedCostCenters,
    selectedSites,
    selectedCostCenters,
    unitType,
    dateRange,
    filterModel,
  ) {
    let searchBody = this.getSearchBody({
      filterGroups: {
        acceptStates,
        article,
        dateRange,
        filterModel,
        permittedCostCenters,
        permittedToSites,
        processStates,
        recipients,
        selectedCostCenters,
        selectedSites,
        settledStatus,
        suppliers,
        toSiteRecipient,
        toSiteSupplier,
      },
    });

    const timeAggregate =
      DashboardService.getAggregationFromDateRange(dateRange);

    searchBody = {
      ...searchBody,
      time_aggregate: timeAggregate,
      unit_type: unitType,
    };

    return axios
      .post(apiUrl + '/analytics', searchBody)
      .then(async ({ data }) => data.values)
      .catch((error) => {
        Log.error('Failed to get analytics data.', error);
        Log.productAnalyticsEvent(
          'Failed to get analytics data',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        throw error;
      });
  }

  getDlnPageSearchBody({
    acceptStates,
    article,
    dateRange,
    filterModel,
    fromSite,
    page,
    permittedCostCenters,
    permittedToSites,
    processStates,
    recipients,
    selectedCostCenters,
    selectedSites,
    settledStatus,
    suppliers,
    toSiteRecipient,
    toSiteSupplier,
  }) {
    const searchBody = this.getSearchBody({
      filterGroups: {
        acceptStates,
        article,
        dateRange,
        filterModel,
        fromSite,
        permittedCostCenters,
        permittedToSites,
        processStates,
        recipients,
        selectedCostCenters,
        selectedSites,
        settledStatus,
        suppliers,
        toSiteRecipient,
        toSiteSupplier,
      },
    });

    return {
      ...searchBody,
      limit: this.DELIVERY_NOTES_PAGE_SIZE,
      offset: page * this.DELIVERY_NOTES_PAGE_SIZE,
      order_by: 'created_on',
    };
  }

  /**
   * Returns the search body for the delivery notes.
   *
   * Takes filter groups and an optional filter aggregator as parameters and returns a DlnFilterConfig.
   * @see  https://app.dev.vestigas.com/redoc#tag/Analytics/operation/compute_dln_analytics_suggestions_analytics_suggestions_post for an example what the DlnFilterConfig looks like as used in an API endpoint.
   *
   * @param {object} options.filterGroups - The filter groups object.
   * @param {string} options.filterAggregator - The filter aggregator - defaults to AND
   * @return {object} The search body.
   */
  getSearchBody({
    filterGroups: {
      acceptStates = [],
      article = [],
      dateRange,
      filterModel,
      fromSite = [],
      permittedCostCenters = [],
      permittedToSites = [],
      processStates = [],
      recipients = [],
      selectedCostCenters = [],
      selectedSites = [],
      settledStatus = [],
      suppliers = [],
      toSiteRecipient = [],
      toSiteSupplier = [],
    },
    filterAggregator = FilterNew.BOOLEAN_OPERATOR.AND,
  }) {
    const mappedFilterGroups = [];

    mappedFilterGroups.push(
      this.getFilterGroupForPermittedSitesAndCostCenters(
        selectedSites,
        selectedCostCenters,
      ),
    );

    const filterGroupForGenericFilters = {
      filter_aggr: FilterNew.BOOLEAN_OPERATOR.AND,
      filter_clauses: [],
    };

    if (dateRange) {
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.GREATER_THAN_OR_EQUALS,
        name: 'dln_date',
        value: dateUtils.getFormattedDate(
          moment(dateRange[0]),
          dateUtils.DATE_FORMAT.YYYY_MM_DD_ISO,
        ),
      });
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.LESS_THAN_OR_EQUALS,
        name: 'dln_date',
        // Add 1 day to the end date to include the whole day
        value: dateUtils.getFormattedDate(
          moment(dateRange[1]).add(1, 'days'),
          dateUtils.DATE_FORMAT.YYYY_MM_DD_ISO,
        ),
      });
    }

    if (processStates.length > 0) {
      const processStateValues = [];
      for (const processStateString of processStates) {
        const processState =
          DeliveryNote.getProcessStateKeyByString(processStateString);
        processStateValues.push(processState);
        // In the backend "settled" is a process state. In the Webapp it is moved to the accept states.
        // To retrieve the correct delivery notes, if the user wants to see all "delivered" delivery notes, the Webapp also has to query the settled ones as they are included.
        if (processState === DeliveryNote.PROCESS_STATE.DELIVERED.API_KEY) {
          processStateValues.push(DeliveryNote.PROCESS_STATE.SETTLED.API_KEY);
        }
      }

      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.PROCESS_STATE,
        value: processStateValues,
      });
    }

    if (acceptStates.length > 0) {
      const acceptStateValues = [];
      for (const acceptStateString of acceptStates) {
        const acceptState =
          AcceptStateCalculator.getBackendAcceptStateKey(acceptStateString);
        acceptStateValues.push(acceptState);
      }

      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.DLN_ACCEPT_STATE,
        value: acceptStateValues,
      });
    }

    if (toSiteRecipient.length > 0) {
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.CONFIRMED_SITE_NAME,
        value: toSiteRecipient,
      });
    }

    if (toSiteSupplier.length > 0) {
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.SUPPLIER_ASSIGNED_SITE_NAME,
        value: toSiteSupplier,
      });
    }

    if (article.length > 0) {
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.ARTICLE_NAME,
        value: article,
      });
    }

    if (suppliers.length > 0) {
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.SUPPLIER_NAME,
        value: suppliers,
      });
    }

    if (recipients.length > 0) {
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.RECIPIENT_NAME,
        value: recipients,
      });
    }

    if (permittedToSites.length > 0) {
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.CONFIRMED_SITE_ID,
        value: permittedToSites,
      });
    }

    if (permittedCostCenters.length > 0) {
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.CONFIRMED_ACCOUNTING_REFERENCE_ID,
        value: permittedCostCenters,
      });
    }

    if (fromSite?.length) {
      // TODO: VGSD-3794 use confirmed_site_names instead of trying to load 'loading_location', which is not supported by the API
      filterGroupForGenericFilters.filter_clauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.LOADING_LOCATION,
        value: fromSite,
      });
    }

    mappedFilterGroups.push(filterGroupForGenericFilters);

    if (filterModel?.items?.length) {
      const filterGroupForFilterModel = {
        filter_aggr:
          filterModel.booleanOperator ?? FilterNew.BOOLEAN_OPERATOR.AND,
        filter_clauses: [],
      };

      for (const muiDataGridFilterModel of filterModel.items) {
        const {
          filterProps: { fieldBackend, operatorBackend },
          value,
        } = MuiDataGridFilter.getParsedMuiDataGridFilter(
          muiDataGridFilterModel,
        );

        if (!value) {
          continue;
        }

        filterGroupForFilterModel.filter_clauses.push({
          case_sensitive: false,
          comp: operatorBackend,
          name: fieldBackend,
          value,
        });
      }

      mappedFilterGroups.push(filterGroupForFilterModel);
    }

    return {
      filter_config: {
        filter_aggr: filterAggregator,
        filter_groups: mappedFilterGroups,
      },
    };
  }

  getFilterGroupForPermittedSitesAndCostCenters(
    permittedToSites,
    permittedCostCenters,
  ) {
    const filterClauses = [];

    if (permittedToSites.length > 0) {
      filterClauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.CONFIRMED_SITE_ID,
        value: permittedToSites,
      });
    }

    if (permittedCostCenters.length > 0) {
      filterClauses.push({
        comp: BackendFilter.OPERATOR.IS_ANY_OF,
        name: BackendFilter.FILTER.CONFIRMED_ACCOUNTING_REFERENCE_ID,
        value: permittedCostCenters,
      });
    }

    return {
      filter_aggr: FilterNew.BOOLEAN_OPERATOR.OR,
      filter_clauses: filterClauses,
    };
  }

  runDlnUpdater() {
    axios
      .get(couchbaseSyncgatewayUrl + '/_changes', {
        params: {
          feed: 'longpoll',
          include_docs: true,
          since: this.dlnCursor,
        },
      })
      .then(async (response) => {
        const data = response.data;
        this.dlnCursor = data.last_seq;

        if (!data.results) {
          setTimeout(() => {
            this.runDlnUpdater();
          }, waitTime);

          return;
        }

        const assetMains = data.results
          .filter((item) => item.doc?.document_type === 'asset_main')
          .map((item) => item.doc);

        const [deliveryNotes, error] = await promiseHandler(
          this.initDlns(assetMains),
        );

        if (deliveryNotes?.length) {
          store.dispatch(updateDeliveryNotes(deliveryNotes));
        }

        // Prevent that too many dlns are loaded into the frontend.
        // Thus, throw away the oldest ones and update the archive mode.
        if (
          store.getState().deliveryNotes.deliveryNotes.length >
          this.getMaxDlnCount() * 1.1
        ) {
          const dlnsToBeRemoved = this.getMaxDlnCount() * 0.1;
          const newDeliveryNotes = ArrayUtils.removeLastNElements(
            store.getState().deliveryNotes.deliveryNotes,
            dlnsToBeRemoved,
          );

          store.dispatch(replaceDeliveryNotes(newDeliveryNotes));

          this.setArchiveMode(newDeliveryNotes.at(-1));
        }

        // all dln changes of one changes feed response are grouped in this array and then displayed as one toast
        const dlnChanges = [];

        for (const item of data.results) {
          // previous items that are sent again are recognized by old created_on timestamp (time based filter shouldn't be needed)
          // let itemIsRecent = (new Date().getTime() - (new Date(item.doc.created_on).getTime())) < 10000;

          const itemIsLatestRevision = item.doc?._rev.startsWith('1-');
          const itemIsCompleteChange = !item.seq?.toString().includes(':');
          const itemIsAssetChain = item.doc?.document_type === 'asset_chain';

          // if(itemIsAssetChain && itemIsLatestRevision && itemIsCompleteChange && itemIsRecent) {
          if (
            itemIsAssetChain &&
            itemIsLatestRevision &&
            itemIsCompleteChange
          ) {
            const message = new DeliveryNoteAction(item.doc).action;
            const id = item.doc.asset_id ? item.doc.asset_id : item.doc.id;
            let dlnNr = item.doc.body?.header?.id;
            dlnNr = dlnNr
              ? dlnNr
              : store
                  .getState()
                  .deliveryNotes.deliveryNotes.find((dln) => dln.id === id)
                  ?.number;
            dlnNr = dlnNr ? dlnNr : 'Hier klicken';

            let changeIsNew = true;
            for (const change of dlnChanges) {
              if (change.message === message) {
                change.dlns.push({ dlnNr, id });
                changeIsNew = false;
              }
            }

            if (changeIsNew) {
              dlnChanges.push({
                dlns: [
                  {
                    dlnNr,
                    id,
                  },
                ],
                message,
              });
            }
          }
        }

        for (const change of dlnChanges) {
          const key = Object.keys(DeliveryNoteAction.ACTION).find(
            (x) => DeliveryNoteAction.ACTION[x].STRING === change.message,
          );

          // only display certain changes to prevent spam
          if (DeliveryNoteAction.ACTION[key].SHOW_TOAST) {
            ToastService.dlnInfo(
              [change.message],
              change.dlns,
              change.message === DeliveryNoteAction.ACTION.CREATED.STRING,
            );
          }
        }

        this.dlnErrorCount = 0;

        setTimeout(() => {
          this.runDlnUpdater();
        }, waitTime);
      })
      .catch((error) => {
        Log.error('Failed to load delivery notes from changes feed.', error);
        Log.productAnalyticsEvent(
          'Failed to load delivery note updates',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );

        this.dlnErrorCount++;

        if (this.dlnErrorCount > maxErrorCount) {
          return;
        }

        setTimeout(() => {
          this.runDlnUpdater();
        }, onErrorWaitTime);
      });
  }

  setArchiveMode(deliveryNote) {
    // Set the date range for the archive mode from the DLN at its boundary
    const initialDateRange = [new Date(deliveryNote.dlnDate), new Date()];
    store.dispatch(setOldestFilteredDlnDate(deliveryNote.dlnDate));

    const dashboardDateRange =
      store.getState().filters.dashboard_selectedDateRange;
    if (
      dateUtils.isDateOlderThanDate(dashboardDateRange[0], initialDateRange[0])
    ) {
      store.dispatch(setDashboard_selectedDateRange(initialDateRange));
      store.dispatch(setDashboard_individualDateRange(true));
    }

    const deliveryDateRange = store.getState().filters.delivery_dateRange;
    if (
      dateUtils.isDateOlderThanDate(deliveryDateRange[0], initialDateRange[0])
    ) {
      store.dispatch(
        setDelivery_dateRange({
          dateRange: initialDateRange,
          updateCookie: true,
        }),
      );
      store.dispatch(setDelivery_individualDateRange(true));
    }

    // If the user is not allowed to access the archive, we need to reset the selected date range in the home screen
    // so that no archive date range is selected after the init of the dlns.
    const homeDateRange = store.getState().filters.home_selectedDateRange;
    if (
      dateUtils.isDateOlderThanDate(homeDateRange[0], initialDateRange[0]) &&
      !UserUtils.isArchiveModeAllowedUser()
    ) {
      store.dispatch(setHome_selectedDateRange(initialDateRange));
      store.dispatch(setHome_individualDateRange(true));
    }
  }

  removeArchiveMode() {
    store.dispatch(setOldestFilteredDlnDate(null));
  }

  // search for dln in store. if not found, load from backend
  async getDeliveryNoteById(dlnId, ignoreCache) {
    if (!ignoreCache) {
      const deliveryNote = store
        .getState()
        .deliveryNotes?.deliveryNotes?.find(({ id }) => id === dlnId);
      if (deliveryNote) {
        return ES6ClassFactory.convertToES6Class(
          [deliveryNote],
          new DeliveryNote(),
        )[0];
      }
    }

    const [response, error] = await promiseHandler(
      this.getDeliveryNote(dlnId, ignoreCache),
    );

    if (error) {
      throw error;
    }

    const deliveryNote = new DeliveryNote(response);

    if (ignoreCache) {
      store.dispatch(updateDeliveryNotes([deliveryNote]));
    }

    return deliveryNote;
  }

  async getDeliveryNote(dlnId, ignoreCache) {
    const url = apiUrl + '/asset/delivery_note/' + dlnId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then(({ data, status }) => {
        if (status !== 200) {
          Log.warn(
            'GET /delivery_note did not return 200',
            { status_code: status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }

        CacheService.setCached(url, data);

        return data;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  async getDeliveryNotesByIds(dlnIds, ignoreCache) {
    const deliveryNotes = [];

    if (!ignoreCache) {
      const deliveryNotesFromStore = store
        .getState()
        .deliveryNotes?.deliveryNotes?.filter((dln) => dlnIds.includes(dln.id));
      deliveryNotes.push(
        ...ES6ClassFactory.convertToES6Class(
          deliveryNotesFromStore,
          new DeliveryNote(),
        ),
      );
    }

    const missingDlnIds = ArrayUtils.subtract(
      dlnIds,
      deliveryNotes.map(({ id }) => id),
    );

    if (missingDlnIds.length > 0) {
      const [response, error] = await promiseHandler(
        this.getDeliveryNotes(missingDlnIds),
      );

      if (error) {
        throw error;
      }

      deliveryNotes.push(
        ...response.assets.map((dln) => new DeliveryNote(dln)),
      );
    }

    if (ignoreCache) {
      store.dispatch(updateDeliveryNotes(deliveryNotes));
    }

    return deliveryNotes;
  }

  async getDeliveryNotes(ids) {
    return axios
      .get(apiUrl + '/asset/delivery_note/ids', {
        params: { ids },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      .then((response) => {
        if (response.status !== 200) {
          Log.warn(
            'GET /delivery_note/ids did not return 200',
            { status_code: response.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }

        return response.data;
      });
  }

  async getDeliveryNoteChain(chainId) {
    const cachedChain = this.cachedChainsByDlns.find(
      (chain) => chain._id === chainId,
    );
    if (cachedChain) {
      return cloneDeep(cachedChain);
    }

    const url = apiUrl + '/asset/delivery_note/chain/' + chainId;

    const [cachedValue, error] = CacheService.getCached(url);
    if (cachedValue) {
      return cachedValue;
    }

    if (error) {
      throw error;
    }

    return axios
      .get(url)
      .then(({ response }) => {
        if (response?.status !== 200) {
          Log.warn('GET /delivery_note/chain did not return 200', {
            status: response?.status,
          });
        }

        CacheService.setCached(url, response?.data);
        return response?.data;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  async getDeliveryNoteChainsByDlnIds(dlnIds) {
    const cachedResponse = [];
    const uncachedDlnIds = [];

    for (const dlnId of dlnIds) {
      const cached = this.cachedChainsByDlns.filter(
        ({ asset_id }) => asset_id === dlnId,
      );

      if (cached.length > 0) {
        cachedResponse.push(...cached);
      } else {
        uncachedDlnIds.push(dlnId);
      }
    }

    if (uncachedDlnIds.length === 0) {
      return cachedResponse;
    }

    const query = uncachedDlnIds.map((id) => 'ids=' + id).join('&');

    return axios
      .get(apiUrl + '/asset/delivery_note/ids/chains?' + query)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn('GET /delivery_note/ids/chains did not return 200', {
            status: response?.status,
          });
        }

        this.cachedChainsByDlns.push(...response?.data?.assets);

        return cloneDeep([...cachedResponse, ...response?.data?.assets]);
      });
  }

  async createDeliveryNote(body) {
    return axios.post(apiUrl + '/asset/manual', body);
  }

  async createDeliveryNoteVestigasFormat(body) {
    return axios.post(apiUrl + '/asset/vestigas_dln', body);
  }

  async createDeliveryNoteVestigasFormat_enhanced(body) {
    Log.info(
      'Upload delivery note as JSON',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );

    const [response, error] = await promiseHandler(
      this.createDeliveryNoteVestigasFormat(body),
    );

    if (error) {
      Log.productAnalyticsEvent(
        'Failed to upload invalid JSON',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      Log.error('Failed to upload JSON.', error);
      ToastService.httpError(
        ['Lieferung konnte nicht hochgeladen werden.'],
        error.response,
        null,
        true,
      );
      throw error;
    }

    ToastService.success(['Lieferung erstellt.']);
  }

  uploadJson = async (file, createDeliveryNoteCallback) => {
    Log.productAnalyticsEvent('Upload JSON', Log.FEATURE.CREATE_DELIVERY_NOTE);

    const [string, error] = await promiseHandler(
      FileReaderUtils.readFileAsTextAsync(file),
    );

    if (error) {
      Log.error('Error reading JSON file.', error);
      Log.productAnalyticsEvent(
        'Failed to upload JSON',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      ToastService.error(['Lieferung konnte nicht hochgeladen werden.']);
    }

    try {
      createDeliveryNoteCallback(JSON.parse(string));
    } catch (error) {
      Log.error('Error reading JSON file.', error);
      Log.productAnalyticsEvent(
        'Failed to upload JSON',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      ToastService.error(['Lieferung konnte nicht hochgeladen werden.']);
    }

    const fileInput = document.querySelector('#input-json-upload');
    if (fileInput) {
      fileInput.value = '';
    } else {
      console.error('fileInput with ID input-json-upload not found.');
    }
  };

  async validateDeliveryNoteVestigasFormat(body) {
    return axios.post(apiUrl + '/asset/validate', body).then((response) => {
      return response.data.detail.map(
        (item) => new DeliveryNoteValidationResult(item),
      );
    });
  }

  async getAllTemplates() {
    return axios
      .get(apiUrl + '/dln_template')
      .then(({ data }) => data.delivery_notes);
  }

  async createTemplate(name, company_id, body) {
    return axios.post(apiUrl + '/dln_template', body, {
      params: { company_id, name },
    });
  }

  async updateTemplate(name, template_id, body) {
    return axios.put(apiUrl + '/dln_template', body, {
      params: { name, template_id },
    });
  }

  async deleteTemplate(template_id) {
    return axios.delete(apiUrl + '/dln_template/delete', {
      params: { template_id },
    });
  }

  async getAllArticleTemplates(company_id) {
    return axios
      .get(apiUrl + '/article_master/all', { params: { company_id } })
      .then(({ data }) => data.articles);
  }

  async updateArticleTemplate(company_id, body) {
    return axios.put(apiUrl + '/article_master', body, {
      params: { company_id },
    });
  }

  async getAllowedCompaniesForDeliveryNoteCreation(company_type) {
    return axios
      .get(apiUrl + '/company/allowed_companies', { params: { company_type } })
      .then(({ data }) => {
        const initCompany = (company) => new Company(company);

        return data.companies.map(initCompany);
      });
  }

  async getBulkAllowedCompaniesForDeliveryNoteCreation() {
    return axios
      .get(apiUrl + '/company/bulk_allowed_companies')
      .then((response) => {
        if (!response.data.allowed_companies) {
          return {
            buyers: [],
            carriers: [],
            recipients: [],
            sellers: [],
            suppliers: [],
          };
        }

        const { buyer, carrier, recipient, seller, supplier } =
          response.data.allowed_companies;
        const initCompany = (company) => new Company(company);

        return {
          buyers: buyer?.companies?.map(initCompany) ?? [],
          carriers: carrier?.companies?.map(initCompany) ?? [],
          recipients: recipient?.companies?.map(initCompany) ?? [],
          sellers: seller?.companies?.map(initCompany) ?? [],
          suppliers: supplier?.companies?.map(initCompany) ?? [],
        };
      });
  }

  // get the correct CSS class for the corresponding status
  switchClassName(parameter) {
    const status = Object.keys(DeliveryNote.PROCESS_STATE).find(
      (x) => DeliveryNote.PROCESS_STATE[x].STRING === parameter,
    );

    if (!status) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid process state: ' + parameter),
      );
      return DeliveryNote.PROCESS_STATE.DEFAULT.CLASS;
    }

    return DeliveryNote.PROCESS_STATE[status].CLASS;
  }

  /**
   * Initializes delivery notes based on the provided assetMains.
   *
   * @param {Array} assetMains - The array of assetMains to initialize delivery notes from.
   * @returns {Array} An array of initialized delivery notes.
   */
  async initDlns(assetMains) {
    const deliveryNotes = [];

    for (const assetMain of assetMains) {
      try {
        deliveryNotes.push(new DeliveryNote(assetMain));
      } catch (error) {
        Log.error(
          'Failed to initialize delivery note. id: ' + assetMain?._id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to initialize delivery note',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
      }
    }

    let siteIds = [];
    let costCenterIds = [];
    let userIds = [];
    let customFieldIds = [];

    for (const deliveryNote of deliveryNotes) {
      siteIds.push(deliveryNote.toSiteRecipient.id);
      siteIds.push(...deliveryNote.permittedToSites.map((site) => site.id));
      costCenterIds.push(
        ...deliveryNote.permittedCostCenters.map((costCenter) => costCenter.id),
      );
      userIds.push(
        ...ObjectUtils.entries(deliveryNote.processStateChangeUser).map(
          (entry) => entry.value.id,
        ),
      );
      customFieldIds.push(...deliveryNote.getCustomFieldIds());
    }

    // Need to filter out 10003 manually because in dev - polier-dev there is a corrupt dln with this site id.
    siteIds = ArrayUtils.removeDuplicates(siteIds).filter(
      (siteId) => siteId && siteId !== '10003',
    );
    costCenterIds = ArrayUtils.removeDuplicates(costCenterIds).filter(Boolean);
    userIds = ArrayUtils.removeDuplicates(userIds).filter(Boolean);
    customFieldIds = ArrayUtils.removeDuplicates(customFieldIds);

    const sitesBulkPromise =
      siteIds.length && SiteService.loadSitesBulk(siteIds);
    const costCentersBulkPromise =
      costCenterIds.length &&
      CostCenterService.loadCostCentersBulk(costCenterIds);
    const usersBulkPromise =
      userIds.length && UserService.loadUsersBulk(userIds);
    const customFieldsBulkPromise =
      customFieldIds.length &&
      CustomFieldService.loadCustomFieldsBulk(customFieldIds);

    const [response, error] = await promiseHandler(
      PromiseUtils.allResolved(
        [
          sitesBulkPromise,
          costCentersBulkPromise,
          usersBulkPromise,
          customFieldsBulkPromise,
        ].filter(Boolean), // Remove requests without payload
      ),
    );

    if (error) {
      Log.error(
        'Failed to load sites, cost centers, users or custom fields for delivery notes.',
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load sites, cost centers, users or custom fields for delivery notes',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      throw error;
    }

    for (const [index, deliveryNote] of deliveryNotes.entries()) {
      try {
        const [, error_] = await promiseHandler(
          deliveryNote.asyncInitWithBulkEntities(),
        );

        if (error_) {
          Log.error(
            'Failed to initialize async data of delivery note. id: ' +
              assetMains[index]?._id,
            error_,
          );
          Log.productAnalyticsEvent(
            'Failed to initialize enhanced delivery note data',
            Log.FEATURE.DELIVERY_NOTE,
            Log.TYPE.ERROR,
          );
        }

        deliveryNote.initUserActions();
      } catch (error) {
        Log.error(
          'Failed to initialize delivery note. id: ' + assetMains[index]?._id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to initialize delivery note',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
      }
    }

    return deliveryNotes;
  }

  getDeliveryNoteForCreationForm() {
    return this.deliveryNoteForCreationForm;
  }

  setDeliveryNoteForCreationForm(deliveryNote) {
    this.deliveryNoteForCreationForm = cloneDeep(deliveryNote);
  }

  refreshTemplates = async () => {
    store.dispatch(setDeliveryNoteTemplatesLoading(LOADING_STATE.LOADING));

    const [templates, error] = await promiseHandler(this.getAllTemplates());

    if (error) {
      store.dispatch(setDeliveryNoteTemplatesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load delivery note templates.', error);
      Log.productAnalyticsEvent(
        'Failed to load templates',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceDeliveryNoteTemplates(templates));
  };

  async shareDeliveryNote(deliveryNoteId, share_user_id) {
    return axios.post(apiUrl + '/asset/' + deliveryNoteId + '/share', null, {
      params: { share_user_id },
    });
  }

  async getUsersOfSharedDeliveryNote(deliveryNoteId) {
    return axios
      .get(apiUrl + '/asset/' + deliveryNoteId + '/share')
      .then((response) => response.data.ids);
  }

  async mapDirectDeliveryNotes(
    deliveryNoteId,
    siteIds,
    costCenterIds,
    replaceSites,
    replaceCostCenters,
  ) {
    return axios.put(apiUrl + '/asset/' + deliveryNoteId + '/direct_mapping', {
      acc_refs: costCenterIds,
      replace_after_signing: false,
      replace_cc_fields: replaceCostCenters,
      replace_pr_fields: replaceSites,
      sites: siteIds, // Keep sites and cost centers for already signed dlns.
    });
  }

  async removeDeliveryNoteMapping(deliveryNoteId) {
    return axios.put(apiUrl + '/asset/' + deliveryNoteId + '/direct_mapping', {
      acc_refs: [],
      replace_after_signing: false,
      replace_cc_fields: true,
      replace_pr_fields: true,
      sites: [], // Keep sites and cost centers for already signed dlns.
    });
  }

  getUnassignedDeliveryRows(deliveryRows) {
    return deliveryRows.filter(
      ({ permittedCostCenters, permittedToSites, toSiteRecipient }) =>
        !toSiteRecipient && !permittedToSites && !permittedCostCenters,
    );
  }

  isArchiveMode(dateRange, defaultIsArchiveMode = false) {
    const dlnDate = store.getState().filters.oldestFilteredDlnDate;

    if (!dateRange[0] || !dlnDate) {
      return defaultIsArchiveMode;
    }

    return moment(dateRange[0]).isBefore(dlnDate);
  }
}

export default new DeliveriesService();
