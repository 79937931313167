import clsx from 'clsx';
import React, { memo } from 'react';
import { Button } from '@mui/material';
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material';

type P = {
  isCollapsed: boolean;
  toggleState: () => void;
  withTooltip: (
    element: React.ReactElement,
    tooltip: string,
  ) => React.ReactElement;
};

export const ExpandButton = memo(
  ({ isCollapsed, toggleState, withTooltip }: P) => {
    const button = (
      <div className="mt-auto h-12 w-full flex-shrink-0">
        <Button
          variant="text"
          color="inherit"
          size="large"
          startIcon={
            <KeyboardArrowLeftIcon
              className={clsx('transition-transform', {
                'rotate-180': isCollapsed,
              })}
            />
          }
          onMouseDown={toggleState}
          className="h-full w-full hover:bg-[#12306d]"
        >
          <span
            className={clsx({
              hidden: isCollapsed,
            })}
          >
            Einklappen
          </span>
        </Button>
      </div>
    );

    return withTooltip(button, 'Ausklappen');
  },
);
