import { VerifiedUser as VerifiedUserIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { MissingPermissionsTooltip } from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

export const UpdatePermissionsButton = ({ onClick }) => {
  const canUpdatePermissions = UserUtils.isPermissionGrantAllowedUser();

  const CreateButton = (
    <Button
      className={
        canUpdatePermissions ? 'primary-button' : 'disabled-primary-button'
      }
      onClick={onClick}
      startIcon={<VerifiedUserIcon />}
      disabled={!canUpdatePermissions}
    >
      Berechtigungen anpassen
    </Button>
  );

  if (canUpdatePermissions) {
    return CreateButton;
  }

  return <MissingPermissionsTooltip>{CreateButton}</MissingPermissionsTooltip>;
};
