import { useCallback, useEffect, useState, useMemo } from 'react';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

export const useSettingsTableLEGACY = ({
  entity,
  hasActiveStateFilter,
  isMigratedToServerSidePagination,
  items,
  onSearchStringChange,
  refreshData,
  rows,
  sortBy,
}) => {
  const [state, setState] = useState({
    activeStateFilter: true,
    excelData: [],
    filteredRows: [],
    formType: 'create',
    freeTextFilter: '',
    isFormOpen: false,
    item: null,
    rowSelectionModel: [],
    sortModel: [
      {
        field: sortBy ?? 'name',
        sort: 'asc',
      },
    ],
    updatedCompanies: [],
    updatedCostCenters: [],
    updatedOrganisationalGroups: [],
    updatedSites: [],
    updatedUserGroups: [],
    updatedUsers: [],
    updatedVehicles: [],
  });

  const filterRows = useCallback(() => {
    if (isMigratedToServerSidePagination) {
      // Don't compute anything if the table is migrated to server-side pagination and we are not using this legacy code
      return;
    }

    const doFilterRows = (activeStateFilter, freeTextFilter) => {
      // Legacy fuckup: check if the rows contain a key "active" or "isActive" and set the isActiveKey accordingly
      const isActiveKey =
        rows[0] && Object.keys(rows[0]).includes('active')
          ? 'active'
          : 'isActive';
      const isActiveValue =
        typeof activeStateFilter === 'string'
          ? ''
          : activeStateFilter
            ? 'Ja'
            : 'Nein';

      const filteredRows = hasActiveStateFilter
        ? ArrayUtils.filterByKey(rows, isActiveKey, isActiveValue)
        : rows;

      return filteredRows.filter(({ searchString }) =>
        searchString.includes(freeTextFilter.toLowerCase()),
      );
    };

    setState((previousState) => ({
      ...previousState,
      filteredRows: doFilterRows(
        previousState.activeStateFilter,
        previousState.freeTextFilter,
      ),
    }));
  }, [hasActiveStateFilter, rows]);

  useEffect(() => {
    filterRows();
  }, [filterRows]);

  useEffect(() => {
    filterRows();
  }, [rows, state.freeTextFilter, state.activeStateFilter, filterRows]);

  useEffect(() => {
    setState((previousState) => ({
      ...previousState,
      excelData: previousState.filteredRows.filter(
        ({ id }) => previousState.rowSelectionModel?.includes(id) ?? true,
      ),
    }));
  }, [state.rowSelectionModel, state.filteredRows]);

  const eventHandlers = {
    closeForm: useCallback(() => {
      Log.info(`Close ${entity} form`, null, Log.BREADCRUMB.FORM_CLOSE.KEY);

      setState((previousState) => ({
        ...previousState,
        isFormOpen: false,
      }));
    }, [entity]),
    onOpenForm: useCallback(
      (element) => {
        const item = items?.find(({ id }) => id === element.id) ?? null;
        const action = item ? 'edit' : 'create';

        Log.info(
          `Open ${entity} ${action} form`,
          item,
          Log.BREADCRUMB.FORM_OPEN.KEY,
        );
        Log.productAnalyticsEvent(
          `Open ${entity} ${action} form`,
          Log.FEATURE.SETTINGS_TABLE,
        );

        setState((previousState) => ({
          ...previousState,
          formType: action,
          isFormOpen: true,
          item,
        }));
      },
      [items, entity],
    ),
    onRowClick: useCallback(
      (event) => {
        const item = items.find(({ id }) => id === event.id);
        Log.info(
          `Open ${entity} edit form`,
          item,
          Log.BREADCRUMB.FORM_OPEN.KEY,
        );
        Log.productAnalyticsEvent(
          `Open ${entity} edit form`,
          Log.FEATURE.SETTINGS_TABLE,
        );

        setState((previousState) => ({
          ...previousState,
          item,
        }));
        eventHandlers.openEditForm();
      },
      [entity, items],
    ),
    onRowSelectionModelChange: useCallback(
      (event) => {
        Log.info(
          `Change selection value of ${entity}`,
          {
            from: state.rowSelectionModel,
            to: event,
          },
          Log.BREADCRUMB.SELECTION_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          `(De)select ${entity}`,
          Log.FEATURE.SETTINGS_TABLE,
        );

        setState((previousState) => ({
          ...previousState,
          rowSelectionModel: event,
        }));
      },
      [entity, state.rowSelectionModel],
    ),
    onSortModelChange: useCallback((event) => {
      Log.productAnalyticsEvent('Sort', Log.FEATURE.SETTINGS_TABLE);

      setState((previousState) => ({
        ...previousState,
        sortModel: event,
      }));
    }, []),
    openCreateForm: useCallback(() => {
      Log.info(
        `Open ${entity} create form`,
        null,
        Log.BREADCRUMB.FORM_OPEN.KEY,
      );
      Log.productAnalyticsEvent(
        `Open ${entity} create form`,
        Log.FEATURE.SETTINGS_TABLE,
      );

      setState((previousState) => ({
        ...previousState,
        formType: 'create',
        isFormOpen: true,
        item: null,
      }));
    }, [entity]),
    openEditForm: useCallback(() => {
      setState((previousState) => ({
        ...previousState,
        formType: 'edit',
        isFormOpen: true,
      }));
    }, []),
    setActiveStateFilter: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          activeStateFilter: value,
        })),
      [],
    ),
    setFilteredRows: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          filteredRows: value,
        })),
      [],
    ),
    setFormOpen: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          isFormOpen: value,
        })),
      [],
    ),
    setFormType: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          formType: value,
        })),
      [],
    ),
    setFreeTextFilter: useCallback(
      (value) => {
        setState((previousState) => ({
          ...previousState,
          freeTextFilter: value,
        }));

        if (onSearchStringChange) {
          onSearchStringChange(value);
        }
      },
      [onSearchStringChange],
    ),
    setItem: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          item: value,
        })),
      [],
    ),
    setRowSelectionModel: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          rowSelectionModel: value,
        })),
      [],
    ),
    setSortModel: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          sortModel: value,
        })),
      [],
    ),
  };

  return {
    eventHandlersLEGACY: eventHandlers,
    setStateLEGACY: setState,
    stateLEGACY: state,
  };
};
