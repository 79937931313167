/**
 * Filter operators supported both in the frontend and the backend with their respective names.
 */
const FILTER_OPERATOR_COMMON = {
  CONTAINS: 'contains',
};

/**
 * Filter operators supported in the frontend with their respective names.
 */
export const FILTER_OPERATOR_FRONTEND = {
  ...FILTER_OPERATOR_COMMON,
  AFTER: 'after',
  BEFORE: 'before',
  ENDS_WITH: 'endsWith',
  EQUALS: 'equals',
  IS: 'is',
  IS_ANY_OF: 'isAnyOf',
  IS_EMPTY: 'isEmpty',
  IS_NOT_EMPTY: 'isNotEmpty',
  MATH_EQUALS: '=',
  MATH_GREATER_THAN: '>',
  MATH_GREATER_THAN_OR_EQUALS: '>=',
  MATH_LESS_THAN: '<',
  MATH_LESS_THAN_OR_EQUALS: '<=',
  MATH_NOT_EQUALS: '!=',
  NOT: 'not',
  NOT_EQUALS: 'notEquals',
  ON_OR_AFTER: 'onOrAfter',
  ON_OR_BEFORE: 'onOrBefore',
  STARTS_WITH: 'startsWith',
};

/**
 * Filter operators supported in the backend with their respective names.
 */
export const FILTER_OPERATOR_BACKEND = {
  ...FILTER_OPERATOR_COMMON,
  ENDS_WITH: 'endswith',
  EQUALS: 'eq',
  GREATER_THAN: 'gt',
  GREATER_THAN_OR_EQUALS: 'ge',
  IS_ANY_OF: 'in',
  LESS_THAN: 'lt',
  LESS_THAN_OR_EQUALS: 'le',
  NOT_CONTAINS: 'not_contains',
  NOT_ENDS_WITH: 'not_endswith',
  NOT_EQUALS: 'ne',
  NOT_STARTS_WITH: 'not_startswith',
  STARTS_WITH: 'startswith',
};

export const BOOLEAN_OPERATOR = {
  AND: 'and',
  OR: 'or',
};
