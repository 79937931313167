export const articlesColumns = [
  {
    field: 'articleId',
    headerName: 'Artikel-ID',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'name',
    headerName: 'Name',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'description',
    headerName: 'Beschreibung',
    resizableText: true,
    sortable: true,
    width: 200,
  },
  {
    field: 'ean',
    headerName: 'EAN',
    resizableText: true,
    sortable: true,
    width: 200,
  },
  {
    field: 'isActive',
    headerName: 'Aktiv',
    resizableText: true,
    sortable: true,
    width: 100,
  },
  {
    field: 'virtual',
    headerName: 'Generischer Artikel',
    resizableText: true,
    sortable: true,
    width: 100,
  },
  {
    field: 'equivalentArticles',
    headerName: 'Ersetzbare Artikel',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'access',
    headerName: 'Berechtigung',
    resizableText: true,
    sortable: true,
    width: 150,
  },
  {
    field: 'ownerCompanies',
    headerName: 'Zuordnung zu Firmen',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'filterCompanies',
    headerName: 'Suchbar in Firmen',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'filterSites',
    headerName: 'Suchbar in Standorten',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'filterCostCenters',
    headerName: 'Suchbar in Kostenstellen',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'id',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
];

export const categoriesColumns = [
  {
    field: 'name',
    headerName: 'Name',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'linkedArticles',
    headerName: 'Enthaltene Artikel',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'subCategories',
    headerName: 'Unterkategorien',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'parentCategories',
    headerName: 'Oberkategorien',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'isActive',
    headerName: 'Aktiv',
    resizableText: true,
    sortable: true,
    width: 100,
  },
  {
    field: 'access',
    headerName: 'Berechtigung',
    resizableText: true,
    sortable: true,
    width: 150,
  },
  {
    field: 'ownerCompanies',
    headerName: 'Zuordnung zu Firmen',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'filterCompanies',
    headerName: 'Suchbar in Firmen',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'filterSites',
    headerName: 'Suchbar in Standorten',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'filterCostCenters',
    headerName: 'Suchbar in Kostenstellen',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'id',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
];
