import User from '../masterdata/User';
import UserService from '~/services/user.service';
import { promiseHandler } from '~/utils/promiseHandler';
import CompanyService from '~/services/company.service';
import Company from '../masterdata/Company';
import Log from '~/utils/Log';
import FeatureService from '~/services/feature.service';
import AcceptArticle from '../acceptState/AcceptArticle';
import AcceptStateCalculator from '../acceptState/AcceptStateCalculator';
import SignatureRoles from '../masterdata/SignatureRoles';
import TradeContact from '../masterdata/TradeContact';

export default class DeliveryNoteAction {
  constructor(assetChain, deliveryNote) {
    this.id = assetChain._id;
    this.deliveryNoteId = assetChain?.asset_id;
    this.action = '';
    this.icon = '';
    this.datetime = assetChain.created_on;
    this.user = new User({ id: assetChain.created_by?.split('_')?.pop() });
    this.signatureRole =
      assetChain.signature_role ===
      SignatureRoles.SIGNATURE_ROLE.RECIPIENT.LEGACY_KEY
        ? SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY
        : assetChain.signature_role;
    this.company = this.getCompany(assetChain, deliveryNote);
    this.acceptArticles =
      assetChain?.meta?.accept_event?.accepted_items?.map(
        (acceptedItem) => new AcceptArticle(acceptedItem, assetChain._id),
      ) ?? [];
    this.acceptState =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.acceptArticles.map(({ acceptState }) => acceptState),
      );
    this.signerTradeContact = new TradeContact(
      assetChain?.meta?.accept_event?.signer?.trade_contact,
    );
    this.signerCompany = new Company(
      assetChain?.meta?.accept_event?.signer?.legal_organization,
    );
    this.declinedArticles = this.getDeclinedArticles(deliveryNote);
    this.approvedArticles = this.getApprovedArticles(deliveryNote);
    this.changes = [];
    this.attachments = [];

    this.determineAction(assetChain);
  }

  getDeclinedArticles(deliveryNote) {
    if (!deliveryNote) {
      return [];
    }

    const declinedArticles = [];

    for (const acceptArticle of this.acceptArticles) {
      if (
        acceptArticle.acceptState !==
        AcceptStateCalculator.ACCEPT_STATE.DECLINED
      ) {
        continue;
      }

      const article = deliveryNote.articles?.find(
        (article) =>
          article.documentLogPackagePosition ===
            acceptArticle.documentLogPackagePosition &&
          article.documentLineItemPosition ===
            acceptArticle.documentLineItemPosition,
      );

      if (!article) {
        continue;
      }

      acceptArticle.setUnit(article.amount.unit);

      declinedArticles.push({
        ...article,
        acceptArticle,
      });
    }

    return declinedArticles;
  }

  getApprovedArticles(deliveryNote) {
    if (!deliveryNote) {
      return [];
    }

    const approvedArticles = [];

    for (const acceptArticle of this.acceptArticles) {
      if (
        acceptArticle.acceptState !==
        AcceptStateCalculator.ACCEPT_STATE.APPROVED
      ) {
        continue;
      }

      const article = deliveryNote.articles.find(
        (article) =>
          article.documentLogPackagePosition ===
            acceptArticle.documentLogPackagePosition &&
          article.documentLineItemPosition ===
            acceptArticle.documentLineItemPosition,
      );

      if (!article) {
        continue;
      }

      acceptArticle.setUnit(article.amount.unit);

      approvedArticles.push({
        ...article,
        acceptArticle,
      });
    }

    return approvedArticles;
  }

  determineAction(assetChain) {
    if (assetChain.meta?.action?.accepted) {
      if (this.acceptState === AcceptStateCalculator.ACCEPT_STATE.DECLINED) {
        // diff to mobile
        switch (this.signatureRole) {
          case SignatureRoles.SIGNATURE_ROLE.SUPPLIER.KEY: {
            this.action = DeliveryNoteAction.ACTION.DECLINED_SUPPLIER.STRING;
            this.icon = DeliveryNoteAction.ACTION.DECLINED_SUPPLIER.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.CARRIER.KEY: {
            this.action = DeliveryNoteAction.ACTION.DECLINED_CARRIER.STRING;
            this.icon = DeliveryNoteAction.ACTION.DECLINED_CARRIER.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY: {
            this.action = DeliveryNoteAction.ACTION.DECLINED_RECIPIENT.STRING;
            this.icon = DeliveryNoteAction.ACTION.DECLINED_RECIPIENT.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.KEY: {
            this.action =
              DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_SUPPLIER.STRING;
            this.icon =
              DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_SUPPLIER.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_CARRIER.KEY: {
            this.action =
              DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_CARRIER.STRING;
            this.icon =
              DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_CARRIER.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.KEY: {
            this.action =
              DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_RECIPIENT.STRING;
            this.icon =
              DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_RECIPIENT.ICON;

            break;
          }
          // No default
        }
      } else
        switch (this.signatureRole) {
          case SignatureRoles.SIGNATURE_ROLE.SUPPLIER.KEY: {
            // diff to mobile
            this.action = DeliveryNoteAction.ACTION.CONFIRMED_SUPPLIER.STRING;
            this.icon = DeliveryNoteAction.ACTION.CONFIRMED_SUPPLIER.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.CARRIER.KEY: {
            this.action = DeliveryNoteAction.ACTION.CONFIRMED_CARRIER.STRING;
            this.icon = DeliveryNoteAction.ACTION.CONFIRMED_CARRIER.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY: {
            this.action = DeliveryNoteAction.ACTION.CONFIRMED_RECIPIENT.STRING;
            this.icon = DeliveryNoteAction.ACTION.CONFIRMED_RECIPIENT.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.KEY: {
            this.action =
              DeliveryNoteAction.ACTION.CONFIRMED_ON_BEHALF_SUPPLIER.STRING;
            this.icon =
              DeliveryNoteAction.ACTION.CONFIRMED_ON_BEHALF_SUPPLIER.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_CARRIER.KEY: {
            this.action =
              DeliveryNoteAction.ACTION.CONFIRMED_ON_BEHALF_CARRIER.STRING;
            this.icon =
              DeliveryNoteAction.ACTION.CONFIRMED_ON_BEHALF_CARRIER.ICON;

            break;
          }

          case SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.KEY: {
            this.action =
              DeliveryNoteAction.ACTION.CONFIRMED_ON_BEHALF_RECIPIENT.STRING;
            this.icon =
              DeliveryNoteAction.ACTION.CONFIRMED_ON_BEHALF_RECIPIENT.ICON;

            break;
          }
          // No default
        }
    } else if (assetChain.meta?.action?.arrived || assetChain.meta?.arrived) {
      this.action = DeliveryNoteAction.ACTION.ARRIVED.STRING;
      this.icon = DeliveryNoteAction.ACTION.ARRIVED.ICON;
    } else if (assetChain.meta?.action?.cancelled) {
      this.action = DeliveryNoteAction.ACTION.CANCELLED.STRING;
      this.icon = DeliveryNoteAction.ACTION.CANCELLED.ICON;
    } else if (
      assetChain.meta?.action?.archived !== null &&
      assetChain.meta?.action?.archived !== undefined
    ) {
      if (assetChain.meta?.action?.archived) {
        this.action = DeliveryNoteAction.ACTION.ARCHIVED.STRING;
        this.icon = DeliveryNoteAction.ACTION.ARCHIVED.ICON;
      } else {
        this.action = DeliveryNoteAction.ACTION.UNARCHIVED.STRING;
        this.icon = DeliveryNoteAction.ACTION.UNARCHIVED.ICON;
      }
    } else if (
      assetChain.meta?.share &&
      Object.keys(assetChain.meta?.share).length > 0
    ) {
      this.action = DeliveryNoteAction.ACTION.SHARED.STRING;
      this.icon = DeliveryNoteAction.ACTION.SHARED.ICON;
    } else if (assetChain.body?.context) {
      if (assetChain.id_prev_item) {
        this.action = DeliveryNoteAction.ACTION.EDITED.STRING;
        this.icon = DeliveryNoteAction.ACTION.EDITED.ICON;
      } else {
        this.action = DeliveryNoteAction.ACTION.CREATED.STRING;
        this.icon = DeliveryNoteAction.ACTION.CREATED.ICON;
      }
    } else {
      this.action = DeliveryNoteAction.ACTION.EDITED.STRING;
      this.icon = DeliveryNoteAction.ACTION.EDITED.ICON;
    }
  }

  getCompany(assetChain, deliveryNote) {
    // if the asset creator has created the chain, take the issuer as fallback
    if (this.user.isAssetCreator()) {
      return new Company({ name: deliveryNote?.issuer?.name });
    }

    return new Company();
  }

  setChanges(deliveryNote) {
    deliveryNote.setChanges();

    for (const change of deliveryNote.changes) {
      const latestValue = change.history.at(-1);
      const priorValue =
        change.history.length > 1 ? change.history.at(-2) : change.initial;

      if (latestValue.datetime !== this.datetime) {
        continue;
      }

      this.changes.push({
        formatter: change.formatter,
        name: change.name,
        newValue: latestValue.value,
        oldValue: priorValue.value,
      });
    }
  }

  async initCompany() {
    // As the user of a dln creation is the System User Asset Creator, there is no company which leads to 404 error.
    // Therefore, don't call getUserCompany in this case.
    if (this.user.isAssetCreator()) {
      return;
    }

    const [company, error] = await promiseHandler(
      UserService.getUserCompany(this.user.id),
    );
    if (error) {
      throw error;
    }

    this.company = company;
  }

  async initUser() {
    // no use in loading the usernames if they aren't displayed anyway
    if (!FeatureService.showUserInDlnHistory()) {
      return;
    }

    // for the asset creator we will not find any specific information in the backend
    if (this.user.isAssetCreator()) {
      return;
    }

    // this case shouldn't happen because this.initCompany() is already called directly before in DeliveryNoteHistory
    if (!this.company.id) {
      const [response, error] = await promiseHandler(this.initCompany());
      if (error) {
        throw error;
      }
    }

    const [companyAccount, error2] = await promiseHandler(
      UserService.getCompanyAccount(),
    );
    if (error2) {
      throw error2;
    }

    // Due to data privacy we decided that if the company account of the logged-in user and the creator of the delivery note action are different, no user data should be loaded and displayed.
    if (companyAccount.id !== this.company.companyAccount) {
      return;
    }

    const [user, error3] = await promiseHandler(
      UserService.getUserById(this.user.id),
    );
    if (error3) {
      throw error3;
    }

    if (user) {
      this.user = user;
      return;
    }

    // If UserService.getUserById doesn't return the user, load them from CompanyService.getEmployeesOfCompany as fallback.
    // This is a dirty workaround because users can't see anymore who signed dlns if they are not permitted to see users as UserService.getUserById is now checking permissions.
    const [usersFromEmployees, error4] = await promiseHandler(
      CompanyService.getEmployeesOfCompany(this.company.id, true),
    );
    if (error4) {
      throw error4;
    }

    const userFromEmployees = usersFromEmployees.find(
      (item) => item.id === this.user.id,
    );

    if (!userFromEmployees) {
      Log.error(
        'Failed to find user in list of company employees. userId: ' +
          this.user.id,
      );
      return;
    }

    this.user = userFromEmployees;
  }

  static isOnBehalfAction(action) {
    return (
      action ===
        DeliveryNoteAction.ACTION.CONFIRMED_ON_BEHALF_SUPPLIER.STRING ||
      action === DeliveryNoteAction.ACTION.CONFIRMED_ON_BEHALF_CARRIER.STRING ||
      action ===
        DeliveryNoteAction.ACTION.CONFIRMED_ON_BEHALF_RECIPIENT.STRING ||
      action === DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_SUPPLIER.STRING ||
      action === DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_CARRIER.STRING ||
      action === DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_RECIPIENT.STRING
    );
  }

  static ACTION = {
    ARCHIVED: {
      ICON: 'archive',
      STRING: 'Lieferung archiviert',
    },
    ARRIVED: {
      ICON: 'arrow_forward',
      SHOW_TOAST: true,
      STRING: 'Lieferung am Ziel angekommen',
    },
    CANCELLED: {
      ICON: 'cancel',
      SHOW_TOAST: true,
      STRING: 'Lieferung storniert',
    },
    CONFIRMED_CARRIER: {
      ICON: 'check',
      SHOW_TOAST: true,
      STRING: 'Lieferung bestätigt (Spediteur)',
    },
    CONFIRMED_ON_BEHALF_CARRIER: {
      ICON: 'check',
      SHOW_TOAST: true,
      STRING: 'Lieferung bestätigt (Bildschirmunterschrift für Spediteur)',
    },
    CONFIRMED_ON_BEHALF_RECIPIENT: {
      ICON: 'done_all',
      SHOW_TOAST: true,
      STRING: 'Lieferung bestätigt (Bildschirmunterschrift für Abnehmer)',
    },
    CONFIRMED_ON_BEHALF_SUPPLIER: {
      ICON: 'check',
      SHOW_TOAST: true,
      STRING: 'Lieferung bestätigt (Bildschirmunterschrift für Lieferant)',
    },
    CONFIRMED_RECIPIENT: {
      ICON: 'done_all',
      SHOW_TOAST: true,
      STRING: 'Lieferung bestätigt (Abnehmer)',
    },
    CONFIRMED_SUPPLIER: {
      ICON: 'check',
      SHOW_TOAST: true,
      STRING: 'Lieferung bestätigt (Lieferant)',
    },
    CREATED: {
      ICON: 'create_new_folder',
      SHOW_TOAST: true,
      STRING: 'Lieferung erstellt',
    },
    DECLINED_CARRIER: {
      ICON: 'declined_carrier',
      SHOW_TOAST: true,
      STRING: 'Lieferung abgelehnt (Spediteur)',
    },
    DECLINED_ON_BEHALF_CARRIER: {
      ICON: 'declined_on_behalf_carrier',
      SHOW_TOAST: true,
      STRING: 'Lieferung abgelehnt (Bildschirmunterschrift für Spediteur)',
    },
    DECLINED_ON_BEHALF_RECIPIENT: {
      ICON: 'declined_on_behalf_recipient',
      SHOW_TOAST: true,
      STRING: 'Lieferung abgelehnt (Bildschirmunterschrift für Abnehmer)',
    },
    DECLINED_ON_BEHALF_SUPPLIER: {
      ICON: 'declined_on_behalf_supplier',
      SHOW_TOAST: true,
      STRING: 'Lieferung abgelehnt (Bildschirmunterschrift für Lieferant)',
    },
    DECLINED_RECIPIENT: {
      ICON: 'declined_recipient',
      SHOW_TOAST: true,
      STRING: 'Lieferung abgelehnt (Abnehmer)',
    },
    DECLINED_SUPPLIER: {
      ICON: 'declined_supplier',
      SHOW_TOAST: true,
      STRING: 'Lieferung abgelehnt (Lieferant)',
    },
    EDITED: {
      ICON: 'create',
      SHOW_TOAST: true,
      STRING: 'Lieferung bearbeitet',
    },
    SHARED: {
      ICON: 'share',
      STRING: 'Lieferung geteilt',
    },
    UNARCHIVED: {
      ICON: 'archive',
      STRING: 'Lieferung unarchiviert',
    },
  };
  static PROPERTY = {
    ACTION: {
      KEY: 'action',
      STRING: 'Aktion',
    },
    COMPANY: {
      KEY: 'company',
      STRING: 'Benutzerorganisation',
    },
    DATETIME: {
      KEY: 'datetime',
      STRING: 'Zeitpunkt',
    },
    ICON: {
      KEY: 'icon',
      STRING: '',
    },
    USER: {
      KEY: 'user',
      STRING: 'Benutzer',
    },
  };
}
