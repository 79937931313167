import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    '& *': {
      boxSizing: 'border-box',
      fontFamily: 'Inter',
    },
    height: '100%',
    overflow: 'hidden',

    width: '100%',
  },

  filtersContainer: {
    '& .filter-item': {
      position: 'relative',
    },
    '& .left-filters': {
      '& .MuiChip-root': {
        '& .MuiChip-avatar': {
          color: '#8D94A8',
          height: 20,
          margin: 0,
          width: 20,
        },
        '& .MuiChip-label': {
          color: '#5D647A',
          fontSize: '1rem',
        },
        '&[data-active="true"]': {
          '& .MuiChip-label': {
            color: '#343A4D',
            fontWeight: 600,
          },
          '& .MuiSvgIcon-root': {
            color: '#343A4D ',
          },

          backgroundColor: '#E4E6ED',

          borderColor: '#343A4D',
        },
        borderColor: '#C5C9D6',
        borderRadius: '1.5rem',

        color: '#8D94A8',

        height: '2.5rem',

        padding: `0 0.75rem !important`,
      },
    },
    '& .right-filters': {
      '& .excel-icon': {
        border: '1px solid #C5C9D6',
        borderRadius: '0.5rem',
        height: '3rem',
        width: '3rem',
      },

      '& .form-icon': {
        '& .MuiSvgIcon-root': {
          margin: 0,
        },
        border: '1px solid #C5C9D6',
        borderRadius: '0.5rem',
        height: '3rem',

        width: '3rem',
      },

      '& .MuiButtonBase-root': {
        '& .MuiSvgIcon-root': {
          marginRight: '0.8125rem',
        },
        borderRadius: '0.5rem',
        fontSize: '1rem',
        fontWeight: 600,
        height: 48,

        textTransform: 'unset',
      },
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',

    padding: '2rem',

    paddingBottom: 0,

    position: 'relative',
  },

  kanbanContainer: {
    '& .kanban-divider': {
      height: '3.25rem',
    },
    '& .loading-indicator': {
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.3)',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 99,
    },
    '&.is-loading-indicator': {
      overflow: 'hidden',
    },
    '&::before': {
      background:
        'linear-gradient(360deg,#fff 0,hsla(0,0%,100%,.738) 19%,hsla(0,0%,100%,.541) 34%,hsla(0,0%,100%,.382) 47%,hsla(0,0%,100%,.278) 56.5%,hsla(0,0%,100%,.194) 65%,hsla(0,0%,100%,.126) 73%,hsla(0,0%,100%,.075) 80.2%,hsla(0,0%,100%,.042) 86.1%,hsla(0,0%,100%,.021) 91%,hsla(0,0%,100%,.008) 95.2%,hsla(0,0%,100%,.002) 98.2%,hsla(0,0%,100%,0))',
      bottom: '0',
      content: '""',
      height: '8rem',
      left: '0',
      pointerEvents: 'none',
      position: 'fixed',
      right: '0',
      zIndex: 9,
    },
    display: 'flex',
    flexWrap: 'nowrap',
    height: 'calc(100vh - 165px)',
    overflowX: 'auto',

    overflowY: 'hidden',

    paddingLeft: '1rem',

    paddingTop: '1rem',

    position: 'relative',
  },

  textField: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiInputAdornment-positionEnd': {
      '& .MuiSvgIcon-root': {
        height: '1.5rem',
        margin: `0 !important`,
      },

      color: '#C5C9D6',
    },
    '& .MuiInputBase-root': {
      '&::after': {
        display: 'none',
      },

      '&::before': {
        display: 'none',
      },

      height: '100%',
    },
    backgroundColor: 'white',

    borderRadius: '0.5rem',

    height: '3rem',

    width: 300,
  },
}));
