import clsx from 'clsx';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import Select from '../baseComponents/inputs/select/Select';

import { LOADING_STATE } from '~/constants/LoadingState';
import { Spinner } from '../Spinner';

import { FilterComponent } from './FilterComponent';

const LOADING_PLACEHOLDER = 'loading_placeholder';

export const FilterRow = ({
  disabled,
  hideDeleteButton,
  onChangeProperty,
  onChangeSearchValue,
  onChangeValue,
  onDeleteRow,
  onOpen,
  onScrollToBottom,
  selectableFilters,
  selectedFilter,
  selectedValues,
  testId,
}) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const previousSelectedFilterRef = useRef();
  const previousInputValueRef = useRef();

  // This is used to prevent the input value from being reset when the options are updated.
  const ignoreNextInputChangeRef = useRef(false);

  const initFilterOptions = useCallback(() => {
    const { allOptions, filteredOptions } = selectedFilter;

    // The filtered options are the options that can still be applied after filtering the data.
    const newFilterOptions = [...filteredOptions];

    for (const option of allOptions) {
      if (filteredOptions.includes(option)) {
        continue;
      }

      // If it's not in the filtered options, it cannot be applied to the current filtered data. Append.
      newFilterOptions.push(option);
    }

    setFilterOptions(newFilterOptions);
  }, [selectedFilter]);

  useEffect(() => {
    initFilterOptions();
  }, [initFilterOptions]);

  useEffect(() => {
    // If the input value is the same as the previous one,
    // it means that the component was updated by another state or props.
    // In this case, it might happen that the options are updated and thus the input value should not be reset.
    // If the input value would be reset, the pagination would not work correctly.
    if (inputValue === previousInputValueRef.current) {
      ignoreNextInputChangeRef.current = true;
    }

    if (
      JSON.stringify(selectedFilter.filteredOptions) !==
        JSON.stringify(previousSelectedFilterRef.current?.filteredOptions) ||
      JSON.stringify(selectedFilter.allOptions) !==
        JSON.stringify(previousSelectedFilterRef.current?.allOptions)
    ) {
      initFilterOptions();
    }

    previousSelectedFilterRef.current = selectedFilter;
    previousInputValueRef.current = inputValue;
  }, [selectedFilter, inputValue, initFilterOptions]);

  const getOptions = () => {
    if (
      selectedFilter.loading === LOADING_STATE.LOADING ||
      selectedFilter.loading === LOADING_STATE.FAILED
    ) {
      // Include the input value in the loading placeholder because otherwise if would be filtered out from the options.
      return [LOADING_PLACEHOLDER + inputValue];
    }

    if (
      selectedFilter.paginationLoading === LOADING_STATE.LOADING ||
      selectedFilter.paginationLoading === LOADING_STATE.FAILED
    ) {
      return filterOptions.concat([LOADING_PLACEHOLDER + inputValue]);
    }

    return filterOptions;
  };

  const handleChangeSearchValue = (value) => {
    // In this case, we assume that onChangeSearchValue was triggered by the update of the options.
    // In this case, we do not want to reset the input value because then the pagination would not work correctly.
    if (ignoreNextInputChangeRef.current && value === '') {
      ignoreNextInputChangeRef.current = false;
      return;
    }

    setInputValue(value);
    onChangeSearchValue(value);
  };

  const renderOption = (props, option, { selected }) => {
    const { key, ...rest } = props;

    if (
      option.includes(LOADING_PLACEHOLDER) &&
      (selectedFilter.loading === LOADING_STATE.LOADING ||
        selectedFilter.paginationLoading === LOADING_STATE.LOADING)
    ) {
      return (
        <li key={key} {...rest}>
          <div className="flex w-full items-center justify-center py-1 text-sm">
            <Spinner title="Laden..." />
          </div>
        </li>
      );
    }

    if (
      option.includes(LOADING_PLACEHOLDER) &&
      (selectedFilter.loading === LOADING_STATE.FAILED ||
        selectedFilter.paginationLoading === LOADING_STATE.FAILED)
    ) {
      return (
        <li key={key} {...rest}>
          <div className="flex w-full items-center justify-center py-1 text-sm">
            <div>Daten konnten nicht geladen werden.</div>
          </div>
        </li>
      );
    }

    return (
      <li key={key} {...rest}>
        <div
          className={clsx(
            'flex w-full items-center justify-between py-1 text-sm',
            {
              'text-primary500': selected,
            },
          )}
        >
          <div>{option}</div>
          {selected ? (
            <CheckIcon className="text-primary500" fontSize="small" />
          ) : null}
        </div>
      </li>
    );
  };

  return (
    <div className="flex items-center gap-4" data-testid={testId}>
      <div className="w-80">
        <Select
          data-testid={`${testId}_operator_select`}
          value={selectedFilter.id}
          onChange={onChangeProperty}
          size="small"
          options={selectableFilters}
          className="bg-white"
          fullWidth
        />
      </div>
      <FilterComponent
        testId={`${testId}_filter_operator_value_select`}
        disabled={disabled}
        getOptions={getOptions}
        handleChangeSearchValue={handleChangeSearchValue}
        inputValue={inputValue}
        onChangeValue={onChangeValue}
        onOpen={onOpen}
        onScrollToBottom={onScrollToBottom}
        renderOption={renderOption}
        selectedFilter={selectedFilter}
        selectedValues={selectedValues}
      />
      {hideDeleteButton ? null : (
        <IconButton
          size="small"
          onClick={onDeleteRow}
          data-testid={`${testId}_delete_button`}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};
