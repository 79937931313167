import { useMutation } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

type User = Record<string, unknown>; // TODO

type CreateUserResponse = {
  code: number;
  id: UUID;
  message: string;
};

/**
 * Creates a new user.
 *
 * @param {Object} userData - The user data for creating a new user.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Admin/operation/create_user_admin_users_post
 */
export const createUser = async (
  userData: User,
): Promise<CreateUserResponse> => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.USER.CREATE(), { json: userData })
      .json<CreateUserResponse>();

    return response;
  } catch (error) {
    Log.error('Error creating user', error);

    throw error;
  }
};

/**
 * Custom hook for creating a new user.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationCreateUser = (
  options?: Parameters<typeof useMutation>[0],
) => {
  return useMutation({
    mutationFn: async (userData: User) => createUser(userData),
    onError(error) {
      Log.productAnalyticsEvent(
        'Failed to create user',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    ...options,
  });
};
