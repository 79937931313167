import { useMemo } from 'react';

import PermissionGrant, {
  ENTITY_TYPES,
} from '~/models/masterdata/PermissionGrant';

import UserUtils from '~/utils/userUtils';

import { PermissionGrantTableBase } from './PermissionGrantTableBase';

export const PermissionGrantEntityTable = ({
  defaultEntities,
  defaultEntityType,
  fixedPicker,
  loading,
  permissionGrantsFrom,
  refreshData,
  title,
}) => {
  const rows = useMemo(
    () =>
      permissionGrantsFrom.map(
        ({ id, permissions, subjectId, subjectName, subjectType }) => ({
          defaultRole: permissions.getDefaultRoleName(),
          entityId: subjectId,
          entityName: subjectName,
          entityType: ENTITY_TYPES[subjectType],
          id,
          permissions: {
            permissionGrantId: id,
            permissions,
          },
        }),
      ),
    [permissionGrantsFrom],
  );

  return (
    <PermissionGrantTableBase
      columnVisibilityModel={{ subjectId: UserUtils.isVestigasAccount() }}
      defaultEntities={defaultEntities}
      defaultEntityType={defaultEntityType}
      fixedPicker={fixedPicker}
      loading={loading}
      permissionGrants={permissionGrantsFrom}
      refreshData={refreshData}
      rows={rows}
      title={title}
      type={PermissionGrant.TYPE.ENTITY}
    />
  );
};

PermissionGrantEntityTable.displayName = 'PermissionGrantEntityTable';
