import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';

const customerSubscriptions = createSlice({
  initialState: {
    customerSubscriptions: [],
    customerSubscriptionsLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'customerSubscriptions',
  reducers: {
    replaceCustomerSubscriptions(state, parameter) {
      const { payload } = parameter;

      state.customerSubscriptions = payload;
      state.customerSubscriptionsLoading = LOADING_STATE.SUCCEEDED;
    },
    setCustomerSubscriptionsLoading(state, parameter) {
      const { payload } = parameter;

      state.customerSubscriptionsLoading = payload;
    },
  },
});

const { actions, reducer } = customerSubscriptions;
export const { replaceCustomerSubscriptions, setCustomerSubscriptionsLoading } =
  actions;
export default reducer;
