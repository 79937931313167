import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  fetchCostCenter,
  fetchCostCenters,
  queryKeysCostCenter,
  type CostCenter,
} from '~/data/costCenter';

import UserUtils from '~/utils/userUtils';

import { type Option, SelectServerDriven } from './SelectServerDriven';
import { createLoadOptions, createGetItemData } from './utils';

const getOptionLabel = ({ name }) => name;

type P = {
  filterActive?: boolean;
  formatOptionLabel?: (option: Option<CostCenter>) => React.ReactNode;
  isDisabled?: boolean;
  isMultiSelect?: boolean;
  onChange: (value: Array<{ id: string }>) => void;
  placeholder?: string;
  value: string[];
} & ComponentStyling;

export const MultiSelectCostCenters = ({
  className,
  filterActive = true,
  formatOptionLabel,
  isDisabled = false,
  isMultiSelect = true,
  placeholder = 'Kostenstellen',
  onChange,
  style,
  value,
}: P) => {
  const queryClient = useQueryClient();

  /**
   * Loads the options for the multi-select dropdown.
   * @param {string} searchString - The search string entered by the user.
   * @param {unknown} loadedOptions - The already loaded options.
   * @param {{ page: number }} - The current page number.
   */
  const loadOptions = createLoadOptions<CostCenter>({
    createQueryKey: ({ page, ...queryParams }) =>
      queryKeysCostCenter.getAll(queryParams),
    async fetchItems({ page, ...queryParams }) {
      const { data, hasNextPage } = await fetchCostCenters(queryParams);

      return {
        data,
        hasNextPage,
      };
    },
    filterActive,
    getOptionLabel,
    queryClient,
  });

  /**
   * Gets costCenter data for a costCenter with a given costCenterId.
   * Attempts to get it from the query cache and fetches it from the API
   * if the costCenter data is not in the cache.
   * @param {string} costCenterId - The ID of the costCenter to get.
   */
  const getItemData = createGetItemData<CostCenter>({
    createQueryKey: (costCenterId) => queryKeysCostCenter.get(costCenterId),
    fetchItem: fetchCostCenter,
    queryClient,
    queryKeyBase: [queryKeysCostCenter.base()],
  });

  // This is just a glue function b/c PermissionGrantMultiPicker currently expects to pick the ID from an object in its onChange function. Should be refactored.
  const handleChange = useCallback(
    (newValues: string[] | string, data: CostCenter[] | CostCenter) => {
      onChange(
        Array.isArray(newValues)
          ? newValues.map((id) => ({ id }))
          : { id: newValues },
        data,
      );
    },
    [onChange],
  );

  return (
    <SelectServerDriven<CostCenter>
      value={value}
      onChange={handleChange}
      className={className}
      formatOptionLabel={formatOptionLabel}
      getItemData={getItemData}
      getOptionLabel={getOptionLabel}
      isDisabled={isDisabled}
      isItemSelectionAllowed={UserUtils.isCostCenterReadAllowedUser}
      isMultiSelect={isMultiSelect}
      loadOptions={loadOptions}
      placeholder={placeholder}
      style={style}
    />
  );
};
