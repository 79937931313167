import { createTheme } from '@mui/material/styles';
import { deDE as dataGridDeDE } from '@mui/x-data-grid/locales';
import { deDE as coreDeDE } from '@mui/material/locale';
// import { deDE as datePickerDeDE } from '@mui/x-date-pickers/locales';

export const theme = createTheme(
  {
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // Disable MUI ripple effect for all buttons
        },
      },
      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            shrink: true,
          },
          size: 'small',
        },
      },
    },
  },
  coreDeDE, // core translations
  dataGridDeDE, // x-data-grid translations
  // datePickerDeDE, // x-date-pickers translations
);
