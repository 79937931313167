import { type CustomFieldsQueryParams } from './useQueryCustomFields';

/**
 * Query key factory for customFields-related queries.
 */
export const queryKeysCustomFields = {
  base: () => 'customFields' as const,
  getAll: (queryParams: Partial<CustomFieldsQueryParams>) =>
    [queryKeysCustomFields.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<CustomFieldsQueryParams>) =>
    [queryKeysCustomFields.base(), 'list', 'infinite', queryParams] as const,
  getAllLegacy: () => [queryKeysCustomFields.base(), 'list', 'LEGACY'] as const,
} as const;
