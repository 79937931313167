import UserUtils from '~/utils/userUtils';

import { Spinner } from '~/components/Spinner';

import { NotificationSection } from './NotificationSection';

export const PushNotificationSettings = ({
  isError,
  isLoading,
  onChange,
  onUpdateUser,
  user,
}) => {
  if (!UserUtils.isVestigasAccount() && !UserUtils.isUserWriteAllowedUser()) {
    return null;
  }

  if (isLoading || isError || !user) {
    return (
      <div className="w-full space-y-4">
        <div className="w-full">
          <h3>Push-Benachrichtigungen, wenn Lieferung erstellt wurde</h3>
          <div className="h-12 w-[480px]">
            {isError ? 'Daten konnten nicht geladen werden.' : <Spinner />}
          </div>
        </div>
        <div className="w-full">
          <h3>Push-Benachrichtigungen am Ende des Tages</h3>
          <div className="h-12 w-[480px]">
            {isError ? 'Daten konnten nicht geladen werden.' : <Spinner />}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full space-y-4">
      <NotificationSection
        title="Push-Benachrichtigungen, wenn Lieferung erstellt wurde"
        settingsKey="dlnCreated"
        user={user}
        onChange={onChange}
        onUpdateUser={onUpdateUser}
      />
      <NotificationSection
        title="Push-Benachrichtigungen am Ende des Tages"
        settingsKey="dlnSignReminder"
        user={user}
        onChange={onChange}
        onUpdateUser={onUpdateUser}
      />
    </div>
  );
};
