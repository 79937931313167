import React from 'react';
import { useSelector } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';

import Address from '~/models/masterdata/Address';
import CustomField from '~/models/customData/CustomField';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import ShippingMark from '~/models/deliveries/ShippingMark';
import TradeContact from '~/models/masterdata/TradeContact';
import ValueGroup from '~/models/deliveries/ValueGroup';

import ArrayUtils from '~/utils/arrayUtils';
import { dateUtils } from '~/utils/dateUtils';
import UserUtils from '~/utils/userUtils';

import Attachment from '~/components/attachments/Attachment';
import { InactiveIcon } from '~/components/InactiveIcon';

import { EditingHistorySafe as EditingHistory } from '../EditingHistorySafe';

import DeliveryNoteMetaDataUsers from './DeliveryNoteMetaDataUsers';
import { DeliveryNoteMetaDataItem } from './DeliveryNoteMetaDataItem';

const renderItems = (items) => (
  <>
    {items.map((item, index) => (
      <div key={index}>{item}</div>
    ))}
  </>
);

function DeliveryNoteMetaData(props) {
  const { customFieldsBulk } = useSelector((state) => state.customFields);
  const { userinfo } = useSelector((state) => state.userinfo);

  const isSchickerAccount = UserUtils.isSchickerAccount(
    userinfo.company?.companyAccount,
  );

  const categoryValues = [];

  categoryValues.push(
    {
      category: 'Lieferscheinnummer (LFS-Nr.)',
      value: <EditingHistory value={props?.deliveryNote?.number} />,
    },
    {
      category: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
      value: (
        <EditingHistory value={props?.deliveryNote?.toSiteSupplier?.name} />
      ),
    },
  );

  if (props?.deliveryNote?.toSiteSupplier?.address?.getConcatenatedAddress()) {
    categoryValues.push({
      category: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_ADDRESS.STRING,
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(
            props?.deliveryNote?.toSiteSupplier?.address,
            Address.getConcatenatedAddress,
          )}
        />
      ),
    });
  }

  if (
    props?.deliveryNote?.toSiteSupplier?.tradeContact?.hasContactInformation()
  ) {
    categoryValues.push({
      category: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.STRING,
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(
            props?.deliveryNote?.toSiteSupplier?.tradeContact,
            TradeContact.getConcatenatedContactInformation,
          )}
        />
      ),
    });
  }

  categoryValues.push({
    category: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING,
    value: (
      <EditingHistory value={props?.deliveryNote?.toSiteRecipient?.name} />
    ),
  });

  if (
    userinfo.userFeatureFlags?.accessPermittedSites ||
    UserUtils.isPermittedSiteAllowedUser()
  ) {
    categoryValues.push(
      {
        category: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.STRING,
        value: (() => {
          if (props?.deliveryNote?.permittedToSites?.length === 0) {
            return '-';
          }

          if (
            props?.deliveryNote?.permittedToSites?.every((site) => site.active)
          ) {
            return props?.deliveryNote?.permittedToSites
              ?.map((site) => site.name)
              .join(', ');
          }

          return (
            <div>
              {props?.deliveryNote?.permittedToSites?.map((site) => {
                if (site.active) {
                  return site.name;
                }

                return (
                  <span key={site.name} className="flex-s-c gap-5px">
                    {site.name}
                    <InactiveIcon />
                  </span>
                );
              })}
            </div>
          );
        })(),
      },
      {
        category: DeliveryNote.PROPERTY.PERMITTED_COST_CENTERS.STRING,
        value: (() => {
          if (props?.deliveryNote?.permittedCostCenters?.length === 0) {
            return '-';
          }

          if (
            props?.deliveryNote?.permittedCostCenters?.every(
              (costCenter) => costCenter.active,
            )
          ) {
            return props?.deliveryNote?.permittedCostCenters
              ?.map((costCenter) => costCenter.name)
              .join(', ');
          }

          return (
            <div>
              {props?.deliveryNote?.permittedCostCenters?.map((costCenter) => {
                if (costCenter.active) {
                  return costCenter.name;
                }

                return (
                  <span key={costCenter.name} className="flex-s-c gap-5px">
                    {costCenter.name}
                    <InactiveIcon />
                  </span>
                );
              })}
            </div>
          );
        })(),
      },
      {
        category: 'Berechtigte Benutzer',
        value: (
          <DeliveryNoteMetaDataUsers
            users={
              props.deliveryNote?.permittedUsers
                ?.filter((user) => user.email)
                .map((user) => {
                  return {
                    email: user.email,
                    id: user.id,
                    name: user.getName(),
                  };
                }) ?? []
            }
            deliveryNote={props.deliveryNote}
            usersLoading={props.permittedUsersLoading}
          />
        ),
      },
      {
        category: DeliveryNote.PROPERTY.SIGNATURES_REQUESTED_FROM.STRING,
        value: (
          <DeliveryNoteMetaDataUsers
            users={
              props.deliveryNote?.userActions?.requestSignatures
                ?.filter((userAction) => userAction.userEmail)
                .map((userAction) => {
                  return {
                    email: userAction.userEmail,
                    id: userAction.userId,
                    name: userAction.userName,
                  };
                }) ?? []
            }
            deliveryNote={props.deliveryNote}
            usersLoading={props.requestedUsersLoading}
          />
        ),
      },
      {
        category: DeliveryNote.PROPERTY.DELIVERY_SHARED_WITH.STRING,
        value: (
          <DeliveryNoteMetaDataUsers
            users={
              props.deliveryNote?.userActions?.shareDeliveryNote
                ?.filter((userAction) => userAction.userEmail)
                .map((userAction) => {
                  return {
                    email: userAction.userEmail,
                    id: userAction.userId,
                    name: userAction.userName,
                  };
                }) ?? []
            }
            deliveryNote={props.deliveryNote}
            usersLoading={props.sharedUsersLoading}
          />
        ),
      },
    );
  }

  if (ValueGroup.getCurrentValue(props?.deliveryNote?.constructionPlans)) {
    categoryValues.push({
      category: DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.STRING,
      value: <EditingHistory value={props?.deliveryNote?.constructionPlans} />,
    });
  }

  if (ValueGroup.getCurrentValue(props?.deliveryNote?.constructionComponents)) {
    categoryValues.push({
      category: DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.STRING,
      value: (
        <EditingHistory value={props?.deliveryNote?.constructionComponents} />
      ),
    });
  }

  categoryValues.push(
    {
      category: DeliveryNote.PROPERTY.PROJECT.STRING,
      value: <EditingHistory value={props?.deliveryNote?.project} />,
    },
    {
      category: DeliveryNote.PROPERTY.SELLER_ORDER_REFERENCES.STRING,
      value: (
        <EditingHistory value={props?.deliveryNote?.sellerOrderReferences} />
      ),
    },
    {
      category: DeliveryNote.PROPERTY.BUYER_ORDER_REFERENCES.STRING,
      value: (
        <EditingHistory value={props?.deliveryNote?.buyerOrderReferences} />
      ),
    },
    {
      category: DeliveryNote.PROPERTY.FROM_SITE.STRING,
      value: <EditingHistory value={props?.deliveryNote?.fromSite?.name} />,
    },
  );

  // Group shipping marks by code and sort values alphabetically
  const uniqueShippingMarkCodes = ArrayUtils.removeDuplicates(
    ValueGroup.getCurrentValue(props?.deliveryNote?.shippingMarks)?.map(
      (shippingMark) => ValueGroup.getCurrentValue(shippingMark.code),
    ) ?? [],
  );

  for (const shippingMarkCode of uniqueShippingMarkCodes) {
    let categoryHeader = ShippingMark.getShippingMarkString(shippingMarkCode);

    categoryHeader ||= ShippingMark.FALLBACK_STRING;

    const values = [];

    ValueGroup.getCurrentValue(props?.deliveryNote?.shippingMarks)?.forEach(
      (shippingMark) => {
        if (
          ValueGroup.getCurrentValue(shippingMark.code) !== shippingMarkCode
        ) {
          return;
        }

        values.push(ValueGroup.getCurrentValue(shippingMark.value));
      },
    );

    values.sort((a, b) => a.localeCompare(b));

    categoryValues.push({
      category: categoryHeader,
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(values, () => values.join(', '))}
        />
      ),
    });
  }

  if (props?.deliveryNote?.fromSite?.address?.getConcatenatedAddress()) {
    categoryValues.push({
      category: 'Beladeort (Adresse)',
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(
            props?.deliveryNote?.fromSite?.address,
            Address.getConcatenatedAddress,
          )}
        />
      ),
    });
  }

  if (props?.deliveryNote?.fromSite?.tradeContact?.hasContactInformation()) {
    categoryValues.push({
      category: 'Ansprechperson des Beladeorts',
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(
            props?.deliveryNote?.fromSite?.tradeContact,
            TradeContact.getConcatenatedContactInformation,
          )}
        />
      ),
    });
  }

  categoryValues.push(
    {
      category: DeliveryNote.PROPERTY.MOVEMENT_MEANS.STRING,
      value: <EditingHistory value={props?.deliveryNote?.movementMeans} />,
    },
    {
      category: DeliveryNote.PROPERTY.DELIVERY_TYPE.STRING,
      value: <EditingHistory value={props?.deliveryNote?.deliveryType} />,
    },
    {
      category: 'Lieferscheindatum (LFS-Datum)',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.dlnDate}
          callback={(value) =>
            dateUtils.getFormattedDateWithoutMidnight_safe(
              value,
              isSchickerAccount
                ? dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss
                : dateUtils.DATE_FORMAT.DD_MM_YYYY,
            )
          }
        />
      ),
    },
  );

  const dlnDate = dateUtils.getFormattedDate_safe(
    props?.deliveryNote?.dlnDate,
    dateUtils.DATE_FORMAT.DD_MM_YYYY,
  );
  const creationDate = dateUtils.getFormattedDate_safe(
    props?.deliveryNote?.creationDate,
    dateUtils.DATE_FORMAT.DD_MM_YYYY,
  );

  if (dlnDate !== creationDate) {
    categoryValues.push({
      category: 'Erstellungsdatum (Datensatz)',
      value: creationDate ?? '-',
    });
  }

  if (ValueGroup.getCurrentValue(props?.deliveryNote?.palletAmount) > 0) {
    categoryValues.push({
      category: 'Anzahl Paletten',
      value: <EditingHistory value={props?.deliveryNote?.palletAmount} />,
    });
  }

  if (ValueGroup.getCurrentValue(props?.deliveryNote?.comments)?.length > 0) {
    categoryValues.push({
      category: 'Kommentare',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.comments}
          callback={renderItems}
        />
      ),
    });
  }

  if (ValueGroup.getCurrentValue(props?.deliveryNote?.waitingTime) > 0) {
    categoryValues.push({
      category: 'Wartezeit',
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(
            { waitingTime: props?.deliveryNote?.waitingTime },
            (value) => value.waitingTime + ' Minuten',
          )}
        />
      ),
    });
  }

  if (ValueGroup.getCurrentValue(props?.deliveryNote?.regieTime) > 0) {
    categoryValues.push({
      category: 'Regiezeit',
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(
            { regieTime: props?.deliveryNote?.regieTime },
            (value) => value.regieTime + ' Minuten',
          )}
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(
      props?.deliveryNote?.execution?.beginExecutionPlanned,
    )
  ) {
    categoryValues.push({
      category: 'Beginn Lieferung (Geplant)',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.beginExecutionPlanned}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(
      props?.deliveryNote?.execution?.beginLoadingPlanned,
    )
  ) {
    categoryValues.push({
      category: 'Beginn Beladung (Geplant)',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.beginLoadingPlanned}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(
      props?.deliveryNote?.execution?.endLoadingPlanned,
    )
  ) {
    categoryValues.push({
      category: 'Ende Beladung (Geplant)',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.endLoadingPlanned}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.departurePlanned)
  ) {
    categoryValues.push({
      category: 'Abfahrt (Geplant)',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.departurePlanned}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.arrivedPlanned)
  ) {
    categoryValues.push({
      category: 'Ankunft (Geplant)',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.arrivedPlanned}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(
      props?.deliveryNote?.execution?.beginUnloadingPlanned,
    )
  ) {
    categoryValues.push({
      category: 'Beginn Entladung (Geplant)',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.beginUnloadingPlanned}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(
      props?.deliveryNote?.execution?.endUnloadingPlanned,
    )
  ) {
    categoryValues.push({
      category: 'Ende Entladung (Geplant)',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.endUnloadingPlanned}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(
      props?.deliveryNote?.execution?.endExecutionPlanned,
    )
  ) {
    categoryValues.push({
      category: 'Ende Lieferung (Geplant)',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.endExecutionPlanned}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.beginExecution)
  ) {
    categoryValues.push({
      category: 'Beginn Lieferung',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.beginExecution}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.beginLoading)
  ) {
    categoryValues.push({
      category: 'Beginn Beladung',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.beginLoading}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.endLoading)) {
    categoryValues.push({
      category: 'Ende Beladung',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.endLoading}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.departure)) {
    categoryValues.push({
      category: 'Abfahrt',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.departure}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.arrived)) {
    categoryValues.push({
      category: 'Ankunft',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.arrived}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.beginUnloading)
  ) {
    categoryValues.push({
      category: 'Beginn Entladung',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.beginUnloading}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.endUnloading)
  ) {
    categoryValues.push({
      category: 'Ende Entladung',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.endUnloading}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (
    ValueGroup.getCurrentValue(props?.deliveryNote?.execution?.endExecution)
  ) {
    categoryValues.push({
      category: 'Ende Lieferung',
      value: (
        <EditingHistory
          value={props?.deliveryNote?.execution?.endExecution}
          callback={(value) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
            )
          }
        />
      ),
    });
  }

  if (props?.deliveryNote?.customData?.getLength() > 0) {
    categoryValues.push({
      category: 'Weitere Informationen',
      value: (
        <>
          {props?.deliveryNote?.customData?.getEntries()?.map((entry) => {
            const customFieldId = CustomField.getIdFromKey(entry[0]);
            const customField = customFieldsBulk.find(
              (customField) => customField.id === customFieldId,
            );

            const value =
              typeof entry[1] === 'object' && !ValueGroup.isValueGroup(entry[1])
                ? JSON.stringify(entry[1])
                : entry[1];

            return (
              <div
                key={customFieldId}
                className="flex flex-wrap items-start justify-start"
              >
                <span className="break-words">
                  {customField?.displayName ?? entry[0]}:{' '}
                </span>
                <EditingHistory value={value} />
              </div>
            );
          })}
        </>
      ),
    });
  }

  const deliveryPictures = ArrayUtils.removeDuplicatesByKey(
    props?.deliveryNote?.attachmentHandler?.attachments?.filter((attachment) =>
      attachment.isDeliveryPicture(),
    ) ?? [],
    'digest',
  );
  const otherPictures = ArrayUtils.removeDuplicatesByKey(
    props?.deliveryNote?.attachmentHandler?.attachments?.filter(
      (attachment) => !attachment.isDeliveryPicture(),
    ) ?? [],
    'digest',
  );

  if (deliveryPictures.length > 0) {
    categoryValues.push({
      category: 'Abladeort',
      value: (
        <div className="flex-s-s gap-10px flex-wrap">
          {deliveryPictures.map((attachment) => (
            <Attachment key={attachment.digest} attachment={attachment} />
          ))}
        </div>
      ),
    });
  }

  if (otherPictures.length > 0) {
    categoryValues.push({
      category: 'Anhänge',
      value: (
        <div className="flex-s-s gap-10px flex-wrap">
          {otherPictures.map((attachment) => (
            <Attachment key={attachment.digest} attachment={attachment} />
          ))}
        </div>
      ),
    });
  }

  return (
    <div className="flexdir-column gap-20px flex">
      {categoryValues.map((dataSet, index) => (
        <DeliveryNoteMetaDataItem
          key={index}
          category={dataSet.category}
          value={dataSet.value}
        />
      ))}
    </div>
  );
}

export default withErrorBoundary(
  DeliveryNoteMetaData,
  'Lieferdaten konnten nicht geladen werden.',
);
