import { useCallback, useEffect, useState } from 'react';

import { useQuerySiteIdentifierProposals } from '~/data/site';

import SiteService from '~/services/site.service';
import ToastService from '~/services/toast.service';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

import { Spinner } from '~/components/Spinner';

const mapSiteIdentifierProposalsToOptions = (data) =>
  data.map((item) => ({
    ...item,
    id: item.proposalId,
    name:
      'Bezeichnung: ' +
      [item.identifier, item.addressString].filter(Boolean).join(', ') +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0' +
      'Lieferant: ' +
      item.issuerName,
  }));

const refreshData = () => {
  SiteService.refreshSites();
  SiteService.refreshAssignedSiteReferences();
};

export const useSiteIdentifiersForm = ({ siteId, shouldBlacklist }) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pickedSiteIdentifierProposals, setPickedSiteIdentifierProposals] =
    useState([]);
  const [updateDlns, setUpdateDlns] = useState(!shouldBlacklist);
  const [affectedDlnsCount, setAffectedDlnsCount] = useState(0);
  const [affectedDlnsCountIsLoading, setAffectedDlnsCountIsLoading] =
    useState(false);

  const { data: siteIdentifierOptions } = useQuerySiteIdentifierProposals({
    select: mapSiteIdentifierProposalsToOptions,
  });

  const handleOpen = () => {
    Log.info(
      'Open site reference add form',
      { id: siteId },
      Log.BREADCRUMB.FORM_OPEN.KEY,
    );
    setOpen(true);
  };

  const handleClose = () => {
    Log.info('Close site reference form', null, Log.BREADCRUMB.FORM_CLOSE.KEY);
    setOpen(false);
    setPickedSiteIdentifierProposals([]);
    setUpdateDlns(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!pickedSiteIdentifierProposals) {
      handleClose();
      return;
    }

    setIsSubmitting(true);

    Log.info(
      'Submit site reference form',
      { value: pickedSiteIdentifierProposals },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Map site reference to site (from site form)',
      Log.FEATURE.SITE_MAPPING,
    );

    const failedProposals = [];

    for (const proposal of pickedSiteIdentifierProposals) {
      const [, error] = await promiseHandler(
        SiteService.addSiteIdentifierFromProposal(
          siteId,
          proposal.proposalId,
          updateDlns,
          shouldBlacklist,
        ),
      );

      if (error) {
        failedProposals.push(proposal.name);
        Log.error('Failed to create site reference.', error);
      }
    }

    if (failedProposals.length > 0) {
      ToastService.error([
        'Die folgenden Standortbezeichnungen konnten nicht hinzugefügt werden:',
        ...failedProposals,
        ToastService.MESSAGE.CONTACT_SUPPORT,
      ]);
      Log.productAnalyticsEvent(
        'Failed to map site reference to site (from site form)',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
    } else {
      ToastService.success(['Die Standortbezeichnungen wurden hinzugefügt.']);
    }

    setIsSubmitting(false);

    refreshData();
    handleClose();
    SiteService.refreshSiteReferences();
  };

  const loadAffectedDlnsCount = useCallback(async () => {
    setAffectedDlnsCountIsLoading(true);

    const promises = pickedSiteIdentifierProposals.map(
      ({ identifier, issuerId: supplierId }) =>
        SiteService.countAffectedDlnsOfSiteIdentifier(identifier, supplierId),
    );
    const results = await Promise.all(
      promises.map((promise) => promiseHandler(promise)),
    );
    let count = 0;
    for (const [response, error] of results) {
      if (!error) {
        count += response.data.total_count;
      }
    }

    setAffectedDlnsCountIsLoading(false);
    setAffectedDlnsCount(count);
  }, [pickedSiteIdentifierProposals]);

  const handleChange = (event) => {
    Log.productAnalyticsEvent(
      'Change site references',
      Log.FEATURE.SITE_MAPPING,
    );
    Log.info(
      'Change form value of site references',
      {
        from: pickedSiteIdentifierProposals,
        to: event,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );

    setPickedSiteIdentifierProposals(event);
  };

  const handleCheckboxChange = (event) => {
    Log.productAnalyticsEvent(
      'Change update dlns checkbox',
      Log.FEATURE.SITE_MAPPING,
    );
    Log.info(
      'Change form value of update dlns checkbox',
      { from: updateDlns, to: event.target.checked },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );

    setUpdateDlns(event.target.checked);
  };

  let checkboxLabel = `${affectedDlnsCount} nicht zugeordnete Lieferungen dem Standort zuordnen.`;
  if (affectedDlnsCount === 0) {
    checkboxLabel = 'Nicht zugeordnete Lieferungen dem Standort zuordnen.';
  }

  if (affectedDlnsCount > 100) {
    checkboxLabel =
      'Die letzten 100 nicht zugeordneten Lieferungen dem Standort zuordnen.';
  }

  if (affectedDlnsCountIsLoading) {
    checkboxLabel = <Spinner />;
  }

  useEffect(() => {
    SiteService.refreshSiteReferences();
  }, []);

  useEffect(() => {
    loadAffectedDlnsCount();
  }, [loadAffectedDlnsCount, pickedSiteIdentifierProposals]);

  return {
    affectedDlnsCount,
    affectedDlnsCountIsLoading,
    checkboxLabel,
    handleChange,
    handleCheckboxChange,
    handleClose,
    handleOpen,
    handleSubmit,
    isSubmitting,
    open,
    pickedSiteIdentifierProposals,
    siteIdentifierOptions,
    updateDlns,
  };
};
