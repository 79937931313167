import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    '& .logo-container': {
      '& img': {
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'left center',
        width: '100%',
      },
      height: 48,
      marginBottom: '1rem',

      width: 240,
    },
    '& .MuiButtonBase-root': {
      borderRadius: '0.5rem',
      textTransform: 'unset',
    },
    '& .MuiDivider-root': {
      backgroundColor: '#C5C9D6',
      marginBottom: '1rem',
    },
    '& .status-flag': {
      '&.created': {
        backgroundColor: '#E4E6ED',
        borderColor: '#09152E',
      },
      '&.expired': {
        backgroundColor: '#FFD9D8',
        borderColor: '#CC2C29',
      },
      '&.filled': {
        backgroundColor: '#E5FFF0',
        borderColor: '#24B25F',
      },
      border: '1px solid',
      borderBottomLeftRadius: '0.3125rem',
      borderBottomRightRadius: '0.3125rem',
      borderTopWidth: 0,
      height: '2.5rem',
      marginRight: '1rem',
      position: 'absolute',

      right: 0,

      top: 0,

      width: '2rem',
    },
    '& .supplier-details': {
      '& .contact': {
        '& .option': {
          '& p': {
            color: '#343A4D',
            fontSize: 14,
          },
          '& svg': {
            height: 24,
            marginRight: '0.5rem',
            width: 24,
          },
          alignItems: 'center',

          flex: 'unset',

          height: 40,
        },
        display: 'flex',
        flex: 0.5,
        flexDirection: 'column',

        flexShrink: 0,
      },
      '& .option': {
        alignItems: 'center',
        backgroundColor: '#F2F4F7',
        borderRadius: '0.5rem',
        display: 'flex',
        flex: 1,
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
        width: '100%',
      },

      '& .traits': {
        '& .option': {
          '& .label': {
            color: '#343A4D',
            fontSize: 14,
            fontWeight: 600,
            marginBottom: '0.375rem',
          },
          '& .value': {
            '& p': {
              fontSize: 14,
              fontWeight: 700,
            },
            '& svg': {
              height: 16,
              marginRight: 4,
              width: 16,
            },

            alignItems: 'center',

            display: 'flex',
          },
          '&.active': {
            '& .value': {
              color: '#158C47',
              fontWeight: 600,
            },
          },
          alignItems: 'flex-start',
          flexDirection: 'column',

          justifyContent: 'flex-start',

          padding: '0.5rem',
        },
        display: 'flex',
        flex: 0.5,
        flexDirection: 'column',
        flexShrink: 0,

        gap: '0.5rem',
      },

      display: 'flex',

      gap: '0.5rem',
    },
    '& .title': {
      '& .email-icon': {
        border: '1px solid #C5C9D6',
        borderRadius: '0.5rem',
        height: '2.5rem',
        opacity: 0,
        transition: 'all 0.2s ease-out',
        visibility: 'hidden',
        width: '2.5rem',
      },

      gap: '0.5rem',
    },
    '&:hover': {
      '& .email-icon': {
        opacity: 1,
        visibility: 'visible',
      },
    },
    backgroundColor: 'white',

    borderRadius: '1rem',

    boxShadow: '0px 4px 8px 0px rgba(23, 60, 136, 0.20)',

    color: '#343A4D',

    cursor: 'pointer',

    padding: '1rem',

    position: 'relative',

    width: '26.5rem',
  },

  emailDialog: {
    '& *': {
      fontFamily: 'Inter',
    },
    '& .close-button': {
      position: 'absolute',
      right: 0,
      top: 0,
    },

    '& .MuiButtonBase-root': {
      borderRadius: '0.5rem',
      fontWeight: 500,
      height: '2.5rem',
      marginLeft: '1rem',
      textTransform: 'unset',
    },

    '& .MuiPaper-root': {
      borderRadius: '0.5rem',
      height: '14.8125rem',
      maxWidth: '22.5rem',
      padding: '1.25rem 1rem',
      position: 'relative',
      width: '100%',
    },

    '& h5': {
      fontSize: '1.25rem',
      fontWeight: 600,
    },

    color: '#343A4D',

    position: 'relative',
  },
}));
