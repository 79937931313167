import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';
import ArrayUtils from '~/utils/arrayUtils';

const costCenters = createSlice({
  initialState: {
    costCenters: [],
    costCentersLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'costCenters',
  reducers: {
    replaceCostCenter(state, parameter) {
      const { payload } = parameter;

      const newCostCenters = ArrayUtils.removeByKey(
        state.costCenters,
        'id',
        payload.id,
      );
      newCostCenters.push(payload);

      state.costCenters = newCostCenters;
    },
    replaceCostCenters(state, parameter) {
      const { payload } = parameter;

      state.costCenters = ArrayUtils.sortByKey(payload, 'name');
      state.costCentersLoading = LOADING_STATE.SUCCEEDED;
    },
    setCostCentersLoading(state, parameter) {
      const { payload } = parameter;

      state.costCentersLoading = payload;
    },
  },
});

const { actions, reducer } = costCenters;
export const { replaceCostCenter, replaceCostCenters, setCostCentersLoading } =
  actions;
export default reducer;
