import { useCallback, useEffect, useState } from 'react';

import { MASTERDATA } from '~/constants/Masterdata';

import Log from '~/utils/Log';

export const useTableInteractions = ({
  entity,
  onSearchStringChange,
  rows,
  sortBy,
}) => {
  const [state, setState] = useState({
    activeStateFilter: MASTERDATA.IS_ACTIVE.YES,
    excelData: [],
    filteredRows: [],
    freeTextFilter: '',
    rowSelectionModel: [],
    sortModel: [
      {
        field: sortBy ?? 'name',
        sort: 'asc',
      },
    ],
  });

  const setRows = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      filteredRows: rows,
    }));
  }, [rows]);

  useEffect(() => {
    setRows();
  }, [rows, setRows]);

  useEffect(() => {
    setState((previousState) => ({
      ...previousState,
      excelData: previousState.filteredRows.filter(
        ({ id }) => previousState.rowSelectionModel?.includes(id) ?? true,
      ),
    }));
  }, [state.rowSelectionModel, state.filteredRows]);

  const tableActions = {
    onRowSelectionModelChange: useCallback(
      (event) => {
        Log.info(
          `Change selection value of ${entity}`,
          {
            from: state.rowSelectionModel,
            to: event,
          },
          Log.BREADCRUMB.SELECTION_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          `(De)select ${entity}`,
          Log.FEATURE.SETTINGS_TABLE,
        );

        setState((previousState) => ({
          ...previousState,
          rowSelectionModel: event,
        }));
      },
      [entity, state.rowSelectionModel],
    ),
    onSortModelChange: useCallback((event) => {
      Log.productAnalyticsEvent('Sort', Log.FEATURE.SETTINGS_TABLE);

      setState((previousState) => ({
        ...previousState,
        sortModel: event,
      }));
    }, []),
    setActiveStateFilter: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          activeStateFilter: value,
        })),
      [],
    ),
    setFilteredRows: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          filteredRows: value,
        })),
      [],
    ),
    setFreeTextFilter: useCallback(
      (value) => {
        setState((previousState) => ({
          ...previousState,
          freeTextFilter: value,
        }));

        if (onSearchStringChange) {
          onSearchStringChange(value);
        }
      },
      [onSearchStringChange],
    ),
    setRowSelectionModel: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          rowSelectionModel: value,
        })),
      [],
    ),
    setSortModel: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          sortModel: value,
        })),
      [],
    ),
  };

  return {
    setTableState: setState,
    tableActions,
    tableState: state,
  };
};
