import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  queryKeysCostCenter,
  useMutationCreateCostCenter,
  useMutationUpdateCostCenter,
  useQueryCostCenter,
} from '~/data/costCenter';
import { useMutationUpdateOrganizationalUnits } from '~/data/organizationalUnit';
import { useQueryUserData } from '~/data/user';

import ToastService from '~/services/toast.service';

import CostCenter from '~/models/masterdata/CostCenter';

import { useDelayedValue } from '~/hooks/useDelayedValue';

import Log from '~/utils/Log';

import { handleSubmit as onSubmit } from './handleSubmit';
import {
  handleChangeDate,
  handleChangeName,
  handleChangeActive,
  handleChangeCompany,
  handleChangeOrganisationalGroups,
} from './formHandlers';

export const useCostCenterForm = ({
  closeForm,
  costCenterId,
  setCostCenter,
  type,
}) => {
  const isCreatingCostCenter = type === 'create';

  const queryClient = useQueryClient();

  const {
    data: currentUser,
    isError: isErrorUserInfo,
    isLoading: isLoadingUserInfo,
    isSuccess: isSuccessUserInfo,
  } = useQueryUserData(true);

  const companyInfo = currentUser?.companyInfo ?? {};

  const {
    data: costCenter,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchCostCenter,
  } = useQueryCostCenter(costCenterId, {
    enabled:
      !isCreatingCostCenter && Boolean(costCenterId) && Boolean(currentUser),
    placeholderData() {
      // Try to get some basic data from the list item cache to improve the rendering experience.
      const queriesData = queryClient.getQueriesData({
        queryKey: queryKeysCostCenter.getAll().filter(Boolean),
      });

      for (const [, data] of queriesData) {
        if (Array.isArray(data?.data)) {
          const listItem = data?.data?.find(({ id }) => id === costCenterId);

          if (listItem) {
            const { companyId, end, id, isActive, name, start } = listItem;

            return new CostCenter({
              companyId,
              end,
              id,
              isActive,
              name,
              start,
            });
          }
        }
      }

      return undefined;
    },
    select: (data) => new CostCenter(data),
  });

  const {
    mutateAsync: createCostCenterMutation,
    isPending: isPendingCreateCostCenter,
  } = useMutationCreateCostCenter({
    onError(error) {
      Log.error('createCostCenterMutation error');
    },
  });

  const {
    mutateAsync: updateCostCenterMutation,
    isPending: isPendingUpdateCostCenter,
  } = useMutationUpdateCostCenter({
    onError(error) {
      Log.error('updateCostCenter error');
    },
  });

  const {
    mutateAsync: updateOrganizationalUnitsMutation,
    isPending: isPendingUpdateOrganizationalUnits,
  } = useMutationUpdateOrganizationalUnits({
    onError(error) {
      Log.error('updateOrganizationalUnitsMutation error');

      ToastService.httpError(
        ['Organisations-Gruppen konnten nicht geändert werden.'],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to update organisational groups',
        Log.FEATURE.COST_CENTER,
        Log.TYPE.ERROR,
      );
    },
  });

  const isSubmitting =
    isPendingCreateCostCenter ||
    isPendingUpdateCostCenter ||
    isPendingUpdateOrganizationalUnits;

  const refetchCostCenters = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: queryKeysCostCenter.getAll({}),
    });
  }, [queryClient]);

  const getDefaultCostCenter = useCallback(() => {
    const defaultCostCenter = new CostCenter();

    if (companyInfo?.id) {
      defaultCostCenter.companyId = companyInfo.id;
    }

    return defaultCostCenter;
  }, [companyInfo?.id]);

  const [state, setState] = useState({
    costCenter: isCreatingCostCenter ? getDefaultCostCenter() : costCenter,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit({
      closeForm,
      costCenter: state.costCenter,
      createCostCenterMutation,
      isCreatingCostCenter,
      originalData: costCenter,
      queryClient,
      refetchCostCenter,
      refetchCostCenters,
      resetForm,
      setCostCenter,
      setState,
      updateCostCenterMutation,
      updateOrganizationalUnitsMutation,
    });
  };

  const resetForm = useCallback(
    (resetGeneralCostCenterInformation) => {
      if (!resetGeneralCostCenterInformation) {
        setState((previousState) => {
          const newCostCenter = cloneDeep(previousState);
          newCostCenter.permissionGrantsFrom = costCenter
            ? costCenter.permissionGrantsFrom
            : getDefaultCostCenter().permissionGrantsFrom;

          return {
            ...previousState,
            costCenter: newCostCenter,
          };
        });

        return;
      }

      const newCostCenter = costCenter
        ? cloneDeep(costCenter)
        : getDefaultCostCenter();

      setState((previousState) => ({
        ...previousState,
        costCenter: newCostCenter,
      }));
    },
    [costCenter, getDefaultCostCenter, refetchCostCenter],
  );

  useEffect(() => {
    resetForm(true);
  }, [isSuccess, isError, JSON.stringify(costCenter)]);

  const handleCancel = () => {
    closeForm();
    resetForm(true);
  };

  const unsavedChanges = useMemo(() => {
    if (isCreatingCostCenter) {
      return [];
    }

    if (isLoading || isError || !costCenter) {
      return [];
    }

    return CostCenter.getDifferentValues(costCenter, state.costCenter);
  }, [
    JSON.stringify(costCenter),
    JSON.stringify(state.costCenter),
    isCreatingCostCenter,
    isLoading,
    isError,
  ]);

  const delayedUnsavedChanges = useDelayedValue(unsavedChanges);

  return {
    costCenter: state.costCenter,
    handleCancel,
    handleChangeActive: (event) => handleChangeActive(event, setState),
    handleChangeCompany: (event) => handleChangeCompany(event, setState),
    handleChangeDate: (date, eventName) =>
      handleChangeDate(date, eventName, setState),
    handleChangeName: (event) => handleChangeName(event, setState),
    handleChangeOrganisationalGroups: ({ added, removed }) =>
      handleChangeOrganisationalGroups({ added, removed }, setState),
    handleSubmit,
    isCreatingCostCenter,
    isLoading,
    isSubmitting,
    refetchCostCenter,
    unsavedChanges: delayedUnsavedChanges,
  };
};
