import { Checkbox, FormControlLabel } from '@mui/material';

import SignatureRoles from '~/models/masterdata/SignatureRoles';

import { toCamelCase } from '~/utils/string';

import { Spinner } from '~/components/Spinner';

const SIGNATURE_ROLES = ['supplier', 'carrier', 'recipient'];
const ON_BEHALF_SIGNATURE_ROLES = SIGNATURE_ROLES.map(
  (role) => `on_behalf_${role}`,
);

export const OnBehalfSignatureRolesSettings = ({
  isError,
  isLoading,
  onChange,
  signatureRoles,
}) => {
  if (isLoading) {
    return (
      <div className="h-12 w-96">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return <div className="h-12 w-96">Daten konnten nicht geladen werden.</div>;
  }

  if (!signatureRoles) {
    return null;
  }

  return (
    <>
      {ON_BEHALF_SIGNATURE_ROLES.map((role) => {
        const roleName =
          SignatureRoles.SIGNATURE_ROLE[role.toUpperCase()].STRING;

        return (
          <FormControlLabel
            key={role}
            control={
              <Checkbox
                checked={Boolean(signatureRoles?.[toCamelCase(role)])}
                onChange={onChange}
                name={`signature_role_${role}`}
              />
            }
            label={`Als ${roleName}`}
            className="mr-12"
          />
        );
      })}
    </>
  );
};
