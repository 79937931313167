export const FEATURE_FLAG = {
  ACCESS_ARCHIVE: 'accessArchive',
  CLIENT_PORTAL: 'clientPortal',
  CONCRETE_DIARY: 'concreteDiary',
  CREATE_DELIVERY_NOTE: 'createDeliveryNote',
  CREATE_DEMO_DLNS: 'createDemoDlns',
  CUSTOMER_SUBSCRIPTION: 'customerSubscription',
  DEACTIVATED_COMPANY_ACCOUNT: 'deactivatedCompanyAccount',
  DISABLE_INVOICES: 'disableInvoices',
  MODULE_INVOICE_RESTRICTION: 'moduleInvoiceRestriction',
  NEW_SUPPLIERS_KANBAN: 'suppliersKanban',
  NO_PRIVACY_STATEMENT: 'noPrivacyStatement',
  PACKAGE_BASIC_RESTRICTION: 'packageBasicRestriction',
  PRODUCT_ANALYTICS_TOOLS: 'productAnalyticsTools',
  SENTRY_GOOGLE_ANALYTICS_DISABLED: 'sentryGoogleAnalyticsDisabled',
  SERVICE_NOTES: 'serviceNotes',
  USER_IN_DLN_HISTORY: 'userInDlnHistory',
};

export const FEATURE_CONFIG = {
  LOAD_DLNS_AMOUNT: 'loadDlnsAmount',
};
