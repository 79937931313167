import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { toSnakeCase } from '~/utils/string';

export default class SignatureRoles {
  constructor(signatureRoles) {
    this.supplier = signatureRoles?.supplier ?? false;
    this.carrier = signatureRoles?.carrier ?? false;
    this.recipient = this.getRecipient(signatureRoles);
    this.onBehalfSupplier =
      signatureRoles?.on_behalf_supplier ??
      signatureRoles?.onBehalfSupplier ??
      false;
    this.onBehalfCarrier =
      signatureRoles?.on_behalf_carrier ??
      signatureRoles?.onBehalfCarrier ??
      false;
    this.onBehalfRecipient =
      signatureRoles?.on_behalf_recipient ??
      signatureRoles?.onBehalfRecipient ??
      false;
    this.defaultSignatureRole =
      signatureRoles?.default_signature_role ??
      signatureRoles?.defaultSignatureRole ??
      '';
  }

  // We used to use "buyer" as signature role for the recipient.
  // As this is legacy, we want to migrate to the signature role "recipient".
  // To support the legacy signature roles of "buyer", we need to check if "buyer" is set.
  getRecipient(signatureRoles) {
    if (signatureRoles?.recipient) {
      return true;
    }

    if (signatureRoles?.buyer) {
      return true;
    }

    return false;
  }

  signatureRoleIsAssigned() {
    return (
      this.supplier ||
      this.carrier ||
      this.recipient ||
      this.onBehalfSupplier ||
      this.onBehalfCarrier ||
      this.onBehalfRecipient
    );
  }

  // Based on the signature role of the user, we want to set the user type. Because the signature role is a multiselect,
  // we need to decide which user type to set in case of multiple signature roles.
  // The hierarchy recipient -> carrier -> supplier is kind of random though.
  getUserType() {
    if (this.recipient) {
      return SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY;
    }

    if (this.carrier) {
      return SignatureRoles.SIGNATURE_ROLE.CARRIER.KEY;
    }

    if (this.supplier) {
      return SignatureRoles.SIGNATURE_ROLE.SUPPLIER.KEY;
    }

    return null;
  }

  noSignatureRoleIsSelected() {
    return !this.supplier && !this.carrier && !this.recipient;
  }

  multipleSignatureRolesAreSelected() {
    return (
      (this.supplier ? 1 : 0) +
        (this.carrier ? 1 : 0) +
        (this.recipient ? 1 : 0) >
      1
    );
  }

  getSignatureRoles() {
    const signatureRoles = [];
    if (this.supplier) {
      signatureRoles.push(SignatureRoles.SIGNATURE_ROLE.SUPPLIER.KEY);
    }

    if (this.carrier) {
      signatureRoles.push(SignatureRoles.SIGNATURE_ROLE.CARRIER.KEY);
    }

    if (this.recipient) {
      signatureRoles.push(SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY);
    }

    if (this.onBehalfSupplier) {
      signatureRoles.push(SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.KEY);
    }

    if (this.onBehalfCarrier) {
      signatureRoles.push(SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_CARRIER.KEY);
    }

    if (this.onBehalfRecipient) {
      signatureRoles.push(
        SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.KEY,
      );
    }

    return signatureRoles;
  }

  static getSignatureRole(signatureRoleKey) {
    const signatureRole = Object.keys(SignatureRoles.SIGNATURE_ROLE).find(
      (x) =>
        SignatureRoles.SIGNATURE_ROLE[x].KEY === toSnakeCase(signatureRoleKey),
    );

    if (!signatureRole) {
      throw new EnumValueNotFoundException(
        'invalid signature role: ' + toSnakeCase(signatureRoleKey),
      );
    }

    return SignatureRoles.SIGNATURE_ROLE[signatureRole].STRING;
  }

  static getDifferentValues(signatureRolesA, signatureRolesB) {
    const differentValues = [];

    if (signatureRolesA?.supplier !== signatureRolesB?.supplier) {
      differentValues.push(SignatureRoles.SIGNATURE_ROLE.SUPPLIER.STRING);
    }

    if (signatureRolesA?.carrier !== signatureRolesB?.carrier) {
      differentValues.push(SignatureRoles.SIGNATURE_ROLE.CARRIER.STRING);
    }

    if (signatureRolesA?.recipient !== signatureRolesB?.recipient) {
      differentValues.push(SignatureRoles.SIGNATURE_ROLE.RECIPIENT.STRING);
    }

    if (
      signatureRolesA?.onBehalfSupplier !== signatureRolesB?.onBehalfSupplier
    ) {
      differentValues.push(
        SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.STRING,
      );
    }

    if (signatureRolesA?.onBehalfCarrier !== signatureRolesB?.onBehalfCarrier) {
      differentValues.push(
        SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_CARRIER.STRING,
      );
    }

    if (
      signatureRolesA?.onBehalfRecipient !== signatureRolesB?.onBehalfRecipient
    ) {
      differentValues.push(
        SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.STRING,
      );
    }

    if (
      SignatureRoles.getDifferentValuesForDefaultSignatureRole(
        signatureRolesA,
        signatureRolesB,
      )
    ) {
      differentValues.push('Standard-Signaturberechtigung');
    }

    return differentValues;
  }

  // Legacy case:
  // Handle the case where the default signature role is set to "recipient"
  // but the user already has the signature role "buyer".
  // In this case, we don't want to show the "buyer" signature role as different.
  static getDifferentValuesForDefaultSignatureRole(
    signatureRolesA,
    signatureRolesB,
  ) {
    if (
      signatureRolesA?.defaultSignatureRole ===
      signatureRolesB?.defaultSignatureRole
    ) {
      return false;
    }

    if (
      !signatureRolesA?.defaultSignatureRole &&
      !signatureRolesB?.defaultSignatureRole
    ) {
      // Treat '', null and undefined values as the same value.
      return false;
    }

    if (
      signatureRolesA?.defaultSignatureRole ===
        SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY &&
      signatureRolesB?.defaultSignatureRole ===
        SignatureRoles.SIGNATURE_ROLE.RECIPIENT.LEGACY_KEY
    ) {
      return false;
    }

    if (
      signatureRolesA?.defaultSignatureRole ===
        SignatureRoles.SIGNATURE_ROLE.RECIPIENT.LEGACY_KEY &&
      signatureRolesB?.defaultSignatureRole ===
        SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY
    ) {
      return false;
    }

    return true;
  }

  static SIGNATURE_ROLE = {
    CARRIER: {
      KEY: 'carrier',
      STRING: 'Spediteur',
    },
    NONE: {
      KEY: 'none',
      STRING: 'Keine',
    },
    ON_BEHALF_CARRIER: {
      KEY: 'on_behalf_carrier',
      STRING: 'Spediteur',
    },
    ON_BEHALF_RECIPIENT: {
      KEY: 'on_behalf_recipient',
      STRING: 'Abnehmer',
    },
    ON_BEHALF_SUPPLIER: {
      KEY: 'on_behalf_supplier',
      STRING: 'Lieferant',
    },
    RECIPIENT: {
      KEY: 'recipient',
      LEGACY_KEY: 'buyer',
      STRING: 'Abnehmer',
    },
    SUPPLIER: {
      KEY: 'supplier',
      STRING: 'Lieferant',
    },
  };
}
