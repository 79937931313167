import axios from '~/utils/api-client';
import Config from '~/Config';
import store from '~/redux/store';
import { promiseHandler } from '~/utils/promiseHandler';
import {
  replaceCompanies,
  replaceCompany,
  saveCompanyLogo,
  setCompaniesLoading,
} from '~/redux/companiesSlice';
import Log from '~/utils/Log';
import CacheService from './cache.service';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import Company from '~/models/masterdata/Company';
import { LOADING_STATE } from '~/constants/LoadingState';
import User from '~/models/masterdata/User';

const API_URL = Config.apiUrl + '/company';

class CompanyService {
  async getAllCompanies() {
    return axios.get(API_URL + '/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.companies.map((item) => new Company(item));
    });
  }

  async getInvoiceReceiversOfCompany(id, include_info = false) {
    return axios
      .get(API_URL + '/' + id + '/invoice_receivers', {
        params: {
          include_info,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          return response.data?.invoice_receivers;
        }

        return [];
      });
  }

  // search for company in store. if not found, load from backend.
  getCompanyById = async (companyId) => {
    const company = store
      .getState()
      .companies?.companies?.find((company) => company.id === companyId);
    if (company) {
      return ES6ClassFactory.convertToES6Class([company], new Company())[0];
    }

    return this.getCompany(companyId);
  };
  getCompany = async (companyId, ignoreCache) => {
    const url = API_URL + '/' + companyId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn('GET /company did not return 200', {
            status: response?.status,
          });
        }

        const site = new Company(response?.data, null, true);
        CacheService.setCached(url, site);
        return site;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  };
  loadCompanies = async () => {
    // to not load companies again when they are already loading or have already been loaded
    if (
      store.getState().companies?.companiesLoading !== LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshCompanies();
  };
  refreshCompanies = async () => {
    store.dispatch(setCompaniesLoading(LOADING_STATE.LOADING));

    const [companies, error] = await promiseHandler(this.getAllCompanies());

    if (error) {
      store.dispatch(setCompaniesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load companies.', error);
      Log.productAnalyticsEvent(
        'Failed to load companies',
        Log.FEATURE.COMPANY,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceCompanies(companies));
  };
  refreshCompany = async (companyId) => {
    const [company, error] = await promiseHandler(
      this.getCompany(companyId, true),
    );

    if (error) {
      Log.error('Failed to load company.', error);
      Log.productAnalyticsEvent(
        'Failed to load company',
        Log.FEATURE.COMPANY,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceCompany(company));
  };

  async createNewCompany(body) {
    return axios.post(API_URL, body).then((response) => {
      return response.data?.id;
    });
  }

  async updateCompany(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  async updateInvoiceReceiversOfCompany(id, body) {
    return axios.put(API_URL + '/' + id + '/invoice_receivers', body);
  }

  async removeInvoiceReceiversFromCompany(id, body) {
    return axios.delete(API_URL + '/' + id + '/invoice_receivers', {
      data: body,
    });
  }

  async getCompanyLogoById(companyId) {
    const companyLogo = store
      ?.getState()
      .companies?.companyLogos?.find(
        (company) => company.companyId === companyId,
      )?.logo;

    if (companyLogo) {
      return companyLogo;
    }

    const [newCompanyLogo, error] = await promiseHandler(
      this.getCompanyLogo(companyId),
    );

    if (error) {
      throw error;
    }

    store.dispatch(saveCompanyLogo({ companyId, logo: newCompanyLogo }));

    return newCompanyLogo;
  }

  async getCompanyLogo(id, ignoreCache) {
    if (!id) {
      throw new Error('Failed to load company logo. id: ' + id);
    }

    const url = API_URL + '/' + id + '/logo';

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn(
            'GET company logo did not return 200',
            { status_code: response?.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }

        CacheService.setCached(url, response.data);
        return response.data;
      })
      .catch((error) => {
        CacheService.setError(url, error);

        Log.error('Failed to load logo', error);

        throw error;
      });
  }

  async uploadCompanyLogo(id, logo) {
    return axios
      .put(API_URL + '/' + id + '/logo', logo, {
        headers: { 'Content-Type': logo.type },
      })
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn(
            'PUT company logo did not return 200',
            { status_code: response?.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }
      });
  }

  async deleteCompanyLogo(id) {
    const url = `${API_URL}/${id}/logo`;
    return axios.delete(url).then((response) => {
      CacheService.removeCached(url);
      return response.data;
    });
  }

  // This endpoint is actually deprecated. However, it is needed for the dirty workaround in DeliveryNoteAction.js
  async getEmployeesOfCompany(id, include_info = false, ignoreCache) {
    const url = API_URL + '/' + id + '/employees';

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url, {
        params: { include_info },
      })
      .then((response) => {
        if (response?.status === 200) {
          const users = response.data.users?.map((user) => {
            return new User({ ...user, company_id: id });
          });
          CacheService.setCached(url, users);
          return users;
        }

        CacheService.setCached(url, []);
        return [];
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }
}

export default new CompanyService();
