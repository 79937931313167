import clsx from 'clsx';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { type GroupBase } from 'react-select';
import { AsyncPaginate, type LoadOptions } from 'react-select-async-paginate';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { Chip } from '@mui/material';

export type Option<T = any> = {
  value: string;
  label: string;
  data: T;
};

export type SelectServerDrivenProps<T> = {
  debounceTime?: number;
  formatOptionLabel?: (
    option: Option<T>,
    formatOptionLabelMeta: any,
  ) => React.ReactNode;
  getItemData: (value: string) => Promise<T | undefined>;
  getOptionLabel: (item: T) => string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isItemSelectionAllowed: () => boolean;
  isMultiSelect?: boolean;
  loadOptions: LoadOptions<
    Option<T>,
    GroupBase<Option<T>>,
    { page: number; filterActive?: boolean | undefined }
  >;
  onChange: {
    (value: string[], data: T[]): void;
    (value: string, data: T): void;
  };
  placeholder?: string;
  value: string | string[];
  filterActive?: boolean;
} & ComponentStyling;

const hideComponent = () => null;

// Always return the value as an array.
const ensureArray = (value: string | string[]) =>
  Array.isArray(value) ? value : value ? [value] : [];

/**
 * SelectServerDriven is a customizable, server-driven select component that supports both single and multi-select modes.
 * It uses react-select-async-paginate for efficient loading of paginated data from a server.
 *
 * @template T - The type of the item data returned by getItemData
 *
 * @param props - The component props
 * @param props.className - Additional CSS class for the select component
 * @param props.debounceTime - Debounce time for search input in milliseconds (default: 300)
 * @param props.formatOptionLabel - Function to format the option label
 * @param props.getItemData - Function to fetch item data for a given value
 * @param props.getOptionLabel - Function to get the label string for an item
 * @param props.isClearable - Whether the select allows clearing the selection (default: true)
 * @param props.isDisabled - Whether the select is disabled (default: false)
 * @param props.isItemSelectionAllowed - Function to determine if item selection is allowed
 * @param props.isMultiSelect - Whether the select allows multiple selections (default: false)
 * @param props.loadOptions - Function to load options from the server
 * @param props.onChange - Callback function when selection changes
 * @param props.placeholder - Placeholder text for the select (default: 'Element auswählen')
 * @param props.style - Additional inline styles for the select component
 * @param props.value - Current selected value(s)
 *
 * @returns JSX.Element
 */
export const SelectServerDriven = <T,>({
  className,
  debounceTime = 300,
  formatOptionLabel,
  getItemData,
  getOptionLabel,
  isItemSelectionAllowed,
  isClearable = true,
  isDisabled = false,
  isMultiSelect = false,
  loadOptions,
  onChange,
  placeholder = 'Element auswählen',
  style,
  value,
  filterActive = true,
}: SelectServerDrivenProps<T>): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<Array<Option<T>>>([]);

  const [error, setError] = useState<string | undefined>(undefined);

  const getSelectedItems = useCallback(
    async (value, getItemData, getOptionLabel) => {
      try {
        const items = await Promise.all(
          ensureArray(value).map(async (value_) => {
            const item = await getItemData(value_);

            return item
              ? {
                  data: item,
                  label: getOptionLabel(item),
                  value: value_,
                }
              : null;
          }),
        );

        setSelectedItems(
          items.filter((item): item is Option<T> => item != null),
        );
      } catch (error) {
        setError('Failed to fetch selected items. Please try again.');
        console.error('Error fetching selected items:', error);
      }
    },
    [JSON.stringify(value), getItemData, getOptionLabel],
  );

  useEffect(() => {
    getSelectedItems(value, getItemData, getOptionLabel);
  }, [value, getItemData, getOptionLabel]);

  const MultiValue = ({ data }: { data: Option<T> }) => (
    <Chip
      label={data.label}
      onDelete={() => {
        handleChange(selectedItems.filter(({ value }) => value !== data.value));
      }}
      className="mr-1 first:-ml-1.5"
    />
  );

  const DropdownIndicator = ({ selectProps }) => (
    <div
      className={clsx(
        'flex size-8 cursor-pointer items-center justify-center transition-transform',
        {
          'rotate-0': !selectProps.menuIsOpen,
          'rotate-180': selectProps.menuIsOpen,
        },
      )}
    >
      <ArrowDropDownIcon />
    </div>
  );

  const handleChange = useCallback(
    (newValue: Option<T> | Array<Option<T>> | undefined) => {
      const newSelectedItems = ensureArray(newValue);

      setSelectedItems(newSelectedItems);

      const newValues = newSelectedItems.map(({ value }) => value);
      const newData = newSelectedItems.map(({ data }) => data);

      onChange(
        isMultiSelect ? newValues : (newValues[0] ?? ''),
        isMultiSelect ? newData : newData[0],
      );
    },
    [onChange, isMultiSelect],
  );

  const handleLoadOptions: typeof loadOptions = async (...arguments_) => {
    try {
      return await loadOptions(...arguments_);
    } catch (error) {
      setError('Failed to load options. Please try again.');
      console.error('Error loading options:', error);

      return {
        hasMore: false,
        options: [],
      };
    }
  };

  const additional = {
    filterActive,
    page: 1,
  };

  const memoizedStyles = useMemo(
    () => ({
      control: (provided: React.CSSProperties) => ({
        ...provided,
        minHeight: '2.5rem',
      }),
      menu: (provided: React.CSSProperties) => ({
        ...provided,
        margin: 0,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 1500,
      }),
      valueContainer: (provided: React.CSSProperties) => ({
        ...provided,
        rowGap: '0.25rem',
      }),
      ...style,
    }),
    [style],
  );

  return (
    <>
      <AsyncPaginate<Option<T>, GroupBase<Option<T>>, { page: number }>
        value={isMultiSelect ? selectedItems : selectedItems[0] || null}
        loadOptions={handleLoadOptions}
        onChange={handleChange}
        components={{
          DropdownIndicator,
          IndicatorSeparator: hideComponent,
          MultiValue,
        }}
        menuPortalTarget={document.body}
        className={className}
        styles={memoizedStyles}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        isDisabled={isDisabled || !isItemSelectionAllowed()}
        isMulti={isMultiSelect}
        placeholder={placeholder}
        additional={additional}
        debounceTimeout={debounceTime}
        loadingMessage={() => 'Lade Daten...'}
        noOptionsMessage={() => 'Keine passenden Daten gefunden'}
      />
      {error && <div className="mt-1 text-red-500">{error}</div>}
    </>
  );
};
