import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { ComponentTestIds } from '~/constants/test-ids';

import { MissingPermissionsTooltip } from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

import { SettingsCSVUpload } from '../SettingsCSVUpload';

import { type EntityType } from './types';

const permissionsMap = new Map([
  ['article', () => true],
  ['category', () => true],
  ['company', () => false], // Only Vestigas admin users can create or update companies and only in the admin portal.
  ['costCenter', UserUtils.isCostCenterCreateAllowedUser],
  ['customerSubscription', () => true],
  ['customField', () => true],
  ['invoiceCheckIgnoredArticle', () => true],
  ['organisationalGroup', UserUtils.isOrganisationalGroupCreateAllowedUser],
  ['signatureField', () => true],
  ['site', UserUtils.isSiteCreateAllowedUser],
  ['user', UserUtils.isUserCreateAllowedUser],
  ['userGroup', UserUtils.isUserGroupCreateAllowedUser],
  ['vehicle', UserUtils.isVehicleCreateAllowedUser],
]);

type P = {
  entity: EntityType;
  onOpenForm: () => void;
};

export const CreateItemButton = ({ entity, onOpenForm }: P) => {
  const isMissingPermissions = !permissionsMap.get(entity)?.();

  if (isMissingPermissions) {
    return (
      <MissingPermissionsTooltip>
        <Button
          className="disabled-primary-button"
          startIcon={<AddIcon />}
          onMouseDown={onOpenForm}
          disabled={isMissingPermissions}
          data-testid={ComponentTestIds.FORM_MODAL.OPEN_BUTTON}
        >
          {'Erstellen'}
        </Button>
      </MissingPermissionsTooltip>
    );
  }

  const uploadEnabledEntities = [
    'site',
    'user',
  ] as const satisfies EntityType[];

  if (uploadEnabledEntities.includes(entity) && UserUtils.isVestigasAccount()) {
    return <SettingsCSVUpload entity={entity} onOpenForm={onOpenForm} />;
  }

  return (
    <Button
      className="primary-button"
      startIcon={<AddIcon />}
      onMouseDown={onOpenForm}
      data-testid={ComponentTestIds.FORM_MODAL.OPEN_BUTTON}
    >
      {'Erstellen'}
    </Button>
  );
};
