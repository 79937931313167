import { dateUtils, fromUTC } from '~/utils/dateUtils';

const formatDateInLocalTime = (date) =>
  dateUtils.getFormattedDate(
    fromUTC(date),
    dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
  );

export const columns = [
  {
    field: 'name',
    headerName: 'Kostenstelle',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    field: 'isActive',
    headerName: 'Aktiv',
    resizableText: true,
    sortable: true,
    width: 100,
  },
  {
    field: 'id',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    field: 'start',
    headerName: 'Startdatum',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'end',
    headerName: 'Enddatum',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'createdOn',
    headerName: 'Erstellt am',
    resizableText: true,
    sortable: true,
    valueGetter: formatDateInLocalTime,
    width: 300,
  },
  {
    field: 'modifiedOn',
    headerName: 'Geändert am',
    resizableText: true,
    sortable: true,
    valueGetter: formatDateInLocalTime,
    width: 300,
  },
];

export const columnVisibility = {
  createdOn: false,
  modifiedOn: false,
};
