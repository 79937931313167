import { useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { queryKeysCostCenter } from '~/data/costCenter';
import { queryKeysSite } from '~/data/site';
import { useQueryUserData } from '~/data/user';

import ToastService from '~/services/toast.service';

import Address from '~/models/masterdata/Address';
import Site from '~/models/masterdata/Site';

import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import Wizard from '~/components/Wizard';

import { withErrorBoundary } from '~/ui/atoms';

import {
  CostCenters,
  CreateCostCenterButton,
  Permissions,
  SiteInformation,
} from './components';

import { handleSubmit as onSubmit } from './handleSubmit';

export const CreateSiteWizard = withErrorBoundary(() => {
  const queryClient = useQueryClient();

  const {
    data: currentUser,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
    isSuccess: isSuccessUserData,
  } = useQueryUserData(true);

  const companyInfo = currentUser?.companyInfo ?? {};

  const getDefaultSite = () => {
    const site = new Site();
    site.companyId = companyInfo.id;
    site.type = Site.getSiteTypes()[0].id;
    site.address.country = Address.DEFAULT_COUNTRY_CODE.DE;

    return site;
  };

  const [grantPermissions, setGrantPermissions] = useState(true);
  const [grantPermissionsOnCostCenters, setGrantPermissionsOnCostCenters] =
    useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [site, setSite] = useState(getDefaultSite());

  const permissionGrantRef = useRef(null);

  const resetWizard = () => {
    setSite(getDefaultSite());
    setGrantPermissions(true);
    setGrantPermissionsOnCostCenters(true);
  };

  const onOpenWizard = () => {
    Log.productAnalyticsEvent('Open create site wizard', Log.FEATURE.WIZARD);

    setIsOpen(true);
    resetWizard();
  };

  const onCloseWizard = () => {
    Log.productAnalyticsEvent('Close create site wizard', Log.FEATURE.WIZARD);

    setIsOpen(false);
  };

  const getWizardSteps = () => {
    const wizardSteps = [
      {
        component: <SiteInformation setSite={setSite} site={site} />,
        preventNextStep() {
          if (!site.companyId) {
            ToastService.warning(['Bitte wähle eine Firma aus.']);

            Log.productAnalyticsEvent(
              'Missing company',
              Log.FEATURE.WIZARD,
              Log.TYPE.FAILED_VALIDATION,
            );

            return true;
          }
        },
        title: 'Standort-Informationen',
      },
    ];

    // If the user isn't allowed to update the site, the endpoint to assign the cost centers would return 403.
    if (
      UserUtils.isSiteWriteAllowedUser() &&
      UserUtils.isCostCenterReadAllowedUser()
    ) {
      wizardSteps.push({
        component: <CostCenters setSite={setSite} site={site} />,
        title: 'Kostenstellen',
      });
    }

    if (UserUtils.isPermissionGrantAllowedUser()) {
      wizardSteps.push({
        component: (
          <Permissions
            ref={permissionGrantRef}
            grantPermissions={grantPermissions}
            grantPermissionsOnCostCenters={grantPermissionsOnCostCenters}
            setGrantPermissions={setGrantPermissions}
            setGrantPermissionsOnCostCenters={setGrantPermissionsOnCostCenters}
            site={site}
          />
        ),
        title: 'Berechtigungen',
      });
    }

    return wizardSteps.map((step) => ({
      ...step,
      component: <div className="my-8">{step.component}</div>,
    }));
  };

  const handleSubmit = async () => {
    const refetchData = () => {
      queryClient.invalidateQueries({
        queryKey: queryKeysSite.get(site.id),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeysSite.getAll({}),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeysCostCenter.getAll({}),
      });
    };

    onSubmit({
      grantPermissions,
      grantPermissionsOnCostCenters,
      permissionGrantRef,
      refetchData,
      resetWizard,
      setIsOpen,
      setIsSubmitting,
      site,
    });
  };

  return (
    <div>
      <CreateCostCenterButton onClick={onOpenWizard} />
      <Wizard
        open={isOpen}
        title="Standort erstellen"
        wizardSuccess={handleSubmit}
        closeWizard={onCloseWizard}
        steps={getWizardSteps()}
        submittingWizard={isSubmitting}
        unsavedChanges={Site.getDifferentValues(site, getDefaultSite())}
        fullWidth
      />
    </div>
  );
}, 'Daten konnten nicht geladen werden.');
