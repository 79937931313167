import { withErrorBoundary } from '~/ui/atoms';
import { Menu, MenuItem } from '@mui/material';

const ContextMenu = ({
  contextMenu,
  onClose,
  onOpen,
  onOpenInNewTab,
  customMenuItems,
}) => (
  <Menu
    open={Boolean(contextMenu)}
    onClose={onClose}
    anchorReference="anchorPosition"
    anchorPosition={
      contextMenu
        ? {
            left: contextMenu.mouseX,
            top: contextMenu.mouseY,
          }
        : undefined
    }
  >
    {onOpen && <MenuItem onClick={onOpen}>Öffnen</MenuItem>}
    {onOpenInNewTab && (
      <MenuItem onClick={onOpenInNewTab}>In neuem Tab öffnen</MenuItem>
    )}
    {customMenuItems?.map((customMenuItem) => (
      <MenuItem key={customMenuItem.name} onClick={customMenuItem.callback}>
        {customMenuItem.name}
      </MenuItem>
    ))}
  </Menu>
);

export default withErrorBoundary(ContextMenu, null);
