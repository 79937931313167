import { useMemo, useState } from 'react';

import { AddLocation as AddLocationIcon } from '@mui/icons-material';

import IconButton from '~/components/IconButton';

import Log from '~/utils/Log';
import ValueGroup from '~/models/deliveries/ValueGroup';

import { MapDirectDeliveryNoteForm } from '../../deliveryNoteActions/MapDirectDeliveryNoteForm';

export const AssignSiteButton = ({ deliveryNote, id, refreshDeliveryNote }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    Log.info('Open map direct delivery note form');
    Log.productAnalyticsEvent(
      'Open map direct delivery note form',
      Log.FEATURE.SITE_MAPPING,
    );

    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const filteredToSiteSupplierNames = useMemo(
    () =>
      [ValueGroup.getCurrentValue(deliveryNote?.toSiteSupplier?.name)].filter(
        Boolean,
      ),
    [deliveryNote?.toSiteSupplier?.name],
  );

  return (
    <>
      <IconButton
        onClick={handleOpen}
        tooltipTitle="Standort zuweisen."
        size="large"
      >
        <AddLocationIcon className="text-primary500 icon-small" />
      </IconButton>
      <MapDirectDeliveryNoteForm
        issuerIds={[deliveryNote?.issuer?.id]}
        toSiteSupplierNames={filteredToSiteSupplierNames}
        deliveryNoteIds={[id]}
        defaultSelectedSiteId={ValueGroup.getCurrentValue(
          deliveryNote.permittedToSites[0]?.id,
        )}
        defaultSelectedCostCenterId={ValueGroup.getCurrentValue(
          deliveryNote.permittedCostCenters[0]?.id,
        )}
        open={isOpen}
        closeForm={handleClose}
        refreshDeliveryNote={refreshDeliveryNote}
      />
    </>
  );
};
