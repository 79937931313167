import { addressToString } from '~/models/masterdata/Address';
import Site from '~/models/masterdata/Site';

import { dateUtils } from '~/utils/dateUtils';

export const selectSites = (data) => ({
  rows:
    data.data?.map((site) => ({
      ...site,
      address: addressToString(site.address),
      coords: [site.coords?.latitude, site.coords?.longitude]
        .filter(Boolean)
        .join(', '),
      costCenters: site.accountingReferences.map(({ name }) => name).join(', '),
      createdOn: site.createdOn,
      end: dateUtils.getFormattedDate_safe(
        site.end,
        dateUtils.DATE_FORMAT.DD_MM_YYYY,
      ),
      isActive: site.isActive ? Site.IS_ACTIVE.YES : Site.IS_ACTIVE.NO,
      modifiedOn: site.modifiedOn,
      start: dateUtils.getFormattedDate_safe(
        site.start,
        dateUtils.DATE_FORMAT.DD_MM_YYYY,
      ),
      type: Site.getSiteType(site.type),
    })) ?? [],
  sites: data.data ?? [],
  totalCount: data.totalCount ?? 0,
});
