import { useQueryVehiclesInfinite } from '~/data/vehicle';

import { type UUID } from '~/types/common';

import { MultiItemsManager } from './MultiItemsManager';

const selectVehicles = (data) =>
  data?.map(({ id, licensePlate }) => ({
    id,
    name: licensePlate,
  }));

type P = {
  isCreatingNewEntity?: boolean;
  label?: string;
  onChange?: (selectedIds: UUID[]) => void;
  onSaveSelection?: () => void;
  onSelectedItemClick?: (id: UUID) => void;
  parentOrganizationalUnitId?: UUID;
};

export const MultiItemsManagerVehicles = ({
  isCreatingNewEntity = false,
  label = 'Fahrzeuge',
  onChange,
  onSaveSelection,
  onSelectedItemClick,
  parentOrganizationalUnitId,
}: P) => {
  const getterFunctionSelectedItems = (searchString: string) => {
    const query = useQueryVehiclesInfinite({
      filterActive: true,
      filterParentOu: parentOrganizationalUnitId ?? undefined,
      searchString,
    });

    if (!isCreatingNewEntity) {
      return query;
    }

    return Promise.resolve({
      data: [],
      fetchNextPage() {},
      hasNextPage: false,
      isFetchingNextPage: false,
      isLoading: false,
      totalCount: 0,
    });
  };

  const getterFunctionSelectableItems = (searchString: string) =>
    useQueryVehiclesInfinite({
      filterActive: true,
      filterNotParentOu: parentOrganizationalUnitId ?? undefined,
      searchString,
    });

  return (
    <MultiItemsManager
      fieldName="name"
      getterFunctionSelectableItems={getterFunctionSelectableItems}
      getterFunctionSelectedItems={getterFunctionSelectedItems}
      label={label}
      onChange={onChange}
      onSaveSelection={onSaveSelection}
      onSelectedItemClick={onSelectedItemClick}
      selectFunctionSelectableItems={selectVehicles}
      selectFunctionSelectedItems={selectVehicles}
    />
  );
};
