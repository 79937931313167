import { Switch } from 'react-router-dom';

import { ROUTE } from '~/constants/Route';

import { SentryRoute } from '~/services/thirdParty.service';

import { DeliveryOverview } from '../deliveries/deliveryOverview';
import { Settings } from '../settings/Settings';
import ConcreteDiary from '../concreteDiary/ConcreteDiary';
import DashboardOverview from '../dashboard/DashboardOverview';
import DataExchangePage from '../dataExchanges/DataExchangePage';
import DeliveryNote from '../deliveries/deliveryNote/DeliveryNote';
import DeliveryNoteDataQuality from '../deliveries/deliveryNoteDataQuality/DeliveryNoteDataQuality';
import DeliveryNoteForm from '../deliveries/deliveryNoteForm/DeliveryNoteForm';
import Home from '../home/Home';
import Invoice from '../invoices/Invoice';
import InvoiceOverview from '../invoices/invoiceOverview/InvoiceOverview';
import ServiceNoteForm from '../deliveries/serviceNoteForm/ServiceNoteForm';
// The entire UserMetrics feature can not be implemented with the current paginated API endpoints.
// Also, nobody uses it.
// import UserMetrics from '../userMetrics/UserMetrics';

export const Routes = () => (
  <Switch>
    <SentryRoute path={ROUTE.HOME.ROUTE} component={Home} exact />
    <SentryRoute path="/home" component={Home} exact />
    <SentryRoute
      path={ROUTE.DASHBOARD.ROUTE}
      component={DashboardOverview}
      exact
    />
    {/*
      // The entire UserMetrics feature can not be implemented with the current paginated API endpoints.
      // Also, nobody uses it.
      <SentryRoute
        path={ROUTE.USER_METRICS.ROUTE}
        component={UserMetrics}
        exact
      />
    */}
    <SentryRoute
      path={ROUTE.DELIVERIES.ROUTE}
      component={DeliveryOverview}
      exact
    />
    <SentryRoute
      path={ROUTE.DELIVERY_NOTE.ROUTE + '/:id'}
      component={DeliveryNote}
    />
    <SentryRoute
      path={ROUTE.CREATE_DELIVERY_NOTE.ROUTE}
      component={DeliveryNoteForm}
      exact
    />
    <SentryRoute
      path={ROUTE.CREATE_SERVICE_NOTE.ROUTE}
      component={ServiceNoteForm}
      exact
    />
    <SentryRoute
      path={ROUTE.CONCRETE_DIARY.ROUTE}
      component={ConcreteDiary}
      exact
    />
    <SentryRoute
      path={ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE + '/:id'}
      component={DeliveryNoteDataQuality}
    />
    <SentryRoute
      path={ROUTE.OUTGOING_INVOICES.ROUTE}
      component={InvoiceOverview}
      exact
    />
    <SentryRoute
      path={ROUTE.INCOMING_INVOICES.ROUTE}
      component={InvoiceOverview}
      exact
    />
    <SentryRoute
      path={ROUTE.OUTGOING_INVOICE.ROUTE + '/:id'}
      component={Invoice}
    />
    <SentryRoute
      path={ROUTE.INCOMING_INVOICE.ROUTE + '/:id'}
      component={Invoice}
    />
    <SentryRoute path={ROUTE.INVOICE.ROUTE + '/:id'} component={Invoice} />
    <SentryRoute path={ROUTE.SETTINGS.ROUTE} component={Settings} />
    <SentryRoute path={ROUTE.SETTINGS_MASTERDATA.ROUTE} component={Settings} />
    <SentryRoute
      path={ROUTE.DATA_EXCHANGE.ROUTE}
      component={DataExchangePage}
    />
  </Switch>
);
