import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import Log from '~/utils/Log';

import CustomField from '../customData/CustomField';

export default class SignatureField {
  constructor(signatureField) {
    this.id = signatureField?.id ?? null;
    this.name = signatureField?.name ?? null;
    this.displayName =
      signatureField?.display_name ?? signatureField?.displayName ?? null;
    this.pathKey = this.getPath(signatureField);
    this.customFieldId = this.getCustomFieldId(signatureField);
    this.required = signatureField?.required ?? false;
    this.role = signatureField?.role ?? SignatureField.ROLE.RECIPIENT.KEY;
    this.companyId =
      signatureField?.company_id ?? signatureField?.companyId ?? null;
    this.siteId = signatureField?.site_id ?? signatureField?.siteId ?? null;
    this.costCenterId =
      signatureField?.accounting_reference_id ??
      signatureField?.accountingReferenceId ??
      signatureField?.cost_center_id ??
      signatureField?.costCenterId ??
      null;
    this.defaultMode =
      signatureField?.default_mode ??
      signatureField?.defaultMode ??
      SignatureField.DEFAULT_MODE.EMPTY.KEY;
    this.defaultValue =
      signatureField?.default_value ?? signatureField?.defaultValue ?? null;
  }

  getPath(signatureField) {
    // Default value
    if (!signatureField?.path) {
      return SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY;
    }

    // Extract only until additional_party_data because the path also includes the key of the custom field
    if (
      JSON.stringify(signatureField.path.slice(0, 2)) ===
      JSON.stringify(SignatureField.PATH.CUSTOM_FIELD_GLOBAL.PATH)
    ) {
      return SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY;
    }

    if (
      JSON.stringify(signatureField.path.slice(0, 6)) ===
      JSON.stringify(SignatureField.PATH.CUSTOM_FIELD_LOCAL.PATH)
    ) {
      return SignatureField.PATH.CUSTOM_FIELD_LOCAL.KEY;
    }

    return SignatureField.getKeyFromPath(signatureField.path);
  }

  getCustomFieldId(signatureField) {
    if (!signatureField?.path) {
      return null;
    }

    if (this.pathKey === SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY) {
      return CustomField.getIdFromKey(signatureField.path[2]);
    }

    if (this.pathKey === SignatureField.PATH.CUSTOM_FIELD_LOCAL.KEY) {
      return CustomField.getIdFromKey(signatureField.path[6]);
    }

    return null;
  }

  static getDifferentValues(signatureFieldA, signatureFieldB) {
    const differentValues = [];

    if (signatureFieldA?.id !== signatureFieldB?.id) {
      differentValues.push('ID');
    }

    if (signatureFieldA?.name !== signatureFieldB?.name) {
      differentValues.push('Name');
    }

    if (signatureFieldA?.displayName !== signatureFieldB?.displayName) {
      differentValues.push('Anzeige-Name');
    }

    if (signatureFieldA?.pathKey !== signatureFieldB?.pathKey) {
      differentValues.push('Zugriff auf');
    }

    if (signatureFieldA?.customFieldId !== signatureFieldB?.customFieldId) {
      differentValues.push('Flexibles Feld');
    }

    if (signatureFieldA?.required !== signatureFieldB?.required) {
      differentValues.push('Verpflichtend');
    }

    if (signatureFieldA?.role !== signatureFieldB?.role) {
      differentValues.push('Rolle');
    }

    if (signatureFieldA?.companyId !== signatureFieldB?.companyId) {
      differentValues.push('Firma');
    }

    if (signatureFieldA?.siteId !== signatureFieldB?.siteId) {
      differentValues.push('Standort');
    }

    if (signatureFieldA?.costCenterId !== signatureFieldB?.costCenterId) {
      differentValues.push('Kostenstelle');
    }

    if (signatureFieldA?.defaultMode !== signatureFieldB?.defaultMode) {
      differentValues.push('Default Wert');
    }

    if (signatureFieldA?.defaultValue !== signatureFieldB?.defaultValue) {
      differentValues.push('Default Wert');
    }

    return differentValues;
  }

  static getSignatureFieldRoles() {
    return Object.keys(SignatureField.ROLE).map((x) => {
      return {
        id: SignatureField.ROLE[x].KEY,
        name: SignatureField.ROLE[x].STRING,
      };
    });
  }

  static getRoleString(key) {
    const role = Object.keys(SignatureField.ROLE).find(
      (x) => SignatureField.ROLE[x].KEY === key,
    );

    if (!role) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid signature field role: ' + key),
      );
      return null;
    }

    return SignatureField.ROLE[role].STRING;
  }

  static getKeyFromPath(path) {
    const x = Object.keys(SignatureField.PATH).find(
      (x) =>
        JSON.stringify(SignatureField.PATH[x].PATH) === JSON.stringify(path),
    );

    if (!x) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          'Invalid signature field path: ' + JSON.stringify(path),
        ),
      );
      return null;
    }

    return SignatureField.PATH[x].KEY;
  }

  static getPathStringFromKey(key) {
    const x = Object.keys(SignatureField.PATH).find(
      (x) => SignatureField.PATH[x].KEY === key,
    );

    if (!x) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          'Invalid signature field path key: ' + key,
        ),
      );
      return null;
    }

    return SignatureField.PATH[x].STRING;
  }

  static getPathFromKey(key) {
    const x = Object.keys(SignatureField.PATH).find(
      (x) => SignatureField.PATH[x].KEY === key,
    );

    if (!x) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          'Invalid signature field path key: ' + key,
        ),
      );
      return null;
    }

    return SignatureField.PATH[x].PATH;
  }

  static getPathFromKeyAndCustomFields(key, customFieldId, customFields) {
    if (
      key !== SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY &&
      key !== SignatureField.PATH.CUSTOM_FIELD_LOCAL.KEY
    ) {
      return SignatureField.getPathFromKey(key);
    }

    const customField = customFields.find(
      (customField) => customField.id === customFieldId,
    );

    if (!customField) {
      Log.error('Failed to find custom field. id: ' + customFieldId);
      return [];
    }

    // Push the custom field key to the end of the path (either on the global or local path).
    return [
      ...(key === SignatureField.PATH.CUSTOM_FIELD_GLOBAL.KEY
        ? SignatureField.PATH.CUSTOM_FIELD_GLOBAL.PATH
        : SignatureField.PATH.CUSTOM_FIELD_LOCAL.PATH),
      customField.key,
    ];
  }

  static getDefaultModes() {
    return Object.keys(SignatureField.DEFAULT_MODE).map((x) => {
      return {
        id: SignatureField.DEFAULT_MODE[x].KEY,
        name: SignatureField.DEFAULT_MODE[x].STRING,
      };
    });
  }

  static ROLE = {
    CARRIER: {
      KEY: 'carrier',
      STRING: 'Spediteur',
    },
    RECIPIENT: {
      KEY: 'recipient',
      STRING: 'Abnehmer',
    },
    SUPPLIER: {
      KEY: 'supplier',
      STRING: 'Lieferant',
    },
  };
  static TYPE = {
    CUSTOM_FIELD: {
      KEY: 'custom_field',
    },
    DATETIME: {
      KEY: 'datetime',
    },
    LEGAL_ORGANIZATION: {
      KEY: 'legal_organization',
    },
  };
  static PATH = {
    BUYER: {
      KEY: 'buyer',
      PATH: ['transaction', 'agreement', 'buyer', 'legal_organization'],
      STRING: 'Auftraggeber',
      TYPE: SignatureField.TYPE.LEGAL_ORGANIZATION.KEY,
    },
    CARRIER: {
      KEY: 'carrier',
      PATH: [
        'transaction',
        'delivery',
        'ship_from',
        'consignment',
        'carrier',
        'legal_organization',
      ],
      STRING: 'Spediteur',
      TYPE: SignatureField.TYPE.LEGAL_ORGANIZATION.KEY,
    },
    CUSTOM_FIELD_GLOBAL: {
      KEY: 'custom_field_global',
      PATH: ['header', 'additional_party_data'],
      STRING: 'Flexibles Feld (Lieferung)',
      TYPE: SignatureField.TYPE.CUSTOM_FIELD.KEY,
    },
    CUSTOM_FIELD_LOCAL: {
      KEY: 'custom_field_local',
      PATH: [
        'transaction',
        'logistics_package',
        null,
        'line_item',
        null,
        'additional_party_data',
      ],
      STRING: 'Flexibles Feld (Artikel)',
      TYPE: SignatureField.TYPE.CUSTOM_FIELD.KEY,
      // PATH: []
    },
    EXECUTION_ARRIVED: {
      KEY: 'execution_arrived',
      PATH: ['transaction', 'delivery', 'execution', 'arrived'],
      STRING: 'Ankunft',
      TYPE: SignatureField.TYPE.DATETIME.KEY,
    },
    EXECUTION_BEGIN_EXECUTION: {
      KEY: 'execution_begin_execution',
      PATH: ['transaction', 'delivery', 'execution', 'begin_execution'],
      STRING: 'Beginn Lieferung',
      TYPE: SignatureField.TYPE.DATETIME.KEY,
    },
    EXECUTION_BEGIN_LOADING: {
      KEY: 'execution_begin_loading',
      PATH: ['transaction', 'delivery', 'execution', 'begin_loading'],
      STRING: 'Beginn Beladung',
      TYPE: SignatureField.TYPE.DATETIME.KEY,
    },
    EXECUTION_BEGIN_UNLOADING: {
      KEY: 'execution_begin_unloading',
      PATH: ['transaction', 'delivery', 'execution', 'begin_unloading'],
      STRING: 'Beginn Entladung',
      TYPE: SignatureField.TYPE.DATETIME.KEY,
    },
    EXECUTION_END_EXECUTION: {
      KEY: 'execution_end_execution',
      PATH: ['transaction', 'delivery', 'execution', 'end_execution'],
      STRING: 'Ende Lieferung',
      TYPE: SignatureField.TYPE.DATETIME.KEY,
    },
    EXECUTION_END_LOADING: {
      KEY: 'execution_end_loading',
      PATH: ['transaction', 'delivery', 'execution', 'end_loading'],
      STRING: 'Ende Beladung',
      TYPE: SignatureField.TYPE.DATETIME.KEY,
    },
    EXECUTION_END_UNLOADING: {
      KEY: 'execution_end_unloading',
      PATH: ['transaction', 'delivery', 'execution', 'end_unloading'],
      STRING: 'Ende Entladung',
      TYPE: SignatureField.TYPE.DATETIME.KEY,
    },
    RECIPIENT: {
      KEY: 'recipient',
      PATH: ['transaction', 'delivery', 'ship_to', 'legal_organization'],
      STRING: 'Abnehmer',
      TYPE: SignatureField.TYPE.LEGAL_ORGANIZATION.KEY,
    },
    SELLER: {
      KEY: 'seller',
      PATH: ['transaction', 'agreement', 'seller', 'legal_organization'],
      STRING: 'Auftragnehmer',
      TYPE: SignatureField.TYPE.LEGAL_ORGANIZATION.KEY,
    },
    SUPPLIER: {
      KEY: 'supplier',
      PATH: ['transaction', 'delivery', 'ship_from', 'legal_organization'],
      STRING: 'Lieferant',
      TYPE: SignatureField.TYPE.LEGAL_ORGANIZATION.KEY,
    },
  };
  static DEFAULT_MODE = {
    EMPTY: {
      KEY: 'empty',
      STRING: 'Kein Default Wert',
    },
    FIXED: {
      KEY: 'fixed',
      STRING: 'Bestimmter Wert',
    },
    LAST: {
      KEY: 'last',
      STRING: 'Letzter ausgewählter Wert',
    },
  };
}
