import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysCompany } from './queryKeys';

/**
 * Deletes a company logo.
 *
 * @param {string} companyId - The ID of the company for which to delete the logo.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Company/operation/remove_company_logo_company__id__logo_delete
 */
export const deleteCompanyLogo = async (companyId: UUID) => {
  try {
    const response = await vestigasApi
      .delete(ENDPOINT.COMPANY.DELETE_LOGO(companyId))
      .json();

    return response;
  } catch (error) {
    Log.error('Error deleting company logo', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query mutation hook for deleting a company logo.
 *
 * This hook provides functionality to:
 * - Delete the logo image for a company
 * - Automatically invalidate the company query cache on completion
 *
 * @param {UUID} companyId - The ID of the company to delete the logo for
 * @returns {UseMutationResult} A mutation object containing the delete function and status
 *
 * @example
 * const mutation = useMutationCompanyLogoDelete(companyId);
 * mutation.mutate();
 */
export const useMutationCompanyLogoDelete = (companyId: UUID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => deleteCompanyLogo(companyId),
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: queryKeysCompany.get(companyId),
      });
    },
    onSuccess() {
      // ... show toast or something
    },
  });
};
