import { createSlice } from '@reduxjs/toolkit';
import ArrayUtils from '~/utils/arrayUtils';
import { LOADING_STATE } from '~/constants/LoadingState';

const userGroupsSlice = createSlice({
  initialState: {
    userGroupRowCount: 0,
    userGroupRowCountLoading: LOADING_STATE.NOT_LOADED,
    userGroupRowPages: [],
    userGroupRowPagesVersion: 0,
    userGroups: [],
    userGroupsLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'userGroups',
  reducers: {
    replaceUserGroup(state, parameter) {
      const { payload } = parameter;

      const newUserGroups = ArrayUtils.removeByKey(
        state.userGroups,
        'id',
        payload.id,
      );
      newUserGroups.push(payload);

      state.userGroups = newUserGroups;
    },
    replaceUserGroups(state, parameter) {
      const { payload } = parameter;

      state.userGroups = ArrayUtils.sortByKey(payload, 'name');
      state.userGroupsLoading = LOADING_STATE.SUCCEEDED;
    },
    setUserGroupsLoading(state, parameter) {
      const { payload } = parameter;

      state.userGroupsLoading = payload;
    },
  },
});

const { actions, reducer } = userGroupsSlice;
export const { replaceUserGroup, replaceUserGroups, setUserGroupsLoading } =
  actions;
export default reducer;
