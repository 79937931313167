import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import Log from '~/utils/Log';

import { queryKeysIgnoredArticle } from './queryKeys';

export type IgnoredArticlesQueryParams = Record<string, unknown>;

export type IgnoredArticlesListItem = {
  name: string;
  subscriptionType: string;
  subscriptionEvents: string[];
  isActive: boolean;
  includeDlnData: boolean;
  restrictToCompanies: string[];
  authType: string;
  keyAuthHeader: string;
  callbackUrl: string;
  id: string;
};

type IgnoredArticlesResponse = {
  customerSubscriptions: readonly IgnoredArticlesListItem[];
  count: number;
};

const defaultQueryParams: IgnoredArticlesQueryParams = {};

/**
 * Generates the query options for the IgnoredArticles query so they can be shared between the useQuery hook and the prefetching.
 */
export const getIgnoredArticlesQueryOptions = ({
  queryParams,
  options = {},
}: {
  queryParams: Partial<IgnoredArticlesQueryParams>;
  options?: Omit<
    UseQueryOptions<IgnoredArticlesResponse, Error>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryFn: async () => fetchIgnoredArticles(qp),
    queryKey: queryKeysIgnoredArticle.getAll(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all IgnoredArticles from the API.
 * @param queryParams - The query parameters for fetching IgnoredArticles.
 * @returns The category data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Invoice/operation/get_all_ign_articles_invoice_ignored_article_get
 */
export const fetchIgnoredArticles = async (
  queryParams: Partial<IgnoredArticlesQueryParams>,
): Promise<IgnoredArticlesResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  for (const key of Object.keys(qp)) {
    if (qp[key] === undefined) {
      delete qp[key];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.IGNORED_ARTICLES.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<IgnoredArticlesResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching IgnoredArticles', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all IgnoredArticles with given query parameters.
 * Handles pagination and prefetches the next page of IgnoredArticles for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching IgnoredArticles.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryIgnoredArticles = (
  queryParams: Partial<IgnoredArticlesQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getIgnoredArticlesQueryOptions({
        options,
        queryParams: {
          ...queryParams,
        },
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery<IgnoredArticlesResponse, Error>({
    ...getIgnoredArticlesQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all IgnoredArticles with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching IgnoredArticles.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQueryIgnoredArticlesInfinite = (
  queryParams: Partial<IgnoredArticlesQueryParams>,
  options: InfiniteQueryOptionsType<IgnoredArticlesResponse, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async () =>
      fetchIgnoredArticles({
        ...qp,
      }),
    queryKey: queryKeysIgnoredArticle.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<IgnoredArticlesResponse>(qp, infiniteQueryOptions);
};
