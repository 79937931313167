import {
  createRef,
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';

import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { PermissionGrantPicker } from './PermissionGrantPicker';

export const Permissions = withErrorBoundary(
  forwardRef(({ grantPermissions, setGrantPermissions }, ref) => {
    const [grantPermissionsOnCostCenters, setGrantPermissionsOnCostCenters] =
      useState(true);
    const [permissionGrantPickerList, setPermissionGrantPickerList] = useState(
      [],
    );

    useEffect(() => {
      addNewPermissionGrantPicker();
    }, []);

    useImperativeHandle(ref, () => ({
      async submit(userId) {
        const promises = permissionGrantPickerList.map((picker) =>
          picker.ref.current?.submit(userId, grantPermissionsOnCostCenters),
        );

        return PromiseUtils.allResolved(promises);
      },
    }));

    const handleRadioChange = (event) => {
      Log.info(
        'Change form value of grant permissions radio button',
        {
          from: grantPermissions,
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change grant permissions radio button in create user wizard',
        Log.FEATURE.WIZARD,
      );

      setGrantPermissions(event.target.value === 'grant_permissions_yes');
    };

    const handleGrantPermissionsOnCostCentersRadioChange = (event) => {
      Log.info(
        'Change form value of grant permissions on cost centers radio button',
        {
          from: grantPermissionsOnCostCenters,
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change grant permissions on cost centers radio button in create user wizard',
        Log.FEATURE.WIZARD,
      );

      setGrantPermissionsOnCostCenters(
        event.target.value === 'cost_centers_yes',
      );
    };

    const addNewPermissionGrantPicker = () => {
      const newRef = createRef();

      setPermissionGrantPickerList((previousList) => [
        ...previousList,
        {
          key: previousList.length,
          ref: newRef,
        },
      ]);
    };

    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12}>
          <h3 className="main-text mt-0">
            Sollen für den Benutzer Berechtigungen vergeben werden? (kann
            jederzeit geändert werden)
          </h3>
          <RadioGroup
            onChange={handleRadioChange}
            row
            value={
              grantPermissions
                ? 'grant_permissions_yes'
                : 'grant_permissions_no'
            }
          >
            <FormControlLabel
              className="mr-12"
              control={<Radio />}
              label="Ja (empfohlen)"
              value="grant_permissions_yes"
            />
            <FormControlLabel
              control={<Radio />}
              label="Nein"
              value="grant_permissions_no"
            />
          </RadioGroup>
        </Grid>
        {grantPermissions && (
          <>
            <Grid item lg={12} xs={12}>
              <h3 className="main-text mt-4">
                Sollen die Berechtigungen jeweils auf die verbundenen Standorte
                und Kostenstellen vergeben werden?
              </h3>
              <RadioGroup
                onChange={handleGrantPermissionsOnCostCentersRadioChange}
                row
                value={
                  grantPermissionsOnCostCenters
                    ? 'cost_centers_yes'
                    : 'cost_centers_no'
                }
              >
                <FormControlLabel
                  className="mr-12"
                  control={<Radio />}
                  label="Ja (empfohlen)"
                  value="cost_centers_yes"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Nein"
                  value="cost_centers_no"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <div className="flex flex-col gap-4">
                {permissionGrantPickerList.map((permissionGrantPicker) => (
                  <PermissionGrantPicker
                    key={permissionGrantPicker.key}
                    ref={permissionGrantPicker.ref}
                    grantPermissionsOnCostCenters={
                      grantPermissionsOnCostCenters
                    }
                  />
                ))}
                <Button
                  className="mt-4"
                  onClick={addNewPermissionGrantPicker}
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  Weitere Berechtigung hinzufügen
                </Button>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    );
  }),
  'Daten konnten nicht geladen werden.',
);
