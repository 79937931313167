import FilterProps from './FilterProps';
import ArrayUtils from '~/utils/arrayUtils';
import FilterNew from './FilterNew';
import { FILTER_OPERATOR_BACKEND } from './constants';

export default class BackendFilter {
  constructor(backendFilter) {
    // Use this in the future for the backend filters
    this.name = backendFilter.name;
    this.comp = backendFilter.comp;
    this.value = backendFilter.value;
    this.caseSensitive = backendFilter.caseSensitive;
  }

  /**
   * Gets all filters that can be used in API requests to the backend based on the supported fields and operators.
   *
   * @return {Array} An array of filter configurations for each field that can be filtered.
   */
  static getApplicableBackendFilters() {
    // This code is not clean and must be refactored in the future:
    // dlnProperties is actually "field". Thus, we need to filter FilterProps.FILTER_MAPPING by "page".
    // Currently, this is not needed because we only have filters for delivery notes. This will change as soon
    // as we also start to load paginated invoices.

    // Get all fields that can be filtered
    const filterableFields = ArrayUtils.removeDuplicates(
      FilterProps.FILTER_MAPPING.filter(({ BACKEND_FILTER }) =>
        Boolean(BACKEND_FILTER),
      ).map(({ FIELD }) => FIELD),
    );

    const filters = [];

    // Generate the filter configurations for each field that can be filtered
    for (const field of filterableFields) {
      for (const operator of BackendFilter.getApplicableBackendOperators()) {
        filters.push(new FilterNew({ field, operator }));
      }
    }

    return filters;
  }

  /**
   * Get all filter operators that can be used in API requests to the backend.
   *
   * @return {Array} An array of applicable backend operators.
   */
  static getApplicableBackendOperators() {
    return FilterProps.OPERATOR_STRING_MAPPING.map(
      ({ OPERATOR }) => OPERATOR,
    ).filter(Boolean);
  }

  /**
   * Filter operators supported in API requests to the backend.
   */
  static OPERATOR = FILTER_OPERATOR_BACKEND;
  /**
   * Field names that can be filtered in the backend.
   */
  static FILTER = {
    // From Filter Group -------------------------------------------------------
    ARTICLE_NAME: 'article_name',
    CONFIRMED_ACCOUNTING_REFERENCE_ID: 'confirmed_accounting_reference_id',
    CONFIRMED_SITE_ID: 'confirmed_site_id',
    CONFIRMED_SITE_NAME: 'confirmed_site_name',
    COST_CENTER: 'confirmed_accounting_reference_name',
    DLN_ACCEPT_STATE: 'dln_accept_state',
    // settled status -> missing
    // to site supplier trade contact -> missing
    // permitted to sites -> missing (should be authorized_site_name)
    // permitted cost centers -> missing (should be authorized_accounting_reference_name)
    //
    // From Filter Model -------------------------------------------------------
    DLN_NR: 'dln_nr',

    LICENSE_PLATE_NUMBER: 'license_plate_number',

    LOADING_LOCATION: 'loading_location',

    PROCESS_STATE: 'process_state',

    RECIPIENT_NAME: 'recipient_name',
    SUPPLIER_ASSIGNED_SITE_NAME: 'supplier_assigned_site_name',
    SUPPLIER_NAME: 'supplier_name',
    // additional party data and custom fields -> missing
    // Bestellreferenz Auftraggeber und Auftragnehmer -> missing
    // carrier -> missing
    // comments -> missing
    // Menge, Einheit -> missing
    // to site supplier trade address -> missing
    // trader -> missing
    //
    // From Filter Model -------------------------------------------------------
    // Menge, Einheit -> missing
    // permitted cost centers -> missing (should be authorized_accounting_reference_name)
    // permitted to sites -> missing (should be authorized_site_name)
    // settled status -> missing
    // to site supplier trade address -> missing
    // to site supplier trade contact -> missing
    // trader -> missing
  };
}
