import SignatureRoles from '~/models/masterdata/SignatureRoles';

export const getDefaultSignatureRoleOptions = (signatureRoles = {}) => {
  const defaultSignatureRoleOptions = [];

  if (signatureRoles.supplier) {
    defaultSignatureRoleOptions.push({
      id: SignatureRoles.SIGNATURE_ROLE.SUPPLIER.KEY,
      name: SignatureRoles.SIGNATURE_ROLE.SUPPLIER.STRING,
    });
  }

  if (signatureRoles.carrier) {
    defaultSignatureRoleOptions.push({
      id: SignatureRoles.SIGNATURE_ROLE.CARRIER.KEY,
      name: SignatureRoles.SIGNATURE_ROLE.CARRIER.STRING,
    });
  }

  if (signatureRoles.recipient) {
    defaultSignatureRoleOptions.push({
      id: SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY,
      name: SignatureRoles.SIGNATURE_ROLE.RECIPIENT.STRING,
    });
  }

  return defaultSignatureRoleOptions;
};
