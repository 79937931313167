import { PersonAdd as PersonAddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { MissingPermissionsTooltip } from '~/utils/componentUtils';

import UserUtils from '~/utils/userUtils';

export const CreateUserButton = ({ onClick }) => {
  const canCreateUser = UserUtils.isUserCreateAllowedUser();

  const CreateButton = (
    <Button
      className={canCreateUser ? 'primary-button' : 'disabled-primary-button'}
      onClick={onClick}
      startIcon={<PersonAddIcon />}
      disabled={!canCreateUser}
    >
      Benutzer erstellen
    </Button>
  );

  if (canCreateUser) {
    return CreateButton;
  }

  return <MissingPermissionsTooltip>{CreateButton}</MissingPermissionsTooltip>;
};
