import ms from 'ms';
import { useEffect, useState } from 'react';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
} from '@mui/icons-material';
import { Popover } from '@mui/material';

import ArrayUtils from '~/utils/arrayUtils';
import ComponentUtils from '~/utils/componentUtils';
import Log from '~/utils/Log';

import { Spinner } from '~/components/Spinner';

export const Path = ({ id, Model, onOpenEntity, path, useQueriesEntities }) => {
  const [fullPath, setFullPath] = useState([]); // Contains the full path with all elements that are passed from the parent component.
  const [pathToTrackWraps, setPathToTrackWraps] = useState([]); // Needed to check when flex box wraps. Is extended incrementally with one element until flex box wraps.
  const [displayedPath, setDisplayedPath] = useState([]); // Is the path that is displayed to the user. All elements from fullPath are either displayed to the user or hidden in the popover.
  const [hiddenPath, setHiddenPath] = useState([]); // Contains the elements that would be too much. Are therefore hidden in the popover.
  const [wrappedIndex, setWrappedIndex] = useState(null); // Is needed to determine until which element the path should be displayed and which part should be hidden.
  const [anchorElement, setAnchorElement] = useState(null);

  const defaultName = '…';
  const wrappedItemsClassName = `wrapped-items-class-name-${id}`;

  const entityQueries = useQueriesEntities(path, {
    staleTime: ms('5m'),
  });

  const isLoading = entityQueries.some(({ isLoading }) => isLoading);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const pathElements = entityQueries.map((query, index) => {
      if (query.error || !query.data) {
        Log.error(`Failed to load entity: ${path[index]}`);
        Log.productAnalyticsEvent(
          'Failed to load entity in organizational unit or user group path',
          Log.FEATURE.OTHER_FEATURE,
          Log.TYPE.ERROR,
        );

        return new Model({
          id: path[index],
          name: defaultName,
        });
      }

      return new Model(query.data);
    });

    setFullPath(ArrayUtils.sortByKeyValues(pathElements, path, 'id'));
  }, [entityQueries.map((q) => q.dataUpdatedAt).join(','), path]);

  useEffect(() => {
    // Detect the wrap. In case of a wrap, set the index of the path element that causes the wrap.
    const wrappedItems = ComponentUtils.detectWrap(wrappedItemsClassName);
    if (wrappedItems.length > 0) {
      setWrappedIndex(pathToTrackWraps.length);
      return;
    }

    // Extend the path that is used to track when the wrap happens.
    if (fullPath.length > pathToTrackWraps.length) {
      setPathToTrackWraps(
        fullPath.slice(
          fullPath.length - pathToTrackWraps.length - 1,
          fullPath.length,
        ),
      );
    }

    // If the complete path doesn't wrap, display it to the user.
    if (fullPath.length === pathToTrackWraps.length) {
      setDisplayedPath(fullPath);
    }
  }, [JSON.stringify(fullPath), JSON.stringify(pathToTrackWraps)]);

  useEffect(() => {
    if (!wrappedIndex) {
      return;
    }

    // If the wrap has been detected, display the later part of the path and hide the first items in the popover.
    setDisplayedPath(
      fullPath.slice(fullPath.length - wrappedIndex + 1, fullPath.length),
    );
    setHiddenPath(fullPath.slice(0, fullPath.length - wrappedIndex + 2));
  }, [wrappedIndex]);

  const onClick = (entity) => {
    // User isn't authorized to see entity.
    if (entity.name === defaultName) {
      return;
    }

    onOpenEntity(entity);
  };

  // If the hidden path has been set, it is clear that the first element in the path opens the popover.
  const pathContainsPopover = () => {
    return hiddenPath.length > 0;
  };

  const getPathElementsAsButtons = (path, openHiddenPath) => {
    return path.map((entity, index) => (
      <div
        key={entity.id}
        className="cursor-pointer rounded-md p-2 hover:bg-gray-200"
        onMouseDown={(event) =>
          // Open the popover if the clicked path element is the first one and the path contains a popover.
          openHiddenPath && index === 0 && pathContainsPopover()
            ? setAnchorElement(event.currentTarget)
            : onClick(entity)
        }
      >
        {entity.name}
      </div>
    ));
  };

  if (isLoading) {
    return (
      <div className="flex h-14 w-full items-center justify-center rounded-md bg-gray-200 p-2">
        <Spinner />
      </div>
    );
  }

  if (displayedPath.length > 0) {
    // Display the path to be displayed.
    return (
      <>
        <div className="flex h-14 items-center gap-2 rounded-md bg-gray-200 p-2">
          <div className="flex w-full items-center gap-2">
            {getPathElementsAsButtons(displayedPath, true).map(
              (item, index) => (
                <div
                  key={displayedPath[index].id}
                  className="flex items-center gap-2"
                >
                  {index === 1 && pathContainsPopover() ? (
                    <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '20px' }} />
                  ) : null}
                  {index > 1 || (index === 1 && !pathContainsPopover()) ? (
                    <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />
                  ) : null}
                  {item}
                </div>
              ),
            )}
          </div>
        </div>
        <Popover
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          onClose={() => setAnchorElement(null)}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <div className="flex items-center gap-4 rounded-md bg-gray-200 p-2">
            <div className="flex w-full flex-wrap items-center gap-2">
              {getPathElementsAsButtons(hiddenPath).map((item, index) => (
                <div
                  key={hiddenPath[index].id}
                  className="flex items-center gap-2"
                >
                  {index > 0 ? (
                    <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />
                  ) : null}
                  {item}
                </div>
              ))}
            </div>
          </div>
        </Popover>
      </>
    );
  }

  // Display the tracking path so that it can be detected whether a wrap would happen.
  return (
    <div className="flex items-center gap-2 rounded-md bg-gray-200 p-2">
      <div className="flex w-full flex-wrap items-center gap-2">
        {getPathElementsAsButtons(pathToTrackWraps).map((item, index) => (
          <div
            key={pathToTrackWraps[index].id}
            className={'flex items-center gap-2 ' + wrappedItemsClassName}
          >
            {index > 0 ? (
              <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />
            ) : null}
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
