import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { ROUTE } from '~/constants/Route';

import { openBackdrop, closeBackdrop } from '~/redux/backdropSlice';

import ArrayUtils from './arrayUtils';
import BrowserUtils from './browserUtils';

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="top"
    {...props}
    title={props.title ?? ''}
    classes={{ popper: className }}
    onMouseEnter={props.onMouseEnter}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
  },
}));

export const LightTooltipSpan = styled(({ className, ...props }) => (
  // Wrap children in span so that tooltip also shows up for disabled elements such as buttons.
  <Tooltip
    placement="top"
    {...props}
    children={<span>{props.children}</span>}
    title={props.title ?? ''}
    classes={{ popper: className }}
    onMouseEnter={props.onMouseEnter}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
  },
}));

export const LightTooltipWide = styled(({ className, ...props }) => (
  <Tooltip
    placement="top"
    {...props}
    title={props.title ?? ''}
    classes={{ popper: className }}
    onMouseEnter={props.onMouseEnter}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    maxWidth: 600,
  },
}));

export const LightTooltipWideSpan = styled(({ className, ...props }) => (
  // Wrap children in span so that tooltip also shows up for disabled elements such as buttons.
  <Tooltip
    placement="top"
    {...props}
    children={<span>{props.children}</span>}
    title={props.title ?? ''}
    classes={{ popper: className }}
    onMouseEnter={props.onMouseEnter}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    maxWidth: 600,
  },
}));

export const DarkTooltipWide = styled(({ className, ...props }) => (
  <Tooltip
    placement="top"
    {...props}
    title={props.title ?? ''}
    classes={{ popper: className }}
    onMouseEnter={props.onMouseEnter}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#485163',
    boxShadow: theme.shadows[1],
    color: 'white',
    fontSize: 11,
    maxHeight: 400,
    maxWidth: 600,
    overflow: 'scroll',
  },
}));

export const DarkTooltipExtraWide = styled(({ className, ...props }) => (
  <Tooltip
    placement="top"
    {...props}
    title={props.title ?? ''}
    classes={{ popper: className }}
    onMouseEnter={props.onMouseEnter}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#485163',
    boxShadow: theme.shadows[1],
    color: 'white',
    fontSize: 11,
    maxHeight: 400,
    maxWidth: 900,
    overflow: 'scroll',
  },
}));

export const FunctionalLink = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();

    if (location?.pathname === props.to) {
      return;
    }

    if (!props.backdropDuration) {
      history.push(props.to);
      return;
    }

    const id = uuidv4();

    dispatch(openBackdrop({ id, message: props.backdropMessage ?? '' }));

    setTimeout(() => {
      history.push(props.to);
      dispatch(closeBackdrop({ id }));
    }, props.backdropDuration ?? 0);
  };

  // setTimeout can't be used because browser would block window.open because of popup
  const onContextMenu = (e) => {
    e.preventDefault();
    BrowserUtils.openNewTab(props.to);
  };

  return (
    <Link
      to={props.to}
      onClick={handleClick}
      onContextMenu={onContextMenu}
      className={props.className}
      style={{ textDecoration: 'none' }}
    >
      {props.message}
    </Link>
  );
};

export const DeliveryNoteLink = (props) => {
  if (!props.id) {
    return 'Lieferung ' + (props.number ?? '');
  }

  return (
    <FunctionalLink
      to={ROUTE.DELIVERY_NOTE.ROUTE + '/' + props.id}
      message={'Lieferung ' + (props.number ?? '')}
      className={props.className ?? 'text-primary500 bold'}
    />
  );
};

export const DeliveryNoteLinks = (props) => {
  const displayLink = props.deliveryNotes.every(
    (deliveryNote) => deliveryNote.id,
  );

  if (!displayLink) {
    return (
      'Lieferung ' +
      props.deliveryNotes.map((deliveryNote) => deliveryNote.number).join(', ')
    );
  }

  const deliveryNoteLinks = props.deliveryNotes.map((deliveryNote, index) => {
    // With Lieferung
    if (index === 0) {
      return (
        <FunctionalLink
          to={ROUTE.DELIVERY_NOTE.ROUTE + '/' + deliveryNote.id}
          message={'Lieferung ' + (deliveryNote.number ?? '')}
          className={props.className ?? 'text-primary500 bold'}
        />
      );
    }

    // Without Lieferung
    return (
      <FunctionalLink
        to={ROUTE.DELIVERY_NOTE.ROUTE + '/' + deliveryNote.id}
        message={deliveryNote.number ?? ''}
        className={props.className ?? 'text-primary500 bold'}
      />
    );
  });

  return ArrayUtils.joinComponents(deliveryNoteLinks);
};

export const InvoiceLink = (props) => {
  if (!props.id) {
    return 'Rechnung ' + (props.number ?? '');
  }

  return (
    <FunctionalLink
      to={ROUTE.INVOICE.ROUTE + '/' + props.id}
      message={'Rechnung ' + (props.number ?? '')}
      className={props.className ?? 'text-primary500 bold'}
    />
  );
};

export const InvoiceLinks = (props) => {
  const displayLink = props.invoices.every((invoice) => invoice.id);

  if (!displayLink) {
    return (
      'Rechnung ' + props.invoices.map((invoice) => invoice.number).join(', ')
    );
  }

  const invoiceLinks = props.invoices.map((invoice, index) => {
    // With Rechnung
    if (index === 0) {
      return (
        <FunctionalLink
          to={ROUTE.INVOICE.ROUTE + '/' + invoice.id}
          message={'Rechnung ' + (invoice.number ?? '')}
          className={props.className ?? 'text-primary500 bold'}
        />
      );
    }

    // Without Rechnung
    return (
      <FunctionalLink
        to={ROUTE.INVOICE.ROUTE + '/' + invoice.id}
        message={invoice.number ?? ''}
        className={props.className ?? 'text-primary500 bold'}
      />
    );
  });

  return ArrayUtils.joinComponents(invoiceLinks);
};

export const MissingPermissionsTooltip = (props) => {
  return (
    <LightTooltipWideSpan title="Für diese Aktion fehlen dir die notwendigen Berechtigungen.">
      {props.children}
    </LightTooltipWideSpan>
  );
};

class ComponentUtils {
  getStringFromComponent(component) {
    let string = ReactDOMServer.renderToString(component);

    string = string.replace('<!-- -->', '');

    return string;
  }

  // Finds out if flex box has wrapped by comparing if DOM elements with the same class name have a different top position.
  detectWrap(className) {
    const wrappedItems = [];
    let previousItem = {};
    let currentItem = {};
    const items = document.getElementsByClassName(className);

    for (const item of items) {
      currentItem = item.getBoundingClientRect();
      if (previousItem && previousItem.top < currentItem.top) {
        wrappedItems.push(item);
      }

      previousItem = currentItem;
    }

    return wrappedItems;
  }
}

export default new ComponentUtils();
