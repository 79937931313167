import { useCallback } from 'react';

import CompanyService from '~/services/company.service';
import CostCenterService from '~/services/costCenter.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import SiteService from '~/services/site.service';
import UserGroupService from '~/services/userGroup.service';
import UserService from '~/services/user.service';
import VehicleService from '~/services/vehicle.service';

export const useSyncServerData = (state) =>
  useCallback(() => {
    for (const company of state.updatedCompanies) {
      CompanyService.refreshCompany(company.id);
    }

    for (const costCenter of state.updatedCostCenters) {
      CostCenterService.refreshCostCenter(costCenter.id);
    }

    for (const ou of state.updatedOrganisationalGroups) {
      OrganisationalGroupService.refreshOrganisationalGroup(ou.id);
    }

    for (const site of state.updatedSites) {
      SiteService.refreshSite(site.id);
    }

    for (const userGroup of state.updatedUserGroups) {
      UserGroupService.refreshUserGroup(userGroup.id);
    }

    for (const user of state.updatedUsers) {
      UserService.refreshUser(user.id);
    }

    for (const vehicle of state.updatedVehicles) {
      VehicleService.refreshVehicle(vehicle.id);
    }
  }, [
    state.updatedCompanies,
    state.updatedCostCenters,
    state.updatedOrganisationalGroups,
    state.updatedSites,
    state.updatedUserGroups,
    state.updatedUsers,
    state.updatedVehicles,
  ]);
