export const columns = [
  {
    field: 'name',
    headerName: 'Name',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'isActive',
    headerName: 'Aktiv',
    resizableText: true,
    sortable: true,
    width: 100,
  },
  {
    field: 'events',
    headerName: 'Events',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'callbackUrl',
    headerName: 'URL',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    field: 'id',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
];
