import { useQuery } from '@tanstack/react-query';

import costCenterService from '~/services/costCenter.service';

import { queryKeysCostCenter } from './queryKeys';

// This query uses an deprecated endpoint for fetching ALL cost centers ever, which is not technically possible for large accounts.
export const useQueryCostCentersLegacy = (options) =>
  useQuery({
    queryFn: costCenterService.getAllCostCenters,
    queryKey: queryKeysCostCenter.getAllLegacy(),
    ...options,
  });
