import { useQuery } from '@tanstack/react-query';

import ArticleMasterService from '~/services/articleMaster.service';

import { queryKeysArticle } from './queryKeys';

export const useQueryArticlesLegacy = (options) =>
  useQuery({
    queryFn: ArticleMasterService.getAllArticles,
    queryKey: queryKeysArticle.getAllLegacy(),
    ...options,
  });
