import { type Dispatch } from 'react-redux';

import { updateDeliveryNotes } from '~/redux/deliveryNotesSlice';
import { saveUserActions } from '~/redux/userinfoSlice';

import UserAction from '~/models/userActions/UserAction';

import UserService from '~/services/user.service';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import { type UUID } from '~/types/common';

type P = {
  deliveryNoteIds: UUID[];
  deliveryNotes: Array<Record<string, unknown>>;
  dispatch: Dispatch<any>;
  selectedUsers: Array<{
    id: UUID;
    name: string;
    email: string;
  }>;
  userActions: Record<string, unknown>;
};

/**
 * Handles the process of updating delivery notes and user actions when requesting signatures.
 *
 * @param {Object} params - The parameters for updating delivery notes.
 * @param {UUID[]} params.deliveryNoteIds - Array of delivery note IDs to be updated.
 * @param {Array<Record<string, unknown>>} params.deliveryNotes - Array of delivery note objects.
 * @param {Function} params.dispatch - Redux dispatch function.
 * @param {Array<{id: UUID, name: string, email: string}>} params.selectedUsers - Array of selected users.
 * @param {object} params.userActions - Object of user actions.
 *
 * @returns {Promise<void>}
 *
 * @throws {Error} Logs an error if updating user actions fails.
 */
export const handleUpdateDeliveryNotes = async ({
  deliveryNoteIds,
  deliveryNotes,
  dispatch,
  selectedUsers,
  userActions,
}: P) => {
  const [newUserActions, updatedDeliveryNotes] =
    UserAction.getEnhanceUserActionsAndDeliveryNotes(
      userActions,
      selectedUsers,
      deliveryNotes,
      deliveryNoteIds,
      UserAction.TYPE.REQUEST_SIGNATURES,
    );

  dispatch(saveUserActions(newUserActions));
  dispatch(updateDeliveryNotes(updatedDeliveryNotes));

  const [, error] = await promiseHandler(
    UserService.updateUserActions(newUserActions),
  );

  if (error) {
    Log.error('Failed to update user actions.', error);
  }
};
