import { DashboardTestIds, DeliveriesTestIds } from '~/constants/test-ids';

export const TAB = {
  DASHBOARD: {
    CHARTS: {
      INDEX: 0,
      NAME: 'Diagramme',
      TESTID: DashboardTestIds.CHARTS,
    },
    REPORT: {
      INDEX: 1,
      NAME: 'Berichte',
      TESTID: DashboardTestIds.REPORT,
    },
  },
  DELIVERY: {
    CHANGES: {
      INDEX: 1,
      NAME: 'Änderungen',
      TESTID: DeliveriesTestIds.CHANGES_CHIP,
    },
    LIST: {
      INDEX: 0,
      NAME: 'Alle Lieferungen',
    },
    UNASSIGNED_DELIVERY_NOTES: {
      INDEX: 2,
      NAME: 'Nicht zugeordnet',
    },
  },
};
