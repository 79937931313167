import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ROUTE } from '~/constants/Route';

import { saveUserActions } from '~/redux/userinfoSlice';
import { updateDeliveryNotes } from '~/redux/deliveryNotesSlice';

import { useQuerySharedWithUsersOfDlns } from '~/data/deliveryNote';
import { useQueryUserData } from '~/data/user';

import UserService from '~/services/user.service';
import DeliveriesService from '~/services/deliveries.service';
import ToastService from '~/services/toast.service';

import UserAction from '~/models/userActions/UserAction';

import PromiseUtils from '~/utils/promiseUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import { type UUID } from '~/types/common';

import { useSelectedUsers } from '../RequestSignatureForm/useSelectedUsers';

const selectDeliveryNotes = (state) => state.deliveryNotes.deliveryNotes;
const selectUserActions = (state) => state.userinfo.userActions;

type P = {
  closeForm: () => void;
  deliveryNoteIds: UUID[];
};

export const useShareDeliveryNoteForm = ({ closeForm, deliveryNoteIds }: P) => {
  const dispatch = useDispatch();

  const { data: currentUser } = useQueryUserData(true);
  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};
  const userPermissions = currentUser?.userPermissions ?? [];

  const deliveryNotes = useSelector(selectDeliveryNotes);
  const userActions = useSelector(selectUserActions);

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: selectedUsers,
    isLoading: isLoadingUsersData,
    isSuccess: isUsersDataComplete,
  } = useSelectedUsers(selectedUserIds);

  const {
    data: sharedUserIds,
    isLoading: isLoadingSharedUsers,
    isError: isErrorSharedUsers,
  } = useQuerySharedWithUsersOfDlns(deliveryNoteIds);

  const { data: sharedUsers } = useSelectedUsers(sharedUserIds);

  const handleSubmit = async (event) => {
    event.preventDefault();

    Log.info(
      'Submit share delivery note form',
      {
        value: selectedUserIds,
      },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Submit share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    if (deliveryNoteIds.length === 0) {
      Log.error('Failed to share delivery note: Missing delivery note.');
      ToastService.error([
        'Die Lieferung(en) konnte(n) nicht den Benutzern geteilt werden.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to share delivery note',
        Log.FEATURE.SHARE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );

      return;
    }

    if (isLoadingUsersData || !isUsersDataComplete) {
      Log.error('User data is still loading or incomplete.');
      return;
    }

    setIsSubmitting(true);

    const promises = selectedUserIds.flatMap((userId) =>
      deliveryNoteIds.map((deliveryNoteId) =>
        DeliveriesService.shareDeliveryNote(deliveryNoteId, userId),
      ),
    );

    const [, error] = await promiseHandler(PromiseUtils.allResolved(promises));

    if (error) {
      Log.error('Failed to share delivery note.', error);
      Log.productAnalyticsEvent(
        'Failed to share delivery note',
        Log.FEATURE.SHARE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );

      ToastService.error([
        `Die ${deliveryNoteIds.length > 1 ? 'Lieferungen konnten' : 'Lieferung konnte'} nicht mit allen Benutzern geteilt werden.`,
      ]);

      setIsSubmitting(false);
      return;
    }

    ToastService.success([
      `Die ${deliveryNoteIds.length > 1 ? 'Lieferungen wurden' : 'Lieferung wurde'} geteilt mit den ausgewählten Benutzern.`,
    ]);

    const [newUserActions, updatedDeliveryNotes] =
      UserAction.getEnhanceUserActionsAndDeliveryNotes(
        userActions,
        selectedUsers,
        deliveryNotes,
        deliveryNoteIds,
        UserAction.TYPE.SHARE_DELIVERY_NOTE,
      );

    dispatch(saveUserActions(newUserActions));
    dispatch(updateDeliveryNotes(updatedDeliveryNotes));

    const [, error2] = await promiseHandler(
      UserService.updateUserActions(newUserActions),
    );

    if (error2) {
      Log.error('Failed to update user actions.', error2);
    }

    handleCancel();
  };

  const handleCancel = () => {
    setSelectedUserIds([]);
    setIsSubmitting(false);
    closeForm();
  };

  const handleChange = (selectedUsers) => {
    Log.productAnalyticsEvent(
      'Change users of share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    setSelectedUserIds(selectedUsers.map(({ id }) => id).filter(Boolean));
  };

  const isAuthorized = UserService.userIsAuthorizedForPage(
    ROUTE.SETTINGS_USER.ROUTE,
    userPermissions,
    featureFlags,
  );

  return {
    handleCancel,
    handleChange,
    handleSubmit,
    isAuthorized,
    isErrorSharedUsers,
    isLoadingSharedUsers,
    isSubmitting,
    selectedUserIds,
    sharedUsers,
  };
};
