import CostCenter from '~/models/masterdata/CostCenter';

import { dateUtils } from '~/utils/dateUtils';

export const selectCostCenters = (data) => ({
  costCenters: data.data ?? [],
  rows:
    data.data?.map(
      ({ createdOn, end, id, isActive, modifiedOn, name, start }) => ({
        createdOn,
        end: dateUtils.getFormattedDate_safe(
          end,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ),
        id,
        isActive: isActive ? CostCenter.IS_ACTIVE.YES : CostCenter.IS_ACTIVE.NO,
        modifiedOn,
        name,
        start: dateUtils.getFormattedDate_safe(
          start,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ),
      }),
    ) ?? [],
  totalCount: data.totalCount ?? 0,
});
