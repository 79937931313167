import { type QueryClient } from '@tanstack/react-query';

import Log from '~/utils/Log';
import { toSnakeCase } from '~/utils/string';

import { type UUID } from '~/types/common';

import type {
  EntityFilter,
  EntityFilterType,
  GetItemById,
  GetSelectedItems,
} from './types';

/**
 * Returns an object with the entity type as filter key and the entity id as filter value.
 * @example
 * <MultiItemsManagerOrganizationalUnits filterByEntity={{ vehicle: undefined, company: '123', costCenter: '456' }} />

 * getEntityFilter(filterByEntity, 'filterContainsEntity') // => { filterContainsEntity: '123', containsEntityType: 'company' }
 * getEntityFilter(filterByEntity, 'filterNotContainsEntity') // => { filterNotContainsEntity: '123', containsEntityType: 'company' }
 */
export const getEntityFilter = (
  filterByEntity?: EntityFilter,
  filterType: EntityFilterType,
): Record<string, UUID> => {
  if (!filterByEntity || !filterType) {
    return {};
  }

  // Pick the first entity type and id that is truthy.
  const filter = Object.entries(filterByEntity)?.find(([_, value]) => value);

  if (!filter) {
    return {};
  }

  const [entityType, entityId] = filter;

  return {
    containsEntityType: toSnakeCase(entityType),
    [filterType]: entityId,
  };
};

export const defaultSelectFunction = (data) =>
  data?.map(({ id, name }) => ({
    id,
    name,
  }));

export const defaultGetItemById: GetItemById = async (id, data) =>
  data.find(({ id: itemId }) => itemId === id);

export const defaultGetSelectedItems: GetSelectedItems = async (
  selectedIds,
  getItemById,
  fieldName,
  data,
) => {
  if (!selectedIds || !Array.isArray(selectedIds)) {
    return [];
  }

  if (!data || !Array.isArray(data)) {
    return [];
  }

  if (!fieldName) {
    return [];
  }

  const items = await Promise.all(
    selectedIds.map(async (id) => getItemById(id, data)),
  );

  return items.filter(Boolean).sort((a, b) => {
    const valueA = a[fieldName] ?? '';
    const valueB = b[fieldName] ?? '';
    return valueA.toString().localeCompare(valueB.toString());
  });
};

type CreateGetItemByIdParams<T> = {
  queryClient: QueryClient;
  createQueryKey: (id: string) => unknown[];
  fetchItem: (id: string) => Promise<T>;
  getItemId?: (item: T) => string;
  queryKeyBase: unknown[];
};

/**
 * Creates a getItemById function for use with MultiItemsManager.
 * Implements a multi-level caching strategy with fallback to API fetch.
 *
 * @template T - The type of the item being retrieved
 * @param params Configuration parameters
 * @returns A function that retrieves item data given an ID
 */
export function createGetItemById<T>({
  queryClient,
  createQueryKey,
  fetchItem,
  getItemId = (item: any) => item.id,
  queryKeyBase,
}: CreateGetItemByIdParams<T>): (id: string) => Promise<T | undefined> {
  return async (id: string): Promise<T | undefined> => {
    if (!id) {
      return undefined;
    }

    const queryKey = createQueryKey(id);

    try {
      // First check cache
      const cachedItem = queryClient.getQueryData<T>(queryKey);
      if (cachedItem) {
        return cachedItem;
      }

      // Check list cache
      const queriesData = queryClient.getQueriesData<T[]>(queryKeyBase);
      for (const [, queryData] of queriesData) {
        if (queryData && 'data' in queryData) {
          const item = (queryData.data as T[]).find(
            (item) => getItemId(item) === id,
          );
          if (item) {
            return item;
          }
        }
      }

      // If not found in any cache, fetch from API
      const item = await queryClient.fetchQuery({
        queryFn: async () => fetchItem(id),
        queryKey,
      });

      return item;
    } catch (error) {
      Log.error('Error fetching item data:', error);

      return undefined;
    }
  };
}
