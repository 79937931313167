import ms from 'ms';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Config from '~/Config';

import AuthService from '~/services/auth.service';

import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

/**
 * Custom hook to handle authentication flow in the application.
 *
 * This hook manages various authentication scenarios:
 * - Logs out Vestigas admin accounts automatically
 * - Handles OIDC redirects
 * - Manages valid, expired, and missing access tokens
 * - Attempts to refresh expired tokens
 * - Handles logout scenarios
 *
 * @param {Function} setUserHasLoggedIn - Function to set whether the user has logged in
 * @param {Function} setLoadingMessage - Function to set a loading message for the user
 *
 * @returns {void}
 */
export const useHandleAuthentication = (
  setUserHasLoggedIn,
  setLoadingMessage,
) => {
  const location = useLocation();

  useEffect(() => {
    // If the user is still logged in as vestigas admin (only usable in Admin Webapp), it makes sense to log out automatically.
    if (UserUtils.isVestigasAdminAccount()) {
      AuthService.logout();
      return;
    }

    // If the page is loaded due to the redirection from oidc, an empty page should be displayed.
    // Hence, don't do anything at all here and wait for the reloading in the oidc onSignIn callback.
    if (AuthService.isOidcRedirectUrl(location.search)) {
      // If redirecting to the home page during login (via oidc onSignIn) takes unexpectedly long (more than 3 seconds), a message is displayed to the user that he is logged in
      setTimeout(() => {
        setLoadingMessage('Du wirst eingeloggt...');
      }, ms('3s'));

      // If redirecting to the home page during login hasn't been done after 10 seconds we restart the login
      // process so that he isn't stuck in that loading screen. We're not calling logout here since there are
      // cases where login fails because of oidc-react and not keycloak login (e.g. if login url with state is
      // bookmarked, authprovider is initialized with invalid state and dies => in this case, keycloak is fine and
      // we will restart the webapp login process.)
      setTimeout(() => {
        window.location.href = Config.redirectUrl;
      }, ms('10s'));
      return;
    }

    // Case 1: user has a valid access token
    // -> Just regularly load the webapp
    if (AuthService.accessTokenValid()) {
      setUserHasLoggedIn(true);
      return;
    }

    // Case 2: user has an expired token
    // -> If the refresh token is expired or not given at all, the user can directly be logged out
    // -> If the user has a valid refresh token, we try to get a new access token. If this succeeds, the webapp is loaded normally. If it fails, the user is logged out.
    if (AuthService.accessTokenExpired()) {
      if (!AuthService.getRefreshToken() || AuthService.refreshTokenExpired()) {
        AuthService.logout();
        return;
      }

      AuthService.loadRefreshedTokens()
        .then((response) => {
          AuthService.saveTokens(response.data);
          setUserHasLoggedIn(true);
        })
        .catch((error) => {
          Log.error('Failed to refresh tokens.', error);
          Log.productAnalyticsEvent(
            'Failed to refresh access token',
            Log.FEATURE.AUTHENTICATION,
            Log.TYPE.ERROR,
          );

          AuthService.logout();
        });
      return;
    }

    // Case 3: user has no token at all
    // -> Log out the user.
    if (
      !AuthService.getAccessToken() && // If the page has "logout=true" in the search, the page is reloaded after logout due to signoutRedirect({post_logout_redirect_uri: oidcConfig.redirectUri + '/?logout=true'}).
      // In this case, we don't need to log out the user again as this would result in an infinite loop of logouts and page reloads.
      !AuthService.isLogoutUrl(location.search)
    ) {
      // The user is logged out automatically because it could happen that the user has no access token but a session in keycloak.
      // In this case, the user wouldn't get a new access token because keycloak doesn't sign in and oidc onSignIn isn't triggered. Thus, the user wouldn't have an access token and wouldn't get a new one.

      // The delay of 3 seconds is built in due to the following reason:
      // If the user opens the webapp and no keycloak session is present, the user is immediately redirected to the login page.
      // In this case, the auto logout would be unnecessary and just cause the webapp to reload every time it is opened and the user doesn't have a keycloak session.
      setTimeout(() => {
        AuthService.logout();
      }, ms('3s'));
    }
  }, []);
};
