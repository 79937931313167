import cloneDeep from 'lodash/cloneDeep';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';

import SignatureRoles from '~/models/masterdata/SignatureRoles';

import { withErrorBoundary } from '~/ui/atoms';

import Log from '~/utils/Log';

export const Signatures = withErrorBoundary(
  ({ enableSignature, setEnableSignature, setUser, user }) => {
    const handleRadioChange = (event) => {
      Log.info(
        'Change form value of enable signature radio button',
        {
          from: enableSignature,
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change enable signature radio button in create user wizard',
        Log.FEATURE.WIZARD,
      );

      setEnableSignature(event.target.value === 'enable_signature_yes');
    };

    const handleCheckboxChange = (event) => {
      const newUser = cloneDeep(user);

      switch (event.target.name) {
        case 'signature_role_supplier': {
          newUser.signatureRoles.supplier = event.target.checked;

          Log.info(
            'Change form value of supplier signature role',
            {
              from: user.signatureRoles.supplier,
              to: newUser.signatureRoles.supplier,
            },
            Log.BREADCRUMB.FORM_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Change supplier signature role checkbox in create user wizard',
            Log.FEATURE.WIZARD,
          );

          break;
        }

        case 'signature_role_carrier': {
          newUser.signatureRoles.carrier = event.target.checked;

          Log.info(
            'Change form value of carrier signature role',
            {
              from: user.signatureRoles.carrier,
              to: newUser.signatureRoles.carrier,
            },
            Log.BREADCRUMB.FORM_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Change carrier signature role checkbox in create user wizard',
            Log.FEATURE.WIZARD,
          );

          break;
        }

        case 'signature_role_recipient': {
          newUser.signatureRoles.recipient = event.target.checked;

          Log.info(
            'Change form value of recipient signature role',
            {
              from: user.signatureRoles.recipient,
              to: newUser.signatureRoles.recipient,
            },
            Log.BREADCRUMB.FORM_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Change recipient signature role checkbox in create user wizard',
            Log.FEATURE.WIZARD,
          );

          break;
        }

        default: {
          break;
        }
      }

      setUser(newUser);
    };

    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item lg={12} xs={12}>
          <h3 className="main-text mt-0">
            Soll der Benutzer berechtigt sein, Lieferungen in der VESTIGAS App
            zu bearbeiten oder zu signieren? (kann jederzeit geändert werden)
          </h3>
          <RadioGroup
            onChange={handleRadioChange}
            row
            value={
              enableSignature ? 'enable_signature_yes' : 'enable_signature_no'
            }
          >
            <FormControlLabel
              className="mr-12"
              control={<Radio />}
              label="Ja (empfohlen)"
              value="enable_signature_yes"
            />
            <FormControlLabel
              control={<Radio />}
              label="Nein"
              value="enable_signature_no"
            />
          </RadioGroup>
        </Grid>
        {/* We could provide the on behalf signature roles here as well. But as they are currently a more edge case, it doesn't make a lot of sense to include this configuration in the wizard. */}
        {enableSignature ? (
          <Grid item lg={12} xs={12}>
            <h3 className="main-text mt-4">
              In welcher Funktion soll der Benutzer Lieferungen bearbeiten oder
              signieren können?
            </h3>
            <FormControlLabel
              className="mr-12"
              control={
                <Checkbox
                  checked={user.signatureRoles.supplier}
                  name="signature_role_supplier"
                  onChange={handleCheckboxChange}
                />
              }
              label={'Als ' + SignatureRoles.SIGNATURE_ROLE.SUPPLIER.STRING}
            />
            <FormControlLabel
              className="mr-12"
              control={
                <Checkbox
                  checked={user.signatureRoles.carrier}
                  name="signature_role_carrier"
                  onChange={handleCheckboxChange}
                />
              }
              label={'Als ' + SignatureRoles.SIGNATURE_ROLE.CARRIER.STRING}
            />
            <FormControlLabel
              className="mr-12"
              control={
                <Checkbox
                  checked={user.signatureRoles.recipient}
                  name="signature_role_recipient"
                  onChange={handleCheckboxChange}
                />
              }
              label={
                'Als ' +
                SignatureRoles.SIGNATURE_ROLE.RECIPIENT.STRING +
                ' (empfohlen)'
              }
            />
          </Grid>
        ) : null}
      </Grid>
    );
  },
  'Daten konnten nicht geladen werden.',
);
