import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { fetchSite, fetchSites, queryKeysSite, type Site } from '~/data/site';

import UserUtils from '~/utils/userUtils';

import { type Option, SelectServerDriven } from './SelectServerDriven';
import { createLoadOptions, createGetItemData } from './utils';

const getOptionLabel = ({ name }) => name;

type P = {
  filterActive?: boolean;
  formatOptionLabel?: (option: Option<Site>) => React.ReactNode;
  isDisabled?: boolean;
  isMultiSelect?: boolean;
  onChange: (value: Array<{ id: string }>, data: Site[]) => void;
  placeholder?: string;
  value: string[];
} & ComponentStyling;

export const MultiSelectSites = ({
  className,
  filterActive = true,
  formatOptionLabel,
  isDisabled = false,
  isMultiSelect = true,
  onChange,
  placeholder = 'Standorte',
  style,
  value,
}: P) => {
  const queryClient = useQueryClient();

  /**
   * Loads the options for the multi-select dropdown.
   * @param {string} searchString - The search string entered by the user.
   * @param {unknown} loadedOptions - The already loaded options.
   * @param {{ page: number }} - The current page number.
   */
  const loadOptions = createLoadOptions<Site>({
    createQueryKey: ({ page, ...queryParams }) =>
      queryKeysSite.getAll(queryParams),
    async fetchItems({ page, ...queryParams }) {
      const { data, hasNextPage } = await fetchSites(queryParams);

      return {
        data,
        hasNextPage,
      };
    },
    filterActive,
    getOptionLabel,
    queryClient,
  });

  /**
   * Gets site data for a site with a given siteId.
   * Attempts to get it from the query cache and fetches it from the API
   * if the site data is not in the cache.
   * @param {string} siteId - The ID of the site to get.
   */
  const getItemData = createGetItemData<Site>({
    createQueryKey: (siteId) => queryKeysSite.get(siteId),
    fetchItem: fetchSite,
    queryClient,
    queryKeyBase: [queryKeysSite.base()],
  });

  // This is just a glue function b/c PermissionGrantMultiPicker currently expects to pick the ID from an object in its onChange function. Should be refactored.
  const handleChange = useCallback(
    (newValues: string[] | string, data: Site[] | Site) => {
      onChange(
        Array.isArray(newValues)
          ? newValues.map((id) => ({ id }))
          : { id: newValues },
        data,
      );
    },
    [onChange],
  );

  return (
    <SelectServerDriven<Site>
      value={value}
      onChange={handleChange}
      className={className}
      getItemData={getItemData}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      isItemSelectionAllowed={UserUtils.isSiteReadAllowedUser}
      isMultiSelect={isMultiSelect}
      loadOptions={loadOptions}
      placeholder={placeholder}
      style={style}
    />
  );
};
