import { type UUID } from '~/types/common';

import { type CompaniesQueryParams } from './useQueryCompanies';

/**
 * Query key factory for company-related queries.
 */
export const queryKeysCompany = {
  base: () => 'companies' as const,
  get: (companyId: UUID) =>
    [queryKeysCompany.base(), 'detail', { companyId }] as const,
  getAll: (queryParams: Partial<CompaniesQueryParams>) =>
    [queryKeysCompany.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<CompaniesQueryParams>) =>
    [queryKeysCompany.base(), 'list', 'infinite', queryParams] as const,
  getAllLegacy: () => [queryKeysCompany.base(), 'list', 'LEGACY'] as const,
  getLogo: (companyId: UUID) =>
    [queryKeysCompany.base(), 'logo', { companyId }] as const,
  upsert: (companyId: UUID) =>
    [queryKeysCompany.base(), 'upsert', { companyId }] as const,
} as const;
