import { useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { queryKeysUser, useQueryUserData } from '~/data/user';

import ToastService from '~/services/toast.service';

import User from '~/models/masterdata/User';

import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import Wizard from '~/components/Wizard';
import { withErrorBoundary } from '~/ui/atoms';

import {
  AccountInformation,
  CreateUserButton,
  Permissions,
  Signatures,
} from './components';
import { handleSubmit as onSubmit } from './handleSubmit';

export const CreateUserWizard = withErrorBoundary(() => {
  const queryClient = useQueryClient();

  const {
    data: currentUser,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
    isSuccess: isSuccessUserData,
  } = useQueryUserData(true);

  const companyInfo = currentUser?.companyInfo ?? {};

  const getDefaultUser = () => {
    const user = new User();
    user.companyId = companyInfo.id;
    user.signatureRoles.recipient = true;

    return user;
  };

  const [enableSignature, setEnableSignature] = useState(true);
  const [grantPermissions, setGrantPermissions] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState(getDefaultUser());

  const permissionsRef = useRef(null);

  const resetWizard = () => {
    setUser(getDefaultUser());
    setEnableSignature(true);
    setGrantPermissions(true);
  };

  const onOpenWizard = () => {
    Log.productAnalyticsEvent('Open create user wizard', Log.FEATURE.WIZARD);
    setIsOpen(true);
  };

  const onCloseWizard = () => {
    Log.productAnalyticsEvent('Close create user wizard', Log.FEATURE.WIZARD);
    setIsOpen(false);
    resetWizard();
  };

  const getWizardSteps = () => {
    const wizardSteps = [
      {
        component: <AccountInformation user={user} setUser={setUser} />,
        preventNextStep() {
          if (!user.companyId) {
            ToastService.warning(['Bitte wähle eine Firma aus.']);

            Log.productAnalyticsEvent(
              'Missing company',
              Log.FEATURE.WIZARD,
              Log.TYPE.FAILED_VALIDATION,
            );

            return true;
          }
        },
        title: 'Benutzer-Account-Informationen',
      },
      {
        component: (
          <Signatures
            enableSignature={enableSignature}
            setEnableSignature={setEnableSignature}
            setUser={setUser}
            user={user}
          />
        ),
        title: 'Signaturen',
      },
    ];

    if (UserUtils.isPermissionGrantAllowedUser()) {
      wizardSteps.push({
        component: (
          <Permissions
            ref={permissionsRef}
            grantPermissions={grantPermissions}
            setGrantPermissions={setGrantPermissions}
            user={user}
          />
        ),
        title: 'Berechtigungen',
      });
    }

    return wizardSteps.map((step) => ({
      ...step,
      component: (
        <div key={step.title} className="my-8">
          {step.component}
        </div>
      ),
    }));
  };

  const handleSubmit = async () => {
    const refetchData = () => {
      queryClient.invalidateQueries({
        queryKey: queryKeysUser.get(user.id),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeysUser.getAll({}),
      });
    };

    return onSubmit({
      enableSignature,
      grantPermissions,
      permissionsRef,
      refetchData,
      resetWizard,
      setIsOpen,
      setIsSubmitting,
      user,
    });
  };

  return (
    <div>
      <CreateUserButton onClick={onOpenWizard} />
      <Wizard
        open={isOpen}
        title="Benutzer erstellen"
        wizardSuccess={handleSubmit}
        closeWizard={onCloseWizard}
        steps={getWizardSteps()}
        submittingWizard={isSubmitting}
        unsavedChanges={User.getDifferentValues(user, getDefaultUser())}
        fullWidth
      />
    </div>
  );
}, 'Daten konnten nicht geladen werden.');
