import cloneDeep from 'lodash/cloneDeep';

export default class UserAction {
  constructor(userAction) {
    this.userId = userAction.userId ?? null;
    this.userName = userAction.userName ?? null;
    this.userEmail = userAction.userEmail ?? null;
    this.deliveryNoteId = userAction.deliveryNoteId ?? null;
    this.createdAt = userAction.createdAt ?? new Date().toISOString();
  }

  /**
   * Updates user actions and delivery notes with new user actions.
   *
   * @param {Object} userActions - The existing user actions.
   * @param {Array} users - The list of users to add actions for.
   * @param {Array} deliveryNotes - All existing delivery notes.
   * @param {Array} deliveryNoteIds - The IDs of delivery notes to update.
   * @param {string} type - The type of user action (e.g., 'requestSignatures', 'shareDeliveryNote').
   * @returns {Array} An array containing the updated user actions and updated delivery notes.
   */
  static getEnhanceUserActionsAndDeliveryNotes(
    userActions,
    users,
    deliveryNotes,
    deliveryNoteIds,
    type,
  ) {
    const newUserActions = cloneDeep(userActions);
    const updatedDeliveryNotes = [];

    for (const deliveryNoteId of deliveryNoteIds) {
      const newDeliveryNote = cloneDeep(
        deliveryNotes.find(
          (deliveryNote) => deliveryNote.id === deliveryNoteId,
        ),
      );

      for (const user of users) {
        const userAction = new UserAction({
          deliveryNoteId,
          userEmail: user.email,
          userId: user.id,
          userName: user.name,
        });

        newUserActions[deliveryNoteId] ||= {
          requestSignatures: [],
          shareDeliveryNote: [],
        };
        newUserActions[deliveryNoteId][type].push(userAction);

        if (newDeliveryNote) {
          newDeliveryNote.userActions[type].push(userAction);
        }

        if (newDeliveryNote) {
          updatedDeliveryNotes.push(newDeliveryNote);
        }
      }
    }

    return [newUserActions, updatedDeliveryNotes];
  }

  /**
   * Get a list of users who have been requested to sign a list of delivery notes
   * together with the count of requests and the date of the latest request.
   *
   * @param {Array} userActionsList - An array of user actions for multiple delivery notes.
   * @returns {Array} An array of objects containing information about requested users.
   *                  Each object includes: id, name, email, date (of latest request), and count (of requests).
   */
  static getRequestedUsers(userActionsList) {
    const requestedUsers = [];

    for (const userActions of userActionsList) {
      for (const {
        createdAt,
        userEmail,
        userId,
        userName,
      } of userActions.requestSignatures) {
        // Check if a signature request has already been found for this user.
        const requestedUserIndex = requestedUsers.findIndex(
          ({ id }) => id === userId,
        );

        if (requestedUserIndex === -1) {
          // If no signature request has already been found for this user, add it to the list with the latest date of a signature request.
          requestedUsers.push({
            count: 1,
            date: createdAt,
            email: userEmail,
            id: userId,
            name: userName,
          });
        } else if (requestedUsers[requestedUserIndex].date < createdAt) {
          // If a signature request has already been found for this user and the date of the user action is newer,
          // update the date with the latest date of a signature request.

          requestedUsers[requestedUserIndex].date = createdAt;
          requestedUsers[requestedUserIndex].count++;
        }
      }
    }

    return requestedUsers;
  }

  static TYPE = {
    REQUEST_SIGNATURES: 'requestSignatures',
    SHARE_DELIVERY_NOTE: 'shareDeliveryNote',
  };
}
