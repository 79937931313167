export const columns = [
  {
    field: 'sellerAssignedId',
    headerName: 'Artikelnummer',
    resizableText: true,
    sortable: true,
    width: 150,
  },
  {
    field: 'name',
    headerName: 'Artikelname',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'reason',
    headerName: 'Grund',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'sellerCompanies',
    headerName: 'Auftragnehmer',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    field: 'buyerCompanies',
    headerName: 'Auftraggeber',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    field: 'id',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
];
