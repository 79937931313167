import { queryKeysOrganizationalUnit } from '~/data/organizationalUnit';

import MasterDataService from '~/services/masterData.service';
import ToastService from '~/services/toast.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

const handleCreateError = (error) => {
  if (error.response?.status === 409) {
    ToastService.httpError(
      [
        ToastService.MESSAGE.VEHICLE_CREATION_FAILED_DUPLICATE,
        'Bitte gib ein anderes Kennzeichen ein und versuche es erneut.',
      ],
      error.response,
    );

    Log.productAnalyticsEvent(
      'Failed to create due to duplicate license plate',
      Log.FEATURE.VEHICLE,
      Log.TYPE.ERROR,
    );

    return;
  }

  ToastService.httpError(
    [ToastService.MESSAGE.VEHICLE_CREATION_FAILED],
    error.response,
  );

  Log.productAnalyticsEvent(
    'Failed to create',
    Log.FEATURE.VEHICLE,
    Log.TYPE.ERROR,
  );
  Log.error('Failed to create vehicle.', error);
};

const handleUpdateError = (error, vehicleId) => {
  if (error.response?.status === 409) {
    ToastService.httpError(
      [
        ToastService.MESSAGE.VEHICLE_UPDATE_FAILED_DUPLICATE,
        'Bitte gib ein anderes Kennzeichen ein und versuche es erneut.',
      ],
      error.response,
    );

    Log.productAnalyticsEvent(
      'Failed to update due to duplicate license plate',
      Log.FEATURE.VEHICLE,
      Log.TYPE.ERROR,
    );
  } else {
    ToastService.httpError(
      [ToastService.MESSAGE.VEHICLE_UPDATE_FAILED],
      error.response,
    );

    Log.productAnalyticsEvent(
      'Failed to update',
      Log.FEATURE.VEHICLE,
      Log.TYPE.ERROR,
    );
  }

  Log.error('Failed to update vehicle. id: ' + vehicleId, error);
};

export const handleSubmit = async ({
  closeForm,
  createVehicleMutation,
  isCreatingVehicle,
  originalData,
  queryClient,
  refetchVehicles,
  updateOrganizationalUnitsMutation,
  updateVehicleMutation,
  vehicle,
}) => {
  const { id, companyId, isActive, licensePlate, organisationalGroups } =
    vehicle;

  try {
    if (!licensePlate?.isValid?.()) {
      ToastService.error([
        ToastService.MESSAGE.VEHICLE_INVALID_INPUT,
        'Bitte überprüfe deine Eingabe und versuche es erneut.',
      ]);

      Log.productAnalyticsEvent(
        'Invalid license plate',
        Log.FEATURE.VEHICLE,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    const body = {
      companyId,
      isActive,
      licensePlate: licensePlate.getConcatenatedName(),
    };

    if (isCreatingVehicle) {
      body.orgUnits = organisationalGroups;
    }

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['companyId'],
        Log.FEATURE.VEHICLE,
      )
    ) {
      return;
    }

    Log.info('Submit vehicle form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.VEHICLE);

    if (isCreatingVehicle) {
      try {
        await createVehicleMutation(body);
      } catch (error) {
        handleCreateError(error);
        return;
      }
    } else {
      try {
        await updateVehicleMutation({
          vehicleData: body,
          vehicleId: id,
        });

        const [deletedOrganizationalUnits, addedOrganizationalUnits] =
          ArrayUtils.getDifference(
            originalData.organisationalGroups,
            organisationalGroups,
          );

        await updateOrganizationalUnitsMutation({
          addedMembers: addedOrganizationalUnits,
          deletedMembers: deletedOrganizationalUnits,
          entityId: id,
          entityType: PermissionGrant.ENTITY_TYPE.VEHICLE.KEY,
          updateType: 'updateParentOrganizationalUnits',
        });
      } catch (error) {
        handleUpdateError(error, id);
        return;
      }
    }

    refetchVehicles();

    // Refetch MultiItemsManager queries.
    queryClient.invalidateQueries({
      queryKey: queryKeysOrganizationalUnit.getAll(),
      refetchType: 'all',
    });

    closeForm();

    ToastService.success(
      `Fahrzeug ${licensePlate?.getConcatenatedName?.()} ${
        isCreatingVehicle ? 'erstellt' : 'aktualisiert'
      }`,
    );
  } catch (error) {
    Log.error('Unexpected error in handleSubmit', error);
    ToastService.error('Ein unerwarteter Fehler ist aufgetreten');
  }
};
