import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    '& *': {
      fontFamily: 'Inter',
    },

    '& .contact-attempts-container': {
      '& .active-form-data': {
        backgroundColor: '#F2F4F7',
        borderRadius: '0.5rem',
        padding: '1rem',
      },
      '& .form-container': {
        border: '1px solid #C5C9D6',
        borderRadius: '0.5rem',
        padding: '1rem',
      },

      '& .textarea-container': {
        '& textarea': {
          border: '1px solid #8D94A8',
          borderRadius: '0.5rem',
          minHeight: '5.4375rem',
          padding: '0.75rem',
          width: '100%',
        },
        alignItems: 'flex-start',
        display: 'flex',

        flexDirection: 'column',
      },
      paddingBottom: '1rem',
    },

    '& .logo-container': {
      '& img': {
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center center',
        width: '100%',
      },
      height: 64,
      marginTop: 'auto',

      width: '100%',
    },

    '& .MuiDivider-root': {
      backgroundColor: '#C5C9D6',
      height: '100%',
      width: 1,
    },

    '& .MuiFormLabel-root': {
      marginBottom: '0.5rem',
    },

    '& .MuiPaper-root': {
      '& *': {
        fontFamily: 'Inter',
      },
      borderRadius: '0.5rem',
      height: '30rem',
      maxWidth: '57.5rem',

      width: '100%',
    },

    '& .MuiSelect-root, & .MuiButton-root, & .MuiInputBase-root': {
      '& .copy-svg': {
        marginRight: 0,
      },
      '& .MuiButton-label': {
        fontWeight: 600,
      },
      '& svg': {
        marginRight: '0.5rem',
      },
      '&.MuiButton-sizeSmall': {
        height: '2rem',
      },
      alignItems: 'center',
      borderRadius: '0.5rem',
      display: 'flex',

      height: '3rem',

      paddingBottom: 0,

      paddingTop: 0,

      textTransform: 'unset',
    },

    '& .MuiTypography-root': {
      '& .MuiSvgIcon-root': {
        marginRight: '0.25rem',
      },
      alignItems: 'center',

      display: 'flex',
    },

    '& .nav-content': {
      '& .close-button': {
        position: 'absolute',
        right: 0,
        top: 0,
      },
      '& .content-heading': {
        fontSize: '1.25rem',
        fontWeight: 600,
      },
      flex: 1,
      height: '100%',
      overflowY: 'scroll',
      paddingLeft: '1rem',
      paddingRight: '1rem',

      paddingTop: '1.5rem',

      position: 'relative',
    },

    '& .people-container': {
      '& .banner': {
        '&.expired': {
          backgroundColor: '#FFD9D8',
          borderColor: '#CC2C29',
          color: '#A61310',
        },
        '&.filled': {
          backgroundColor: '#E5FFF0',
          borderColor: '#24B25F',
          color: '#158C47',
        },
        backgroundColor: '#F2F4F7',
        border: '1px solid #C5C9D6',
        borderRadius: '0.5rem',
        color: '#343A4D',

        gap: '0.5rem',

        padding: `0.75rem 0.5625rem`,
      },

      '& .content-heading': {
        marginBottom: '1.5rem',
      },

      '& .user-contact-banner': {
        '& .MuiAvatar-root': {
          backgroundColor: '#D9E6FF',
          color: '#343A4D',
        },
        alignItems: 'center',
        backgroundColor: '#F2F4F7',
        border: '1px solid #C5C9D6',
        borderRadius: '0.5rem',
        display: 'flex',
        gap: '1rem',

        padding: '1rem',
      },

      paddingBottom: '0.625rem',
    },

    '& .semibold': {
      color: '#343A4D',
      fontSize: '1rem',
      fontWeight: 600,
    },

    '& .spacer': {
      fontSize: '1.125rem',
      margin: `0 0.5rem`,
    },

    '& .supplier_detail_container': {
      height: '100%',
      overflow: 'hidden',
      padding: '1rem',
    },

    '& fieldset': {
      borderRadius: '0.5rem',
    },

    '& nav': {
      '& .nav-item': {
        '&.active': {
          '& p': {
            fontWeight: 600,
          },

          backgroundColor: '#D9E6FF',
        },
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: '0.5rem',
        color: '#343A4D',
        cursor: 'pointer',
        gap: '0.5rem',
        padding: '0.5rem',
        transition: 'all 0.4s ease',

        width: '100%',
      },
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      padding: '1rem',

      width: '15.5rem',
    },

    color: '#343A4D',
  },

  loadingContainer: {
    '& *': {
      fontFamily: 'Inter',
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    position: 'absolute',
  },

  person_search_select: {
    '& *': {
      fontFamily: 'Inter',
    },
    '& .MuiPaper-root': {
      height: 400,
    },
  },
  popover: {
    '& *': {
      fontFamily: 'Inter',
    },
    '& .MuiPaper-root': {
      borderRadius: '0.5rem',
      boxShadow: '0px 4px 8px 0px rgba(23, 60, 136, 0.20)',
      marginTop: '0.25rem',
    },
  },

  searchableSelectContainer: {
    '& .MuiChip-root': {
      '& .MuiChip-label': {
        color: '#343A4D',
        fontSize: '1.0625rem',
        marginRight: 'auto',
      },
      borderColor: '#C5C9D6',
      borderRadius: '0.5rem',
      color: '#8D94A8',
      height: '3.25rem',
      minWidth: '13.125rem',
      paddingLeft: '0.25rem',

      paddingRight: '0.25rem',
    },

    position: 'relative',
  },

  searchContainer: {
    '& .content-container': {
      overflow: 'hidden',
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      margin: `0 !important`,
      padding: `0.6875rem 1rem`,
    },

    '& .MuiOutlinedInput-input': {
      paddingBottom: '0.90625rem',
      paddingTop: '0.90625rem',
    },

    '& .MuiPaper-root': {
      height: '18.875rem',
    },

    '& .search-input': {
      padding: '1rem',
    },

    '& .select-list': {
      '& .empty-state': {
        fontSize: '1rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        textAlign: 'center',
      },
      '& .option': {
        '& p': {
          fontSize: '1.125rem',
          margin: 0,
        },
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem',

        width: '100%',
      },
      display: 'flex',
      flexDirection: 'column',
      height: '100%',

      justifyContent: 'flex-start',

      width: '100%',
    },
  },
}));
