export default class NotificationPolicy {
  constructor(notificationPolicy) {
    this.contactPointId =
      notificationPolicy?.ds_contact_point_id ??
      notificationPolicy?.dsContactPointId ??
      null;
    this.dataSubscriptionId =
      notificationPolicy?.ds_id ?? notificationPolicy?.dsId ?? null;
  }
}
