import { createSlice } from '@reduxjs/toolkit';

const backdropSlice = createSlice({
  initialState: {
    id: '',
    message: '',
    open: false,
  },
  name: 'backdrop',
  reducers: {
    closeBackdrop(state, parameter) {
      const { payload } = parameter;

      if (payload.id === state.id) {
        state.open = false;
        state.message = '';
      }
    },
    openBackdrop(state, parameter) {
      const { payload } = parameter;

      state.id = payload.id;
      state.open = true;
      state.message = payload.message ?? '';
    },
  },
});

const { actions, reducer } = backdropSlice;
export const { closeBackdrop, openBackdrop } = actions;
export default reducer;
