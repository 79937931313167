import { useMutation } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type Address } from '~/types/address';
import { type UUID } from '~/types/common';

type SiteTypeType =
  | 'construction_site'
  | 'disposal'
  | 'factory'
  | 'plant'
  | 'warehouse';

type SitePayload = {
  name: string;
  type: SiteTypeType;
  coords: {
    latitude: number;
    longitude: number;
  };
  address: Address;
  start: string; // ISO 8601 date string
  end: string; // ISO 8601 date string
  companyId: UUID;
  accountingReferenceList: UUID | UUID[];
  orgUnits: UUID | UUID[];
  orgUnitsSyncKeys: string[];
  companySyncKey: string;
  accRefSyncKeys: string[];
  syncKey: string;
};

type CreateSiteResponse = {
  code: number;
  id: UUID;
  message: string;
};

/**
 * Creates a new site.
 *
 * @param {Object} siteData - The site data for creating a new site.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/create_site_site_post
 */
export const createSite = async (
  siteData: SitePayload,
): Promise<CreateSiteResponse> => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.SITE.CREATE(), { json: siteData })
      .json<CreateSiteResponse>();

    return response;
  } catch (error) {
    Log.error('Error creating site', error);

    throw error;
  }
};

/**
 * Custom hook for creating a new site.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationCreateSite = (
  options?: Parameters<typeof useMutation>[0],
) => {
  return useMutation({
    mutationFn: async (siteData: SitePayload) => createSite(siteData),
    onError(error) {
      Log.productAnalyticsEvent(
        'Failed to create site',
        Log.FEATURE.SITE,
        Log.TYPE.ERROR,
      );
    },
    ...options,
  });
};
