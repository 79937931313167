import { useAutoAnimate } from '@formkit/auto-animate/react';

import { type UUID } from '~/types/common';

import { PathElement } from './PathElement';

type OrganizationalUnit = {
  id: UUID;
  name: string;
};

type P = {
  id: UUID;
  onOpenOrganizationalUnit: (organizationalUnit: OrganizationalUnit) => void;
  organizationalUnitPaths: OrganizationalUnit[];
};

export const OrganizationalUnitPaths = ({
  id,
  onOpenOrganizationalUnit,
  organizationalUnitPaths = [],
}: P) => {
  if (!organizationalUnitPaths?.length) {
    return null;
  }

  const [pathsAnimationRef] = useAutoAnimate({
    duration: 400,
    easing: 'ease-out',
  });

  return (
    <div>
      <div className="font-bold">Teil der Organisations-Gruppen</div>
      <div ref={pathsAnimationRef} className="mt-2 flex flex-col gap-2">
        {organizationalUnitPaths.map((path, index) => {
          const pathId = `organizational-unit-path-${id}-${index}-${JSON.stringify(
            path,
          )}`;

          return (
            <PathElement
              key={pathId}
              id={pathId}
              onOpenEntity={onOpenOrganizationalUnit}
              path={path}
            />
          );
        })}
      </div>
    </div>
  );
};
