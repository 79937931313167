import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import Invoice from './Invoice'; // MOTHERFUCKER. NOT importing this unused model breaks the build?! FIXME

export default class InvoiceCheckResult {
  constructor(check_result, version) {
    if (version === '1.0') {
      this.init_v1(check_result);
    } else if (version === '2.0') {
      this.init_v2(check_result);
    }
  }

  init_v1(check_result) {
    this.name = check_result?.name ?? null;
    this.status = check_result?.status ?? null;
    this.articleName = check_result?.conflicting_entity ?? null;
    this.expectedValue = check_result.conflicting_values?.['2'] ?? null;
    this.invoiceValue = check_result.conflicting_values?.['1'] ?? null;
    this.deliveryNotes = check_result?.delivery_note_id
      ? [
          {
            id: check_result?.delivery_note_asset_id ?? null,
            number: check_result?.delivery_note_id ?? null,
          },
        ]
      : [];
  }

  init_v2(check_result) {
    this.name = check_result?.name ?? null;
    this.status = check_result?.display_status ?? null;
    this.articleName = check_result?.item_name ?? null;
    this.expectedValue = check_result?.expected_value ?? null;
    this.invoiceValue = check_result?.invoice_value ?? null;
    this.deliveryNotes =
      check_result?.delivery_note_nrs?.map((delivery_note_nr, index) => {
        return {
          id: check_result?.delivery_note_asset_ids?.[index],
          number: delivery_note_nr,
        };
      }) ?? [];
  }

  getDeliveryNoteNumbers() {
    return this.deliveryNotes.map((deliveryNote) => deliveryNote.number ?? '');
  }

  // Helper function to flat delivery note numbers
  static getDeliveryNoteNumbers(checkResults) {
    return checkResults.flatMap((checkResult) =>
      checkResult.getDeliveryNoteNumbers(),
    );
  }

  static getStatusColorClass(status) {
    switch (status) {
      case InvoiceCheckResult.STATUS.SUCCESS: {
        return 'text-success500';
      }

      case InvoiceCheckResult.STATUS.WARNING: {
        return 'text-warningBase';
      }

      case InvoiceCheckResult.STATUS.ERROR: {
        return 'text-error500';
      }

      case InvoiceCheckResult.STATUS.NA:
      case InvoiceCheckResult.STATUS.MANUAL:
      case InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE: {
        return 'text-grey500';
      }

      case InvoiceCheckResult.STATUS.DELAYED_SUCCESS: {
        return 'text-success100';
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException('Invalid invoice status: ' + status),
        );
        return null;
      }
    }
  }

  static STATUS = {
    DELAYED_SUCCESS: 'delayed-success',
    ERROR: 'error',
    MANUAL: 'manual',
    NA: 'na',
    NO_CHECKING_POSSIBLE: 'no-checking-possible',
    SUCCESS: 'success',
    WARNING: 'warning',
  };
}
