import { createBrowserHistory } from 'history';
import { Route } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';

import AuthService from './auth.service';
import LocalStorageService from './localStorage.service';

import ConfigUtils from '~/utils/configUtils';
import Log from '~/utils/Log';
import GlobalStorage from '~/utils/GlobalStorage';
import UserUtils from '~/utils/userUtils';

import Config from '~/Config';

// Create Custom Sentry Route component
export const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

class ThirdPartyService {
  initMuiPro() {
    LicenseInfo.setLicenseKey(Config.muiLicenseKey);
  }

  initSentry() {
    if (LocalStorageService.sentryGoogleAnalyticsDisabled()) {
      return;
    }

    Sentry.init({
      beforeBreadcrumb(breadcrumb) {
        return breadcrumb.category === 'console' ||
          breadcrumb.category?.includes('ui.')
          ? null
          : breadcrumb;
      },
      dsn: Config.sentryDsn,

      ignoreErrors: [
        'No matching state found in storage',
        'Request failed with status code 401',
      ],

      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.browserProfilingIntegration(),
        Sentry.feedbackIntegration({
          addScreenshotButtonLabel: 'Screenshot hinzufügen',
          autoInject: UserUtils.showUserFeedbackButton(),
          cancelButtonLabel: 'Abbrechen',
          colorScheme: 'system',
          confirmButtonLabel: 'Bestätigen',

          emailLabel: 'E-Mail-Adresse',

          emailPlaceholder: 'name@domain.com',

          formTitle: 'Fehler melden',

          isEmailRequired: false,

          isNameRequired: false,

          isRequiredLabel: '(Pflichtfeld)',

          messageLabel: 'Beschreibung',

          messagePlaceholder:
            'Was ist der Fehler? Was hätte eigentlich passieren sollen?',

          nameLabel: 'Name',

          namePlaceholder: 'Dein Name',

          removeScreenshotButtonLabel: 'Screenshot löschen',

          showBranding: false,

          submitButtonLabel: 'Senden',

          successMessageText: 'Danke für deine Fehlermeldung!',

          triggerAriaLabel: 'Fehler melden',
          // i18n
          triggerLabel: 'Fehler melden',
        }),
        Sentry.replayIntegration(),
      ],

      // Profiling
      profilesSampleRate: 1,

      release: `web-customer-portal@${Config.version}`,

      // Reduce this as we gain users in production!
      // Session Replay
      replaysOnErrorSampleRate: 1,

      replaysSessionSampleRate: 0.1,

      // Reduce this as we gain users in production!
      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [/^\//, /^https:\/\/app\.vestigas\.com\/api/],

      // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
      tracesSampleRate: 1,
    });
  }

  initSentryUser() {
    const userId = AuthService.getUserIdFromAccessToken();
    Sentry.setUser({ id: userId });

    const isVestigasUser = UserUtils.isVestigasAccount();
    Sentry.setTag('isVestigasUser', isVestigasUser);
  }

  initSentryCompany(companyName) {
    Sentry.setTag('company', companyName);
  }

  initProductAnalyticsTools(featureFlag, companyName, isVestigasSupport) {
    if (!featureFlag) {
      return;
    }

    if (isVestigasSupport || UserUtils.isVestigasAccount()) {
      return;
    }

    if (!ConfigUtils.isProductionEnvironment()) {
      return;
    }

    const userId = AuthService.getUserIdFromAccessToken();

    if (!userId) {
      Log.error(
        "Failed to initialize product analytics tool because user id couldn't be retrieved from access token.",
      );
      return;
    }

    // Init and disable IP tracking
    amplitude.init('9b5a3c6fe13909baba504ba823f59263', {
      serverZone: 'EU',
      trackingOptions: {
        ipAddress: false,
      },
    });

    amplitude.setUserId(userId);

    const identifyEvent = new amplitude.Identify();
    identifyEvent.setOnce('company', companyName);
    amplitude.identify(identifyEvent);

    // Tracking Amplitude events before the libraries have been initialized leads to bugs.
    // Thus, use this variable as a Boolean to track whether the libraries have already been initialized.
    GlobalStorage.productAnalyticsToolsInitialized = true;
  }

  /*
   * @see https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#opt-users-out-of-tracking
   */
  optOutProductAnalyticsTools() {
    if (!GlobalStorage.productAnalyticsToolsInitialized) {
      return;
    }

    amplitude.setOptOut(true);

    const userId = AuthService.getUserIdFromAccessToken();
    LocalStorageService.setLocalStorage(
      `product_analytics_tools_opted_out_${userId}`,
      '1',
    );
  }

  optInProductAnalyticsTools() {
    if (!GlobalStorage.productAnalyticsToolsInitialized) {
      return;
    }

    amplitude.setOptOut(false);

    const userId = AuthService.getUserIdFromAccessToken();
    LocalStorageService.setLocalStorage(
      `product_analytics_tools_opted_out_${userId}`,
      '0',
    );
  }

  productAnalyticsToolsOptedOut() {
    const userId = AuthService.getUserIdFromAccessToken();

    const hasOptedOut =
      LocalStorageService.getLocalStorage(
        `product_analytics_tools_opted_out_${userId}`,
      ) === '1';

    return hasOptedOut;
  }
}

export default new ThirdPartyService();
