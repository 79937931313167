import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysOrganizationalUnit } from './queryKeys';

type OrganizationalUnitData = {
  name: string;
};

type MutationOrganizationalUnitArgument = {
  organizationalUnitId: UUID;
  organizationalUnitData: OrganizationalUnitData;
};

/**
 * Updates a given organizational unit.
 *
 * @param {string} organizationalUnitId - The ID of the organizational unit to update.
 * @param {Object} organizationalUnitData - An object containing the organizational unit data to update.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/update_org_unit_access_control_org_unit__org_unit_id__put
 */
export const updateOrganizationalUnit = async (
  organizationalUnitId: UUID,
  organizationalUnitData: OrganizationalUnitData,
) => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.ORGANIZATIONAL_UNIT.UPDATE(organizationalUnitId), {
        json: organizationalUnitData,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating organisational unit', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating the settings of a given organizational unit.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationUpdateOrganizationalUnit = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      organizationalUnitId,
      organizationalUnitData,
    }: MutationOrganizationalUnitArgument) =>
      updateOrganizationalUnit(organizationalUnitId, organizationalUnitData),
    async onError(_error) {
      Log.productAnalyticsEvent(
        'Failed to update organisational unit',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(_data, { organizationalUnitId }) {
      queryClient.invalidateQueries({
        queryKey: queryKeysOrganizationalUnit.get(organizationalUnitId),
      });
    },
  });
};
