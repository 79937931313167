import { useState } from 'react';

import { UserSignatureIcon } from '~/assets/icons';

import UserAction from '~/models/userActions/UserAction';

import { RequestSignatureForm } from '../../deliveryNoteActions/RequestSignatureForm';
import { DeliveryNoteActionMetaData } from '../../deliveryNoteActions/DeliveryNoteActionMetaData';

import Log from '~/utils/Log';

import IconButton from '~/components/IconButton';

export const RequestSignatureButton = ({ id, userActions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleRequestSignature = () => {
    Log.info('Open request signature form');
    Log.productAnalyticsEvent(
      'Open request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const requestedUsers = UserAction.getRequestedUsers([userActions]);

  const tooltipTitle =
    requestedUsers.length > 0 ? (
      <DeliveryNoteActionMetaData
        title="Signatur bereits angefordert von:"
        users={requestedUsers}
      />
    ) : (
      'Signatur von einem Benutzer anfordern.'
    );

  return (
    <>
      <IconButton
        onClick={handleRequestSignature}
        tooltipTitle={tooltipTitle}
        size="large"
      >
        <UserSignatureIcon
          className={
            'icon-small ' +
            (requestedUsers.length > 0 ? 'text-grey400' : 'text-primary500')
          }
        />
      </IconButton>
      {isOpen ? (
        <RequestSignatureForm
          permittedUserIds={[]}
          deliveryNoteIds={[id]}
          open={isOpen}
          closeForm={handleClose}
        />
      ) : null}
    </>
  );
};
