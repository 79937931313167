import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  queryKeysOrganizationalUnit,
  useMutationCreateOrganizationalUnit,
  useMutationDeleteOrganizationalUnit,
  useMutationUpdateOrganizationalUnits,
  useMutationUpdateOrganizationalUnit,
  useQueryOrganizationalUnit,
} from '~/data/organizationalUnit';

import ToastService from '~/services/toast.service';

import OrganisationalGroup from '~/models/masterdata/OrganisationalGroup';

import { useDelayedValue } from '~/hooks/useDelayedValue';

import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';

import { selectOrganizationalUnit } from '../selectOrganizationalUnit';

import { handleSubmit as onSubmit } from './handleSubmit';

export const useOrganizationalUnitForm = ({
  closeForm,
  onRefreshEntities,
  organizationalUnitId,
  type,
}) => {
  const isCreatingOrganizationalUnit = type === 'create';

  const queryClient = useQueryClient();

  const {
    data: organisationalGroup,
    isLoading,
    isError,
    isSuccess,
    refetch: refetchOrganizationalUnit,
  } = useQueryOrganizationalUnit(organizationalUnitId, {
    enabled: !isCreatingOrganizationalUnit && Boolean(organizationalUnitId),
    placeholderData() {
      // Try to get some basic data from the list item cache to improve the rendering experience.
      const queriesData = queryClient.getQueriesData({
        queryKey: queryKeysOrganizationalUnit.getAll().filter(Boolean),
      });

      for (const [, data] of queriesData) {
        if (Array.isArray(data?.data)) {
          const listItem = data?.data?.find(
            ({ id }) => id === organizationalUnitId,
          );

          if (listItem) {
            const { id, name } = listItem;

            return new OrganisationalGroup({ id, name });
          }
        }
      }

      return undefined;
    },
    select: selectOrganizationalUnit,
  });

  const refetchOrganizationalUnits = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: queryKeysOrganizationalUnit.getAll({}),
    });
  }, [queryClient]);

  const getDefaultOrganisationalGroup = () => new OrganisationalGroup();

  const [state, setState] = useState(getDefaultOrganisationalGroup());

  const {
    mutateAsync: createOrganizationalUnitMutation,
    isPending: isPendingCreateOrganizationalUnit,
  } = useMutationCreateOrganizationalUnit({
    onError(error) {
      Log.error('createOrganizationalUnit error');
    },
  });

  const {
    mutateAsync: updateOrganizationalUnitMutation,
    isPending: isPendingOrganizationalUnit,
  } = useMutationUpdateOrganizationalUnit({
    onError(error) {
      Log.error('updateOrganizationalUnit error');
    },
  });

  const {
    mutateAsync: updateOrganizationalUnitsMutation,
    isPending: isPendingOrganizationalUnits,
  } = useMutationUpdateOrganizationalUnits();

  const isSubmitting =
    isPendingCreateOrganizationalUnit ||
    isPendingOrganizationalUnit ||
    isPendingOrganizationalUnits;

  const {
    mutateAsync: deleteOrganizationalUnitMutation,
    isPending: isDeleting,
  } = useMutationDeleteOrganizationalUnit({
    onError(error) {
      ToastService.httpError(
        ['Organisations-Gruppe konnte nicht gelöscht werden.'],
        error,
      );

      Log.error('Failed to delete organizational unit.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.ORGANISATIONAL_GROUP,
        Log.TYPE.ERROR,
      );
    },
  });

  const resetForm = useCallback(() => {
    setState(organisationalGroup ?? getDefaultOrganisationalGroup());
  }, [getDefaultOrganisationalGroup, organisationalGroup]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit({
      closeForm,
      createOrganizationalUnitMutation,
      isCreatingOrganizationalUnit,
      // onRefreshEntities,
      organizationalUnit: state,
      originalData: organisationalGroup,
      queryClient,
      refetchOrganizationalUnit,
      refetchOrganizationalUnits,
      resetForm,
      updateOrganizationalUnitMutation,
      updateOrganizationalUnitsMutation,
    });
  };

  const handleCancel = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.ORGANISATIONAL_GROUP);

    closeForm();
    resetForm();
  };

  const handleDeleteOrganizationalUnit = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete organisational group',
      {
        id: organizationalUnitId,
      },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.ORGANISATIONAL_GROUP);

    await deleteOrganizationalUnitMutation(organizationalUnitId);

    closeForm();
    refetchOrganizationalUnits();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setState((previousState) => {
      const newOrganisationalGroup = cloneDeep(previousState);
      newOrganisationalGroup[name] = value;

      Log.info(
        `Change form value of ${name}`,
        {
          from: previousState[name],
          to: value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );

      FunctionUtils.delayFunction(
        'organisational_group_change_name',
        Log.productAnalyticsEvent,
        ['Change name', Log.FEATURE.ORGANISATIONAL_GROUP],
      );

      return newOrganisationalGroup;
    });
  };

  const handleChangeEntity = (entityType, { added, removed }, setState) => {
    setState((previousState) => {
      const newOrganizationalUnit = cloneDeep(previousState);

      const removedIds = new Set(removed.map(({ id }) => id));
      const addedIds = new Set(added.map(({ id }) => id));

      newOrganizationalUnit[entityType] = [
        ...new Set([
          ...newOrganizationalUnit[entityType].filter(
            (id) => !removedIds.has(id),
          ),
          ...addedIds,
        ]),
      ];

      Log.info(
        `Change form value of ${entityType}`,
        {
          from: previousState[entityType],
          to: newOrganizationalUnit[entityType],
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        `Change ${entityType}`,
        Log.FEATURE.ORGANISATIONAL_GROUP,
      );

      return {
        ...previousState,
        [entityType]: newOrganizationalUnit[entityType],
      };
    });
  };

  const unsavedChanges = useMemo(() => {
    if (isCreatingOrganizationalUnit) {
      return [];
    }

    if (isLoading || isError || !isSuccess || !organisationalGroup) {
      return [];
    }

    if (!organisationalGroup.additionalDataInitiated) {
      return [];
    }

    return OrganisationalGroup.getDifferentValues(organisationalGroup, state);
  }, [
    isCreatingOrganizationalUnit,
    isLoading,
    isError,
    isSuccess,
    JSON.stringify(organisationalGroup),
    JSON.stringify(state),
  ]);

  const delayedUnsavedChanges = useDelayedValue(unsavedChanges);

  useEffect(() => {
    resetForm();
  }, [isSuccess, isError, JSON.stringify(organisationalGroup)]);

  return {
    handleCancel,
    handleChangeEntity: (entityType, { added, removed }) =>
      handleChangeEntity(entityType, { added, removed }, setState),
    handleDeleteOrganizationalUnit,
    handleInputChange,
    handleSubmit,
    isCreatingOrganizationalUnit,
    isDeleting,
    isError,
    isLoading,
    isSubmitting,
    organisationalGroup: state,
    refetchOrganizationalUnit,
    unsavedChanges: delayedUnsavedChanges,
  };
};
