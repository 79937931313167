import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysOrganizationalUnit } from './queryKeys';

type OrganizationalUnitData = {
  name: string;
  accountingReferences?: UUID[];
  childOus?: UUID[];
  companies?: UUID[];
  parentOus?: UUID[];
  sites?: UUID[];
  syncKey?: string;
  userGroups?: UUID[];
  users?: UUID[];
  vehicles?: UUID[];
};

type OrganizationalUnitCreateResponse = {
  code: number;
  message: string;
  id: UUID;
};

/**
 * Creates a new organizational unit.
 *
 * @param {OrganizationalUnitData} organizationalUnitData - An object containing the organizational unit data.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/create_organizational_unit_access_control_org_unit_post
 */
export const createOrganizationalUnit = async (
  organizationalUnitData: OrganizationalUnitData,
) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.ORGANIZATIONAL_UNIT.CREATE(), {
        json: organizationalUnitData,
      })
      .json<OrganizationalUnitCreateResponse>();

    return response;
  } catch (error) {
    Log.error('Error creating organizational unit', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for creating a new organizational unit.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationCreateOrganizationalUnit = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (organizationalUnitData: OrganizationalUnitData) =>
      createOrganizationalUnit(organizationalUnitData),
    async onError(_error) {
      Log.productAnalyticsEvent(
        'Failed to create organizational unit',
        Log.FEATURE.ORGANIZATIONAL_UNIT,
        Log.TYPE.ERROR,
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeysOrganizationalUnit.getAll({}),
      });
    },
    ...options,
  });
};
