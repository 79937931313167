import { useState } from 'react';

import PermissionGrantService from '~/services/permissionGrant.service';
import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

export const usePermissionForm = (refreshData, permissionGrants, type) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (permissions, permissionGrantId) => {
    if (!permissionGrantId) {
      return;
    }

    setIsSubmitting(true);

    const permissionGrant = permissionGrants.find(
      ({ id }) => id === permissionGrantId,
    );

    if (permissionGrant) {
      const [isDuplicate, error] = await promiseHandler(
        PermissionGrantService.isDuplicatePermissionGrant(
          type,
          permissionGrant.subjectType,
          permissionGrant.subjectId,
          permissionGrant.entityType,
          permissionGrant.entityId,
          permissions,
        ),
      );

      if (error) {
        Log.error('Failed to detect duplicate permission grant.', error);
        Log.productAnalyticsEvent(
          'Failed to detect duplicate permission grant',
          Log.FEATURE.PERMISSIONS,
          Log.TYPE.ERROR,
        );
      }

      if (isDuplicate) {
        ToastService.warning([
          'Die Berechtigung wurde nicht aktualisiert, da diese Berechtigung bereits vorhanden ist.',
        ]);

        setIsSubmitting(false);
        return;
      }
    } else {
      Log.error('Failed to find permission grant. id: ' + permissionGrantId);
      Log.productAnalyticsEvent(
        'Failed to find permission grant',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
    }

    // FIXME VGS-6957: replace with mutation
    const [, error2] = await promiseHandler(
      PermissionGrantService.updatePermissionGrant(permissionGrantId, {
        permissions: permissions.getBackendPermissions(),
      }),
    );

    if (error2) {
      ToastService.httpError(
        ['Berechtigung konnte nicht aktualisiert werden.'],
        error2.response,
      );

      Log.error(
        `Failed to update permission grant. id: ${permissionGrantId}`,
        error2,
      );
      Log.productAnalyticsEvent(
        'Failed to update',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
    }

    ToastService.success(['Berechtigung wurden aktualisiert.']);

    setIsSubmitting(false);
    refreshData();
  };

  const handleDelete = async (rowSelectionModel, setState) => {
    if (rowSelectionModel.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Berechtigung aus, die gelöscht werden soll.',
      ]);

      Log.productAnalyticsEvent(
        'No permission selected to be deleted',
        Log.FEATURE.PERMISSIONS,
      );

      return;
    }

    setState((previousState) => ({
      ...previousState,
      deletingPermissionGrants: true,
    }));

    Log.info(
      'Delete permission grants',
      { rowSelectionModel },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.PERMISSIONS);

    // FIXME VGS-6957: replace with mutation
    const promises = rowSelectionModel.map((id) =>
      PermissionGrantService.deletePermissionGrant(id),
    );

    try {
      await Promise.all(promises);
      ToastService.success(['Berechtigungen wurden gelöscht.']);
    } catch (error) {
      ToastService.error([
        'Berechtigungen konnten nicht vollständig gelöscht werden.',
      ]);

      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.PERMISSIONS,
        Log.TYPE.ERROR,
      );
      Log.error('Failed to delete permissions.', error);
    } finally {
      setState((previousState) => ({
        ...previousState,
        deletingPermissionGrants: false,
      }));
      refreshData();
    }
  };

  return {
    handleDelete,
    handleSubmit,
    isSubmitting,
  };
};
