import { type UUID } from '~/types/common';
import { type CustomerSubscriptionsQueryParams } from './useQueryCustomerSubscriptions';

/**
 * Query key factory for customFields-related queries.
 */
export const queryKeysCustomerSubscriptions = {
  base: () => 'customerSubsctiption' as const,
  get: (articleId: UUID) =>
    [queryKeysCustomerSubscriptions.base(), 'detail', { articleId }] as const,
  getAll: (queryParams: Partial<CustomerSubscriptionsQueryParams>) =>
    [queryKeysCustomerSubscriptions.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<CustomerSubscriptionsQueryParams>) =>
    [
      queryKeysCustomerSubscriptions.base(),
      'list',
      'infinite',
      queryParams,
    ] as const,
} as const;
