import { useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { queryKeysUser } from '~/data/user';

import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import Wizard from '~/components/Wizard';
import { withErrorBoundary } from '~/ui/atoms';

import {
  DeletePermissions,
  GrantPermissions,
  UpdatePermissionsButton,
} from './components';

export const UpdatePermissionsWizard = withErrorBoundary(() => {
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [deletePermissions, setDeletePermissions] = useState(true);
  const [grantPermissions, setGrantPermissions] = useState(true);
  const [pickedSubjects, setPickedSubjects] = useState([]); // We need to control this state on such a high level as we want to prefill the user list in the deletion step with the user from the granting step.

  const permissionDeleteRef = useRef();
  const permissionGrantRef = useRef();

  const getWizardSteps = () => {
    const wizardSteps = [
      {
        component: (
          <GrantPermissions
            ref={permissionGrantRef}
            grantPermissions={grantPermissions}
            pickedSubjects={pickedSubjects}
            setGrantPermissions={setGrantPermissions}
            setPickedSubjects={setPickedSubjects}
          />
        ),
        title: 'Neue Berechtigungen vergeben',
      },
      {
        component: (
          <DeletePermissions
            ref={permissionDeleteRef}
            deletePermissions={deletePermissions}
            pickedSubjects={pickedSubjects}
            setDeletePermissions={setDeletePermissions}
            setPickedSubjects={setPickedSubjects}
          />
        ),
        title: 'Bestehende Berechtigungen löschen',
      },
    ];

    return wizardSteps.map((step) => ({
      ...step,
      component: (
        <div key={step.title} className="my-8">
          {step.component}
        </div>
      ),
    }));
  };

  const onCloseWizard = () => {
    Log.productAnalyticsEvent(
      'Close update permissions wizard',
      Log.FEATURE.WIZARD,
    );

    setIsOpen(false);
  };

  const onOpenWizard = () => {
    Log.productAnalyticsEvent(
      'Open update permissions wizard',
      Log.FEATURE.WIZARD,
    );

    setIsOpen(true);
  };

  const resetForm = () => {
    setDeletePermissions(true);
    setGrantPermissions(true);
    setPickedSubjects([]);
  };

  const submitPermissions = async () => {
    setIsSubmitting(true);

    Log.info(
      'Submit update permissions wizard',
      null,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Submit update permissions wizard',
      Log.FEATURE.WIZARD,
    );

    if (grantPermissions) {
      const [response, error] = await promiseHandler(
        permissionGrantRef.current.submit(),
      );

      if (error) {
        ToastService.error([
          'Berechtigungen konnten nicht vollständig vergeben werden.',
        ]);

        Log.error('Failed to grant permissions.', error);
        Log.productAnalyticsEvent(
          'Failed to grant permissions',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );

        setIsSubmitting(false);
        return false;
      }

      // Accessing response[0]?.value is a not clean solution.
      // Otherwise, we would have to flatten the promises in the permission grant pickers which makes the code in the child component a bit more complex.
      if (response[0]?.value?.length > 0) {
        ToastService.success('Berechtigungen wurden vergeben.');
      }
    }

    if (deletePermissions) {
      const [, error2] = await promiseHandler(
        permissionDeleteRef.current.submit(),
      );

      if (error2) {
        ToastService.error(
          'Berechtigungen konnten nicht vollständig gelöscht werden.',
        );

        Log.error('Failed to delete permissions.', error2);
        Log.productAnalyticsEvent(
          'Failed to delete permissions',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );

        setIsSubmitting(false);
        return false;
      }
    }

    setIsOpen(false);
    setIsSubmitting(false);
    resetForm();

    queryClient.invalidateQueries({
      queryKey: queryKeysUser.getAll({}),
    });

    return true;
  };

  return (
    <div>
      <UpdatePermissionsButton onClick={onOpenWizard} />
      <Wizard
        closeWizard={onCloseWizard}
        fullWidth
        open={isOpen}
        steps={getWizardSteps()}
        submittingWizard={isSubmitting}
        title="Berechtigungen anpassen"
        wizardSuccess={submitPermissions}
      />
    </div>
  );
}, 'Daten konnten nicht geladen werden.');
