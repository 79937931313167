import PermissionGrant from './PermissionGrant';
import ArrayUtils from '~/utils/arrayUtils';

export default class OrganisationalGroup {
  constructor(organisationalGroup, additionalDataInitiated = true) {
    this.id = organisationalGroup?.id ?? null;
    this.name = organisationalGroup?.name ?? null;

    this.parentOrganisationalGroups =
      organisationalGroup?.parent_ous ?? organisationalGroup?.parentOus ?? []; // deprecated - use parentOrganizationalUnits instead
    this.parentOrganizationalUnits =
      organisationalGroup?.parent_organizational_units ??
      organisationalGroup?.parentOrganizationalUnits ??
      [];
    this.childOrganisationalGroups =
      organisationalGroup?.child_ous ?? organisationalGroup?.childOus ?? [];
    this.organisationalGroupPaths =
      organisationalGroup?.org_unit_paths ??
      organisationalGroup?.orgUnitPaths ??
      []; // deprecated - use parentOrganizationalUnitPaths instead
    this.parentOrganizationalUnitPaths =
      organisationalGroup?.parentOrganizationalUnitPaths ?? [];

    this.users = organisationalGroup?.users ?? [];
    this.sites = organisationalGroup?.sites ?? [];
    this.costCenters =
      organisationalGroup?.accounting_references ??
      organisationalGroup?.accountingReferences ??
      [];
    this.vehicles = organisationalGroup?.vehicles ?? [];
    this.companies = organisationalGroup?.companies ?? [];
    this.userGroups =
      organisationalGroup?.user_groups ?? organisationalGroup?.userGroups ?? [];

    this.permissionGrantsFrom =
      organisationalGroup?.permissions_from?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            targetId: this.id,
            targetType: PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY,
          }),
      ) ??
      organisationalGroup?.permissionsFrom?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            targetId: this.id,
            targetType: PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY,
          }),
      ) ??
      [];

    // deprecated: remove once all data is loaded via the new paginated endpoint
    // This variable is used to track whether additional data such as permissions and paths are already initiated.
    // In the future, this data will not be provided anymore by the bulk endpoint and must be loaded separately when the user opens the respective master data form.
    this.additionalDataInitiated = additionalDataInitiated;

    // Workaround: Prevent the paths to be loaded when the GET /all endpoint is called as the paths are currently still provided and cause visual bugs.
    if (!this.additionalDataInitiated) {
      this.parentOrganisationalGroups = [];
    }
  }

  // We want to know whether this organisational group is the highest one (e.g. mother or company account). Therefore, we test
  // - if it is initialized (this.id) and
  // - if it has no parents and
  // - if it is no path with a depth of more than one item
  isHighestOrganisationalGroup() {
    return (
      this.id &&
      this.parentOrganisationalGroups.length === 0 &&
      this.organisationalGroupPaths.filter((path) => path.length > 1).length ===
        0
    );
  }

  static getDifferentValues(organisationalGroupA, organisationalGroupB) {
    const differentValues = [];

    if (organisationalGroupA?.id !== organisationalGroupB?.id) {
      differentValues.push('ID');
    }

    if (organisationalGroupA?.name !== organisationalGroupB?.name) {
      differentValues.push('Name');
    }

    const differentParentOrganisationalGroups = ArrayUtils.getDifference(
      organisationalGroupA?.parentOrganisationalGroups,
      organisationalGroupB?.parentOrganisationalGroups,
    );
    if (
      differentParentOrganisationalGroups[0].length > 0 ||
      differentParentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen (Ist Teil von...)');
    }

    const differentChildOrganisationalGroups = ArrayUtils.getDifference(
      organisationalGroupA?.childOrganisationalGroups,
      organisationalGroupB?.childOrganisationalGroups,
    );
    if (
      differentChildOrganisationalGroups[0].length > 0 ||
      differentChildOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    const differentUsers = ArrayUtils.getDifference(
      organisationalGroupA?.users,
      organisationalGroupB?.users,
    );
    if (differentUsers[0].length > 0 || differentUsers[1].length > 0) {
      differentValues.push('Benutzer');
    }

    const differentSites = ArrayUtils.getDifference(
      organisationalGroupA?.sites,
      organisationalGroupB?.sites,
    );
    if (differentSites[0].length > 0 || differentSites[1].length > 0) {
      differentValues.push('Standorte');
    }

    const differentCostCenters = ArrayUtils.getDifference(
      organisationalGroupA?.costCenters,
      organisationalGroupB?.costCenters,
    );
    if (
      differentCostCenters[0].length > 0 ||
      differentCostCenters[1].length > 0
    ) {
      differentValues.push('Kostenstellen');
    }

    const differentVehicles = ArrayUtils.getDifference(
      organisationalGroupA?.vehicles,
      organisationalGroupB?.vehicles,
    );
    if (differentVehicles[0].length > 0 || differentVehicles[1].length > 0) {
      differentValues.push('Fahrzeuge');
    }

    const differentCompanies = ArrayUtils.getDifference(
      organisationalGroupA?.companies,
      organisationalGroupB?.companies,
    );
    if (differentCompanies[0].length > 0 || differentCompanies[1].length > 0) {
      differentValues.push('Firmen');
    }

    const differentUserGroups = ArrayUtils.getDifference(
      organisationalGroupA?.userGroups,
      organisationalGroupB?.userGroups,
    );
    if (
      differentUserGroups[0].length > 0 ||
      differentUserGroups[1].length > 0
    ) {
      differentValues.push('Benutzer-Gruppen');
    }

    return differentValues;
  }
}
