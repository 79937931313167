import { useMemo } from 'react';
import {
  type GridColDef,
  type GridColumnVisibilityModel,
  type GridPaginationModel,
  type GridSortModel,
} from '@mui/x-data-grid';

import { ComponentTestIds, SettingsTestIds } from '~/constants/test-ids';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import { FreeTextSearch } from '~/components/baseComponents/inputs/FreeTextSearch';
import BasicTable from '~/components/BasicTable';
import {
  DataManagementLayout,
  DataTable,
  FiltersRow,
  PageTitle,
} from '~/ui/layouts/DataManagementLayout';
import { DatagridServerDriven } from '~/ui/molecules/Datagrid';

import { PermissionGrantDialog } from '../masterData/permissionGrant/PermissionGrantDialog';

import { ActiveStateFilter } from './ActiveStateFilter';
import { CreateItemButton } from './CreateItemButton';
import { FormComponent } from './FormComponent';
import { useSettingsTable, useSettingsTableLEGACY } from './hooks';
import { type EntityType } from './types';

type FilterActive = boolean | '';

type P = {
  columns: GridColDef[];
  columnVisibilityModel?: GridColumnVisibilityModel;
  entity: EntityType;
  filterActive: FilterActive;
  filterFunction: (item: Record<string, unknown>) => boolean;
  hasActiveStateFilter: boolean;
  hasMultiPermissionGrantEditing: boolean;
  isLoading: boolean;
  items: any[]; // TODO: type this properly
  localStorageKey: string;
  multiPermissionGrantDefaultEntityType: string;
  multiPermissionGrantDefaultSubjectType: string;
  multiPermissionGrantFixedPicker:
    | typeof PermissionGrant.TYPE.SUBJECT
    | typeof PermissionGrant.TYPE.ENTITY;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  onSearchStringChange: (searchString: string) => void;
  onSortModelChange: (model: GridSortModel) => void;
  paginationModel: GridPaginationModel;
  refreshData: () => void;
  rows: any[]; // TODO: type this properly
  setActiveFilter: (filterActive: FilterActive) => void;
  sortBy: string;
  sortModel: GridSortModel;
  title: string;
  totalRowCount: number;
};

/**
 * SettingsTable Component
 *
 * This component renders a settings page with a configurable data table for various settings entities.
 * It supports both client-side and server-side pagination, sorting, and filtering.
 */
export const SettingsTable = ({
  columns,
  columnVisibilityModel,
  entity,
  filterActive,
  filterFunction,
  hasActiveStateFilter,
  hasMultiPermissionGrantEditing,
  isLoading,
  items,
  localStorageKey,
  multiPermissionGrantDefaultEntityType,
  multiPermissionGrantDefaultSubjectType,
  multiPermissionGrantFixedPicker,
  onPaginationModelChange,
  onSearchStringChange,
  onSortModelChange,
  paginationModel,
  refreshData,
  rows,
  setActiveFilter,
  sortBy,
  sortModel,
  title,
  totalRowCount,
}: P) => {
  const { eventHandlers, refreshEntities, setState, state } = useSettingsTable({
    entity,
    filterFunction,
    hasActiveStateFilter,
    hasMultiPermissionGrantEditing,
    items,
    onSearchStringChange,
    rows,
    sortBy,
  });

  // We need a different Datagrid for data that is already using server-side paginated data.
  const isMigratedToServerSidePagination = [
    'company',
    'costCenter',
    'organisationalGroup',
    'site',
    'user',
    'userGroup',
    'vehicle',
    'article',
    'category',
    'customField',
    'customerSubscription',
    'invoiceCheckIgnoredArticle',
    'signatureField',
  ].includes(entity);

  const legacyTablesFunctionality = useSettingsTableLEGACY({
    entity,
    filterFunction,
    hasActiveStateFilter,
    isMigratedToServerSidePagination,
    items,
    onSearchStringChange,
    refreshData,
    rows,
    sortBy,
  });

  const memoizedDatagrid = useMemo(() => {
    if (!isMigratedToServerSidePagination) {
      return null;
    }

    return (
      <DatagridServerDriven
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        csvOptions={{
          allColumns: true,
        }}
        isLoading={isLoading}
        onMultiPermissionGrantEdit={eventHandlers.handleMultiPermissionGrantEdit()}
        onPaginationModelChange={onPaginationModelChange}
        onRowClick={(params) => eventHandlers.onOpenForm(params.row)}
        onRowSelectionModelChange={eventHandlers.onRowSelectionModelChange}
        onSortModelChange={onSortModelChange}
        paginationMeta={{ totalRowCount }}
        paginationModel={paginationModel}
        rows={state.filteredRows}
        sortModel={sortModel}
        checkboxSelection
        disableRowSelectionOnClick
      />
    );
  }, [
    columns,
    columnVisibilityModel,
    eventHandlers,
    isMigratedToServerSidePagination,
    isLoading,
    onPaginationModelChange,
    onSortModelChange,
    paginationModel,
    sortModel,
    state.filteredRows,
    totalRowCount,
  ]);

  if (isMigratedToServerSidePagination) {
    return (
      <DataManagementLayout>
        <PageTitle data-testid={SettingsTestIds.HEADER.TITLE} className="pt-4">
          {title}
        </PageTitle>
        <FiltersRow
          CreateItemButton={
            <CreateItemButton
              entity={entity}
              onOpenForm={eventHandlers.onOpenForm}
            />
          }
          Filters={
            hasActiveStateFilter ? (
              <ActiveStateFilter
                value={filterActive}
                onChange={setActiveFilter}
              />
            ) : null
          }
          SearchInput={
            <FreeTextSearch
              value={state.freeTextFilter}
              onChange={eventHandlers.setFreeTextFilter}
              debounceTime={250}
              productAnalyticsFeature={Log.FEATURE.SETTINGS_TABLE}
              testId={ComponentTestIds.SEARCH_INPUT.INPUT}
              autoFocus
            />
          }
        />
        <DataTable>{memoizedDatagrid}</DataTable>
        {UserUtils.isPermissionGrantAllowedUser() &&
          state.showMultiPermissionGrantEdit && (
            <PermissionGrantDialog
              isOpen={state.showMultiPermissionGrantEdit}
              refreshData={refreshData}
              closeForm={eventHandlers.closeMultiPermissionGrantEdit}
              defaultSubjects={
                multiPermissionGrantFixedPicker === PermissionGrant.TYPE.SUBJECT
                  ? state.rowSelectionModel
                  : []
              }
              defaultSubjectType={multiPermissionGrantDefaultSubjectType}
              defaultEntities={
                multiPermissionGrantFixedPicker === PermissionGrant.TYPE.ENTITY
                  ? state.rowSelectionModel
                  : []
              }
              defaultEntityType={multiPermissionGrantDefaultEntityType}
              fixedPicker={multiPermissionGrantFixedPicker}
            />
          )}
        <FormComponent
          closeForm={eventHandlers.closeForm}
          entity={entity}
          onOpenCompany={eventHandlers.onOpenCompany}
          onOpenCostCenter={eventHandlers.onOpenCostCenter}
          onOpenOrganisationalGroup={eventHandlers.onOpenOrganisationalGroup}
          onOpenSite={eventHandlers.onOpenSite}
          onOpenUser={eventHandlers.onOpenUser}
          onOpenUserGroup={eventHandlers.onOpenUserGroup}
          onOpenVehicle={eventHandlers.onOpenVehicle}
          refreshEntities={refreshEntities}
          refreshData={refreshData}
          setState={setState}
          state={state}
        />
      </DataManagementLayout>
    );
  }

  // -------------------------------------------------------------------------------------------------------------------
  // TODO: this is legacy code that should be removed once all entities are migrated to server-side pagination:
  // -------------------------------------------------------------------------------------------------------------------

  const { eventHandlersLEGACY, setStateLEGACY, stateLEGACY } =
    legacyTablesFunctionality;

  return (
    <DataManagementLayout>
      <PageTitle data-testid={SettingsTestIds.HEADER.TITLE} className="pt-4">
        {title}
      </PageTitle>
      <FiltersRow
        CreateItemButton={
          <CreateItemButton
            entity={entity}
            onOpenForm={eventHandlersLEGACY.onOpenForm}
          />
        }
        Filters={
          hasActiveStateFilter ? (
            <ActiveStateFilter
              value={stateLEGACY.activeStateFilter}
              onChange={eventHandlersLEGACY.setActiveStateFilter}
            />
          ) : null
        }
        SearchInput={
          <FreeTextSearch
            value={stateLEGACY.freeTextFilter}
            onChange={eventHandlersLEGACY.setFreeTextFilter}
            debounceTime={250}
            productAnalyticsFeature={Log.FEATURE.SETTINGS_TABLE}
            testId={ComponentTestIds.SEARCH_INPUT.INPUT}
            autoFocus
          />
        }
      />
      <DataTable>
        <BasicTable
          columns={columns}
          defaultHiddenColumns={['id']}
          excelColumns={columns}
          excelData={stateLEGACY.excelData}
          loading={isLoading}
          localStorageKey={localStorageKey}
          onRowClick={(params) => eventHandlersLEGACY.onOpenForm(params.row)}
          onRowSelectionModelChange={
            eventHandlersLEGACY.onRowSelectionModelChange
          }
          onSortModelChange={eventHandlersLEGACY.onSortModelChange}
          rows={stateLEGACY.filteredRows}
          rowSelectionModel={stateLEGACY.rowSelectionModel}
          sortModel={stateLEGACY.sortModel}
          disableRowSelectionOnClick
          checkboxSelection
        />
      </DataTable>
      <FormComponent
        closeForm={eventHandlersLEGACY.closeForm}
        entity={entity}
        refreshEntities={refreshEntities}
        refreshData={refreshData}
        setState={setStateLEGACY}
        state={stateLEGACY}
      />
    </DataManagementLayout>
  );
};
