import ArrayUtils from '~/utils/arrayUtils';

import LicensePlate from './LicensePlate';
import PermissionGrant from './PermissionGrant';

export default class Vehicle {
  constructor(vehicle, additionalDataInitiated) {
    this.id = vehicle?.id ?? null;
    this.licensePlate = new LicensePlate(
      vehicle?.license_plate ?? vehicle?.licensePlate,
    );
    this.company = vehicle?.company_id ?? vehicle?.companyId ?? null; // TODO: this can likely be removed!
    this.companyId = vehicle?.company_id ?? vehicle?.companyId ?? null;
    this.companyName = vehicle?.company_name ?? vehicle?.companyName ?? null;
    this.active = vehicle?.is_active ?? vehicle?.isActive ?? true; // FIXME: it is so bad that this is renamed to a much worse name!
    this.isActive = vehicle?.is_active ?? vehicle?.isActive ?? true;

    this.organisationalGroups = vehicle?.org_units ?? vehicle?.orgUnits ?? []; // deprecated - use parentOrganizationalUnits instead
    this.parentOrganizationalUnits =
      vehicle?.parent_organizational_units ??
      vehicle?.parentOrganizationalUnits ??
      [];
    this.organisationalGroupPaths =
      vehicle?.org_unit_paths ?? vehicle?.orgUnitPaths ?? []; // deprecated - use parentOrganizationalUnitPaths instead
    this.parentOrganizationalUnitPaths =
      vehicle?.parentOrganizationalUnitPaths ?? [];

    this.permissionGrantsFrom =
      (vehicle?.permissions_from ?? vehicle?.permissionsFrom)?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            targetId: this.id,
            targetType: PermissionGrant.ENTITY_TYPE.VEHICLE.KEY,
          }),
      ) ?? [];

    // This variable is used to track whether additional data such as permissions and paths are already initiated.
    // In the future, this data will not be provided anymore by the bulk endpoint and must be loaded separately when the user opens the respective master data form.
    this.additionalDataInitiated = additionalDataInitiated;

    // Workaround: Prevent the paths to be loaded when the GET /all endpoint is called as the paths are currently still provided and cause visual bugs.
    if (!this.additionalDataInitiated) {
      this.organisationalGroupPaths = [];
    }
  }

  static getDifferentValues(vehicleA, vehicleB) {
    const differentValues = [];

    if (vehicleA?.id !== vehicleB?.id) {
      differentValues.push('ID');
    }

    if (vehicleA?.company !== vehicleB?.company) {
      differentValues.push('Firma');
    }

    if (vehicleA?.isActive !== vehicleB.isActive) {
      differentValues.push('Aktiv');
    }

    if (
      LicensePlate.getDifferentValues(
        vehicleA?.licensePlate,
        vehicleB?.licensePlate,
      ).length > 0
    ) {
      differentValues.push('Kfz-Kennzeichen');
    }

    const differentOrganisationalGroups = ArrayUtils.getDifference(
      vehicleA?.organisationalGroups,
      vehicleB?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    return differentValues;
  }

  static IS_ACTIVE = {
    NO: 'Nein',
    YES: 'Ja',
  };
}
