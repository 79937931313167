import React, { memo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import UserUtils from '~/utils/userUtils';

import BasicForm from '~/components/BasicForm';
import PasswordTextField from '~/components/baseComponents/inputs/PasswordTextField';
import Select from '~/components/baseComponents/inputs/select/Select';

import { MultiItemsManagerOrganizationalUnits } from '~/ui/molecules/MultiItemsManager';
import { SelectCompany } from '~/ui/molecules/SelectServerDriven';

import { OrganizationalUnitPaths } from '../../paths/OrganizationalUnitPaths';
import { UserGroupPaths } from '../../paths/UserGroupPaths';
import { PermissionGrantSubjectTable } from '../../permissionGrant/PermissionsTable';

import { FeatureFlagSettings } from './components/FeatureFlagSettings';
import { OnBehalfSignatureRolesSettings } from './components/OnBehalfSignatureRolesSettings';
import { PushNotificationSettings } from './components/PushNotificationSettings';
import { SignatureRolesSettings } from './components/SignatureRolesSettings';

import { useUserForm } from './useUserForm';
import { UsersSettingsTestIds } from '~/constants/test-ids/users-settings-testids';
import { ComponentTestIds } from '~/constants/test-ids';

/**
 * Compare props that should cause memo to rerender UserForm.
 */
const propsAreEqual = (prevProps, nextProps) =>
  prevProps.open === nextProps.open &&
  prevProps.type === nextProps.type &&
  prevProps.userCompanyId === nextProps.userCompanyId &&
  prevProps.userId === nextProps.userId;

export const UserForm = memo(
  ({
    closeForm,
    onOpenOrganisationalGroup,
    onOpenUserGroup,
    onUpdatedOrganisationalGroupsChange,
    open,
    type,
    userCompanyId,
    userId,
  }) => {
    const {
      getDefaultSignatureRoleOptions,
      handleCancel,
      handleChange,
      handleChangeCompany,
      handleChangeDefaultSignatureRole,
      handleChangeOrganisationalGroups,
      handleOpenOrganisationalGroup,
      handleSubmit,
      handleUpdateUser,
      isCreatingUser,
      isError,
      isLoading,
      isSubmitting,
      refetchUser,
      unsavedChanges,
      user,
    } = useUserForm({
      closeForm,
      onOpenOrganisationalGroup,
      type,
      userCompanyId,
      userId,
    });

    const shouldRenderGroupPaths =
      user?.parentOrganizationalUnitPaths?.length > 0 ||
      user?.userGroupPaths?.length > 0;

    return (
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleCancel}
        title={`Benutzer ${isCreatingUser ? 'Erstellen' : (user?.email ?? '')}`}
        submittingForm={isSubmitting}
        id={user.id}
        unsavedChanges={unsavedChanges}
        missingPermissionsToSubmit={
          isCreatingUser
            ? !UserUtils.isUserCreateAllowedUser()
            : !UserUtils.isUserWriteAllowedUser()
        }
        fullWidth
        testId={ComponentTestIds.FORM_MODAL.SUBMIT_BUTTON}
      >
        <Grid container direction="row" spacing={3} space={4}>
          {shouldRenderGroupPaths ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="flex flex-col gap-2">
                    <OrganizationalUnitPaths
                      id={user.id}
                      organizationalUnitPaths={
                        user.parentOrganizationalUnitPaths
                      }
                      onOpenOrganizationalUnit={handleOpenOrganisationalGroup}
                    />
                    <UserGroupPaths
                      id={user.id}
                      userGroupPaths={user.userGroupPaths}
                      onOpenUserGroup={(userGroup) =>
                        onOpenUserGroup(userGroup, unsavedChanges)
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <h3 className="main-text mb-4 mt-0">Benutzer</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="email-input"
                  name="email"
                  label="E-Mail"
                  type="text"
                  fullWidth
                  required
                  value={user.email}
                  onChange={handleChange}
                  autoComplete="off"
                  inputProps={{
                    'data-testid': UsersSettingsTestIds.MODAL_FIELDS.EMAIL,
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <PasswordTextField
                  label={isCreatingUser ? 'Passwort' : 'Passwort zurücksetzen'}
                  fullWidth
                  required={isCreatingUser}
                  value={user.password}
                  onChange={handleChange}
                  testId={UsersSettingsTestIds.MODAL_FIELDS.PASSWORD}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <FormControlLabel
                  disabled={isCreatingUser}
                  control={
                    <Checkbox
                      checked={user.isActive}
                      onChange={handleChange}
                      name="isActive"
                    />
                  }
                  label="Aktiv"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="first_name-input"
                  name="first_name"
                  label="Vorname"
                  type="text"
                  value={user.firstName}
                  onChange={handleChange}
                  autoComplete="off"
                  fullWidth
                  required
                  inputProps={{
                    'data-testid': UsersSettingsTestIds.MODAL_FIELDS.FIRST_NAME,
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="last_name-input"
                  name="last_name"
                  label="Nachname"
                  type="text"
                  value={user.lastName}
                  onChange={handleChange}
                  autoComplete="off"
                  fullWidth
                  required
                  inputProps={{
                    'data-testid': UsersSettingsTestIds.MODAL_FIELDS.LAST_NAME,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="position-input"
                  name="position"
                  label="Position"
                  type="text"
                  value={user.position}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    'data-testid': UsersSettingsTestIds.MODAL_FIELDS.POSITION,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} lg={4}>
            <InputLabel className="text-13px" id="label-company">
              Firma
            </InputLabel>
            <SelectCompany
              value={user.companyId}
              onChange={handleChangeCompany}
            />
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12} lg={6}>
              <h3 className="main-text mt-4">
                Rechte zum Signieren und Bearbeiten von Lieferungen in der App
              </h3>
              <SignatureRolesSettings
                signatureRoles={user.signatureRoles}
                onChange={handleChange}
                isError={isError}
                isLoading={isLoading}
              />
            </Grid>
            {user?.signatureRoles?.multipleSignatureRolesAreSelected?.() && (
              <Grid item xs={12} lg={6}>
                <h3 className="main-text mt-4">
                  Standard-Signaturberechtigung
                </h3>
                <Select
                  options={getDefaultSignatureRoleOptions(user.signatureRoles)}
                  value={user.signatureRoles?.defaultSignatureRole}
                  onChange={handleChangeDefaultSignatureRole}
                  size="small"
                  className="w-56"
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <h3 className="main-text mt-4">
              Bildschirmunterschrift von Lieferungen in der App
            </h3>
            <OnBehalfSignatureRolesSettings
              isLoading={isLoading}
              isError={isError}
              onChange={handleChange}
              signatureRoles={user.signatureRoles}
            />
          </Grid>

          {UserUtils.isVestigasAccount() && (
            <Grid item xs={12}>
              <h3 className="main-text mt-4">Feature Flags</h3>
              <FeatureFlagSettings
                featureFlags={user.featureFlags}
                onChange={handleChange}
                isLoading={isLoading}
                isError={isError}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <h3 className="main-text mb-2 mt-4">Push-Benachrichtigungen</h3>
            <PushNotificationSettings
              user={user}
              onChange={handleChange}
              onUpdateUser={handleUpdateUser}
              isError={isError}
              isLoading={isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <h3 className="main-text mb-2 mt-4">Ist Teil von...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <MultiItemsManagerOrganizationalUnits
                  isCreatingNewEntity={isCreatingUser}
                  filterByEntity={{
                    user: userId,
                  }}
                  onSaveSelection={handleChangeOrganisationalGroups}
                  onSelectedItemClick={handleOpenOrganisationalGroup}
                  onChange={onUpdatedOrganisationalGroupsChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {isCreatingUser ? null : (
            <Grid item xs={12}>
              <PermissionGrantSubjectTable
                title="Worauf ist dieser Benutzer berechtigt?"
                permissionGrantsOn={user.permissionGrantsOn}
                defaultSubjects={[user.id]}
                defaultSubjectType={PermissionGrant.SUBJECT_TYPE.USER.KEY}
                fixedPicker={PermissionGrant.TYPE.SUBJECT}
                refreshData={refetchUser}
                isError={isError}
                isLoading={isLoading}
              />
            </Grid>
          )}
        </Grid>
      </BasicForm>
    );
  },
  propsAreEqual,
);
