import { addressToString } from '~/models/masterdata/Address';

export const selectCompanies = (data) => ({
  companies: data.data ?? [],
  rows:
    data.data?.map(({ address, id, name }) => ({
      address: addressToString(address),
      id,
      name,
    })) ?? [],
  totalCount: data.totalCount ?? 0,
});
