import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';
import { type Permission } from '~/types/permission';

import { queryKeysVehicle } from './queryKeys';

export type Vehicle = {
  companyId: UUID;
  id: UUID;
  isActive: boolean;
  licensePlate: string;
  orgUnitPaths: UUID[][];
  orgUnits: UUID[];
  permissionsFrom: Array<{
    id: number;
    subjectId: UUID;
    subjectType:
      | 'accounting_reference'
      | 'company'
      | 'org_unit'
      | 'site'
      | 'user_group'
      | 'user'
      | 'vehicle';
    permissions: Permission[];
  }>;
};

/**
 * Fetches vehicle data from the API.
 * @param {UUID} vehicleId - The ID of the vehicle to fetch.
 * @returns {Promise<Vehicle|undefined>} The vehicle data if successful, undefined otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/Vehicle/operation/get_vehicle_by_id_vehicle__id__get
 */
export const fetchVehicle = async (
  vehicleId: UUID,
): Promise<Vehicle | undefined> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.VEHICLE.GET(vehicleId))
      .json<Vehicle>();

    return response;
  } catch (error) {
    console.error('Error fetching vehicle:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for a vehicle with a given vehicleId.
 * @param {UUID} vehicleId - The ID of the vehicle to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<Vehicle | undefined>} The result of the useQuery hook.
 */
export const useQueryVehicle = (
  vehicleId: UUID,
  options?: Record<string, unknown>,
): UseQueryResult<Vehicle | undefined> =>
  useQuery({
    enabled: Boolean(vehicleId),
    queryFn: async () => fetchVehicle(vehicleId),
    queryKey: queryKeysVehicle.get(vehicleId),
    ...options,
  });
