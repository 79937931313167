import Config from '~/Config';
import axios from '~/utils/api-client';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import UserService from './user.service';
import UserGroupService from './userGroup.service';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import SiteService from './site.service';
import CostCenterService from './costCenter.service';
import VehicleService from './vehicle.service';
import CompanyService from './company.service';
import OrganisationalGroupService from './organisationalGroup.service';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import ArrayUtils from '~/utils/arrayUtils';
import PromiseUtils from '~/utils/promiseUtils';

const API_URL = Config.apiUrl + '/access_control/granted_permissions';

class PermissionGrantService {
  async createNewPermissionGrant(
    subjectType,
    subjectId,
    entityType,
    entityId,
    body,
  ) {
    return axios
      .post(
        API_URL +
          '/' +
          subjectType +
          '/' +
          subjectId +
          '/' +
          entityType +
          '/' +
          entityId,
        body,
      )
      .then((response) => {
        return response.data?.id;
      });
  }

  async updatePermissionGrant(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  async deletePermissionGrant(id) {
    return axios.delete(API_URL + '/' + id);
  }

  // Assign the permission grants from the site to the added cost centers.
  async assignCoupledPermissionGrants(
    sitePermissionGrantsFrom,
    oldCostCenterIds,
    newCostCenterIds,
  ) {
    const promises = [];

    const [removedCostCenterIds, addedCostCenterIds] = ArrayUtils.getDifference(
      oldCostCenterIds,
      newCostCenterIds,
    );

    for (const costCenterId of addedCostCenterIds) {
      // Load the already granted permissions on the cost center from the backend to not assign duplicate permission grants.
      const [costCenter, error] = await promiseHandler(
        CostCenterService.getCostCenter(costCenterId, true),
      );

      if (error) {
        Log.error('Failed to load cost center: ' + costCenterId, error);
        continue;
      }

      for (const element of sitePermissionGrantsFrom) {
        const subjectType = element.subjectType;
        const subjectId = element.subjectId;
        const entityType = PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY;
        const entityId = costCenterId;
        const body = {
          permissions: element.permissions.getBackendPermissions(),
        };

        const matchingPermissionGrant = costCenter.permissionGrantsFrom.find(
          (permissionGrant) =>
            PermissionGrant.permissionGrantsAreMatching(permissionGrant, {
              entityId,
              permissions: element.permissions,
              subjectId,
            }),
        );

        // Don't assign permission grants to the cost center if it would be duplicates.
        if (matchingPermissionGrant) {
          continue;
        }

        const promise = this.createNewPermissionGrant(
          subjectType,
          subjectId,
          entityType,
          entityId,
          body,
        );

        promises.push(promise);
      }
    }

    return PromiseUtils.allResolved(promises);
  }

  // Delete the permission grants from the coupled cost centers.
  async deleteCoupledPermissionGrants(
    sitePermissionGrantsFrom,
    oldCostCenterIds,
    newCostCenterIds,
  ) {
    const promises = [];

    const [removedCostCenterIds, addedCostCenterIds] = ArrayUtils.getDifference(
      oldCostCenterIds,
      newCostCenterIds,
    );

    for (const costCenterId of removedCostCenterIds) {
      // Load the already granted permissions on the cost center from the backend to only delete matching permission grants.
      const [costCenter, error] = await promiseHandler(
        CostCenterService.getCostCenter(costCenterId, true),
      );

      if (error) {
        Log.error('Failed to load cost center: ' + costCenterId, error);
        continue;
      }

      for (const element of sitePermissionGrantsFrom) {
        const subjectId = element.subjectId;
        const entityId = costCenterId;

        const matchingPermissionGrant = costCenter.permissionGrantsFrom.find(
          (permissionGrant) =>
            PermissionGrant.permissionGrantsAreMatching(permissionGrant, {
              entityId,
              permissions: element.permissions,
              subjectId,
            }),
        );

        // Only delete permission grants from the cost center that have matches between site and cost center.
        if (!matchingPermissionGrant) {
          continue;
        }

        const promise = this.deletePermissionGrant(matchingPermissionGrant.id);

        promises.push(promise);
      }
    }

    return PromiseUtils.allResolved(promises);
  }

  async isDuplicatePermissionGrant(
    loadByType,
    subjectType,
    subjectId,
    entityType,
    entityId,
    permissions,
  ) {
    let permissionGrants = [];

    // 1. Load the subject/entity to get the already granted permissions.
    if (loadByType === PermissionGrant.TYPE.SUBJECT) {
      // Load the subjects via the get (instead of the getById) function
      // because the getById function might not have the latest state of the subject.
      let getSubjectCallback = null;

      switch (subjectType) {
        case PermissionGrant.SUBJECT_TYPE.USER.KEY: {
          getSubjectCallback = UserService.getUser;
          break;
        }

        case PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY: {
          getSubjectCallback = UserGroupService.getUserGroup;
          break;
        }

        default: {
          Log.error(
            null,
            new EnumValueNotFoundException(
              `Invalid subject type: ${subjectType}`,
            ),
          );
          return false;
        }
      }

      const [subject, error] = await promiseHandler(
        getSubjectCallback(subjectId),
      );

      if (error) {
        Log.error('Failed to load subject. id: ' + subjectId);
        Log.productAnalyticsEvent(
          'Failed to load subject',
          Log.FEATURE.PERMISSIONS,
          Log.TYPE.ERROR,
        );
        return false;
      }

      permissionGrants = subject.permissionGrantsOn;
    } else if (loadByType === PermissionGrant.TYPE.ENTITY) {
      // Load the entities via the get (instead of the getById) function
      // because the getById function might not have the latest state of the entity.
      let getEntityCallback = null;

      switch (entityType) {
        case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
          getEntityCallback = SiteService.getSite;
          break;
        }

        case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
          getEntityCallback = CostCenterService.getCostCenter;
          break;
        }

        case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
          getEntityCallback = VehicleService.getVehicle;
          break;
        }

        case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
          getEntityCallback = CompanyService.getCompany;
          break;
        }

        case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
          getEntityCallback = OrganisationalGroupService.getOrganisationalGroup;
          break;
        }

        default: {
          Log.error(
            null,
            new EnumValueNotFoundException(
              'Invalid entity type: ' + entityType,
            ),
          );
          return false;
        }
      }

      const [entity, error] = await promiseHandler(getEntityCallback(entityId));

      if (error) {
        Log.error('Failed to load entity. id: ' + entityId);
        Log.productAnalyticsEvent(
          'Failed to load entity',
          Log.FEATURE.PERMISSIONS,
          Log.TYPE.ERROR,
        );
        return false;
      }

      permissionGrants = entity.permissionGrantsFrom;
    } else {
      Log.error(
        null,
        new EnumValueNotFoundException(
          'Invalid permission grant type: ' + loadByType,
        ),
      );
      return false;
    }

    // 2. Check if the permission grant has already been granted by checking for a duplicate in the list of already granted permissions.
    for (const permissionGrant of permissionGrants) {
      if (
        permissionGrant.subjectId === subjectId &&
        permissionGrant.entityId === entityId &&
        JSON.stringify(permissionGrant.permissions) ===
          JSON.stringify(permissions)
      ) {
        Log.info('Duplicate permission detected');
        Log.productAnalyticsEvent(
          'Duplicate permission detected',
          Log.FEATURE.PERMISSIONS,
        );
        return true;
      }
    }

    return false;
  }
}

export default new PermissionGrantService();
