import ms from 'ms';
import { useEffect } from 'react';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import snakecaseKeys from 'snakecase-keys';

import { ENDPOINT } from '~/constants/endpoints';
import {
  PAGINATION_PAGE_SIZE_DEFAULT,
  type PaginationSortDirection,
} from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import Log from '~/utils/Log';
import { toSnakeCase } from '~/utils/string';

import { queryKeysUserGroup } from './queryKeys';

export type UserGroupListItem = {
  companyAccountId: UUID;
  createdById: UUID;
  createdOn: string;
  id: UUID;
  modifiedById: UUID;
  modifiedOn: string;
  name: string;
  syncKey: string | undefined;
};

export type UserGroupsResponse = {
  data: readonly UserGroupListItem[];
  hasNextPage: boolean;
  paginatedCount: number;
  totalCount: number;
};

export type UserGroupsQueryParams = {
  filterCompanyAccount?: string;
  filterNotParentOu?: UUID;
  filterNotParentUserGroup?: UUID;
  filterParentOu?: UUID;
  filterParentUserGroup?: UUID;
  limit: number;
  offset: number;
  orderBy?: string;
  returnTotalCount: boolean;
  searchString?: string;
  sort?: PaginationSortDirection;
};

const defaultQueryParams: UserGroupsQueryParams = {
  filterCompanyAccount: undefined,
  filterNotParentOu: undefined,
  filterNotParentUserGroup: undefined,
  filterParentOu: undefined,
  filterParentUserGroup: undefined,
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  orderBy: 'name',
  returnTotalCount: true,
  searchString: undefined,
  sort: 'ASC',
};

/**
 * Generates the query options for the user groups query.
 *
 * @param {Object} queryParams - The query parameters for fetching user groups.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getUserGroupsQueryOptions = ({
  queryParams,
  options,
}: {
  queryParams: Partial<UserGroupsQueryParams>;
  options?: Omit<
    UseQueryOptions<UserGroupsResponse, Error>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.sort = qp.sort?.toUpperCase() as PaginationSortDirection;

  return {
    queryFn: async () => fetchUserGroups(qp),
    queryKey: queryKeysUserGroup.getAll(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all user groups from the API.
 * @param {Object} queryParams - The query parameters for fetching user groups.
 * @returns {Promise<UserGroupsResponse|null>} The user group data if successful, null otherwise.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/get_user_groups_access_control_user_group_test_all_get
 */
export const fetchUserGroups = async (
  queryParams: Partial<UserGroupsQueryParams>,
): Promise<UserGroupsResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.orderBy = toSnakeCase(qp.orderBy);
  qp.sort = qp.sort?.toUpperCase() as PaginationSortDirection;

  for (const key of Object.keys(qp)) {
    if (qp[key] === undefined) {
      delete qp[key];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER_GROUP.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<UserGroupsResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching user groups', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all user groups with given query parameters.
 * Handles pagination and prefetches the next page of user groups for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching user groups.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryUserGroups = (
  queryParams: Partial<UserGroupsQueryParams>,
  options: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getUserGroupsQueryOptions({
        options,
        queryParams: {
          ...queryParams,
          offset:
            (queryParams.offset ?? 0) +
            (queryParams.limit ?? PAGINATION_PAGE_SIZE_DEFAULT),
        },
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery({
    ...getUserGroupsQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all user groups with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching user groups.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQueryUserGroupsInfinite = (
  queryParams: Partial<UserGroupsQueryParams>,
  options: InfiniteQueryOptionsType<UserGroupsResponse, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async ({ pageParam: pageParameter = 0 }) =>
      fetchUserGroups({
        ...qp,
        offset: pageParameter,
      }),
    queryKey: queryKeysUserGroup.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<UserGroupsResponse>(qp, infiniteQueryOptions);
};
