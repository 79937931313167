import { memo, useState, useCallback } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { useQueryVehicles } from '~/data/vehicle/useQueryVehicles';

import LocalStorageService from '~/services/localStorage.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns } from './constants';
import { selectVehicles } from './selectVehicles';

const VehiclesTableComponent = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'license_plate',
      sort: 'asc',
    },
  ]);

  const [filterActive, setFilterActive] = useState(true);

  const [searchString, setSearchString] = useState(undefined);

  const { data, isFetching, isPending, isPlaceholderData, refetch } =
    useQueryVehicles(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        orderBy: sortModel[0]?.field,
        searchString,
        sort: sortModel[0]?.sort,
        ...(filterActive !== '' && { filterActive }),
      },
      {
        select: selectVehicles,
      },
    );

  const handleSetActiveFilter = useCallback((filterActive) => {
    setFilterActive(filterActive);
  }, []);

  const handlePaginationModelChange = useCallback((newModel) => {
    setPaginationModel(newModel);
  }, []);

  const handleSortModelChange = useCallback((newModel) => {
    setSortModel(newModel);
  }, []);

  const handleSearchStringChange = useCallback((newSearchString) => {
    setSearchString(newSearchString);
  }, []);

  const filterFunction = useCallback((string) => string, []); // We filter server side -> prevent the DataGrid from filtering.

  return (
    <SettingsTable
      key="VehiclesTable"
      columns={columns}
      entity="vehicle"
      filterActive={filterActive}
      filterFunction={filterFunction}
      hasActiveStateFilter
      hasMultiPermissionGrantEditing
      isLoading={isPending || (isFetching && isPlaceholderData)}
      items={data?.vehicles ?? []}
      localStorageKey={LocalStorageService.VEHICLE_TABLE}
      multiPermissionGrantDefaultEntityType={
        PermissionGrant.ENTITY_TYPE.VEHICLE.KEY
      }
      multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
      onPaginationModelChange={handlePaginationModelChange}
      onSearchStringChange={handleSearchStringChange}
      onSortModelChange={handleSortModelChange}
      paginationModel={paginationModel}
      refreshData={refetch}
      rows={data?.rows ?? []}
      setActiveFilter={handleSetActiveFilter}
      sortBy="licensePlate"
      sortModel={sortModel}
      title="Fahrzeuge"
      totalRowCount={data?.totalCount ?? 0}
    />
  );
};

export const VehiclesTable = withErrorBoundary(memo(VehiclesTableComponent));
