import { v4 as uuidv4 } from 'uuid';

/**
 * Push the default tabs to the front of the list of filter groups if they are not already present.
 */
export const fixTabsOrder = (props, DEFAULT_TABS, getFilterGroupObject) => {
  const defaultFilterGroups = DEFAULT_TABS.map(({ NAME }) =>
    getFilterGroupObject(uuidv4(), NAME, [], true),
  );

  // Replace default filter groups with filter groups from state if they already exist there.
  let newFilterGroups = defaultFilterGroups.map((filterGroup) => {
    const modifiedDefaultFilterGroup = props.filterGroups.find(
      ({ name }) => name === filterGroup.name,
    );

    return modifiedDefaultFilterGroup ?? filterGroup;
  });

  // Add filter groups from state that are not based on the default filter groups.
  newFilterGroups = [
    ...newFilterGroups,
    ...props.filterGroups.filter(
      (filterGroup) =>
        !DEFAULT_TABS.some(({ NAME }) => NAME === filterGroup.name),
    ),
  ];

  props.setDelivery_filterGroups(newFilterGroups);
};
