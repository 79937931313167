import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { fetchVehicles, fetchVehicle, queryKeysVehicle } from '~/data/vehicle';

import UserUtils from '~/utils/userUtils';

import { type Option, SelectServerDriven } from './SelectServerDriven';
import { createLoadOptions, createGetItemData } from './utils';

const getOptionLabel = ({ licensePlate }) => licensePlate;

// Define a Vehicle type based on the expected structure; TODO: this should come as response type from the request.
type Vehicle = {
  id: string;
  licensePlate: string;
};

type P = {
  filterActive?: boolean;
  formatOptionLabel?: (option: Option<Vehicle>) => React.ReactNode;
  isDisabled?: boolean;
  isMultiSelect?: boolean;
  onChange: (value: Array<{ id: string }>) => void;
  placeholder?: string;
  value: string[];
} & ComponentStyling;

export const MultiSelectVehicles = ({
  className,
  filterActive = true,
  formatOptionLabel,
  isDisabled = false,
  isMultiSelect = true,
  onChange,
  placeholder = 'Fahrzeuge',
  style,
  value,
}: P) => {
  const queryClient = useQueryClient();

  /**
   * Loads the options for the multi-select dropdown.
   * @param {string} searchString - The search string entered by the user.
   * @param {unknown} loadedOptions - The already loaded options.
   * @param {{ page: number }} - The current page number.
   */
  const loadOptions = createLoadOptions<Vehicle>({
    createQueryKey: ({ page, ...queryParams }) =>
      queryKeysVehicle.getAll(queryParams),
    async fetchItems({ page, ...queryParams }) {
      const { data, hasNextPage } = await fetchVehicles(queryParams);

      return {
        data,
        hasNextPage,
      };
    },
    filterActive,
    getOptionLabel,
    queryClient,
  });

  /**
   * Gets vehicle data for a vehicle with a given vehicleId.
   * Attempts to get it from the query cache and fetches it from the API
   * if the vehicle data is not in the cache.
   * @param {string} vehicleId - The ID of the vehicle to get.
   */
  const getItemData = createGetItemData<Vehicle>({
    createQueryKey: (vehicleId) => queryKeysVehicle.get(vehicleId),
    fetchItem: fetchVehicle,
    queryClient,
    queryKeyBase: [queryKeysVehicle.base()],
  });

  // This is just a glue function b/c PermissionGrantMultiPicker currently expects to pick the ID from an object in its onChange function. Should be refactored.
  const handleChange = useCallback(
    (newValues: string[]) => {
      onChange(newValues.map((id) => ({ id })));
    },
    [onChange],
  );

  return (
    <SelectServerDriven<Vehicle>
      value={value}
      onChange={handleChange}
      className={className}
      formatOptionLabel={formatOptionLabel}
      getItemData={getItemData}
      getOptionLabel={getOptionLabel}
      isDisabled={isDisabled}
      isItemSelectionAllowed={UserUtils.isVehicleReadAllowedUser}
      isMultiSelect={isMultiSelect}
      loadOptions={loadOptions}
      placeholder={placeholder}
      style={style}
    />
  );
};
