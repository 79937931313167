import cloneDeep from 'lodash/cloneDeep';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { validate as uuidvalidate } from 'uuid';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';

import {
  useMutationCreateArticle,
  useMutationDeleteArticle,
  useMutationUpdateArticle,
  useQueryArticlesLegacy,
  type ArticleListItem,
} from '~/data/article';

import ToastService from '~/services/toast.service';

import Article from '~/models/articleMaster/Article';
import Company from '~/models/masterdata/Company';
import CostCenter from '~/models/masterdata/CostCenter';
import Site from '~/models/masterdata/Site';

import { LightTooltip } from '~/utils/componentUtils';
import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';

import BasicForm from '~/components/BasicForm';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';
import JsonInput from '~/components/JsonInput';
import Select from '~/components/baseComponents/inputs/select/Select';
import { type FormType } from '~/components/settings/SettingsTable/types';

import {
  MultiSelectCompanies,
  MultiSelectCostCenters,
  MultiSelectSites,
} from '~/ui/molecules/SelectServerDriven';
import { withErrorBoundary } from '~/ui/atoms';

const NEW_OPTION_TYPE = {
  COMPANY: 'company',
  COST_CENTER: 'cost_center',
  SITE: 'site',
};

type ArticleFormComponentProps = {
  open: boolean;
  type: FormType;
  closeForm: () => void;
  initialArticle: ArticleListItem;
};

const ArticleFormComponent = ({
  open,
  initialArticle,
  closeForm,
  type,
}: ArticleFormComponentProps) => {
  const [article, setArticle] = useState(new Article());

  const { data: articles, isLoading: isLoadingArticlesLegacy } =
    useQueryArticlesLegacy();

  const {
    mutateAsync: createArticle,
    isPending: isPendingCreateArticle,
    isSuccess: isSuccessCreateArticle,
  } = useMutationCreateArticle();
  const {
    mutateAsync: updateArticle,
    isPending: isPendingUpdateArticle,
    isSuccess: isSuccessUpdateArticle,
  } = useMutationUpdateArticle();
  const {
    mutateAsync: deleteArticle,
    isPending: isPendingDeleteArticle,
    isSuccess: isSuccessDeleteArticle,
  } = useMutationDeleteArticle();

  const userinfo = useSelector((state) => state.userinfo);

  const handleResetForm = async () => {
    setArticle(initialArticle ?? new Article());
  };

  const handleFormSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const body = {
      access: article.access,
      additional_data: article.additionalPartyData ?? {},
      article_id: article.articleId,
      description: article.description,
      ean: article.ean,
      equivalent_articles: article.equivalentArticles,
      filter_acc_refs: article.filterCostCenters,
      filter_companies: article.filterCompanies,
      filter_sites: article.filterSites,
      handling: article.handling ?? {},

      is_active: article.isActive,

      is_virtual: article.virtual,

      name: article.name,

      owner_companies: article.ownerCompanies,

      // Pass company account of user as default
      owner_company_accounts: article.ownerCompanyAccounts,

      product: article.product ?? {},
    };

    Log.info('Submit article form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.ARTICLE_MASTER);

    await (isCreateFormType
      ? createArticle(body)
      : updateArticle({ articleData: body, articleId: initialArticle.id }));
  };

  const handleFormAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.ARTICLE_MASTER);
    closeForm();
    handleResetForm();
  };

  const handleFormDelete = async (event) => {
    event.preventDefault();

    await deleteArticle(initialArticle.id);
  };

  const isCreateFormType = useMemo(() => {
    return type === 'create';
  }, [type]);

  const handleInputChange = (event) => {
    const newArticle = cloneDeep(article);

    switch (event.target.name) {
      case 'articleId': {
        newArticle.articleId = event.target.value;
        newArticle.product.seller_assigned_id = event.target.value;
        Log.info(
          'Change form value of article id',
          {
            from: article.articleId,
            to: newArticle.articleId,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'article_change_articleId',
          Log.productAnalyticsEvent,
          ['Change article id', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }

      case 'name': {
        newArticle.name = event.target.value;
        newArticle.product.name = event.target.value;
        Log.info(
          'Change form value of name',
          {
            from: article.name,
            to: newArticle.name,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'article_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }

      case 'description': {
        newArticle.description = event.target.value;
        newArticle.product.description = event.target.value;
        Log.info(
          'Change form value of description',
          {
            from: article.description,
            to: newArticle.description,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'article_change_description',
          Log.productAnalyticsEvent,
          ['Change description', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }

      case 'ean': {
        newArticle.ean = event.target.value;
        newArticle.product.ean = event.target.value;
        Log.info(
          'Change form value of ean',
          {
            from: article.ean,
            to: newArticle.ean,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'article_change_ean',
          Log.productAnalyticsEvent,
          ['Change ean', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }
    }

    setArticle(newArticle);
  };

  const handleChangeProduct = (product) => {
    const newArticle = cloneDeep(article);

    newArticle.product = product;
    newArticle.articleId = product.seller_assigned_id ?? '';
    newArticle.name = product.name ?? '';
    newArticle.description = product.description ?? '';
    newArticle.ean = product.ean ?? '';

    Log.info(
      'Change form value of product',
      {
        from: article.product,
        to: newArticle.product,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change product', Log.FEATURE.ARTICLE_MASTER);

    setArticle(newArticle);
  };

  const handleChangeHandling = (handling) => {
    const newArticle = cloneDeep(article);

    newArticle.handling = handling;

    Log.info(
      'Change form value of handling',
      {
        from: article.handling,
        to: newArticle.handling,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change handling', Log.FEATURE.ARTICLE_MASTER);

    setArticle(newArticle);
  };

  const handleChangeAdditionalPartyData = (additionalPartyData) => {
    const newArticle = cloneDeep(article);

    newArticle.additionalPartyData = additionalPartyData;

    Log.info(
      'Change form value of additional party data',
      {
        from: article.additionalPartyData,
        to: newArticle.additionalPartyData,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change additional party data',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setArticle(newArticle);
  };

  const handleCheckboxChange = (event) => {
    const newArticle = cloneDeep(article);

    switch (event.target.name) {
      case 'is_active': {
        newArticle.isActive = event.target.checked;
        Log.info(
          'Change form value of active',
          {
            from: article.isActive,
            to: newArticle.isActive,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change active checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }

      case 'is_virtual': {
        newArticle.virtual = event.target.checked;
        Log.info(
          'Change form value of virtual',
          {
            from: article.virtual,
            to: newArticle.virtual,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change virtual checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }

      case 'owner_company_accounts': {
        newArticle.ownerCompanyAccounts = event.target.checked
          ? [userinfo.userinfo.company?.companyAccount]
          : [];
        Log.info(
          'Change form value of owner company account',
          {
            from: article.ownerCompanyAccounts,
            to: newArticle.ownerCompanyAccounts,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change owner company account checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }
    }

    setArticle(newArticle);
  };

  const handleChangeEquivalentArticles = (equivalentArticles) => {
    const newArticle = cloneDeep(article);

    newArticle.equivalentArticles = equivalentArticles.map(
      (article) => article.id,
    );

    Log.info(
      'Change form value of equivalent articles',
      {
        from: article.equivalentArticles,
        to: newArticle.equivalentArticles,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change equivalent articles',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setArticle(newArticle);
  };

  const handleChangeOwnerCompanies = (ownerCompanies) => {
    const newArticle = cloneDeep(article);

    newArticle.ownerCompanies = ownerCompanies.map((company) => company.id);

    Log.info(
      'Change form value of owner companies',
      {
        from: article.ownerCompanies,
        to: newArticle.ownerCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change owner companies',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setArticle(newArticle);
  };

  const handleChangeAccess = (event) => {
    const newArticle = cloneDeep(article);

    newArticle.access = event.target.value;

    Log.info(
      'Change form value of access',
      {
        from: article.access,
        to: newArticle.access,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change access', Log.FEATURE.ARTICLE_MASTER);

    setArticle(newArticle);
  };

  const handleChangeFilterCompanies = (filterCompanies) => {
    const newArticle = cloneDeep(article);

    newArticle.filterCompanies = filterCompanies.map((company) => company.id);

    Log.info(
      'Change form value of filter companies',
      {
        from: article.filterCompanies,
        to: newArticle.filterCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter companies',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setArticle(newArticle);
  };

  const handleChangeFilterSites = (filterSites) => {
    const newArticle = cloneDeep(article);

    newArticle.filterSites = filterSites.map((site) => site.id);

    Log.info(
      'Change form value of filter sites',
      {
        from: article.filterSites,
        to: newArticle.filterSites,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter sites',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setArticle(newArticle);
  };

  const handleChangeFilterCostCenters = (filterCostCenters) => {
    const newArticle = cloneDeep(article);

    newArticle.filterCostCenters = filterCostCenters.map(
      (costCenter) => costCenter.id,
    );

    Log.info(
      'Change form value of filter cost centers',
      {
        from: article.filterCostCenters,
        to: newArticle.filterCostCenters,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter cost centers',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setArticle(newArticle);
  };

  const getUnsavedChanges = () => {
    if (isCreateFormType) {
      return [];
    }

    return Article.getDifferentValues(initialArticle, article);
  };

  const handleNewOptionCreate = (option, type) => {
    if (uuidvalidate(option.inputValue)) {
      switch (type) {
        case NEW_OPTION_TYPE.COMPANY: {
          return new Company({
            id: option.inputValue,
            name: option.inputValue,
          });
        }

        case NEW_OPTION_TYPE.SITE: {
          return new Site({ id: option.inputValue, name: option.inputValue });
        }

        case NEW_OPTION_TYPE.COST_CENTER: {
          return new CostCenter({
            id: option.inputValue,
            name: option.inputValue,
          });
        }

        default: {
          break;
        }
      }
    } else {
      ToastService.error(['Ungültige ID: Bitte gib eine gültige UUID ein.']);
      Log.productAnalyticsEvent(
        'Invalid UUID',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      return null;
    }
  };

  const getFilterHint = () => {
    if (
      article?.filterCompanies?.length > 0 ||
      article?.filterSites?.length > 0 ||
      article?.filterCostCenters?.length > 0
    ) {
      return (
        <div className="text-12px text-grey500 mb-10px">Exklusiv für...</div>
      );
    }

    if (article?.access === Article.ACCESS.PRIVATE.KEY) {
      return (
        <div className="text-12px text-grey500 mb-10px">
          Dieser Artikel hat aktuell keine Beschränkungen und wird jedem Nutzer
          der oben ausgewählten Firma / Firmenaccount angezeigt.
        </div>
      );
    }

    return (
      <div className="text-12px text-grey500 mb-10px">
        Dieser Artikel hat aktuell keine Beschränkungen und wird jedem Nutzer in
        VESTIGAS angezeigt.
      </div>
    );
  };

  useEffect(() => {
    if (
      isSuccessCreateArticle ||
      isSuccessUpdateArticle ||
      isSuccessDeleteArticle
    ) {
      handleResetForm();
      closeForm();
    }
  }, [isSuccessCreateArticle, isSuccessUpdateArticle, isSuccessDeleteArticle]);

  useEffect(() => {
    handleResetForm();
  }, []);

  return (
    <BasicForm
      open={open}
      formSuccess={handleFormSuccess}
      formAbort={handleFormAbort}
      formDelete={isCreateFormType ? null : handleFormDelete}
      title={
        'Artikel ' + (isCreateFormType ? 'erstellen' : initialArticle.name)
      }
      fullWidth
      submittingForm={isPendingCreateArticle || isPendingUpdateArticle}
      deletingForm={isPendingDeleteArticle}
      unsavedChanges={getUnsavedChanges()}
      id={initialArticle?.id}
    >
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text mt-0">Artikel</h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="articleId-input"
                name="articleId"
                label="Artikel-ID"
                type="text"
                required
                fullWidth
                value={article?.articleId}
                onChange={handleInputChange}
                autoFocus
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                id="name-input"
                name="name"
                label="Name"
                type="text"
                required
                fullWidth
                value={article?.name}
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Grid>
            {isCreateFormType ? null : (
              <Grid item xs={6} lg={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={article?.isActive}
                      onChange={handleCheckboxChange}
                      name="is_active"
                    />
                  }
                  label="Aktiv"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="description-input"
                name="description"
                label="Beschreibung"
                type="text"
                fullWidth
                value={article?.description}
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                id="ean-input"
                name="ean"
                label="EAN"
                type="number"
                fullWidth
                value={article?.ean}
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">JSON</h3>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <JsonInput
                title="product"
                value={article?.product}
                onChange={handleChangeProduct}
                minRows={15}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <JsonInput
                title="handling"
                value={article?.handling}
                onChange={handleChangeHandling}
                minRows={15}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <JsonInput
                title="additional_party_data"
                value={article?.additionalPartyData}
                onChange={handleChangeAdditionalPartyData}
                minRows={15}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">Generischer Artikel</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8} className="flex-s-c gap-20px">
              <div className="w-300px">
                <LightTooltip title="Generische Artikel (z.B. Palette) können statt spezifischen Artikeln (z.B. Europalette 100x100) ausgewählt werden und sind in der Rechnungsprüfung ein gleichwertiger Ersatz.">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={article?.virtual}
                        onChange={handleCheckboxChange}
                        name="is_virtual"
                      />
                    }
                    label="Generischer Artikel"
                  />
                </LightTooltip>
              </div>
              <GenericMultiPicker
                textfieldLabel="Welche Artikel sollen ersetzt werden?"
                pickedItemIds={
                  article?.virtual && article?.equivalentArticles
                    ? article?.equivalentArticles
                    : []
                }
                allItems={
                  articles
                    ? articles
                        .filter((article) => article.id !== initialArticle?.id)
                        .map((article) => {
                          return {
                            ...article,
                            nameComponent:
                              article.articleId + ' ' + article.name,
                          };
                        })
                    : []
                }
                onSaveSelection={handleChangeEquivalentArticles}
                loading={isLoadingArticlesLegacy}
                disabled={!article?.virtual}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">Zugeordnet zu</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8} className="flex-s-c gap-20px">
              <div className="w-300px">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={article?.ownerCompanyAccounts?.length > 0}
                      onChange={handleCheckboxChange}
                      name="owner_company_accounts"
                    />
                  }
                  label="Firmen-Account"
                />
              </div>
              <MultiSelectCompanies
                placeholder={
                  'Zu welchen Firmen soll der Artikel zugeordnet sein?'
                }
                value={article?.ownerCompanies ?? []}
                onChange={handleChangeOwnerCompanies}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">Berechtigung</h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <Select
                value={article?.access}
                fullWidth
                onChange={handleChangeAccess}
                size="small"
                options={Article.getAccessEnum()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">
            Dieser Artikel soll exklusiv sein?
          </h3>
          {getFilterHint()}
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <MultiSelectCompanies
                placeholder={'Firmen'}
                value={article?.filterCompanies ?? []}
                onChange={handleChangeFilterCompanies}
                // FIXME: allow creating new companies
                // onNewOptionCreate={(option) =>
                //   handleNewOptionCreate(option, NEW_OPTION_TYPE.COMPANY)
                // }
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <MultiSelectSites
                placeholder={'Standorte'}
                value={article?.filterSites ?? []}
                onChange={handleChangeFilterSites}
                // FIXME: allow creating new companies
                // onNewOptionCreate={(option) =>
                //   handleNewOptionCreate(option, NEW_OPTION_TYPE.SITE)
                // }
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <MultiSelectCostCenters
                placeholder={'Kostenstellen'}
                value={article?.filterCostCenters ?? []}
                onChange={handleChangeFilterCostCenters}
                // FIXME: allow creating new companies
                // onNewOptionCreate={(option) =>
                //   handleNewOptionCreate(
                //     option,
                //     NEW_OPTION_TYPE.COST_CENTER,
                //   )
                // }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BasicForm>
  );
};

export const ArticleForm = withErrorBoundary(memo(ArticleFormComponent));
