import { useMemo } from 'react';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';

import { withErrorBoundary } from '~/ui/atoms';

import DataExchangeCategory from './DataExchangeCategory';

export const DataExchangeTiles = withErrorBoundary(
  ({
    filteredDataExchanges,
    onChangeRequestInformationSelection,
    requestInformationSelection,
    requestInformationSelectionList,
  }) => {
    const sections = useMemo(
      () =>
        DataExchangeCompany.STATUS_ORDER.filter((status) => {
          // Don't display companies that have no status assigned.
          return !['CURRENT_ISSUES', 'NO_STATUS'].includes(status);
        }).map((statusKey) => {
          const status = DataExchangeCompany.STATUS[statusKey] || {};
          const { KEY, LABEL } = status;

          return {
            dataExchanges: filteredDataExchanges.filter(
              ({ overallStatus }) => overallStatus === KEY,
            ),
            key: KEY,
            label: LABEL,
          };
        }),
      [JSON.stringify(filteredDataExchanges)],
    );

    return (
      <div className="flex flex-col gap-4">
        {sections.map((category) => (
          <DataExchangeCategory
            category={category}
            key={category.key}
            requestInformationSelection={requestInformationSelection}
            requestInformationSelectionList={requestInformationSelectionList}
            onChangeRequestInformationSelection={
              onChangeRequestInformationSelection
            }
          />
        ))}
      </div>
    );
  },
  'Lieferantenübersicht konnte nicht geladen werden.',
);
