import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysVehicle } from './queryKeys';
import { updateVehicle } from './useMutationUpdateVehicle';

type VehicleData = Record<string, unknown>;

type VehicleCreateResponse = {
  code: number;
  message: string;
  id: UUID;
};

type MutationVehicleArgument = {
  vehicleData: VehicleData;
};

/**
 * Create a new vehicle.
 *
 * @param {Object} vehicleData - An object containing the vehicle data.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Vehicle/operation/create_vehicle_vehicle_post
 */
export const createVehicle = async (vehicleData: VehicleData) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.VEHICLE.CREATE(), {
        json: vehicleData,
      })
      .json<VehicleCreateResponse>();

    return response;
  } catch (error) {
    Log.error('Error creating vehicle', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for creating a new vehicle.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationCreateVehicle = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (vehicleData: MutationVehicleArgument) =>
      createVehicle(vehicleData),
    async onError(error) {
      Log.productAnalyticsEvent(
        'Failed to create vehicle',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeysVehicle.getAll({}),
      });
    },
    ...options,
  });
};
