import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import Log from '~/utils/Log';

import { queryKeysSignatureFields } from './queryKeys';

export type SignatureFieldsQueryParams = {
  companyId?: string[];
  siteId?: string[];
  accRefId?: string[];
};

export type SignatureFieldsListItem = {
  name: string;
  displayName: string;
  level: string;
  type: string;
  helpText: string;
  sorting: number;
  required: boolean;
  public: boolean;
  visibility: string;
  hasOptions: boolean;
  allowedUnits: string | undefined;
  defaultUnit: string | undefined;
  usedBy: string[];
  id: string;
};

type SignatureFieldsResponse = {
  data: readonly SignatureFieldsListItem[];
  count: number;
};

const defaultQueryParams: SignatureFieldsQueryParams = {
  accRefId: undefined,
  companyId: undefined,
  siteId: undefined,
};

/**
 * Generates the query options for the SignatureFields query so they can be shared between the useQuery hook and the prefetching.
 */
export const getSignatureFieldsQueryOptions = ({
  queryParams,
  options = {},
}: {
  queryParams: Partial<SignatureFieldsQueryParams>;
  options?: Omit<
    UseQueryOptions<SignatureFieldsResponse, Error>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryFn: async () => fetchSignatureFields(qp),
    queryKey: queryKeysSignatureFields.getAll(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all SignatureFields from the API.
 * @param queryParams - The query parameters for fetching SignatureFields.
 * @returns The category data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Signature-Field/operation/get_all_signature_fields_signature_field_all_get
 */
export const fetchSignatureFields = async (
  queryParams: Partial<SignatureFieldsQueryParams>,
): Promise<SignatureFieldsResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  for (const key of Object.keys(qp)) {
    if (qp[key as keyof SignatureFieldsQueryParams] === undefined) {
      delete qp[key as keyof SignatureFieldsQueryParams];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.SIGNATURE_FIELD.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<SignatureFieldsResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching SignatureFields', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all SignatureFields with given query parameters.
 * Handles pagination and prefetches the next page of SignatureFields for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching SignatureFields.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQuerySignatureFields = (
  queryParams: Partial<SignatureFieldsQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getSignatureFieldsQueryOptions({
        options,
        queryParams: {
          ...queryParams,
        },
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery<SignatureFieldsResponse, Error>({
    ...getSignatureFieldsQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all SignatureFields with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching SignatureFields.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQuerySignatureFieldsInfinite = (
  queryParams: Partial<SignatureFieldsQueryParams>,
  options: InfiniteQueryOptionsType<SignatureFieldsResponse, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async () =>
      fetchSignatureFields({
        ...qp,
      }),
    queryKey: queryKeysSignatureFields.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<SignatureFieldsResponse>(qp, infiniteQueryOptions);
};
