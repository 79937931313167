import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysCompany } from './queryKeys';

type CompanySettings = Record<string, unknown>;

type CompanyArguments = {
  companyDetails: CompanySettings;
  companyId: UUID;
};

/**
 * Upserts a given company.
 *
 * @param {string} companyId - The ID of the company to create or update.
 * @param {Object} companyDetails - An object containing the company details.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Company/operation/create_or_update_company_company__id__put
 */
export const upsertCompany = async (
  companyId: UUID,
  companyDetails: CompanySettings,
) => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.COMPANY.UPSERT(companyId), {
        json: companyDetails,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating company settings', error);
    throw error;
  }
};

/**
 * Custom hook for creating a new company updating a given company.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationUpsertCompany = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ companyId, companyDetails }: CompanyArguments) =>
      upsertCompany(companyId, companyDetails),
    async onError(_error) {
      Log.productAnalyticsEvent(
        'Failed to create or update company',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(_data, { companyId }) {
      queryClient.invalidateQueries({
        queryKey: queryKeysCompany.get(companyId),
      });
    },
  });
};
