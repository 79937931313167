import clsx from 'clsx';

type P = {
  direction?: 'top' | 'right' | 'bottom' | 'left';
} & ComponentStyling;

export const Triangle = ({ className, direction = 'right', style }: P) => (
  <div
    className={clsx(
      'size-0 border-b-[16px] border-l-[24px] border-t-[16px] border-b-transparent border-l-gray-200 border-t-transparent',
      {
        'rotate-180': direction === 'left',
      },
      className,
    )}
    style={style}
  />
);
