import React from 'react';
import { connect } from 'react-redux';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import FilterGroups from '../filterBar/FilterGroups';
import {
  setDashboard_filterGroups,
  setDashboard_filterRows,
  setDashboard_selectedFilterGroup,
} from '~/redux/filtersSlice';
import ArrayUtils from '~/utils/arrayUtils';
import DashboardService from '~/services/dashboard.service';
import Log from '~/utils/Log';
import cloneDeep from 'lodash/cloneDeep';
import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import BilledItem from '~/models/billingState/BilledItem';
import UserService from '~/services/user.service';
import ToastService from '~/services/toast.service';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';
import { LOADING_STATE } from '~/constants/LoadingState';
import DeliveriesService from '~/services/deliveries.service';
import FilterNew from '~/models/filters/FilterNew';
import FilterContext from '~/models/filters/FilterContext';
import FilterProps from '~/models/filters/FilterProps';
import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import FunctionUtils from '~/utils/functionUtils';

const mapStateToProps = (state) => ({
  customFields: state.customFields.customFields,
  dateRange: state.filters.dashboard_selectedDateRange,
  filterGroups: state.filters.dashboard_filterGroups,
  filterRows: state.filters.dashboard_filterRows,
  oldestFilteredDlnDate: state.filters.oldestFilteredDlnDate,
  selectedAcceptState: state.filters.dashboard_selectedAcceptState,
  selectedArticle: state.filters.dashboard_selectedArticle,
  selectedArticleNumber: state.filters.dashboard_selectedArticleNumber,
  selectedCostCenter: state.filters.dashboard_selectedCostCenter,
  selectedCostCenters: state.filters.selectedCostCenters,
  selectedCustomFields: state.filters.dashboard_selectedCustomFields,
  selectedFilterGroup: state.filters.dashboard_selectedFilterGroup,
  selectedFromSite: state.filters.dashboard_selectedFromSite,
  selectedPermittedCostCenters:
    state.filters.dashboard_selectedPermittedCostCenters,
  selectedPermittedToSites: state.filters.dashboard_selectedPermittedToSites,
  selectedProcessState: state.filters.dashboard_selectedProcessState,
  selectedRecipient: state.filters.dashboard_selectedRecipient,
  selectedSettledStatus: state.filters.dashboard_selectedSettledStatus,
  selectedSites: state.filters.selectedSites,
  selectedSupplier: state.filters.dashboard_selectedSupplier,
  selectedToSiteRecipient: state.filters.dashboard_selectedToSiteRecipient,
  selectedToSiteSupplier: state.filters.dashboard_selectedToSiteSupplier,
  selectedToSiteSupplierTradeContact:
    state.filters.dashboard_selectedToSiteSupplierTradeContact,
  userFeatureFlags: state.userinfo.userinfo.userFeatureFlags,
});
const mapDispatchToProps = () => ({
  setDashboard_filterGroups,
  setDashboard_filterRows,
  setDashboard_selectedFilterGroup,
});

class DashboardFilterGroups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cachedSuggestions: {},
      filterGroups: this.getFilterGroups(),
      selectableFilters: [],
    };
  }

  componentDidMount() {
    this.initSelectableFilters();
    this.initFilterGroups();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.customFields) !==
        JSON.stringify(prevProps.customFields) ||
      JSON.stringify(this.props.dataVersion) !==
        JSON.stringify(prevProps.dataVersion) ||
      JSON.stringify(this.props.selectedToSiteRecipient) !==
        JSON.stringify(prevProps.selectedToSiteRecipient) ||
      JSON.stringify(this.props.selectedToSiteSupplier) !==
        JSON.stringify(prevProps.selectedToSiteSupplier) ||
      JSON.stringify(this.props.selectedCostCenter) !==
        JSON.stringify(prevProps.selectedCostCenter) ||
      JSON.stringify(this.props.selectedArticleNumber) !==
        JSON.stringify(prevProps.selectedArticleNumber) ||
      JSON.stringify(this.props.selectedArticle) !==
        JSON.stringify(prevProps.selectedArticle) ||
      JSON.stringify(this.props.selectedSupplier) !==
        JSON.stringify(prevProps.selectedSupplier) ||
      JSON.stringify(this.props.selectedRecipient) !==
        JSON.stringify(prevProps.selectedRecipient) ||
      JSON.stringify(this.props.selectedProcessState) !==
        JSON.stringify(prevProps.selectedProcessState) ||
      JSON.stringify(this.props.selectedAcceptState) !==
        JSON.stringify(prevProps.selectedAcceptState) ||
      JSON.stringify(this.props.selectedSettledStatus) !==
        JSON.stringify(prevProps.selectedSettledStatus) ||
      JSON.stringify(this.props.selectedFromSite) !==
        JSON.stringify(prevProps.selectedFromSite) ||
      JSON.stringify(this.props.selectedPermittedToSites) !==
        JSON.stringify(prevProps.selectedPermittedToSites) ||
      JSON.stringify(this.props.selectedPermittedCostCenters) !==
        JSON.stringify(prevProps.selectedPermittedCostCenters) ||
      JSON.stringify(this.props.selectedCustomFields) !==
        JSON.stringify(prevProps.selectedCustomFields) ||
      JSON.stringify(this.props.dateRange) !==
        JSON.stringify(prevProps.dateRange) ||
      this.props.oldestFilteredDlnDate !== prevProps.oldestFilteredDlnDate
    ) {
      this.initSelectableFilters();
    }

    if (
      JSON.stringify(this.props.filterGroups) !==
        JSON.stringify(prevProps.filterGroups) ||
      JSON.stringify(this.props.dateRange) !==
        JSON.stringify(prevProps.dateRange) ||
      this.props.oldestFilteredDlnDate !== prevProps.oldestFilteredDlnDate
    ) {
      this.initFilterGroups();
    }

    // Reset cached suggestions if the selected sites or cost centers have changed.
    if (
      JSON.stringify(this.props.selectedSites) !==
        JSON.stringify(prevProps.selectedSites) ||
      JSON.stringify(this.props.selectedCostCenters) !==
        JSON.stringify(prevProps.selectedCostCenters)
    ) {
      this.setState({
        cachedSuggestions: {},
      });
    }
  }

  onClickFilterGroup = (id) => {
    if (id !== this.props.selectedFilterGroup) {
      this.props.setDashboard_selectedFilterGroup(id);
      return;
    }

    this.props.setDashboard_selectedFilterGroup(null);
    this.props.resetFilters();
  };

  getFilterGroupObject(id, name, filterRows = [], emptyFilterGroup) {
    const filterGroup = this.props.filterGroups.find(
      (filterGroup) => filterGroup.id === id,
    );

    let newFilterRows = [...filterRows];
    // If no filter rows have been provided and a corresponding filter is given, take the filter rows from the filter group.
    if (newFilterRows.length === 0 && filterGroup) {
      newFilterRows = [...filterGroup.filterRows];
    }

    return {
      filterRows: newFilterRows,

      // ? operator needed as this.props.filterGroups are not set when initializing Dashboard.
      filters: {
        selectedAcceptState: emptyFilterGroup
          ? []
          : this.props.selectedAcceptState,
        selectedArticle: emptyFilterGroup ? [] : this.props.selectedArticle,
        selectedArticleNumber: emptyFilterGroup
          ? []
          : this.props.selectedArticleNumber,
        selectedCostCenter: emptyFilterGroup
          ? []
          : this.props.selectedCostCenter,
        selectedCustomFields: emptyFilterGroup
          ? []
          : this.props.selectedCustomFields,
        selectedFromSite: emptyFilterGroup ? [] : this.props.selectedFromSite,
        selectedPermittedCostCenters: emptyFilterGroup
          ? []
          : this.props.selectedPermittedCostCenters,
        selectedPermittedToSites: emptyFilterGroup
          ? []
          : this.props.selectedPermittedToSites,
        selectedProcessState: emptyFilterGroup
          ? []
          : this.props.selectedProcessState,
        selectedRecipient: emptyFilterGroup ? [] : this.props.selectedRecipient,
        selectedSettledStatus: emptyFilterGroup
          ? []
          : this.props.selectedSettledStatus,
        selectedSupplier: emptyFilterGroup ? [] : this.props.selectedSupplier,
        selectedToSiteRecipient: emptyFilterGroup
          ? []
          : this.props.selectedToSiteRecipient,
        selectedToSiteSupplier: emptyFilterGroup
          ? []
          : this.props.selectedToSiteSupplier,
        selectedToSiteSupplierTradeContact: emptyFilterGroup
          ? []
          : this.props.selectedToSiteSupplierTradeContact,
      },
      id,
      name: name ?? filterGroup?.name,
    };
  }

  saveNewFilterGroup = async (id, name, filterRows, emptyFilters) => {
    const newFilterGroups = [
      ...this.props.filterGroups,
      this.getFilterGroupObject(id, name, filterRows, emptyFilters),
    ];

    this.props.setDashboard_filterGroups(newFilterGroups);
    this.props.setDashboard_selectedFilterGroup(id);

    const [response, error] = await promiseHandler(
      UserService.updateDashboardFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to save new filter group.', error);
      ToastService.error(['Filter konnte nicht hinzugefügt werden.']);
    }
  };
  updateFilterGroupName = async (id, name) => {
    const newFilterGroups = cloneDeep(this.props.filterGroups);

    for (const filterGroup of newFilterGroups) {
      if (filterGroup.id === id) {
        filterGroup.name = name;
      }
    }

    this.props.setDashboard_filterGroups(newFilterGroups);

    const [response, error] = await promiseHandler(
      UserService.updateDashboardFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to update filter group name.', error);
      ToastService.error(['Filter konnte nicht umbenannt werden.']);
    }
  };
  updateFilterGroup = async (filterRows) => {
    let newFilterGroups = cloneDeep(this.props.filterGroups);

    newFilterGroups = ArrayUtils.updateByKey(
      this.props.filterGroups,
      'id',
      this.props.selectedFilterGroup,
      this.getFilterGroupObject(
        this.props.selectedFilterGroup,
        null,
        filterRows,
      ),
    );

    this.props.setDashboard_filterGroups(newFilterGroups);

    const [response, error] = await promiseHandler(
      UserService.updateDashboardFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to update filter group.', error);
      ToastService.error(['Filter konnte nicht geändert werden.']);
    }
  };
  updateFilterRows = (filterRows) => {
    this.props.setDashboard_filterRows(filterRows);
  };
  deleteFilterGroup = async () => {
    let newFilterGroups = cloneDeep(this.props.filterGroups);

    newFilterGroups = ArrayUtils.removeByKey(
      this.props.filterGroups,
      'id',
      this.props.selectedFilterGroup,
    );

    this.props.setDashboard_filterGroups(newFilterGroups);
    this.props.setDashboard_selectedFilterGroup(null);
    this.props.resetFilters();

    const [response, error] = await promiseHandler(
      UserService.updateDashboardFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to delete filter group.', error);
      ToastService.error(['Filter konnte nicht gelöscht werden.']);
    }
  };

  initSelectableFilters() {
    this.setState({
      selectableFilters: this.getSelectableFilters(),
    });
  }

  // Based on the kind of filter, call the corresponding functionality in order to obtain all dropdown/autocomplete options
  getFilteredOptions(name, customField) {
    if (this.props.data.length === 0) {
      return [];
    }

    const data = [
      this.props.data, // data
      name === DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING
        ? null
        : this.props.selectedToSiteRecipient, // filterSiteRecipient
      name === DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING
        ? null
        : this.props.selectedToSiteSupplier, // filterSiteSupplier
      name === DeliveryNote.PROPERTY.COST_CENTER.STRING
        ? null
        : this.props.selectedCostCenter, // filterCostCenter
      name === DeliveryNote.PROPERTY.ARTICLE_NUMBER.STRING
        ? null
        : this.props.selectedArticleNumber, // filterArticleNumber
      name === DeliveryNote.PROPERTY.ARTICLE.STRING
        ? null
        : this.props.selectedArticle, // filterArticle
      name === DeliveryNote.PROPERTY.SUPPLIER.STRING
        ? null
        : this.props.selectedSupplier, // filterSupplier
      name === DeliveryNote.PROPERTY.RECIPIENT.STRING
        ? null
        : this.props.selectedRecipient, // filterRecipient
      name === DeliveryNote.PROPERTY.PROCESS_STATE.STRING
        ? null
        : this.props.selectedProcessState, // filterProcessState
      name === DeliveryNote.PROPERTY.ACCEPT_STATE.STRING
        ? null
        : this.props.selectedAcceptState, // filterAcceptState
      name === DeliveryNote.PROPERTY.SETTLED_STATUS.STRING
        ? null
        : this.props.selectedSettledStatus, // filterSettledStatus
      name === DeliveryNote.PROPERTY.FROM_SITE.STRING
        ? null
        : this.props.selectedFromSite, // filterFromSite
      name === DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.STRING
        ? null
        : this.props.selectedPermittedToSites, // filterPermittedToSites
      name === DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.STRING
        ? null
        : this.props.selectedPermittedCostCenters, // filterPermittedCostCenters
      name === customField?.name ? [] : this.props.selectedCustomFields, // filterCustomFields
      name === DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.STRING
        ? null
        : this.props.selectedToSiteSupplierTradeContact, // filterSiteSupplierTradeContact
      null, // unit
      null, // timeframe
      null, // selectedArticle
    ];

    if (customField) {
      // In order to make the custom field filters influence each other, this.props.data must be filtered also by the other custom fields.
      // Thus, pass this.props.selectedCustomFields to the filter logic but of course remove the own custom field filter to not restrict the own options by the own selection.
      const newSelectedCustomFields = cloneDeep(
        this.props.selectedCustomFields,
      );
      const selectedCustomFieldIndex =
        this.props.selectedCustomFields.findIndex(
          (selectedCustomField) => selectedCustomField.key === customField.key,
        );
      if (selectedCustomFieldIndex !== -1) {
        newSelectedCustomFields.splice(selectedCustomFieldIndex, 1);
      }

      data[13] = newSelectedCustomFields;
    }

    const filteredData = DashboardService.filterData(...data);

    switch (name) {
      case DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
          ArrayUtils.EMPTY_DROPDOWN_OPTION_RECIPIENT_SITE,
        );
      }

      case DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
        );
      }

      case DeliveryNote.PROPERTY.COST_CENTER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.COST_CENTER.KEY,
        );
      }

      case DeliveryNote.PROPERTY.ARTICLE_NUMBER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.ARTICLE_NUMBER.KEY,
        );
      }

      case DeliveryNote.PROPERTY.ARTICLE.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.ARTICLE.KEY,
        );
      }

      case DeliveryNote.PROPERTY.SUPPLIER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.SUPPLIER.KEY,
        );
      }

      case DeliveryNote.PROPERTY.RECIPIENT.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.RECIPIENT.KEY,
        );
      }

      case DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.KEY,
        );
      }

      case DeliveryNote.PROPERTY.PROCESS_STATE.STRING: {
        const autocompleteOptions = ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.PROCESS_STATE.KEY,
        );
        if (
          !autocompleteOptions.includes(
            DeliveryNote.PROCESS_STATE.DELIVERED.STRING,
          )
        ) {
          autocompleteOptions.push(DeliveryNote.PROCESS_STATE.DELIVERED.STRING);
        }

        return autocompleteOptions;
      }

      case DeliveryNote.PROPERTY.ACCEPT_STATE.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.ACCEPT_STATE.KEY,
          ArrayUtils.EMPTY_DROPDOWN_OPTION,
          AcceptStateCalculator.getAcceptStateOptionsBasedOnProcessStates(
            this.props.selectedProcessState,
          ),
        );
      }

      case DeliveryNote.PROPERTY.SETTLED_STATUS.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.SETTLED_STATUS.KEY,
          ArrayUtils.EMPTY_DROPDOWN_OPTION,
          BilledItem.getSettledStatusOptions(),
        );
      }

      case DeliveryNote.PROPERTY.FROM_SITE.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          filteredData,
          DeliveryNote.PROPERTY.FROM_SITE.KEY,
        );
      }

      case DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.STRING: {
        return ArrayUtils.getDistinctValuesFromArrayByKey(
          filteredData,
          DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.KEY,
        );
      }

      case DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.STRING: {
        return ArrayUtils.getDistinctValuesFromArrayByKey(
          filteredData,
          DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.KEY,
        );
      }
    }

    if (customField) {
      return ArrayUtils.getDistinctValuesByKey(filteredData, customField.key);
    }

    Log.error(
      'Failed to find autocomplete option for selectable filter. name: ' +
        name +
        ' | custom field key: ' +
        customField?.key,
    );
  }

  getAllOptions(name, customField) {
    if (this.props.data.length === 0) {
      return [];
    }

    switch (name) {
      case DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
          ArrayUtils.EMPTY_DROPDOWN_OPTION_RECIPIENT_SITE,
        );
      }

      case DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
        );
      }

      case DeliveryNote.PROPERTY.COST_CENTER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.COST_CENTER.KEY,
        );
      }

      case DeliveryNote.PROPERTY.ARTICLE_NUMBER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.ARTICLE_NUMBER.KEY,
        );
      }

      case DeliveryNote.PROPERTY.ARTICLE.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.ARTICLE.KEY,
        );
      }

      case DeliveryNote.PROPERTY.SUPPLIER.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.SUPPLIER.KEY,
        );
      }

      case DeliveryNote.PROPERTY.RECIPIENT.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.RECIPIENT.KEY,
        );
      }

      case DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.KEY,
        );
      }

      case DeliveryNote.PROPERTY.PROCESS_STATE.STRING: {
        const autocompleteOptions = ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.PROCESS_STATE.KEY,
        );
        if (
          !autocompleteOptions.includes(
            DeliveryNote.PROCESS_STATE.DELIVERED.STRING,
          )
        ) {
          autocompleteOptions.push(DeliveryNote.PROCESS_STATE.DELIVERED.STRING);
        }

        return autocompleteOptions;
      }

      case DeliveryNote.PROPERTY.ACCEPT_STATE.STRING: {
        return AcceptStateCalculator.getAcceptStateOptionsBasedOnProcessStates(
          this.props.selectedProcessState,
        );
      }

      case DeliveryNote.PROPERTY.SETTLED_STATUS.STRING: {
        return BilledItem.getSettledStatusOptions();
      }

      case DeliveryNote.PROPERTY.FROM_SITE.STRING: {
        return ArrayUtils.getDistinctValuesByKey(
          this.props.data,
          DeliveryNote.PROPERTY.FROM_SITE.KEY,
        );
      }

      case DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.STRING: {
        return ArrayUtils.getDistinctValuesFromArrayByKey(
          this.props.data,
          DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.KEY,
        );
      }

      case DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.STRING: {
        return ArrayUtils.getDistinctValuesFromArrayByKey(
          this.props.data,
          DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.KEY,
        );
      }
    }

    if (customField) {
      return ArrayUtils.getDistinctValuesByKey(
        this.props.data,
        customField.key,
      );
    }

    Log.error(
      'Failed to find autocomplete option for selectable filter. name: ' +
        name +
        ' | custom field key: ' +
        customField?.key,
    );
  }

  initFilterGroups() {
    const newFilterGroups = this.getFilterGroups();

    this.setState({
      filterGroups: newFilterGroups,
    });

    if (
      newFilterGroups.find(
        (filterGroup) => filterGroup.id === this.props.selectedFilterGroup,
      )?.disabled
    ) {
      this.props.setDashboard_selectedFilterGroup(null);
    }
  }

  getFilterGroups() {
    let filterGroups = cloneDeep(this.props.filterGroups);

    if (!this.isArchiveMode()) {
      return filterGroups;
    }

    filterGroups = filterGroups.map((filterGroup) => {
      const activeFilters = FilterNew.getActiveFiltersForFilterGroup(
        filterGroup,
        FilterContext.PAGE.DASHBOARD,
      );
      const nonApplicableBackendFilters =
        FilterNew.getNonApplicableFilters(activeFilters);

      return {
        ...filterGroup,
        disabled: nonApplicableBackendFilters.length > 0,
      };
    });

    return filterGroups;
  }

  isArchiveMode = () => {
    return DeliveriesService.isArchiveMode(this.props.dateRange);
  };

  selectableFilterDisabled(propertyKey) {
    return (
      this.isArchiveMode() &&
      !FilterNew.fieldIsApplicableBackendFilter(
        propertyKey,
        FilterContext.PAGE.DASHBOARD,
      )
    );
  }

  getSelectableFilters() {
    const selectableFilters = [
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.PROCESS_STATE.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE,
        name: DeliveryNote.PROPERTY.PROCESS_STATE.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.ACCEPT_STATE.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE,
        name: DeliveryNote.PROPERTY.ACCEPT_STATE.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.SETTLED_STATUS.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS,
        name: DeliveryNote.PROPERTY.SETTLED_STATUS.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT,
        name: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER,
        name: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.COST_CENTER.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_COST_CENTER,
        name: DeliveryNote.PROPERTY.COST_CENTER.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.ARTICLE_NUMBER.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER,
        name: DeliveryNote.PROPERTY.ARTICLE_NUMBER.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.ARTICLE.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_ARTICLE,
        name: DeliveryNote.PROPERTY.ARTICLE.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.SUPPLIER.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_SUPPLIER,
        name: DeliveryNote.PROPERTY.SUPPLIER.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.RECIPIENT.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_RECIPIENT,
        name: DeliveryNote.PROPERTY.RECIPIENT.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.FROM_SITE.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_FROM_SITE,
        name: DeliveryNote.PROPERTY.FROM_SITE.STRING,
      },
      {
        disabled: this.selectableFilterDisabled(
          DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.KEY,
        ),
        id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT,
        name: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.STRING,
      },
    ];

    if (
      this.props.userFeatureFlags.accessPermittedSites ||
      UserUtils.isPermittedSiteAllowedUser()
    ) {
      selectableFilters.push(
        {
          disabled: this.selectableFilterDisabled(
            DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.KEY,
          ),
          id: FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES,
          name: DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.STRING,
        },
        {
          disabled: this.selectableFilterDisabled(
            DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.KEY,
          ),
          id: FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS,
          name: DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.STRING,
        },
      );
    }

    for (const customField of this.props.customFields) {
      selectableFilters.push({
        customField,
        disabled: this.selectableFilterDisabled(customField.key),
        id: customField.key,
        name: customField.displayName,
      });
    }

    return selectableFilters.map((item) => {
      return {
        ...item,
        allOptions: this.getAllOptions(item.name, item.customField),
        filteredOptions: this.getFilteredOptions(item.name, item.customField),
      };
    });
  }

  handleOpenFilterRow = async (filterRowId) => {
    if (!this.isArchiveMode()) {
      return;
    }

    this.loadDlnFilterSuggestions(filterRowId, null, 0);
  };
  onChangeSearchValue = (filterRowId, searchValue) => {
    if (!this.isArchiveMode()) {
      return;
    }

    FunctionUtils.delayFunction(
      'filter_row_change_' + filterRowId,
      () => this.loadDlnFilterSuggestions(filterRowId, searchValue, 0, true),
      [filterRowId, searchValue, 0, true],
      1000,
    );
  };
  onScrollToBottom = async (filterRowId) => {
    if (!this.isArchiveMode()) {
      return;
    }

    const offset = this.state.cachedSuggestions[filterRowId].offset;
    const suggestions = this.state.cachedSuggestions[filterRowId].suggestions;
    const searchValue = this.state.cachedSuggestions[filterRowId].searchValue;

    // If the number of suggestions is equal to the offset, it means that the last request returned an empty list and
    // there are no more suggestions to load.
    if (suggestions.length === offset) {
      return;
    }

    this.loadDlnFilterSuggestions(filterRowId, searchValue, suggestions.length);
  };
  loadDlnFilterSuggestions = async (
    filterRowId,
    searchValue,
    offset,
    ignoreCache,
  ) => {
    const newSelectableFilters = cloneDeep(this.state.selectableFilters);
    const index = newSelectableFilters.findIndex(
      (filter) => filter.id === filterRowId,
    );

    // 1. Check if cached data existing
    if (
      !ignoreCache &&
      JSON.stringify(
        this.state.cachedSuggestions[filterRowId]?.appliedFilters,
      ) === JSON.stringify(this.getAppliedFilters(filterRowId)) &&
      this.state.cachedSuggestions[filterRowId]?.suggestions?.length > offset &&
      this.state.cachedSuggestions[filterRowId]?.searchValue === searchValue
    ) {
      const suggestions = this.state.cachedSuggestions[filterRowId].suggestions;

      newSelectableFilters[index].allOptions = suggestions;
      newSelectableFilters[index].filteredOptions = suggestions;

      this.setState({
        selectableFilters: newSelectableFilters,
      });
      return;
    }

    // 2. If not, set loading
    if (offset === 0) {
      newSelectableFilters[index].loading = LOADING_STATE.LOADING;
    } else {
      newSelectableFilters[index].paginationLoading = LOADING_STATE.LOADING;
    }

    this.setState({
      selectableFilters: newSelectableFilters,
    });

    // 3. Fetch data + filterId
    const [suggestions, error] = await promiseHandler(
      DeliveriesService.loadDlnFilterSuggestions({
        dateRange: this.props.dateRange,
        entity: FilterProps.getBackendFilterFromFilterGroupFilter(
          filterRowId,
          FilterContext.PAGE.DASHBOARD,
        ),
        filterGroups: {
          acceptStates: [],
          articles: this.props.selectedArticle,
          permittedCostCenters: this.props.selectedPermittedCostCenters,
          permittedToSites: this.props.selectedPermittedToSites,
          processStates: this.props.selectedProcessState,
          recipients: this.props.selectedRecipient,
          selectedCostCenters: this.props.selectedCostCenters,
          selectedSites: this.props.selectedSites,
          settledStatus: [],
          suppliers: this.props.selectedSupplier,
          toSiteRecipients: this.props.selectedToSiteRecipient,
          toSiteSuppliers: this.props.selectedToSiteSupplier,
        },
        offset,
        searchValue,
      }),
    );

    // 4. Set data
    if (suggestions) {
      if (offset === 0) {
        newSelectableFilters[index].loading = LOADING_STATE.SUCCEEDED;
        newSelectableFilters[index].allOptions = suggestions;
        newSelectableFilters[index].filteredOptions = suggestions;
      } else {
        newSelectableFilters[index].paginationLoading = LOADING_STATE.SUCCEEDED;
        newSelectableFilters[index].allOptions = [
          ...newSelectableFilters[index].allOptions,
          ...suggestions,
        ];
        newSelectableFilters[index].filteredOptions = [
          ...newSelectableFilters[index].filteredOptions,
          ...suggestions,
        ];
      }

      const newCachedSuggestions = cloneDeep(this.state.cachedSuggestions);
      newCachedSuggestions[filterRowId] ||= {};
      newCachedSuggestions[filterRowId].suggestions =
        newSelectableFilters[index].allOptions;
      newCachedSuggestions[filterRowId].offset = offset;
      newCachedSuggestions[filterRowId].searchValue = searchValue;
      newCachedSuggestions[filterRowId].appliedFilters =
        this.getAppliedFilters(filterRowId);
      this.setState({
        cachedSuggestions: newCachedSuggestions,
      });
    } else if (error) {
      Log.error('Failed to load suggestions for filter row.', error);
      newSelectableFilters[index].loading = LOADING_STATE.FAILED;
    }

    this.setState({
      selectableFilters: newSelectableFilters,
    });
  };

  getAppliedFilters(filter) {
    return {
      [FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE]:
        filter === FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE
          ? null
          : this.props.selectedProcessState,
      [FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE]:
        filter === FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE
          ? null
          : this.props.selectedAcceptState,
      [FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS]:
        filter === FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS
          ? null
          : this.props.selectedSettledStatus,
      [FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT]:
        filter === FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT
          ? null
          : this.props.selectedToSiteRecipient,
      [FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER]:
        filter === FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER
          ? null
          : this.props.selectedToSiteSupplier,
      [FilterGroupFilter.FILTER.SELECTED_COST_CENTER]:
        filter === FilterGroupFilter.FILTER.SELECTED_COST_CENTER
          ? null
          : this.props.selectedCostCenter,
      [FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER]:
        filter === FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER
          ? null
          : this.props.selectedArticleNumber,
      [FilterGroupFilter.FILTER.SELECTED_ARTICLE]:
        filter === FilterGroupFilter.FILTER.SELECTED_ARTICLE
          ? null
          : this.props.selectedArticle,
      [FilterGroupFilter.FILTER.SELECTED_SUPPLIER]:
        filter === FilterGroupFilter.FILTER.SELECTED_SUPPLIER
          ? null
          : this.props.selectedSupplier,
      [FilterGroupFilter.FILTER.SELECTED_RECIPIENT]:
        filter === FilterGroupFilter.FILTER.SELECTED_RECIPIENT
          ? null
          : this.props.selectedRecipient,
      [FilterGroupFilter.FILTER.SELECTED_FROM_SITE]:
        filter === FilterGroupFilter.FILTER.SELECTED_FROM_SITE
          ? null
          : this.props.selectedFromSite,
      [FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT]:
        filter ===
        FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT
          ? null
          : this.props.selectedToSiteSupplierTradeContact,
      [FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES]:
        filter === FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES
          ? null
          : this.props.selectedPermittedToSites,
      [FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS]:
        filter === FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS
          ? null
          : this.props.selectedPermittedCostCenters,
      [FilterGroupFilter.FILTER.SELECTED_CUSTOM_FIELDS]:
        filter === FilterGroupFilter.FILTER.SELECTED_CUSTOM_FIELDS
          ? null
          : this.props.selectedCustomFields,
    };
  }

  render() {
    return (
      <FilterGroups
        filterGroups={this.state.filterGroups}
        selectedFilterGroup={this.props.selectedFilterGroup}
        filterGroupOpen
        filterRows={this.props.filterRows}
        onClickFilterGroup={this.onClickFilterGroup}
        saveNewFilterGroup={this.saveNewFilterGroup}
        updateFilterGroupName={this.updateFilterGroupName}
        deleteFilterGroup={this.deleteFilterGroup}
        updateFilterGroup={this.updateFilterGroup}
        updateFilterRows={this.updateFilterRows}
        currentFilterGroupObject={this.getFilterGroupObject(
          this.props.selectedFilterGroup,
        )}
        originalFilterGroupObject={this.props.filterGroups.find(
          (filterGroup) => filterGroup.id === this.props.selectedFilterGroup,
        )}
        resetSelectedFilterGroup={() =>
          this.props.setDashboard_selectedFilterGroup(null)
        }
        selectableFilters={this.state.selectableFilters}
        onChangeValue={this.props.onChangeValue}
        onOpenFilterRow={this.handleOpenFilterRow}
        onChangeSearchValue={this.onChangeSearchValue}
        onScrollToBottom={this.onScrollToBottom}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(DashboardFilterGroups);
