import Permissions from './Permissions';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

export const SUBJECT_TYPES = {
  user: 'Benutzer',
  user_group: 'Benutzer-Gruppe',
};

export const ENTITY_TYPES = {
  accounting_reference: 'Kostenstelle',
  company: 'Firma',
  org_unit: 'Organisations-Gruppe',
  site: 'Standort',
  user: 'Benutzer',
  user_group: 'Benutzer-Gruppe',
  vehicle: 'Fahrzeug',
};

export const permissionGrantsAreMatching = (
  permissionGrantA,
  permissionGrantB,
) =>
  permissionGrantA.subjectId === permissionGrantB.subjectId &&
  permissionGrantA.entityId === permissionGrantB.entityId &&
  JSON.stringify(permissionGrantA.permissions) ===
    JSON.stringify(permissionGrantB.permissions);

export default class PermissionGrant {
  constructor(permissionGrant) {
    this.id = permissionGrant?.id?.toString() ?? null;
    this.permissions = new Permissions(permissionGrant?.permissions);
    this.subjectType =
      permissionGrant?.subject_type ?? permissionGrant?.subjectType ?? null;
    this.subjectId =
      permissionGrant?.subject_id ?? permissionGrant?.subjectId ?? null;
    this.subjectName =
      permissionGrant?.subject_name ?? permissionGrant?.subjectName ?? null;
    this.entityType =
      permissionGrant?.target_type ?? permissionGrant?.targetType ?? null;
    this.entityId =
      permissionGrant?.target_id ?? permissionGrant?.targetId ?? null;
    this.entityName =
      permissionGrant?.target_name ?? permissionGrant?.targetName ?? null;
  }

  getEntityTypeString() {
    const entityType = Object.keys(PermissionGrant.ENTITY_TYPE).find(
      (x) => PermissionGrant.ENTITY_TYPE[x].KEY === this.entityType,
    );

    if (!entityType) {
      throw new EnumValueNotFoundException(
        'Invalid entity type: ' + this.entityType,
      );
    }

    return PermissionGrant.ENTITY_TYPE[entityType].STRING;
  }

  getSubjectTypeString() {
    const subjectType = Object.keys(PermissionGrant.SUBJECT_TYPE).find(
      (x) => PermissionGrant.SUBJECT_TYPE[x].KEY === this.subjectType,
    );

    if (!subjectType) {
      throw new EnumValueNotFoundException(
        'Invalid subject type: ' + this.subjectType,
      );
    }

    return PermissionGrant.SUBJECT_TYPE[subjectType].STRING;
  }

  static permissionGrantsAreMatching(permissionGrantA, permissionGrantB) {
    return (
      permissionGrantA.subjectId === permissionGrantB.subjectId &&
      permissionGrantA.entityId === permissionGrantB.entityId &&
      JSON.stringify(permissionGrantA.permissions) ===
        JSON.stringify(permissionGrantB.permissions)
    );
  }

  canReadDeliveryNotes() {
    return this.permissions.canReadDeliveryNotes();
  }

  getDefaultRoleName() {
    return this.permissions.getDefaultRoleName();
  }

  static TYPE = {
    ENTITY: 'entity',
    SUBJECT: 'subject',
  };
  /**
   * @deprecated - use the named export of this instead
   */
  static SUBJECT_TYPE = {
    USER: {
      KEY: 'user',
      STRING: 'Benutzer',
    },
    USER_GROUP: {
      KEY: 'user_group',
      STRING: 'Benutzer-Gruppe',
    },
  };
  /**
   * @deprecated - use the named export of this instead
   */
  static ENTITY_TYPE = {
    COMPANY: {
      KEY: 'company',
      STRING: 'Firma',
    },
    COST_CENTER: {
      KEY: 'accounting_reference',
      STRING: 'Kostenstelle',
    },
    ORGANISATIONAL_GROUP: {
      KEY: 'org_unit',
      STRING: 'Organisations-Gruppe',
    },
    SITE: {
      KEY: 'site',
      STRING: 'Standort',
    },
    USER: {
      KEY: 'user',
      STRING: 'Benutzer',
    },
    USER_GROUP: {
      KEY: 'user_group',
      STRING: 'Benutzer-Gruppe',
    },
    VEHICLE: {
      KEY: 'vehicle',
      STRING: 'Fahrzeug',
    },
  };
}
