import {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';

import { PermissionGrantPicker } from './PermissionGrantPicker';

export const GrantPermissions = withErrorBoundary(
  forwardRef((props, ref) => {
    const {
      grantPermissions,
      pickedSubjects,
      setGrantPermissions,
      setPickedSubjects,
    } = props;

    const [grantPermissionsOnCostCenters, setGrantPermissionsOnCostCenters] =
      useState(true);
    const [permissionGrantPickerList, setPermissionGrantPickerList] = useState(
      [],
    );
    const permissionGrantReferences = useRef([]);

    useEffect(() => {
      addNewPermissionGrantPicker();
    }, []);

    useImperativeHandle(ref, () => ({
      async submit() {
        const promises = [];

        for (const ref of permissionGrantReferences.current) {
          const promise = ref.current.submit(grantPermissionsOnCostCenters);
          promises.push(promise);
        }

        return PromiseUtils.allResolved(promises);
      },
    }));

    const handleGrantPermissionsRadioChange = (event) => {
      Log.info(
        'Change form value of grant permissions radio button',
        {
          from: grantPermissions,
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change grant permissions radio button in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      setGrantPermissions(event.target.value === 'grant_permissions_yes');
    };

    const handleGrantPermissionsOnCostCentersRadioChange = (event) => {
      Log.info(
        'Change form value of grant permissions on cost centers radio button',
        {
          from: grantPermissionsOnCostCenters,
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change grant permissions on cost centers radio button in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      setGrantPermissionsOnCostCenters(
        event.target.value === 'cost_centers_yes',
      );
    };

    const addNewPermissionGrantPicker = () => {
      const newRef = createRef();
      permissionGrantReferences.current.push(newRef);

      const newPermissionGrantPickerList = [
        ...permissionGrantPickerList,
        {
          key: permissionGrantPickerList.length,
          pickedSubjects,
          ref: newRef,
          setPickedSubjects,
        },
      ];

      Log.info(
        'Add permission grant picker',
        { values: newPermissionGrantPickerList },
        Log.BREADCRUMB.USER_ACTION.KEY,
      );

      // Initially, a picker is added automatically. We don't want to incorrectly track this as user behavior.
      if (permissionGrantPickerList.length > 0) {
        Log.productAnalyticsEvent('Add picker', Log.FEATURE.PERMISSIONS);
      }

      setPermissionGrantPickerList(newPermissionGrantPickerList);
    };

    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12}>
          <h3 className="main-text mt-0">
            Sollen neue Berechtigungen vergeben werden?
          </h3>
          <RadioGroup
            onChange={handleGrantPermissionsRadioChange}
            row
            value={
              grantPermissions
                ? 'grant_permissions_yes'
                : 'grant_permissions_no'
            }
          >
            <FormControlLabel
              className="mr-12"
              control={<Radio />}
              label="Ja"
              value="grant_permissions_yes"
            />
            <FormControlLabel
              control={<Radio />}
              label="Nein"
              value="grant_permissions_no"
            />
          </RadioGroup>
        </Grid>
        {grantPermissions ? (
          <>
            <Grid item xs={12}>
              <h3 className="main-text mt-4">
                Sollen die Berechtigungen jeweils auf die verbundenen Standorte
                und Kostenstellen vergeben werden?
              </h3>
              <RadioGroup
                onChange={handleGrantPermissionsOnCostCentersRadioChange}
                row
                value={
                  grantPermissionsOnCostCenters
                    ? 'cost_centers_yes'
                    : 'cost_centers_no'
                }
              >
                <FormControlLabel
                  className="mr-12"
                  control={<Radio />}
                  label="Ja (empfohlen)"
                  value="cost_centers_yes"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Nein"
                  value="cost_centers_no"
                />
              </RadioGroup>
            </Grid>
            <Grid item lg={12} xs={12}>
              <div className="flex flex-col gap-4">
                {permissionGrantPickerList.map((permissionGrantPicker) => (
                  <PermissionGrantPicker
                    grantPermissionsOnCostCenters={
                      grantPermissionsOnCostCenters
                    }
                    key={permissionGrantPicker.key}
                    pickedSubjects={permissionGrantPicker.pickedSubjects}
                    ref={permissionGrantPicker.ref}
                    setPickedSubjects={permissionGrantPicker.setPickedSubjects}
                  />
                ))}
                <Button
                  className="mt-4"
                  onClick={addNewPermissionGrantPicker}
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  Weitere Berechtigung hinzufügen
                </Button>
              </div>
            </Grid>
          </>
        ) : null}
      </Grid>
    );
  }),
  'Daten konnten nicht geladen werden.',
);

GrantPermissions.displayName = 'GrantPermissions';
