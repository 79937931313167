import Vehicle from '~/models/masterdata/Vehicle';

export const selectVehicles = (data) => ({
  rows:
    data.data.map((vehicle) => ({
      companyName: vehicle.companyName,
      id: vehicle.id,
      isActive: vehicle.isActive ? Vehicle.IS_ACTIVE.YES : Vehicle.IS_ACTIVE.NO,
      licensePlate: vehicle.licensePlate,
    })) ?? [],
  totalCount: data.totalCount ?? 0,
  vehicles: data.data.map((vehicle) => new Vehicle(vehicle)) ?? [],
});
