import { Button, ButtonGroup } from '@mui/material';

import { SettingsTestIds } from '~/constants/test-ids';

type P = {
  onChange: (value: string) => void;
  value: string;
};

export const ActiveStateFilter = ({ onChange, value }: P) => {
  const buttons = [
    { label: 'Alle', testId: SettingsTestIds.STATUS.ALL, value: '' },
    { label: 'Aktiv', testId: SettingsTestIds.STATUS.ACTIVE, value: true },
    {
      label: 'Inaktiv',
      testId: SettingsTestIds.STATUS.NOT_ACTIVE,
      value: false,
    },
  ];

  return (
    <ButtonGroup
      color="primary"
      aria-label="outlined primary button group"
      orientation={window.innerWidth > 600 ? 'horizontal' : 'vertical'}
    >
      {buttons.map(({ value: buttonValue, label, testId }) => (
        <Button
          key={buttonValue}
          data-testid={testId}
          className={
            value === buttonValue ? 'primary-button' : 'secondary-button'
          }
          onMouseDown={() => {
            onChange(buttonValue);
          }}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
