import { useEffect, useReducer } from 'react';

import ValueGroup from '~/models/deliveries/ValueGroup';

import ToastService from '~/services/toast.service';

import ArrayUtils from '~/utils/arrayUtils';

import { useMutationBlacklistSiteIdentifier } from '~/data/site';

const initialState = {
  form: {
    ignoreAddressInfo: false,
    selectedSite: null,
    shouldAddIdentifierToSite: false,
  },
  isSubmittingForm: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_VALUES': {
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    }

    case 'SET_VALUE': {
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.name]: action.payload.value,
        },
      };
    }

    case 'TOGGLE_SUBMITTING_FORM': {
      return {
        ...state,
        isSubmittingForm: action.payload,
      };
    }

    case 'RESET_FORM': {
      return {
        ...state,
        form: {
          ...initialState.form,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export const useBlacklistSiteForm = ({
  closeForm,
  defaultSelectedSiteId,
  deliveryNote,
  refreshDeliveryNote,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    init();
  }, [defaultSelectedSiteId]);

  const init = async () => {
    dispatch({
      payload: {
        selectedSite: defaultSelectedSiteId ?? '',
      },
      type: 'SET_VALUES',
    });
  };

  const { mutateAsync: blacklistSiteForDeliveryNotes } =
    useMutationBlacklistSiteIdentifier();

  const closeFormHandler = () => {
    dispatch({ type: 'RESET_FORM' });
    closeForm();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch({
      payload: true,
      type: 'TOGGLE_SUBMITTING_FORM',
    });

    const { ignoreAddressInfo, selectedSite } = state.form;

    const siteId =
      selectedSite === ArrayUtils.EMPTY_DROPDOWN_OPTION ? null : selectedSite;

    const response = await blacklistSiteForDeliveryNotes({
      address: {
        buildingNumber: ValueGroup.getCurrentValue(
          deliveryNote.toSiteSupplier.address.buildingNumber,
        ),
        city: ValueGroup.getCurrentValue(
          deliveryNote.toSiteSupplier.address.city,
        ),
        country: ValueGroup.getCurrentValue(
          deliveryNote.toSiteSupplier.address.country,
        ),
        postCode: ValueGroup.getCurrentValue(
          deliveryNote.toSiteSupplier.address.postCode,
        ),
        streetName: ValueGroup.getCurrentValue(
          deliveryNote.toSiteSupplier.address.streetName,
        ),
      },
      ignoreAddressInfo,
      issuerId: ValueGroup.getCurrentValue(deliveryNote.issuer.id),
      shouldBlacklist: true,
      siteId,
      tradeAddressIssuerAssignedId: ValueGroup.getCurrentValue(
        deliveryNote.project,
      ),
      tradeAddressLineOne: ValueGroup.getCurrentValue(
        deliveryNote.toSiteSupplier.name,
      ),
    });

    if (response.status === 200) {
      ToastService.success([
        `Der Standort wurde von der Zuordnung zu dieser Lieferortbezeichnung ausgeschlossen.`,
      ]);
    } else {
      ToastService.error([
        `Der Standort konnte nicht von der Zuordnung zu dieser Lieferortbezeichnung ausgeschlossen werden.`,
      ]);
    }

    closeFormHandler();
    refreshDeliveryNote?.();
  };

  const handleCancel = () => {
    closeFormHandler();
  };

  const setValue = (name, value) => {
    dispatch({
      payload: {
        name,
        value,
      },
      type: 'SET_VALUE',
    });

    if (name === 'shouldAddIdentifierToSite' && !value) {
      dispatch({
        payload: {
          name: 'ignoreAddressInfo',
          value: false,
        },
        type: 'SET_VALUE',
      });
    }
  };

  return {
    handleCancel,
    handleSubmit,
    setValue,
    state,
  };
};
