import { InputLabel } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import { dateUtils } from '~/utils/dateUtils';

export const DeliveryNoteActionMetaData = withErrorBoundary(
  ({ className, title, users, withoutNameAndEmail }) => (
    <div className={className}>
      <InputLabel className="text-md">{title}</InputLabel>
      <ul className="mt-1">
        {[...new Set(users)].map(({ count, date, email, id, name }) => (
          <li key={id} className="flex gap-2">
            {!withoutNameAndEmail && (
              <span className="flex gap-2">
                {[name, email].filter(Boolean).map((string) => (
                  <span key={[name, email].join('')}>{string}</span>
                ))}
              </span>
            )}
            <span className="ml-auto">
              {[
                count && `${count} mal`,
                date &&
                  `zuletzt am ${dateUtils.getFormattedDate(
                    date,
                    dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
                  )}`,
              ]
                .filter(Boolean)
                .join(', ')}
            </span>
          </li>
        ))}
      </ul>
    </div>
  ),
);
