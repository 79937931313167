import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type Address } from '~/types/address';
import { type UUID } from '~/types/common';
import { type Permission } from '~/types/permission';

import Log from '~/utils/Log';

import { queryKeysCompany } from './queryKeys';

export type Company = {
  id: UUID;
  companyAccountId: UUID;
  name: string;
  address: Address;
  orgUnits: UUID[];
  orgUnitPaths: UUID[][];
  permissionsFrom: Array<{
    id: number;
    subjectId: UUID;
    subjectType:
      | 'accounting_reference'
      | 'company'
      | 'org_unit'
      | 'site'
      | 'user_group'
      | 'user'
      | 'vehicle';
    permissions: Permission[];
  }>;
  syncKey: string;
};

/**
 * Fetches company data from the API.
 * @param companyId - The ID of the company to fetch.
 * @returns The company data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Company/operation/query_company_by_id_company__company_id__get
 */
export const fetchCompany = async (companyId: UUID): Promise<Company> => {
  try {
    return await vestigasApi
      .get(ENDPOINT.COMPANY.GET(companyId))
      .json<Company>();
  } catch (error) {
    Log.error('Error fetching company', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for a company with a given companyId.
 * @param companyId - The ID of the company to fetch.
 * @param options - Additional options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
export const useQueryCompany = (
  companyId: UUID,
  options: Omit<UseQueryOptions<Company>, 'queryKey' | 'queryFn'> = {},
) => {
  return useQuery<Company>({
    queryFn: async () => fetchCompany(companyId),
    queryKey: queryKeysCompany.get(companyId),
    ...options,
  });
};
