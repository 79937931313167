import { Button } from '@mui/material';

import Config from '~/Config';

import { useQueryUserData } from '~/data/user';

import axios from '~/utils/api-client';

export const Demo = () => {
  const { data: currentUser } = useQueryUserData(true);

  const companyAccountInfo = currentUser?.companyAccountInfo ?? {};
  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};
  const userInfo = currentUser?.userSettings ?? {};

  const resetDemo = async () => {
    const API_URL = Config.apiUrl + '/admin';
    return axios.get(API_URL + '/reset_demo');
  };

  const resetDemo2 = async () => {
    const API_URL = Config.apiUrl + '/admin';
    return axios.get(API_URL + '/reset_demo2');
  };

  const createMusterDln = async (type) => {
    const API_URL = Config.apiUrl + '/admin';
    return axios.get(API_URL + '/create_demo_dln', {
      params: { dln_type: type },
    });
  };

  const createMustermannDln = async (type) => {
    const API_URL = Config.apiUrl + '/admin';
    return axios.get(API_URL + '/create_mustermann_dln', {
      params: { dln_type: type },
    });
  };

  const createDevDln = async (type) => {
    const API_URL = Config.apiUrl + '/admin';
    return axios.get(API_URL + '/dev/create_demo_dln', {
      params: { dln_type: type },
    });
  };

  const createCustomerDemoDln = async (type) => {
    const API_URL = Config.apiUrl + '/admin';
    return axios.get(API_URL + '/create_customer_demo_dln', {
      params: { dln_type: type },
    });
  };

  const throwTypeError = (event) => {
    return event.undefinedValue.undefinedValue;
  };

  const returnMatchingDemo = () => {
    const demoCompanyAccounts = [
      '9b004d37-5da7-4065-a9d6-76fed9716d76',
      'a85be708-d8b3-42d8-8e4b-1ffedb4f5f0e',
      '7c045c36-9e31-494d-849d-4eb873fca674',
    ];
    const musterDemoCompanyAccounts = [
      '2c6b34b9-09df-4ae3-901b-1a4ba7c1dcdb',
      '781dc85d-7cbb-4ade-a762-d2274793989d',
      '01a60cb0-75fc-4c2e-a465-2cc1701ec279',
    ];
    const devCompanyAccounts = [
      'b506cfa1-4e87-42ef-9e38-6a01d8194a1e',
      '6360729a-f2b6-4431-8b81-6764f1aa5fff',
      'ecdcb1f5-40bf-4ac3-9cc7-05171ecf40bf',
    ];
    const devCompanyAccountsDevDB = [
      'fc9a9155-0058-4ef2-a6fd-3fd9ffca90cb',
      'd695f86a-e20c-447d-b373-390e72248f8c',
      'a767719d-453c-404e-b9cd-ac849b9a113b',
    ];
    const devCompanyAccountsProductionDB = [
      'f0110e4a-505e-4d0b-ba8d-c1500df12eaf',
      '0141a50c-da1c-46b1-82fa-62454d560a8f',
      'a6ecab6d-c55c-457d-a096-4da3785b6fc0',
    ];
    const unauthorizedUsers = ['06e29deb-6fd2-4651-9d54-5f61d90a9f1d'];

    const mustermannCompanyAccounts = [
      '948ed3a9-b375-4623-bc00-63f6fc09999c',
      'e90991cc-97e4-4b6d-962b-d12ef1a9c335',
      'b04cb975-8287-4c36-af38-9c3e46d82d50',
    ];

    const userIsAuthorized =
      userInfo?.id && !unauthorizedUsers.includes(userInfo?.id);

    if (!userIsAuthorized) {
      return '';
    }

    if (demoCompanyAccounts.includes(companyAccountInfo?.id)) {
      return (
        <div>
          <Button className="primary-button" onClick={resetDemo}>
            {' '}
            Demo zurücksetzen{' '}
          </Button>
        </div>
      );
    }

    if (musterDemoCompanyAccounts.includes(companyAccountInfo?.id)) {
      return (
        <div>
          Lieferung erstellen:
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMusterDln('schüttgut_kies')}
            >
              {' '}
              Kies
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMusterDln('schüttgut_splitt')}
            >
              {' '}
              Splitt
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMusterDln('stückgut')}
            >
              {' '}
              Stückgut 1
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMusterDln('stückgut2')}
            >
              {' '}
              Stückgut 2
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMusterDln('beton')}
            >
              {' '}
              Beton
            </Button>
          </div>
          <br></br>
          <div>
            <Button className="primary-button" onClick={resetDemo2}>
              {' '}
              Demo zurücksetzen{' '}
            </Button>
          </div>
        </div>
      );
    }

    if (
      devCompanyAccounts.includes(companyAccountInfo?.id) ||
      devCompanyAccountsDevDB.includes(companyAccountInfo?.id) ||
      devCompanyAccountsProductionDB.includes(companyAccountInfo?.id)
    ) {
      return (
        <div>
          Lieferung erstellen:
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createDevDln('schüttgut_kies')}
            >
              {' '}
              Kies
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createDevDln('schüttgut_splitt')}
            >
              {' '}
              Splitt
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createDevDln('stückgut')}
            >
              {' '}
              Stückgut 1
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createDevDln('stückgut2')}
            >
              {' '}
              Stückgut 2
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createDevDln('beton')}
            >
              {' '}
              Beton
            </Button>
          </div>
          <br></br>
        </div>
      );
    }

    if (mustermannCompanyAccounts.includes(companyAccountInfo?.id)) {
      return (
        <div>
          Lieferung erstellen:
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMustermannDln('schüttgut_kies')}
            >
              {' '}
              Kies
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMustermannDln('schüttgut_splitt')}
            >
              {' '}
              Splitt
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMustermannDln('stückgut')}
            >
              {' '}
              Stückgut 1
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMustermannDln('stückgut2')}
            >
              {' '}
              Stückgut 2
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createMustermannDln('beton')}
            >
              {' '}
              Beton
            </Button>
          </div>
          <br></br>
        </div>
      );
    }

    if (featureFlags?.createDemoDlns) {
      return (
        <div>
          Lieferung erstellen:
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createCustomerDemoDln('schüttgut_kies')}
            >
              {' '}
              Kies
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createCustomerDemoDln('schüttgut_splitt')}
            >
              {' '}
              Splitt
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createCustomerDemoDln('stückgut')}
            >
              {' '}
              Stückgut 1
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createCustomerDemoDln('stückgut2')}
            >
              {' '}
              Stückgut 2
            </Button>
          </div>
          <br></br>
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={() => createCustomerDemoDln('beton')}
            >
              {' '}
              Beton
            </Button>
          </div>
          <br></br>
        </div>
      );
    }

    return '';
  };

  const returnDebugButtons = () => {
    const authorizedUsers = ['830acde4-ef26-4411-a9b1-150957cb3a83']; // polier-dev

    if (authorizedUsers.includes(userInfo?.id)) {
      return (
        <div>
          Fehler werfen
          <div>
            <Button
              size="small"
              className="primary-button"
              onClick={throwTypeError}
            >
              {' '}
              Throw TypeError
            </Button>
          </div>
        </div>
      );
    }

    return '';
  };

  return (
    <div>
      {returnMatchingDemo()}
      {returnDebugButtons()}
    </div>
  );
};
