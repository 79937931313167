import React from 'react';
import {
  gridExpandedSortedRowIdsSelector,
  gridFilterModelSelector,
} from '@mui/x-data-grid';
import DashboardService from '~/services/dashboard.service';
import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import UnitUtils from '~/utils/unitUtils';

import BasicTable from '../BasicTable';
import LocalStorageService from '~/services/localStorage.service';
import { precision } from '~/utils/number';

class DashboardDetailOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // list of all rows as they should be preselected.
      rows: [],
      rowSelectionModel: [], // data rows in table that have been transformed from this.props.data
      sortModel: [
        {
          field: 'value',
          sort: 'desc',
        },
      ],
    };

    this.visibleRows = []; // rows that are still visible even after filtering
    this.filterModel = [];
    this.filterModelUpdated = false;
  }

  componentDidMount() {
    this.updateStates(this.props.data, this.props.archiveAnalyticsData);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isArchiveMode && nextProps.data === this.props.data) {
      return;
    }

    if (
      this.props.isArchiveMode &&
      nextProps.archiveAnalyticsDataVersion ===
        this.props.archiveAnalyticsDataVersion
    ) {
      return;
    }

    this.updateStates(nextProps.data, nextProps.archiveAnalyticsData);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // only update the component if one of the essential states has changed
    return (
      JSON.stringify(nextState.rows) !== JSON.stringify(this.state.rows) ||
      JSON.stringify(nextState.rowSelectionModel) !==
        JSON.stringify(this.state.rowSelectionModel) ||
      JSON.stringify(nextState.sortModel) !==
        JSON.stringify(this.state.sortModel)
    );
  }

  updateStates(data, archiveAnalyticsData) {
    const newRows =
      (this.props.isArchiveMode
        ? DashboardService.getValuePerArticleFromAnalyticsData(
            archiveAnalyticsData,
            this.props.selectedUnit,
          )
        : DashboardService.getValuePerArticle(data, this.props.selectedUnit)) ??
      [];

    // automatically preselect/check all records
    const newSelectionModel = newRows.map((_, index) => index);

    this.setState({
      rows: newRows,
      rowSelectionModel: newSelectionModel,
    });
  }

  onRowSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected articles',
      { from: this.state.rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('(De)select article', Log.FEATURE.DASHBOARD);

    this.setState({
      rowSelectionModel: event,
    });

    this.publishSelectedArticlesToDashboard(event, this.visibleRows);
  };
  onSortModelChange = (event) => {
    this.setState({
      sortModel: event,
    });
  };
  getColumns = () => {
    let maxPrecision = 0;

    for (const row of this.state.rows) {
      const p = precision(row.value);

      if (p > maxPrecision && p <= 2) {
        maxPrecision = p;
      }
    }

    return [
      {
        field: 'article',
        flex: 6,
        headerName: 'Artikel',
        resizableText: true,
        sortable: true,
      },
      {
        field: 'value',
        flex: 2,
        headerName: 'Menge',
        renderCell: (params) =>
          UnitUtils.formatDeWithPrecision_safe(
            params.value,
            maxPrecision,
            maxPrecision,
          ),
        resizableText: true,
        sortable: true,
        type: 'number',
      },
      {
        field: 'unit',
        flex: 1,
        headerName: 'Einheit',
        resizableText: true,
        sortable: true,
      },
    ];
  };
  updateVisibleRows = (state) => {
    const newVisibleRows = gridExpandedSortedRowIdsSelector(state);
    const newFilterModel = gridFilterModelSelector(state).items;

    if (
      JSON.stringify(this.visibleRows) !== JSON.stringify(newVisibleRows) &&
      this.filterModelUpdated
    ) {
      this.publishSelectedArticlesToDashboard(
        this.state.rowSelectionModel,
        newVisibleRows,
      );
    }

    this.filterModelUpdated =
      JSON.stringify(this.filterModel) !== JSON.stringify(newFilterModel);
    this.visibleRows = newVisibleRows;
    this.filterModel = newFilterModel;
  };

  publishSelectedArticlesToDashboard(rowSelectionModel, visibleRows) {
    const selectedAndVisibleRows = ArrayUtils.getOverlappingValues(
      rowSelectionModel,
      visibleRows,
    );

    const selectedAndVisibleMaterial = this.state.rows.map((row) => {
      if (selectedAndVisibleRows.includes(row.id)) {
        return row.article;
      }
    });

    this.props.onSelectedRowChange(selectedAndVisibleMaterial);
  }

  getExcelData() {
    // parse unit to human-readable formats
    return this.state.rows.map((row) => {
      return {
        ...row,
        unit: UnitUtils.getAbbreviatedUnitString(row.unitKey),
      };
    });
  }

  getSelectionModelProps() {
    if (this.props.isArchiveMode) {
      return null;
    }

    return {
      checkboxSelection: true,
      onRowSelectionModelChange: this.onRowSelectionModelChange,
      rowSelectionModel: this.state.rowSelectionModel,
    };
  }

  render() {
    return (
      <div className="h-475px">
        <BasicTable
          rows={this.state.rows}
          columns={this.getColumns()}
          excelData={this.getExcelData()}
          excelColumns={this.getColumns()}
          {...this.getSelectionModelProps()}
          onSortModelChange={this.onSortModelChange}
          sortModel={this.state.sortModel}
          onStateChange={this.updateVisibleRows}
          localStorageKey={LocalStorageService.DASHBOARD}
        />
      </div>
    );
  }
}

export default DashboardDetailOverview;
