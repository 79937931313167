import React, { useState } from 'react';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

import {
  LightTooltip,
  MissingPermissionsTooltip,
} from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';
import Log from '~/utils/Log';

import BasicModal from './BasicModal';
import { Spinner } from './Spinner';

const StickyDialogFooter = ({ children }) => (
  <div
    className="sticky-dialog-footer z-10 flex items-center justify-between"
    style={{ width: `calc(100% - 48px)` }}
  >
    {children}
  </div>
);

const BasicForm = ({
  children,
  deleteButtonTitle = 'Löschen',
  deletingForm,
  disableSubmitButton,
  formAbort,
  formDelete,
  formSuccess,
  fullWidth,
  id,
  missingPermissionsToDelete,
  missingPermissionsToSubmit,
  noDeleteForm,
  opacity,
  open,
  submitButtonTitle = 'Speichern',
  submittingForm,
  testId,
  title,
  unsavedChanges = [],
}) => {
  const [deleteFormOpen, setDeleteFormOpen] = useState(false);
  const [abortFormOpen, setAbortFormOpen] = useState(false);

  const handleCloseModal = (event) => {
    event.stopPropagation();

    if (unsavedChanges?.length > 0) {
      setAbortFormOpen(true);
      return;
    }

    formAbort();
  };

  const handleCloseAbortForm = () => {
    setAbortFormOpen(false);
  };

  const handleAbortFormSuccess = (event) => {
    event.stopPropagation();
    event.preventDefault();

    Log.productAnalyticsEvent('Submit abort form', Log.FEATURE.FORM);

    formAbort();
    handleCloseAbortForm();
  };

  const handleAbortFormAbort = () => {
    Log.productAnalyticsEvent('Abort abort form', Log.FEATURE.FORM);
    handleCloseAbortForm();
  };

  const handleDeleteFormSuccess = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDeleteFormOpen(false);
    formDelete(event);
  };

  const handleDeleteFormAbort = () => {
    setDeleteFormOpen(false);
  };

  const renderTitle = () => (
    <div>
      <div className="flex items-center gap-2">
        {title}
        {id && UserUtils.isVestigasAccount() ? (
          <LightTooltip title="ID in die Zwischenablage kopieren">
            <IconButton
              size="medium"
              onClick={() => navigator.clipboard.writeText(id)}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </LightTooltip>
        ) : null}
      </div>
      {unsavedChanges?.length > 0 ? (
        <div className="text-warningBase absolute top-14 text-xs">
          * Ungespeicherte Änderungen: {unsavedChanges.join(', ')}
        </div>
      ) : null}
    </div>
  );

  const renderSubmitButton = () => {
    const button = (
      <Button
        variant="contained"
        color="primary"
        data-testid={`${testId}_submit_button`}
        className={
          missingPermissionsToSubmit
            ? 'disabled-primary-button'
            : 'primary-button'
        }
        type="submit"
        disabled={
          submittingForm || disableSubmitButton || missingPermissionsToSubmit
        }
      >
        {submittingForm ? (
          <Spinner white title={`${submitButtonTitle}...`} />
        ) : (
          submitButtonTitle
        )}
      </Button>
    );

    if (missingPermissionsToSubmit) {
      return <MissingPermissionsTooltip>{button}</MissingPermissionsTooltip>;
    }

    return button;
  };

  const renderDeleteButton = () => {
    const button = (
      <Button
        variant="outlined"
        color="secondary"
        onClick={(event) =>
          noDeleteForm
            ? handleDeleteFormSuccess(event)
            : setDeleteFormOpen(true)
        }
        disabled={deletingForm || missingPermissionsToDelete}
      >
        {deletingForm ? (
          <Spinner title={`${deleteButtonTitle}...`} />
        ) : (
          deleteButtonTitle
        )}
      </Button>
    );

    if (missingPermissionsToDelete) {
      return <MissingPermissionsTooltip>{button}</MissingPermissionsTooltip>;
    }

    return button;
  };

  const renderDeleteForm = () => (
    <BasicForm
      open={deleteFormOpen}
      formSuccess={handleDeleteFormSuccess}
      formAbort={handleDeleteFormAbort}
      title={`${title} löschen`}
      submitButtonTitle="Löschen"
    >
      Willst du mit dem Löschen fortfahren?
    </BasicForm>
  );

  return (
    <BasicModal
      title={renderTitle()}
      open={open}
      closeModal={handleCloseModal}
      fullWidth={fullWidth}
      opacity={opacity}
    >
      <form onSubmit={formSuccess} data-testid={testId}>
        <div className="min-w-80 pb-20">
          {' '}
          {/* Min width to prevent that footer buttons don't fit the whole row */}
          {children}
        </div>
        <StickyDialogFooter>
          {formDelete ? (
            <>
              {renderDeleteButton()}
              {renderDeleteForm()}
            </>
          ) : (
            <div />
          )}
          <div className="flex items-center gap-2">
            <Button variant="text" onClick={handleCloseModal}>
              Abbrechen
            </Button>
            <BasicForm
              open={abortFormOpen}
              formSuccess={handleAbortFormSuccess}
              formAbort={handleAbortFormAbort}
              title="Ungespeicherte Änderungen"
              submitButtonTitle="Fortfahren"
            >
              Ungespeicherte Änderungen gehen verloren. Willst du wirklich
              fortfahren?
            </BasicForm>
            {renderSubmitButton()}
          </div>
        </StickyDialogFooter>
      </form>
    </BasicModal>
  );
};

export default BasicForm;
