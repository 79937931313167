import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import Permissions from '~/models/masterdata/Permissions';

import { LightTooltipWide } from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

import { withErrorBoundary } from '~/ui/atoms';

import BasicForm from '~/components/BasicForm';

import { usePermissionForm } from './usePermissionForm';

export const PermissionForm = withErrorBoundary(
  ({
    disabled: isDisabled,
    formAbort: onCancel,
    formSuccess: onSubmit,
    opacity,
    open,
    permissions: propertyPermissions,
    submittingForm,
    title,
  }) => {
    const {
      handleCancel,
      handleChange,
      handleChangeRole,
      handleSubmit,
      permissions,
    } = usePermissionForm({
      onCancel,
      onSubmit,
      propertyPermissions,
    });

    const renderCheckbox = (name, label, checked, description) => (
      <Grid item xs={12}>
        <LightTooltipWide
          title={description}
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleChange} name={name} />
            }
            label={label}
            disabled={isDisabled}
          />
        </LightTooltipWide>
      </Grid>
    );

    return (
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleCancel}
        title={title ?? 'Berechtigungen vergeben'}
        submittingForm={submittingForm}
        opacity={opacity}
        missingPermissionsToSubmit={!UserUtils.isPermissionGrantAllowedUser()}
        fullWidth
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={6} lg={4}>
            <InputLabel className="text-13px">
              Standardrolle auswählen
            </InputLabel>
            <Select
              value={permissions.getDefaultRoleName() ?? 'None'} // A random String has to be chosen as fallback value because otherwise renderValue wouldn't catch the case if(!this.state.permissions.permissionGranted())
              key={0}
              onChange={handleChangeRole}
              className="w-300px"
              size="small"
              renderValue={(id) => {
                if (!permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Rolle wählen
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
            >
              {Permissions.getPickableRoles().map((role) => (
                <MenuItem value={role.id} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="pb-1 text-xl">
              Rechte für Lieferungen und Rechnungen
            </h3>
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Lieferungen</FormLabel>
                {renderCheckbox(
                  'deliveryNote_accessIncoming',
                  'Eingehende Lieferungen einsehen',
                  permissions.deliveryNote.accessIncoming,
                  Permissions.PERMISSION.ACCESS_INCOMING_DELIVERY_NOTE
                    .DESCRIPTION,
                )}
                {renderCheckbox(
                  'deliveryNote_accessOutgoing',
                  'Ausgehende Lieferungen einsehen',
                  permissions.deliveryNote.accessOutgoing,
                  Permissions.PERMISSION.ACCESS_OUTGOING_DELIVERY_NOTE
                    .DESCRIPTION,
                )}
                {renderCheckbox(
                  'deliveryNote_accessTransported',
                  'Transportierte Lieferungen einsehen',
                  permissions.deliveryNote.accessTransported,
                  Permissions.PERMISSION.ACCESS_TRANSPORTED_DELIVERY_NOTE
                    .DESCRIPTION,
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel component="legend">Rechnungen</FormLabel>
                {renderCheckbox(
                  'invoice_accessIncoming',
                  'Eingehende Rechnungen einsehen',
                  permissions.invoice.accessIncoming,
                  Permissions.PERMISSION.ACCESS_INCOMING_INVOICE.DESCRIPTION,
                )}
                {renderCheckbox(
                  'invoice_accessOutgoing',
                  'Ausgehende Rechnungen einsehen',
                  permissions.invoice.accessOutgoing,
                  Permissions.PERMISSION.ACCESS_OUTGOING_INVOICE.DESCRIPTION,
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="pb-1 text-xl">
              Administrationsrechte für Verantwortungsbereiche
            </h3>
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Benutzer</FormLabel>
                {renderCheckbox(
                  'user_read',
                  'Benutzer einsehen',
                  permissions.user.read,
                  Permissions.PERMISSION.READ_USER.DESCRIPTION,
                )}
                {renderCheckbox(
                  'user_write',
                  'Benutzer ändern',
                  permissions.user.write,
                  Permissions.PERMISSION.WRITE_USER.DESCRIPTION,
                )}
                {renderCheckbox(
                  'user_create',
                  'Benutzer anlegen',
                  permissions.user.create,
                  Permissions.PERMISSION.CREATE_USER.DESCRIPTION,
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Standorte</FormLabel>
                {renderCheckbox(
                  'site_read',
                  'Standorte einsehen',
                  permissions.site.read,
                  Permissions.PERMISSION.READ_SITE.DESCRIPTION,
                )}
                {renderCheckbox(
                  'site_write',
                  'Standorte ändern',
                  permissions.site.write,
                  Permissions.PERMISSION.WRITE_SITE.DESCRIPTION,
                )}
                {renderCheckbox(
                  'site_create',
                  'Standorte anlegen',
                  permissions.site.create,
                  Permissions.PERMISSION.CREATE_SITE.DESCRIPTION,
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" className="mt-30px">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Kostenstellen</FormLabel>
                {renderCheckbox(
                  'costCenter_read',
                  'Kostenstellen einsehen',
                  permissions.costCenter.read,
                  Permissions.PERMISSION.READ_COST_CENTER.DESCRIPTION,
                )}
                {renderCheckbox(
                  'costCenter_write',
                  'Kostenstellen ändern',
                  permissions.costCenter.write,
                  Permissions.PERMISSION.WRITE_COST_CENTER.DESCRIPTION,
                )}
                {renderCheckbox(
                  'costCenter_create',
                  'Kostenstellen anlegen',
                  permissions.costCenter.create,
                  Permissions.PERMISSION.CREATE_COST_CENTER.DESCRIPTION,
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Fahrzeuge</FormLabel>
                {renderCheckbox(
                  'vehicle_read',
                  'Fahrzeuge einsehen',
                  permissions.vehicle.read,
                  Permissions.PERMISSION.READ_VEHICLE.DESCRIPTION,
                )}
                {renderCheckbox(
                  'vehicle_write',
                  'Fahrzeuge ändern',
                  permissions.vehicle.write,
                  Permissions.PERMISSION.WRITE_VEHICLE.DESCRIPTION,
                )}
                {renderCheckbox(
                  'vehicle_create',
                  'Fahrzeuge anlegen',
                  permissions.vehicle.create,
                  Permissions.PERMISSION.CREATE_VEHICLE.DESCRIPTION,
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" className="mt-30px">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Firmen</FormLabel>
                {renderCheckbox(
                  'company_read',
                  'Firmen einsehen',
                  permissions.company.read,
                  Permissions.PERMISSION.READ_COMPANY.DESCRIPTION,
                )}
                {renderCheckbox(
                  'company_write',
                  'Firmen ändern',
                  permissions.company.write,
                  Permissions.PERMISSION.WRITE_COMPANY.DESCRIPTION,
                )}
                {renderCheckbox(
                  'company_create',
                  'Firmen anlegen',
                  permissions.company.create,
                  Permissions.PERMISSION.CREATE_COMPANY.DESCRIPTION,
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" className="mt-30px">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Organisations-Gruppen</FormLabel>
                {renderCheckbox(
                  'organisationalGroup_read',
                  'Organisations-Gruppen einsehen',
                  permissions.organisationalGroup.read,
                  Permissions.PERMISSION.READ_ORGANISATIONAL_GROUP.DESCRIPTION,
                )}
                {renderCheckbox(
                  'organisationalGroup_write',
                  'Organisations-Gruppen ändern',
                  permissions.organisationalGroup.write,
                  Permissions.PERMISSION.WRITE_ORGANISATIONAL_GROUP.DESCRIPTION,
                )}
                {renderCheckbox(
                  'organisationalGroup_create',
                  'Organisations-Gruppen anlegen',
                  permissions.organisationalGroup.create,
                  Permissions.PERMISSION.CREATE_ORGANISATIONAL_GROUP
                    .DESCRIPTION,
                )}
                {renderCheckbox(
                  'organisationalGroup_delete',
                  'Organisations-Gruppen löschen',
                  permissions.organisationalGroup.delete,
                  Permissions.PERMISSION.DELETE_ORGANISATIONAL_GROUP
                    .DESCRIPTION,
                )}
                {renderCheckbox(
                  'organisationalGroup_member',
                  'Zuordnungen verwalten',
                  permissions.organisationalGroup.member,
                  Permissions.PERMISSION.MEMBER_ORGANISATIONAL_GROUP
                    .DESCRIPTION,
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Benutzer-Gruppen</FormLabel>
                {renderCheckbox(
                  'userGroup_read',
                  'Benutzer-Gruppen einsehen',
                  permissions.userGroup.read,
                  Permissions.PERMISSION.READ_USER_GROUP.DESCRIPTION,
                )}
                {renderCheckbox(
                  'userGroup_write',
                  'Benutzer-Gruppen ändern',
                  permissions.userGroup.write,
                  Permissions.PERMISSION.WRITE_USER_GROUP.DESCRIPTION,
                )}
                {renderCheckbox(
                  'userGroup_create',
                  'Benutzer-Gruppen anlegen',
                  permissions.userGroup.create,
                  Permissions.PERMISSION.CREATE_USER_GROUP.DESCRIPTION,
                )}
                {renderCheckbox(
                  'userGroup_delete',
                  'Benutzer-Gruppen löschen',
                  permissions.userGroup.delete,
                  Permissions.PERMISSION.DELETE_USER_GROUP.DESCRIPTION,
                )}
                {renderCheckbox(
                  'userGroup_member',
                  'Zuordnungen verwalten',
                  permissions.userGroup.member,
                  Permissions.PERMISSION.MEMBER_USER_GROUP.DESCRIPTION,
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" className="mt-30px">
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Berechtigungen</FormLabel>
                {renderCheckbox(
                  'permission_grant',
                  'Berechtigungen vergeben',
                  permissions.permission.grant,
                  Permissions.PERMISSION.GRANT_PERMISSION.DESCRIPTION,
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    );
  },
  null,
);

PermissionForm.displayName = 'PermissionForm';
