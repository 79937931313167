import snakecaseKeys from 'snakecase-keys';

import UserService from '~/services/user.service';
import ToastService from '~/services/toast.service';
import MasterDataService from '~/services/masterData.service';

import Log from '~/utils/Log';
import ObjectUtils from '~/utils/objectUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';

export const handleSubmit = async ({
  enableSignature,
  grantPermissions,
  permissionsRef,
  refetchData,
  resetWizard,
  setIsSubmitting,
  setIsOpen,
  user,
}) => {
  setIsSubmitting(true);

  const body = {
    companyId: user.companyId,
    email: user.email,
    firstName: user.firstName,
    isActive: true,
    lastName: user.lastName,
    orgUnits: user.organisationalGroups,
    password: user.password,
    permittedSignatureTypes: enableSignature
      ? ObjectUtils.entries(user.signatureRoles)
          .filter(({ value }) => value)
          .map(({ key }) => key)
      : [],
    position: user.position,
    username: user.email,
    userType: user.signatureRoles?.getUserType() ?? '',
  };

  if (
    MasterDataService.propertiesAreMissing(
      body,
      ['companyId'],
      Log.FEATURE.WIZARD,
    )
  ) {
    setIsSubmitting(false);
    return false;
  }

  Log.info('Submit create user wizard', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
  Log.productAnalyticsEvent('Submit create user wizard', Log.FEATURE.WIZARD);

  // Convert camelCase keys to snake_case for API
  const snakeCaseBody = snakecaseKeys(body, { deep: true });

  const [userId, error] = await promiseHandler(
    UserService.createUser(snakeCaseBody),
  );

  if (error) {
    Log.error('Failed to create user.', error);

    if (error.response.status === 409) {
      ToastService.httpError(
        [ToastService.MESSAGE.USER_CREATION_FAILED_DUPLICATE],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to create due to duplicate user',
        Log.FEATURE.WIZARD,
        Log.TYPE.ERROR,
      );
    } else {
      ToastService.httpError(
        [ToastService.MESSAGE.USER_CREATION_FAILED],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to create user',
        Log.FEATURE.WIZARD,
        Log.TYPE.ERROR,
      );
    }

    setIsSubmitting(false);
    return false;
  }

  if (grantPermissions && permissionsRef.current) {
    Log.info('Save granted permissions on new user', permissionsRef);
    await permissionsRef.current.submit(userId);
  }

  ToastService.success(['Benutzer wurde angelegt.']);

  setIsOpen(false);
  setIsSubmitting(false);

  resetWizard();
  refetchData();

  return true;
};
