import FileSaver from 'file-saver';
import { useState, useEffect, useRef } from 'react';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import PdfsettingsService from '~/services/pdfsettings.service';
import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import { Spinner } from '~/components/Spinner';

const MAX_REQUEST_COUNT = 10;

export const PdfTestTemplateTile = ({ files, id, onClick, onDelete }) => {
  const testPdfRequestCount = useRef(0);
  const [downloadFile, setDownloadFile] = useState(null);
  const [downloadingPdf, setDownloadingPdf] = useState(true);

  const handleDownload = () => {
    const extension = downloadFile?.type.split('/')[1];

    const file = new Blob([downloadFile]);
    FileSaver.saveAs(file, `VESTIGAS-test-template-download.${extension}`);
  };

  const fetchPdfTemplate = async () => {
    if (!id) {
      return;
    }

    const [response, error] = await promiseHandler(
      PdfsettingsService.getTestPdfTemplate(id),
    );

    if (response.status === 200) {
      setDownloadFile(response.data);
      setDownloadingPdf(false);

      const extension = response?.data?.type.split('/')[1];

      const file = new Blob([response.data]);
      FileSaver.saveAs(file, `VESTIGAS-test-template-download.${extension}`);

      return;
    }

    if (error) {
      ToastService.httpError(
        [ToastService.MESSAGE.PDF_TEMPLATE_CREATION_FAILED],
        error.response,
      );
      Log.error('Failed to generate test pdf template', error);
      Log.productAnalyticsEvent(
        'Failed to generate test pdf template',
        Log.FEATURE.PDF_TEMPLATE,
      );
      return;
    }

    if (testPdfRequestCount.current >= MAX_REQUEST_COUNT) {
      ToastService.httpError(
        [ToastService.MESSAGE.PDF_TEMPLATE_CREATION_FAILED],
        error.response,
      );
      Log.error('Failed to generate test pdf template - exceed timeout', error);
      Log.productAnalyticsEvent(
        'Failed to generate test pdf template - exceed timeout',
        Log.FEATURE.PDF_TEMPLATE,
      );
      return;
    }

    setTimeout(() => {
      fetchPdfTemplate();
    }, 1000);
  };

  useEffect(() => {
    fetchPdfTemplate();
  }, [id]);

  return (
    <div
      className="rounded-5px box-shadow-blue p-20px max-w-600px w-full bg-white"
      onClick={onClick}
    >
      <div>JSON Name:</div>
      <div className="bold">{files?.json?.name}</div>
      <div className="mt-20px">Template Name:</div>
      <div className="bold">{files?.template?.name}</div>

      <div className="flex-sb-c mt-20px">
        <Button variant="contained" color="secondary" onClick={onDelete}>
          Löschen
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={onClick}
          className="mr-5px ml-auto"
        >
          Bearbeiten
        </Button>
        <Button
          variant="outlined"
          color="primary"
          disabled={downloadingPdf}
          startIcon={downloadingPdf ? null : <DownloadIcon />}
          onClick={(event) => {
            event.stopPropagation();
            handleDownload();
          }}
        >
          {downloadingPdf ? <Spinner title="Download..." /> : 'Download'}
        </Button>
      </div>
    </div>
  );
};
