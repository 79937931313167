import { Button } from '@mui/material';

import {
  LightTooltip,
  MissingPermissionsTooltip,
} from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

import { Spinner } from '~/components/Spinner';

export const DeletePermissionButton = ({ onClick, disabled, deleting }) => {
  if (!UserUtils.isPermissionGrantAllowedUser()) {
    return (
      <MissingPermissionsTooltip>
        <Button
          variant="outlined"
          color="secondary"
          className="mt-2"
          onClick={onClick}
          disabled
        >
          {deleting ? (
            <Spinner title="Berechtigungen löschen..." />
          ) : (
            'Berechtigungen löschen'
          )}
        </Button>
      </MissingPermissionsTooltip>
    );
  }

  return (
    <LightTooltip title="Berechtigungen von ausgewählten Benutzern oder Benutzer-Gruppen löschen.">
      <Button
        variant="outlined"
        color="secondary"
        className="mt-2"
        onClick={onClick}
        disabled={disabled}
      >
        {deleting ? (
          <Spinner title="Berechtigungen löschen..." />
        ) : (
          'Berechtigungen löschen'
        )}
      </Button>
    </LightTooltip>
  );
};
