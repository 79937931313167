import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { InputLabel, MenuItem, Select, Button } from '@mui/material';

import { useQueriesSites } from '~/data/site';
import { useQueryUser } from '~/data/user';

import PermissionGrant, {
  ENTITY_TYPES,
  SUBJECT_TYPES,
} from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';
import Site from '~/models/masterdata/Site';
import User from '~/models/masterdata/User';

import PermissionGrantService from '~/services/permissionGrant.service';
import ToastService from '~/services/toast.service';

import ArrayUtils from '~/utils/arrayUtils';
import { LightTooltip } from '~/utils/componentUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import PromiseUtils from '~/utils/promiseUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { PermissionForm } from '~/components/settings/masterData/permissionGrant/PermissionForm';
import { PermissionGrantMultiPicker } from '~/components/settings/masterData/permissionGrant/PermissionGrantMultiPicker';

const combineSitesData = (results) => ({
  data: results.map(({ data }) => new Site(data)),
  isError: results.some(({ isError }) => isError),
  isLoading: results.some(({ isLoading }) => isLoading),
  isSuccess: results.every(({ isSuccess }) => isSuccess),
});

export const PermissionGrantPicker = withErrorBoundary(
  forwardRef((props, ref) => {
    const [pickedSubjectType, setPickedSubjectType] = useState(
      PermissionGrant.SUBJECT_TYPE.USER.KEY,
    );
    const [pickedSubjects, setPickedSubjects] = useState([]);
    const [pickedEntityType, setPickedEntityType] = useState(
      PermissionGrant.ENTITY_TYPE.SITE.KEY,
    );
    const [pickedEntities, setPickedEntities] = useState([]);
    const [permissionFormOpen, setPermissionFormOpen] = useState(false);
    const [permissions, setPermissions] = useState(new Permissions());

    const selectedSiteIds =
      pickedEntityType === PermissionGrant.ENTITY_TYPE.SITE.KEY
        ? pickedEntities
        : [];
    const sitesData = useQueriesSites(selectedSiteIds, {}, combineSitesData);

    const firstUserId = pickedSubjects[0];
    const { data: firstSelectedUser, isSuccess: isSuccessFirstSelectedUser } =
      useQueryUser(firstUserId, {
        enabled: Boolean(firstUserId),
        select: (user) => new User(user),
      });

    useEffect(() => {
      initRole();
    }, [isSuccessFirstSelectedUser]);

    // Automatically select a role as default based on the roles that are already assigned to the users whose permissions should be updated.
    const initRole = async () => {
      if (permissions.permissionGranted()) {
        // Don't overwrite if the user has already specified a set of permissions.
        return;
      }

      if (!firstSelectedUser) {
        return;
      }

      const roles = firstSelectedUser.permissionGrantsOn.map(
        (permissionGrant) => permissionGrant.getDefaultRoleName(),
      );
      // Ignore role 'Mitarbeiter' as we assume that users don't want to assign employee roles in daily usage.
      const filteredRoles = roles.filter(
        (role) => role !== Permissions.DEFAULT_ROLE.EMPLOYEE.NAME,
      );

      const mostFrequentRole = ArrayUtils.getMostFrequentValue(filteredRoles);

      // Ignore role 'Individuell' as we assume that users don't want to assign individual permissions in daily usage.
      if (mostFrequentRole === Permissions.INDIVIDUAL_ROLE) {
        return;
      }

      const newPermissions = new Permissions();
      newPermissions.initWithDefaultRole(mostFrequentRole);

      setPermissions(newPermissions);
    };

    const handleChangeSubjectType = (event) => {
      Log.info(
        'Change form value of subject type',
        {
          from: pickedSubjectType,
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change subject type in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      setPickedSubjectType(event.target.value);
      setPickedSubjects([]);
    };

    const handleChangeSubjects = (event) => {
      const newPickedSubjects = event.map(({ id }) => id);

      Log.info(
        'Change form value of subjects',
        {
          from: pickedSubjects,
          to: newPickedSubjects,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change subjects in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      setPickedSubjects(newPickedSubjects);
    };

    const handleChangeEntityType = (event) => {
      Log.info(
        'Change form value of entity type',
        {
          from: pickedEntityType,
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change entity type', Log.FEATURE.WIZARD);

      setPickedEntityType(event.target.value);
      setPickedEntities([]);
    };

    const handleChangeEntities = (event) => {
      const newPickedEntities = event.map(({ id }) => id);

      Log.info(
        'Change form value of entities',
        {
          from: pickedEntities,
          to: newPickedEntities,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change entities', Log.FEATURE.WIZARD);

      setPickedEntities(newPickedEntities);
    };

    const handleChangeRole = (event) => {
      Log.info(
        'Change form value of role',
        {
          from: permissions.getDefaultRoleName(),
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        `Change role (${event.target.value}) in update permissions wizard`,
        Log.FEATURE.WIZARD,
      );

      const newPermissions = new Permissions();

      if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
        setPermissionFormOpen(true);
        return;
      }

      newPermissions.initWithDefaultRole(event.target.value);

      setPermissions(newPermissions);
    };

    const openPermissionForm = () => {
      Log.productAnalyticsEvent(
        'Open permission form in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      setPermissionFormOpen(true);
    };

    const permissionFormSuccess = (newPermissions) => {
      setPermissionFormOpen(false);
      setPermissions(newPermissions);
    };

    const permissionFormAbort = () => {
      setPermissionFormOpen(false);
    };

    useImperativeHandle(ref, () => ({
      async submit(grantPermissionsOnCostCenters) {
        const promises = [];

        if (!permissions.permissionGranted()) {
          return;
        }

        if (sitesData.isError) {
          ToastService.error(
            'Standortdaten konnten nicht geladen werden für die Vergabe von Berechtigungen.',
          );

          return;
        }

        for (const subject of pickedSubjects) {
          for (const entity of pickedEntities) {
            const body = {
              permissions: permissions.getBackendPermissions(),
            };

            Log.info(
              'Submit permission grant form',
              body,
              Log.BREADCRUMB.FORM_SUBMIT.KEY,
            );

            const [isDuplicate, error] = await promiseHandler(
              PermissionGrantService.isDuplicatePermissionGrant(
                PermissionGrant.TYPE.SUBJECT,
                pickedSubjectType,
                subject,
                pickedEntityType,
                entity,
                permissions,
              ),
            );

            if (error) {
              Log.error('Failed to detect duplicate permission grant.', error);
              Log.productAnalyticsEvent(
                'Failed to detect duplicate permission grant',
                Log.FEATURE.PERMISSIONS,
                Log.TYPE.ERROR,
              );
            }

            if (isDuplicate) {
              continue;
            }

            const promise = PermissionGrantService.createNewPermissionGrant(
              pickedSubjectType,
              subject,
              pickedEntityType,
              entity,
              body,
            );

            promises.push(promise);
          }
        }

        if (
          grantPermissionsOnCostCenters &&
          pickedEntityType === PermissionGrant.ENTITY_TYPE.SITE.KEY
        ) {
          for (const site of sitesData.data) {
            for (const subject of pickedSubjects) {
              for (const costCenterId of site.costCenters) {
                const body = {
                  permissions: permissions.getBackendPermissions(),
                };

                Log.info(
                  'Submit permission grant form',
                  body,
                  Log.BREADCRUMB.FORM_SUBMIT.KEY,
                );

                const [isDuplicate, error] = await promiseHandler(
                  PermissionGrantService.isDuplicatePermissionGrant(
                    PermissionGrant.TYPE.SUBJECT,
                    pickedSubjectType,
                    subject,
                    PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
                    costCenterId,
                    permissions,
                  ),
                );

                if (error) {
                  Log.error(
                    'Failed to detect duplicate permission grant.',
                    error,
                  );
                  Log.productAnalyticsEvent(
                    'Failed to detect duplicate permission grant',
                    Log.FEATURE.PERMISSIONS,
                    Log.TYPE.ERROR,
                  );
                }

                if (isDuplicate) {
                  continue;
                }

                const promise = PermissionGrantService.createNewPermissionGrant(
                  // TODO VGS-6993: use useMutationCreatePermissionGrant instead
                  pickedSubjectType,
                  subject,
                  PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
                  costCenterId,
                  body,
                );

                promises.push(promise);
              }
            }
          }
        }

        return PromiseUtils.allResolved(promises);
      },
    }));

    return (
      <div ref={ref} className="rounded-md border border-gray-300 p-4">
        <div className="flex items-center gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung für
            </InputLabel>
            <Select
              value={pickedSubjectType}
              onChange={handleChangeSubjectType}
              className="w-80"
              size="small"
              // If changing permissions for user groups should be possible in the wizard, the whole logic becomes a bit more complex.
              // Thus, we disable it for the moment. It should be possible in the future though.
              disabled
            >
              {Object.entries(SUBJECT_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_for"
            subjectType={pickedSubjectType}
            pickedIds={pickedSubjects}
            onChange={handleChangeSubjects}
            fullWidth
          />
        </div>

        <div className="mt-4 flex items-center gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung auf
            </InputLabel>
            <Select
              value={pickedEntityType}
              key={0}
              onChange={handleChangeEntityType}
              className="w-80"
              size="small"
            >
              {Object.entries(ENTITY_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_to"
            entityType={pickedEntityType}
            pickedIds={pickedEntities}
            onChange={handleChangeEntities}
            subjectType={pickedSubjectType}
            subjects={pickedSubjects}
            displayPermissionGrantOfCostCentersWithSites={
              props.grantPermissionsOnCostCenters
            }
            fullWidth // Needed for the enhanced label
          />
        </div>

        <div className="mt-4 flex items-end gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung als
            </InputLabel>
            <Select
              key={0}
              value={permissions.getDefaultRoleName() ?? 'None'} // A random String has to be chosen as fallback value because otherwise renderValue wouldn't catch the case if(!this.state.permissions.permissionGranted())
              onChange={handleChangeRole}
              className="w-80"
              size="small"
              renderValue={(id) => {
                if (!permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Rolle wählen
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
            >
              {Permissions.getPickableRoles().map(({ id, name }) => (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mb-0.5">
            <LightTooltip title="Detaillierte Berechtigungen einsehen">
              <Button
                variant="outlined"
                color="primary"
                onClick={openPermissionForm}
              >
                Details verwalten
              </Button>
            </LightTooltip>
          </div>
        </div>

        <PermissionForm
          open={permissionFormOpen}
          formSuccess={permissionFormSuccess}
          formAbort={permissionFormAbort}
          permissions={permissions}
        />
      </div>
    );
  }),
  'Daten konnten nicht geladen werden.',
);

PermissionGrantPicker.displayName = 'PermissionGrantPicker';
