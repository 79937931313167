import { useCallback, useRef, useState } from 'react';

import Log from '~/utils/Log';

import type { Item, UseMultiItemsManagerProps } from './types';

const filterItems = (allItems = [], excludedItems = []) =>
  allItems?.filter(
    ({ id }) => !excludedItems?.map(({ id }) => id)?.includes(id),
  );

export const useMultiItemsManager = ({
  fieldName = 'name',
  getterFunctionSelectableItems,
  getterFunctionSelectedItems,
  onChange,
  onSaveSelection,
  selectFunctionSelectableItems,
  selectFunctionSelectedItems,
}) => {
  const searchInputRef = useRef<HTMLInputElement | undefined>(null);

  const [searchString, setSearchString] = useState('');

  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [addedItems, setAddedItems] = useState<Item[]>([]);
  const [removedItems, setRemovedItems] = useState<Item[]>([]);

  const {
    data: dataSelectedItems,
    isFetchingNextPage: isFetchingNextPageSelectedItems,
    fetchNextPage: fetchNextPageSelectedItems,
    hasNextPage: hasNextPageSelectedItems,
    isLoading: isLoadingSelectedItems,
  } = getterFunctionSelectedItems(searchString);

  const selectedItems = selectFunctionSelectedItems(
    dataSelectedItems?.data ?? [],
  );
  const selectedItemsTotalCount = dataSelectedItems?.totalCount ?? 0;

  const selectedItemsFiltered = [
    ...addedItems.filter((item) => item[fieldName].includes(searchString)),
    ...filterItems(selectedItems, removedItems),
  ];

  const {
    data: dataSelectableItems,
    isFetchingNextPage: isFetchingNextPageSelectableItems,
    fetchNextPage: fetchNextPageSelectableItems,
    hasNextPage: hasNextPageSelectableItems,
    isLoading: isLoadingSelectableItems,
  } = getterFunctionSelectableItems(searchString);

  const selectableItems = selectFunctionSelectableItems(
    dataSelectableItems?.data ?? [],
  );
  const selectableItemsTotalCount = dataSelectableItems?.totalCount ?? 0;

  const selectableItemsFiltered = [
    ...removedItems.filter((item) => item[fieldName].includes(searchString)),
    ...filterItems(selectableItems, addedItems),
  ];

  const focusSearchInput = useCallback(() => {
    setTimeout(() => {
      searchInputRef.current?.focus();
    }, 1);
  }, []);

  const handleOpen = useCallback(
    (isOpen) => {
      setIsOpen(isOpen);

      if (isOpen) {
        focusSearchInput();
      } else {
        searchInputRef.current?.blur();
        setSearchString('');
      }
    },
    [focusSearchInput],
  );

  const handleEdit = useCallback(() => {
    setIsEditing(true);
    focusSearchInput();
  }, [focusSearchInput]);

  const handleChange = useCallback(() => {
    if (onChange) {
      const changedIds = [
        ...addedItems.map(({ id }) => id),
        ...removedItems.map(({ id }) => id),
      ];

      onChange(changedIds);
    }
  }, [addedItems, removedItems, onChange]);

  const handleAdd = useCallback(
    async (addedItem) => {
      try {
        if (!addedItem) {
          Log.error('No item added');
          return;
        }

        Log.info('Add entity.', addedItem);
        Log.productAnalyticsEvent(
          'Add entity',
          Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
        );

        const wasRemoved = removedItems.some(({ id }) => id === addedItem.id);
        if (wasRemoved) {
          const newRemovedItems = removedItems.filter(
            ({ id }) => id !== addedItem.id,
          );

          setRemovedItems(newRemovedItems);
        } else {
          const isAddedAlready = addedItems.some(
            ({ id }) => id === addedItem.id,
          );
          const newAddedItems = isAddedAlready
            ? [...addedItems]
            : [...addedItems, addedItem].sort((a, b) => {
                const valueA = a[fieldName] ?? '';
                const valueB = b[fieldName] ?? '';

                return valueA.toString().localeCompare(valueB.toString());
              });

          setAddedItems(newAddedItems);
        }

        handleChange();
      } catch (error) {
        Log.error('Failed to add entity:', error);
      }
    },
    [addedItems, fieldName, handleChange, removedItems],
  );

  const handleDelete = useCallback(
    (removedItem) => {
      Log.info('Remove entity.', removedItem);
      Log.productAnalyticsEvent(
        'Remove entity',
        Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
      );

      const wasAdded = addedItems.some(({ id }) => id === removedItem.id);
      if (wasAdded) {
        const newAddedItems = addedItems.filter(
          ({ id }) => id !== removedItem.id,
        );

        setAddedItems(newAddedItems);
      } else {
        const isRemovedAlready = removedItems.some(
          ({ id }) => id === removedItem.id,
        );
        const newRemovedItems = isRemovedAlready
          ? [...removedItems]
          : [...removedItems, removedItem].sort((a, b) => {
              const valueA = a[fieldName] ?? '';
              const valueB = b[fieldName] ?? '';

              return valueA.toString().localeCompare(valueB.toString());
            });

        setRemovedItems(newRemovedItems);
      }

      handleChange();
    },
    [fieldName, handleChange, removedItems, addedItems],
  );

  const handleSave = useCallback(() => {
    Log.info('Save edit mode.');
    Log.productAnalyticsEvent(
      'Save edit mode',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    onSaveSelection({
      added: addedItems,
      removed: removedItems,
    });

    setIsEditing(false);
    setAddedItems([]);
    setRemovedItems([]);
    focusSearchInput();
  }, [addedItems, removedItems, onSaveSelection]);

  const handleCancel = useCallback(() => {
    Log.info('Cancel edit mode.');
    Log.productAnalyticsEvent(
      'Cancel edit mode',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    setIsEditing(false);
    setAddedItems([]);
    setRemovedItems([]);
    focusSearchInput();
  }, [focusSearchInput]);

  return {
    addedItems,
    addedItemsCount: addedItems.length,
    fetchNextPageSelectableItems,
    fetchNextPageSelectedItems,
    handleAdd,
    handleCancel,
    handleDelete,
    handleEdit,
    handleOpenBody: handleOpen,
    handleSave,
    hasNextPageSelectableItems,
    hasNextPageSelectedItems,
    isEditing,
    isFetchingNextPageSelectableItems,
    isFetchingNextPageSelectedItems,
    isLoadingSelectableItems,
    isLoadingSelectedItems,
    isOpen,
    removedItems,
    removedItemsCount: removedItems.length,
    searchInputRef,
    searchString,
    selectableItems,
    selectableItemsFiltered,
    selectableItemsTotalCount,
    selectedItems,
    selectedItemsFiltered,
    selectedItemsTotalCount,
    setSearchString,
  };
};
