import React from 'react';

import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { FormHelperText, IconButton } from '@mui/material';

import ArticleTextField from '../deliveryNoteForm/ArticleTextField';
import cloneDeep from 'lodash/cloneDeep';
import ArticleSelect from '../deliveryNoteForm/ArticleSelect';
import Article from '~/models/articles/Article';
import ToastService from '~/services/toast.service';
import UnitUtils from '~/utils/unitUtils';
import Log from '~/utils/Log';

class ServiceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedRow: null,
      focusedColumn: null,
      focusedRow: null,
      hoveredRow: null,
    };

    this.AMOUNT_UNITS = [
      { id: Article.UNIT.TNE.STANDARDISED, name: Article.UNIT.TNE.ABBREVIATED },
      { id: Article.UNIT.KGM.STANDARDISED, name: Article.UNIT.KGM.ABBREVIATED },
      { id: Article.UNIT.MTQ.STANDARDISED, name: Article.UNIT.MTQ.ABBREVIATED },
      { id: Article.UNIT.LTR.STANDARDISED, name: Article.UNIT.LTR.ABBREVIATED },
      { id: Article.UNIT.EA.STANDARDISED, name: Article.UNIT.EA.ABBREVIATED },
      { id: Article.UNIT.MTR.STANDARDISED, name: Article.UNIT.MTR.ABBREVIATED },
      { id: Article.UNIT.MTK.STANDARDISED, name: Article.UNIT.MTK.ABBREVIATED },
      { id: Article.UNIT.MIN.STANDARDISED, name: Article.UNIT.MIN.ABBREVIATED },
      { id: Article.UNIT.HUR.STANDARDISED, name: Article.UNIT.HUR.ABBREVIATED },
      { id: Article.UNIT.SA.STANDARDISED, name: Article.UNIT.SA.DESCRIPTIVE },
      { id: Article.UNIT.RO.STANDARDISED, name: Article.UNIT.RO.DESCRIPTIVE },
      { id: Article.UNIT.PF.STANDARDISED, name: Article.UNIT.PF.DESCRIPTIVE },
      { id: Article.UNIT.PA.STANDARDISED, name: Article.UNIT.PA.DESCRIPTIVE },
      { id: Article.UNIT.BJ.STANDARDISED, name: Article.UNIT.BJ.DESCRIPTIVE },
      { id: Article.UNIT.BG.STANDARDISED, name: Article.UNIT.BG.DESCRIPTIVE },
      { id: Article.UNIT.PK.STANDARDISED, name: Article.UNIT.PK.DESCRIPTIVE },
      { id: Article.UNIT.TU.STANDARDISED, name: Article.UNIT.TU.DESCRIPTIVE },
      { id: Article.UNIT.CA.STANDARDISED, name: Article.UNIT.CA.DESCRIPTIVE },
      { id: Article.UNIT.CI.STANDARDISED, name: Article.UNIT.CI.DESCRIPTIVE },
      { id: Article.UNIT.PR.STANDARDISED, name: Article.UNIT.PR.DESCRIPTIVE },
    ];

    this.WEIGHT_UNITS = [
      { id: Article.UNIT.TNE.STANDARDISED, name: Article.UNIT.TNE.ABBREVIATED },
      { id: Article.UNIT.KGM.STANDARDISED, name: Article.UNIT.KGM.ABBREVIATED },
    ];

    this.COLUMNS = {
      MANUFACTURER_NAME: 'manufacturer-name',
      NUMBER: 'number',
      TYPE: 'type',
    };
  }

  handleChangeNumber = (event, id) => {
    const newArticles = cloneDeep(this.props.articles);

    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    newArticles[index].number = event.target.value;

    this.props.onChange(newArticles);
  };
  handleChangeType = (event, id) => {
    const newArticles = cloneDeep(this.props.articles);

    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    newArticles[index].type = event.target.value;

    this.props.onChange(newArticles);
  };
  handleChangeQuantityValue = (event, id) => {
    const newArticles = cloneDeep(this.props.articles);

    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    try {
      newArticles[index].quantity.value = UnitUtils.complexFormatDe(
        event.target.value,
      );
    } catch {
      Log.productAnalyticsEvent(
        'Invalid quantity',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );
      this.invalidInputToast();
    }

    this.props.onChange(newArticles);
  };
  handleChangeQuantityUnit = (event, id) => {
    const newArticles = cloneDeep(this.props.articles);

    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    newArticles[index].quantity.unit = event.target.value;

    this.props.onChange(newArticles);
  };

  articleIsActive(id) {
    if (this.state.focusedRow !== null) {
      return this.state.focusedRow === id;
    }

    if (this.state.hoveredRow !== null) {
      return this.state.hoveredRow === id;
    }

    return false;
  }

  invalidInputToast() {
    ToastService.warning(
      ['Ungültige Eingabe.'],
      ToastService.ID.CREATE_DLN_INVALID_INPUT,
    );
    Log.productAnalyticsEvent(
      'Invalid input for article(s)',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
      Log.TYPE.FAILED_VALIDATION,
    );
  }

  matchedMasterDataArticle(article) {
    let matchedArticle = null;

    if (this.state.focusedColumn === this.COLUMNS.NUMBER) {
      if (article.number.length < 3) {
        return null;
      }

      const matchedArticles = this.props.articleTemplates.filter((item) =>
        item.article.article_nr.startsWith(article.number),
      );
      matchedArticle = matchedArticles[0];

      for (const item of matchedArticles) {
        if (
          item.article.article_nr.length <
          matchedArticle.article.article_nr.length
        ) {
          matchedArticle = item;
        }
      }
    }

    if (this.state.focusedColumn === this.COLUMNS.TYPE) {
      if (article.type.length < 3) {
        return null;
      }

      const matchedArticles = this.props.articleTemplates.filter((item) =>
        item.article.article_name.startsWith(article.type),
      );
      matchedArticle = matchedArticles[0];

      for (const item of matchedArticles) {
        if (
          item.article.article_name.length <
          matchedArticle.article.article_name.length
        ) {
          matchedArticle = item;
        }
      }
    }

    if (this.state.focusedColumn === this.COLUMNS.MANUFACTURER_NAME) {
      if (article.manufacturer.name.length < 3) {
        return null;
      }

      const matchedArticles = this.props.articleTemplates
        .filter((item) =>
          item.article.manufacturer_name.startsWith(article.manufacturer.name),
        )
        .map((item) => {
          return {
            ...item,
            article: {
              manufacturer_city: item.article.manufacturer_city,

              manufacturer_country: item.article.manufacturer_country,

              manufacturer_house_number: item.article.manufacturer_house_number,
              // only make suggestions for manufacturer information
              manufacturer_name: item.article.manufacturer_name,
              manufacturer_post_code: item.article.manufacturer_post_code,
              manufacturer_street: item.article.manufacturer_street,
            },
          };
        });
      matchedArticle = matchedArticles[0];

      for (const item of matchedArticles) {
        if (
          item.article.manufacturer_name.length <
          matchedArticle.article.manufacturer_name.length
        ) {
          matchedArticle = item;
        }
      }
    }

    return matchedArticle?.article;
  }

  autofill = (e, id, autofillArticle) => {
    if (!autofillArticle) {
      return;
    }

    const newArticles = cloneDeep(this.props.articles);
    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    // right arrow: only autofill the focused column
    if (e.keyCode === 39) {
      if (this.state.focusedColumn === this.COLUMNS.NUMBER) {
        Log.productAnalyticsEvent(
          'Autofill article number',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        newArticles[index].number = autofillArticle.article_nr;
      }

      if (this.state.focusedColumn === this.COLUMNS.TYPE) {
        Log.productAnalyticsEvent(
          'Autofill article type',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        newArticles[index].type = autofillArticle.article_name;
      }

      if (this.state.focusedColumn === this.COLUMNS.MANUFACTURER_NAME) {
        Log.productAnalyticsEvent(
          'Autofill article manufacturer',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        newArticles[index].manufacturer.name =
          autofillArticle.manufacturer_name;
      }

      this.props.onChange(newArticles);
    }

    // enter 13; tab 9
    if (e.keyCode !== 13 && e.keyCode !== 9) {
      return;
    }

    // autofill the whole article
    if (
      this.state.focusedColumn === this.COLUMNS.NUMBER ||
      this.state.focusedColumn === this.COLUMNS.TYPE
    ) {
      if (this.state.focusedColumn === this.COLUMNS.NUMBER) {
        Log.productAnalyticsEvent(
          'Autofill article by number',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
      } else {
        Log.productAnalyticsEvent(
          'Autofill article by type',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
      }

      newArticles[index].number = autofillArticle.article_nr;
      newArticles[index].type = autofillArticle.article_name;
      newArticles[index].quantity.unit = autofillArticle.article_amount_unit;
      newArticles[index].weight.unit = autofillArticle.article_weight_unit;
      newArticles[index].ean = autofillArticle.article_ean;
      newArticles[index].manufacturer.name = autofillArticle.manufacturer_name;
      newArticles[index].manufacturer.address.streetName =
        autofillArticle.manufacturer_street;
      newArticles[index].manufacturer.address.buildingNumber =
        autofillArticle.manufacturer_house_number;
      newArticles[index].manufacturer.address.postCode =
        autofillArticle.manufacturer_post_code;
      newArticles[index].manufacturer.address.city =
        autofillArticle.manufacturer_city;
      newArticles[index].manufacturer.address.country =
        autofillArticle.manufacturer_country;

      this.props.onChange(newArticles);
    }

    // only autofill the manufacturer information
    if (this.state.focusedColumn === this.COLUMNS.MANUFACTURER_NAME) {
      Log.productAnalyticsEvent(
        'Autofill article manufacturer by manufacturer name',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      newArticles[index].manufacturer.name = autofillArticle.manufacturer_name;
      newArticles[index].manufacturer.address.streetName =
        autofillArticle.manufacturer_street;
      newArticles[index].manufacturer.address.buildingNumber =
        autofillArticle.manufacturer_house_number;
      newArticles[index].manufacturer.address.postCode =
        autofillArticle.manufacturer_post_code;
      newArticles[index].manufacturer.address.city =
        autofillArticle.manufacturer_city;
      newArticles[index].manufacturer.address.country =
        autofillArticle.manufacturer_country;

      this.props.onChange(newArticles);
    }
  };

  render() {
    return (
      <div className="relative w-full">
        <div className="border-grey400 rounded-4px mt-10px">
          <div className="bg-grey100 rounded-top-4px border-bottom-grey400 bold flex-s-c gap-20px p-10px">
            <div className="pl-10px w-150px">Artikel-Nr.*</div>
            <div className="pl-10px flex-1">Artikelname*</div>
            <div className="pr-10px w-100px text-end">Menge*</div>
            <div className="pl-10px w-100px">Einheit*</div>
            <div className="pl-10px w-20px"></div>
          </div>
          {this.props.articles.map((article, index) => {
            const autofillArticle = this.matchedMasterDataArticle(article);

            return (
              <div
                className={
                  'p-10px transition-500ms w-full ' +
                  (this.articleIsActive(article.id) ? 'bg-grey100' : '') +
                  (this.props.articles.length === index + 1
                    ? 'rounded-bottom-4px'
                    : 'border-bottom-grey400')
                }
                onMouseEnter={() => this.setState({ hoveredRow: article.id })}
                onMouseLeave={() => this.setState({ hoveredRow: null })}
                key={article.id}
              >
                <div className="flex-s-c gap-20px">
                  <div className="w-150px">
                    <ArticleTextField
                      value={article.number}
                      error={article.error.number}
                      placeholder="Artikel-Nr.*"
                      autofillvalue={autofillArticle?.article_nr}
                      onChange={(event) =>
                        this.handleChangeNumber(event, article.id)
                      }
                      onFocus={() =>
                        this.setState({
                          focusedColumn: this.COLUMNS.NUMBER,
                          focusedRow: article.id,
                        })
                      }
                      onBlur={() => {
                        if (this.state.focusedRow === article.id) {
                          this.setState({
                            focusedColumn: null,
                            focusedRow: null,
                          });
                        }
                      }}
                      onKeyDown={(e) =>
                        this.autofill(e, article.id, autofillArticle)
                      }
                    />
                  </div>
                  <div className="flex-1">
                    <ArticleTextField
                      value={article.type}
                      error={article.error.type}
                      placeholder="Artikelname*"
                      autofillvalue={autofillArticle?.article_name}
                      onChange={(event) =>
                        this.handleChangeType(event, article.id)
                      }
                      onFocus={() =>
                        this.setState({
                          focusedColumn: this.COLUMNS.TYPE,
                          focusedRow: article.id,
                        })
                      }
                      onBlur={() => {
                        if (this.state.focusedRow === article.id) {
                          this.setState({
                            focusedColumn: null,
                            focusedRow: null,
                          });
                        }
                      }}
                      onKeyDown={(e) =>
                        this.autofill(e, article.id, autofillArticle)
                      }
                    />
                  </div>
                  <div className="w-100px">
                    <ArticleTextField
                      value={article.quantity.value}
                      error={article.error.quantityValue}
                      placeholder="Menge*"
                      onChange={(event) =>
                        this.handleChangeQuantityValue(event, article.id)
                      }
                      onFocus={() => this.setState({ focusedRow: article.id })}
                      onBlur={() => {
                        if (this.state.focusedRow === article.id) {
                          this.setState({ focusedRow: null });
                        }
                      }}
                      textend={1}
                    />
                  </div>
                  <div className="w-100px">
                    <ArticleSelect
                      value={article.quantity.unit}
                      error={article.error.quantityUnit}
                      options={this.AMOUNT_UNITS}
                      onChange={(event) =>
                        this.handleChangeQuantityUnit(event, article.id)
                      }
                      placeholder={
                        autofillArticle?.article_amount_unit
                          ? UnitUtils.getAbbreviatedUnit(
                              autofillArticle.article_amount_unit,
                            )
                          : 'Einheit*'
                      }
                    />
                  </div>
                  <div className="flex-c-c w-20px">
                    <IconButton
                      size="small"
                      onClick={() => this.props.removeArticle(article.id)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {this.props.error ? (
          <div className="flex-s-s w-full">
            <FormHelperText className="text-mui-error-red absolute">
              {this.props.error}
            </FormHelperText>
          </div>
        ) : null}
        <div className="flex-c-c mt-10px w-full">
          <IconButton onClick={this.props.addArticle} size="large">
            <AddIcon />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default ServiceList;
