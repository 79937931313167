import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { useQueryArticlesLegacy } from '~/data/article';
import { useQueryCategories } from '~/data/category';
import { useQueryCompaniesLegacy } from '~/data/company';
import { useQuerySiteLegacy } from '~/data/site';

import LocalStorageService from '~/services/localStorage.service';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { categoriesColumns } from './constants';
import { selectCategories } from './selectCategories';

const CategoryTableComponent = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  const { companyAccount, id } = useSelector(
    (state) => state.userinfo.userinfo.company,
  );

  const { data: sites } = useQuerySiteLegacy();
  const { data: companies } = useQueryCompaniesLegacy();
  const { data: articles } = useQueryArticlesLegacy();

  const [filterActive, setFilterActive] = useState(true);

  const [searchString, setSearchString] = useState(undefined);

  const {
    data: categories,
    isFetching,
    isPending,
    isPlaceholderData,
    refetch,
  } = useQueryCategories(
    {
      filterOwnerCompAccs: companyAccount,
      filterOwnerComps: id,
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      orderBy: sortModel[0]?.field,
      searchText: searchString,
      sort: sortModel[0]?.sort,
      ...(filterActive !== '' && { isActive: filterActive }),
    },
    {
      select: (data) => selectCategories(data, companies, sites, articles),
    },
  );

  const handleSetActiveFilter = useCallback((filterActive) => {
    setFilterActive(filterActive);
  }, []);

  const handlePaginationModelChange = useCallback((newModel) => {
    setPaginationModel(newModel);
  }, []);

  const handleSortModelChange = useCallback((newModel) => {
    setSortModel(newModel);
  }, []);

  const handleSearchStringChange = useCallback((newSearchString) => {
    setSearchString(newSearchString);
  }, []);

  const filterFunction = useCallback((string) => string, []); // We filter server side -> prevent the DataGrid from filtering.

  return (
    <SettingsTable
      columns={categoriesColumns}
      entity="category"
      filterActive={filterActive}
      filterFunction={filterFunction}
      hasActiveStateFilter
      isLoading={isPending || (isFetching && isPlaceholderData)}
      items={categories?.categories ?? []}
      localStorageKey={LocalStorageService.ARTICLE_TABLE}
      onPaginationModelChange={handlePaginationModelChange}
      onSearchStringChange={handleSearchStringChange}
      onSortModelChange={handleSortModelChange}
      paginationModel={paginationModel}
      refreshData={refetch}
      rows={categories?.rows ?? []}
      setActiveFilter={handleSetActiveFilter}
      sortBy="name"
      sortModel={sortModel}
      title="Kategorie"
      totalRowCount={categories?.totalCount ?? 0}
    />
  );
};

export const CategoriesTable = withErrorBoundary(memo(CategoryTableComponent));
