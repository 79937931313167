import DeliveryNote from '~/models/deliveries/DeliveryNote';
import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import UserUtils from '~/utils/userUtils';

export const getSelectableFilters = ({
  customFields = [],
  isSelectableFilterDisabled,
  userFeatureFlags = {},
}) => {
  const filtersStandard = [
    {
      disabled: isSelectableFilterDisabled(
        DeliveryNote.PROPERTY.PROCESS_STATE.KEY,
      ),
      id: FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE,
      name: DeliveryNote.PROPERTY.PROCESS_STATE.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(
        DeliveryNote.PROPERTY.ACCEPT_STATE.KEY,
      ),
      id: FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE,
      name: DeliveryNote.PROPERTY.ACCEPT_STATE.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(
        DeliveryNote.PROPERTY.SETTLED_STATUS.KEY,
      ),
      id: FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS,
      name: DeliveryNote.PROPERTY.SETTLED_STATUS.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(
        DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
      ),
      id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT,
      name: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(
        DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
      ),
      id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER,
      name: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(
        DeliveryNote.PROPERTY.COST_CENTER.KEY,
      ),
      id: FilterGroupFilter.FILTER.SELECTED_COST_CENTER,
      name: DeliveryNote.PROPERTY.COST_CENTER.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(
        DeliveryNote.PROPERTY.ARTICLE_NUMBER.KEY,
      ),
      id: FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER,
      name: DeliveryNote.PROPERTY.ARTICLE_NUMBER.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(DeliveryNote.PROPERTY.ARTICLE.KEY),
      id: FilterGroupFilter.FILTER.SELECTED_ARTICLE,
      name: DeliveryNote.PROPERTY.ARTICLE.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(DeliveryNote.PROPERTY.SUPPLIER.KEY),
      id: FilterGroupFilter.FILTER.SELECTED_SUPPLIER,
      name: DeliveryNote.PROPERTY.SUPPLIER.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(DeliveryNote.PROPERTY.RECIPIENT.KEY),
      id: FilterGroupFilter.FILTER.SELECTED_RECIPIENT,
      name: DeliveryNote.PROPERTY.RECIPIENT.STRING,
    },
    {
      disabled: isSelectableFilterDisabled(DeliveryNote.PROPERTY.FROM_SITE.KEY),
      id: FilterGroupFilter.FILTER.SELECTED_FROM_SITE,
      name: DeliveryNote.PROPERTY.FROM_SITE.STRING,
    },
    // VGS-6663: activate filter commenting in this block
    // {
    //   id: FilterGroupFilter.FILTER.SELECTED_NUMBER,
    //   name: DeliveryNote.PROPERTY.NUMBER.STRING,
    //   disabled: isSelectableFilterDisabled(DeliveryNote.PROPERTY.NUMBER.KEY),
    // },
  ];

  const canFilterPermittedSites =
    userFeatureFlags.accessPermittedSites ||
    UserUtils.isPermittedSiteAllowedUser();

  const filtersPermittedSites = canFilterPermittedSites
    ? [
        {
          disabled: isSelectableFilterDisabled(
            DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.KEY,
          ),
          id: FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES,
          name: DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.STRING,
        },
        {
          disabled: isSelectableFilterDisabled(
            DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.KEY,
          ),
          id: FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS,
          name: DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.STRING,
        },
      ]
    : [];

  const filtersCustomFields = customFields.map((customField) => ({
    customField,
    disabled: isSelectableFilterDisabled(customField.key),
    id: customField.key,
    name: customField.displayName,
  }));

  const selectableFilters = [
    ...filtersStandard,
    ...filtersPermittedSites,
    ...filtersCustomFields,
  ];

  return selectableFilters;
};
