import { memo, useCallback, useState } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { useQueryCustomFields } from '~/data/customFields';

import LocalStorageService from '~/services/localStorage.service';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns } from './constants';
import { selectCustomFields } from './selectCustomFields';

export const CustomFieldsTable = withErrorBoundary(
  memo(() => {
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
    });
    const [sortModel, setSortModel] = useState([
      {
        field: 'name',
        sort: 'asc',
      },
    ]);
    const [searchString, setSearchString] = useState(undefined);

    const { data, isPending, isFetching, isPlaceholderData, refetch } =
      useQueryCustomFields(
        {
          limit: paginationModel.pageSize,
          offset: paginationModel.page * paginationModel.pageSize,
          orderBy: sortModel[0]?.field,
          searchString,
          sort: sortModel[0]?.sort,
        },
        {
          select: selectCustomFields,
        },
      );

    const handlePaginationModelChange = useCallback((newModel) => {
      setPaginationModel(newModel);
    }, []);

    const handleSortModelChange = useCallback((newModel) => {
      setSortModel(newModel);
    }, []);

    const handleSearchStringChange = useCallback((newSearchString) => {
      // Show results from start when search string changes.
      setPaginationModel({
        ...paginationModel,
        page: 0,
      });
      setSearchString(newSearchString);
    }, []);

    const filterFunction = useCallback((string) => string, []); // We filter server side -> prevent the DataGrid from filtering.

    return (
      <SettingsTable
        entity="customField"
        title="Flexible Felder"
        columns={columns}
        filterFunction={filterFunction}
        items={data?.customFields ?? []}
        isLoading={isPending || (isFetching && isPlaceholderData)}
        localStorageKey={LocalStorageService.CUSTOM_FIELD_TABLE}
        onPaginationModelChange={handlePaginationModelChange}
        onSearchStringChange={handleSearchStringChange}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        refreshData={refetch}
        sortModel={sortModel}
        rows={data?.rows ?? []}
        sortBy="name"
        totalRowCount={data?.totalCount ?? 0}
      />
    );
  }),
  'Benutzer-Gruppen konnten nicht geladen werden.',
);

CustomFieldsTable.displayName = 'CustomFieldsTable';
