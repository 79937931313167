import React, { memo, useRef } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Button, InputLabel } from '@mui/material';

import { CostCenterIcon, LocationIcon } from '~/assets/icons';

import { LightTooltip } from '~/utils/componentUtils';

import BasicPopover from '~/components/BasicPopover';
import { withErrorBoundary } from '~/ui/atoms';
import {
  MultiSelectCostCenters,
  MultiSelectSites,
  OptionPrimarySecondary,
} from '~/ui/molecules/SelectServerDriven';

import { useSitesCostCentersSelection } from './useSitesCostCentersSelection';

const formatOptionLabel = (option) => (
  <OptionPrimarySecondary
    primaryInformation={option.label}
    secondaryInformation={option.data?.accountingReferences
      ?.map(({ name }) => name)
      ?.join(', ')}
  />
);

export const SitesCostCentersSelection = withErrorBoundary(
  memo((props) => {
    const containerRef = useRef(null);

    const {
      anchorElement,
      getSelectedCostCentersString,
      getSelectedSitesString,
      handleCancel,
      handleChangeCostCenters,
      handleChangeSites,
      handleDelete,
      handleOpenPopover,
      handleSubmit,
      hasChanges,
      hasGlobalSelectedData,
      hasSelectedData,
      selectedCostCenters,
      selectedSites,
    } = useSitesCostCentersSelection();

    const getComponent = () => {
      if (!hasGlobalSelectedData) {
        return (
          <LightTooltip title="Aktuellen Standort und Kostenstelle auswählen. Lieferungen, Statistiken und Rechnungen werden nur für diese angezeigt.">
            <Button variant="outlined" onClick={handleOpenPopover}>
              Aktuellen Standort auswählen
            </Button>
          </LightTooltip>
        );
      }

      return (
        <div
          className="text-primary500 cursor-pointer font-bold underline underline-offset-2"
          onClick={handleOpenPopover}
        >
          <div className="flex items-center gap-1">
            <LocationIcon className="text-primary500 icon-15px" />
            <div className="max-w-400px text-ellipsis">
              {getSelectedSitesString}
            </div>
          </div>
          <div className="flex items-center gap-1">
            <CostCenterIcon className="text-primary500 icon-15px" />
            <div className="max-w-400px text-ellipsis">
              {getSelectedCostCentersString}
            </div>
          </div>
        </div>
      );
    };

    const getDeleteButton = () => {
      if (!hasSelectedData) {
        return null;
      }

      return (
        <LightTooltip title="Standorte und Kostenstellen zurücksetzen.">
          <Button
            variant="text"
            onClick={handleDelete}
            startIcon={<DeleteIcon fontSize="small" />}
          >
            Zurücksetzen
          </Button>
        </LightTooltip>
      );
    };

    return (
      <div className={props.className} ref={containerRef}>
        {getComponent()}
        <BasicPopover
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          formSuccess={handleSubmit}
          onClose={handleCancel}
          disableSubmitButton={!hasChanges}
          customDeleteButton={getDeleteButton()}
        >
          <div className="w-500px flex flex-col gap-4">
            <h3 className="text-lg font-bold">
              Anwendungsweite Standort- und Kostenstellen-Filter
            </h3>
            <div className="flex flex-col gap-0.5">
              <InputLabel>
                Alle angezeigten Daten filtern auf Standorte
              </InputLabel>
              <MultiSelectSites
                placeholder="Standorte auswählen"
                value={selectedSites}
                onChange={handleChangeSites}
                formatOptionLabel={formatOptionLabel}
              />
            </div>
            <div className="flex flex-col gap-0.5">
              <InputLabel>
                Alle angezeigten Daten filtern auf Kostenstellen
              </InputLabel>
              <MultiSelectCostCenters
                placeholder="Kostenstellen auswählen"
                value={selectedCostCenters}
                onChange={handleChangeCostCenters}
              />
            </div>
          </div>
        </BasicPopover>
      </div>
    );
  }),
  null,
);

SitesCostCentersSelection.displayName = 'SitesCostCentersSelection';
