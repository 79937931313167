import { useQuery } from '@tanstack/react-query';

import SiteService from '~/services/site.service';

import { queryKeysSite } from './queryKeys';

// This query uses an deprecated endpoint for fetching ALL sites ever, which is not technically possible for large accounts.
export const useQuerySiteLegacy = (options) =>
  useQuery({
    queryFn: SiteService.getAllSites,
    queryKey: queryKeysSite.getAllLegacy(),
    ...options,
  });
