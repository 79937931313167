import { type ReactElement } from 'react';
import clsx from 'clsx';
import {
  Delete as DeleteIcon,
  Replay as ReplayIcon,
} from '@mui/icons-material';

import { ListItem } from '~/ui/molecules/VirtualizedList';

import { type Item } from '../types';

type ManageableListItemProps = {
  fieldName: string;
  item: Item;
  onClick: (item: Item) => void;
  type: Item['type'];
  variant: 'selectable' | 'selected';
};

export const ManageableListItem = ({
  fieldName,
  item,
  onClick,
  type,
  variant,
}: ManageableListItemProps): ReactElement => {
  const getIcon = (): ReactElement | undefined => {
    if (variant === 'selected') {
      switch (type) {
        case 'added': {
          return <DeleteIcon />;
        }

        case 'current': {
          return <DeleteIcon />;
        }

        case 'removed': {
          return <ReplayIcon />;
        }

        default: {
          return null;
        }
      }
    }

    return type === 'removed' ? <ReplayIcon /> : null;
  };

  const handleClick = (): void => {
    onClick(item);
  };

  return (
    <ListItem
      onClick={handleClick}
      className={clsx('decoration-gray-500', {
        '!bg-green-200/35': type === 'added',
        '!bg-red-200/35 line-through decoration-gray-500': type === 'removed',
      })}
      icon={getIcon()}
    >
      {item[fieldName]}
    </ListItem>
  );
};
