import { type UUID } from '~/types/common';

import { type VehiclesQueryParams } from './useQueryVehicles';

/**
 * Query key factory for vehicle-related queries.
 */
export const queryKeysVehicle = {
  base: () => 'vehicles' as const,
  get: (vehicleId: UUID) =>
    [queryKeysVehicle.base(), 'detail', { vehicleId }] as const,
  getAll: (queryParams: Partial<VehiclesQueryParams>) =>
    [queryKeysVehicle.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<VehiclesQueryParams>) =>
    [queryKeysVehicle.base(), 'list', 'infinite', queryParams] as const,
  updateSettings: (vehicleId: UUID) =>
    [queryKeysVehicle.base(), 'update', vehicleId] as const,
} as const;
