import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';

import UserUtils from '~/utils/userUtils';
import PermissionGrant from '~/models/masterdata/PermissionGrant';

import DatePicker from '~/components/baseComponents/inputs/date/DatePicker';
import BasicForm from '~/components/BasicForm';

import { MultiItemsManagerOrganizationalUnits } from '~/ui/molecules/MultiItemsManager';
import { SelectCompany } from '~/ui/molecules/SelectServerDriven';

import { OrganizationalUnitPaths } from '../../paths/OrganizationalUnitPaths';
import { PermissionGrantEntityTable } from '../../permissionGrant/PermissionsTable';
import { useCostCenterForm } from './useCostCenterForm';

export const CostCenterForm = ({
  closeForm,
  costCenterId,
  onOpenOrganisationalGroup,
  onUpdatedOrganisationalGroupsChange,
  open,
  setCostCenter,
  type,
}) => {
  const {
    costCenter,
    handleCancel,
    handleChangeActive,
    handleChangeCompany,
    handleChangeDate,
    handleChangeName,
    handleChangeOrganisationalGroups,
    handleSubmit,
    isCreatingCostCenter,
    isLoading,
    isSubmitting,
    refetchCostCenter,
    unsavedChanges,
  } = useCostCenterForm({
    closeForm,
    costCenterId,
    setCostCenter,
    type,
  });

  const shouldRenderGroupPaths =
    costCenter?.parentOrganizationalUnitPaths?.length > 0;

  const canSave = isCreatingCostCenter
    ? UserUtils.isCostCenterCreateAllowedUser()
    : UserUtils.isCostCenterWriteAllowedUser();

  return (
    <BasicForm
      open={open}
      formSuccess={handleSubmit}
      formAbort={handleCancel}
      title={`Kostenstelle ${isCreatingCostCenter ? 'erstellen' : costCenter?.name}`}
      fullWidth
      submittingForm={isSubmitting}
      id={costCenter?.id}
      unsavedChanges={unsavedChanges}
      missingPermissionsToSubmit={!canSave}
    >
      <Grid container direction="row" spacing={3} space={4}>
        {shouldRenderGroupPaths ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <OrganizationalUnitPaths
                  id={costCenter?.id}
                  organizationalUnitPaths={
                    costCenter?.parentOrganizationalUnitPaths
                  }
                  onOpenOrganizationalUnit={(organizationalUnit) =>
                    onOpenOrganisationalGroup(
                      organizationalUnit,
                      unsavedChanges,
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12} lg={12}>
          <h3 className="main-text mt-0">Kostenstelle</h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                id="name-input"
                name="name"
                label="Kostenstelle"
                type="text"
                disabled={!isCreatingCostCenter}
                required
                fullWidth
                value={costCenter?.name}
                onChange={handleChangeName}
                autoFocus
                autoComplete="off"
              />
            </Grid>
            {isCreatingCostCenter ? null : (
              <Grid item xs={6} lg={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(costCenter?.isActive)}
                      name="isActive"
                    />
                  }
                  onChange={handleChangeActive}
                  label="Aktiv"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <InputLabel className="text-13px">Firma</InputLabel>
              <SelectCompany
                value={costCenter?.companyId}
                onChange={handleChangeCompany}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <InputLabel>Startdatum</InputLabel>
              <DatePicker
                textFieldProps={{
                  fullWidth: true,
                }}
                value={costCenter?.start}
                maxDate={costCenter?.end}
                onChange={(value) => handleChangeDate(value, 'start')}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <InputLabel>Enddatum</InputLabel>
              <DatePicker
                textFieldProps={{
                  fullWidth: true,
                }}
                value={costCenter?.end}
                minDate={costCenter?.start}
                onChange={(value) => handleChangeDate(value, 'end')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <h3 className="main-text mt-4">Ist Teil von...</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerOrganizationalUnits
                isCreatingNewEntity={isCreatingCostCenter}
                filterByEntity={{
                  accountingReference: costCenterId,
                }}
                onSaveSelection={handleChangeOrganisationalGroups}
                onSelectedItemClick={(organisationalGroup) =>
                  onOpenOrganisationalGroup(organisationalGroup, unsavedChanges)
                }
                onChange={onUpdatedOrganisationalGroupsChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {isCreatingCostCenter ? null : (
          <Grid item xs={12}>
            <PermissionGrantEntityTable
              title="Wer ist auf diese Kostenstelle berechtigt?"
              permissionGrantsFrom={costCenter?.permissionGrantsFrom ?? []}
              defaultEntities={[costCenterId]}
              defaultEntityType={PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY}
              fixedPicker={PermissionGrant.TYPE.ENTITY}
              refreshData={refetchCostCenter}
              loading={isLoading}
            />
          </Grid>
        )}
      </Grid>
    </BasicForm>
  );
};
