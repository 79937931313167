export default class CustomFieldOption {
  constructor(custom_field_option) {
    this.id = custom_field_option?.id ?? null;
    this.displayName =
      custom_field_option?.display_name ??
      custom_field_option?.displayName ??
      null;
    this.description = custom_field_option?.description ?? null;
  }
}
