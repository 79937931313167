import {
  ArrowRight as ArrowRightIcon,
  LocalShippingRounded as LocalShippingRoundedIcon,
  WarehouseRounded as WarehouseRoundedIcon,
} from '@mui/icons-material';

import { useQueryCompanyLogo } from '~/data/company';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import ValueGroup from '~/models/deliveries/ValueGroup';

import { LightTooltip } from '~/utils/componentUtils';

import { withErrorBoundary } from '~/ui/atoms';

import {
  AttentionIconLight,
  DeclinedIconLight,
  DoneIconLight,
} from '~/assets/icons';

export const DeliveryNoteLogos = withErrorBoundary(
  ({
    acceptState,
    acceptStateCarrier,
    acceptStateOnBehalfCarrier,
    acceptStateOnBehalfRecipient,
    acceptStateOnBehalfSupplier,
    acceptStateRecipient,
    acceptStateSupplier,
    carrier,
    processState,
    recipient,
    supplier,
  }) => {
    const supplierId = ValueGroup.getCurrentValue(supplier?.id);
    const carrierId = ValueGroup.getCurrentValue(carrier?.id);
    const recipientId = ValueGroup.getCurrentValue(recipient?.id);

    const supplierName = ValueGroup.getCurrentValue(supplier?.name);
    const carrierName = ValueGroup.getCurrentValue(carrier?.name);
    const recipientName = ValueGroup.getCurrentValue(recipient?.name);

    const { data: supplierLogo } = useQueryCompanyLogo(supplierId);
    const { data: carrierLogo } = useQueryCompanyLogo(carrierId);
    const { data: recipientLogo } = useQueryCompanyLogo(recipientId);

    const shouldDisplayCarrier =
      supplierId !== carrierId ||
      acceptState?.carrier === AcceptStateCalculator.ACCEPT_STATE.DECLINED;

    const renderLogo = ({
      acceptState,
      companyLogo,
      companyName,
      fallback,
      onBehalfAcceptState,
      role,
    }) => {
      let title = role + (companyName ? `: ${companyName}` : '');
      let acceptStateIcon = null;

      const iconClassName =
        'shadow-md absolute -right-1 -top-1 size-5 rounded-full';

      if (
        acceptState === AcceptStateCalculator.ACCEPT_STATE.DECLINED ||
        onBehalfAcceptState === AcceptStateCalculator.ACCEPT_STATE.DECLINED
      ) {
        acceptStateIcon = <DeclinedIconLight className={iconClassName} />;
        title += ' (Lieferung abgelehnt)';
      } else if (
        acceptState === AcceptStateCalculator.ACCEPT_STATE.APPROVED ||
        onBehalfAcceptState === AcceptStateCalculator.ACCEPT_STATE.APPROVED
      ) {
        acceptStateIcon = <DoneIconLight className={iconClassName} />;
        title += ' (Lieferung bestätigt)';
      } else if (
        role !== DeliveryNote.PROCESS_ROLE.CARRIER.STRING &&
        processState === DeliveryNote.PROCESS_STATE.DELIVERED.STRING
      ) {
        // Case: Status of party is open.
        // If dln is delivered, display attention icon (signature needed) for both supplier and recipient.

        acceptStateIcon = <AttentionIconLight className={iconClassName} />;
        title += ' (fehlende Signatur)';
      }

      return (
        <LightTooltip title={title}>
          <div className="relative flex size-12 items-center justify-center rounded-lg bg-white">
            {companyLogo ? (
              <img className="delivery-note-logo" src={companyLogo} />
            ) : (
              fallback
            )}
            {acceptStateIcon}
          </div>
        </LightTooltip>
      );
    };

    return (
      <div className="flex items-center justify-end">
        {renderLogo({
          acceptState: acceptStateSupplier,
          companyLogo: supplierLogo,
          companyName: supplierName,
          fallback: <WarehouseRoundedIcon />,
          onBehalfAcceptState: acceptStateOnBehalfSupplier,
          role: DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING,
        })}
        <ArrowRightIcon fontSize="large" />
        {shouldDisplayCarrier ? (
          <>
            {renderLogo({
              acceptState: acceptStateCarrier,
              companyLogo: carrierLogo,
              companyName: carrierName,
              fallback: <LocalShippingRoundedIcon />,
              onBehalfAcceptState: acceptStateOnBehalfCarrier,
              role: DeliveryNote.PROCESS_ROLE.CARRIER.STRING,
            })}
            <ArrowRightIcon fontSize="large" />
          </>
        ) : null}
        {renderLogo({
          acceptState: acceptStateRecipient,
          companyLogo: recipientLogo,
          companyName: recipientName,
          fallback: <WarehouseRoundedIcon />,
          onBehalfAcceptState: acceptStateOnBehalfRecipient,
          role: DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING,
        })}
      </div>
    );
  },
  null,
);

DeliveryNoteLogos.displayName = 'DeliveryNoteLogos';
