import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysCategory } from './queryKeys';

/**
 * delete an existing categoryIcon.
 *
 * @param {string} categoryIconId - The ID of the categoryIcon to delete.
 * @param {Object} categoryIconData - An object containing the vehicle data.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/remove_article_category_icon_article_master_category__category_uuid__icon_delete
 */
export const deleteCategoryIcon = async (categoryIconId: UUID) => {
  try {
    const response = await vestigasApi
      .delete(ENDPOINT.CATEGORY.DELETE_ICON(categoryIconId))
      .json();

    return response;
  } catch (error) {
    Log.error('Error deleting categoryIcon settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for deleting an existing categoryIcon.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */

export const useMutationDeleteCategoryIcon = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (categoryIconId: UUID) =>
      deleteCategoryIcon(categoryIconId),
    async onError(error) {
      Log.productAnalyticsEvent(
        'Failed to delete categoryIcon',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeysCategory.getAll({}),
      });
    },
    ...options,
  });
};
