import { Checkbox, Divider, FormControlLabel, InputLabel } from '@mui/material';

import BasicForm from '~/components/BasicForm';

import { LightTooltipWide } from '~/utils/componentUtils';

import { withErrorBoundary } from '~/ui/atoms';
import {
  MultiSelectCostCenters,
  MultiSelectSites,
  OptionPrimarySecondary,
} from '~/ui/molecules/SelectServerDriven';

import { useMapDirectDeliveryNoteForm } from './useMapDirectDeliveryNoteForm';

const formatOptionLabel = (option) => (
  <OptionPrimarySecondary
    primaryInformation={option.label}
    secondaryInformation={option.data?.accountingReferences
      ?.map(({ name }) => name)
      ?.join(', ')}
  />
);

export const MapDirectDeliveryNoteForm = withErrorBoundary(
  ({
    closeForm,
    defaultSelectedCostCenterId,
    defaultSelectedSiteId,
    deliveryNoteIds,
    formSuccess,
    issuerIds,
    open,
    refreshDeliveryNote,
    toSiteSupplierNames,
  }) => {
    const {
      handleCancel,
      handleDelete,
      handleSubmit,
      hasMultipleIssuersOrIdentifiers,
      setValue,
      state,
    } = useMapDirectDeliveryNoteForm({
      closeForm,
      defaultSelectedCostCenterId,
      defaultSelectedSiteId,
      deliveryNoteIds,
      formSuccess,
      issuerIds,
      refreshDeliveryNote,
      toSiteSupplierNames,
    });

    return (
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleCancel}
        formDelete={handleDelete}
        title="Standort zuweisen"
        submitButtonTitle="Zuweisen"
        deleteButtonTitle="Zuordnungen entfernen"
        submittingForm={state.isSubmitting}
        deletingForm={state.isDeleting}
        noDeleteForm
      >
        <div className="text-13px text-grey600">
          Bitte gib an, zu welchem Standort und Kostenstelle die Lieferung(en)
          zugewiesen werden soll(en).
        </div>
        <InputLabel className="text-13px mt-4">Standort</InputLabel>
        <div className="w-600px">
          <MultiSelectSites
            placeholder="Standort auswählen"
            value={state.form.selectedSite ? [state.form.selectedSite] : []}
            onChange={(site, siteData) => {
              setValue('selectedSite', site?.id ?? '', siteData);
            }}
            formatOptionLabel={formatOptionLabel}
            isMultiSelect={false}
          />
        </div>
        <LightTooltipWide title="Sollen alle bisher berechtigten Standorte entfernt werden?">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.form.replaceSites}
                onChange={(event) =>
                  setValue('replaceSites', event.target.checked)
                }
                name="replaceSites"
              />
            }
            label="Bisher berechtigte Standorte entfernen"
          />
        </LightTooltipWide>
        <InputLabel className="text-13px mt-4">Kostenstelle</InputLabel>
        <div className="w-600px">
          <MultiSelectCostCenters
            placeholder="Kostenstelle auswählen"
            value={
              state.form.selectedCostCenter
                ? [state.form.selectedCostCenter]
                : []
            }
            onChange={(costCenter) => {
              setValue('selectedCostCenter', costCenter?.id ?? '');
            }}
            isMultiSelect={false}
          />
        </div>
        <LightTooltipWide title="Sollen alle bisher berechtigten Kostenstellen entfernt werden?">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.form.replaceCostCenters}
                onChange={(event) =>
                  setValue('replaceCostCenters', event.target.checked)
                }
                name="replaceCostCenters"
              />
            }
            label="Bisher berechtigte Kostenstellen entfernen"
          />
        </LightTooltipWide>
        <Divider className="mt-4" />
        <LightTooltipWide
          title={
            hasMultipleIssuersOrIdentifiers() ? (
              'Verschiedene Lieferortbezeichnungen können nicht gleichzeitig einem Standort zugeordnet werden.'
            ) : (
              <span>
                Sollen Lieferungen mit der Lieferortbezeichnung{' '}
                <span className="mx-1 font-bold">{toSiteSupplierNames[0]}</span>{' '}
                zukünftig auch zu dem ausgewählten Lieferort zugeordnet werden?
              </span>
            )
          }
        >
          <FormControlLabel
            className="mt-2"
            control={
              <Checkbox
                checked={state.form.shouldAddIdentifierToSite}
                onChange={(e) =>
                  setValue('shouldAddIdentifierToSite', e.target.checked)
                }
                name="shouldAddIdentifierToSite"
                disabled={hasMultipleIssuersOrIdentifiers()}
              />
            }
            label="Zukünftige Lieferungen mit dieser Lieferortbezeichnung dem Standort zuordnen"
          />
        </LightTooltipWide>
        {state.form.shouldAddIdentifierToSite && (
          <div>
            <LightTooltipWide title="Eine Zuordnung basiert normalerweise auf der vollständigen Adresse des Orts. Wenn die Adresse ignoriert wird, wird nur die Bezeichnung des Orts in der Zuordnung verwendet.">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.form.ignoreAddressInfo}
                    onChange={(event) =>
                      setValue('ignoreAddressInfo', event.target.checked)
                    }
                    name="ignoreAddressInfo"
                    disabled={!state.form.shouldAddIdentifierToSite}
                  />
                }
                label="Nur Lieferort-Bezeichnung verwenden und -Adresse ignorieren für Zuordnung"
              />
            </LightTooltipWide>
          </div>
        )}
      </BasicForm>
    );
  },
  null,
);
