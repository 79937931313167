import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import UserAction from '~/models/userActions/UserAction';

import { type UUID } from '~/types/common';

const selectUserActions = (state) => state.userinfo.userActions;

export const useUsersRequestedPrior = (deliveryNoteIds: UUID[]) => {
  const userActions = useSelector(selectUserActions);

  return useMemo(() => {
    const deliveryNoteUserActions = deliveryNoteIds
      .map((id) => userActions[id])
      .filter(Boolean);

    return UserAction.getRequestedUsers(deliveryNoteUserActions);
  }, [deliveryNoteIds, userActions]);
};
