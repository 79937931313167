import clsx from 'clsx';
import { Button } from '@mui/material';

import {
  LightTooltip,
  MissingPermissionsTooltip,
} from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

import { Spinner } from '~/components/Spinner';

export const GrantPermissionButton = ({
  className,
  onClick,
  disabled,
  deleting,
  style,
}) => {
  if (!UserUtils.isPermissionGrantAllowedUser()) {
    return (
      <MissingPermissionsTooltip>
        <Button
          variant="outlined"
          color="primary"
          className={clsx('mt-2', className)}
          onClick={onClick}
          style={style}
          disabled
        >
          Berechtigungen vergeben
        </Button>
      </MissingPermissionsTooltip>
    );
  }

  return (
    <LightTooltip title="Neue Benutzer oder Benutzer-Gruppen berechtigen.">
      <Button
        variant="outlined"
        color="primary"
        className={clsx('mt-2', className)}
        onClick={onClick}
        disabled={disabled}
        style={style}
      >
        {deleting ? (
          <Spinner title="Berechtigungen löschen..." />
        ) : (
          'Berechtigungen vergeben'
        )}
      </Button>
    </LightTooltip>
  );
};
