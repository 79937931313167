import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import Log from '~/utils/Log';

import ToastService from './toast.service';

class MasterDataService {
  propertiesAreMissing(body, properties, productAnalyticsFeature) {
    let propertiesAreMissing = false;

    for (const property of properties) {
      if (body[property]) {
        continue;
      }

      propertiesAreMissing = true;

      switch (property) {
        case 'company_id': // legacy
        case 'companyId': {
          ToastService.warning(['Bitte wähle eine Firma aus.']);

          Log.productAnalyticsEvent(
            'Missing company',
            productAnalyticsFeature,
            Log.TYPE.FAILED_VALIDATION,
          );
          break;
        }

        default: {
          Log.error(
            null,
            new EnumValueNotFoundException(`Invalid property: ${property}`),
          );

          return false;
        }
      }
    }

    return propertiesAreMissing;
  }
}

export default new MasterDataService();
