import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import CompanyService from '~/services/company.service';
import CostCenterService from '~/services/costCenter.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import PermissionGrantService from '~/services/permissionGrant.service';
import SiteService from '~/services/site.service';
import UserGroupService from '~/services/userGroup.service';
import UserService from '~/services/user.service';
import VehicleService from '~/services/vehicle.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import PromiseUtils from '~/utils/promiseUtils';

import { withErrorBoundary } from '~/ui/atoms';
import { MultiSelectUsers } from '~/ui/molecules/SelectServerDriven';

import { Spinner } from '~/components/Spinner';

export const DeletePermissions = withErrorBoundary(
  forwardRef(
    (
      {
        deletePermissions,
        pickedSubjects,
        setDeletePermissions,
        setPickedSubjects,
      },
      ref,
    ) => {
      const [deleteAllPermissionGrants, setDeleteAllPermissionGrants] =
        useState(false);
      const [
        deletePermissionsFromCostCenters,
        setDeletePermissionsFromCostCenters,
      ] = useState(true);
      const [permissionGrants, setPermissionGrants] = useState([]);
      const [selectedPermissionGrants, setSelectedPermissionGrants] = useState(
        [],
      );

      const [permissionGrantsAreLoading, setPermissionGrantsAreLoading] =
        useState(false);

      useEffect(() => {
        if (deletePermissionsFromCostCenters !== undefined) {
          initPermissionGrants();
        }
      }, [pickedSubjects, deletePermissionsFromCostCenters]);

      useImperativeHandle(ref, () => ({
        async submit() {
          const promises = [];

          for (const permissionGrantId of selectedPermissionGrants) {
            const permissionGrant = permissionGrants.find(
              ({ id }) => id === permissionGrantId,
            );

            const promise = PermissionGrantService.deletePermissionGrant(
              // TODO VGS-6993: change to useMutationDeletePermissionGrant
              permissionGrant.id,
            );
            promises.push(promise);

            // Delete all permissions from the corresponding cost centers.
            for (const permissionGrantCostCenter of permissionGrant.permissionGrantCostCenters) {
              const promise = PermissionGrantService.deletePermissionGrant(
                // TODO VGS-6993: change to useMutationDeletePermissionGrant
                permissionGrantCostCenter.id,
              );
              promises.push(promise);
            }
          }

          return PromiseUtils.allResolved(promises);
        },
      }));

      const initPermissionGrants = async () => {
        setPermissionGrantsAreLoading(true);

        const newPermissionGrants = [];

        for (const subjectId of pickedSubjects) {
          // We need to load the subject to get the permissions that the user has been granted.
          // As we currently only allow permissions to be granted to and deleted from users, we know that subjects can only be users.
          const [user, error] = await promiseHandler(
            UserService.getUser(subjectId, true), // TODO VGS-6993: change to useQueryUser
          );

          if (error) {
            Log.error('Failed to load user. id: ' + subjectId);
            continue;
          }

          // Ignore role 'Mitarbeiter' as we assume that users don't want to delete Mitarbeiter role in daily usage.
          const filteredPermissionGrants = user.permissionGrantsOn.filter(
            (permissionGrant) =>
              permissionGrant.getDefaultRoleName() !==
              Permissions.DEFAULT_ROLE.EMPLOYEE.NAME,
          );

          for (const permissionGrant of filteredPermissionGrants) {
            let loadItemCallback = null;

            switch (permissionGrant.entityType) {
              case PermissionGrant.ENTITY_TYPE.USER.KEY: {
                loadItemCallback = UserService.getUserById;
                break;
              }

              case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
                loadItemCallback = SiteService.getSiteById;
                break;
              }

              case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
                loadItemCallback = CostCenterService.getCostCenterById;
                break;
              }

              case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
                loadItemCallback = VehicleService.getVehicleById;
                break;
              }

              case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
                loadItemCallback = CompanyService.getCompanyById;
                break;
              }

              case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
                loadItemCallback =
                  OrganisationalGroupService.getOrganisationalGroupById;
                break;
              }

              case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
                loadItemCallback = UserGroupService.getUserGroupById;
                break;
              }

              default: {
                Log.error(
                  null,
                  new EnumValueNotFoundException(
                    'Invalid entity type: ' + permissionGrant.entityType,
                  ),
                );
                continue;
              }
            }

            const [entity, error2] = await promiseHandler(
              loadItemCallback(permissionGrant.entityId),
            );

            if (error2) {
              Log.error(
                'Failed to load entity. id: ' + permissionGrant.entityId,
              );
              continue;
            }

            permissionGrant.userEmail = user.email;
            permissionGrant.entityName = entity?.name ?? ''; // The ? and ?? operators are necessary because it can be that the user doesn't have access to read the specific entity.
            if (
              permissionGrant.entityType ===
              PermissionGrant.ENTITY_TYPE.USER.KEY
            ) {
              permissionGrant.entityName = entity?.email ?? '';
            }

            if (
              permissionGrant.entityType ===
              PermissionGrant.ENTITY_TYPE.VEHICLE.KEY
            ) {
              permissionGrant.entityName = entity?.licensePlate?.name ?? '';
            }

            permissionGrant.entity = entity;

            newPermissionGrants.push(permissionGrant);
          }
        }

        let sortedPermissionGrants = newPermissionGrants.sort(
          (a, b) =>
            a.userEmail.localeCompare(b.userEmail) ||
            b.entityType.localeCompare(a.entityType) || // Reverse the order of entity types because we want to access sites before cost centers.
            a.entityName.localeCompare(b.entityName) ||
            a.getDefaultRoleName().localeCompare(b.getDefaultRoleName()),
        );

        for (const permissionGrant of sortedPermissionGrants) {
          let permissionGrantCostCenters = [];

          // If sites and connected cost centers should both be deleted, search for the corresponding cost centers, add them to the permission grant and remove them from the list.
          if (
            deletePermissionsFromCostCenters &&
            permissionGrant.entityType === PermissionGrant.ENTITY_TYPE.SITE.KEY
          ) {
            // Search for the corresponding cost centers and add them to the permission grant.
            permissionGrantCostCenters = sortedPermissionGrants.filter(
              (permissionGrantCostCenter) =>
                permissionGrant.entity?.costCenters?.includes(
                  permissionGrantCostCenter.entityId,
                ) &&
                permissionGrant.subjectId ===
                  permissionGrantCostCenter.subjectId &&
                JSON.stringify(permissionGrant.permissions) ===
                  JSON.stringify(permissionGrantCostCenter.permissions),
            );

            // Remove the connected cost centers from the list.
            sortedPermissionGrants = sortedPermissionGrants.filter(
              (permissionGrant) =>
                !permissionGrantCostCenters.some(
                  (permissionGrantCostCenter) =>
                    permissionGrantCostCenter.id === permissionGrant.id,
                ),
            );
          }

          permissionGrant.permissionGrantCostCenters =
            permissionGrantCostCenters;

          permissionGrant.label = (
            <>
              <span className="bold">{permissionGrant.userEmail}</span>
              <span className="text-grey600">
                {'\u00A0auf\u00A0' +
                  permissionGrant.getEntityTypeString() +
                  '\u00A0'}
              </span>
              <span className="bold">{permissionGrant.entityName}</span>
              {permissionGrantCostCenters.length > 0 ? (
                <>
                  <span className="text-grey600">
                    {'\u00A0und\u00A0Kostenstelle(n)\u00A0'}
                  </span>
                  <span className="bold">
                    {permissionGrantCostCenters
                      .map(
                        (permissionGrantCostCenter) =>
                          permissionGrantCostCenter.entityName,
                      )
                      .join(', ')}
                  </span>
                </>
              ) : null}
              <span className="text-grey600">{'\u00A0als\u00A0'}</span>
              <span className="font-bold">
                {permissionGrant.getDefaultRoleName()}
              </span>
            </>
          );
        }

        setPermissionGrants(sortedPermissionGrants);
        setPermissionGrantsAreLoading(false);
        // Remove the permission grants from the selected ones that have been implicitly deselected because a user has been deselected.
        setSelectedPermissionGrants(
          selectedPermissionGrants.filter((permissionGrantId) =>
            sortedPermissionGrants.some(({ id }) => id === permissionGrantId),
          ),
        );
      };

      const handleDeletePermissionsRadioChange = (event) => {
        Log.info(
          'Change form value of delete permissions radio button',
          {
            from: deletePermissions,
            to: event.target.value,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change delete permissions radio button in update permissions wizard',
          Log.FEATURE.WIZARD,
        );

        setDeletePermissions(event.target.value === 'delete_permissions_yes');
      };

      const handleDeletePermissionsFromCostCentersRadioChange = (event) => {
        Log.info(
          'Change form value of delete permissions from cost centers radio button',
          {
            from: deletePermissionsFromCostCenters,
            to: event.target.value,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change delete permissions from cost centers radio button in update permissions wizard',
          Log.FEATURE.WIZARD,
        );

        setDeletePermissionsFromCostCenters(
          event.target.value === 'cost_centers_yes',
        );
      };

      const handleChangePickedSubjects = (event) => {
        const newPickedSubjects = event.map((item) => item.id);

        Log.info(
          'Change form value of subjects',
          { from: pickedSubjects, to: newPickedSubjects },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Change subjects', Log.FEATURE.WIZARD);

        setPickedSubjects(newPickedSubjects);
      };

      const handleChangeCheckbox = (event) => {
        if (event.target.name === 'delete_all') {
          Log.info(
            'Change checkbox value of delete_all',
            {
              from: deleteAllPermissionGrants,
              to: !deleteAllPermissionGrants,
            },
            Log.BREADCRUMB.FORM_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Change delete all permissions checkbox in update permissions wizard',
            Log.FEATURE.WIZARD,
          );

          setSelectedPermissionGrants(
            deleteAllPermissionGrants
              ? []
              : permissionGrants.map(({ id }) => id),
          );
          setDeleteAllPermissionGrants(!deleteAllPermissionGrants);
          return;
        }

        if (selectedPermissionGrants.includes(event.target.name)) {
          Log.info(
            'Change checkbox value of to be deleted permission',
            {
              from: deleteAllPermissionGrants,
              to: !deleteAllPermissionGrants,
            },
            Log.BREADCRUMB.FORM_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Activate to be deleted permission checkbox in update permissions wizard',
            Log.FEATURE.WIZARD,
          );

          setSelectedPermissionGrants(
            ArrayUtils.remove([...selectedPermissionGrants], event.target.name),
          );
          setDeleteAllPermissionGrants(false);
        } else {
          Log.info(
            'Change checkbox value of to be deleted permission',
            {
              from: deleteAllPermissionGrants,
              to: !deleteAllPermissionGrants,
            },
            Log.BREADCRUMB.FORM_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Deactivate to be deleted permission checkbox in update permissions wizard',
            Log.FEATURE.WIZARD,
          );

          const newSelectedPermissionGrants = [
            ...selectedPermissionGrants,
            event.target.name,
          ];

          setSelectedPermissionGrants(newSelectedPermissionGrants);
          setDeleteAllPermissionGrants(
            newSelectedPermissionGrants.length === permissionGrants.length,
          );
        }
      };

      const getPermissionGrantCheckbox = ({ id, label }) => (
        <Grid item key={id} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedPermissionGrants.includes(id)}
                name={id}
                onChange={handleChangeCheckbox}
              />
            }
            label={label}
          />
        </Grid>
      );

      return (
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12}>
            <h3 className="main-text mt-0">
              Sollen bestehende Berechtigungen gelöscht werden?
            </h3>
            <RadioGroup
              onChange={handleDeletePermissionsRadioChange}
              row
              value={
                deletePermissions
                  ? 'delete_permissions_yes'
                  : 'delete_permissions_no'
              }
            >
              <FormControlLabel
                className="mr-12"
                control={<Radio />}
                label="Ja"
                value="delete_permissions_yes"
              />
              <FormControlLabel
                control={<Radio />}
                label="Nein"
                value="delete_permissions_no"
              />
            </RadioGroup>
          </Grid>
          {deletePermissions ? (
            <>
              <Grid item xs={12}>
                <h3 className="main-text mt-4">
                  Sollen die Berechtigungen von den jeweils verbundenen
                  Standorten und Kostenstellen gelöscht werden?
                </h3>
                <RadioGroup
                  onChange={handleDeletePermissionsFromCostCentersRadioChange}
                  row
                  value={
                    deletePermissionsFromCostCenters
                      ? 'cost_centers_yes'
                      : 'cost_centers_no'
                  }
                >
                  <FormControlLabel
                    className="mr-12"
                    control={<Radio />}
                    label="Ja (empfohlen)"
                    value="cost_centers_yes"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Nein"
                    value="cost_centers_no"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <h3 className="main-text mt-4">
                  Welche Berechtigungen sollen gelöscht werden?
                </h3>
                <MultiSelectUsers
                  // Currently, we only allow to change the permissions for users as it makes the logic of the wizard simpler.
                  label="Für welche Benutzer sollen Berechtigungen gelöscht werden?"
                  onChange={handleChangePickedSubjects}
                  value={pickedSubjects}
                  fullWidth
                />
                {permissionGrantsAreLoading && <Spinner />}
                {!permissionGrantsAreLoading && permissionGrants.length > 0 && (
                  <Grid className="mt-4" item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={deleteAllPermissionGrants}
                          name="delete_all"
                          onChange={handleChangeCheckbox}
                        />
                      }
                      label="Sollen alle bestehenden Berechtigungen gelöscht werden?"
                    />
                  </Grid>
                )}
                {!permissionGrantsAreLoading &&
                  permissionGrants.map((permissionGrant) =>
                    getPermissionGrantCheckbox(permissionGrant),
                  )}
              </Grid>
            </>
          ) : null}
        </Grid>
      );
    },
  ),
  'Daten konnten nicht geladen werden.',
);

DeletePermissions.displayName = 'DeletePermissions';
