import { type ReactNode } from 'react';
import {
  type GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';

import { withErrorBoundary } from '~/ui/atoms';

import { GridToolbarCustomComponent } from '~/components/interaction/GridToolbarCustomComponent';

type CustomGridToolbarComponent = {
  icon: ReactNode;
  menuItems: ReactNode[];
  title: string;
};

type P = {
  csvOptions?: GridCsvExportOptions;
  customComponents?: CustomGridToolbarComponent[];
};

export const DatagridToolbar = withErrorBoundary(
  ({ csvOptions, customComponents = [] }: P) => {
    return (
      <GridToolbarContainer className="flex gap-2 px-2 pt-2">
        <GridToolbarExport
          csvOptions={csvOptions}
          printOptions={{ disableToolbarButton: true }}
          slotProps={{
            button: { variant: 'outlined' },
          }}
        />
        {customComponents?.length > 0 &&
          customComponents.map(({ icon, menuItems, title }) => (
            <GridToolbarCustomComponent
              key={title}
              title={title}
              icon={icon}
              menuItems={menuItems}
              buttonProps={{
                variant: 'outlined',
              }}
            />
          ))}
      </GridToolbarContainer>
    );
  },
  null,
);
