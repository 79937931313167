import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import Log from '~/utils/Log';

export default class ContactPoint {
  constructor(contactPoint) {
    this.id = contactPoint?.id ?? null;
    this.companyAccountId =
      contactPoint?.company_account_id ??
      contactPoint?.companyAccountId ??
      null;
    this.name = contactPoint?.name ?? null;
    this.integrationType =
      contactPoint?.integration_type ??
      contactPoint?.integrationType ??
      ContactPoint.INTEGRATION_TYPE.EMAIL.KEY;
    this.emailReceivers =
      contactPoint?.email_receivers ?? contactPoint?.emailReceivers ?? [];
    this.dlnFilenamePlaceholder =
      contactPoint?.dln_filename_placeholder ??
      contactPoint?.dlnFilenamePlaceholder ??
      null;
    this.emailSubject =
      contactPoint?.email_subject ?? contactPoint?.emailSubject ?? null;
    this.dataFormat =
      contactPoint?.data_format ??
      contactPoint?.dataFormat ??
      ContactPoint.DATA_FORMAT.ZIP.KEY;
    this.dataGrouping =
      contactPoint?.data_grouping ??
      contactPoint?.dataGrouping ??
      ContactPoint.DATA_GROUPING.NO_GROUPING.KEY;
  }

  static getDifferentValues(contactPointA, contactPointB) {
    const differentValues = [];

    if (contactPointA?.id !== contactPointB?.id) {
      differentValues.push('ID');
    }

    if (contactPointA?.companyAccountId !== contactPointB?.companyAccountId) {
      differentValues.push('Firmenaccount');
    }

    if (contactPointA?.name !== contactPointB?.name) {
      differentValues.push('Name');
    }

    if (contactPointA?.integrationType !== contactPointB?.integrationType) {
      differentValues.push('Übertragungsweg');
    }

    if (
      JSON.stringify(contactPointA?.emailReceivers) !==
      JSON.stringify(contactPointB?.emailReceivers)
    ) {
      differentValues.push('E-Mail Empfänger');
    }

    if (
      contactPointA?.dlnFilenamePlaceholder !==
      contactPointB?.dlnFilenamePlaceholder
    ) {
      differentValues.push('Dateiname');
    }

    if (contactPointA?.emailSubject !== contactPointB?.emailSubject) {
      differentValues.push('Betreffzeile');
    }

    if (contactPointA?.dataFormat !== contactPointB?.dataFormat) {
      differentValues.push('Dateityp');
    }

    if (contactPointA?.dataGrouping !== contactPointB?.dataGrouping) {
      differentValues.push('Gruppierung');
    }

    return differentValues;
  }

  static isValid(contactPoint) {
    if (
      contactPoint.dataGrouping === ContactPoint.DATA_GROUPING.COMBINED.KEY &&
      contactPoint.dlnFilenamePlaceholder
    ) {
      return [
        false,
        'Dateiname darf nicht gesetzt sein, wenn Lieferscheine gruppiert werden.',
      ];
    }

    return [true, null];
  }

  static getDataFormatString(key) {
    const dataFormat = Object.keys(ContactPoint.DATA_FORMAT).find(
      (x) => ContactPoint.DATA_FORMAT[x].KEY === key,
    );

    if (!dataFormat) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid data format: ' + key),
      );
      return key;
    }

    return ContactPoint.DATA_FORMAT[dataFormat].STRING;
  }

  static getDataGroupingString(key) {
    const dataGrouping = Object.keys(ContactPoint.DATA_GROUPING).find(
      (x) => ContactPoint.DATA_GROUPING[x].KEY === key,
    );

    if (!dataGrouping) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid data grouping: ' + key),
      );
      return key;
    }

    return ContactPoint.DATA_GROUPING[dataGrouping].STRING;
  }

  static INTEGRATION_TYPE = {
    EMAIL: {
      KEY: 'email',
      STRING: 'E-Mail',
    },
  };
  static DATA_FORMAT = {
    PDF: {
      KEY: 'pdf',
      STRING: 'PDF (als Anhang)',
    },
    PDF_JSON: {
      KEY: 'pdf_json',
      STRING: 'PDF und JSON (als Anhang)',
    },
    ZIP: {
      KEY: 'zip',
      STRING: 'PDF in zip (als Anhang)',
    },
    ZIP_JSON: {
      KEY: 'zip_json',
      STRING: 'JSON in zip (als Anhang)',
    },
    ZIP_PDF_JSON: {
      KEY: 'zip_pdf_json',
      STRING: 'PDF und JSON in zip (als Anhang)',
    },
  };
  static DATA_GROUPING = {
    COMBINED: {
      KEY: 'combined',
      STRING: 'Alle Lieferscheine in einer Datei',
    },
    NO_GROUPING: {
      KEY: 'no_grouping',
      STRING: 'Jeder Lieferschein als eigene Datei',
    },
  };
}
