import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '~/constants/LoadingState';
import ArrayUtils from '~/utils/arrayUtils';

const vehiclesSlice = createSlice({
  initialState: {
    vehicles: [],
    vehiclesLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'vehicles',
  reducers: {
    replaceVehicle(state, { payload }) {
      const newVehicles = ArrayUtils.removeByKey(
        state.vehicles,
        'id',
        payload.id,
      );
      newVehicles.push(payload);

      state.vehicles = newVehicles;
    },
    replaceVehicles(state, { payload }) {
      state.vehicles = ArrayUtils.sortByNestedKey(payload, [
        'licensePlate',
        'name',
      ]);
      state.vehiclesLoading = LOADING_STATE.SUCCEEDED;
    },
    setVehiclesLoading(state, { payload }) {
      state.vehiclesLoading = payload;
    },
  },
});

const { actions, reducer } = vehiclesSlice;
export const { replaceVehicle, replaceVehicles, setVehiclesLoading } = actions;
export default reducer;
