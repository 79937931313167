import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import Log from '~/utils/Log';

import { queryKeysCustomFields } from './queryKeys';

export type CustomFieldsQueryParams = {
  language?: string;
};

export type CustomFieldsListItem = {
  name: string;
  displayName: string;
  level: string;
  type: string;
  helpText: string;
  sorting: number;
  required: boolean;
  public: boolean;
  visibility: string;
  hasOptions: boolean;
  allowedUnits: string | undefined;
  defaultUnit: string | undefined;
  usedBy: string[];
  id: string;
};

type CustomFieldsResponse = {
  data: readonly CustomFieldsListItem[];
  count: number;
};

const defaultQueryParams: CustomFieldsQueryParams = {
  language: undefined,
};

/**
 * Generates the query options for the CustomFields query so they can be shared between the useQuery hook and the prefetching.
 */
export const getCustomFieldsQueryOptions = ({
  queryParams,
  options = {},
}: {
  queryParams: Partial<CustomFieldsQueryParams>;
  options?: Omit<
    UseQueryOptions<CustomFieldsResponse, Error>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryFn: async () => fetchCustomFields(qp),
    queryKey: queryKeysCustomFields.getAll(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all CustomFields from the API.
 * @param queryParams - The query parameters for fetching CustomFields.
 * @returns The category data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/get_CustomFields_article_master_category_get
 */
export const fetchCustomFields = async (
  queryParams: Partial<CustomFieldsQueryParams>,
): Promise<CustomFieldsResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  for (const key of Object.keys(qp)) {
    if (qp[key as keyof CustomFieldsQueryParams] === undefined) {
      delete qp[key as keyof CustomFieldsQueryParams];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.CUSTOM_FIELD.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<CustomFieldsResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching CustomFields', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all CustomFields with given query parameters.
 * Handles pagination and prefetches the next page of CustomFields for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching CustomFields.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryCustomFields = (
  queryParams: Partial<CustomFieldsQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getCustomFieldsQueryOptions({
        options,
        queryParams,
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery<CustomFieldsResponse, Error>({
    ...getCustomFieldsQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all CustomFields with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching CustomFields.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQueryCustomFieldsInfinite = (
  queryParams: Partial<CustomFieldsQueryParams>,
  options: InfiniteQueryOptionsType<CustomFieldsResponse, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async () =>
      fetchCustomFields({
        ...qp,
      }),
    queryKey: queryKeysCustomFields.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<CustomFieldsResponse>(qp, infiniteQueryOptions);
};
