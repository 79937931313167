import React from 'react';

import { Check as CheckIcon, Loop as LoopIcon } from '@mui/icons-material';
import { Dialog, DialogContent, Grid, InputLabel, Button } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';

import TextField from '../deliveryNoteForm/TextField';
import DatePicker from '../../baseComponents/inputs/date/DatePicker';
import CompanySelect from '../deliveryNoteForm/CompanySelect';

import ToastService from '~/services/toast.service';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import cloneDeep from 'lodash/cloneDeep';
import DeliveriesService from '~/services/deliveries.service';
import Article from '~/models/articles/Article';
import { v4 as uuidv4 } from 'uuid';
import BasicForm from '../../BasicForm';
import { connect } from 'react-redux';
import TemplateMenu from '../deliveryNoteForm/TemplateMenu';
import { promiseHandler } from '~/utils/promiseHandler';
import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import Company from '~/models/masterdata/Company';
import UnitUtils from '~/utils/unitUtils';
import { Spinner } from '../../Spinner';
import { setPageTitle } from '~/redux/menuSlice';
import UniversalCommunication from '~/models/masterdata/UniversalCommunication';
import { dateUtils } from '~/utils/dateUtils';
import UserUtils from '~/utils/userUtils';
import ServiceList from './ServiceList';

import { isInteger } from '~/utils/number';

const mapStateToProps = (state) => ({
  deliveryNoteTemplates: state.deliveryNotes.deliveryNoteTemplates,
  userinfo: state.userinfo,
});
const mapDispatchToProps = () => ({
  setPageTitle,
});

class ServiceNoteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articlesError: null,

      // article templates
      articleTemplates: [],

      buyerOptions: [],

      carrierOptions: [],

      costCentersError: null,

      createdTemplate: null,

      // needed so that deselecting companies, doesn't remove them from dropdown options
      creatingDeliveryNote: false,

      deliveryNote: new DeliveryNote(),

      importedDeliveryNote: new DeliveryNote(),

      licensePlateError: null,

      newTemplateName: '',

      numberError: null,

      recipientError: null,

      recipientOptions: [],

      // dln templates
      selectedTemplate: null,

      sellerOptions: [],

      submittingTemplateForm: false,

      // errors
      supplierError: null,

      // dropdown options
      supplierOptions: [],

      supportModalOpen: false,

      templateModalOpen: false,
      templateSaved: false,
      toSiteError: null,
      updatingTemplate: false,
    };

    this.DELIVERY_TYPES = [
      {
        id: DeliveryNote.INCOTERMS.EXW.DESCRIPTION,
        name: DeliveryNote.INCOTERMS.EXW.DESCRIPTION,
      },
      {
        id: DeliveryNote.INCOTERMS.DPU.DESCRIPTION,
        name: DeliveryNote.INCOTERMS.DPU.DESCRIPTION,
      },
    ];

    this.ERROR_MESSAGES = {
      INVALID_FORMAT: 'Format ungültig',
      REQUIRED: 'Pflichtfeld',
    };

    this.TEMPLATE_TYPES = {
      DEFAULT: 'default',
      DELIVERY_NOTE: 'delivery-note',
      TEMPLATE: 'template',
    };

    this.SUBMIT_TYPES = {
      DELIVERY_NOTE: 'delivery-note',
      TEMPLATE: 'template',
    };
  }

  componentDidMount() {
    this.initCompanyOptions();
    this.initDeliveryNote();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.state.deliveryNote) !==
      JSON.stringify(prevState.deliveryNote)
    ) {
      this.setState({
        templateSaved: false,
      });
    }

    if (
      JSON.stringify(this.props.deliveryNoteTemplates) !==
        JSON.stringify(prevProps.deliveryNoteTemplates) &&
      this.state.createdTemplate
    ) {
      const selectedTemplate = this.props.deliveryNoteTemplates.find(
        (template) => template.id === this.state.createdTemplate,
      );

      if (selectedTemplate) {
        this.setState({
          selectedTemplate,
        });
      }

      this.setState({
        createdTemplate: null,
      });
    }

    if (
      this.state.deliveryNote.supplier.id !==
        prevState.deliveryNote.supplier.id &&
      this.state.deliveryNote.supplier.id
    ) {
      this.loadArticleTemplates();
    }

    this.props.setPageTitle('Leistungsnachweis anlegen');
    document.title = 'VESTIGAS - Leistungsnachweis anlegen';
  }

  initCompanyOptions() {
    DeliveriesService.getBulkAllowedCompaniesForDeliveryNoteCreation().then(
      (response) => {
        this.setState({
          buyerOptions: response.buyers,
          carrierOptions: response.carriers,
          recipientOptions: response.recipients,
          sellerOptions: response.sellers,
          supplierOptions: response.suppliers,
        });

        const newDeliveryNote = cloneDeep(this.state.deliveryNote);

        // If only one supplier is selectable, the supplier is preselected automatically
        if (!newDeliveryNote.supplier.id && response.suppliers.length === 1) {
          newDeliveryNote.supplier = response.suppliers[0];
        }

        if (!newDeliveryNote.recipient.id && response.recipients.length === 1) {
          newDeliveryNote.recipient = response.recipients[0];
        }

        this.setState({
          deliveryNote: newDeliveryNote,
        });
      },
    );
  }

  initDeliveryNote() {
    let deliveryNote = this.props.history.location.state?.passDeliveryNote
      ? DeliveriesService.getDeliveryNoteForCreationForm()
      : null;
    let type = this.TEMPLATE_TYPES.DELIVERY_NOTE;

    const template = cloneDeep(this.props.history.location.state?.template);

    this.resetHistory(); // so that no template is selected when user refreshes the page

    if (template) {
      deliveryNote = new DeliveryNote({
        asset_state: { body: template?.data?.template },
      });
      type = this.TEMPLATE_TYPES.TEMPLATE;

      this.setState({
        selectedTemplate: template,
      });
    }

    if (!deliveryNote) {
      deliveryNote = new DeliveryNote();
      type = this.TEMPLATE_TYPES.DEFAULT;
    }

    const defaultDeliveryNote = this.getDefaultDeliveryNote(deliveryNote, type);

    this.setState({
      deliveryNote: defaultDeliveryNote,
      importedDeliveryNote: cloneDeep(defaultDeliveryNote),
    });
  }

  getDefaultDeliveryNote(deliveryNote, type) {
    // every new dln should have a new number and is expected to be today
    deliveryNote.dlnDate = new Date();
    deliveryNote.number = '';

    // If only one supplier is selectable, the supplier is preselected automatically
    if (!deliveryNote.supplier.id && this.state.supplierOptions.length === 1) {
      deliveryNote.supplier = this.state.supplierOptions[0];
    }

    if (
      !deliveryNote.recipient.id &&
      this.state.recipientOptions.length === 1
    ) {
      deliveryNote.recipient = this.state.recipientOptions[0];
    }

    // When article has only been used to store cost center, the article is thrown away after reading the cost center from it
    if (
      type === this.TEMPLATE_TYPES.TEMPLATE &&
      deliveryNote.costCenters.length > 0 &&
      deliveryNote.articles.length === 1 &&
      !this.articleIsSet(deliveryNote.articles[0])
    ) {
      deliveryNote.articles = [];
    }

    for (const article of deliveryNote.articles) {
      article.id = article.id ?? uuidv4();
      article.error = this.getDefaultArticle().error;

      // If weight is given but not quantity, map weight to quantity
      if (!article.quantity.unit && article.weight.unit) {
        article.quantity = article.weight;
        article.weight = Article.getEmptyValue();
      }

      article.quantity.value = '';
      article.weight.value = '';
      article.weighingInformation.gross.weight.value = '';
      article.weighingInformation.tare.weight.value = '';
    }

    // Every dln should have at least one empty row so that the user can directly edit
    if (deliveryNote.articles.length === 0) {
      deliveryNote.articles.push(
        this.getDefaultArticle(),
        this.getDefaultArticle(),
        this.getDefaultArticle(),
      );
    } else {
      deliveryNote.articles.push(this.getDefaultArticle());
    }

    return deliveryNote;
  }

  getDefaultArticle() {
    const article = new Article();

    article.id = uuidv4();
    article.error = {
      number: false,
      quantityUnit: false,
      quantityValue: false,
      type: false,
      weightUnit: false,
      weightValue: false,
    };

    article.number = ''; // overwrite 0 default
    article.manufacturer.address.country = ''; // overwrite DE default

    return article;
  }

  resetHistory() {
    const { history, location } = this.props;
    history.replace();
  }

  loadArticleTemplates() {
    DeliveriesService.getAllArticleTemplates(
      this.state.deliveryNote.supplier.id,
    ).then((response) => {
      this.setState({
        articleTemplates: ArrayUtils.sortByKey(
          response,
          'modified_on',
          true,
        ).map((item) => {
          item.article = {
            article_amount_unit: item.article.article_amount_unit ?? '',
            article_ean: item.article.article_ean
              ? String(item.article.article_ean)
              : '',
            article_name: item.article.article_name ?? '',
            article_nr: item.article.article_nr ?? '',
            article_weight_unit: item.article.article_weight_unit ?? '',
            manufacturer_city: item.article.manufacturer_city ?? '',
            manufacturer_country: item.article.manufacturer_country ?? '',
            manufacturer_house_number:
              item.article.manufacturer_house_number ?? '',
            manufacturer_name: item.article.manufacturer_name ?? '',
            manufacturer_post_code: item.article.manufacturer_post_code ?? '',
            manufacturer_street: item.article.manufacturer_street ?? '',
          };

          return item;
        }),
      });
    });
  }

  handleTemplateSelect = (id) => {
    Log.productAnalyticsEvent(
      'Select template',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    const selectedTemplate = cloneDeep(
      this.props.deliveryNoteTemplates.find((template) => template.id === id),
    );

    if (!selectedTemplate) {
      Log.error('Failed to find template by id. id: ' + id);
      return;
    }

    const deliveryNote = new DeliveryNote({
      asset_state: { body: selectedTemplate?.data?.template },
    });
    const defaultDeliveryNote = this.getDefaultDeliveryNote(
      deliveryNote,
      this.TEMPLATE_TYPES.TEMPLATE,
    );

    this.setState({
      deliveryNote: defaultDeliveryNote,
      importedDeliveryNote: cloneDeep(defaultDeliveryNote),
      selectedTemplate,
    });
  };
  handleDeliveryNoteSelect = (deliveryNote) => {
    const defaultDeliveryNote = this.getDefaultDeliveryNote(
      deliveryNote,
      this.TEMPLATE_TYPES.DELIVERY_NOTE,
    );

    this.setState({
      deliveryNote: defaultDeliveryNote,
      importedDeliveryNote: cloneDeep(defaultDeliveryNote),
      selectedTemplate: null,
    });
  };
  handleChangeSupplier = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.supplier.id = event.target.value;

    this.setState({
      deliveryNote: newDeliveryNote,
      supplierError: null,
    });
  };
  handleChangeRecipient = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.recipient.id = event.target.value;

    this.setState({
      deliveryNote: newDeliveryNote,
      recipientError: null,
    });
  };
  handleChangeNumber = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.number = event.target.value;

    this.setState({
      deliveryNote: newDeliveryNote,
      numberError: null,
    });
  };
  handleChangeDate = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.dlnDate = event;

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  handleChangeHours = (event) => {
    if (
      !/\d$/.test(event.target.value) &&
      !/[0-5]\d$/.test(event.target.value)
    ) {
      return;
    }

    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.dlnDate.setHours(event.target.value);

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  handleChangeMinutes = (event) => {
    if (
      !/\d$/.test(event.target.value) &&
      !/[0-5]\d$/.test(event.target.value)
    ) {
      return;
    }

    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.dlnDate.setMinutes(event.target.value);

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  handleChangeToSite = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.toSiteSupplier.name = event.target.value;

    this.setState({
      costCentersError: null,
      deliveryNote: newDeliveryNote,
      toSiteError: null,
    });
  };
  handleChangeCostCenters = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.costCenters = [event.target.value];

    this.setState({
      costCentersError: null,
      deliveryNote: newDeliveryNote,
      toSiteError: null,
    });
  };
  handleChangeBuyerOrderReferences = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.buyerOrderReferences = event.target.value;

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  handleChangeComments = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.comments[0] = event.target.value;

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  handleChangeProject = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.project = event.target.value;

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  handleChangeConstructionPlans = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.constructionPlans = event.target.value;

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  handleChangeConstructionComponents = (event) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.constructionComponents = event.target.value;

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  handleChangeArticles = (articles) => {
    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.articles = articles.map((article) => {
      article.error = {
        number: false,
        quantityUnit: false,
        quantityValue: false,
        type: false,
        weightUnit: false,
        weightValue: false,
      };

      return article;
    });

    this.setState({
      articlesError: null,
      deliveryNote: newDeliveryNote,
    });
  };
  addArticle = () => {
    Log.productAnalyticsEvent('Add article', Log.FEATURE.CREATE_DELIVERY_NOTE);

    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.articles.push(this.getDefaultArticle());

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  removeArticle = (id) => {
    Log.productAnalyticsEvent(
      'Remove article',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    const index = newDeliveryNote.articles.findIndex(
      (article) => article.id === id,
    );

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    newDeliveryNote.articles.splice(index, 1);

    this.setState({
      deliveryNote: newDeliveryNote,
    });
  };
  createDeliveryNote = async () => {
    if (!this.dlnIsValid()) {
      ToastService.error([
        'Der Leistungsnachweis konnte nicht erstellt werden, da entweder Angaben fehlen oder ungültig sind.',
        'Bitte überprüfen deine Angaben und versuche es erneut.',
      ]);
      Log.productAnalyticsEvent(
        'Missing inputs',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    this.setState({
      creatingDeliveryNote: true,
    });

    const [response, error] = await promiseHandler(
      DeliveriesService.createDeliveryNote(
        this.getDeliveryNoteInBackendFormat(this.SUBMIT_TYPES.DELIVERY_NOTE),
      ),
    );

    if (error) {
      if (error.response?.status === 409) {
        Log.productAnalyticsEvent(
          'Duplicate number',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        ToastService.error([
          'LFS-Nr. bereits vorhanden. Bitte gib eine andere Lieferscheinnummer ein und versuche es erneut.',
        ]);
        Log.productAnalyticsEvent(
          'Duplicate LFS-Nr.',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
          Log.TYPE.FAILED_VALIDATION,
        );

        this.setState({
          numberError: 'LFS-Nr. bereits vorhanden.',
        });

        this.setState({
          creatingDeliveryNote: false,
        });
        return;
      }

      Log.productAnalyticsEvent(
        'Error during submit',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );
      ToastService.error([
        'Leistungsnachweis konnte nicht erstellt werden wegen eines internen Fehlers.',
        ToastService.MESSAGE.CONTACT_SUPPORT,
      ]);
      Log.productAnalyticsEvent(
        'Failed to create delivery note',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      this.setState({
        creatingDeliveryNote: false,
      });
      return;
    }

    this.setState({
      creatingDeliveryNote: false,
    });

    Log.productAnalyticsEvent('Submit', Log.FEATURE.CREATE_DELIVERY_NOTE);
    ToastService.success(['Leistungsnachweis wurde erstellt.']);

    const articles = this.state.deliveryNote.articles
      .filter((article) => this.articleIsSet(article))
      .map((article) => {
        return {
          article_amount_unit: article.quantity.unit,
          article_ean: UnitUtils.parseToNumber(article.ean),
          article_name: article.type,
          article_nr: article.number,
          article_weight_unit: article.weight.unit,
          manufacturer_city: article.manufacturer.address.city,
          manufacturer_country: article.manufacturer.address.country,
          manufacturer_house_number:
            article.manufacturer.address.buildingNumber,
          manufacturer_name: article.manufacturer.name,
          manufacturer_post_code: article.manufacturer.address.postCode,
          manufacturer_street: article.manufacturer.address.streetName,
        };
      });

    const [response2, error2] = await promiseHandler(
      DeliveriesService.updateArticleTemplate(
        this.state.deliveryNote.supplier.id,
        articles,
      ),
    );

    if (error2) {
      return;
    }

    this.loadArticleTemplates();
  };
  saveTemplate = (e) => {
    e.preventDefault();

    this.setState({
      submittingTemplateForm: true,
    });

    Log.productAnalyticsEvent(
      'Submit template form',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    DeliveriesService.createTemplate(
      this.state.newTemplateName,
      this.props.userinfo.userinfo.company?.id,
      this.getDeliveryNoteInBackendFormat(this.SUBMIT_TYPES.TEMPLATE),
    )
      .then((response) => {
        this.setState({
          createdTemplate: response?.data?.id,
          newTemplateName: '',
          submittingTemplateForm: false,
          templateModalOpen: false,
          templateSaved: true,
        });

        DeliveriesService.refreshTemplates();
      })
      .catch((error) => {
        ToastService.error([
          'Vorlage konnte nicht gespeichert werden wegen eines internen Fehlers.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        Log.productAnalyticsEvent(
          'Failed to create template',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingTemplateForm: false,
        });
      });
  };
  abortTemplate = () => {
    Log.productAnalyticsEvent(
      'Abort template form',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );
    this.setState({
      templateModalOpen: false,
    });
  };
  updateTemplate = () => {
    this.setState({
      updatingTemplate: true,
    });

    Log.productAnalyticsEvent(
      'Update template',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    DeliveriesService.updateTemplate(
      this.state.selectedTemplate.name,
      this.state.selectedTemplate.id,
      this.getDeliveryNoteInBackendFormat(this.SUBMIT_TYPES.TEMPLATE),
    )
      .then((response) => {
        this.setState({
          templateSaved: true,
          updatingTemplate: false,
        });

        DeliveriesService.refreshTemplates();
      })
      .catch((error) => {
        ToastService.error([
          'Vorlage konnte nicht aktualisiert werden wegen eines internen Fehlers.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ]);
        Log.productAnalyticsEvent(
          'Failed to update template',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        this.setState({
          updatingTemplate: false,
        });
      });
  };

  getDeliveryNoteInBackendFormat(type) {
    const deliveryNote = cloneDeep(this.state.deliveryNote);

    deliveryNote.supplier = this.getSupplierOptions().find(
      (company) => company.id === this.state.deliveryNote.supplier.id,
    );
    // deliveryNote.carrier = this.getCarrierOptions().find(company => company.id === this.state.deliveryNote.carrier.id);
    deliveryNote.recipient = this.getRecipientOptions().find(
      (company) => company.id === this.state.deliveryNote.recipient.id,
    );

    deliveryNote.issuer = deliveryNote.supplier;

    // the freight forwarder can be mapped from the supplier or recipient based on the Incoterm
    if (deliveryNote.deliveryType === DeliveryNote.INCOTERMS.EXW.DESCRIPTION) {
      deliveryNote.freightForwarder = deliveryNote.recipient;
    } else if (
      deliveryNote.deliveryType === DeliveryNote.INCOTERMS.DPU.DESCRIPTION
    ) {
      deliveryNote.freightForwarder = deliveryNote.supplier;
    } else {
      deliveryNote.freightForwarder = new Company();
    }

    if (this.state.deliveryNote.seller.id) {
      deliveryNote.seller = this.getSellerOptions().find(
        (company) => company.id === this.state.deliveryNote.seller.id,
      );
    } else if (type === this.SUBMIT_TYPES.DELIVERY_NOTE) {
      deliveryNote.seller = deliveryNote.supplier;
    }

    if (this.state.deliveryNote.buyer.id) {
      deliveryNote.buyer = this.getBuyerOptions().find(
        (company) => company.id === this.state.deliveryNote.buyer.id,
      );
    } else if (type === this.SUBMIT_TYPES.DELIVERY_NOTE) {
      deliveryNote.buyer = deliveryNote.recipient;
    }

    // Because license plate has already been validated, the separators are exchanged by space
    // deliveryNote.carrierLicensePlate = deliveryNote.carrierLicensePlate.split('-').join(' ').split('/').join(' ');

    deliveryNote.articles = deliveryNote.articles.filter((article) =>
      this.articleIsSet(article),
    );

    // The weight and quantity need to be mapped to the correct fields
    for (const article of deliveryNote.articles) {
      if (
        article.quantity.unit &&
        UnitUtils.isWeightUnit(article.quantity.unit)
      ) {
        article.weight = article.quantity;
        article.quantity = Article.getEmptyValue();
      }
    }

    // handle if submitted quantity or weight is only the separator -> could be refactored as this special case shouldn't be handled individually
    for (const article of deliveryNote.articles) {
      if (article.quantity.value === ',') {
        article.quantity.value = '0';
      }

      if (article.weight.value === ',') {
        article.weight.value = '0';
      }
    }

    // If only units are set, the values must be set to a default value, otherwise both value and unit are set to null in deliveryNote.getBackendFormat()
    if (type === this.SUBMIT_TYPES.TEMPLATE) {
      for (const article of deliveryNote.articles) {
        if (article.weight.unit && !article.weight.value) {
          article.weight.value = '1';
        }

        if (article.quantity.unit && !article.quantity.value) {
          article.quantity.value = '1';
        }
      }
    }

    // If there is no article where we can store the cost center, we take a new empty one
    if (
      type === this.SUBMIT_TYPES.TEMPLATE &&
      deliveryNote.costCenters.length > 0 &&
      deliveryNote.articles.length === 0
    ) {
      deliveryNote.articles.push(new Article());
    }

    return deliveryNote.getBackendFormat();
  }

  dlnIsValid() {
    let isValid = true;

    if (!this.state.deliveryNote.supplier.id) {
      Log.productAnalyticsEvent(
        'Missing supplier',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      this.setState({
        supplierError: this.ERROR_MESSAGES.REQUIRED,
      });
      isValid = false;
    }

    if (!this.state.deliveryNote.recipient.id) {
      Log.productAnalyticsEvent(
        'Missing recipient',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      this.setState({
        recipientError: this.ERROR_MESSAGES.REQUIRED,
      });
      isValid = false;
    }

    if (!this.state.deliveryNote.number) {
      Log.productAnalyticsEvent(
        'Missing number',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      this.setState({
        numberError: this.ERROR_MESSAGES.REQUIRED,
      });
      isValid = false;
    }

    /* if(this.state.deliveryNote.carrierLicensePlate && !this.licensePlateIsValid()) {
            Log.productAnalyticsEvent('Invalid license plate', Log.FEATURE.CREATE_DELIVERY_NOTE);

            this.setState({
                licensePlateError: this.ERROR_MESSAGES.INVALID_FORMAT
            });
            isValid = false;
        } */

    if (
      !this.state.deliveryNote.toSiteSupplier.name &&
      this.state.deliveryNote.costCenters.length === 0
    ) {
      Log.productAnalyticsEvent(
        'Missing toSite or cost center',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      this.setState({
        costCentersError:
          'Gib entweder den Lieferort oder die Kostenstelle an.',
        toSiteError: 'Gib entweder den Lieferort oder die Kostenstelle an.',
      });
      isValid = false;
    }

    if (
      this.state.deliveryNote.articles.filter((article) =>
        this.articleIsSet(article),
      ).length === 0
    ) {
      Log.productAnalyticsEvent(
        'Missing article',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      this.setState({
        articlesError: 'Füge mindestens einen Artikel hinzu.',
      });
      isValid = false;
    }

    const newDeliveryNote = cloneDeep(this.state.deliveryNote);

    newDeliveryNote.articles = newDeliveryNote.articles.map((article) => {
      if (!this.articleIsSet(article)) {
        return article;
      }

      const invalidArticle = this.getInvalidArticle(article);

      if (invalidArticle) {
        isValid = false;
        return invalidArticle;
      }

      return article;
    });

    this.setState({
      deliveryNote: newDeliveryNote,
    });

    return isValid;
  }

  articleIsSet(article) {
    return (
      article.number ||
      article.type ||
      article.quantity.value ||
      article.quantity.unit ||
      article.weight.value ||
      article.weight.unit
    );
  }

  getInvalidArticle(article) {
    const newArticle = cloneDeep(article);

    let isValid = true;
    let articlesError = null;

    if (!article.number) {
      Log.productAnalyticsEvent(
        'Missing article number',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      newArticle.error.number = true;
      articlesError ??= this.ERROR_MESSAGES.REQUIRED;
      isValid = false;
    }

    if (!article.type) {
      Log.productAnalyticsEvent(
        'Missing article type',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      newArticle.error.type = true;
      articlesError ??= this.ERROR_MESSAGES.REQUIRED;
      isValid = false;
    }

    if (!article.quantity.value) {
      Log.productAnalyticsEvent(
        'Missing article quantity',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      newArticle.error.quantityValue = true;
      articlesError = this.ERROR_MESSAGES.REQUIRED;
      isValid = false;
    }

    if (!article.quantity.unit) {
      Log.productAnalyticsEvent(
        'Missing article unit',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      newArticle.error.quantityUnit = true;
      articlesError = this.ERROR_MESSAGES.REQUIRED;
      isValid = false;
    }

    const quantity = article.quantity.value
      ? UnitUtils.handleLeadingAndFollowingDelimiter(
          article.quantity.value,
          ',',
        )
      : '';
    const weight = article.weight.value
      ? UnitUtils.handleLeadingAndFollowingDelimiter(article.weight.value, ',')
      : '';
    const gross = article.weighingInformation.gross.weight.value
      ? UnitUtils.handleLeadingAndFollowingDelimiter(
          article.weighingInformation.gross.weight.value,
          ',',
        )
      : '';
    const tare = article.weighingInformation.tare.weight.value
      ? UnitUtils.handleLeadingAndFollowingDelimiter(
          article.weighingInformation.tare.weight.value,
          ',',
        )
      : '';

    if (quantity && !/^\d+,?\d*\b$/.test(quantity)) {
      Log.productAnalyticsEvent(
        'Invalid article quantity',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      ToastService.warning([this.ERROR_MESSAGES.INVALID_FORMAT + ': Menge']);
      isValid = false;
    }

    if (weight && !/^\d+,?\d*\b$/.test(weight)) {
      Log.productAnalyticsEvent(
        'Invalid article weight',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      ToastService.warning([this.ERROR_MESSAGES.INVALID_FORMAT + ': Gewicht']);
      isValid = false;
    }

    if (gross && !/^\d+,?\d*\b$/.test(gross)) {
      Log.productAnalyticsEvent(
        'Invalid article gross weight',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      ToastService.warning([
        this.ERROR_MESSAGES.INVALID_FORMAT + ': Bruttogewicht',
      ]);
      isValid = false;
    }

    if (tare && !/^\d+,?\d*\b$/.test(tare)) {
      Log.productAnalyticsEvent(
        'Invalid article tare weight',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      ToastService.warning([
        this.ERROR_MESSAGES.INVALID_FORMAT + ': Taragewicht',
      ]);
      isValid = false;
    }

    if (article.ean && !isInteger(article.ean)) {
      Log.productAnalyticsEvent(
        'Invalid article ean',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      ToastService.warning([this.ERROR_MESSAGES.INVALID_FORMAT + ': EAN']);
      isValid = false;
    }

    this.setState({
      articlesError,
    });

    if (isValid) {
      return null;
    }

    return newArticle;
  }

  onDeletedTemplate = (id) => {
    if (this.state.selectedTemplate?.id !== id) {
      return;
    }

    const defaultDeliveryNote = this.getDefaultDeliveryNote(
      new DeliveryNote(),
      this.TEMPLATE_TYPES.DEFAULT,
    );

    this.setState({
      deliveryNote: defaultDeliveryNote,
      importedDeliveryNote: cloneDeep(defaultDeliveryNote),
      selectedTemplate: null,
    });
  };

  getSupplierOptions() {
    const supplierOptions = [...this.state.supplierOptions];
    if (
      this.state.importedDeliveryNote.supplier.id &&
      !supplierOptions.find(
        (supplier) =>
          supplier.id === this.state.importedDeliveryNote.supplier.id,
      )
    ) {
      supplierOptions.push(this.state.importedDeliveryNote.supplier);
    }

    return ArrayUtils.sortByKey(supplierOptions, 'name');
  }

  getRecipientOptions() {
    const recipientOptions = [...this.state.recipientOptions];
    if (
      this.state.importedDeliveryNote.recipient.id &&
      !recipientOptions.find(
        (recipient) =>
          recipient.id === this.state.importedDeliveryNote.recipient.id,
      )
    ) {
      recipientOptions.push(this.state.importedDeliveryNote.recipient);
    }

    return ArrayUtils.sortByKey(recipientOptions, 'name');
  }

  getSellerOptions() {
    const sellerOptions = [...this.state.sellerOptions];
    if (
      this.state.importedDeliveryNote.seller.id &&
      !sellerOptions.find(
        (seller) => seller.id === this.state.importedDeliveryNote.seller.id,
      )
    ) {
      sellerOptions.push(this.state.importedDeliveryNote.seller);
    }

    return ArrayUtils.sortByKey(sellerOptions, 'name');
  }

  getBuyerOptions() {
    const buyerOptions = [...this.state.buyerOptions];
    if (
      this.state.importedDeliveryNote.buyer.id &&
      !buyerOptions.find(
        (buyer) => buyer.id === this.state.importedDeliveryNote.buyer.id,
      )
    ) {
      buyerOptions.push(this.state.importedDeliveryNote.buyer);
    }

    return ArrayUtils.sortByKey(buyerOptions, 'name');
  }

  openSupportModal = () => {
    Log.productAnalyticsEvent(
      'Open contact support modal',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );
    this.setState({
      supportModalOpen: true,
    });
  };
  closeSupportModal = () => {
    Log.productAnalyticsEvent(
      'Close contact support modal',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );
    this.setState({
      supportModalOpen: true,
    });
  };

  render() {
    return (
      <div className="main-padding">
        <div className="min-w-fit-content flex-s-c flexdir-column">
          <div className="max-w-1200px w-full">
            <div className="pb-1rem flex-e-e">
              <div className="flex-c-c gap-20px">
                {this.state.selectedTemplate?.name ? (
                  <div className="rounded-15px h-30px max-w-300px pl-15px pr-15px bg-grey100 line-h-30px text-overflow">
                    {'Vorlage: ' + this.state.selectedTemplate?.name}
                  </div>
                ) : null}
                <TemplateMenu
                  menuId="dln-create-template-menu"
                  onClick={this.handleTemplateSelect}
                  onDeletedTemplate={this.onDeletedTemplate}
                  onDeliveryNoteSelect={this.handleDeliveryNoteSelect}
                />
              </div>
            </div>
            <div className="rounded-5px box-shadow-blue box-padding bg-white text-start">
              <div className="flex-sb-s w-full">
                <div className="bold text-16px mb-20px">Parteien</div>
                <div className="">
                  <div className="flex-e-c w-full">
                    <span
                      className="bold text-primary500 cursor-pointer"
                      onClick={this.openSupportModal}
                    >
                      Support anrufen & Adressen verwalten
                    </span>
                  </div>
                  <Dialog
                    open={this.state.supportModalOpen}
                    onClose={this.closeSupportModal}
                  >
                    <DialogContent>
                      <div className="bold mb-20px text-20px mt-10px">
                        Telefonnummer
                      </div>
                      <div className="bold text-16px">
                        {UniversalCommunication.getSupportContact().phone}
                      </div>
                      <div className="w-400px mt-20px">
                        Wenn wir eine neue Adresse für dich anlegen und
                        freischalten sollen, stehen wir jederzeit zu deiner
                        Verfügung!
                      </div>
                      <div className="flex-c-c mt-30px mb-10px w-full">
                        <Button
                          className="primary-button"
                          onClick={() => {
                            this.setState({ supportModalOpen: false });
                          }}
                        >
                          Schließen
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
              <Grid
                container
                rowSpacing="20px"
                columnSpacing="76px"
                justifyContent="space-between"
              >
                <Grid item xs={12} md={6}>
                  <CompanySelect
                    title="Lieferant*"
                    value={this.state.deliveryNote.supplier.id}
                    options={this.getSupplierOptions()}
                    onChange={this.handleChangeSupplier}
                    error={this.state.supplierError}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CompanySelect
                    title="Abnehmer*"
                    value={this.state.deliveryNote.recipient.id}
                    options={this.getRecipientOptions()}
                    onChange={this.handleChangeRecipient}
                    error={this.state.recipientError}
                    required
                  />
                </Grid>
              </Grid>
            </div>
            <div className="rounded-5px box-shadow-blue box-padding mt-30px bg-white text-start">
              <div className="bold text-16px mb-20px">Leistungsdetails</div>
              <Grid
                container
                rowSpacing="20px"
                columnSpacing="76px"
                justifyContent="space-between"
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    title="LFS-Nr.*"
                    value={this.state.deliveryNote.number}
                    onChange={this.handleChangeNumber}
                    error={this.state.numberError}
                    placeholder="z.B. 1056888122"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel className="text-13px">Datum*</InputLabel>
                  <div className="flex-s-c gap-10px">
                    <DatePicker
                      value={this.state.deliveryNote.dlnDate}
                      onChange={this.handleChangeDate}
                    />
                    <div className="flex-s-c gap-5px">
                      <TextField
                        value={dateUtils.getFormattedDate_safe(
                          this.state.deliveryNote.dlnDate,
                          dateUtils.DATE_FORMAT.HH,
                        )}
                        onChange={this.handleChangeHours}
                        placeholder="00"
                        autoComplete="off"
                        className="w-46px"
                      />
                      :
                      <TextField
                        value={dateUtils.getFormattedDate_safe(
                          this.state.deliveryNote.dlnDate,
                          dateUtils.DATE_FORMAT.mm,
                        )}
                        onChange={this.handleChangeMinutes}
                        placeholder="00"
                        autoComplete="off"
                        className="w-46px"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    title={
                      this.state.deliveryNote.costCenters.length > 0
                        ? 'Lieferort (optional, da Kostenstelle vorhanden)'
                        : 'Lieferort*'
                    }
                    value={this.state.deliveryNote.toSiteSupplier.name}
                    onChange={this.handleChangeToSite}
                    placeholder="z.B. Ortsumfahrung B35 Heidelsheim"
                    error={this.state.toSiteError}
                    autoComplete="off"
                  />
                  <div className="h-22px" />
                  <TextField
                    title={
                      this.state.deliveryNote.toSiteSupplier.name
                        ? 'Kostenstelle (optional, da Lieferort vorhanden)'
                        : 'Kostenstelle*'
                    }
                    value={this.state.deliveryNote.costCenters[0] ?? ''}
                    onChange={this.handleChangeCostCenters}
                    placeholder="z.B. 200247"
                    error={this.state.costCentersError}
                    autoComplete="off"
                  />
                  <div className="h-22px" />
                  <TextField
                    title="Bestellreferenz Auftraggeber"
                    value={this.state.deliveryNote.buyerOrderReferences}
                    onChange={this.handleChangeBuyerOrderReferences}
                    placeholder="z.B. 73594"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    title="Kommentare"
                    value={this.state.deliveryNote.comments[0]}
                    onChange={this.handleChangeComments}
                    placeholder="z.B. Aushebung der Grube im Abschnitt C-25"
                    autoComplete="off"
                    multiline
                    rows={8}
                  />
                </Grid>
                {UserUtils.isANBSAccount(
                  this.props.userinfo.userinfo.company?.id,
                ) ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        title="Projekt"
                        value={this.state.deliveryNote.project}
                        onChange={this.handleChangeProject}
                        placeholder="z.B. 10030141"
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        title="Plan"
                        value={this.state.deliveryNote.constructionPlans}
                        onChange={this.handleChangeConstructionPlans}
                        placeholder="z.B. B18-B21"
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        title="Bauteil"
                        value={this.state.deliveryNote.constructionComponents}
                        onChange={this.handleChangeConstructionComponents}
                        placeholder="z.B. Außenwände UG - Sporthalle"
                        autoComplete="off"
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </div>
            <div className="rounded-5px box-shadow-blue box-padding mt-30px bg-white text-start">
              <div className="bold text-16px">Artikel</div>
              <ServiceList
                articles={this.state.deliveryNote.articles}
                onChange={this.handleChangeArticles}
                addArticle={this.addArticle}
                removeArticle={this.removeArticle}
                error={this.state.articlesError}
                articleTemplates={this.state.articleTemplates}
              />
            </div>
            <div className="flex-e-c mt-30px gap-20px w-full">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.setState({ templateModalOpen: true });
                }}
                disabled={this.state.templateSaved}
                startIcon={this.state.templateSaved ? <CheckIcon /> : null}
              >
                {this.state.templateSaved
                  ? 'Vorlage gespeichert'
                  : 'Neue Vorlage speichern'}
              </Button>
              <BasicForm
                title="Vorlage"
                open={this.state.templateModalOpen}
                formAbort={this.abortTemplate}
                formSuccess={this.saveTemplate}
                submittingForm={this.state.submittingTemplateForm}
              >
                <div className="w-350px">
                  <TextField
                    title="Name"
                    value={this.state.newTemplateName}
                    onChange={(event) =>
                      this.setState({ newTemplateName: event.target.value })
                    }
                    placeholder="Bitte eingeben"
                    autoComplete="off"
                  />
                </div>
              </BasicForm>
              {this.state.selectedTemplate &&
              !this.state.templateSaved &&
              !this.state.selectedTemplate.default_template ? (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={this.state.updatingTemplate ? null : <LoopIcon />}
                  onClick={this.updateTemplate}
                  disabled={
                    this.state.selectedTemplate.default_template ||
                    this.state.updatingTemplate
                  }
                >
                  {this.state.updatingTemplate ? (
                    <Spinner title="Vorlage aktualisieren..." />
                  ) : (
                    'Vorlage aktualisieren'
                  )}
                </Button>
              ) : null}
              <Button
                className="primary-button"
                disabled={this.state.creatingDeliveryNote}
                onClick={this.createDeliveryNote}
              >
                {this.state.creatingDeliveryNote ? (
                  <Spinner white title="Leistungsnachweis erstellen..." />
                ) : (
                  'Leistungsnachweis erstellen'
                )}
              </Button>
            </div>
          </div>
        </div>
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(ServiceNoteForm),
  'Formular konnte nicht geladen werden.',
);
