import axios from 'axios';
import { UserManager } from 'oidc-react';
import qs from 'qs';

import Config from '~/Config';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

import ToastService from './toast.service';

const tokenUrl =
  'https://login.vestigas.com/auth/realms/vestigas/protocol/openid-connect/token';

class AuthService {
  constructor() {
    // Use idp param to pass kc_idp_hint to keycloak: e.g. app.vestigas.com?idp=strabag directly navigates to strabag login
    const url = new URL(window.location.href);
    const idpHint = url.searchParams.get('idp');

    const extraQueryParams = idpHint ? { kc_idp_hint: idpHint } : {};

    this.oidcConfig = {
      authority: 'https://login.vestigas.com/auth/realms/vestigas/',
      clientId: 'mobile_apps',
      extraQueryParams,
      onSignIn: async (user) => {
        this.saveTokens(user);
        // Redirect directly to the initially called page so that the oidc state ("?state=...") is removed from the url.
        // This is important mainly due to two reasons:
        // 1. Keeping the oidc state in the url led to a bug where the page couldn't be loaded when people where using the url with the state as a bookmark.
        // 2. The oidc state in the url is used to determine in which state of the login flow the page currently is.
        const [url] = window.location.href.split('?');
        window.location.href = url;
      },
      onSignOut: () => {
        Log.info('Handle onSignOut in oidc flow.');
        this.removeTokens();
      },
      redirectUri: window.location.href,

      responseType: 'code',
      // redirect to the page that has been initially called by the user
      scope: 'openid offline_access',
    };

    this.userManager = new UserManager({
      ...this.oidcConfig,
      client_id: this.oidcConfig.clientId,
      redirect_uri: this.oidcConfig.redirectUri,
    });

    // this.logoutToast = null;
    this.shouldLogout = false;
  }

  refreshTokens = async () => {
    const [response, error] = await promiseHandler(this.loadRefreshedTokens());

    if (error) {
      Log.error('Failed to refresh tokens.', error);

      if (this.shouldLogout) {
        return;
      } // if user will be logged out, it is not necessary to trigger the logout a second time

      ToastService.warning(
        [
          'Deine Session konnte nicht erneuert werden. Du wirst in wenigen Sekunden ausgeloggt.',
        ],
        ToastService.ID.SESSION_EXPIRED_LOGOUT,
      );
      Log.productAnalyticsEvent(
        'Failed to refresh access token',
        Log.FEATURE.AUTHENTICATION,
        Log.TYPE.ERROR,
      );

      this.shouldLogout = true;

      setTimeout(() => {
        if (this.shouldLogout) {
          this.logout();
        }
      }, 10_000);

      throw error;
    }

    this.saveTokens(response.data);

    return response.data.access_token;
  };

  async loadRefreshedTokens() {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      throw new Error(
        'Failed to retrieve refresh token from local storage. refresh token: ' +
          refreshToken,
      );
    }

    const body = qs.stringify({
      client_id: this.oidcConfig.clientId,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });

    return axios.post(tokenUrl, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  saveTokens(data) {
    if (!data) {
      return;
    }

    if (data.refresh_token) {
      localStorage.setItem('refreshToken', data.refresh_token);
    }

    if (data.access_token) {
      localStorage.setItem('accessToken', data.access_token);
    }

    if (data.id_token) {
      localStorage.setItem('idToken', data.id_token);
    }
  }

  removeTokens() {
    Log.info('Remove tokens from local storage.');

    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
  }

  logout() {
    Log.info('Log out user.');

    this.removeTokens();
    this.userManager.signoutRedirect({
      // post_logout_redirect_uri requires either client_id or id_token_hint to be set (requirement from
      // keycloak/oidc). Id_token_hint will be set by oidc-react if everything works, but if login fails and
      // authprovider runs in an exception, it isn't set (or any other option when no id token was obtained. So we
      // set client_id in all cases to prevent keycloak error screens.
      extraQueryParams: {
        client_id: 'mobile_apps',
      },

      post_logout_redirect_uri: Config.redirectUrl + '/?logout=true',
    });
  }

  /* cancelLogout = () => {
        this.shouldLogout = false;
        toast.dismiss(this.logoutToast);
    } */

  accessTokenValid() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return false;
    }

    const accessTokenPayload = this.getJwtPayload(accessToken);

    if (!accessTokenPayload) {
      return false;
    }

    return accessTokenPayload.exp * 1000 > Date.now();
  }

  accessTokenExpired() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return false;
    }

    const accessTokenPayload = this.getJwtPayload(accessToken);

    if (!accessTokenPayload) {
      return true;
    }

    return accessTokenPayload.exp * 1000 <= Date.now();
  }

  refreshTokenExpired() {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      return false;
    }

    const refreshTokenPayload = this.getJwtPayload(refreshToken);

    return refreshTokenPayload.exp * 1000 <= Date.now();
  }

  getJwtPayload(jwt) {
    if (!jwt) {
      return null;
    }

    const parts = jwt.split('.');
    if (parts.length !== 3 || !parts[1]) {
      Log.error(
        'Invalid JWT format - expected 3 parts (header, payload, signature), got',
        parts.length,
      );
      return null;
    }

    const payload = parts[1];
    // Replace - with + and _ with / to handle URL-safe base64
    const base64 = payload.replaceAll('-', '+').replaceAll('_', '/');

    try {
      return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
    } catch (error) {
      Log.error('Failed to decode JWT payload', error);
      return null;
    }
  }

  getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  getIdToken() {
    return localStorage.getItem('idToken');
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  // To track whether the current url is a redirection from oidc.
  // In this case the url has specific query/search params which are needed for the oidc login flow.
  isOidcRedirectUrl(url) {
    return (
      url.includes('state') &&
      url.includes('session_state') &&
      url.includes('code')
    );
  }

  // To track whether this redirect came from logout.
  // In this case, the user shouldn't be logged out again as this would cause an infinite loop of page reloads
  isLogoutUrl(url) {
    return url.includes('?logout=true');
  }

  getUserIdFromAccessToken() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return null;
    }

    const accessTokenPayload = this.getJwtPayload(accessToken);

    return accessTokenPayload?.sub;
  }

  accessTokenContainsFicKey() {
    return Boolean(this.getJwtPayload(this.getAccessToken())?.fic);
  }
}

export default new AuthService();
