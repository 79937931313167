import axios from '~/utils/api-client';
import Config from '~/Config';
import PdfTemplate from '~/models/masterdata/PdfTemplate';
import { LOADING_STATE } from '~/constants/LoadingState';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import store from '~/redux/store';
import {
  replacePdfTemplates,
  setPdfTemplatesLoading,
} from '~/redux/pdfTemplatesSlice';

const API_URL = Config.apiUrl;

class PdfSettingsService {
  async getAllPdfTemplates() {
    return axios.get(API_URL + '/pdf_template').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.templates.map(
        (template) => new PdfTemplate(template),
      );
    });
  }

  async getPdfTemplate(id) {
    return axios.get(API_URL + '/pdf_template/' + id, { responseType: 'blob' });
  }

  async createPdfTemplate(body) {
    const formData = new FormData();

    formData.append('type', body.type);
    formData.append('file', body.file);
    if (body.company_id) {
      formData.append('company_id', body.company_id);
    }

    return axios.post(API_URL + '/pdf_template', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async createTestPdfTemplate(body) {
    const formData = new FormData();

    formData.append('template_file', body.template_file);

    if (body?.asset_file) {
      formData.append('asset_file', body.asset_file);
    }

    for (const id of body.asset_ids) {
      formData.append('asset_ids', id.trim());
    }

    return axios.post(
      `${API_URL}/asset/dev/request_test_pdf_template`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  async getTestPdfTemplate(id) {
    return axios.get(`${API_URL}/asset/dev/get_test_pdf_template`, {
      params: { request_id: id },
      responseType: 'blob',
    });
  }

  async deletePdfTemplate(id) {
    return axios.delete(API_URL + '/pdf_template/' + id);
  }

  async getCompanyDlnToInvoice(id) {
    return axios.get(API_URL + '/company/' + id + '/dln_to_invoice');
  }

  async putCompanyDlnToInvoice(id, add_dlns_to_inv) {
    return axios.put(API_URL + '/company/' + id + '/dln_to_invoice', null, {
      params: { add_dlns_to_inv },
    });
  }

  async getInvoiceProtocolToInvoice(id) {
    return axios.get(
      API_URL + '/company/' + id + '/invoice_protocol_to_invoice',
    );
  }

  async putInvoiceProtocolToInvoice(id, value) {
    return axios.put(
      API_URL + '/company/' + id + '/invoice_protocol_to_invoice',
      null,
      { params: { value } },
    );
  }

  loadPdfTemplates = async () => {
    // to not load pdf templates again when they are already loading or have already been loaded
    if (
      store.getState().pdfTemplates?.pdfTemplatesLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshPdfTemplates();
  };
  refreshPdfTemplates = async () => {
    store.dispatch(setPdfTemplatesLoading(LOADING_STATE.LOADING));

    const [pdfTemplates, error] = await promiseHandler(
      this.getAllPdfTemplates(),
    );

    if (error) {
      Log.error('Failed to load pdf templates.', error);
      Log.productAnalyticsEvent(
        'Failed to load pdf templates',
        Log.FEATURE.PDF_TEMPLATE,
        Log.TYPE.ERROR,
      );
      store.dispatch(setPdfTemplatesLoading(LOADING_STATE.FAILED));
      return;
    }

    store.dispatch(replacePdfTemplates(pdfTemplates));
  };
}

export default new PdfSettingsService();
