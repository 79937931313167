export const columns = [
  {
    field: 'name',
    headerName: 'Name',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'displayName',
    headerName: 'Anzeige-Name',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'required',
    headerName: 'Verpflichtend',
    resizableText: true,
    sortable: true,
    width: 150,
  },
  {
    field: 'role',
    headerName: 'Rolle',
    resizableText: true,
    sortable: true,
    width: 200,
  },
  {
    field: 'pathString',
    headerName: 'Zugriff auf',
    resizableText: true,
    sortable: true,
    width: 200,
  },
  {
    field: 'customField',
    headerName: 'Flexibles Feld',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'company',
    headerName: 'Firma',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'site',
    headerName: 'Standort',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'costCenter',
    headerName: 'Kostenstelle',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    field: 'id',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
];
