import { type UUID } from '~/types/common';
import { type ArticlesQueryParams } from './useQueryArticles';

/**
 * Query key factory for article-related queries.
 */
export const queryKeysArticle = {
  base: () => 'vehicles' as const,
  get: (articleId: UUID) =>
    [queryKeysArticle.base(), 'detail', { articleId }] as const,
  getAll: (queryParams: Partial<ArticlesQueryParams>) =>
    [queryKeysArticle.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<ArticlesQueryParams>) =>
    [queryKeysArticle.base(), 'list', 'infinite', queryParams] as const,
  getAllLegacy: () => [queryKeysArticle.base(), 'list', 'LEGACY'] as const,
} as const;
