import { array, bool, func, number, object, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { DeliveriesTestIds } from '~/constants/test-ids';

import FilterContext from '~/models/filters/FilterContext';
import FilterNew from '~/models/filters/FilterNew';

import DeliveriesService from '~/services/deliveries.service';

import { TAB } from '~/constants/Tab';

import DatagridUtils from '~/utils/datagridUtils';
import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import { filterDeliveries } from '~/components/deliveries/utils';
import FilterGroups from '~/components/filterBar/FilterGroups';

import {
  deleteFilterGroup,
  fixTabsOrder,
  getAllOptions,
  getAppliedFilters,
  getFilteredOptions,
  getFilterGroupObject,
  getFilterGroups,
  getSelectableFilters,
  handleComponentDidUpdate,
  handleSelectFilterGroup,
  loadDlnFilterSuggestions,
  saveNewFilterGroup,
  updateFilterGroup,
  updateFilterGroupName,
} from './utils';
import { mapStateToProps } from './mapStateToProps';
import { mapDispatchToProps } from './mapDispatchToProps';

class DeliveryFilterGroupsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cachedSuggestions: {},
      filterGroups: this.getFilterGroups(),
      selectableFilters: [],
      unassignedDeliveryNotesCount: 0,
    };

    /*
     * When changing the default tabs, it is also necessary to update the tabs in the render method.
     * This is currently not dynamic.
     * It would be a better solution to make it dynamic and to iterate through the DEFAULT_TABS array,
     * but that would require to move the FeatureService.clientPortal() function call to Tab.js.
     * This however doesn't work because of a package initialization error.
     */
    this.DEFAULT_TABS = [TAB.DELIVERY.LIST, TAB.DELIVERY.CHANGES];

    if (
      this.props.userFeatureFlags.accessPermittedSites ||
      UserUtils.isPermittedSiteAllowedUser()
    ) {
      this.DEFAULT_TABS.push(TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES);
    }

    this.TAB_OFFSET = this.DEFAULT_TABS.length;
  }

  componentDidMount() {
    fixTabsOrder(
      this.props,
      this.DEFAULT_TABS,
      this.getFilterGroupObject.bind(this),
    ); // TODO:move to handleComponentDidMount
    this.initUnassignedDeliveryNotesCount(); // TODO:move to handleComponentDidMount
    this.initSelectedFilterGroup(); // TODO:move to handleComponentDidMount
    this.initSelectedTab(); // TODO:move to handleComponentDidMount
    this.initSelectableFilters(); // TODO:move to handleComponentDidMount
    this.initFilterGroups(); // TODO:move to handleComponentDidMount
  }

  componentDidUpdate(prevProps) {
    handleComponentDidUpdate(this.props, prevProps, this);
  }

  isArchiveMode = () => {
    return DeliveriesService.isArchiveMode(this.props.dateRange);
  };
  initUnassignedDeliveryNotesCount = () => {
    const filteredRows = filterDeliveries({
      dateRange: this.props.dateRange,
      query: '',
      rows: this.props.deliveryNotes.deliveryRows,
      selectedAcceptState: [],
      selectedArticle: [],
      selectedArticleNumber: [],
      selectedCostCenter: [],
      selectedCustomFields: [],
      selectedFromSite: [],
      selectedNumber: [],
      selectedPermittedCostCenters: [],
      selectedPermittedToSites: [],
      selectedProcessState: [],
      selectedRecipient: [],
      selectedSettledStatus: [],
      selectedSupplier: [],
      selectedToSiteRecipient: [],
      selectedToSiteSupplier: [],
      selectField: this.props.selectField,
    });
    const unassignedDeliveryNotesCount =
      DeliveriesService.getUnassignedDeliveryRows(filteredRows).length;

    this.setState({
      unassignedDeliveryNotesCount,
    });
  };
  // Set the selected filter group to the first filter group if no filter group is selected.
  initSelectedFilterGroup = () => {
    if (this.props.selectedFilterGroup) {
      return;
    }

    if (this.props.filterGroups.length === 0) {
      return;
    }

    this.props.setDelivery_selectedFilterGroup(
      this.props.filterGroups[TAB.DELIVERY.LIST.INDEX].id,
    );
    this.handleChangeFilterGroup(
      this.props.filterGroups[TAB.DELIVERY.LIST.INDEX],
    );
  };
  // Update the selected tab based on the selected filter group.
  initSelectedTab = () => {
    const index = this.props.filterGroups.findIndex(
      ({ id }) => id === this.props.selectedFilterGroup,
    );

    if (index === -1) {
      return;
    }

    this.props.setDelivery_tab(index);
    // TODO mgottelt overwrites filter model
    this.props.setDeliveryList_filterModel(
      this.props.filterGroups[index]?.filterModel ??
        DatagridUtils.EMPTY_FILTER_MODEL,
    );
  };
  initSelectableFilters = () => {
    this.setState({
      selectableFilters: this.getSelectableFilters(),
    });
  };
  initFilterGroups = () => {
    const newFilterGroups = this.getFilterGroups();

    this.setState({
      filterGroups: newFilterGroups,
    });

    if (
      newFilterGroups.find(({ id }) => id === this.props.selectedFilterGroup)
        ?.disabled
    ) {
      this.props.setDelivery_selectedFilterGroup(
        this.props.filterGroups[TAB.DELIVERY.LIST.INDEX].id,
      );
    }
  };
  resetSelectedFilterGroup = () => {
    if (this.props.filterGroups.length === 0) {
      this.props.setDelivery_selectedFilterGroup(null);
      this.handleChangeFilterGroup();
      return;
    }

    this.props.setDelivery_selectedFilterGroup(
      this.props.filterGroups[TAB.DELIVERY.LIST.INDEX].id,
    );
    this.handleChangeFilterGroup(
      this.props.filterGroups[TAB.DELIVERY.LIST.INDEX],
    );
  };
  getAllOptions = (name, customField) => {
    return getAllOptions(
      this.props.data,
      this.props.selectedProcessState,
      name,
      customField,
    );
  };
  getFilteredOptions = (name, customField) => {
    return getFilteredOptions(
      this.props.data,
      this.props.selectedProcessState,
      name,
      customField,
      this.props.selectedCustomFields,
      this.props,
    );
  };
  getFilterGroups = () => {
    return getFilterGroups(
      this.props.filterGroups,
      this.isArchiveMode(),
      TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.INDEX,
    );
  };
  /**
   * Disables a selectable filter if the page is in archive mode and the corresponding backend filter is not implemented.
   *
   * @param {string} propertyKey - The key of the filter field.
   * @return {boolean} Returns true if the filter is disabled, false otherwise.
   */
  selectableFilterDisabled = (propertyKey) => {
    return (
      this.isArchiveMode() &&
      !FilterNew.fieldIsApplicableBackendFilter(
        propertyKey,
        FilterContext.PAGE.DELIVERY,
      )
    );
  };
  getSelectableFilters = () => {
    if (!this.props) {
      return [];
    }

    const selectableFilters = getSelectableFilters({
      customFields: this.props.customFields,
      isSelectableFilterDisabled: (propertyKey) =>
        this.selectableFilterDisabled(propertyKey),
      userFeatureFlags: this.props.userFeatureFlags,
    });

    const selectableFiltersWithOptions = selectableFilters.map((item) => ({
      ...item,
      // FIXME: this performs a filtering of the entire DLN data for every selectable filter! In combination unnecessary re-renders, you can easily end up filtering the entire DLN data 116 times!
      allOptions: this.getAllOptions(item.name, item.customField),
      filteredOptions: this.getFilteredOptions(item.name, item.customField),
    }));

    return selectableFiltersWithOptions;
  };
  getFilterGroupObject = (id, name, filterRows, isEmptyFilterGroup) => {
    return getFilterGroupObject(
      id,
      name,
      this.props.filterGroups,
      filterRows,
      this.props,
      this.props.calculatedFilterModel,
      isEmptyFilterGroup,
    );
  };
  saveNewFilterGroup = async (id, name, filterRows, isEmptyFilterGroup) => {
    const newFilterGroup = getFilterGroupObject(
      id,
      name,
      this.props.filterGroups,
      filterRows,
      this.props,
      this.props.calculatedFilterModel,
      isEmptyFilterGroup,
    );

    try {
      await saveNewFilterGroup(
        newFilterGroup,
        this.props.filterGroups,
        this.props.setDelivery_filterGroupOpen,
        this.props.setDelivery_filterGroups,
        this.props.setDelivery_selectedFilterGroup,
        this.handleChangeFilterGroup,
      );
    } catch (error) {
      Log.error('Failed to update save new filter group.', error);
    }
  };
  updateFilterGroup = async (filterRows) => {
    try {
      const updatedFilterGroup = getFilterGroupObject(
        this.props.selectedFilterGroup,
        null,
        this.props.filterGroups,
        filterRows,
        this.props,
        this.props.calculatedFilterModel,
        false,
      );

      await updateFilterGroup(
        this.props.filterGroups,
        this.props.selectedFilterGroup,
        updatedFilterGroup,
        this.props.setDelivery_filterGroups,
      );
    } catch (error) {
      Log.error('Failed to update filter group.', error);
    }
  };
  updateFilterGroupName = async (id, name) => {
    try {
      await updateFilterGroupName(
        id,
        name,
        this.props.filterGroups,
        this.props.setDelivery_filterGroups,
      );
    } catch (error) {
      Log.error('Failed to update filter group name.', error);
    }
  };
  deleteFilterGroup = async () => {
    try {
      await deleteFilterGroup(
        this.props.filterGroups,
        this.props.selectedFilterGroup,
        this.props.setDelivery_filterGroups,
        this.props.setDelivery_selectedFilterGroup,
        this.handleChangeFilterGroup,
      );
    } catch (error) {
      Log.error('Failed to delete filter group.', error);
    }
  };
  handleChangeFilterGroup = (filterGroupRaw) => {
    const filterGroup =
      filterGroupRaw ??
      this.props.filterGroups.find(
        ({ id }) => id === this.props.selectedFilterGroup,
      );

    // Set selected filter values.
    // Reset, if filterGroup doesn't exist anymore.
    this.props.setDelivery_selectedToSiteRecipient(
      filterGroup?.filters?.selectedToSiteRecipient ?? [],
    );
    this.props.setDelivery_selectedToSiteSupplier(
      filterGroup?.filters?.selectedToSiteSupplier ?? [],
    );
    this.props.setDelivery_selectedCostCenter(
      filterGroup?.filters?.selectedCostCenter ?? [],
    );
    this.props.setDelivery_selectedArticleNumber(
      filterGroup?.filters?.selectedArticleNumber ?? [],
    );
    this.props.setDelivery_selectedArticle(
      filterGroup?.filters?.selectedArticle ?? [],
    );
    this.props.setDelivery_selectedSupplier(
      filterGroup?.filters?.selectedSupplier ?? [],
    );
    this.props.setDelivery_selectedRecipient(
      filterGroup?.filters?.selectedRecipient ?? [],
    );
    this.props.setDelivery_selectedProcessState(
      filterGroup?.filters?.selectedProcessState ?? [],
    );
    this.props.setDelivery_selectedAcceptState(
      filterGroup?.filters?.selectedAcceptState ?? [],
    );
    this.props.setDelivery_selectedSettledStatus(
      filterGroup?.filters?.selectedSettledStatus ?? [],
    );
    this.props.setDelivery_selectedFromSite(
      filterGroup?.filters?.selectedFromSite ?? [],
    );
    this.props.setDelivery_selectedPermittedToSites(
      filterGroup?.filters?.selectedPermittedToSites ?? [],
    );
    this.props.setDelivery_selectedPermittedCostCenters(
      filterGroup?.filters?.selectedPermittedCostCenters ?? [],
    );
    this.props.setDelivery_selectedCustomFields(
      filterGroup?.filters?.selectedCustomFields ?? [],
    );
  };
  loadDlnFilterSuggestions = async (
    filterRowId,
    searchValue,
    offset,
    ignoreCache,
  ) => {
    loadDlnFilterSuggestions(
      filterRowId,
      searchValue,
      offset,
      ignoreCache,
      this.state,
      this.setState.bind(this),
      this.props,
      this.getAppliedFilters,
    );
  };
  getAppliedFilters = (filter) => {
    return getAppliedFilters(filter, this.props);
  };
  updateFilterRows = (filterRows) => {
    this.props.setDelivery_filterRows(filterRows);
  };
  handleOpenFilterRow = async (filterRowId) => {
    if (!this.isArchiveMode()) {
      return;
    }

    this.loadDlnFilterSuggestions(filterRowId, null, 0);
  };
  handleSelectFilterGroup = (filterGroupId) => {
    return handleSelectFilterGroup(
      filterGroupId,
      this.props.filterGroups,
      this.props.selectedFilterGroup,
      this.props.setDelivery_selectedFilterGroup,
      this.props.userFeatureFlags,
      this.handleChangeFilterGroup,
      this.TAB_OFFSET,
    );
  };
  handleExpandFilterGroup = () => {
    this.props.setDelivery_filterGroupOpen(!this.props.filterGroupOpen);
  };
  handleChangeSearchValue = async (filterRowId, searchValue) => {
    if (!this.isArchiveMode()) {
      return;
    }

    FunctionUtils.delayFunction(
      `filter_row_change_${filterRowId}`,
      () => this.loadDlnFilterSuggestions(filterRowId, searchValue, 0, true),
      [filterRowId, searchValue, 0, true],
      1000,
    );
  };
  handleScrollToBottom = async (filterRowId) => {
    if (!this.isArchiveMode()) {
      return;
    }

    const offset = this.state.cachedSuggestions[filterRowId].offset;
    const suggestions = this.state.cachedSuggestions[filterRowId].suggestions;
    const searchValue = this.state.cachedSuggestions[filterRowId].searchValue;

    // If the number of suggestions is equal to the offset, the last request returned an empty list and
    // there are no more suggestions to load.
    if (suggestions.length === offset) {
      return;
    }

    this.loadDlnFilterSuggestions(filterRowId, searchValue, suggestions.length);
  };

  render() {
    const {
      filterGroupOpen,
      filterGroups,
      filterRows,
      onChangeValue,
      selectedFilterGroup,
      selectedTab,
    } = this.props;

    const hiddenElements = {
      hideAccordion:
        selectedTab === TAB.DELIVERY.CHANGES.INDEX ||
        selectedFilterGroup ===
          filterGroups.find(
            ({ name }) => name === TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.NAME,
          )?.id,
      hideEditNameIcon: selectedTab < this.TAB_OFFSET,
      hideUpdateDeleteButton: selectedTab === TAB.DELIVERY.LIST.INDEX,
    };

    return (
      <FilterGroups
        baseTestId={DeliveriesTestIds.FILTER_GROUPS.BASE}
        currentFilterGroupObject={this.getFilterGroupObject(
          selectedFilterGroup,
        )}
        deleteFilterGroup={this.deleteFilterGroup}
        disableDeleteButton={selectedTab < this.TAB_OFFSET}
        disableUpdateButton={selectedTab < this.TAB_OFFSET}
        filterGroupMarks={[
          {
            id: filterGroups.find(
              ({ name }) =>
                name === TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.NAME,
            )?.id,
            mark:
              this.state.unassignedDeliveryNotesCount > 99
                ? '+99'
                : this.state.unassignedDeliveryNotesCount,
            tooltipTitle:
              'Anzahl der nicht zugeordneten Lieferungen: ' +
              this.state.unassignedDeliveryNotesCount,
          },
        ]}
        filterGroupOpen={filterGroupOpen}
        filterGroups={this.state.filterGroups}
        filterRows={filterRows}
        onChangeSearchValue={this.handleChangeSearchValue}
        onChangeValue={onChangeValue}
        onClickExpandFilterGroup={this.handleExpandFilterGroup}
        onClickFilterGroup={this.handleSelectFilterGroup}
        onOpenFilterRow={this.handleOpenFilterRow}
        onScrollToBottom={this.handleScrollToBottom}
        originalFilterGroupObject={filterGroups.find(
          ({ id }) => id === selectedFilterGroup,
        )}
        resetSelectedFilterGroup={this.resetSelectedFilterGroup}
        saveNewFilterGroup={this.saveNewFilterGroup}
        selectableFilters={this.state.selectableFilters} // TODO: the filter options should not be passed here, but fetched by the Select onClick
        selectedFilterGroup={selectedFilterGroup}
        updateFilterGroup={this.updateFilterGroup}
        updateFilterGroupName={this.updateFilterGroupName}
        updateFilterRows={this.updateFilterRows}
        {...hiddenElements}
        withExpandableFilterGroup
      />
    );
  }
}

DeliveryFilterGroupsComponent.propTypes = {
  customFields: array.isRequired,
  dataVersion: number.isRequired,
  dateRange: array.isRequired,
  deliveryNotes: object.isRequired,
  filterGroupOpen: bool.isRequired,
  filterGroups: array.isRequired,
  filterRows: array.isRequired,
  oldestFilteredDlnDate: string,
  onChangeValue: func.isRequired,
  selectedAcceptState: array.isRequired,
  selectedArticle: array.isRequired,
  selectedArticleNumber: array.isRequired,
  selectedCostCenter: array.isRequired,
  selectedCostCenters: array.isRequired,
  selectedCustomFields: array.isRequired,
  selectedFilterGroup: string,
  selectedFromSite: array.isRequired,
  selectedPermittedCostCenters: array.isRequired,
  selectedPermittedToSites: array.isRequired,
  selectedProcessState: array.isRequired,
  selectedRecipient: array.isRequired,
  selectedSettledStatus: array.isRequired,
  selectedSites: array.isRequired,
  selectedSupplier: array.isRequired,
  selectedTab: number.isRequired,
  selectedToSiteRecipient: array.isRequired,
  selectedToSiteSupplier: array.isRequired,
  setDelivery_filterGroupOpen: func.isRequired,
  setDelivery_filterGroups: func.isRequired,
  setDelivery_filterRows: func.isRequired,
  setDelivery_selectedAcceptState: func.isRequired,
  setDelivery_selectedArticle: func.isRequired,
  setDelivery_selectedArticleNumber: func.isRequired,
  setDelivery_selectedCostCenter: func.isRequired,
  setDelivery_selectedCustomFields: func.isRequired,
  setDelivery_selectedFilterGroup: func.isRequired,
  setDelivery_selectedFromSite: func.isRequired,
  setDelivery_selectedPermittedCostCenters: func.isRequired,
  setDelivery_selectedPermittedToSites: func.isRequired,
  setDelivery_selectedProcessState: func.isRequired,
  setDelivery_selectedRecipient: func.isRequired,
  setDelivery_selectedSettledStatus: func.isRequired,
  setDelivery_selectedSupplier: func.isRequired,
  setDelivery_selectedToSiteRecipient: func.isRequired,
  setDelivery_selectedToSiteSupplier: func.isRequired,
  setDelivery_tab: func.isRequired,
  setDeliveryList_filterModel: func.isRequired,
  userFeatureFlags: object.isRequired,
  userinfo: object.isRequired,
};

export const DeliveryFilterGroups = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryFilterGroupsComponent);
