import { useMutation } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';
import ToastService from '~/services/toast.service';

import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';

import Log from '~/utils/Log';

type DeleteFromUserGroupParams = {
  memberId: UUID;
  memberType: Pick<EntityType, 'user' | 'user_group'>;
  userGroupId: UUID;
};

/**
 * Removes a member (user or user group) from a user group.
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/remove_member_from_user_group_access_control_user_group__user_group_id___member_type___member_id__delete
 * @param userGroupId - UUID of the user group to remove the member from
 * @param memberType - Type of member being removed ('user' or 'user_group')
 * @param memberId - UUID of the member being removed
 * @returns Promise containing the API response
 * @throws Will throw an error if the API call fails
 */
export const deleteFromUserGroup = async (
  userGroupId: UUID,
  memberType: Pick<EntityType, 'user' | 'user_group'>,
  memberId: UUID,
) => {
  try {
    const response = await vestigasApi
      .delete(
        ENDPOINT.USER_GROUP.DELETE_FROM(userGroupId, memberType, memberId),
      )
      .json();

    return response;
  } catch (error) {
    Log.error('Error removing member from user group', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query mutation hook for removing a member (user or user group) from a user group.
 * @param options - Optional React Query mutation options
 * @returns Mutation object containing mutation function and state
 */
export const useMutationDeleteFromUserGroup = (
  options?: Parameters<typeof useMutation>[0],
) =>
  useMutation({
    mutationFn: async ({
      memberId,
      memberType,
      userGroupId,
    }: DeleteFromUserGroupParams) =>
      deleteFromUserGroup(userGroupId, memberType, memberId),
    onError(error: unknown) {
      Log.productAnalyticsEvent(
        'Failed to remove member from user group',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.ERROR,
      );

      ToastService.httpError(
        ['Failed to remove member from user group'],
        error,
      );
    },
    ...options,
  });
