import SignatureField from '~/models/signatureField/SignatureField';

export const selectSignatureFields = (
  data,
  customFields,
  companies,
  sites,
  costCenters,
) => ({
  rows:
    data.items.map((signatureField) => ({
      ...signatureField,
      company: companies.find(
        (company) => company.id === signatureField.companyId,
      )?.name,
      costCenter: costCenters.find(
        (costCenter) => costCenter.id === signatureField.costCenterId,
      )?.name,
      customField: customFields.find(
        (customField) => customField.id === signatureField.customFieldId,
      )?.name,
      pathString: SignatureField.getPathStringFromKey(signatureField.pathKey),
      required: signatureField.required ? 'Ja' : 'Nein',
      role: SignatureField.getRoleString(signatureField.role),
      site: sites.find((site) => site.id === signatureField.siteId)?.name,
    })) ?? [],
  signatureFields:
    data.items.map((customField) => new SignatureField(customField)) ?? [],
  totalCount: data.count ?? 0,
});
