import { useMutation } from '@tanstack/react-query';
import snakecaseKeys from 'snakecase-keys';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';
import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

type PushNotificationSettings = Record<string, unknown>; // TODO: Define proper type

type UpdatePushNotificationSettingsResponse = {
  code: number;
  message: string;
  requestId: UUID;
};

/**
 * Updates push notification settings for a given user.
 *
 * @param {UUID} userId - The ID of the user whose settings to update.
 * @param {PushNotificationSettings} settings - The push notification settings to update.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/update_user_notification_settings_user_notification_settings_put
 */
export const updatePushNotificationSettings = async (
  userId: UUID,
  settings: PushNotificationSettings,
): Promise<UpdatePushNotificationSettingsResponse> => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.USER.UPDATE_PUSH_NOTIFICATION_SETTINGS(), {
        json: settings,
        searchParams: snakecaseKeys({ userId }), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<UpdatePushNotificationSettingsResponse>();

    return response;
  } catch (error) {
    Log.error('Error updating push notification settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating push notification settings for a user.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationUpdatePushNotificationSettings = (
  options?: Parameters<typeof useMutation>[0],
) => {
  return useMutation({
    mutationFn: async ({
      userId,
      settings,
    }: {
      userId: UUID;
      settings: PushNotificationSettings;
    }) => updatePushNotificationSettings(userId, settings),

    onError(error) {
      Log.productAnalyticsEvent(
        'Failed to update push notification settings',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );

      const lines = ['Failed to update push notification settings'];
      ToastService.httpError(lines, error.response);
    },
    ...options,
  });
};
