import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysUser } from './queryKeys';

type UserSettings = Record<string, unknown>;

type MutationUserArguments = {
  userId: UUID;
  userSettings: Partial<UserSettings>;
};

/**
 * Updates the settings of a given user.
 *
 * @param {string} userId - The ID of the user to update.
 * @param {Object} featureFlags - An object containing the settings to update.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Admin/operation/update_user_settings_admin_user__id__settings_post
 */
export const updateUserSettings = async (
  userId: UUID,
  userSettings: Partial<UserSettings>,
) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.USER.UPDATE_SETTINGS(userId), {
        json: userSettings,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating user settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating the settings of a given user.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationUpdateUserSettings = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ userId, userSettings }: MutationUserArguments) =>
      updateUserSettings(userId, userSettings),
    onError(error, { userId }) {
      Log.productAnalyticsEvent(
        'Failed to update user settings',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(data, { userId }) {
      queryClient.invalidateQueries({
        queryKey: queryKeysUser.get(userId),
      });
    },
    ...options,
  });
};
