import { memo, type ReactNode } from 'react';

import { cn } from '~/utils/tailwind';

type P = {
  Filters?: ReactNode;
  SearchInput?: ReactNode;
  CreateItemButton?: ReactNode;
} & ComponentStyling;

export const FiltersRow = memo(
  ({ className, CreateItemButton, Filters, SearchInput, style }: P) => (
    <div className={cn('flex items-center gap-4', className)} style={style}>
      {Filters && <div className="flex items-center gap-2">{Filters}</div>}
      {SearchInput && <div className="w-80">{SearchInput}</div>}
      {CreateItemButton && <div className="ml-auto">{CreateItemButton}</div>}
    </div>
  ),
);
