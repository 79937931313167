export default class DeliveryNoteValidationResult {
  constructor(validationResult) {
    this.status = validationResult?.cat;
    this.message = validationResult?.msg;
    this.backendPath = validationResult?.loc;
  }

  static STATUS = {
    ERROR: 'err',
    WARNING: 'war',
  };
}
