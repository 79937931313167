import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  queryKeysOrganizationalUnit,
  useMutationUpdateOrganizationalUnits,
} from '~/data/organizationalUnit';
import {
  queryKeysUserGroup,
  useMutationCreateUserGroup,
  useMutationUpdateUserGroup,
  useMutationUpdateUserGroups,
  useQueryUserGroup,
} from '~/data/userGroup';

import ToastService from '~/services/toast.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';
import UserGroup from '~/models/masterdata/UserGroup';

import { useDelayedValue } from '~/hooks/useDelayedValue';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

export const useUserGroupForm = ({ closeForm, userGroupId, type }) => {
  const isCreatingUserGroup = type === 'create';

  const queryClient = useQueryClient();

  const {
    data: userGroup,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchUserGroup,
  } = useQueryUserGroup(userGroupId, {
    enabled: !isCreatingUserGroup && Boolean(userGroupId),
    placeholderData() {
      // Try to get some basic data from the list item cache to improve the rendering experience.
      const queriesData = queryClient.getQueriesData({
        queryKey: queryKeysUserGroup.getAll().filter(Boolean),
      });

      for (const [, data] of queriesData) {
        if (Array.isArray(data?.data)) {
          const listItem = data?.data?.find(({ id }) => id === userGroupId);

          if (listItem) {
            const { id, name } = listItem;

            return new UserGroup({ id, name });
          }
        }
      }

      return undefined;
    },
    select: (data) => new UserGroup(data),
  });

  const refetchUserGroups = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: queryKeysUserGroup.getAll({}),
    });
  }, [queryClient]);

  const getDefaultUserGroup = () => new UserGroup();

  const [state, setState] = useState({
    isDeleting: false,

    userGroup: isCreatingUserGroup
      ? getDefaultUserGroup()
      : new UserGroup(userGroup),
  });

  const {
    mutateAsync: createUserGroupMutation,
    isPending: isPendingCreateUserGroup,
  } = useMutationCreateUserGroup();

  const {
    mutateAsync: updateUserGroupMutation,
    isPending: isPendingUpdateUserGroup,
  } = useMutationUpdateUserGroup();

  const {
    mutateAsync: updateOrganizationalUnitsMutation,
    isPending: isPendingUpdateOrganizationalUnits,
  } = useMutationUpdateOrganizationalUnits({
    onError(error) {
      Log.error('updateOrganizationalUnitsMutation error');

      ToastService.httpError(
        ['Organisations-Gruppen konnten nicht geändert werden.'],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to update organisational groups',
        Log.FEATURE.VEHICLE,
        Log.TYPE.ERROR,
      );
    },
  });

  const {
    mutateAsync: updateParentUserGroupsMutation,
    isPending: isPendingUpdateParentUserGroups,
  } = useMutationUpdateUserGroups({
    onError(error) {
      Log.error('updateParentUserGroupsMutation error');

      ToastService.httpError(
        ['Übergeordnete Benutzer-Gruppen konnten nicht geändert werden.'],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to update parent user groups',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.ERROR,
      );
    },
  });

  const {
    mutateAsync: updateChildUserGroupsMutation,
    isPending: isPendingUpdateChildUserGroups,
  } = useMutationUpdateUserGroups({
    onError(error) {
      Log.error('updateChildUserGroupsMutation error');

      ToastService.httpError(
        ['Untergeordnete Benutzer-Gruppen konnten nicht geändert werden.'],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to update child user groups',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.ERROR,
      );
    },
  });

  const { mutateAsync: updateUsersMutation, isPending: isPendingUpdateUsers } =
    useMutationUpdateUserGroups({
      onError(error) {
        Log.error('updateUsersMutation error');

        ToastService.httpError(
          ['Nutzer konnten nicht geändert werden.'],
          error.response,
        );

        Log.productAnalyticsEvent(
          'Failed to update users',
          Log.FEATURE.USER_GROUP,
          Log.TYPE.ERROR,
        );
      },
    });

  const isSubmitting =
    isPendingCreateUserGroup ||
    isPendingUpdateUserGroup ||
    isPendingUpdateOrganizationalUnits ||
    isPendingUpdateParentUserGroups ||
    isPendingUpdateChildUserGroups ||
    isPendingUpdateUsers;

  const resetForm = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      userGroup: userGroup ?? getDefaultUserGroup(),
    }));
  }, [userGroup]);

  useEffect(() => {
    resetForm();
  }, [isSuccess, isError, JSON.stringify(userGroup)]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;

    setState((previousState) => ({
      ...previousState,
      userGroup: {
        ...previousState.userGroup,
        [name]: value,
      },
    }));
  }, []);

  const handleChangeOrganisationalGroups = useCallback(({ added, removed }) => {
    setState((previousState) => {
      const newUserGroup = cloneDeep(previousState.userGroup);

      const removedIds = new Set(removed.map(({ id }) => id));
      const addedIds = new Set(added.map(({ id }) => id));

      newUserGroup.organisationalGroups = [
        ...new Set([
          ...newUserGroup.organisationalGroups.filter(
            (id) => !removedIds.has(id),
          ),
          ...addedIds,
        ]),
      ];

      Log.info(
        'Change form value of organisational groups',
        {
          from: previousState.userGroup.organisationalGroups,
          to: newUserGroup.organisationalGroups,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change organisational groups',
        Log.FEATURE.USER_GROUP,
      );

      return {
        ...previousState,
        userGroup: newUserGroup,
      };
    });
  }, []);

  const handleChangeParentUserGroups = ({ added, removed }, setState) => {
    setState((previousState) => {
      const newUserGroup = cloneDeep(previousState.userGroup);

      const removedIds = new Set(removed.map(({ id }) => id));
      const addedIds = new Set(added.map(({ id }) => id));

      newUserGroup.parentUserGroups = [
        ...new Set([
          ...newUserGroup.parentUserGroups.filter((id) => !removedIds.has(id)),
          ...addedIds,
        ]),
      ];

      Log.info(
        'Change form value of parent user groups',
        {
          from: previousState.userGroup.parentUserGroups,
          to: newUserGroup.parentUserGroups,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change parent user groups',
        Log.FEATURE.USER_GROUP,
      );

      return {
        ...previousState,
        userGroup: newUserGroup,
      };
    });
  };

  const handleChangeChildUserGroups = ({ added, removed }, setState) => {
    setState((previousState) => {
      const newUserGroup = cloneDeep(previousState.userGroup);

      const removedIds = new Set(removed.map(({ id }) => id));
      const addedIds = new Set(added.map(({ id }) => id));

      newUserGroup.childUserGroups = [
        ...new Set([
          ...newUserGroup.childUserGroups.filter((id) => !removedIds.has(id)),
          ...addedIds,
        ]),
      ];

      Log.info(
        'Change form value of child user groups',
        {
          from: previousState.userGroup.childUserGroups,
          to: newUserGroup.childUserGroups,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change child user groups',
        Log.FEATURE.USER_GROUP,
      );

      return {
        ...previousState,
        userGroup: newUserGroup,
      };
    });
  };

  const handleChangeUsers = ({ added, removed }, setState) => {
    setState((previousState) => {
      const newUserGroup = cloneDeep(previousState.userGroup);

      const removedIds = new Set(removed.map(({ id }) => id));
      const addedIds = new Set(added.map(({ id }) => id));

      newUserGroup.users = [
        ...new Set([
          ...newUserGroup.users.filter((id) => !removedIds.has(id)),
          ...addedIds,
        ]),
      ];

      Log.info(
        'Change form value of users',
        {
          from: previousState.userGroup.users,
          to: newUserGroup.users,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change users', Log.FEATURE.USER_GROUP);

      return {
        ...previousState,
        userGroup: newUserGroup,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (state.userGroup.organisationalGroups.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Organisations-Gruppe aus, zu der diese Benutzer-Gruppe gehört.',
      ]);

      Log.productAnalyticsEvent(
        'Missing organisational group',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    const body = {
      childUserGroups: state.userGroup.childUserGroups,
      name: state.userGroup.name,
      orgUnits: state.userGroup.organisationalGroups,
      parentUserGroups: state.userGroup.parentUserGroups,
      users: state.userGroup.users,
    };

    let userGroupId = userGroup?.id;
    try {
      if (isCreatingUserGroup) {
        const { id } = await createUserGroupMutation(body);
        userGroupId = id;
      } else {
        await updateUserGroupMutation({
          userGroupData: body,
          userGroupId: userGroup.id,
        });

        const [deletedOrganizationalUnits, addedOrganizationalUnits] =
          ArrayUtils.getDifference(
            userGroup.organisationalGroups,
            state.userGroup.organisationalGroups,
          );

        await updateOrganizationalUnitsMutation({
          addedMembers: addedOrganizationalUnits,
          deletedMembers: deletedOrganizationalUnits,
          entityId: userGroupId,
          entityType: PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY,
          updateType: 'updateParentOrganizationalUnits',
        });

        const [deletedParentUserGroups, addedParentUserGroups] =
          ArrayUtils.getDifference(
            userGroup.parentUserGroups,
            state.userGroup.parentUserGroups,
          );

        await updateParentUserGroupsMutation({
          addedMembers: addedParentUserGroups,
          deletedMembers: deletedParentUserGroups,
          memberType: PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY,
          updateType: 'updateParentUserGroups',
          userGroupId,
        });

        const [deletedUsers, addedUsers] = ArrayUtils.getDifference(
          userGroup.users,
          state.userGroup.users,
        );

        await updateUsersMutation({
          addedMembers: addedUsers,
          deletedMembers: deletedUsers,
          memberType: PermissionGrant.SUBJECT_TYPE.USER.KEY,
          updateType: 'updateUserGroupEntities',
          userGroupId,
        });

        const [deletedChildUserGroups, addedChildUserGroups] =
          ArrayUtils.getDifference(
            userGroup.childUserGroups,
            state.userGroup.childUserGroups,
          );

        await updateChildUserGroupsMutation({
          addedMembers: addedChildUserGroups,
          deletedMembers: deletedChildUserGroups,
          memberType: PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY,
          updateType: 'updateUserGroupEntities',
          userGroupId,
        });
      }

      closeForm();
      resetForm();

      refetchUserGroup({ userGroupId });
      refetchUserGroups();

      // Refetch MultiItemsManager queries.
      queryClient.invalidateQueries({
        queryKey: queryKeysOrganizationalUnit.getAll(),
        refetchType: 'all',
      });
    } catch (error) {
      ToastService.error(['Benutzer-Gruppe konnte nicht gespeichert werden.']);
      Log.error('Failed to save user group', error);
    }
  };

  const handleCancel = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.USER_GROUP);
    closeForm();
    resetForm();
  };

  const unsavedChanges = useMemo(() => {
    if (isCreatingUserGroup) {
      return [];
    }

    if (!isSuccess || !userGroup || !state.userGroup) {
      return [];
    }

    return UserGroup.getDifferentValues(userGroup, state.userGroup);
  }, [
    JSON.stringify(userGroup),
    JSON.stringify(state.userGroup),
    isCreatingUserGroup,
    isSuccess,
  ]);

  const delayedUnsavedChanges = useDelayedValue(unsavedChanges);

  return {
    handleCancel,
    handleChangeChildUserGroups,
    handleChangeOrganisationalGroups: ({ added, removed }) =>
      handleChangeOrganisationalGroups({ added, removed }, setState),
    handleChangeParentUserGroups: ({ added, removed }) =>
      handleChangeParentUserGroups({ added, removed }, setState),
    handleChangeUsers: ({ added, removed }) =>
      handleChangeUsers({ added, removed }, setState),
    handleInputChange,
    handleSubmit,
    isCreatingUserGroup,
    isDeleting: state.isDeleting,
    isError,
    isLoading,
    isSubmitting,
    refetchUserGroup,
    unsavedChanges: delayedUnsavedChanges,
    userGroup: state.userGroup,
  };
};
