import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysOrganizationalUnit } from './queryKeys';

/**
 * Deletes an organizational unit.
 *
 * @param {UUID} organizationalUnitId - The ID of the organizational unit to delete.
 * @returns {Promise<void>} A promise that resolves when the deletion is complete.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/delete_organizational_unit_access_control_org_unit__org_unit_id__delete
 */
export const deleteOrganizationalUnit = async (organizationalUnitId: UUID) => {
  try {
    await vestigasApi.delete(
      ENDPOINT.ORGANIZATIONAL_UNIT.DELETE(organizationalUnitId),
    );
  } catch (error) {
    Log.error('Error deleting organizational unit', error);

    throw error; // re-throw error so it can be handled higher up in the callstack
  }
};

/**
 * Custom hook for deleting an organizational unit.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationDeleteOrganizationalUnit = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (organizationalUnitId: UUID) =>
      deleteOrganizationalUnit(organizationalUnitId),
    async onError(_error) {
      Log.productAnalyticsEvent(
        'Failed to delete organizational unit',
        Log.FEATURE.ORGANIZATIONAL_UNIT,
        Log.TYPE.ERROR,
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeysOrganizationalUnit.getAll({}),
      });
    },
    ...options,
  });
};
