import { ComponentTestIds } from '~/constants/test-ids';
import { Spinner } from '~/components/Spinner';

export const LoadingState = ({ title = 'Lade Daten' }) => (
  <div
    data-testid={ComponentTestIds.TABLE.LOADING}
    className="flex h-full flex-col items-center justify-center"
  >
    <Spinner title={title} />
  </div>
);
