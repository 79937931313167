import React from 'react';
import { Grid } from '@mui/material';
import UnitUtils from '~/utils/unitUtils';
import { withErrorBoundary } from '~/ui/atoms';
import ValueGroup from '~/models/deliveries/ValueGroup';
import { EditingHistorySafe as EditingHistory } from '../deliveries/deliveryNote/EditingHistorySafe';
import Concrete from '~/models/articles/Concrete';

class ArticleConcrete extends React.Component {
  getConcatenatedExposureAndMoistureClass = (data) => {
    let classes = data?.exposureClass ?? '';
    classes += classes ? '\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0' : '';
    classes += data?.moistureClass ?? '';
    return classes;
  };

  getConcatenatedUsageAndChlorideClass(data) {
    let output = data?.usage ?? '';
    output += output ? '\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0' : '';
    output += data?.chlorideClass ?? '';
    return output;
  }

  getConcatenatedDelivery(data) {
    const requestedValue = UnitUtils.roundAndFormatDe_safe(data.requestedValue);
    const requestedUnit = UnitUtils.getAbbreviatedUnit(data.requestedUnit);
    const actualValue = UnitUtils.roundAndFormatDe_safe(data.actualValue);
    const actualUnit = UnitUtils.getAbbreviatedUnit(data.actualUnit);
    const remainingValue = UnitUtils.roundAndFormatDe_safe(data.remainingValue);
    const remainingUnit = UnitUtils.getAbbreviatedUnit(data?.remainingUnit);

    return (
      <>
        {requestedValue === null ? (
          <>-</>
        ) : (
          <>
            <>{requestedValue + ' '}</>
            {requestedUnit}
          </>
        )}
        <>{'\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0'}</>
        {actualValue === null ? (
          <>-</>
        ) : (
          <>
            <>{actualValue + ' '}</>
            {actualUnit}
          </>
        )}
        <>{'\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0'}</>
        {remainingValue === null ? (
          <>-</>
        ) : (
          <>
            <>{remainingValue + ' '}</>
            {remainingUnit}
          </>
        )}
      </>
    );
  }

  render() {
    return (
      <Grid container spacing="20px">
        <Grid item xs={12} lg={6}>
          <div>Expositionsklasse / Feuchtigkeitsklasse</div>
          <div className="bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                {
                  exposureClass: this.props.concrete?.exposureClass,
                  moistureClass: this.props.concrete?.moistureClass,
                },
                this.getConcatenatedExposureAndMoistureClass,
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>Festigkeitsklasse</div>
          <div className="bold">
            <EditingHistory value={this.props.concrete?.strengthClass} />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>Konsistenzklasse</div>
          <div className="bold">
            <EditingHistory value={this.props.concrete?.consistency} />
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div>Verwendung / Klasse des Chloridgehaltes</div>
          <div className="bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                {
                  chlorideClass: this.props.concrete?.chlorideClass,
                  usage: this.props.concrete?.usage,
                },
                this.getConcatenatedUsageAndChlorideClass,
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>Größtkorn</div>
          <div className="bold">
            <EditingHistory value={this.props.concrete?.largestGrain} />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>Festigkeitsentwicklung</div>
          <div className="bold">
            <EditingHistory
              value={this.props.concrete?.strengthDevelopment}
              callback={Concrete.getDescriptiveStrengthDevelopment}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div>Menge (Soll | Ist | Rest)</div>
          <div className="bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                {
                  actualUnit: this.props.delivery?.actual?.unit,
                  actualValue: this.props.delivery?.actual?.value,
                  remainingUnit: this.props.delivery?.remaining?.unit,
                  remainingValue: this.props.delivery?.remaining?.value,
                  requestedUnit: this.props.delivery?.requested?.unit,
                  requestedValue: this.props.delivery?.requested?.value,
                },
                this.getConcatenatedDelivery,
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div>W/Z Verhältnis</div>
          <div className="bold">
            <EditingHistory
              value={this.props.concrete?.waterCementRatio}
              callback={UnitUtils.formatDe_safe}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withErrorBoundary(
  ArticleConcrete,
  'Betondaten konnten nicht geladen werden.',
);
