import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

const signatureFieldsSlice = createSlice({
  initialState: {
    signatureFields: [],
    signatureFieldsLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'signatureFields',
  reducers: {
    replaceSignatureFields(state, parameter) {
      const { payload } = parameter;

      state.signatureFields = payload;
      state.signatureFieldsLoading = LOADING_STATE.SUCCEEDED;
    },
    setSignatureFieldsLoading(state, parameter) {
      const { payload } = parameter;

      state.signatureFieldsLoading = payload;
    },
  },
});

const { actions, reducer } = signatureFieldsSlice;
export const { replaceSignatureFields, setSignatureFieldsLoading } = actions;
export default reducer;
