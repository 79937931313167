import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { getCompaniesQueryOptions } from '~/data/company';
import { getCostCentersQueryOptions } from '~/data/costCenter';
import { getOrganizationalUnitsQueryOptions } from '~/data/organizationalUnit';
import { getSitesQueryOptions } from '~/data/site';
import { getUsersQueryOptions, useQueryUserData } from '~/data/user';
import { getUserGroupsQueryOptions } from '~/data/userGroup';

import { SentryRoute } from '~/services/thirdParty.service';
import DataSubscriptionService from '~/services/dataSubscription.service';
import UserService from '~/services/user.service';

import { setPageTitle } from '~/redux/menuSlice';

import { ROUTE } from '~/constants/Route';

import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';

import UserUtils from '~/utils/userUtils';

import { UnauthorizedPage } from '../menu/UnauthorizedPage';

import { ArticlesTable } from './admin/articleMaster/ArticlesTable';
import { CategoriesTable } from './admin/articleMaster/CategoriesTable';
import { CustomerSubscriptionsTable } from './admin/customerSubscription/CustomerSubscriptionsTable';
import { CustomFieldsTable } from './admin/customField/CustomFieldsTable';
import DataSubscriptions from './admin/dataSubscriptions/DataSubscriptions';
import { InvoiceCheckIgnoredArticlesTable } from './admin/invoiceCheckIgnoredArticle/InvoiceCheckIgnoredArticlesTable';
import PdfSettings from './admin/pdf/PdfSettings';
import { SignatureFieldsTable } from './admin/signatureField/SignatureFieldsTable';

import { CompaniesTable } from './masterData/company/CompaniesTable';
import { CostCentersTable } from './masterData/costCenter/CostCentersTable';
import { OrganizationalUnitsTable } from './masterData/organizationalUnit/OrganizationalUnitsTable';
import { MasterData } from './masterData/MasterData';
import { SitesTable } from './masterData/site/SitesTable';
import { UserGroupsTable } from './masterData/userGroup/UserGroupsTable';
import { UsersTable } from './masterData/users/UsersTable';
import { VehiclesTable } from './masterData/vehicle/VehiclesTable';

import { DataProtection } from './dataProtection/DataProtection';
import ProfileSettings from './profile/ProfileSettings';
import SupportSettings from './support/SupportSettings';
import { Demo} from './Demo';
import Impressum from './Impressum';

import { SettingsNav } from './SettingsNav';

export const Settings = withErrorBoundary(
  memo(() => {
    const queryClient = useQueryClient();

    const {
      data: currentUser,
      isLoading,
      isSuccess,
    } = useQueryUserData(true, {
      select: (data) => ({
        companyAccountInfo: data.companyAccount ?? {},
        companyInfo: data.company ?? {},
        userPermissions: data.totalPermissions ?? [],
      }),
    });

    const companyInfo = currentUser?.companyInfo ?? {};
    const companyAccountInfo = currentUser?.companyAccountInfo ?? {};
    const featureFlags =
      currentUser?.companyAccountInfo?.data?.featureFlags ?? {};
    const userPermissions = currentUser?.userPermissions ?? [];

    const dispatch = useDispatch();
    const location = useLocation();

    const isVestigasAccount = useMemo(() => UserUtils.isVestigasAccount(), []);

    const isVestigasSupportAccount = useMemo(
      () => UserUtils.isVestigasSupportAccount(),
      [],
    );

    useEffect(() => {
      // Prefetch first page of users, cost centers, sites, and companies.
      queryClient.prefetchQuery(getCompaniesQueryOptions({}));
      queryClient.prefetchQuery(
        getCostCentersQueryOptions({
          queryParams: {
            filterActive: true,
          },
        }),
      );
      queryClient.prefetchQuery(getOrganizationalUnitsQueryOptions({}));
      queryClient.prefetchQuery(
        getSitesQueryOptions({
          queryParams: {
            filterActive: true,
          },
        }),
      );
      queryClient.prefetchQuery(
        getUsersQueryOptions({
          queryParams: {
            filterActive: true,
          },
        }),
      );
      queryClient.prefetchQuery(getUserGroupsQueryOptions({}));
    }, []);

    useEffect(() => {
      if (!companyInfo.id || !companyAccountInfo.id) {
        // Wait for useData to be loaded as it is required for several requests.
        return;
      }

      if (isVestigasSupportAccount) {
        DataSubscriptionService.loadContactPoints();
        DataSubscriptionService.loadDataSubscriptions();
        DataSubscriptionService.loadNotificationPolicies();
      }

      dispatch(setPageTitle('Einstellungen'));

      document.title = 'VESTIGAS - Einstellungen';
    }, [
      companyAccountInfo.id,
      companyInfo.id,
      dispatch,
      isSuccess,
      isVestigasAccount,
      isVestigasSupportAccount,
    ]);

    return (
      <div className="main-padding">
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} lg={3}>
            <SettingsNav />
          </Grid>
          <Grid item xs={12} lg={9}>
            {isLoading ? (
              <Spinner title="Lade Einstellungen..." />
            ) : UserService.userIsAuthorizedForPage(
                location.pathname,
                userPermissions,
                featureFlags,
              ) ? (
              <Switch>
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS.ROUTE}
                  component={ProfileSettings}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_PROFILE.ROUTE}
                  component={ProfileSettings}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_SUPPORT.ROUTE}
                  component={SupportSettings}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_COMPANY.ROUTE}
                  component={CompaniesTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_MASTERDATA.ROUTE}
                  component={MasterData}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_USER.ROUTE}
                  component={UsersTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_SITE.ROUTE}
                  component={SitesTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_COST_CENTER.ROUTE}
                  component={CostCentersTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_VEHICLE.ROUTE}
                  component={VehiclesTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_ORGANISATIONAL_GROUP.ROUTE}
                  component={OrganizationalUnitsTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_USER_GROUP.ROUTE}
                  component={UserGroupsTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_DATA_SUBSCRIPTIONS.ROUTE}
                  component={DataSubscriptions}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_CUSTOM_FIELDS.ROUTE}
                  component={CustomFieldsTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_SIGNATURE_FIELDS.ROUTE}
                  component={SignatureFieldsTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_ARTICLES.ROUTE}
                  component={ArticlesTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_CATEGORIES.ROUTE}
                  component={CategoriesTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_CUSTOMER_SUBSCRIPTIONS.ROUTE}
                  component={CustomerSubscriptionsTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_PDF.ROUTE}
                  component={PdfSettings}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_INVOICE_CHECK_IGNORED_ARTICLES.ROUTE}
                  component={InvoiceCheckIgnoredArticlesTable}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_DEMO.ROUTE}
                  component={Demo}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_DATA_PROTECTION.ROUTE}
                  component={DataProtection}
                />
                <SentryRoute
                  exact
                  path={ROUTE.SETTINGS_IMPRESSUM.ROUTE}
                  component={Impressum}
                />
              </Switch>
            ) : (
              <UnauthorizedPage />
            )}
          </Grid>
        </Grid>
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
      </div>
    );
  }),
  'Einstellungen konnten nicht geladen werden.',
);

Settings.displayName = 'Settings';
