import { queryKeysOrganizationalUnit } from '~/data/organizationalUnit';

import MasterDataService from '~/services/masterData.service';
import ToastService from '~/services/toast.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

const handleUserCreationError = (error) => {
  Log.error('Failed to create user.', error);

  if (error.response?.status === 409) {
    ToastService.httpError(
      [ToastService.MESSAGE.USER_CREATION_FAILED_DUPLICATE],
      error.response,
    );

    Log.productAnalyticsEvent(
      'Failed to create due to duplicate user',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );
  } else {
    ToastService.httpError(
      [ToastService.MESSAGE.USER_CREATION_FAILED],
      error.response,
    );

    Log.productAnalyticsEvent(
      'Failed to create',
      Log.FEATURE.USER,
      Log.TYPE.ERROR,
    );
  }
};

export const handleSubmit = async ({
  closeForm,
  createUserMutation,
  event,
  isCreatingUser,
  originalData,
  queryClient,
  refetchUser,
  refetchUsers,
  resetForm,
  updateOrganizationalUnitsMutation,
  updatePushNotificationSettingsMutation,
  updateUserMutation,
  updateUserSettingsMutation,
  updateUserSignatureRolesMutation,
  user,
}) => {
  event.preventDefault();

  const {
    companyId,
    email,
    featureFlags,
    firstName,
    isActive,
    lastName,
    organisationalGroups,
    password,
    position,
    pushNotificationSettings,
    signatureRoles,
  } = user;

  try {
    const body = {
      companyId,
      email,
      firstName,
      isActive,
      lastName,
      position,
      username: email,
      userType: signatureRoles?.getUserType?.() ?? null,
      ...(password && { password }),
      ...(isCreatingUser && {
        orgUnits: organisationalGroups,
        permittedSignatureTypes: signatureRoles?.getSignatureRoles?.() ?? [],
      }),
    };

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['companyId'],
        Log.FEATURE.USER,
      )
    ) {
      throw new Error('Missing required properties');
    }

    Log.info('Submit user form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.USER);

    let userId;
    if (isCreatingUser) {
      try {
        const { id } = await createUserMutation(body);
        userId = id;
      } catch (error) {
        handleUserCreationError(error);

        return; // Exit early on error
      }
    } else {
      try {
        await updateUserMutation({
          userData: body,
          userId: user.id,
        });
        userId = user.id;
      } catch (error) {
        ToastService.httpError(
          [ToastService.MESSAGE.USER_UPDATE_FAILED],
          error.response,
        );

        Log.productAnalyticsEvent(
          'Failed to update user',
          Log.FEATURE.USER,
          Log.TYPE.ERROR,
        );

        return; // Exit early on error
      }
    }

    // Only proceed with additional updates if user creation / update succeeded.
    const updatePromises = [];

    if (
      JSON.stringify(featureFlags) !==
      JSON.stringify(originalData?.featureFlags)
    ) {
      updatePromises.push(
        updateUserSettingsMutation({
          userId,
          userSettings: {
            featureFlags,
          },
        }),
      );
    }

    if (
      signatureRoles?.defaultSignatureRole !==
      originalData?.signatureRoles?.defaultSignatureRole
    ) {
      updatePromises.push(
        updateUserSettingsMutation({
          userId,
          userSettings: {
            defaultSignatureRole: signatureRoles?.defaultSignatureRole ?? null,
          },
        }),
      );
    }

    const shouldUpdatePushNotificationSettings =
      JSON.stringify(pushNotificationSettings) !==
      JSON.stringify(originalData?.pushNotificationSettings);
    const canUpdatePushNotificationSettings =
      UserUtils.isVestigasAccount() || UserUtils.isUserWriteAllowedUser();

    if (
      shouldUpdatePushNotificationSettings &&
      canUpdatePushNotificationSettings
    ) {
      updatePromises.push(
        updatePushNotificationSettingsMutation({
          settings: pushNotificationSettings.getBackendFormat(),
          userId,
        }),
      );
    }

    // Additional updates for existing users:
    if (!isCreatingUser) {
      if (
        JSON.stringify(organisationalGroups) !==
        JSON.stringify(originalData.organisationalGroups)
      ) {
        const [deletedOrganizationalUnits, addedOrganizationalUnits] =
          ArrayUtils.getDifference(
            originalData.organisationalGroups,
            organisationalGroups,
          );

        await updateOrganizationalUnitsMutation({
          addedMembers: addedOrganizationalUnits,
          deletedMembers: deletedOrganizationalUnits,
          entityId: userId,
          entityType: PermissionGrant.ENTITY_TYPE.USER.KEY,
          updateType: 'updateParentOrganizationalUnits',
        });
      }

      if (
        JSON.stringify(signatureRoles) !==
          JSON.stringify(originalData.signatureRoles) &&
        UserUtils.isVestigasAccount()
      ) {
        updatePromises.push(
          updateUserSignatureRolesMutation({
            userId,
            userSignatureRoles: user.signatureRoles,
          }),
        );
      }
    }

    // Execute all additional updates in parallel
    try {
      await Promise.all(updatePromises);
    } catch (error) {
      Log.error('Error updating additional user settings', error);
      ToastService.error('Some user settings could not be updated');
      // Continue with form completion since the main user update succeeded
    }

    refetchUser();
    refetchUsers();

    // Invalidate MultiItemsManager queries
    queryClient.invalidateQueries({
      queryKey: queryKeysOrganizationalUnit.getAll(),
      refetchType: 'all',
    });

    closeForm();
    resetForm();

    ToastService.success(
      [
        'Benutzer',
        [firstName, lastName].filter(Boolean).join(' '),
        isCreatingUser ? 'erstellt' : 'aktualisiert',
      ]
        .filter(Boolean)
        .join(' '),
    );
  } catch (error) {
    Log.error('Unexpected error in handleSubmit', error);
    ToastService.error('Ein unerwarteter Fehler ist aufgetreten');
  }
};
