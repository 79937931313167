import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { connect } from 'react-redux';
import { Add as AddIcon } from '@mui/icons-material';
import { Button, InputLabel, MenuItem, Select, Grid } from '@mui/material';

import PdfSettingsService from '~/services/pdfsettings.service';
import ToastService from '~/services/toast.service';

import PdfTemplate from '~/models/masterdata/PdfTemplate';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import BasicForm from '~/components/BasicForm';

import { MultiSelectCompanies } from '~/ui/molecules/SelectServerDriven';

const mapStateToProps = (state) => ({
  companies: state.companies,
});
const mapDispatchToProps = () => ({});

class PdfTemplateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      pdfTemplate: this.getDefaultPdfTemplate(),
      submittingForm: false,
    };
  }

  componentDidMount() {
    this.setState({
      pdfTemplate: this.props.pdfTemplate,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.pdfTemplate) !==
      JSON.stringify(prevProps.pdfTemplate)
    ) {
      this.setState({
        pdfTemplate: this.props.pdfTemplate,
      });
    }
  }

  formSuccess = async (event) => {
    event.preventDefault();

    this.setState({
      submittingForm: true,
    });

    let body = {
      file: this.state.file,
      type: this.state.pdfTemplate.type,
    };

    // if the user hasn't selected the whole company account, add the selected company to the body
    if (
      this.state.pdfTemplate.company.id !== this.props.companyAccountOption.ID
    ) {
      body = {
        ...body,
        company_id: this.state.pdfTemplate.company.id,
      };
    }

    Log.info('Submit pdf template form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.PDF_TEMPLATE);

    const [data, error] = await promiseHandler(
      PdfSettingsService.createPdfTemplate(body),
    );

    if (error) {
      ToastService.httpError(
        [ToastService.MESSAGE.PDF_TEMPLATE_CREATION_FAILED],
        error.response,
      );
      Log.error('Failed to create pdf template.', error);
      Log.productAnalyticsEvent(
        'Failed to create',
        Log.FEATURE.PDF_TEMPLATE,
        Log.TYPE.ERROR,
      );
      this.setState({
        submittingForm: false,
      });
      return;
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    PdfSettingsService.refreshPdfTemplates();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.PDF_TEMPLATE);
    this.props.closeForm();
  };

  getDefaultPdfTemplate() {
    const pdfTemplate = new PdfTemplate();

    pdfTemplate.type = PdfTemplate.TYPE.DEFAULT.KEY;
    pdfTemplate.company.id = this.props.companyAccountOption.ID;

    return pdfTemplate;
  }

  handleChangeFile = (event) => {
    Log.info('Upload pdf template', null, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Upload template', Log.FEATURE.PDF_TEMPLATE);

    const newPdfTemplate = cloneDeep(this.state.pdfTemplate);

    newPdfTemplate.filename = event.target.files[0]?.name;

    this.setState({
      file: event.target.files[0],
      pdfTemplate: newPdfTemplate,
    });
  };
  handleChangeCompany = (value) => {
    const newPdfTemplate = cloneDeep(this.state.pdfTemplate);

    newPdfTemplate.company.id = value.id;

    Log.info(
      'Change form value of companies',
      {
        from: this.state.pdfTemplate.company.id,
        to: newPdfTemplate.company.id,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change company', Log.FEATURE.PDF_TEMPLATE);

    this.setState({
      pdfTemplate: newPdfTemplate,
    });
  };

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title="Erstellen"
        submittingForm={this.state.submittingForm}
      >
        <Grid
          container
          direction="row"
          spacing={3}
          space={4}
          className="w-600px"
        >
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <InputLabel className="text-13px">Firma</InputLabel>
                <MultiSelectCompanies
                  placeholder={'Firma'}
                  value={
                    this.state.pdfTemplate.company.id
                      ? [this.state.pdfTemplate.company.id]
                      : []
                  }
                  onChange={this.handleChangeCompany}
                  isMultiSelect={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={8} className="flex-s-e">
                <div className="flex-s-c gap-10px">
                  <label htmlFor="input-docx-upload">
                    <input
                      type="file"
                      className="hidden"
                      accept=".docx"
                      id="input-docx-upload"
                      onChange={this.handleChangeFile}
                    />
                    <Button
                      className="primary-button"
                      startIcon={<AddIcon />}
                      component="span"
                    >
                      Hochladen
                    </Button>
                  </label>
                  {this.state.pdfTemplate?.filename ? (
                    <div className="rounded-15px h-30px max-w-300px pl-15px pr-15px bg-grey100 line-h-30px text-overflow">
                      {this.state.pdfTemplate?.filename}
                    </div>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(PdfTemplateForm);
