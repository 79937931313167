import { queryKeysOrganizationalUnit } from '~/data/organizationalUnit';

import MasterDataService from '~/services/masterData.service';
import ToastService from '~/services/toast.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

const handleCreateError = (error) => {
  if (error.response?.status === 409) {
    ToastService.httpError(
      [ToastService.MESSAGE.COST_CENTER_CREATION_FAILED_DUPLICATE],
      error.response,
    );

    Log.productAnalyticsEvent(
      'Failed to create duplicate',
      Log.FEATURE.COST_CENTER,
      Log.TYPE.ERROR,
    );

    return;
  }

  ToastService.httpError(
    [ToastService.MESSAGE.COST_CENTER_CREATION_FAILED],
    error.response,
  );

  Log.productAnalyticsEvent(
    'Failed to create',
    Log.FEATURE.COST_CENTER,
    Log.TYPE.ERROR,
  );
  Log.error('Failed to create cost center.', error);
};

export const handleSubmit = async ({
  closeForm,
  costCenter,
  createCostCenterMutation,
  isCreatingCostCenter,
  originalData,
  queryClient,
  refetchCostCenter,
  refetchCostCenters,
  resetForm,
  setCostCenter,
  updateCostCenterMutation,
  updateOrganizationalUnitsMutation,
}) => {
  try {
    const { companyId, end, isActive, name, organisationalGroups, start } =
      costCenter;

    const body = {
      companyId,
      end,
      isActive,
      name,
      start,
    };

    if (isCreatingCostCenter) {
      body.orgUnits = organisationalGroups;
    }

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['companyId'],
        Log.FEATURE.COST_CENTER,
      )
    ) {
      throw new Error('Missing required properties');
    }

    Log.info('Submit cost center form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.COST_CENTER);

    let costCenterId;
    if (isCreatingCostCenter) {
      try {
        const { id } = await createCostCenterMutation(body);
        costCenterId = id;

        if (setCostCenter) {
          setCostCenter(id);
        }
      } catch (error) {
        handleCreateError(error);
        return;
      }
    } else {
      try {
        await updateCostCenterMutation({
          costCenterData: body,
          costCenterId: costCenter.id,
        });
        costCenterId = costCenter.id;

        const [deletedOrganizationalUnits, addedOrganizationalUnits] =
          ArrayUtils.getDifference(
            originalData.organisationalGroups,
            costCenter.organisationalGroups,
          );

        await updateOrganizationalUnitsMutation({
          addedMembers: addedOrganizationalUnits,
          deletedMembers: deletedOrganizationalUnits,
          entityId: costCenterId,
          entityType: PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
          updateType: 'updateParentOrganizationalUnits',
        });
      } catch (error) {
        ToastService.httpError(
          [ToastService.MESSAGE.COST_CENTER_UPDATE_FAILED],
          error.response,
        );

        Log.error(`Failed to update cost center. id: ${costCenter.id}`, error);
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.COST_CENTER,
          Log.TYPE.ERROR,
        );

        return;
      }
    }

    refetchCostCenter(costCenterId);
    refetchCostCenters();
    closeForm();
    resetForm(true);

    // Refetch MultiItemsManager queries.
    queryClient.invalidateQueries({
      queryKey: queryKeysOrganizationalUnit.getAll(),
      refetchType: 'all',
    });

    ToastService.success(
      `Kostenstelle ${name} ${isCreatingCostCenter ? 'erstellt' : 'aktualisiert'}`,
    );
  } catch (error) {
    Log.error('Unexpected error in handleSubmit', error);
    ToastService.error('Ein unerwarteter Fehler ist aufgetreten');
  }
};
