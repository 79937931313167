import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { withErrorBoundary } from '~/ui/atoms';

import AuthService from '~/services/auth.service';
import ThirdPartyService from '~/services/thirdParty.service';

import { saveUserId, setUserHasLoggedInViaSSO } from '~/redux/userinfoSlice';

import UserUtils from '~/utils/userUtils';

import { DeactivatedAccountModal } from '~/components/DeactivatedAccountModal';
import { ExternalSupplierOverview } from '~/components/dataExchanges/ExternalSupplierOverview';
import { BayWaScreen } from '~/components/deliveries/bayWaScreen';
import { Spinner } from '~/components/Spinner';

import { AppLayout } from '../AppLayout';

import { useHandleAuthentication } from './useHandleAuthentication';
import { useInitIncomingInvoices } from './useInitIncomingInvoices';
import { useInitOutgoingInvoices } from './useInitOutgoingInvoices';
import { useLoadData } from './useLoadData';
import { useNotifyDataExchangeIssues } from './useNotifyDataExchangeIssues';

/**
 * AppInitializer
 *
 * This component is responsible for initializing the application, handling user authentication,
 * loading necessary data, and rendering the appropriate view based on the user's status and role.
 *
 * It manages the following tasks:
 * - Handles user authentication
 * - Loads required data upon successful login
 * - Initializes third-party services (e.g., Sentry)
 * - Dispatches user information to Redux store
 * - Initializes incoming and outgoing invoices
 * - Notifies about data exchange issues
 * - Renders special views for specific user roles (supplier overview, BayWa screen)
 * - Renders the main application view (AppLayout and DeactivatedAccountModal) for regular users
 *
 * The component is wrapped with an error boundary to handle potential errors during initialization.
 */
const AppInitializerComponent = () => {
  const dispatch = useDispatch();

  const { loadData } = useLoadData();

  const [userHasLoggedIn, setUserHasLoggedIn] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  useHandleAuthentication(setUserHasLoggedIn, setLoadingMessage);

  useEffect(() => {
    if (!userHasLoggedIn) {
      return;
    }

    // Load all required data when the user logs in.
    loadData();

    dispatch(setUserHasLoggedInViaSSO(AuthService.accessTokenContainsFicKey()));
  }, [userHasLoggedIn]);

  useInitIncomingInvoices();
  useInitOutgoingInvoices();
  useNotifyDataExchangeIssues();

  const userId = AuthService.getUserIdFromAccessToken();

  useEffect(() => {
    if (userId) {
      dispatch(saveUserId(userId));
    }
  }, [userId, dispatch]);

  if (userId) {
    ThirdPartyService.initSentryUser();

    if (userId === UserUtils.SUPPLIER_OVERVIEW_USER) {
      // Render overview of connected suppliers for related special-purpose user.
      return <ExternalSupplierOverview />;
    }

    if (userId === UserUtils.BAYWA_SCREEN_USER) {
      // Render custom BayWa screen for related special-purpose user.
      return <BayWaScreen />;
    }
  }

  if (!userHasLoggedIn) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner title={loadingMessage} />
      </div>
    );
  }

  return (
    <>
      <AppLayout />
      <DeactivatedAccountModal />
    </>
  );
};

export const AppInitializer = withErrorBoundary(
  AppInitializerComponent,
  'Web App konnte nicht geladen werden.',
);
