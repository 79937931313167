import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { withErrorBoundary } from '~/ui/atoms';
import { ROUTE } from '~/constants/Route';
import UnitUtils from '~/utils/unitUtils';
import BasicTable from '../BasicTable';
import LocalStorageService from '~/services/localStorage.service';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import { DeliveryCategoryIcon } from '../deliveries/DeliveryCategoryIcon';
import BrowserUtils from '~/utils/browserUtils';
import DatagridUtils from '~/utils/datagridUtils';
import DeliveryStatus from '../deliveries/DeliveryStatus';
import Log from '~/utils/Log';
import HeaderAccordion from '../baseComponents/accordion/HeaderAccordion';
import { setInvoiceReferencedDeliveryNotesExpanded } from '~/redux/invoicesSlice';
import { pluralizeWord } from '~/utils/pluralize';
import { dateUtils, parseDate } from '~/utils/dateUtils';
import ExportService from '~/services/export.service';
import ContextMenu from '../menu/ContextMenu';

function InvoiceReferencedDeliveryNotes(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.invoices);
  const [rowSelectionModel, setSelectionModel] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);

  const title = props.referencedDeliveryNoteRows?.length
    ? props.referencedDeliveryNoteRows.length +
      ' Verknüpfte ' +
      pluralizeWord(props.referencedDeliveryNoteRows.length, 'Lieferung')
    : 'Verknüpfte Lieferungen';

  const columns = [
    {
      field: DeliveryNote.PROPERTY.CATEGORY.KEY,
      headerName: DeliveryNote.PROPERTY.CATEGORY.STRING,
      renderCell(params) {
        return (
          <div className="flex-c-c w-70px">
            <DeliveryCategoryIcon
              category={DeliveryNote.getProcessCategoryKeyFromString(
                params.value,
              )}
            />
          </div>
        );
      },
      sortable: true,
      width: 100,
    },
    {
      field: DeliveryNote.PROPERTY.STATUS.KEY,
      headerName: DeliveryNote.PROPERTY.STATUS.STRING,
      renderCell(params) {
        const processState = params.value.split(';')?.[0];
        const combinedState = params.value.split(';')?.[2];
        const settledStatus = params.value.split(';')?.[3];

        const cookie = LocalStorageService.getObjectFromLocalStorage(
          LocalStorageService.INVOICE_REFERENCED_DELIVERY_NOTES,
        );

        const className =
          'w-170px ' +
          DatagridUtils.getStatusBoxHeight(
            cookie?.rowHeight ?? DatagridUtils.ROW_HEIGHT.THIN,
          );
        let centerIcon = cookie?.rowHeight <= DatagridUtils.ROW_HEIGHT.THIN;
        if (!cookie?.rowHeight) {
          centerIcon = true;
        } // Center icon as default because default row height is thin

        return (
          <div className={className}>
            <DeliveryStatus
              processState={processState}
              combinedState={combinedState}
              settledStatus={settledStatus}
              whiteBackground
              centerIcon={centerIcon}
            />
          </div>
        );
      },
      sortable: true,
      width: BrowserUtils.isWideScreen() ? 200 : 120,
    },
    {
      field: DeliveryNote.PROPERTY.NUMBER.KEY,
      headerName: DeliveryNote.PROPERTY.NUMBER.STRING,
      resizableText: true,
      sortable: true,
      width: 200,
    },
    {
      field: DeliveryNote.PROPERTY.DLN_DATE.KEY,
      headerName: DeliveryNote.PROPERTY.DLN_DATE.STRING,
      renderCell: (params) =>
        dateUtils.getFormattedDateWithoutMidnight_safe(
          params.value,
          dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
        ),
      resizableText: true,
      sortable: true,
      type: 'date',
      valueGetter: parseDate,
      width: 170,
    },
    {
      field: DeliveryNote.PROCESS_ROLE.SUPPLIER.KEY,
      headerName: DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING,
      resizableText: true,
      sortable: true,
      width: 250,
    },
    {
      field: DeliveryNote.PROCESS_ROLE.RECIPIENT.KEY,
      headerName: DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING,
      resizableText: true,
      sortable: true,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
      headerName: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
      resizableText: true,
      sortable: true,
      width: 400,
    },
    {
      field: DeliveryNote.PROPERTY.COST_CENTERS.KEY,
      headerName: DeliveryNote.PROPERTY.COST_CENTERS.STRING,
      resizableText: true,
      sortable: true,
      width: 150,
      ...DatagridUtils.customColumnTypeArray(),
    },
    {
      field: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.KEY,
      headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.STRING,
      resizableText: true,
      sortable: true,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_VALUE.KEY,
      headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_VALUE.STRING,
      renderCell: (params) => UnitUtils.formatDe_safe(params.value),
      resizableText: true,
      sortable: true,
      type: 'number',
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_UNIT.KEY,
      headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_UNIT.STRING,
      renderCell: (params) => UnitUtils.getAbbreviatedUnit(params.value),
      resizableText: true,
      sortable: true,
      width: 100,
    },
    {
      field: DeliveryNote.PROPERTY.DESCRIPTION.KEY,
      headerName: DeliveryNote.PROPERTY.DESCRIPTION.STRING,
      resizableText: true,
      sortable: true,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.COMMENTS.KEY,
      headerName: DeliveryNote.PROPERTY.COMMENTS.STRING,
      resizableText: true,
      sortable: true,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
      headerName: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING,
      resizableText: true,
      sortable: true,
      width: 400,
    },
    {
      field: DeliveryNote.PROPERTY.FROM_SITE.KEY,
      headerName: DeliveryNote.PROPERTY.FROM_SITE.STRING,
      resizableText: true,
      sortable: true,
      width: 400,
    },
    {
      field: DeliveryNote.PROCESS_ROLE.CARRIER.KEY,
      headerName: DeliveryNote.PROCESS_ROLE.CARRIER.STRING,
      resizableText: true,
      sortable: true,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.LICENSE_PLATE.KEY,
      headerName: DeliveryNote.PROPERTY.LICENSE_PLATE.STRING,
      resizableText: true,
      sortable: true,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.SELLER_ORDER_REFERENCES.KEY,
      headerName: DeliveryNote.PROPERTY.SELLER_ORDER_REFERENCES.STRING,
      resizableText: true,
      sortable: true,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.BUYER_ORDER_REFERENCES.KEY,
      headerName: DeliveryNote.PROPERTY.BUYER_ORDER_REFERENCES.STRING,
      resizableText: true,
      sortable: true,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.KEY,
      headerName: DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.STRING,
      resizableText: true,
      sortable: true,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.KEY,
      headerName: DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.STRING,
      resizableText: true,
      sortable: true,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.MOVEMENT_MEANS.KEY,
      headerName: DeliveryNote.PROPERTY.MOVEMENT_MEANS.STRING,
      resizableText: true,
      sortable: true,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.PROJECT.KEY,
      headerName: DeliveryNote.PROPERTY.PROJECT.STRING,
      resizableText: true,
      sortable: true,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.DELIVERY_TYPE.KEY,
      headerName: DeliveryNote.PROPERTY.DELIVERY_TYPE.STRING,
      resizableText: true,
      sortable: true,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.CREATION_DATE.KEY,
      headerName: DeliveryNote.PROPERTY.CREATION_DATE.STRING,
      renderCell: (params) =>
        dateUtils.getFormattedDate_safe(
          params.value,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ),
      resizableText: true,
      sortable: true,
      type: 'date',
      valueGetter: parseDate,
      width: 150,
    },
  ];

  const defaultHiddenColumns = [
    DeliveryNote.PROPERTY.CATEGORY.KEY,
    DeliveryNote.PROCESS_ROLE.SUPPLIER.KEY,
    DeliveryNote.PROCESS_ROLE.RECIPIENT.KEY,
    DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
    DeliveryNote.PROPERTY.COST_CENTERS.KEY,
    DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
    DeliveryNote.PROPERTY.FROM_SITE.KEY,
    DeliveryNote.PROCESS_ROLE.CARRIER.KEY,
    DeliveryNote.PROPERTY.LICENSE_PLATE.KEY,
    DeliveryNote.PROPERTY.SELLER_ORDER_REFERENCES.KEY,
    DeliveryNote.PROPERTY.BUYER_ORDER_REFERENCES.KEY,
    DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.KEY,
    DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.KEY,
    DeliveryNote.PROPERTY.MOVEMENT_MEANS.KEY,
    DeliveryNote.PROPERTY.PROJECT.KEY,
    DeliveryNote.PROPERTY.DELIVERY_TYPE.KEY,
    DeliveryNote.PROPERTY.CREATION_DATE.KEY,
  ];

  const onOpenDeliveryNote = (id) => {
    Log.productAnalyticsEvent('Open delivery note', Log.FEATURE.INVOICE);
    history.push(ROUTE.DELIVERY_NOTE.ROUTE + '/' + id);
  };

  const onOpenDeliveryNoteInNewTab = () => {
    Log.productAnalyticsEvent(
      'Open delivery note in new tab',
      Log.FEATURE.INVOICE,
    );
    BrowserUtils.openNewTab(ROUTE.DELIVERY_NOTE.ROUTE + '/' + contextMenu.id);
    onCloseContextMenu();
  };

  const onOpenContextMenu = (event) => {
    event.preventDefault();

    if (contextMenu) {
      // Repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu. Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context men
      setContextMenu(null);
      return;
    }

    Log.productAnalyticsEvent('Open context menu', Log.FEATURE.MENU);
    setContextMenu({
      id: event.currentTarget.dataset.id,
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
    });
  };

  const onCloseContextMenu = () => {
    Log.productAnalyticsEvent('Close context menu', Log.FEATURE.MENU);
    setContextMenu(null);
  };

  const onRowSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected delivery notes in invoice referenced list',
      { from: rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('(De)select delivery note', Log.FEATURE.INVOICE);

    setSelectionModel(event);
  };

  const onPdfExport = (downloadOption) => {
    ExportService.exportDeliveryNotes(rowSelectionModel, downloadOption);
  };

  return (
    <HeaderAccordion
      title={title}
      productAnalyticsFeature="invoice_referenced_dlns"
      expanded={invoices.invoiceReferencedDeliveryNotesExpanded}
      setExpanded={(value) =>
        dispatch(setInvoiceReferencedDeliveryNotesExpanded(value))
      }
    >
      <div className="mt-10px rounded-5px flex-1">
        <BasicTable
          rows={props.referencedDeliveryNoteRows}
          columns={columns}
          checkboxSelection
          onRowClick={(rowData) => onOpenDeliveryNote(rowData.row.id)}
          onRowRightClick={onOpenContextMenu}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          onPdfExport={onPdfExport}
          multiplePdfDownload={rowSelectionModel.length > 1}
          loading={props.loading}
          defaultHiddenColumns={defaultHiddenColumns}
          defaultRowHeight={DatagridUtils.ROW_HEIGHT.THIN}
          pageSize={10}
          autoHeight
          localStorageKey={
            LocalStorageService.INVOICE_REFERENCED_DELIVERY_NOTES
          }
        />
        <ContextMenu
          contextMenu={contextMenu}
          onClose={onCloseContextMenu}
          onOpen={() => onOpenDeliveryNote(contextMenu.id)}
          onOpenInNewTab={onOpenDeliveryNoteInNewTab}
        />
      </div>
    </HeaderAccordion>
  );
}

export default withErrorBoundary(
  InvoiceReferencedDeliveryNotes,
  'Verknüpfte Lieferungen konnten nicht geladen werden.',
);
