import { cn } from '~/utils/tailwind';

export const AccordionBody = ({ children, className, id, open, testId }) => {
  if (!open) {
    return null;
  }

  return (
    <div
      className={cn('overflow-scroll', className)}
      id={`accordion-${id}`}
      data-testid={testId}
    >
      {children}
    </div>
  );
};
