import { Checkbox, FormControlLabel } from '@mui/material';

import SignatureRoles from '~/models/masterdata/SignatureRoles';

import { Spinner } from '~/components/Spinner';

const SIGNATURE_ROLES = ['supplier', 'carrier', 'recipient'];

export const SignatureRolesSettings = ({
  isError,
  isLoading,
  onChange,
  signatureRoles,
}) => {
  if (isLoading) {
    return (
      <div className="h-12 w-96">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return <div className="h-12 w-96">Daten konnten nicht geladen werden.</div>;
  }

  if (!signatureRoles) {
    return null;
  }

  return (
    <>
      {SIGNATURE_ROLES.map((role) => (
        <FormControlLabel
          key={role}
          control={
            <Checkbox
              checked={Boolean(signatureRoles?.[role])}
              onChange={onChange}
              name={`signature_role_${role}`}
            />
          }
          label={`Als ${SignatureRoles.SIGNATURE_ROLE[role.toUpperCase()].STRING}`}
          className="mr-12"
        />
      ))}
    </>
  );
};
