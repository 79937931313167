import { HTTPError } from 'ky';
import {
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';
import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysCostCenter } from './queryKeys';

type AccountingReferenceType = 'cost_center' | 'profit_center';

type CreateCostCenterPayload = {
  name: string;
  type: AccountingReferenceType;
  companyId?: UUID;
  companySyncKey?: string;
  orgUnits?: UUID | UUID[];
  orgUnitsSyncKeys?: string[];
  syncKey?: string;
  start?: string; // ISO date-time string
  end?: string; // ISO date-time string
};

const defaultPayload: CreateCostCenterPayload = {
  name: '',
  type: 'cost_center',
};

/**
 * Creates a new cost center (aka accounting reference).
 *
 * @param {CreateCostCenterPayload} costCenterData - The data for creating the cost center
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Accounting-Reference/operation/create_accounting_reference_accounting_reference_post
 */
export const createCostCenter = async (
  costCenterData: CreateCostCenterPayload,
) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.COST_CENTER.CREATE(), {
        json: {
          ...defaultPayload,
          ...costCenterData,
        },
      })
      .json<{ id: UUID }>();

    return response;
  } catch (error) {
    let errorMessage = '';

    if (error instanceof HTTPError) {
      switch (error.response.status) {
        case 409: {
          errorMessage =
            'Kostenstelle existiert bereits und konnte daher nicht erstellt werden.';
          break;
        }

        default: {
          errorMessage = 'Kostenstelle konnte nicht erstellt werden.';
        }
      }
    }

    ToastService.error(errorMessage);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for creating a cost center.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationCreateCostCenter = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (costCenterData: CreateCostCenterPayload) =>
      createCostCenter(costCenterData),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeysCostCenter.getAll({}),
      });

      Log.productAnalyticsEvent(
        'Create cost center success',
        Log.FEATURE.COST_CENTER,
      );
    },
    ...options,
  });
};
