import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import Log from '~/utils/Log';

import {
  MultiSelectCompanies,
  MultiSelectCostCenters,
  MultiSelectOrganizationalUnits,
  MultiSelectSites,
  MultiSelectUserGroups,
  MultiSelectUsers,
  MultiSelectVehicles,
} from '~/ui/molecules/SelectServerDriven';

const getSubjectLabel = (subjectType, subjects) => {
  const count = subjects.length;
  const isUser = subjectType === PermissionGrant.SUBJECT_TYPE.USER.KEY;

  return count <= 1
    ? `soll ${isUser ? 'dieser Benutzer' : 'diese Benutzer-Gruppe'}`
    : `sollen diese ${count} ${isUser ? 'Benutzer' : 'Benutzer-Gruppen'}`;
};

const getTextfieldLabel = (type, subjectType, entityType, subjects) => {
  if (type === 'permission_for') {
    return subjectType === PermissionGrant.SUBJECT_TYPE.USER.KEY
      ? 'Welche Benutzer sollen berechtigt werden?'
      : 'Welche Benutzer-Gruppen sollen berechtigt werden?';
  }

  if (type === 'permission_to') {
    const subjectLabel = getSubjectLabel(subjectType, subjects);
    const entityLabels = {
      [PermissionGrant.ENTITY_TYPE.COMPANY.KEY]: 'Firmen',
      [PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY]: 'Kostenstellen',
      [PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY]:
        'Organisations-Gruppen',
      [PermissionGrant.ENTITY_TYPE.SITE.KEY]: 'Standorte',
      [PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY]: 'Benutzer-Gruppen',
      [PermissionGrant.ENTITY_TYPE.USER.KEY]: 'Benutzer',
      [PermissionGrant.ENTITY_TYPE.VEHICLE.KEY]: 'Fahrzeuge',
    };

    return `Auf welche ${entityLabels[entityType]} ${subjectLabel} berechtigt werden?`;
  }

  throw new EnumValueNotFoundException(`Invalid type: ${type}`);
};

const MultiSelectComponents = {
  [PermissionGrant.ENTITY_TYPE.COMPANY.KEY]: MultiSelectCompanies,
  [PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY]: MultiSelectCostCenters,
  [PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY]:
    MultiSelectOrganizationalUnits,
  [PermissionGrant.ENTITY_TYPE.SITE.KEY]: MultiSelectSites,
  [PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY]: MultiSelectUserGroups,
  [PermissionGrant.ENTITY_TYPE.USER.KEY]: MultiSelectUsers,
  [PermissionGrant.ENTITY_TYPE.VEHICLE.KEY]: MultiSelectVehicles,
};

export const PermissionGrantMultiPicker = ({
  disabled,
  displayPermissionGrantOfCostCentersWithSites,
  entityType,
  onChange,
  pickedIds,
  subjects,
  subjectType,
  type,
}) => {
  const textfieldLabel = getTextfieldLabel(
    type,
    subjectType,
    entityType,
    subjects,
  );
  const MultiSelectComponent = MultiSelectComponents[entityType ?? subjectType];

  if (!MultiSelectComponent) {
    Log.error(
      null,
      new EnumValueNotFoundException(
        `Invalid entity type: ${entityType ?? subjectType}`,
      ),
    );

    return <>Komponente nicht gefunden</>;
  }

  return (
    <MultiSelectComponent
      placeholder={textfieldLabel}
      value={pickedIds}
      onChange={onChange}
      displayPermissionGrantOfCostCentersWithSites={
        displayPermissionGrantOfCostCentersWithSites
      }
      isDisabled={disabled}
      className="mt-auto w-full"
      fullWidth
    />
  );
};
