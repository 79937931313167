import { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { type Location, type History } from 'history';

import { LOADING_STATE } from '~/constants/LoadingState';
import { ROUTE } from '~/constants/Route';

import { useQueryUserData } from '~/data/user';

import LocalStorageService from '~/services/localStorage.service';
import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import BrowserUtils from '~/utils/browserUtils';
import Log from '~/utils/Log';

import { type MainNavItemType } from './types';

type P = {
  isNavCollapsed: boolean;
  items: MainNavItemType[];
  setIsNavCollapsed: (isNavCollapsed: boolean) => void;
};

const filterAvailableNavItems = (
  navItems: MainNavItemType[],
  userPermissions: string[],
  featureFlags: Record<string, boolean>,
) => {
  const defaultDisplayedNavItems = new Set([
    ROUTE.DASHBOARD.ROUTE,
    ROUTE.DELIVERIES.ROUTE,
  ]);

  const clientPortalDisplayedNavItems = new Set([
    ROUTE.CONCRETE_DIARY.ROUTE,
    ROUTE.DASHBOARD.ROUTE,
    ROUTE.DELIVERIES.ROUTE,
    ROUTE.INCOMING_INVOICES.ROUTE,
    ROUTE.OUTGOING_INVOICES.ROUTE,
  ]);

  const moduleInvoiceRestrictionDisplayedNavItems = new Set([
    ROUTE.INCOMING_INVOICES.ROUTE,
    ROUTE.OUTGOING_INVOICES.ROUTE,
  ]);

  const packageBasicRestrictionDisplayedNavItems = new Set([
    ROUTE.CONCRETE_DIARY.ROUTE,
    ROUTE.DASHBOARD.ROUTE,
    ROUTE.DELIVERIES.ROUTE,
  ]);

  return navItems
    .map((navItem) => ({
      ...navItem,
      children:
        navItem?.children?.filter(({ route }) => {
          return (
            defaultDisplayedNavItems.has(route) ||
            (featureFlags?.clientPortal &&
              clientPortalDisplayedNavItems.has(route)) ||
            (featureFlags?.moduleInvoiceRestriction &&
              moduleInvoiceRestrictionDisplayedNavItems.has(route)) ||
            (featureFlags?.packageBasicRestriction &&
              packageBasicRestrictionDisplayedNavItems.has(route)) ||
            UserService.userIsAuthorizedForPage(
              route,
              userPermissions,
              featureFlags,
            )
          );
        }) ?? [],
    }))
    .filter(
      ({ children, route }) =>
        // Keep items that either have a route themselves or have valid children.
        route || children.length > 0,
    );
};

export const useMainNav = ({ isNavCollapsed, items, setIsNavCollapsed }: P) => {
  const location: Location = useLocation();
  const history: History = useHistory();

  const { data: currentUser, isLoading: isLoadingUserData } =
    useQueryUserData(true);

  const userPermissions = currentUser?.userPermissions ?? [];
  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};

  const allowedMainNavItems = filterAvailableNavItems(
    items,
    userPermissions,
    featureFlags,
  );

  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());

  const handleChildItemClick = useCallback(
    (route: string, callback?: () => void) => {
      if (!route) {
        console.warn('Attempted to navigate to an undefined route');
        return;
      }

      if (
        !UserService.userIsAuthorizedForPage(
          route,
          userPermissions,
          featureFlags,
        )
      ) {
        switch (isLoadingUserData) {
          case LOADING_STATE.LOADING: {
            ToastService.info(['Berechtigungen werden geladen...']);
            break;
          }

          case LOADING_STATE.SUCCEEDED: {
            ToastService.warning([
              'Kein Zugriff: Fordere die fehlenden Berechtigungen bei deinem Admin an.',
            ]);

            Log.productAnalyticsEvent(
              'Missing access for this page',
              Log.FEATURE.MENU,
              Log.TYPE.ERROR,
            );

            break;
          }

          case LOADING_STATE.FAILED: {
            ToastService.error([
              'Berechtigungen konnten nicht geladen werden.',
              ToastService.MESSAGE.CONTACT_SUPPORT,
            ]);

            Log.productAnalyticsEvent(
              'Failed to load user permissions',
              Log.FEATURE.MENU,
              Log.TYPE.ERROR,
            );

            break;
          }

          default: {
            break;
          }
        }

        return;
      }

      history.push(route);
      if (callback) {
        callback();
      }
    },
    [history, userPermissions, isLoadingUserData],
  );

  const handleNavItemClick = useCallback(
    (name: string, route: string, callback?: () => void) => {
      if (route) {
        Log.productAnalyticsEvent(`Open ${name}`, Log.FEATURE.MENU);
        handleChildItemClick(route, callback);
      } else {
        console.warn(`Attempted to open menu item without route: ${name}`);
      }
    },
    [handleChildItemClick],
  );

  const handleChildItemsToggle = useCallback(
    (name: string) => {
      setExpandedItems((previous) => {
        const newSet = new Set(previous);
        if (newSet.has(name)) {
          newSet.delete(name);
        } else {
          newSet.add(name);
        }

        return newSet;
      });

      Log.productAnalyticsEvent(
        expandedItems.has(name) ? 'Hide menu item' : 'Expand menu item',
        Log.FEATURE.MENU,
      );
    },
    [expandedItems],
  );

  const handleContextMenu = useCallback(
    (event: React.MouseEvent, name: string, route: string) => {
      event.preventDefault();

      Log.productAnalyticsEvent(
        `Open ${name} via context menu`,
        Log.FEATURE.MENU,
      );

      BrowserUtils.openMenuItemInNewTab(name, route);
    },
    [],
  );

  const handleMainNavToggle = useCallback(async () => {
    if (isNavCollapsed) {
      Log.productAnalyticsEvent('Open main nav', Log.FEATURE.MENU);
    } else {
      Log.productAnalyticsEvent('Close main nav', Log.FEATURE.MENU);
    }

    setIsNavCollapsed(!isNavCollapsed);
    LocalStorageService.setLocalStorage(
      LocalStorageService.HIDE_DRAWER,
      !isNavCollapsed,
    );
  }, [isNavCollapsed, setIsNavCollapsed]);

  useEffect(() => {
    const currentPath = location.pathname;
    const newExpandedItems = new Set<string>();

    for (const item of items) {
      if (
        item.children.length > 1 &&
        item.children.some(({ route }) => route === currentPath)
      ) {
        newExpandedItems.add(item.name);
      }
    }

    setExpandedItems(newExpandedItems);
  }, [location, items]);

  const isChildItemOpen = useCallback(
    (itemName: string) => expandedItems.has(itemName),
    [expandedItems],
  );

  return {
    allowedMainNavItems,
    expandedItems,
    handleChildItemClick,
    handleChildItemsToggle,
    handleContextMenu,
    handleMainNavToggle,
    handleNavItemClick,
    isChildItemOpen,
  };
};
