import cloneDeep from 'lodash/cloneDeep';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import { useQueryClient } from '@tanstack/react-query';

import {
  queryKeysCompany,
  useMutationCompanyLogoDelete,
  useMutationUpsertCompany,
  useQueryCompany,
} from '~/data/company';
import { useMutationUpdateOrganizationalUnits } from '~/data/organizationalUnit';

import { removeCompanyLogo } from '~/redux/companiesSlice';

import CompanyService from '~/services/company.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import ToastService from '~/services/toast.service';

import Address from '~/models/masterdata/Address';
import Company from '~/models/masterdata/Company';
import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { useDelayedValue } from '~/hooks/useDelayedValue';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

export const useCompanyForm = ({ closeForm, companyId, type }) => {
  const isCreatingCompany = type === 'create';

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const {
    data: company,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchCompany,
  } = useQueryCompany(companyId, {
    enabled: !isCreatingCompany && Boolean(companyId),
    placeholderData() {
      // Try to get some basic data from the list item cache to improve the rendering experience.
      const queriesData = queryClient.getQueriesData({
        queryKey: queryKeysCompany.getAll().filter(Boolean),
      });

      for (const [, data] of queriesData) {
        if (Array.isArray(data?.data)) {
          const listItem = data?.data?.find(({ id }) => id === companyId);

          if (listItem) {
            const { address, end, id, isActive, name, start } = listItem;

            return new Company({ address, end, id, isActive, name, start });
          }
        }
      }

      return undefined;
    },
    select: (data) => new Company(data),
  });

  const refetchCompanies = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: queryKeysCompany.getAll({}),
    });
  }, [queryClient]);

  const getDefaultCompany = useCallback(() => {
    const defaultCompany = new Company();
    defaultCompany.address.country = Address.DEFAULT_COUNTRY_CODE.DE;

    return defaultCompany;
  }, []);

  const [state, setState] = useState({
    company: getDefaultCompany(),
    companyLogo: null,
    companyLogoHasBeenUpdated: false,
  });

  const { mutate: upsertCompany, isPending: isPendingUpsertCompany } =
    useMutationUpsertCompany();

  const { mutate: deleteCompanyLogo, isPending: isPendingDeleteCompanyLogo } =
    useMutationCompanyLogoDelete(state.company?.id);

  const {
    mutateAsync: updateOrganizationalUnitsMutation,
    isPending: isPendingUpdateOrganizationalUnits,
  } = useMutationUpdateOrganizationalUnits({
    onError(error) {
      Log.error('updateOrganizationalUnitsMutation error');

      ToastService.httpError(
        ['Organisations-Gruppen konnten nicht geändert werden.'],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to update organisational groups',
        Log.FEATURE.COMPANY,
        Log.TYPE.ERROR,
      );
    },
  });

  useEffect(() => {
    resetForm();
  }, [isSuccess, isError, JSON.stringify(company)]);

  const isSubmitting =
    isPendingUpsertCompany ||
    isPendingDeleteCompanyLogo ||
    isPendingUpdateOrganizationalUnits;

  const resetForm = useCallback(() => {
    setState({
      company: company ?? getDefaultCompany(),
      companyLogo: null,
      companyLogoHasBeenUpdated: false,
    });
  }, [company, getDefaultCompany]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { address, name, organisationalGroups } = state.company;
    const body = { address, name };

    if (isCreatingCompany) {
      body.orgUnits = organisationalGroups;
    }

    Log.info('Submit company form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.COMPANY);

    if (isCreatingCompany) {
      // TODO: replace with mutation useMutationCreateCompany
      const [companyId, error] = await promiseHandler(
        CompanyService.createNewCompany(snakecaseKeys(body, { deep: true })),
      );

      if (error) {
        handleFormError('COMPANY_CREATION_FAILED', error);
        return;
      }

      await uploadCompanyLogo(companyId);
    } else {
      const body = {
        address: state.company?.address,
        name: state.company?.name,
      };

      upsertCompany(
        {
          companyDetails: body,
          companyId: state.company?.id,
        },
        {
          onSuccess() {
            resetForm();
            closeForm();
          },
        },
      );
    }

    closeForm();
    resetForm();

    refetchCompany();
    refetchCompanies();
  };

  const handleCancel = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.COMPANY);

    closeForm();
    resetForm(true);
  };

  const handleFormError = (message, error) => {
    ToastService.httpError([ToastService.MESSAGE[message]], error.response);

    Log.error(`Failed to process company form.`, error);
    Log.productAnalyticsEvent(
      'Failed to process',
      Log.FEATURE.COMPANY,
      Log.TYPE.ERROR,
    );
  };

  const uploadCompanyLogo = async (companyId) => {
    if (!(state.companyLogo?.size > 0)) {
      return;
    }

    Log.productAnalyticsEvent('Upload company logo', Log.FEATURE.COMPANY);

    // TODO: replace with mutation useMutationCompanyLogoUpload
    const [, error] = await promiseHandler(
      CompanyService.uploadCompanyLogo(companyId, state.companyLogo),
    );

    if (error) {
      handleFormError('COMPANY_LOGO_UPLOAD_FAILED', error);
      return;
    }

    dispatch(removeCompanyLogo(companyId));
  };

  const handleCompanyLogoUpdate = async () => {
    if (state.companyLogo?.size > 0) {
      await uploadCompanyLogo(state.company?.id);
      return;
    }

    // TODO: replace with mutation useMutationCompanyLogoDelete
    const [, error] = await promiseHandler(
      CompanyService.deleteCompanyLogo(state.company?.id),
    );

    if (error) {
      handleFormError('COMPANY_LOGO_DELETION_FAILED', error);
      return;
    }

    dispatch(removeCompanyLogo(state.company?.id));
  };

  // TODO: this is not called anymore
  const updateCompany = async (body) => {
    // TODO: replace with mutation
    const [, error] = await promiseHandler(
      CompanyService.updateCompany(
        state.company?.id,
        snakecaseKeys(body, { deep: true }),
      ),
    );

    if (error) {
      handleFormError('COMPANY_UPDATE_FAILED', error);
      return;
    }

    // TODO: replace with mutation
    const [, error2] = await promiseHandler(
      OrganisationalGroupService.updateParentOrganisationalGroups(
        company.id,
        PermissionGrant.ENTITY_TYPE.COMPANY.KEY,
        company.organisationalGroups,
        state.company.organisationalGroups,
      ),
    );

    if (error2) {
      handleFormError('ORGANISATIONAL_GROUP_UPDATE_FAILED', error2);
      return;
    }

    await handleCompanyLogoUpdate();
  };

  const handleInputChange = (event) => {
    const newCompany = cloneDeep(state.company);

    const { name, value } = event.target;

    switch (name) {
      case 'name': {
        newCompany.name = value;
        break;
      }

      case 'streetName': {
        newCompany.address.streetName = value;
        break;
      }

      case 'buildingNumber': {
        newCompany.address.buildingNumber = value;
        break;
      }

      case 'city': {
        newCompany.address.city = value;
        break;
      }

      case 'postCode': {
        newCompany.address.postCode = value;
        break;
      }

      case 'country': {
        newCompany.address.country = value;
        break;
      }
    }

    Log.info('Change form value', {
      from: state.company[name],
      to: newCompany[name],
    });

    setState((previousState) => ({
      ...previousState,
      company: newCompany,
    }));
  };

  const handleChangeOrganisationalGroups = ({ added, removed }, setState) => {
    setState((previousState) => {
      const newCompany = cloneDeep(state.company);

      const removedIds = new Set(removed.map(({ id }) => id));
      const addedIds = new Set(added.map(({ id }) => id));

      newCompany.organisationalGroups = [
        ...new Set([
          ...newCompany.organisationalGroups.filter(
            (id) => !removedIds.has(id),
          ),
          ...addedIds,
        ]),
      ];

      Log.info(
        'Change form value of organisational groups',
        {
          from: previousState.company.organisationalGroups,
          to: newCompany.organisationalGroups,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change organisational groups',
        Log.FEATURE.COMPANY,
      );

      return {
        ...previousState,
        company: newCompany,
      };
    });
  };

  const setCompanyLogo = (companyLogo) => {
    setState((previousState) => ({
      ...previousState,
      companyLogo,
      companyLogoHasBeenUpdated: true,
    }));
  };

  const unsavedChanges = useMemo(() => {
    if (isCreatingCompany) {
      return [];
    }

    if (isLoading || isError || !company) {
      return [];
    }

    const differentValues = Company.getDifferentValues(company, state.company);
    if (state.companyLogoHasBeenUpdated) {
      differentValues.push('Firmenlogo');
    }

    return differentValues;
  }, [
    JSON.stringify(company),
    JSON.stringify(state.company),
    isCreatingCompany,
    isLoading,
    isError,
  ]);

  const delayedUnsavedChanges = useDelayedValue(unsavedChanges);

  return {
    company: state.company,
    companyLogo: state.companyLogo,
    handleCancel,
    handleChangeOrganisationalGroups: ({ added, removed }) =>
      handleChangeOrganisationalGroups({ added, removed }, setState),
    handleInputChange,
    handleSubmit,
    isCreatingCompany,
    isLoadingCompany: isLoading,
    isSubmitting,
    refetchCompany,
    setCompanyLogo,
    unsavedChanges: delayedUnsavedChanges,
  };
};
