import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

/**
 * Validates the selection of users for signature requests and compares it to the suggested (permitted) users.
 *
 * @param selectedUserIds - An array of UUIDs representing the selected users.
 * @param permittedUserIds - An array of UUIDs representing the permitted users.
 * @returns A boolean indicating whether the selection is valid (true) or not (false).
 *
 * This function performs the following checks:
 * 1. Ensures at least one user is selected.
 * 2. Logs analytics events for form submission and user selection changes.
 * 3. Compares selected users against permitted users to track additions or removals.
 */
export const validateSelection = (
  selectedUserIds: UUID[],
  permittedUserIds: UUID[],
) => {
  if (selectedUserIds.length === 0) {
    ToastService.warning([
      'Bitte wähle mindestens einen Benutzer aus, der die Lieferung signieren soll.',
    ]);

    Log.productAnalyticsEvent(
      'Missing user for signature',
      Log.FEATURE.NOTIFICATIONS,
      Log.TYPE.FAILED_VALIDATION,
    );

    return false;
  }

  Log.productAnalyticsEvent(
    'Submit request signature form',
    Log.FEATURE.NOTIFICATIONS,
  );

  const hasPermittedButNotSelectedIds = permittedUserIds.some(
    (id) => !selectedUserIds.includes(id),
  );

  if (hasPermittedButNotSelectedIds) {
    Log.productAnalyticsEvent(
      'Removed user(s) from default list of permitted users in request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );
  }

  const hasSelectedButNotPermittedIds = selectedUserIds.some(
    (id) => !permittedUserIds.includes(id),
  );

  if (hasSelectedButNotPermittedIds) {
    Log.productAnalyticsEvent(
      'Added user(s) to default list of permitted users in request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );
  }

  return true;
};
