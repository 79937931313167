import { filterDeliveries } from '~/components/deliveries/utils';

import Log from '~/utils/Log';

/**
 * Filters the delivery rows on the client side based on the selected filters and updates the state accordingly.
 * Filtering is key functionality and needs to be highly optimized for CPU and memory consumption.
 * The code uses a web worker to improve resource utilization on the client side.
 *
 * @param {Array} rows - The delivery rows to filter.
 * @param {Object} selected - The applied filters values (acceptState, costCenter, etc.).
 * @param {string} query - The search query.
 * @param {Object} dateRange - The selected date range.
 * @param {string} selectField - The selected field for filtering.
 * @param {Function} setFilteredRows - Callback function to update the filtered rows in the state.
 * @param {Function} setLoading - Callback function to update the loading state.
 */
export const filterRows = (
  rows,
  selected,
  query,
  dateRange,
  selectField,
  setFilteredRows,
  setLoading,
) => {
  setLoading(true);

  const data = {
    dateRange,
    query,
    rows,
    selectedAcceptState: selected.acceptState,
    selectedArticle: selected.article,
    selectedArticleNumber: selected.articleNumber,
    selectedCostCenter: selected.costCenter,
    selectedCustomFields: selected.customFields,
    selectedFromSite: selected.fromSite,
    selectedNumber: selected.number,
    selectedPermittedCostCenters: selected.permittedCostCenters,
    selectedPermittedToSites: selected.permittedToSites,
    selectedProcessState: selected.processState,
    selectedRecipient: selected.recipient,
    selectedSettledStatus: selected.settledStatus,
    selectedSupplier: selected.supplier,
    selectedToSiteRecipient: selected.toSiteRecipient,
    selectedToSiteSupplier: selected.toSiteSupplier,
    selectField,
  };

  const filterDeliveriesWorker = new Worker(
    new URL('../../../utils/filterDeliveriesWorker.js', import.meta.url),
    {
      name: 'filterDeliveriesWorker',
      type: 'module',
    },
  );

  filterDeliveriesWorker.addEventListener('message', (event) => {
    setFilteredRows(event.data);
    setLoading(false);

    // Make sure the worker is terminated after receiving the message.
    // It should self close internally, so this is just in case it doesn't.
    filterDeliveriesWorker.terminate();
  });

  filterDeliveriesWorker.addEventListener('error', (event) => {
    Log.info('Log return value from failed filterDeliveriesWorker - 1.', event);
    Log.info('Log return value from failed filterDeliveriesWorker - 2.', {
      currentTarget: JSON.stringify(event.currentTarget),
      filename: event.filename,
      target: JSON.stringify(event.target),
    });
    Log.info('Log return value from failed filterDeliveriesWorker - 3.', {
      event: JSON.stringify(event),
    });
    Log.info('Log return value from failed filterDeliveriesWorker - 4.', {
      event_currentTarget_location_href: event.currentTarget?.location?.href,
    });
    Log.error(
      'Failed to filter deliveries in filterDeliveriesWorker.',
      event.message,
    );
    Log.productAnalyticsEvent(
      'Failed to filter deliveries',
      Log.FEATURE.DELIVERY_LIST,
      Log.TYPE.ERROR,
    );

    /*
     * It might happen that an error occurs in the filterDeliveries worker: Uncaught SyntaxError: Unexpected token '<'
     * In this case, filter the deliveries directly in the main thread.
     * This might make the Webapp unresponsive but it's still better than not showing any DLNs.
     */
    const filteredRows = filterDeliveries(data);

    setFilteredRows(filteredRows);
    setLoading(false);

    // Terminate the worker after error handling. The close() inside the worker has probably failed.
    filterDeliveriesWorker.terminate();
  });

  filterDeliveriesWorker.postMessage(data);
};
