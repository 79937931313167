import { createSlice } from '@reduxjs/toolkit';
import ArrayUtils from '~/utils/arrayUtils';
import { LOADING_STATE } from '~/constants/LoadingState';

const sitesSlice = createSlice({
  initialState: {
    sites: [],
    sitesLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'sites',
  reducers: {
    replaceSite(state, parameter) {
      const { payload } = parameter;

      const newSites = ArrayUtils.removeByKey(state.sites, 'id', payload.id);
      newSites.push(payload);

      state.sites = newSites;
    },
    replaceSites(state, parameter) {
      const { payload } = parameter;

      state.sites = ArrayUtils.sortByKey(payload, 'name');
      state.sitesLoading = LOADING_STATE.SUCCEEDED;
    },
    setSitesLoading(state, parameter) {
      const { payload } = parameter;

      state.sitesLoading = payload;
    },
  },
});

const { actions, reducer } = sitesSlice;
export const { replaceSite, replaceSites, setSitesLoading } = actions;
export default reducer;
