import React, { useState } from 'react';

import {
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@mui/material';

import Log from '~/utils/Log';

// This accordion has the collapse directly integrated on the same height as the title. It doesn't increase margins when expanding.
const Accordion = styled((props) => <MuiAccordion elevation={0} {...props} />)(
  ({ theme }) => ({
    '&:before': {
      display: 'none',
    },
  }),
);
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '8px' }}
        className="main-text"
      />
    }
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginRight: '8px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '&.Mui-expanded': {
    height: '40px',
    minHeight: '40px',
  },
  height: '40px',
  minHeight: '40px',
  padding: 0,
}));

export default function HeaderAccordion(props) {
  const [expanded, setExpanded] = useState(false);

  const onChange = () => {
    if (props.expanded ?? expanded) {
      Log.productAnalyticsEvent(
        'Close ' + props.productAnalyticsFeature,
        Log.FEATURE.ACCORDION,
      );
    } else {
      Log.productAnalyticsEvent(
        'Open ' + props.productAnalyticsFeature,
        Log.FEATURE.ACCORDION,
      );
    }

    if (props.setExpanded) {
      props.setExpanded(!props.expanded);
    } else {
      setExpanded(!expanded);
    }
  };

  return (
    <Accordion expanded={props.expanded ?? expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="text-primary500" />}
      >
        <Typography className="w-full" component={'span'}>
          <div className="text-14px flex w-full items-center justify-between">
            {props.title}
            {props.noExpansionText ? null : (
              <div className="text-primary500">
                {(props.expanded ?? expanded) ? 'Einklappen' : 'Ausklappen'}
              </div>
            )}
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="w-full" component={'span'}>
          {props.children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
