import { useState, useCallback } from 'react';

import { PROMISE_STATUS } from '~/constants/AsyncOperationConsts';

import PermissionGrant from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';

import PermissionGrantService from '~/services/permissionGrant.service';
import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';

export const usePermissionGrantPickerItem = (
  fixedPicker,
  { defaultSubjects, defaultSubjectType, defaultEntityType, defaultEntities },
) => {
  const [pickedSubjectType, setPickedSubjectType] = useState(
    defaultSubjectType || PermissionGrant.SUBJECT_TYPE.USER.KEY,
  );
  const [pickedSubjects, setPickedSubjects] = useState(defaultSubjects || []);
  const [pickedEntityType, setPickedEntityType] = useState(
    defaultEntityType || PermissionGrant.ENTITY_TYPE.SITE.KEY,
  );
  const [pickedEntities, setPickedEntities] = useState(defaultEntities || []);
  const [permissionFormOpen, setPermissionFormOpen] = useState(false);
  const [permissions, setPermissions] = useState(new Permissions());

  const handleChangeSubjectType = useCallback(
    (event) => {
      Log.info(
        'Change form value of subject type',
        { from: pickedSubjectType, to: event.target.value },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change subject type', Log.FEATURE.PERMISSIONS);

      setPickedSubjectType(event.target.value);
      setPickedSubjects([]);
    },
    [pickedSubjectType],
  );

  const handleChangeSubjects = useCallback(
    (event) => {
      const newPickedSubjects = event.map((item) => item.id);

      Log.info(
        'Change form value of subjects',
        { from: pickedSubjects, to: newPickedSubjects },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change subjects', Log.FEATURE.PERMISSIONS);

      setPickedSubjects(newPickedSubjects);
    },
    [pickedSubjects],
  );

  const handleChangeEntityType = useCallback(
    (event) => {
      Log.info(
        'Change form value of entity type',
        { from: pickedEntityType, to: event.target.value },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change entity type', Log.FEATURE.PERMISSIONS);

      setPickedEntityType(event.target.value);
      setPickedEntities([]);
    },
    [pickedEntityType],
  );

  const handleChangeEntities = useCallback(
    (event) => {
      const newPickedEntities = event.map((item) => item.id);

      Log.info(
        'Change form value of entities',
        { from: pickedEntities, to: newPickedEntities },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change entities', Log.FEATURE.PERMISSIONS);

      setPickedEntities(newPickedEntities);
    },
    [pickedEntities],
  );

  const handleChangeRole = useCallback(
    (event) => {
      Log.info(
        'Change form value of role',
        {
          from: permissions.getDefaultRoleName(),
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        `Change role (${event.target.value})`,
        Log.FEATURE.PERMISSIONS,
      );

      const newPermissions = new Permissions();

      if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
        setPermissions(new Permissions());
        setPermissionFormOpen(true);
        return;
      }

      newPermissions.initWithDefaultRole(event.target.value);
      setPermissions(newPermissions);
    },
    [permissions],
  );

  const submit = useCallback(async () => {
    if (!permissions.permissionGranted()) {
      ToastService.warning([
        'Du hast für mindestens einen Benutzer oder eine Benutzer-Gruppe keine Berechtigungen vergeben.',
        'Bitte vergib Berechtigungen, um diese zu speichern.',
      ]);
      Log.productAnalyticsEvent(
        'No user or user group selected',
        Log.FEATURE.PERMISSIONS,
      );
      return false;
    }

    const duplicateChecks = [];
    const grantPromises = [];

    for (const subject of pickedSubjects) {
      for (const entity of pickedEntities) {
        duplicateChecks.push(
          PermissionGrantService.isDuplicatePermissionGrant(
            fixedPicker ?? PermissionGrant.TYPE.SUBJECT,
            pickedSubjectType,
            subject,
            pickedEntityType,
            entity,
            permissions,
          ),
        );
      }
    }

    const duplicateResults = await Promise.all(duplicateChecks);

    // eslint-disable-next-line
    for (let index = 0; index < duplicateResults.length; index++) {
      const duplicateResult = duplicateResults[index];

      if (!duplicateResult) {
        const subjectIndex = Math.floor(index / pickedEntities.length);
        const entityIndex = index % pickedEntities.length;
        const subject = pickedSubjects[subjectIndex];
        const entity = pickedEntities[entityIndex];

        const body = {
          permissions: permissions.getBackendPermissions(),
        };

        Log.info(
          'Submit permission grant form',
          body,
          Log.BREADCRUMB.FORM_SUBMIT.KEY,
        );

        grantPromises.push(
          PermissionGrantService.createNewPermissionGrant(
            pickedSubjectType,
            subject,
            pickedEntityType,
            entity,
            body,
          ),
        );
      }
    }

    const promiseResults = await PromiseUtils.allResolved(grantPromises);

    return promiseResults.every(
      ({ status }) => status === PROMISE_STATUS.FULFILLED,
    );
  }, [
    fixedPicker,
    permissions,
    pickedEntities,
    pickedEntityType,
    pickedSubjects,
    pickedSubjectType,
  ]);

  const openPermissionForm = useCallback(() => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.PERMISSIONS);
    setPermissionFormOpen(true);
  }, []);

  const handleSubmit = useCallback((newPermissions) => {
    setPermissionFormOpen(false);
    setPermissions(newPermissions);
  }, []);

  const handleCancel = useCallback(() => {
    setPermissionFormOpen(false);
  }, []);

  const isDisabledSubjectPicker =
    fixedPicker === PermissionGrant.TYPE.SUBJECT && defaultSubjects.length > 0;

  const isDisabledEntityPicker =
    fixedPicker === PermissionGrant.TYPE.ENTITY && defaultEntities.length > 0;

  const roles = Permissions.getPickableRoles();

  return {
    handleCancel,
    handleChangeEntities,
    handleChangeEntityType,
    handleChangeRole,
    handleChangeSubjects,
    handleChangeSubjectType,
    handleSubmit,
    isDisabledEntityPicker,
    isDisabledSubjectPicker,
    openPermissionForm,
    permissionFormOpen,
    permissions,
    pickedEntities,
    pickedEntityType,
    pickedSubjects,
    pickedSubjectType,
    roles,
    submit,
  };
};
