import { queryKeysCostCenter } from '~/data/costCenter';
import { queryKeysOrganizationalUnit } from '~/data/organizationalUnit';
import Coordinates from '~/models/masterdata/Coordinates';
import PermissionGrant from '~/models/masterdata/PermissionGrant';

import MasterDataService from '~/services/masterData.service';
import PermissionGrantService from '~/services/permissionGrant.service';
import ToastService from '~/services/toast.service';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

const handleCreateError = (error) => {
  ToastService.httpError(
    [ToastService.MESSAGE.SITE_CREATION_FAILED],
    error.response,
  );

  Log.error('Failed to create site.', error);
  Log.productAnalyticsEvent(
    'Failed to create',
    Log.FEATURE.SITE,
    Log.TYPE.ERROR,
  );
};

const handleUpdateError = (error, siteId) => {
  ToastService.httpError(
    [ToastService.MESSAGE.SITE_UPDATE_FAILED],
    error.response,
  );

  Log.error(`Failed to update site. id: ${siteId}`, error);
  Log.productAnalyticsEvent(
    'Failed to update',
    Log.FEATURE.SITE,
    Log.TYPE.ERROR,
  );
};

export const handleSubmit = async ({
  closeForm,
  createSiteMutation,
  deactivateCoupledCostCenters,
  isCreatingSite,
  originalData,
  queryClient,
  refetchSites,
  resetForm,
  site,
  syncCoupledPermissionGrants,
  updateCostCenterMutation,
  updateOrganizationalUnitsMutation,
  updateSiteCostCentersMutation,
  updateSiteMutation,
}) => {
  try {
    const { latitude, longitude } = Coordinates.getConvertedCoordinates(
      site.coords.latitude,
      site.coords.longitude,
    );

    const { address, companyId, end, isActive, name, start, type } = site;

    const body = {
      address,
      companyId,
      coords: {
        latitude,
        longitude,
      },
      end,
      isActive,
      name,
      start,
      type,
    };

    if (isCreatingSite) {
      body.orgUnits = site.organisationalGroups;
    }

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['companyId'],
        Log.FEATURE.SITE,
      )
    ) {
      return;
    }

    Log.info('Submit site form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.SITE);

    let siteId;
    if (isCreatingSite) {
      try {
        const { id } = await createSiteMutation(body);
        siteId = id;

        try {
          await updateSiteCostCentersMutation({
            costCenterIds: site.costCenters,
            siteId,
          });
        } catch (error) {
          ToastService.httpError(
            [ToastService.MESSAGE.ACCOUNTING_REFERENCE_ASSIGNMENT_FAILED],
            error.response,
          );

          Log.productAnalyticsEvent(
            'Failed to update assigned cost centers',
            Log.FEATURE.SITE,
            Log.TYPE.ERROR,
          );
        }
      } catch (error) {
        handleCreateError(error);
        return;
      }
    } else {
      try {
        await updateSiteMutation({
          siteData: body,
          siteId: site.id,
        });
        siteId = site.id;

        try {
          await updateSiteCostCentersMutation({
            costCenterIds: site.costCenters,
            siteId: site.id,
          });
        } catch (error) {
          ToastService.httpError(
            [ToastService.MESSAGE.ACCOUNTING_REFERENCE_ASSIGNMENT_FAILED],
            error.response,
          );

          Log.productAnalyticsEvent(
            'Failed to update assigned cost centers',
            Log.FEATURE.SITE,
            Log.TYPE.ERROR,
          );
        }
      } catch (error) {
        handleUpdateError(error, site.id);
        return;
      }

      if (syncCoupledPermissionGrants) {
        // TODO: replace old code with mutation function from useMutationUpdateSitePermissionGrants
        // const [removedCostCenterIds, addedCostCenterIds] =
        //   ArrayUtils.getDifference(originalData.costCenters, site.costCenters);
        // updateSitePermissionGrantsMutation({
        //   permissionGrantsFrom: site.permissionGrantsFrom,
        //   addedCostCenterIds,
        //   removedCostCenterIds,
        // });

        const [, error3] = await promiseHandler(
          PermissionGrantService.assignCoupledPermissionGrants(
            site.permissionGrantsFrom,
            site.costCenters,
            originalData.costCenters,
          ),
        );

        if (error3) {
          ToastService.httpError(
            [
              'Es konnten nicht alle Berechtigungen zu den Kostenstellen hinzugefügt werden.',
            ],
            error3.response,
          );

          Log.error('Failed to create new permission grants.', error3);
          Log.productAnalyticsEvent(
            'Failed to sync permissions between site and cost centers',
            Log.FEATURE.PERMISSIONS,
            Log.TYPE.ERROR,
          );
        }

        const [, error4] = await promiseHandler(
          PermissionGrantService.deleteCoupledPermissionGrants(
            site.permissionGrantsFrom,
            originalData.costCenters,
            site.costCenters,
          ),
        );

        if (error4) {
          Log.error('Failed to delete permission grants.', error4);
          ToastService.httpError(
            [
              'Es konnten nicht alle Berechtigungen von den Kostenstellen entfernt werden.',
            ],
            error4.response,
          );
          Log.productAnalyticsEvent(
            'Failed to sync permissions between site and cost centers',
            Log.FEATURE.PERMISSIONS,
            Log.TYPE.ERROR,
          );
        }

        const [removedCostCenters, addedCostCenters] = ArrayUtils.getDifference(
          originalData.costCenters,
          site.costCenters,
        );

        for (const costCenterId of [
          ...addedCostCenters,
          ...removedCostCenters,
        ]) {
          queryClient.invalidateQueries({
            queryKey: queryKeysCostCenter.get(costCenterId),
          });
        }
      }

      if (deactivateCoupledCostCenters) {
        // TODO: is site.costCenters updated by refetching? The modified cost centers used to be refreshed by CostCenterService, but a simple refetch should not have affected the site object in state...
        const updatePromises = site.costCenters.map((costCenterId) =>
          updateCostCenterMutation({
            costCenterData: {
              isActive: site.isActive,
            },
            costCenterId,
          }).catch((error) => {
            ToastService.httpError(
              ['Es konnten nicht alle Kostenstellen (de)aktiviert werden.'],
              error.response,
            );

            Log.error('Failed to update cost center.', error);
            Log.productAnalyticsEvent(
              'Failed to update cost center',
              Log.FEATURE.SITE,
              Log.TYPE.ERROR,
            );
          }),
        );

        await Promise.all(updatePromises);
      }

      const [deletedOrganizationalUnits, addedOrganizationalUnits] =
        ArrayUtils.getDifference(
          originalData.organisationalGroups,
          site.organisationalGroups,
        );

      await updateOrganizationalUnitsMutation({
        addedMembers: addedOrganizationalUnits,
        deletedMembers: deletedOrganizationalUnits,
        entityId: siteId,
        entityType: PermissionGrant.ENTITY_TYPE.SITE.KEY,
        updateType: 'updateParentOrganizationalUnits',
      });
    }

    refetchSites();

    // Refetch MultiItemsManager queries.
    queryClient.invalidateQueries({
      queryKey: queryKeysOrganizationalUnit.getAll(),
      refetchType: 'all',
    });

    closeForm();
    resetForm();

    ToastService.success(
      `Standort ${name} ${isCreatingSite ? 'erstellt' : 'aktualisiert'}`,
    );
  } catch (error) {
    Log.error('Unexpected error in onSubmit', error);
    ToastService.error('Ein unerwarteter Fehler ist aufgetreten');
  }
};
