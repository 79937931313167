import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysArticle } from './queryKeys';

/**
 * delete an existing article.
 *
 * @param {string} articleId - The ID of the article to delete.
 * @param {Object} articleData - An object containing the vehicle data.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/delete_article_article_master_article__article_uuid__delete
 */
export const deleteArticle = async (articleId: UUID) => {
  try {
    const response = await vestigasApi
      .delete(ENDPOINT.ARTICLE.DELETE(articleId))
      .json();

    return response;
  } catch (error) {
    Log.error('Error deleting article settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for deleting an existing article.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */

export const useMutationDeleteArticle = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (articleId: UUID) => deleteArticle(articleId),
    async onError(error) {
      Log.productAnalyticsEvent(
        'Failed to delete article',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeysArticle.getAll({}),
      });
    },
    ...options,
  });
};
