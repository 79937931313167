import { AddLocationAlt as AddLocationAltIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { MissingPermissionsTooltip } from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';

export const CreateCostCenterButton = ({ onClick }) => {
  const canCreateCostCenter = UserUtils.isSiteCreateAllowedUser();

  const CreateButton = (
    <Button
      className={
        canCreateCostCenter ? 'primary-button' : 'disabled-primary-button'
      }
      onClick={onClick}
      startIcon={<AddLocationAltIcon />}
      disabled={!canCreateCostCenter}
    >
      Benutzer erstellen
    </Button>
  );

  if (canCreateCostCenter) {
    return CreateButton;
  }

  return <MissingPermissionsTooltip>{CreateButton}</MissingPermissionsTooltip>;
};
