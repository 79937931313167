import InvoiceCheckIgnoredArticle from '~/models/invoices/InvoiceCheckIgnoredArticle';

export const selectIgnoredArticles = (data, dataExchanges, companies) => ({
  ignoredArticles:
    data.items.map(
      (ignoredArticle) => new InvoiceCheckIgnoredArticle(ignoredArticle),
    ) ?? [],
  rows:
    data.items.map((ignoredArticle) => ({
      ...ignoredArticle,
      buyerCompanies: ignoredArticle.buyerCompanies
        .map(
          (buyerCompany) =>
            companies.find((company) => company.id === buyerCompany)?.name ??
            '',
        )
        .join(', '),
      sellerCompanies: ignoredArticle.sellerCompanies
        .map(
          (sellerCompany) =>
            dataExchanges.dataExchangeCompanies.find(
              (company) => company.id === sellerCompany,
            )?.name ?? '',
        )
        .join(', '),
    })) ?? [],
});
