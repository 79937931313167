import React from 'react';
import { Grid } from '@mui/material';
import UnitUtils from '~/utils/unitUtils';
import { withErrorBoundary } from '~/ui/atoms';
import ValueGroup from '~/models/deliveries/ValueGroup';
import { EditingHistorySafe as EditingHistory } from '../deliveries/deliveryNote/EditingHistorySafe';

class ArticleWeighing extends React.Component {
  grossWeightSet() {
    return (
      ValueGroup.getCurrentValue(this.props.weighing?.gross?.weight?.value) &&
      ValueGroup.getCurrentValue(this.props.weighing?.gross?.weight?.unit)
    );
  }

  tareWeightSet() {
    return (
      ValueGroup.getCurrentValue(this.props.weighing?.tare?.weight?.value) &&
      ValueGroup.getCurrentValue(this.props.weighing?.tare?.weight?.unit)
    );
  }

  weighingPersonSet() {
    return ValueGroup.getCurrentValue(this.props.weighing?.person?.name);
  }

  formatWeighing = (weighing) => {
    const value = UnitUtils.roundAndFormatDe_safe(weighing.value);
    const unit = UnitUtils.getDescriptiveUnit(weighing.unit);

    return (
      (value === null ? '-' : value + ' ' + unit) +
      (weighing.scaleId
        ? '\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0' + weighing.scaleId
        : '')
    );
  };

  render() {
    return (
      <Grid container spacing="20px" justifyContent="space-between">
        {this.grossWeightSet() ? (
          <Grid item xs={12} lg={6}>
            <div>Bruttogewicht</div>
            <div className="bold">
              <EditingHistory
                value={ValueGroup.applyFunction(
                  {
                    scaleId: this.props.weighing?.gross?.scaleId,
                    unit: this.props.weighing?.gross?.weight?.unit,
                    value: this.props.weighing?.gross?.weight?.value,
                  },
                  this.formatWeighing,
                )}
              />
            </div>
          </Grid>
        ) : null}
        {this.tareWeightSet() ? (
          <Grid item xs={12} lg={6}>
            <div>Taragewicht</div>
            <div className="bold">
              <EditingHistory
                value={ValueGroup.applyFunction(
                  {
                    scaleId: this.props.weighing?.tare?.scaleId,
                    unit: this.props.weighing?.tare?.weight?.unit,
                    value: this.props.weighing?.tare?.weight?.value,
                  },
                  this.formatWeighing,
                )}
              />
            </div>
          </Grid>
        ) : null}
        {this.weighingPersonSet() ? (
          <Grid item xs={12} lg={6}>
            <div>Wäger</div>
            <div className="bold">
              <EditingHistory value={this.props.weighing?.person?.name} />
            </div>
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

export default withErrorBoundary(
  ArticleWeighing,
  'Daten der Wägung konnten nicht geladen werden.',
);
