import cloneDeep from 'lodash/cloneDeep';
import { Checkbox, FormControlLabel } from '@mui/material';

import FeatureService from '~/services/feature.service';
import Log from '~/utils/Log';

import PackageBasicRestrictionTooltip from '~/components/salesPackages/packageBasicRestriction/packageBasicRestrictionTooltip';

import {
  MultiSelectCostCenters,
  MultiSelectSites,
} from '~/ui/molecules/SelectServerDriven';

export const NotificationSection = ({
  onChange,
  onUpdateUser,
  settingsKey,
  title,
  user,
}) => {
  const logChange = (field, from, to) => {
    Log.info(
      `Change form value of ${field}`,
      {
        from,
        to,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      `Change push notifications ${field}`,
      Log.FEATURE.USER,
    );
  };

  const handleChangeSites = (newSites) => {
    const newUser = cloneDeep(user);

    newUser.pushNotificationSettings[settingsKey].siteIds = newSites.map(
      ({ id }) => id,
    );

    logChange(
      'sites',
      user.pushNotificationSettings[settingsKey].siteIds,
      newUser.pushNotificationSettings[settingsKey].siteIds,
    );

    onUpdateUser(newUser);
  };

  const handleChangeCostCenters = (newCostCenters) => {
    const newUser = cloneDeep(user);

    newUser.pushNotificationSettings[settingsKey].costCenterIds =
      newCostCenters.map(({ id }) => id);

    logChange(
      'cost centers',
      user.pushNotificationSettings[settingsKey].costCenterIds,
      newUser.pushNotificationSettings[settingsKey].costCenterIds,
    );

    onUpdateUser(newUser);
  };

  // prioritizedItems got lost when we switched to server driven selects; add back?
  // prioritizedItems={user?.getDlnPermittedSites()}
  // prioritizedItems={user?.getDlnPermittedCostCenters()}

  return (
    <div className="w-full">
      <h3>{title}</h3>
      <div className="w-full space-y-4">
        <div className="w-full lg:w-2/3">
          <PackageBasicRestrictionTooltip>
            <div className="flex items-center gap-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(
                      user?.pushNotificationSettings[settingsKey]?.all,
                    )}
                    onChange={onChange}
                    name={`push_notifications_${settingsKey}`}
                    disabled={FeatureService.packageBasicRestriction()}
                  />
                }
                label="Alle"
              />
              <div className="flex flex-1 items-center gap-4">
                <div className="w-1/2">
                  <MultiSelectSites
                    label="Standorte"
                    value={
                      user?.pushNotificationSettings[settingsKey]?.siteIds || []
                    }
                    onChange={handleChangeSites}
                    isDisabled={
                      user?.pushNotificationSettings[settingsKey]?.all ||
                      FeatureService.packageBasicRestriction()
                    }
                  />
                </div>
                <div className="w-1/2">
                  <MultiSelectCostCenters
                    label="Kostenstellen"
                    value={
                      user?.pushNotificationSettings[settingsKey]
                        ?.costCenterIds || []
                    }
                    onChange={handleChangeCostCenters}
                    isDisabled={
                      user?.pushNotificationSettings[settingsKey]?.all ||
                      FeatureService.packageBasicRestriction()
                    }
                  />
                </div>
              </div>
            </div>
          </PackageBasicRestrictionTooltip>
        </div>
      </div>
    </div>
  );
};
