import { setDelivery_query } from '~/redux/filtersSlice';
import UnitUtils from '~/utils/unitUtils';
import { dateUtils } from '~/utils/dateUtils';
import MuiDataGridFilter from './MuiDataGridFilter';
import FilterGroupFilter from './FilterGroupFilter';
import FilterContext from './FilterContext';
import DeliveryNote from '../deliveries/DeliveryNote';
import store from '~/redux/store';
import CustomField from '../customData/CustomField';

export default class FilterProps {
  constructor(filterProps) {
    this.dataType = filterProps?.dataType ?? null; // mapped from field and context
    this.dataTypeMapped = filterProps?.dataTypeMapped ?? null; // mapped from dataType and operator
    this.fieldBackend = filterProps?.fieldBackend ?? null; // mapped from field and context
    this.fieldString = filterProps?.fieldString ?? null; // mapped from field and context
    this.operatorBackend = filterProps?.operatorBackend ?? null; // mapped from operator
    this.operatorString = filterProps?.operatorString ?? null; // mapped from operator
    this.valueString = filterProps?.valueString ?? null; // mapped from value and dataType
    this.resetValueCallback = filterProps?.resetValueCallback ?? null; // mapped from field and context
    this.filterString = filterProps?.filterString ?? null;

    this.init(
      filterProps.field,
      filterProps.operator,
      filterProps.value,
      filterProps.filterContext,
    );
  }

  init(field, operator, value, filterContext) {
    this.dataType = this.getDataType(field, filterContext);
    this.dataTypeMapped = this.getDataTypeMapped(this.dataType, operator);
    this.fieldBackend = this.getFieldBackend(field, filterContext);
    this.fieldString = this.getFieldString(field, filterContext);
    this.operatorBackend = this.getOperatorBackend(operator);
    this.operatorString = this.getOperatorString(operator);
    this.valueString = this.getValueString(value, this.dataTypeMapped);
    this.resetValueCallback = this.getResetValueCallback(field, filterContext);
    this.filterString = `${this.fieldString} ${this.operatorString} "${this.valueString}"`;
  }

  /**
   * Returns the data type of a given field based on the provided filter context.
   *
   * @param {string} field - The field for which to determine the data type.
   * @param {object} filterContext - The context in which the data type is determined.
   * @return {string|null} The data type of the field, or null if the field or filter context is falsy.
   */
  getDataType(field, filterContext) {
    if (!field || !filterContext) {
      return null;
    }

    if (filterContext.isCustomField) {
      return FilterProps.DATA_TYPE.STRING; // In the future, replace this with the data type of the custom field.
    }

    const isDashboard = FilterContext.PAGE.DASHBOARD === filterContext.page;
    const isDeliveriesPage = FilterContext.PAGE.DELIVERY === filterContext.page;

    if (isDashboard || isDeliveriesPage) {
      return DeliveryNote.getDataType(field);
    }

    return FilterProps.DATA_TYPE.STRING;
  }

  getDataTypeMapped(dataType, operator) {
    if (!dataType || !operator) {
      return null;
    }

    return FilterProps.getMappedDataTypeFromDataTypeAndOperator(
      dataType,
      operator,
    );
  }

  getFieldBackend(field, filterContext) {
    if (!field || !filterContext) {
      return null;
    }

    return FilterProps.getFieldBackend(field, filterContext);
  }

  getFieldString(field, filterContext) {
    if (!field || !filterContext) {
      return null;
    }

    if (filterContext.isCustomField) {
      const fieldId = CustomField.getIdFromKey(field);
      const customField = store
        .getState()
        .customFields?.customFields.find(({ id }) => id === fieldId);

      return customField ? customField.displayName : '';
    }

    if (filterContext.component === FilterContext.COMPONENT.QUERY) {
      return 'Suche';
    }

    if (
      filterContext.page === FilterContext.PAGE.DELIVERY ||
      filterContext.page === FilterContext.PAGE.DASHBOARD
    ) {
      return DeliveryNote.getPropertyString(field);
    }

    return '';
  }

  getOperatorBackend(operator) {
    if (!operator) {
      return null;
    }

    return FilterProps.getOperatorBackend(operator);
  }

  getOperatorString(operator) {
    if (!operator) {
      return null;
    }

    return FilterProps.getOperatorString(operator);
  }

  getValueString(value, dataType) {
    if (!value || !dataType) {
      return null;
    }

    return FilterProps.getValueString(value, dataType);
  }

  getResetValueCallback(field, filterContext) {
    if (!field || !filterContext) {
      return null;
    }

    const { component, isCustomField, page } = filterContext;
    const isFilterGroup = component === FilterContext.COMPONENT.FILTER_GROUP;
    const isQuery = component === FilterContext.COMPONENT.QUERY;
    const isFilterModel = component === FilterContext.COMPONENT.FILTER_MODEL;
    const isDeliveriesPage = page === FilterContext.PAGE.DELIVERY;

    if (isCustomField) {
      return null;
    }

    if (isFilterGroup) {
      const setValueCallback =
        FilterProps.getSetValueCallbackFromFilterGroupFilterAndContext(
          field,
          filterContext,
        );
      if (!setValueCallback) {
        return null;
      }

      return () => setValueCallback([]);
    }

    if (isQuery) {
      const setValueCallback = (value) =>
        store.dispatch(setDelivery_query(value));

      return () => setValueCallback('');
    }

    if (isFilterModel && isDeliveriesPage) {
      return () => {};
      /* return () => {
        let newFilterModel = cloneDeep(store.getState().filters.deliveryList_filterModel);
        newFilterModel.items = newFilterModel.items.filter((filter) => {
          return filter.field !== field;
        });
        return () => store.dispatch(setDeliveryList_filterModel(newFilterModel));
      }; */
    }
  }

  static getValueString(value, dataType) {
    return (
      FilterProps.DATA_TYPE_STRINGIFY_MAPPING.find(
        ({ DATA_TYPE }) => DATA_TYPE === dataType,
      )?.STRINGIFY(value) ?? ''
    );
  }

  static getOperatorString(operator) {
    return FilterProps.OPERATOR_STRING_MAPPING.find(
      ({ OPERATOR }) => OPERATOR === operator,
    )?.STRING_GERMAN;
  }

  static getOperatorBackend(operator) {
    return FilterProps.OPERATOR_STRING_MAPPING.find(
      ({ OPERATOR }) => OPERATOR === operator,
    )?.BACKEND_OPERATOR;
  }

  static getFieldBackend(field, filterContext) {
    return (
      FilterProps.FILTER_MAPPING.find(
        ({ FIELD, PAGE }) => FIELD === field && PAGE === filterContext.page,
      )?.BACKEND_FILTER ?? null
    );
  }

  static getSetValueCallbackFromFilterGroupFilterAndContext(field, context) {
    return (
      FilterProps.FILTER_MAPPING.find(
        ({ FIELD, PAGE }) => FIELD === field && PAGE === context.page,
      )?.SET_VALUE_CALLBACK ?? null
    );
  }

  /**
   * Returns the GET_VALUE_CALLBACK from the FILTER_MAPPING array for the given context of filter and page.
   */
  static getGetValueCallbackFromFilterGroupFilterAndContext(
    filterGroupFilter,
    context,
  ) {
    return (
      FilterProps.FILTER_MAPPING.find(({ FILTER_GROUP_FILTER, PAGE }) => {
        return (
          FILTER_GROUP_FILTER === filterGroupFilter && PAGE === context.page
        );
      })?.GET_VALUE_CALLBACK ?? null
    );
  }

  static getOperatorFromMuiDataGridOperator(muiDataGridOperator) {
    return FilterProps.OPERATOR_STRING_MAPPING.find(
      ({ MUI_DATA_GRID_OPERATOR }) =>
        MUI_DATA_GRID_OPERATOR === muiDataGridOperator,
    )?.OPERATOR;
  }

  static getMappedDataTypeFromDataTypeAndOperator(dataType, operator) {
    return (
      FilterProps.DATA_TYPE_MAPPING.find(
        ({ DATA_TYPE, OPERATOR }) =>
          DATA_TYPE === dataType && OPERATOR === operator,
      )?.MAPPED_DATA_TYPE ?? dataType
    );
  }

  // Not every field can be filtered in the backend. This is expected.
  static getBackendFilterFromFieldAndContext(field, filterContext) {
    return FilterProps.FILTER_MAPPING.find(
      ({ FIELD, PAGE }) => FIELD === field && PAGE === filterContext.page,
    )?.BACKEND_FILTER;
  }

  static getFieldFromFilterGroupFilter(filterGroupFilter, filterContext) {
    return FilterProps.FILTER_MAPPING.find(
      ({ FILTER_GROUP_FILTER, PAGE }) =>
        FILTER_GROUP_FILTER === filterGroupFilter &&
        PAGE === filterContext.page,
    )?.FIELD;
  }

  static getFilterGroupFiltersFromPage(page) {
    return FilterProps.FILTER_MAPPING.filter(
      ({ FILTER_GROUP_FILTER, PAGE }) => PAGE === page && FILTER_GROUP_FILTER,
    ).map(({ FILTER_GROUP_FILTER }) => FILTER_GROUP_FILTER);
  }

  static getBackendFilterFromFilterGroupFilter(filterGroupFilter, page) {
    return FilterProps.FILTER_MAPPING.find(
      ({ FILTER_GROUP_FILTER, PAGE }) =>
        FILTER_GROUP_FILTER === filterGroupFilter && PAGE === page,
    )?.BACKEND_FILTER;
  }

  static DATA_TYPE = {
    BOOLEAN: 'boolean',
    DATE: 'date',
    NUMBER: 'number',
    STRING: 'string',
    STRING_ARRAY: 'stringArray',
    UUID: 'uuid',
  };

  /**
   * Factory function to create a data type stringify mapping object based on the provided parameters.
   *
   * @param {string} dataType - The data type of the filter.
   * @param {string} muiDataGridDataType - The corresponding MUI Data Grid data type.
   * @param {function} stringifyFunction - The function used to stringify the data.
   * @return {object} The data type stringify object with the given properties.
   */
  static dataTypeStringifyMappingFactory(
    dataType,
    muiDataGridDataType,
    stringifyFunction,
  ) {
    return {
      DATA_TYPE: FilterProps.DATA_TYPE[dataType],
      MUI_DATA_GRID_DATA_TYPE: muiDataGridDataType
        ? MuiDataGridFilter.DATA_TYPE[muiDataGridDataType]
        : null,
      STRINGIFY: stringifyFunction,
    };
  }

  /**
   * Map data type of filter to MUI Data Grid data type and string representation of data.
   */
  static DATA_TYPE_STRINGIFY_MAPPING = [
    ['BOOLEAN', 'BOOLEAN', (value) => (value ? 'Ja' : 'Nein')],
    [
      'DATE',
      'DATE',
      (value) =>
        dateUtils.getFormattedDate(value, dateUtils.DATE_FORMAT.DD_MM_YYYY),
    ],
    ['NUMBER', 'NUMBER', (value) => UnitUtils.formatStringDe(value)],
    ['STRING_ARRAY', null, (value) => value.join(', ')],
    ['STRING', 'STRING', (value) => value],
    ['UUID', null, (value) => value],
  ].map((arguments_) => this.dataTypeStringifyMappingFactory(...arguments_));
  /**
   * When filtering for a string with the operator 'isAnyOf', the filtered value is an array of strings.
   * This list must be extended with the possible combinations.
   * Currently, only the combination 'isAnyOf' with 'string' occurs.
   */
  static DATA_TYPE_MAPPING = [
    {
      DATA_TYPE: FilterProps.DATA_TYPE.STRING,
      MAPPED_DATA_TYPE: FilterProps.DATA_TYPE.STRING_ARRAY,
      OPERATOR: 'isAnyOf',
    },
  ];
  /**
   * Factory function to create an operator name mapping object based on the provided parameters.
   *
   * @param {string} operator - The operator name.
   * @param {string} MUIDataGridOperator - The corresponding operator name in MUI Data Grid.
   * @param {string} backendOperator - The corresponding operator name when making API requests to the backend.
   * @param {string} stringGerman - The German translation of the operator name.
   * @return {Object} The operator name mapping object.
   */
  static operatorNameFactory = (
    operator,
    MUIDataGridOperator,
    backendOperator,
    stringGerman,
  ) => ({
    BACKEND_OPERATOR: backendOperator,
    MUI_DATA_GRID_OPERATOR: MuiDataGridFilter.OPERATOR[MUIDataGridOperator],
    OPERATOR: operator,
    STRING_GERMAN: stringGerman,
  });
  // OPERATOR accesses FilterNew.OPERATOR but due to circular dependency, it is not possible to import FilterNew here.
  // BACKEND_OPERATOR accesses BackendFilter.OPERATOR but due to circular dependency, it is not possible to import BackendFilter here.
  /**
   * Map filter operators to their respective names when used in MUI Data Grid and in API requests to the backend, respectively.
   * Also contains German translations for the UI.
   */
  static OPERATOR_STRING_MAPPING = [
    ['contains', 'CONTAINS', 'contains', 'enthält'],
    ['equals', 'EQUALS', 'eq', 'ist gleich'],
    ['notEquals', 'NOT_EQUALS', 'ne', 'ist nicht gleich'],
    ['startsWith', 'STARTS_WITH', 'startswith', 'beginnt mit'],
    ['endsWith', 'ENDS_WITH', 'endswith', 'endet mit'],
    ['is empty', 'IS_EMPTY', null, 'ist leer'],
    ['isNotEmpty', 'IS_NOT_EMPTY', null, 'ist nicht leer'],
    ['isAnyOf', 'IS_ANY_OF', 'in', 'ist einer der Werte'],
    ['is', 'IS', 'eq', 'ist'],
    ['not', 'NOT', null, 'ist nicht'],
    ['after', 'AFTER', null, 'ist nach'],
    ['onOrAfter', 'ON_OR_AFTER', null, 'ist am oder nach'],
    ['before', 'BEFORE', null, 'ist vor'],
    ['onOrBefore', 'ON_OR_BEFORE', null, 'ist am oder vor'],
    ['=', 'MATH_EQUALS', 'eq', '='],
    ['!=', 'MATH_NOT_EQUALS', 'ne', '!='],
    ['>', 'MATH_GREATER_THAN', 'gt', '>'],
    ['>=', 'MATH_GREATER_THAN_OR_EQUALS', 'ge', '>='],
    ['<', 'MATH_LESS_THAN', 'lt', '<'],
    ['<=', 'MATH_LESS_THAN_OR_EQUALS', 'le', '<='],
  ].map((arguments_) => this.operatorNameFactory(...arguments_));

  /**
   * Factory function to create a filter mapping object based on the provided parameters.
   *
   * @param {string} filterKey - The key of the filter.
   * @param {string} page - The page the filter is associated with.
   * @param {string} field - The field the filter is applied to.
   * @param {string} backendFilter - The backend filter value.
   * @return {Object} The filter mapping object.
   */
  static filterMappingFactory(filterKey, page, field, backendFilter) {
    const fullFilterKey = FilterGroupFilter.FILTER[filterKey];
    const reduxVariable =
      FilterGroupFilter.REDUX_VARIABLE[`${page.toUpperCase()}_${filterKey}`];
    const reduxSetterFunction =
      FilterGroupFilter.REDUX_SETTER_FUNCTION[
        `SET_${page.toUpperCase()}_${filterKey}`
      ];

    return {
      BACKEND_FILTER: backendFilter,
      FIELD: field,
      FILTER_GROUP_FILTER: fullFilterKey,
      GET_VALUE_CALLBACK: () => store.getState().filters[reduxVariable],
      PAGE: page,
      SET_VALUE_CALLBACK: (value) => store.dispatch(reduxSetterFunction(value)),
    };
  }

  /**
   * BACKEND_FILTER accesses BackendFilter.FILTER but due to circular dependency, it is not possible to import BackendFilter here.
   * @deprecated
   */
  static FILTER_MAPPING = [
    ['SELECTED_ACCEPT_STATE', 'dashboard', 'acceptState', 'dln_accept_state'],
    ['SELECTED_ACCEPT_STATE', 'delivery', 'acceptState', 'dln_accept_state'],
    ['SELECTED_ARTICLE_NUMBER', 'dashboard', 'articleNumber', null],
    ['SELECTED_ARTICLE_NUMBER', 'delivery', 'articleNumber', null],
    ['SELECTED_ARTICLE', 'dashboard', 'article', 'article_name'],
    ['SELECTED_ARTICLE', 'delivery', 'article', 'article_name'],
    [
      'SELECTED_COST_CENTER',
      'dashboard',
      'costCenter',
      null, // VGS-6663: activate backend filter by applying 'confirmed_accounting_reference_name' here
    ],
    [
      'SELECTED_COST_CENTER',
      'delivery',
      'costCenter',
      null, // VGS-6663: activate backend filter by applying 'confirmed_accounting_reference_name' here
    ],
    ['SELECTED_FROM_SITE', 'dashboard', 'fromSite', 'loading_location'],
    ['SELECTED_FROM_SITE', 'delivery', 'fromSite', 'loading_location'],
    // ['SELECTED_NUMBER', 'delivery', 'number', 'dln_nr'], // VGS-6663: activate filter commenting in this line
    [
      'SELECTED_PERMITTED_COST_CENTERS',
      'dashboard',
      'permittedCostCenterNames',
      null,
    ],
    [
      'SELECTED_PERMITTED_COST_CENTERS',
      'delivery',
      'permittedCostCenterNames',
      null,
    ],
    ['SELECTED_PERMITTED_TO_SITES', 'dashboard', 'permittedToSiteNames', null],
    ['SELECTED_PERMITTED_TO_SITES', 'delivery', 'permittedToSiteNames', null],
    ['SELECTED_PROCESS_STATE', 'dashboard', 'processState', 'process_state'],
    ['SELECTED_PROCESS_STATE', 'delivery', 'processState', 'process_state'],
    ['SELECTED_RECIPIENT', 'dashboard', 'recipient', 'recipient_name'],
    ['SELECTED_RECIPIENT', 'delivery', 'recipient', 'recipient_name'],
    ['SELECTED_SETTLED_STATUS', 'dashboard', 'settledStatus', null],
    ['SELECTED_SETTLED_STATUS', 'delivery', 'settledStatus', null],
    ['SELECTED_SUPPLIER', 'dashboard', 'supplier', 'supplier_name'],
    ['SELECTED_SUPPLIER', 'delivery', 'supplier', 'supplier_name'],
    [
      'SELECTED_TO_SITE_RECIPIENT',
      'dashboard',
      'toSiteRecipient',
      'confirmed_site_name',
    ],
    [
      'SELECTED_TO_SITE_RECIPIENT',
      'delivery',
      'toSiteRecipient',
      'confirmed_site_name',
    ],
    [
      'SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT',
      'dashboard',
      'toSiteSupplierTradeContact',
      null,
    ],
    [
      'SELECTED_TO_SITE_SUPPLIER',
      'dashboard',
      'toSiteSupplier',
      'supplier_assigned_site_name',
    ],
    [
      'SELECTED_TO_SITE_SUPPLIER',
      'delivery',
      'toSiteSupplier',
      'supplier_assigned_site_name',
    ],
    [null, 'delivery', 'licensePlate', 'license_plate_number'],
    [null, 'delivery', 'number', 'dln_nr'],
  ].map((arguments_) => this.filterMappingFactory(...arguments_));
}
