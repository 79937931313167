import React from 'react';

import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

import AttachmentModel from '~/models/deliveries/Attachment';
import AttachmentImage from './AttachmentImage';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import AttachmentPdf from './AttachmentPdf';
import { LightTooltipWide } from '~/utils/componentUtils';
import { Spinner } from '../Spinner';
import AttachmentJson from './AttachmentJson';
import UserUtils from '~/utils/userUtils';

class Attachment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      url: '',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.attachment) !==
      JSON.stringify(prevProps.attachment)
    ) {
      this.loadData();
    }
  }

  loadData() {
    this.props.attachment
      .getContent()
      .then((response) => {
        this.setState({
          url: URL.createObjectURL(response),
        });
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
        Log.error('Failed to load attachment.', error);
        Log.productAnalyticsEvent(
          'Failed to load attachment',
          Log.FEATURE.OTHER_FEATURE,
          Log.TYPE.ERROR,
        );
      });
  }

  getTile(content) {
    let colorClass = 'bg-grey400';
    let borderClass = null;

    if (
      this.props.attachment.contentType === AttachmentModel.CONTENT_TYPE.JPEG ||
      this.props.attachment.contentType === AttachmentModel.CONTENT_TYPE.PNG ||
      this.props.attachment.contentType === AttachmentModel.CONTENT_TYPE.SVG
    ) {
      colorClass = 'bg-white';
      borderClass = 'border-grey400';
    }

    return (
      <div
        className={
          'rounded-5px h-150px w-150px ' + colorClass + ' ' + borderClass
        }
      >
        {content}
      </div>
    );
  }

  getErrorTile() {
    return this.getTile(
      <div className="flex-c-c h-full w-full text-white">
        <LightTooltipWide title="Anhang konnte nicht geladen werden.">
          <ErrorOutlineIcon className="text-white" fontSize="large" />
        </LightTooltipWide>
      </div>,
    );
  }

  render() {
    if (this.state.error) {
      return this.getErrorTile();
    }

    if (!this.state.url) {
      return this.getTile(<Spinner white />);
    }

    if (
      this.props.attachment.contentType === AttachmentModel.CONTENT_TYPE.JPEG ||
      this.props.attachment.contentType === AttachmentModel.CONTENT_TYPE.PNG ||
      this.props.attachment.contentType === AttachmentModel.CONTENT_TYPE.SVG
    ) {
      return this.getTile(
        <AttachmentImage url={this.state.url} key={this.props.key} />,
      );
    }

    if (
      this.props.attachment.contentType === AttachmentModel.CONTENT_TYPE.PDF
    ) {
      return this.getTile(<AttachmentPdf url={this.state.url} />);
    }

    if (
      this.props.attachment.contentType === AttachmentModel.CONTENT_TYPE.JSON
    ) {
      // Only display JSON attachments for Vestigas accounts. Clients don't need to see this.
      if (!UserUtils.isVestigasAccount()) {
        return null;
      }

      return this.getTile(<AttachmentJson url={this.state.url} />);
    }

    Log.error(
      null,
      new EnumValueNotFoundException(
        'Invalid attachment type: ' + this.props.attachment.type,
      ),
    );

    return this.getErrorTile();
  }
}

export default Attachment;
