import ConcreteDiaryRecord from './ConcreteDiaryRecord';
import { dateUtils } from '~/utils/dateUtils';
import UserUtils from '~/utils/userUtils';
import store from '~/redux/store';

export default class ConcreteDiary {
  constructor(deliveryNotes, concreteIds = [], site, supplier) {
    this.concreteIds = concreteIds;
    this.site = site;
    this.supplier = supplier;

    this.records = this.getRecords(deliveryNotes);
  }

  getRecords(deliveryNotes) {
    const filteredDeliveryNotes = deliveryNotes.filter((deliveryNote) => {
      return (
        deliveryNote.toSiteRecipient.id === this.site &&
        deliveryNote.supplier.id === this.supplier &&
        // Only checks for first concrete in article list.
        // Thus, delivery note would be ignored if concrete id of subsequent concrete would match.
        this.concreteIds.includes(
          deliveryNote.articles.find((article) => article.isConcrete())
            ?.concrete?.concreteId,
        )
      );
    });

    const records = [];

    // Group the delivery notes by date and concrete id.
    for (const deliveryNote of filteredDeliveryNotes) {
      // Here as well only the first concrete article is taken
      const concreteId = deliveryNote.articles.find((article) =>
        article.isConcrete(),
      ).concrete.concreteId;

      const record = records.find(
        (record) =>
          dateUtils.onTheSameDay(record.dlnDate, deliveryNote.dlnDate) &&
          record.concreteId === concreteId,
      );

      // Don't group delivery notes for ATH accounts
      if (
        !record ||
        UserUtils.isATHAccount(
          store?.getState()?.userinfo?.userinfo?.company?.id,
        )
      ) {
        records.push({
          concreteId,
          deliveryNotes: [deliveryNote],
          dlnDate: deliveryNote.dlnDate,
        });

        continue;
      }

      record.deliveryNotes.push(deliveryNote);
    }

    return records.map(
      (record) =>
        new ConcreteDiaryRecord(
          record.deliveryNotes,
          record.dlnDate,
          record.concreteId,
        ),
    );
  }

  getExcelString(recordRows) {
    const records = recordRows.map((recordRow) => {
      const record = this.records.find(
        (record) =>
          dateUtils.onTheSameDay(recordRow.date, record.date) &&
          recordRow.concreteId === record.concreteId &&
          recordRow.numberFrom === record.numberFrom,
      );

      if (!record) {
        throw new Error(
          'No concrete diary record found. concrete id: ' +
            recordRow.concreteId +
            ' | date: ' +
            recordRow.date +
            ' | number from: ' +
            recordRow.numberFrom,
        );
      }

      return record;
    });

    const recordStrings = records.map((record) => record.getExcelString());

    return recordStrings.join('\n');
  }
}
