import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysCategory } from './queryKeys';

type CategoryData = Record<string, unknown>;

type MutationCategoryArgument = {
  categoryId?: UUID;
  categoryData: CategoryData;
};

/**
 * Update an existing category.
 *
 * @param {string} categoryId - The ID of the category to update.
 * @param {Object} categoryData - An object containing the vehicle data.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/update_category_article_master_category__category_uuid__put
 */
export const updateCategory = async (
  categoryId: UUID,
  categoryData: CategoryData,
) => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.CATEGORY.UPDATE(categoryId), {
        json: categoryData,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating category settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating an existing category.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */

export const useMutationUpdateCategory = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      categoryId,
      categoryData,
    }: MutationCategoryArgument) => updateCategory(categoryId, categoryData),
    async onError(error) {
      Log.productAnalyticsEvent(
        'Failed to update category',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(data, { categoryId }) {
      if (categoryId) {
        queryClient.invalidateQueries({
          queryKey: queryKeysCategory.get(categoryId),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeysCategory.getAll({}),
        });
      }
    },
    ...options,
  });
};
