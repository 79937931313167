import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import Tile from './Tile';
import { withErrorBoundary } from '~/ui/atoms';
import UniversalCommunication from '~/models/masterdata/UniversalCommunication';
import clsx from 'clsx';

const SupportTile = () => {
  const userinfo = useSelector(({ userinfo }) => userinfo);
  const companyAccountId = userinfo?.userinfo?.company?.companyAccount;

  const { email, phone } =
    UniversalCommunication.getSupportContact(companyAccountId);

  const rows = [
    {
      onClick: () =>
        (window.location = `mailto:${email}?subject=Support-Anfrage`),
      title: 'E-Mail',
      value: email,
    },
    {
      onClick: () => (window.location = `tel:${phone}`),
      title: 'Telefon',
      value: phone,
    },
    {
      title: 'Schulung',
      value: (
        <a
          href="https://vestigas.notion.site/Herzlich-Willkommen-in-einer-Welt-ohne-Lieferscheine-c4db904d5ff4405fa1feb1f5cf8bd0c2"
          target="_blank"
          className="text-primary500 bold"
          rel="noreferrer"
        >
          <span className="flex-s-c">
            Hier geht es zur Schulung <NavigateNextIcon className="m-0 p-0" />
          </span>
        </a>
      ),
    },
  ];

  return (
    <Tile title={'Support'}>
      <div className="pt-65px flex-s-s gap-20px flexdir-column">
        {rows.map(({ onClick, title, value }) => (
          <div key={title} className="flex-s-c">
            <div className="text-grey400 min-w-80px text-start">{title}</div>
            <div
              className={clsx(['bold', { 'cursor-pointer': Boolean(onClick) }])}
              onClick={onClick}
            >
              {value}
            </div>
          </div>
        ))}
        <div className="bold w-full">
          Wir stehen jederzeit zu deiner Verfügung!
        </div>
      </div>
    </Tile>
  );
};

export default withErrorBoundary(SupportTile, null);
