import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import FeatureService from '~/services/feature.service';
import ClientPortalTooltip from '../salesPackages/clientPortal/ClientPortalTooltip';
import PackageBasicRestrictionTooltip from '../salesPackages/packageBasicRestriction/packageBasicRestrictionTooltip';

export const FilterComponent = ({
  disabled,
  getOptions,
  handleChangeSearchValue,
  inputValue,
  onChangeValue,
  onOpen,
  onScrollToBottom,
  renderOption,
  selectedFilter,
  selectedValues,
  testId,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);

    if (onOpen) {
      onOpen();
    }
  };

  const isOptionDisabled = (option) =>
    !selectedFilter.filteredOptions.includes(option);

  return (
    <div className="min-w-80">
      <PackageBasicRestrictionTooltip>
        <ClientPortalTooltip>
          <Autocomplete
            data-testid={testId}
            size="small"
            open={open}
            onOpen={handleOpen}
            onClose={() => setOpen(false)}
            noOptionsText="Keine Optionen"
            options={getOptions()}
            value={selectedValues}
            onChange={onChangeValue}
            inputValue={inputValue}
            onInputChange={(_, value) => handleChangeSearchValue(value)}
            getOptionDisabled={isOptionDisabled}
            slotProps={{
              popper: {
                'data-testid': `${testId}_popper`,
              },
              popupIndicator: {
                'data-testid': `${testId}_popup_indicator`,
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder={
                  selectedValues?.length ? 'Weitere' : 'Bitte auswählen'
                }
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
            renderOption={renderOption}
            ListboxProps={{
              onScroll(event) {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                  listboxNode.scrollHeight
                ) {
                  onScrollToBottom();
                }
              },
            }}
            disabled={
              disabled ||
              FeatureService.clientPortal() ||
              FeatureService.packageBasicRestriction()
            }
            multiple
          />
        </ClientPortalTooltip>
      </PackageBasicRestrictionTooltip>
    </div>
  );
};
