import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, TextField } from '@mui/material';

import Log from '~/utils/Log';

import BasicForm from '~/components/BasicForm';

export const TEST_TEMPLATE_FILE_TYPE = {
  ASSET_IDS: 'asset_ids',
  DOCX: 'docs',
  JSON: 'json',
};

const Dropzone = ({ file, inputProps, onChange, placeholder }) => {
  const { getInputProps, getRootProps } = useDropzone({
    maxFiles: 1,
    onDrop: (files) => onChange(files[0]),
  });

  return (
    <div {...getRootProps({ className: 'dropzone h-120px' })}>
      <input {...getInputProps()} {...inputProps} />
      {file ? <p>{file?.name}</p> : <p>{placeholder}</p>}
    </div>
  );
};

export const TestPdfTemplateForm = ({
  closeForm,
  files,
  handleFormSubmit,
  handleOnFileChange,
  loadingState,
  open,
  testPdfAssetIds,
}) => {
  const formSuccess = (event) => {
    event.preventDefault();
    handleFormSubmit();
  };

  const formAbort = () => {
    Log.productAnalyticsEvent(
      'Abort test pdf template form',
      Log.FEATURE.PDF_TEMPLATE,
    );
    closeForm();
  };

  const handleFileChange = (type, file) => {
    Log.info(
      `Upload test pdf ${type === TEST_TEMPLATE_FILE_TYPE.DOCX ? 'template' : 'data'} `,
      null,
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      `Upload test pdf ${type === TEST_TEMPLATE_FILE_TYPE.DOCX ? 'template' : 'data'} `,
      Log.FEATURE.PDF_TEMPLATE,
    );

    handleOnFileChange({
      type,
      value: file,
    });
  };

  const handleUUIDsChange = (event) => {
    handleOnFileChange({
      type: TEST_TEMPLATE_FILE_TYPE.ASSET_IDS,
      value: event.target.value,
    });
  };

  // console.log({ testPdfAssetIds });

  return (
    <BasicForm
      open={open}
      formSuccess={formSuccess}
      formAbort={formAbort}
      title="Vorlage testen"
      submittingForm={loadingState}
    >
      <Grid container direction="row" spacing={2} space={2} className="w-600px">
        <Grid item xs={6} lg={6}>
          <p className="mb-2px">JSON-Lieferschein</p>
          <Dropzone
            inputProps={{
              accept: '.json',
            }}
            placeholder="JSON hierher ziehen, klicken oder UUID unten eintragen"
            onChange={(file) =>
              handleFileChange(TEST_TEMPLATE_FILE_TYPE.JSON, file)
            }
            file={files.json}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <p className="mb-2px">Template</p>
          <Dropzone
            inputProps={{
              accept: '.docx',
            }}
            placeholder="Vorlagendatei hierher ziehen oder klicken, um sie auszuwählen"
            onChange={(file) =>
              handleFileChange(TEST_TEMPLATE_FILE_TYPE.DOCX, file)
            }
            file={files.template}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <TextField
            fullWidth
            label="VESTIGAS LFS UUIDs (kommagetrennt) "
            value={testPdfAssetIds}
            onChange={handleUUIDsChange}
          />
        </Grid>
      </Grid>
    </BasicForm>
  );
};
