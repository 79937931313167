import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useQueryPermittedUserIds } from '~/data/deliveryNote';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import {
  handleRequestSignatures,
  handleUpdateDeliveryNotes,
  validateSelection,
} from './utils';

import { useSelectedUsers } from './useSelectedUsers';

const selectDeliveryNotes = (state) => state.deliveryNotes;
const selectUserActions = (state) => state.userinfo.userActions;

type P = {
  closeForm: () => void;
  deliveryNoteIds: UUID[];
  permittedUserIdsFromProps: UUID[];
};

export const useRequestSignatureForm = ({
  closeForm,
  deliveryNoteIds,
  permittedUserIdsFromProps = [],
}: P) => {
  const dispatch = useDispatch();

  const deliveryNotes = useSelector(selectDeliveryNotes);
  const userActions = useSelector(selectUserActions);

  const [selectedUserIds, setSelectedUserIds] = useState<UUID[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: selectedUsers,
    isLoading: isLoadingUsersData,
    isSuccess: isUsersDataComplete,
  } = useSelectedUsers(selectedUserIds);

  const {
    data: permittedUserIdsFromDlns,
    isLoading: isLoadingPermittedUserIds,
  } = useQueryPermittedUserIds(deliveryNoteIds);

  const permittedUserIds = useMemo(
    () => [
      ...new Set(
        [
          ...permittedUserIdsFromProps,
          ...(permittedUserIdsFromDlns || []),
        ].filter(Boolean),
      ),
    ],
    [permittedUserIdsFromProps, permittedUserIdsFromDlns, deliveryNoteIds],
  );

  useEffect(() => {
    // Automatically select all permitted users whenever they change.
    setSelectedUserIds(permittedUserIds);
  }, [permittedUserIds]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const isValidSelection = validateSelection(
      selectedUserIds,
      permittedUserIds,
    );

    if (!isValidSelection) {
      return;
    }

    if (isLoadingUsersData || !isUsersDataComplete) {
      Log.error('User data is still loading or incomplete.');
      return;
    }

    setIsSubmitting(true);

    try {
      await handleRequestSignatures(deliveryNoteIds, selectedUserIds);

      handleUpdateDeliveryNotes({
        deliveryNoteIds,
        deliveryNotes: deliveryNotes.deliveryNotes,
        dispatch,
        selectedUsers,
        userActions,
      });
    } catch (error) {
      Log.error('Error in handleSubmit', error);
    } finally {
      handleCloseForm();
    }
  };

  const handleCloseForm = () => {
    setIsSubmitting(false);
    setSelectedUserIds([]);
    closeForm();
  };

  const handleCancel = () => {
    Log.productAnalyticsEvent(
      'Abort request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    handleCloseForm();
  };

  const handleChange = (selectedUsers) => {
    Log.productAnalyticsEvent(
      'Change users of request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    setSelectedUserIds(selectedUsers.map(({ id }) => id).filter(Boolean));
  };

  return {
    handleCancel,
    handleChange,
    handleSubmit,
    isLoading: isLoadingPermittedUserIds,
    isLoadingUsersData,
    isSubmitting,
    selectedUserIds,
  };
};
