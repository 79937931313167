import { forwardRef, useState, useImperativeHandle } from 'react';
import { InputLabel, MenuItem, Select, Button } from '@mui/material';

import { useQueriesSites } from '~/data/site';

import PermissionGrantService from '~/services/permissionGrant.service';
import ToastService from '~/services/toast.service';

import PermissionGrant, {
  ENTITY_TYPES,
} from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';
import Site from '~/models/masterdata/Site';

import { LightTooltip } from '~/utils/componentUtils';
import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { PermissionGrantMultiPicker } from '~/components/settings/masterData/permissionGrant/PermissionGrantMultiPicker';
import { PermissionForm } from '~/components/settings/masterData/permissionGrant/PermissionForm';

const combineSitesData = (results) => ({
  data: results.map(({ data }) => new Site(data)),
  isError: results.some(({ isError }) => isError),
  isLoading: results.some(({ isLoading }) => isLoading),
  isSuccess: results.every(({ isSuccess }) => isSuccess),
});

export const PermissionGrantPicker = withErrorBoundary(
  forwardRef(({ grantPermissionsOnCostCenters }, ref) => {
    const [state, setState] = useState({
      isOpen: false,
      permissions: new Permissions(),
      pickedEntities: [],
      pickedEntityType: PermissionGrant.ENTITY_TYPE.SITE.KEY,
    });

    const selectedSiteIds =
      state.pickedEntityType === PermissionGrant.ENTITY_TYPE.SITE.KEY
        ? state.pickedEntities
        : [];
    const sitesData = useQueriesSites(selectedSiteIds, {}, combineSitesData);

    useImperativeHandle(ref, () => ({
      async submit(userId, grantPermissionsOnCostCenters) {
        const promises = [];

        if (!state.permissions.permissionGranted()) {
          return;
        }

        if (sitesData.isError) {
          ToastService.error(
            'Standortdaten konnten nicht geladen werden für die Vergabe von Berechtigungen.',
          );

          return;
        }

        for (const entityId of state.pickedEntities) {
          const body = {
            permissions: state.permissions.getBackendPermissions(),
          };

          Log.info(
            'Submit permission grant form',
            body,
            Log.BREADCRUMB.FORM_SUBMIT.KEY,
          );

          const promise = PermissionGrantService.createNewPermissionGrant(
            PermissionGrant.SUBJECT_TYPE.USER.KEY,
            userId,
            state.pickedEntityType,
            entityId,
            body,
          );

          promises.push(promise);
        }

        // Grant permissions on the connected cost centers of the sites.
        if (
          grantPermissionsOnCostCenters &&
          state.pickedEntityType === PermissionGrant.ENTITY_TYPE.SITE.KEY
        ) {
          for (const site of sitesData.data) {
            // Add permission grant promises for each cost center
            for (const costCenterId of site.costCenters) {
              const body = {
                permissions: state.permissions.getBackendPermissions(),
              };

              Log.info(
                'Submit permission grant form',
                body,
                Log.BREADCRUMB.FORM_SUBMIT.KEY,
              );

              const promise = PermissionGrantService.createNewPermissionGrant(
                PermissionGrant.SUBJECT_TYPE.USER.KEY,
                userId,
                PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
                costCenterId,
                body,
              );

              promises.push(promise);
            }
          }
        }

        return PromiseUtils.allResolved(promises);
      },
    }));

    const handleChangeEntityType = (event) => {
      Log.info(
        'Change form value of entity type',
        {
          from: state.pickedEntityType,
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change entity type in create user wizard',
        Log.FEATURE.WIZARD,
      );

      setState((previous) => ({
        ...previous,
        pickedEntities: [],
        pickedEntityType: event.target.value,
      }));
    };

    const handleChangeEntities = (event) => {
      const newPickedEntities = event.map((item) => item.id);

      Log.info(
        'Change form value of entities',
        {
          from: state.pickedEntities,
          to: newPickedEntities,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change entities in create user wizard',
        Log.FEATURE.WIZARD,
      );

      setState((previous) => ({
        ...previous,
        pickedEntities: newPickedEntities,
      }));
    };

    const handleChangeRole = (event) => {
      Log.info(
        'Change form value of role',
        {
          from: state.permissions.getDefaultRoleName(),
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        `Change role (${event.target.value}) in create user wizard`,
        Log.FEATURE.WIZARD,
      );

      const newPermissions = new Permissions();

      if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
        setState((previous) => ({
          ...previous,
          isOpen: true,
        }));
        return;
      }

      newPermissions.initWithDefaultRole(event.target.value);

      setState((previous) => ({
        ...previous,
        permissions: newPermissions,
      }));
    };

    const openPermissionForm = () => {
      Log.productAnalyticsEvent(
        'Open permission form in create user wizard',
        Log.FEATURE.WIZARD,
      );
      setState((previous) => ({
        ...previous,
        isOpen: true,
      }));
    };

    const permissionFormSuccess = (permissions) => {
      setState((previous) => ({
        ...previous,
        isOpen: false,
        permissions,
      }));
    };

    const permissionFormAbort = () => {
      setState((previous) => ({
        ...previous,
        isOpen: false,
      }));
    };

    return (
      <div className="rounded-md border border-gray-300 p-4">
        <div className="flex items-center gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung für
            </InputLabel>
            <Select
              value={state.pickedEntityType}
              onChange={handleChangeEntityType}
              className="w-300px"
              size="small"
            >
              {Object.entries(ENTITY_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_to"
            entityType={state.pickedEntityType}
            pickedIds={state.pickedEntities}
            onChange={handleChangeEntities}
            subjectType={PermissionGrant.SUBJECT_TYPE.USER.KEY}
            subjects={[]}
            displayPermissionGrantOfCostCentersWithSites={
              grantPermissionsOnCostCenters
            }
            fullWidth
          />
        </div>

        <div className="mt-4 flex items-end gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung als
            </InputLabel>
            <Select
              key={0}
              value={state.permissions.getDefaultRoleName() ?? 'None'}
              onChange={handleChangeRole}
              className="w-300px"
              size="small"
              renderValue={(id) => {
                if (!state.permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Rolle wählen
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
            >
              {Permissions.getPickableRoles().map((role) => (
                <MenuItem value={role.id} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mb-0.5">
            <LightTooltip title="Detaillierte Berechtigungen einsehen">
              <Button
                variant="outlined"
                color="primary"
                onClick={openPermissionForm}
              >
                Details verwalten
              </Button>
            </LightTooltip>
          </div>
        </div>

        <PermissionForm
          open={state.isOpen}
          formSuccess={permissionFormSuccess}
          formAbort={permissionFormAbort}
          permissions={state.permissions}
        />
      </div>
    );
  }),
  'Daten konnten nicht geladen werden.',
);
