import { Box, FormControl, FormLabel, Grid, Typography } from '@mui/material';

import { LOADING_STATE } from '~/constants/LoadingState';

import { EmailConfirmationModal } from '../../SupplierCard';

import { SearchableSelect } from './SearchableSelect';
import { useContactPerson } from './useContactPerson';

export const ContactPerson = ({
  buyerCompany,
  contactLinkState,
  eligiblePeople,
  selectedPersonId,
  setSelectedPersonId,
  supplier,
  updateContactSaveLink,
}) => {
  const {
    generateNewContactLink,
    removeResponsiblePerson,
    setShowModal,
    showModal,
    statusContentMap,
    updateResponsiblePerson,
  } = useContactPerson({
    contactLinkState,
    setSelectedPersonId,
    supplier,
    updateContactSaveLink,
  });

  return (
    <>
      <EmailConfirmationModal
        isLoading={contactLinkState.loadingState === LOADING_STATE.LOADING}
        onAccept={() => {
          generateNewContactLink();
          setShowModal(false);
        }}
        onClose={() => setShowModal(false)}
        open={showModal}
      />
      <Box flexDirection="column" className="people-container">
        <Typography variant="h6" className="content-heading">
          Ansprechpersonen
        </Typography>
        <Box marginBottom={3}>
          <FormControl>
            <FormLabel className="semibold">
              Ansprechperson {buyerCompany?.name}
            </FormLabel>
            <SearchableSelect
              selectedOption={selectedPersonId}
              onSelect={(personId) => {
                if (personId === null) {
                  removeResponsiblePerson();
                  return;
                }

                updateResponsiblePerson(personId);
              }}
              options={eligiblePeople.map((person) => {
                return {
                  id: person?.id,
                  name:
                    [person?.first_name, person?.last_name]
                      .filter(Boolean)
                      .join(' ') || person?.email,
                };
              })}
            />
          </FormControl>
        </Box>
        <Grid
          spacing={1}
          flexDirection="column"
          gap={1}
          className="sub-content"
        >
          {statusContentMap[contactLinkState.step]}
        </Grid>
      </Box>
    </>
  );
};
