import clsx from 'clsx';
import { type PropsWithChildren, type ReactNode } from 'react';
import { ListItemButton, ListItemText, ListItemIcon } from '@mui/material';

type P = PropsWithChildren<{
  icon: ReactNode;
  onClick: () => void;
}> &
  ComponentStyling;

export const ListItem = ({ children, className, icon, onClick, style }: P) => (
  <ListItemButton
    onMouseDown={onClick}
    className={clsx('truncate hover:bg-gray-100', className)}
    style={style}
  >
    <ListItemText className="truncate">{children}</ListItemText>
    {Boolean(icon) && (
      <ListItemIcon className="justify-end">{icon}</ListItemIcon>
    )}
  </ListItemButton>
);
