import { type PdfTemplatesQueryParams } from './useQueryPdfTemplates';

/**
 * Query key factory for pdfTemplates-related queries.
 */
export const queryKeysPdfTemplates = {
  base: () => 'pdfTemplates' as const,
  getAll: (queryParams: Partial<PdfTemplatesQueryParams>) =>
    [queryKeysPdfTemplates.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<PdfTemplatesQueryParams>) =>
    [queryKeysPdfTemplates.base(), 'list', 'infinite', queryParams] as const,
} as const;
