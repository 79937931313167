import clsx from 'clsx';

type P = {
  isSelected: boolean;
};

export const SelectedNavItemIndicator = ({ isSelected }: P) => (
  <div
    className={clsx(
      'bg-primaryLight300 absolute left-0 top-0 h-full w-1 rounded-r transition-opacity duration-300',
      {
        'opacity-0': !isSelected,
      },
    )}
  />
);
