import clsx from 'clsx';

import Chip from '~/components/baseComponents/chips/Chip';
import ChipList from '~/components/baseComponents/chips/ChipList';
import { Spinner } from '~/components/Spinner';

import { type UUID } from '~/types/common';

type Item = {
  id: UUID;
  [key: string]: any;
};

type P<T extends Item> = {
  currentItems: T[];
  currentItemsFiltered: T[];
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  label: string;
  onSelectedItemClick: (id: UUID) => void;
} & ComponentStyling;

export const SelectedItemsDisplayMode = <T extends Item>({
  className,
  currentItems,
  currentItemsFiltered,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  label,
  onSelectedItemClick,
  style,
}: P<T>) => (
  <div
    className={clsx(
      'overflow-y-auto',
      {
        flex: currentItems.length === 0,
      },
      className,
    )}
    style={style}
  >
    {currentItemsFiltered.length > 0 && (
      <ChipList
        items={currentItemsFiltered.map((item) => ({
          ...item,
          className: 'bg-gray-200',
          sx: {},
        }))}
        onClick={onSelectedItemClick}
        clickable={Boolean(onSelectedItemClick)}
      />
    )}
    {currentItems.length === 0 && (
      <div className="m-auto my-auto p-2 text-center text-gray-500">
        <div className="whitespace-nowrap">
          Es sind noch keine {label} zugeordnet
        </div>
      </div>
    )}
    {hasNextPage && (
      <div className="mt-1 flex flex-wrap items-center gap-1">
        <Chip
          key="pagination-key"
          name={
            isFetchingNextPage ? (
              <Spinner title="Lade Daten..." className="-translate-x-1.5" />
            ) : (
              '+ weitere Ergebnisse laden'
            )
          }
          onPaginationClick={fetchNextPage}
          className={clsx(
            'border border-gray-300 transition-all duration-150 hover:border-transparent',
          )}
          isPaginationChip
        />
      </div>
    )}
  </div>
);
