import cloneDeep from 'lodash/cloneDeep';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { validate as uuidvalidate } from 'uuid';
import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  Grid,
  TextField,
} from '@mui/material';

import { useQueryArticlesLegacy } from '~/data/article';
import {
  useMutationCreateCategory,
  useMutationDeleteCategory,
  useMutationDeleteCategoryIcon,
  useMutationUpdateCategory,
  useMutationUpdateCategoryIcon,
  useQueryCategoriesLegacy,
  useQueryCategoryIcon,
  type CategoryListItem,
} from '~/data/category';

import Log from '~/utils/Log';

import ToastService from '~/services/toast.service';

import Article from '~/models/articleMaster/Article';
import Category from '~/models/articleMaster/Category';
import Company from '~/models/masterdata/Company';
import CostCenter from '~/models/masterdata/CostCenter';
import Site from '~/models/masterdata/Site';

import FunctionUtils from '~/utils/functionUtils';

import BasicForm from '~/components/BasicForm';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';
import ImageUpload from '~/components/ImageUpload';
import Select from '~/components/baseComponents/inputs/select/Select';
import { type FormType } from '~/components/settings/SettingsTable/types';

import {
  MultiSelectCompanies,
  MultiSelectCostCenters,
  MultiSelectSites,
} from '~/ui/molecules/SelectServerDriven';
import { withErrorBoundary } from '~/ui/atoms';

const NEW_OPTION_TYPE = {
  COMPANY: 'company',
  COST_CENTER: 'cost_center',
  SITE: 'site',
};

type CategoryFormComponentProps = {
  open: boolean;
  type: FormType;
  closeForm: () => void;
  initialCategory: CategoryListItem;
};

const CategoryFormComponent = ({
  open,
  initialCategory,
  type,
  closeForm,
}: CategoryFormComponentProps) => {
  const [category, setCategory] = useState(new Category());
  const [categoryIcon, setCategoryIcon] = useState(null);

  const { data: categories, isLoading: isLoadingCategoriesLegacy } =
    useQueryCategoriesLegacy();
  const { data: articles, isLoading: isLoadingArticlesLegacy } =
    useQueryArticlesLegacy();

  const {
    mutateAsync: createCategory,
    isPending: isPendingCreateCategory,
    isSuccess: isSuccessCreateCategory,
  } = useMutationCreateCategory();
  const {
    mutateAsync: updateCategory,
    isPending: isPendingUpdateCategory,
    isSuccess: isSuccessUpdateCategory,
  } = useMutationUpdateCategory();
  const {
    mutateAsync: deleteCategory,
    isPending: isPendingDeleteCategory,
    isSuccess: isSuccessDeleteCategory,
  } = useMutationDeleteCategory();

  const { data: categoryIconData, refetch: loadCategoryIcon } =
    useQueryCategoryIcon(initialCategory?.id, {
      enabled: false,
    });
  const { mutateAsync: updateCategoryIcon } = useMutationUpdateCategoryIcon();
  const { mutateAsync: deleteCategoryIcon } = useMutationDeleteCategoryIcon();

  const userinfo = useSelector((state) => state.userinfo);

  const handleLoadCategoryIcon = async () => {
    loadCategoryIcon();
    setCategoryIcon(categoryIconData);
  };

  const handleUploadCategoryIcon = async (
    picture,
    categoryId = initialCategory.id,
  ) => {
    const url = URL.createObjectURL(picture);
    const img = new Image();
    img.src = url;

    img.addEventListener('load', async function () {
      await updateCategoryIcon({ categoryIconId: categoryId, picture });
    });

    setCategoryIcon(picture);
  };

  const handleRemoveCategoryIcon = async () => {
    await deleteCategoryIcon(initialCategory.id);
  };

  const handleResetForm = () => {
    setCategory(initialCategory ?? new Category());
  };

  const handleSetCategoryIcon = (picture) => {
    setCategoryIcon(picture);
  };

  const handleFormSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const body = {
      access: category.access,
      filter_acc_refs: category.filterCostCenters,
      filter_companies: category.filterCompanies,
      filter_sites: category.filterSites,
      is_active: category.isActive,

      linked_articles: category.linkedArticles,

      name: category.name,

      owner_companies: category.ownerCompanies,

      // Pass company account of user as default
      owner_company_accounts: category.ownerCompanyAccounts,

      parent_categories: category.parentCategories,

      sub_categories: category.subCategories,
    };

    Log.info('Submit category form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.ARTICLE_MASTER);

    if (isCreateFormType) {
      const { id } = await createCategory(body);

      if (categoryIcon) {
        handleUploadCategoryIcon(categoryIcon, id);
      }
    } else {
      await updateCategory({
        categoryData: body,
        categoryId: initialCategory.id,
      });

      if (categoryIcon) {
        handleUploadCategoryIcon(categoryIcon);
      } else {
        handleRemoveCategoryIcon();
      }
    }
  };

  const handleFormAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.ARTICLE_MASTER);
    closeForm();
    handleResetForm();
  };

  const handleFormDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete category',
      { id: initialCategory.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.ARTICLE_MASTER);

    await deleteCategory(initialCategory.id);
  };

  const isCreateFormType = useMemo(() => {
    return type === 'create';
  }, [type]);

  const handleInputChange = (event) => {
    const newCategory = cloneDeep(category);

    switch (event.target.name) {
      case 'name': {
        newCategory.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: category.name, to: newCategory.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'category_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.ARTICLE_MASTER],
        );
        break;
      }
    }

    setCategory(newCategory);
  };

  const handleCheckboxChange = (event) => {
    const newCategory = cloneDeep(category);

    switch (event.target.name) {
      case 'is_active': {
        newCategory.isActive = event.target.checked;
        newCategory.isActive = event.target.checked;
        Log.info(
          'Change form value of active',
          { from: category.isActive, to: newCategory.isActive },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change active checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }

      case 'owner_company_accounts': {
        newCategory.ownerCompanyAccounts = event.target.checked
          ? [userinfo.userinfo.company?.companyAccount]
          : [];
        Log.info(
          'Change form value of owner company account',
          {
            from: category.ownerCompanyAccounts,
            to: newCategory.ownerCompanyAccounts,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change owner company account checkbox',
          Log.FEATURE.ARTICLE_MASTER,
        );
        break;
      }
    }

    setCategory(newCategory);
  };

  const handleChangeParentCategories = (parentCategories) => {
    const newCategory = cloneDeep(category);

    newCategory.parentCategories = parentCategories.map(
      (category) => category.id,
    );

    Log.info(
      'Change form value of parent categories',
      {
        from: category.parentCategories,
        to: newCategory.parentCategories,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change parent categories',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setCategory(newCategory);
  };

  const handleChangeSubCategories = (subCategories) => {
    const newCategory = cloneDeep(category);

    newCategory.subCategories = subCategories.map((category) => category.id);

    Log.info(
      'Change form value of sub categories',
      {
        from: category.subCategories,
        to: newCategory.subCategories,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change sub categories',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setCategory(newCategory);
  };

  const handleChangeLinkedArticles = (linkedArticles) => {
    const newCategory = cloneDeep(category);

    newCategory.linkedArticles = linkedArticles.map((article) => article.id);

    Log.info(
      'Change form value of linked articles',
      {
        from: category.linkedArticles,
        to: newCategory.linkedArticles,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change linked articles',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setCategory(newCategory);
  };

  const handleChangeOwnerCompanies = (ownerCompanies) => {
    const newCategory = cloneDeep(category);

    newCategory.ownerCompanies = ownerCompanies.map((company) => company.id);

    Log.info(
      'Change form value of owner companies',
      {
        from: category.ownerCompanies,
        to: newCategory.ownerCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change owner companies',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setCategory(newCategory);
  };

  const handleChangeAccess = (event) => {
    const newCategory = cloneDeep(category);

    newCategory.access = event.target.value;

    Log.info(
      'Change form value of access',
      { from: category.access, to: newCategory.access },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change access', Log.FEATURE.ARTICLE_MASTER);

    setCategory(newCategory);
  };

  const handleChangeFilterCompanies = (filterCompanies) => {
    const newCategory = cloneDeep(category);

    newCategory.filterCompanies = filterCompanies.map((company) => company.id);

    Log.info(
      'Change form value of filter companies',
      {
        from: category.filterCompanies,
        to: newCategory.filterCompanies,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter companies',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setCategory(newCategory);
  };

  const handleChangeFilterSites = (filterSites) => {
    const newCategory = cloneDeep(category);

    newCategory.filterSites = filterSites.map((site) => site.id);

    Log.info(
      'Change form value of filter sites',
      { from: category.filterSites, to: newCategory.filterSites },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter sites',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setCategory(newCategory);
  };

  const handleChangeFilterCostCenters = (filterCostCenters) => {
    const newCategory = cloneDeep(category);

    newCategory.filterCostCenters = filterCostCenters.map(
      (costCenter) => costCenter.id,
    );

    Log.info(
      'Change form value of filter cost centers',
      {
        from: category.filterCostCenters,
        to: newCategory.filterCostCenters,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change filter cost centers',
      Log.FEATURE.ARTICLE_MASTER,
    );

    setCategory(newCategory);
  };

  const getUnsavedChanges = () => {
    if (isCreateFormType) {
      return [];
    }

    const unsavedChanges = Category.getDifferentValues(
      initialCategory,
      category,
    );

    unsavedChanges.push('Kategorielogo');

    return unsavedChanges;
  };

  const handleNewOptionCreate = (option, type) => {
    if (uuidvalidate(option.inputValue)) {
      switch (type) {
        case NEW_OPTION_TYPE.COMPANY: {
          return new Company({
            id: option.inputValue,
            name: option.inputValue,
          });
        }

        case NEW_OPTION_TYPE.SITE: {
          return new Site({ id: option.inputValue, name: option.inputValue });
        }

        case NEW_OPTION_TYPE.COST_CENTER: {
          return new CostCenter({
            id: option.inputValue,
            name: option.inputValue,
          });
        }
      }
    } else {
      ToastService.error(['Ungültige ID: Bitte gib eine gültige UUID ein.']);
      Log.productAnalyticsEvent(
        'Invalid UUID',
        Log.FEATURE.ARTICLE_MASTER,
        Log.TYPE.ERROR,
      );
      return null;
    }
  };

  const getFilterHint = () => {
    if (
      category?.filterCompanies?.length > 0 ||
      category?.filterSites?.length > 0 ||
      category?.filterCostCenters?.length > 0
    ) {
      return (
        <div className="text-12px text-grey500 mb-10px">Exklusiv für...</div>
      );
    }

    if (category?.access === Article.ACCESS.PRIVATE.KEY) {
      return (
        <div className="text-12px text-grey500 mb-10px">
          Diese Kategorie hat aktuell keine Beschränkungen und wird jedem Nutzer
          der oben ausgewählten Firma / Firmenaccount angezeigt.
        </div>
      );
    }

    return (
      <div className="text-12px text-grey500 mb-10px">
        Diese Kategorie hat aktuell keine Beschränkungen und wird jedem Nutzer
        in VESTIGAS angezeigt.
      </div>
    );
  };

  useEffect(() => {
    if (
      isSuccessCreateCategory ||
      isSuccessUpdateCategory ||
      isSuccessDeleteCategory
    ) {
      handleResetForm();
      closeForm();
    }
  }, [
    isSuccessCreateCategory,
    isSuccessUpdateCategory,
    isSuccessDeleteCategory,
  ]);

  useEffect(() => {
    handleResetForm();
  }, []);

  useEffect(() => {
    if (!isCreateFormType) {
      handleLoadCategoryIcon();
    }
  }, []);

  return (
    <BasicForm
      open={open}
      formSuccess={handleFormSuccess}
      formAbort={handleFormAbort}
      formDelete={isCreateFormType ? null : handleFormDelete}
      title={
        'Kategorie ' + (isCreateFormType ? 'erstellen' : initialCategory.name)
      }
      fullWidth
      submittingForm={isPendingCreateCategory || isPendingUpdateCategory}
      deletingForm={isPendingDeleteCategory}
      unsavedChanges={getUnsavedChanges()}
      id={initialCategory?.id}
    >
      <Grid container direction="row">
        <Grid item xs={12} lg={8}>
          <Grid container direction="row" spacing={3} space={4}>
            <Grid item xs={12}>
              <h3 className="main-text mt-0">Kategorie</h3>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="name-input"
                    name="name"
                    label="Name"
                    type="text"
                    required
                    fullWidth
                    value={category?.name}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Grid>
                {isCreateFormType ? null : (
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={category?.isActive}
                          onChange={handleCheckboxChange}
                          name="is_active"
                        />
                      }
                      label="Aktiv"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h3 className="mt-20px main-text">
                Verknüpfte Kategorien und Artikel
              </h3>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <GenericMultiPicker
                    textfieldLabel="Oberkategorien"
                    pickedItemIds={category?.parentCategories ?? []}
                    allItems={
                      categories
                        ? categories.filter(
                            (category) => category.id !== initialCategory?.id,
                          )
                        : []
                    }
                    onSaveSelection={handleChangeParentCategories}
                    loading={isLoadingCategoriesLegacy}
                  />
                </Grid>
                <Grid item xs={6}>
                  <GenericMultiPicker
                    textfieldLabel="Unterkategorien"
                    pickedItemIds={category?.subCategories ?? []}
                    allItems={
                      categories
                        ? categories.filter(
                            (category) => category.id !== initialCategory?.id,
                          )
                        : []
                    }
                    onSaveSelection={handleChangeSubCategories}
                    loading={isLoadingCategoriesLegacy}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GenericMultiPicker
                    textfieldLabel="Enthaltene Artikel"
                    pickedItemIds={category?.linkedArticles ?? []}
                    allItems={
                      articles
                        ? articles.map((article) => {
                            return {
                              ...article,
                              nameComponent:
                                article.articleId + ' ' + article.name,
                            };
                          })
                        : []
                    }
                    onSaveSelection={handleChangeLinkedArticles}
                    loading={isLoadingArticlesLegacy}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} className="flex-c-c flexdir-column mt-50px">
          <InputLabel id="demo-multiple-name-label" className="text-13px">
            Kategorielogo
          </InputLabel>
          <ImageUpload
            image={categoryIcon}
            setImage={handleSetCategoryIcon}
            onDelete={() => setCategoryIcon(null)}
            uploadText="Fügen hier das Kategorielogo hinzu."
          />
        </Grid>
        <Grid item xs={12}>
          <h3 className="mt-20px main-text">Zugeordnet zu</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8} className="flex-s-c gap-20px">
              <div className="w-300px">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={category?.ownerCompanyAccounts?.length > 0}
                      onChange={handleCheckboxChange}
                      name="owner_company_accounts"
                    />
                  }
                  label="Firmen-Account"
                />
              </div>
              <MultiSelectCompanies
                placeholder={
                  'Zu welchen Firmen soll die Kategorie zugeordnet sein?'
                }
                value={category?.ownerCompanies ?? []}
                onChange={handleChangeOwnerCompanies}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">Berechtigung</h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <Select
                value={category?.access}
                fullWidth
                onChange={handleChangeAccess}
                size="small"
                options={Article.getAccessEnum()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="mt-20px main-text">
            Diese Kategorie soll exklusiv sein?
          </h3>
          {getFilterHint()}
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <MultiSelectCompanies
                placeholder={'Firmen'}
                value={category?.filterCompanies ?? []}
                onChange={handleChangeFilterCompanies}
                // FIXME: allow creating new companies
                // onNewOptionCreate={(option) =>
                //   handleNewOptionCreate(option, NEW_OPTION_TYPE.COMPANY)
                // }
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <MultiSelectSites
                placeholder={'Standorte'}
                value={category?.filterSites ?? []}
                onChange={handleChangeFilterSites}
                // FIXME: allow creating new sites
                // onNewOptionCreate={(option) =>
                //   this.handleNewOptionCreate(option, NEW_OPTION_TYPE.SITE)
                // }
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <MultiSelectCostCenters
                placeholder={'Kostenstellen'}
                value={category?.filterCostCenters ?? []}
                onChange={handleChangeFilterCostCenters}
                // FIXME: allow creating new cost centers
                // onNewOptionCreate={(option) =>
                //   handleNewOptionCreate(
                //     option,
                //     NEW_OPTION_TYPE.COST_CENTER,
                //   )
                // }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BasicForm>
  );
};

export const CategoryForm = withErrorBoundary(memo(CategoryFormComponent));
