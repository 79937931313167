import { Checkbox, FormControlLabel } from '@mui/material';

import { LightTooltipWide } from '~/utils/componentUtils';

import { Spinner } from '~/components/Spinner';

export const FeatureFlagSettings = ({
  featureFlags = {},
  isError,
  isLoading,
  onChange,
}) => {
  if (isLoading) {
    return (
      <div className="h-12 w-96">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return <div className="h-12 w-96">Daten konnten nicht geladen werden.</div>;
  }

  const {
    accessPermittedSites,
    concreteDiary,
    concreteEditingEnabled,
    lineItemDetailsEditingDisabled,
    ocrEnabled,
    recipientSetArrived,
    showOrderAtPreview,
    userCannotSetAccepted,
    userCannotSetDeclined,
    userCannotSetDelivered,
    userMetrics,
  } = featureFlags;

  return (
    <div className="w-fit-content flex w-fit flex-col">
      <LightTooltipWide
        title={
          <div>
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="font-bold">
                Berechtigte Lieferorte und Kostenstellen einsehen
              </span>{' '}
              freigeschaltet ist:
            </div>
            <div>
              - können berechtigte Lieferorte und berechtigte Kostenstelle in
              der Lieferungsliste und -detailansicht einsehen.
            </div>
            <div>
              - Zudem wird der Tab mit nicht zugeordneten Lieferungen in der
              Lieferungsliste angezeigt.
            </div>
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(accessPermittedSites)}
              onChange={onChange}
              name="feature_flag_access_permitted"
            />
          }
          label={'Berechtigte Lieferorte und Kostenstellen einsehen'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">Benutzerstatistiken einsehen</span>{' '}
            freigeschaltet ist, können diese unter "Statistiken" einsehen.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(userMetrics)}
              onChange={onChange}
              name="feature_flag_user_metrics"
            />
          }
          label={'Benutzerstatistiken einsehen'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">Betondaten bearbeiten</span>{' '}
            freigeschaltet ist, können in der mobilen App Betondaten einer
            Lieferung verändern.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(concreteEditingEnabled)}
              onChange={onChange}
              name="feature_flag_concrete_editing_enabled"
            />
          }
          label={'Betondaten bearbeiten'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">Betontagebuch</span> freigeschaltet ist,
            können in der Webapp das Betontagebuch einsehen.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(concreteDiary)}
              onChange={onChange}
              name="feature_flag_concrete_diary"
            />
          }
          label={'Betontagebuch'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">Artikeldetails nicht bearbeiten</span>{' '}
            freigeschaltet ist, können in der mobilen App Artikeldetails nicht
            verändern.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(lineItemDetailsEditingDisabled)}
              onChange={onChange}
              name="feature_flag_line_item_details_editing_disabled"
            />
          }
          label={'Artikeldetails nicht bearbeiten'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">
              Empfänger kann das Angekommen Event setzen
            </span>{' '}
            freigeschaltet ist, können in der mobilen App eine Lieferung als
            angekommen markieren.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(recipientSetArrived)}
              onChange={onChange}
              name="feature_flag_recipient_set_arrived"
            />
          }
          label={'Empfänger kann das "Angekommen" Event setzen'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">
              Nutzer kann das "Geliefert" Event nicht setzen
            </span>{' '}
            freigeschaltet ist, können in der mobilen App eine Lieferung nicht
            als geliefert markieren.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(userCannotSetDelivered)}
              onChange={onChange}
              name="feature_flag_user_cannot_set_delivered"
            />
          }
          label={'Nutzer kann das "Geliefert" Event nicht setzen'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">
              Nutzer kann das "Angenommen" Event nicht setzen
            </span>{' '}
            freigeschaltet ist, können in der mobilen App eine Lieferung nicht
            als angenommen markieren.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(userCannotSetAccepted)}
              onChange={onChange}
              name="feature_flag_user_cannot_set_accepted"
            />
          }
          label={'Nutzer kann das "Angenommen" Event nicht setzen'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">
              Nutzer kann das "Abgelehnt" Event nicht setzen
            </span>{' '}
            freigeschaltet ist, können in der mobilen App eine Lieferung nicht
            als abgelehnt markieren.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(userCannotSetDeclined)}
              onChange={onChange}
              name="feature_flag_user_cannot_set_declined"
            />
          }
          label={'Nutzer kann das "Abgelehnt" Event nicht setzen'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">
              Bestellreferenz in Vorschau anzeigen (mobile App)
            </span>{' '}
            freigeschaltet ist, können in der mobilen App die Bestellreferenz
            einer Lieferung in der Vorschau einsehen.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(showOrderAtPreview)}
              onChange={onChange}
              name="feature_flag_show_order_at_preview"
            />
          }
          label={'Bestellreferenz in Vorschau anzeigen (mobile App)'}
        />
      </LightTooltipWide>
      <LightTooltipWide
        title={
          <div>
            Benutzer, bei denen das Feature{' '}
            <span className="font-bold">Lieferschein-OCR</span> freigeschaltet
            ist, können Lieferscheine in der mobilen App über OCR einlesen.
          </div>
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(ocrEnabled)}
              onChange={onChange}
              name="feature_flag_ocr_enabled"
            />
          }
          label={'Lieferschein-OCR'}
        />
      </LightTooltipWide>
    </div>
  );
};
