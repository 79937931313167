import { useQueriesUserGroups } from '~/data/userGroup';

import UserGroup from '~/models/masterdata/UserGroup';

import { Path } from './Path';

export const UserGroupPaths = ({ id, onOpenUserGroup, userGroupPaths }) => {
  if (userGroupPaths.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="font-bold">Teil der Benutzer-Gruppen:</div>
      <div className="mt-2 flex flex-col gap-2">
        {userGroupPaths.map((userGroupPath, index) => {
          const pathId = [
            `user-group-path-${id}`,
            index,
            JSON.stringify(userGroupPath),
          ].join('-');

          return (
            <Path
              key={pathId}
              id={pathId}
              Model={UserGroup}
              onOpenEntity={onOpenUserGroup}
              path={userGroupPath}
              useQueriesEntities={useQueriesUserGroups}
            />
          );
        })}
      </div>
    </div>
  );
};
