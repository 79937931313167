import { convert } from 'geo-coordinates-parser';

import Article from '~/models/articles/Article';

import ArrayUtils from './arrayUtils';

class UnitUtils {
  round(number) {
    return Math.round(number * 100) / 100;
  }

  round_safe = (number) => {
    // null is explicitly caught because it would be parsed to 0 in this.round -> Math.round.
    // Actually, the better solution is to use TypeScript for those type checks.
    if (number === null) return null;

    const roundedNumber = this.round(number);

    if (!this.isValidNumber(roundedNumber)) return null;

    return roundedNumber;
  };

  formatDe(number) {
    return number.toLocaleString('de-DE', {
      maximumFractionDigits: 20,
      minimumFractionDigits: 0,
    });
  }

  formatDe_safe = (number) => {
    // null and undefined are explicitly caught because they would throw the "properties of undefined/null" error in this.formatDe -> number.toString.
    // Actually, the better solution is to use TypeScript for those type checks.
    if (number == null) return null;

    return this.formatDe(number);
  };

  formatDeWithPrecision(number, minPrecision, maxPrecision) {
    return number.toLocaleString('de-DE', {
      maximumFractionDigits: maxPrecision,
      minimumFractionDigits: minPrecision,
    });
  }

  formatDeWithPrecision_safe = (number, minPrecision, maxPrecision) => {
    // null and undefined are explicitly caught because they would throw the "properties of undefined/null" error in this.formatDe -> number.toString.
    // Actually, the better solution is to use TypeScript for those type checks.
    if (number === null || number === undefined) return null;

    return this.formatDeWithPrecision(number, minPrecision, maxPrecision);
  };

  formatStringDe_safe(string) {
    // @ts-ignore
    if (
      string === undefined ||
      string === null ||
      string === '' ||
      Number(string) === 'NaN'
    )
      return string;

    return Number(string).toLocaleString('de-DE', {
      maximumFractionDigits: 20,
      minimumFractionDigits: 0,
    });
  }

  complexFormatDe(string) {
    let deNumberExpected = true;

    if (!string.includes(',') && string.includes('.')) {
      // e.g. 25.24
      deNumberExpected = false;
    }

    if (
      string.includes(',') &&
      string.includes('.') && // e.g. 25,24
      string.indexOf(',') < string.indexOf('.')
    ) {
      // e.g. 2,971.97
      deNumberExpected = false;
    }

    if (deNumberExpected) {
      if (!this.isDeNumber(string)) throw new Error('Invalid de number');

      return string.replaceAll(/[^\d,-]/g, ''); // cut out all chars that are neither a number nor a comma (e.g. 2.971,97 -> 2971,97)
    }

    if (!this.isEnNumber(string)) throw new Error('Invalid en number');

    return string.replaceAll(/[^\d.-]/g, '').replace('.', ','); // cut out all chars that are neither a number nor a dot and format to de (e.g. 2,971.97 -> 2971,97)
  }

  isDeNumber = (string) => {
    string = this.handleLeadingAndFollowingDelimiter(string, ',');

    if (string === '') return true;

    if (string.includes('.')) {
      return /^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/.test(string);
    }

    return /^\d+,?\d*\b$/.test(string);
  };
  isEnNumber = (string) => {
    string = this.handleLeadingAndFollowingDelimiter(string, '.');

    if (string === '') return true;

    if (string.includes(',')) {
      return /^-?\d{1,3}(?:,\d{3})*(?:\.\d+)?$/.test(string);
    }

    return /^\d+\.?\d*\b$/.test(string);
  };

  handleLeadingAndFollowingDelimiter(string, separator) {
    if (string.indexOf(separator) === string.length - 1)
      string = string.slice(0, Math.max(0, string.length - 1)); // if string ends with separator, remove the separator for the validation
    if (string[0] === separator) string = '0' + string; // add 0 to front if string starts with separator so that regex validates this as true (e.g. ,864)

    return string;
  }

  // return german number format
  // 123.4586 -> 123,46
  roundAndFormatDe = (number) => {
    return this.formatDe(this.round(number));
  };
  roundAndFormatDe_safe = (number) => {
    return this.formatDe_safe(this.round_safe(number));
  };
  // similar to roundAndFormatDe but with the addition that the precision is always exactly two decimals, e.g. 135,00
  formatDeMoneyAmount = (number) => {
    let amount = this.roundAndFormatDe(number);

    const [integers, decimals] = amount.split(',');

    if (decimals) {
      for (let index = decimals.length; index < 2; index++) {
        amount += '0';
      }
    } else {
      amount = integers + ',00';
    }

    // add dot as thousands separator
    amount = amount.replaceAll(/\B(?=(\d{3})+(?!\d))/g, '.');

    return amount;
  };
  formatDeMoneyAmount_safe = (number) => {
    const amount = this.roundAndFormatDe_safe(number);

    if (amount === null) return null;

    return this.formatDeMoneyAmount(number);
  };

  // convert a weight to the target unit
  // 123.4586, TNE, KGM -> 123458.6
  calculateWeightInTargetUnit(value, unit, targetUnit) {
    if (unit === targetUnit) return value;

    const unitConst = Object.keys(Article.UNIT).find((x) => {
      return (
        unit === Article.UNIT[x].STANDARDISED ||
        unit === Article.UNIT[x].ABBREVIATED
      );
    });

    if (!unitConst) {
      // Log.error(null, new EnumValueNotFoundException('Invalid unit: ' + unit));
      return 0;
    }

    const targetUnitConst = Object.keys(Article.UNIT).find((x) => {
      return (
        targetUnit === Article.UNIT[x].STANDARDISED ||
        targetUnit === Article.UNIT[x].ABBREVIATED
      );
    });

    if (!targetUnitConst) {
      // Log.error(null, new EnumValueNotFoundException('Invalid unit: ' + targetUnit));
      return 0;
    }

    // No need to make any calculations if the units are the same
    if (
      Article.UNIT[unitConst].STANDARDISED ===
      Article.UNIT[targetUnitConst].STANDARDISED
    )
      return value;

    // If the units have no factor (i.e. amount units such as canister, can, etc.), no calculation is possible
    if (!Article.UNIT[unitConst].FACTOR || Article.UNIT[targetUnitConst].FACTOR)
      return 0;

    return (
      (value * Article.UNIT[unitConst].FACTOR) /
      Article.UNIT[targetUnitConst].FACTOR
    );
  }

  // return the corresponding descriptive unit of a unit
  // TNE -> Tonne
  getDescriptiveUnit(standardisedUnit) {
    if (!standardisedUnit) {
      return '';
    }

    const unitConst = Object.keys(Article.UNIT).find((x) => {
      return Article.UNIT[x].STANDARDISED === standardisedUnit;
    });

    if (!unitConst) {
      // Log.error(null, new EnumValueNotFoundException('Invalid unit: ' + standardisedUnit));
      return standardisedUnit;
    }

    return Article.UNIT[unitConst].DESCRIPTIVE;
  }

  // return the corresponding abbreviated unit of a unit
  // TNE -> t
  getAbbreviatedUnit(standardisedUnit) {
    if (!standardisedUnit) {
      return '';
    }

    const unitConst = Object.keys(Article.UNIT).find((x) => {
      return Article.UNIT[x].STANDARDISED === standardisedUnit;
    });

    if (!unitConst) {
      // Log.error(null, new EnumValueNotFoundException('Invalid unit: ' + standardisedUnit));
      return standardisedUnit;
    }

    return Article.UNIT[unitConst].ABBREVIATED;
  }

  // TNE, KGM -> t, kg
  getAbbreviatedUnits = (standardisedUnits) => {
    if (!standardisedUnits) {
      return '';
    }

    const units = standardisedUnits.split(', ');
    const abbreviatedUnits = units.map((standardisedUnit) =>
      this.getAbbreviatedUnit(standardisedUnit),
    );
    return ArrayUtils.joinComponents(abbreviatedUnits);
  };

  getAbbreviatedUnitString(standardisedUnit) {
    if (!standardisedUnit) {
      return '';
    }

    const unitConst = Object.keys(Article.UNIT).find((x) => {
      return Article.UNIT[x].STANDARDISED === standardisedUnit;
    });

    if (!unitConst) {
      // Log.error(null, new EnumValueNotFoundException('Invalid unit: ' + standardisedUnit));
      return standardisedUnit;
    }

    return (
      Article.UNIT[unitConst].ABBREVIATED_STRING ??
      Article.UNIT[unitConst].ABBREVIATED
    );
  }

  // based on the KGM_FACTOR (factor of how many kilograms fit), it is determined whether a unit is bigger
  // isBiggerUnit("TNE", "KGM") -> true
  isBiggerUnit(standardisedUnitA, standardisedUnitB) {
    const unitConstA = Object.keys(Article.UNIT).find((x) => {
      return Article.UNIT[x].STANDARDISED === standardisedUnitA;
    });

    if (!unitConstA) {
      // Log.error(null, new EnumValueNotFoundException('Invalid unit: ' + standardisedUnitA));
      return false;
    }

    const unitConstB = Object.keys(Article.UNIT).find((x) => {
      return Article.UNIT[x].STANDARDISED === standardisedUnitB;
    });

    if (!unitConstB) {
      // Log.error(null, new EnumValueNotFoundException('Invalid unit: ' + standardisedUnitB));
      return false;
    }

    return (
      Article.UNIT[unitConstA].KGM_FACTOR > Article.UNIT[unitConstB].KGM_FACTOR
    );
  }

  /**
   * Returns the biggest unit from a list of standardised units.
   *
   * @param {string[]} standardisedUnits - A list of standardised units.
   * @return {string} The biggest unit. If the input list is empty, an empty string is returned.
   */
  getBiggestUnit(standardisedUnits) {
    if (!standardisedUnits?.length) {
      {
        return '';
      }
    }

    const uniqueUnits = Array.from(new Set(standardisedUnits));

    const biggestUnit = uniqueUnits.reduce(
      (previous, current) =>
        this.isBiggerUnit(previous, current) ? previous : current,
      '',
    );

    return biggestUnit;
  }

  // getUnitTypeOfUnit("EA") -> "amount"
  // getUnitTypeOfUnit("LTR") -> "volume"
  getUnitTypeOfUnit(standardisedUnit) {
    const unitConst = Object.keys(Article.UNIT).find((x) => {
      return Article.UNIT[x].STANDARDISED === standardisedUnit;
    });

    if (!unitConst) {
      // Log.error(null, new EnumValueNotFoundException('Invalid unit: ' + standardisedUnit));
      return null;
    }

    return Article.UNIT[unitConst].TYPE;
  }

  // If unit isn't found in dict, take "amount" as a fallback unit type
  getUnitTypeOfUnit_safe = (standardisedUnit) => {
    const unitType = this.getUnitTypeOfUnit(standardisedUnit);

    if (!unitType) {
      return 'amount';
    }

    return unitType;
  };
  formatValueUnitPair_safe = (value, unit, unitFormatter) => {
    const formattedValue = this.formatDe_safe(value);
    const formattedUnit = unitFormatter(unit);

    if (!formattedValue || !formattedUnit) return null;

    // needs to return a component because m3 must not be parsed to a String to render correctly
    return (
      <>
        {formattedValue} <>{formattedUnit}</>
      </>
    );
  };
  formatValueUnitPairAsString_safe = (value, unit, unitFormatter) => {
    const formattedValue = this.formatDe_safe(value);
    const formattedUnit = unitFormatter(unit);

    if (!formattedValue || !formattedUnit) return null;

    return formattedValue + ' ' + formattedUnit;
  };
  formatStringUnitPair_safe = (string, unit, unitFormatter) => {
    const formattedValue = this.formatStringDe_safe(string);
    const formattedUnit = unitFormatter(unit);

    if (!formattedValue || !formattedUnit) return null;

    // needs to return a component because m3 must not be parsed to a String to render correctly
    return (
      <>
        {formattedValue} <>{formattedUnit}</>
      </>
    );
  };
  // determines whether unit is a volume unit
  // isVolumeUnit("LTR") -> true
  // isVolumeUnit("TNE") -> false
  isVolumeUnit = (standardisedUnit) => {
    return this.getUnitTypeOfUnit(standardisedUnit) === 'volume';
  };
  // determines whether unit is a weight unit
  // isWeightUnit("TNE") -> true
  // isWeightUnit("LTR") -> false
  isWeightUnit = (standardisedUnit) => {
    return this.getUnitTypeOfUnit(standardisedUnit) === 'weight';
  };
  // determines whether unit is an amount unit
  // isAmountUnit("EA") -> true
  // isAmountUnit("LTR") -> false
  isAmountUnit = (standardisedUnit) => {
    return this.getUnitTypeOfUnit(standardisedUnit) === 'amount';
  };

  isValidCoordinate(coordinate) {
    let converted;

    try {
      coordinate = coordinate.toString().replace('"N', '').replace('"E', '');
      converted = convert(coordinate + ', ' + coordinate, null);
      return true;
    } catch {
      return false;
    }
  }

  getConvertedCoordinate(coordinate) {
    coordinate = coordinate.toString().replace('"N', '').replace('"E', '');
    const converted = convert(coordinate + ', ' + coordinate, null);
    return converted.decimalLatitude;
  }

  isValidNumber(number) {
    return typeof number === 'number' && !isNaN(number);
  }

  parseToNumber = (value) => {
    const number = Number(value);

    if (!this.isValidNumber(number)) return null;

    return number;
  };
}

export default new UnitUtils();
