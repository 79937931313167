import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysCategory } from './queryKeys';

type MutationCategoryIconArgument = {
  categoryIconId?: UUID;
  categoryIconData: string;
};

/**
 * Update an existing categoryIcon.
 *
 * @param {string} categoryIconId - The ID of the categoryIcon to update.
 * @param {Object} categoryIconData - An object containing the vehicle data.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/upload_article_category_icon_article_master_category__category_uuid__icon_put
 */
export const updateCategoryIcon = async (
  categoryIconId: UUID,
  categoryIconData: string,
) => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.CATEGORY.UPDATE_ICON(categoryIconId), {
        headers: {
          'Content-Type': 'image/png',
        },
        json: categoryIconData,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating categoryIcon settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating an existing categoryIcon.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */

export const useMutationUpdateCategoryIcon = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      categoryIconId,
      categoryIconData,
    }: MutationCategoryIconArgument) =>
      updateCategoryIcon(categoryIconId, categoryIconData),
    async onError(error) {
      Log.productAnalyticsEvent(
        'Failed to update category',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(data, { categoryIconId }) {
      if (categoryIconId) {
        queryClient.invalidateQueries({
          queryKey: queryKeysCategory.get(categoryIconId),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeysCategory.getAll({}),
        });
      }
    },
    ...options,
  });
};
