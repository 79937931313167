import CustomField from '~/models/customData/CustomField';

export const selectCustomFields = (data) => ({
  customFields:
    data.items.map((customField) => new CustomField(customField)) ?? [],
  rows:
    data.items.map((customField) => ({
      ...customField,
      hasOptions: customField.hasOptions ? 'Ja' : 'Nein',
      key: customField.name
        ? customField.id + '_' + customField.name
        : customField.id,
      level: CustomField.getLevelString(customField.level),
      public: customField.public ? 'Öffentlich' : 'Privat',
      type: CustomField.getTypeString(customField.type),
      visibility: CustomField.getVisibilityString(customField.visibility),
    })) ?? [],
});
