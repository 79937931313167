export const columns = [
  {
    field: 'name',
    headerName: 'Name',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'displayName',
    headerName: 'Anzeige-Name',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'type',
    headerName: 'Typ',
    resizableText: true,
    sortable: true,
    width: 200,
  },
  {
    field: 'hasOptions',
    headerName: 'Dropdown',
    resizableText: true,
    sortable: true,
    width: 150,
  },
  {
    field: 'level',
    headerName: 'Level',
    resizableText: true,
    sortable: true,
    width: 150,
  },
  {
    field: 'visibility',
    headerName: 'Sichtbarkeit',
    resizableText: true,
    sortable: true,
    width: 150,
  },
  {
    field: 'public',
    headerName: 'Öffentlich/Privat',
    resizableText: true,
    sortable: true,
    width: 150,
  },
  {
    field: 'key',
    headerName: 'Key',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    field: 'id',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
];
