import { type UUID } from '~/types/common';

/**
 * Query key factory for delivery note-related queries.
 */
export const queryKeysDeliveryNote = {
  base: () => 'deliveryNotes' as const,
  getAllInfinite: () =>
    [queryKeysDeliveryNote.base(), 'list', 'infinite'] as const,
  getAttachment: (chainId: UUID, digest: string) =>
    [queryKeysDeliveryNote.base(), 'attachment', { chainId, digest }] as const,
  getHandwrittenSignature: (chainId: UUID) =>
    [
      queryKeysDeliveryNote.base(),
      'handwrittenSignature',
      { chainId },
    ] as const,
  getPermittedUserIds: (deliveryNoteId: UUID) =>
    [
      queryKeysDeliveryNote.base(),
      'permittedUsers',
      { deliveryNoteId },
    ] as const,
  getSharedWithUsers: (deliveryNoteId: UUID) =>
    [
      queryKeysDeliveryNote.base(),
      'sharedWithUsers',
      { deliveryNoteId },
    ] as const,
} as const;
