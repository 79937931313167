import { type UUID } from '~/types/common';
import { type CategoriesQueryParams } from './useQueryCategories';

/**
 * Query key factory for category-related queries.
 */
export const queryKeysCategory = {
  base: () => 'categories' as const,
  get: (categoryId: UUID) =>
    [queryKeysCategory.base(), 'detail', { categoryId }] as const,
  getAll: (queryParams: Partial<CategoriesQueryParams>) =>
    [queryKeysCategory.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<CategoriesQueryParams>) =>
    [queryKeysCategory.base(), 'list', 'infinite', queryParams] as const,
  getAllLegacy: () => [queryKeysCategory.base(), 'list', 'LEGACY'] as const,
  getCategoryIcon: () => [queryKeysCategory.base(), 'icon'] as const,
} as const;
