import cloneDeep from 'lodash/cloneDeep';

import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';

export const handleChangeDate = (date, eventName, setState) => {
  setState((previousState) => {
    const newCostCenter = cloneDeep(previousState.costCenter);
    newCostCenter[eventName] = new Date(
      new Date(date).setUTCHours(0, 0, 0, 0),
    ).toISOString();

    Log.info(
      `Change form value of ${eventName} date`,
      {
        from: previousState[eventName],
        to: newCostCenter[eventName],
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      `Change ${eventName} date`,
      Log.FEATURE.COST_CENTER,
    );

    return {
      ...previousState,
      costCenter: newCostCenter,
    };
  });
};

export const handleChangeName = (event, setState) => {
  setState((previousState) => {
    const newCostCenter = cloneDeep(previousState.costCenter);
    newCostCenter.name = event.target.value;

    Log.info(
      'Change form value of name',
      {
        from: previousState.costCenter.name,
        to: newCostCenter.name,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    FunctionUtils.delayFunction(
      'cost_center_change_name',
      Log.productAnalyticsEvent,
      ['Change name', Log.FEATURE.COST_CENTER],
    );

    return {
      ...previousState,
      costCenter: newCostCenter,
    };
  });
};

export const handleChangeActive = (event, setState) => {
  setState((previousState) => {
    const newCostCenter = cloneDeep(previousState.costCenter);
    newCostCenter.isActive = event.target.checked;

    Log.info(
      'Change form value of isActive',
      {
        from: previousState.costCenter.isActive,
        to: newCostCenter.isActive,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change isActive checkbox',
      Log.FEATURE.COST_CENTER,
    );

    return {
      ...previousState,
      costCenter: newCostCenter,
    };
  });
};

export const handleChangeCompany = (companyId, setState) => {
  setState((previousState) => {
    const newCostCenter = cloneDeep(previousState.costCenter);
    newCostCenter.companyId = companyId;

    Log.info(
      'Change form value of companyId',
      {
        from: previousState.costCenter.companyId,
        to: newCostCenter.companyId,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change company', Log.FEATURE.COST_CENTER);

    return {
      ...previousState,
      costCenter: newCostCenter,
    };
  });
};

export const handleChangeOrganisationalGroups = (
  { added, removed },
  setState,
) => {
  setState((previousState) => {
    const newCostCenter = cloneDeep(previousState.costCenter);

    const removedIds = new Set(removed.map(({ id }) => id));
    const addedIds = new Set(added.map(({ id }) => id));

    newCostCenter.organisationalGroups = [
      ...new Set([
        ...newCostCenter.organisationalGroups.filter(
          (id) => !removedIds.has(id),
        ),
        ...addedIds,
      ]),
    ];

    Log.info(
      'Change form value of organisational groups',
      {
        from: previousState.costCenter.organisationalGroups,
        to: newCostCenter.organisationalGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change organisational groups',
      Log.FEATURE.COST_CENTER,
    );

    return {
      ...previousState,
      costCenter: newCostCenter,
    };
  });
};
