import { useCallback, useEffect, useState, Fragment } from 'react';
import {
  Button,
  Chip,
  Divider,
  InputAdornment,
  Popover,
  Radio,
  TextField,
} from '@mui/material';
import {
  ArrowDropDown as ArrowDropDownIcon,
  Person as PersonIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import { useStyles } from '../styles';

export const SearchableSelect = ({ onSelect, options, selectedOption }) => {
  const [element, setElement] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedValue, setSelectedValue] = useState(() =>
    options.find(({ id }) => id === selectedOption),
  );
  const classes = useStyles();

  const open = Boolean(element);
  const id = open ? 'select-with-search' : undefined;

  useEffect(() => {
    const currentOption = options.find(({ id }) => id === selectedOption);
    setSelectedValue(currentOption);

    if (open) {
      setFilteredOptions(options);
    }
  }, [options, open]);

  const getFilteredOptions = useCallback(
    (searchQuery) => {
      const filteredValues = options.filter((option) => {
        if (
          searchQuery !== '' &&
          !option.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return false;
        }

        return true;
      });

      setFilteredOptions(filteredValues);
    },
    [options],
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    getFilteredOptions(value);
  };

  return (
    <div className={classes.searchableSelectContainer}>
      <div className="gap-5px flex items-center">
        <Chip
          onClick={(event) => setElement(event.currentTarget)}
          avatar={<PersonIcon />}
          deleteIcon={<ArrowDropDownIcon />}
          onDelete={(event) => {
            setElement(event.currentTarget);
          }}
          size="medium"
          variant="outlined"
          label={selectedValue?.name ?? ''}
        />
        {selectedValue && (
          <Button
            onClick={() => onSelect(null)}
            variant="contained"
            color="secondary"
            size="small"
          >
            Löschen
          </Button>
        )}
      </div>

      <Popover
        id={id}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        open={open}
        anchorEl={element}
        onClose={() => setElement(null)}
        className={`${classes.popover} ${classes.searchContainer}`}
      >
        <div className="w-360px content-container">
          {filteredOptions.length > 5 && (
            <>
              <div className="search-input">
                <TextField
                  onChange={handleInputChange}
                  placeholder="Suche..."
                  variant="outlined"
                  fullWidth
                  disabled={options.length === 0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Divider />
            </>
          )}
          <div className="select-list">
            {options.length === 0 && (
              <p className="empty-state">
                Es gibt noch keine verantwortlichen Personen
              </p>
            )}
            {filteredOptions.length > 0 ? (
              <Fragment>
                {filteredOptions.map((option, index) => {
                  const checked = selectedValue?.id === option?.id;
                  const isLastItem = filteredOptions.length - 1 === index;

                  return (
                    <Fragment key={option.id}>
                      <div
                        className="option"
                        onMouseDown={() => {
                          onSelect(
                            option.id === selectedValue?.id ? null : option.id,
                          );
                        }}
                      >
                        <p className="option-name">{option.name}</p>
                        <Radio checked={checked} color="primary" />
                      </div>
                      {!isLastItem && <Divider />}
                    </Fragment>
                  );
                })}
              </Fragment>
            ) : (
              <p className="empty-state">
                Keine passende verantwortliche Person gefunden
              </p>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};
