export default class DataExchange {
  constructor(dataExchange) {
    this.id = dataExchange?.id ?? '';
    this.activeCustomerIds =
      dataExchange?.activeCustomerIds ??
      dataExchange?.active_customer_ids ??
      '';
    this.contactAttempts =
      dataExchange?.contactAttempts ?? dataExchange?.contact_attempts ?? [];
    this.contactedPerson =
      dataExchange?.contactedPerson ?? dataExchange?.contacted_person ?? null;
    this.contactingLink =
      dataExchange?.contactingLink ?? dataExchange?.contacting_link ?? null;
    this.exchangeDeliveryNotes =
      dataExchange?.exchangeDeliveryNotes ??
      dataExchange?.exchange_lfs ??
      false;
    this.exchangeInvoices =
      dataExchange?.exchangeInvoices ??
      dataExchange?.exchange_invoices ??
      false;
    this.exchangeOrders =
      dataExchange?.exchangeOrders ?? dataExchange?.exchange_orders ?? false;
    this.furtherInformation =
      dataExchange?.furtherInformation ??
      dataExchange?.further_information ??
      '';
    this.isExchangeViaPaper =
      dataExchange?.isExchangeViaPaper ??
      dataExchange?.is_exchange_via_paper ??
      false;
    this.knownIssues =
      dataExchange?.knownIssues ?? dataExchange?.known_issues ?? '';
    this.lastContactAttempt =
      dataExchange?.lastContactAttempt ??
      dataExchange?.contact_attempts?.[0] ??
      null;
    this.lastContactAttemptTimestamp =
      dataExchange?.lastContactAttemptTimestamp ??
      dataExchange?.contact_attempts?.[0]?.timestamp ??
      null;
    this.receiverId =
      dataExchange?.receiverId ?? dataExchange?.receiver_id ?? '';
    this.responsiblePerson =
      dataExchange?.responsiblePerson ??
      dataExchange?.responsible_person ??
      null;
    this.senderId = dataExchange?.senderId ?? dataExchange?.sender_id ?? '';
  }
}
