import { useState } from 'react';

import { cn } from '~/utils/tailwind';

import Chip from './Chip';

const ChipList = ({
  className,
  clickable,
  deletable,
  fieldName,
  items,
  onClick,
  onDelete,
  onPaginationClick: parentOnPaginationClick,
  selectedItemIsNotClickable,
  withInternalPagination,
}) => {
  const INTERNAL_PAGINATE_BY = 10;

  // Internal Pagination can be toggled via withInternalPagination.
  // The internal pagination helps to not have to implement the pagination logic in the parent component.
  const [internalPaginationOffset, setInternalPaginationOffset] =
    useState(INTERNAL_PAGINATE_BY);

  const onPaginationClick = () => {
    if (!withInternalPagination) {
      parentOnPaginationClick();
      return;
    }

    setInternalPaginationOffset(
      internalPaginationOffset + INTERNAL_PAGINATE_BY,
    );
  };

  const getItems = () => {
    if (!withInternalPagination) {
      return items;
    }

    return items.slice(0, internalPaginationOffset);
  };

  return (
    <div className={cn('flex flex-wrap items-center gap-1', className)}>
      {getItems().map((item) => (
        <Chip
          key={item.id}
          {...item}
          name={item[fieldName ?? 'name']}
          onClick={() => onClick(item)}
          onPaginationClick={onPaginationClick}
          onDelete={onDelete}
          clickable={
            selectedItemIsNotClickable && item.selected ? false : clickable
          }
          deletable={deletable}
        />
      ))}
      {withInternalPagination && items.length > internalPaginationOffset ? (
        <Chip
          id={null}
          key="pagination-key"
          name={`+ ${items.length - internalPaginationOffset} weitere Items`}
          isPaginationChip
          onPaginationClick={onPaginationClick}
        />
      ) : null}
    </div>
  );
};

export default ChipList;
