import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ChevronRight as ChevronRightIcon,
  LocationOn as LocationOnIcon,
  Person as PersonIcon,
  Shield as ShieldIcon,
} from '@mui/icons-material';
import { Chip } from '@mui/material';

import { ROUTE } from '~/constants/Route';
import { SettingsTestIds } from '~/constants/test-ids';

import { useQueryUserData } from '~/data/user';

import UserService from '~/services/user.service';

import Log from '~/utils/Log';

import { withErrorBoundary } from '~/ui/atoms';

import {
  CreateSiteWizard,
  CreateUserWizard,
  UpdatePermissionsWizard,
} from './wizard';

export const MasterData = withErrorBoundary(() => {
  const history = useHistory();

  const {
    data: currentUser,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
    isSuccess: isSuccessUserData,
  } = useQueryUserData(true);

  const userPermissions = currentUser?.userPermissions ?? [];
  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};

  const [chips, setChips] = useState([]);

  useEffect(() => {
    initChips();
  }, []);

  const initChips = () => {
    let chipsList = [
      {
        label: 'Benutzer',
        route: ROUTE.SETTINGS_USER.ROUTE,
      },
      {
        label: 'Standorte',
        route: ROUTE.SETTINGS_SITE.ROUTE,
      },
      {
        label: 'Kostenstellen',
        route: ROUTE.SETTINGS_COST_CENTER.ROUTE,
      },
      {
        label: 'Fahrzeuge',
        route: ROUTE.SETTINGS_VEHICLE.ROUTE,
      },
      {
        label: 'Firmen',
        route: ROUTE.SETTINGS_COMPANY.ROUTE,
      },
      {
        label: 'Organisations-Gruppen',
        route: ROUTE.SETTINGS_ORGANISATIONAL_GROUP.ROUTE,
      },
      {
        label: 'Benutzer-Gruppen',
        route: ROUTE.SETTINGS_USER_GROUP.ROUTE,
      },
    ];

    chipsList = chipsList.filter((chip) =>
      UserService.userIsAuthorizedForPage(
        chip.route,
        userPermissions,
        featureFlags,
      ),
    );

    setChips(chipsList);
  };

  const openSettingsPage = (chip) => {
    Log.productAnalyticsEvent(
      `Open ${chip.label} from Stammdaten`,
      Log.FEATURE.MENU,
    );
    history.push(chip.route);
  };

  return (
    <div className="ml-2rem mr-2rem">
      <div className="main-header" data-testid={SettingsTestIds.HEADER.TITLE}>
        Stammdaten
      </div>
      <div className="flex-sb-s gap-20px">
        <div className="p-20px rounded-5px box-shadow-blue w-full bg-white text-center">
          <div className="flex-c-c gap-5px mb-20px">
            <PersonIcon />
            <div className="text-16px bold">Benutzer</div>
          </div>
          <CreateUserWizard />
        </div>
        <div className="p-20px rounded-5px box-shadow-blue w-full bg-white text-center">
          <div className="flex-c-c gap-5px mb-20px">
            <LocationOnIcon />
            <div className="text-16px bold">Standorte</div>
          </div>
          <CreateSiteWizard />
        </div>
        <div className="p-20px rounded-5px box-shadow-blue w-full bg-white text-center">
          <div className="flex-c-c gap-5px mb-20px">
            <ShieldIcon />
            <div className="text-16px bold">Berechtigungen</div>
          </div>
          <UpdatePermissionsWizard />
        </div>
      </div>
      {chips.length > 0 ? (
        <>
          <div className="mt-40px h-1px bg-grey200 w-full" />
          <div className="mt-40px">Gehe zu bestehenden:</div>
          <div className="flex-s-c gap-10px mt-10px">
            {chips.map((chip) => (
              <Chip
                key={chip.label}
                label={chip.label}
                icon={<ChevronRightIcon />}
                onMouseDown={() => openSettingsPage(chip)}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}, 'Seite konnte nicht geladen werden.');
