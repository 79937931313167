import { type PropsWithChildren } from 'react';

import { cn } from '~/utils/tailwind';

type P = PropsWithChildren & ComponentStyling;

export const DataManagementLayout = ({ children, className, style }: P) => (
  <div className={cn('mx-4 flex flex-col gap-4', className)} style={style}>
    {children}
  </div>
);
