import React, { memo } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Button, Grid, InputLabel } from '@mui/material';

import { PERMISSION } from '~/constants/PermissionConsts';

import PermissionGrant from '~/models/masterdata/PermissionGrant';
import Site from '~/models/masterdata/Site';

import BasicForm from '~/components/BasicForm';
import DatePicker from '~/components/baseComponents/inputs/date/DatePicker';
import Select from '~/components/baseComponents/inputs/select/Select';

import { LightTooltip } from '~/utils/componentUtils';

import { MultiItemsManagerOrganizationalUnits } from '~/ui/molecules/MultiItemsManager';
import {
  MultiSelectCostCenters,
  SelectCompany,
} from '~/ui/molecules/SelectServerDriven';

import { CostCenterForm } from '../../costCenter/CostCenterForm';
import { OrganizationalUnitPaths } from '../../paths/OrganizationalUnitPaths';
import { PermissionGrantEntityTable } from '../../permissionGrant/PermissionsTable';

import { SiteIdentifiersTable } from '../SiteIdentifiersTable';

import { SiteSection } from './components';
import { useSiteForm } from './useSiteForm';

/**
 * Compare props that should cause memo to rerender UserForm.
 */
const propsAreEqual = (prevProps, nextProps) =>
  prevProps.open === nextProps.open &&
  prevProps.type === nextProps.type &&
  prevProps.siteId === nextProps.siteId;

export const SiteForm = memo(
  ({
    closeForm,
    onOpenOrganisationalGroup,
    onUpdatedOrganisationalGroupsChange,
    open,
    siteId,
    type,
  }) => {
    const {
      deactivateCoupledCostCentersFormOpen,
      handleCancel,
      handleChangeCompany,
      handleChangeCostCenters,
      handleChangeDate,
      handleChangeOrganisationalGroups,
      handleChangeSiteType,
      handleCheckboxChange,
      handleCloseCostCenterForm,
      handleInputChange,
      handleOpenCostCenterForm,
      handleSubmit,
      isCreatingSite,
      isLoading,
      isOpenCostCenterForm,
      isSubmitting,
      refetchSite,
      setCostCenter,
      setDeactivateCoupledCostCenters,
      setDeactivateCoupledCostCentersFormOpen,
      setSyncCoupledPermissionGrants,
      setSyncCoupledPermissionGrantsFormOpen,
      site,
      syncCoupledPermissionGrantsFormOpen,
      unsavedChanges,
      userPermissions,
    } = useSiteForm({
      closeForm,
      siteId,
      type,
    });

    const shouldRenderGroupPaths =
      site?.parentOrganizationalUnitPaths?.length > 0;

    return (
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleCancel}
        title={`Standort ${isCreatingSite ? 'erstellen' : site?.name}`}
        fullWidth
        disableSubmitButton={isLoading}
        submittingForm={isSubmitting}
        id={site?.id}
        unsavedChanges={unsavedChanges}
      >
        <Grid container direction="row" spacing={3}>
          {shouldRenderGroupPaths ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <OrganizationalUnitPaths
                    id={site?.id}
                    organizationalUnitPaths={
                      site?.parentOrganizationalUnitPaths
                    }
                    onOpenOrganizationalUnit={onOpenOrganisationalGroup}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <SiteSection
            site={site}
            handleCheckboxChange={handleCheckboxChange}
            handleInputChange={handleInputChange}
            isCreatingSite={isCreatingSite}
          />
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel>Startdatum</InputLabel>
                <DatePicker
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  value={site?.start}
                  maxDate={site?.end}
                  onChange={(value) => handleChangeDate(value, 'start')}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <InputLabel>Enddatum</InputLabel>
                <DatePicker
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  value={site?.end}
                  minDate={site?.start}
                  onChange={(value) => handleChangeDate(value, 'end')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Art</InputLabel>
                <Select
                  value={site?.type}
                  fullWidth
                  onChange={handleChangeSiteType}
                  size="small"
                  options={Site?.getSiteTypes()}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Firma</InputLabel>
                <SelectCompany
                  value={site?.companyId}
                  onChange={handleChangeCompany}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8} className="flex-sb-e">
                <MultiSelectCostCenters
                  label="Kostenstellen"
                  value={site?.costCenters ?? []}
                  onChange={handleChangeCostCenters}
                  className="mt-auto w-full"
                  fullWidth
                />
                <LightTooltip title="Neue Kostenstelle erstellen">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="ml-10px"
                    onClick={handleOpenCostCenterForm}
                    startIcon={<AddIcon />}
                  >
                    Erstellen
                  </Button>
                </LightTooltip>
                <CostCenterForm
                  open={isOpenCostCenterForm}
                  closeForm={handleCloseCostCenterForm}
                  setCostCenter={setCostCenter}
                  type="create"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Ist Teil von...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <MultiItemsManagerOrganizationalUnits
                  isCreatingNewEntity={isCreatingSite}
                  filterByEntity={{
                    site: siteId,
                  }}
                  onSaveSelection={handleChangeOrganisationalGroups}
                  onSelectedItemClick={(organisationalGroup) =>
                    onOpenOrganisationalGroup(
                      organisationalGroup,
                      unsavedChanges,
                    )
                  }
                  onChange={onUpdatedOrganisationalGroupsChange}
                />
              </Grid>
            </Grid>
          </Grid>
          {!isCreatingSite &&
          userPermissions.includes(PERMISSION.MODIFY_SITE_REFERENCES) ? (
            <SiteIdentifiersTable siteId={site?.id} className="ml-3 mt-4" />
          ) : null}
          {isCreatingSite ? null : (
            <Grid item xs={12}>
              <PermissionGrantEntityTable
                title="Wer ist auf diesen Standort berechtigt?"
                permissionGrantsFrom={site?.permissionGrantsFrom}
                defaultEntities={[site?.id]}
                defaultEntityType={PermissionGrant.ENTITY_TYPE.SITE?.KEY}
                fixedPicker={PermissionGrant.TYPE.ENTITY}
                refreshData={refetchSite}
                loading={isLoading}
              />
            </Grid>
          )}
        </Grid>
        {syncCoupledPermissionGrantsFormOpen && (
          <BasicForm
            open={syncCoupledPermissionGrantsFormOpen}
            formSuccess={() => setSyncCoupledPermissionGrants(true)}
            formAbort={() => setSyncCoupledPermissionGrantsFormOpen(false)}
            title="Berechtigungen in Kostenstellen übertragen"
            submitButtonTitle="Berechtigungen übertragen"
          >
            {/* Add your form body here based on the current state */}
            {/* What the flying fuck. Did anything get lost here?! */}
          </BasicForm>
        )}
        {deactivateCoupledCostCentersFormOpen && (
          <BasicForm
            open={deactivateCoupledCostCentersFormOpen}
            formSuccess={() => setDeactivateCoupledCostCenters(true)}
            formAbort={() => setDeactivateCoupledCostCentersFormOpen(false)}
            title={
              'Zugewiesene Kostenstellen ' +
              (site?.isActive ? 'aktivieren' : 'deaktivieren')
            }
            submitButtonTitle={
              'Kostenstellen ' +
              (site?.isActive ? 'aktivieren' : 'deaktivieren')
            }
          >
            {/* Add your form body here based on the current state */}
            {/* What the flying fuck. Did anything get lost here?! */}
          </BasicForm>
        )}
      </BasicForm>
    );
  },
  propsAreEqual,
);
