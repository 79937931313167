import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useMutationUpdateOrganizationalUnits } from '~/data/organizationalUnit';
import { useMutationUpdatePushNotificationSettings } from '~/data/pushNotification';
import {
  queryKeysUser,
  useMutationCreateUser,
  useMutationUpdateUser,
  useMutationUpdateUserSettings,
  useMutationUpdateUserSignatureRoles,
  useQueryUserData,
  useQueryUserFull,
} from '~/data/user';

import ToastService from '~/services/toast.service';

import User from '~/models/masterdata/User';

import { useDelayedValue } from '~/hooks/useDelayedValue';

import Log from '~/utils/Log';

import {
  getDefaultSignatureRoleOptions,
  handleSubmit as onSubmit,
  onChange,
} from './utils';

export const useUserForm = ({
  closeForm,
  onOpenOrganisationalGroup,
  type,
  userCompanyId,
  userId,
}) => {
  const isCreatingUser = type === 'create';

  const queryClient = useQueryClient();

  const {
    data: currentUser,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
    isSuccess: isSuccessUserData,
  } = useQueryUserData(true);

  const companyInfo = currentUser?.companyInfo ?? {};

  // Try to get some basic data from the list item cache to improve the rendering experience.
  const placeholderData = useMemo(() => {
    const placeholderDataArray = [];

    const queriesData = queryClient.getQueriesData({
      queryKey: queryKeysUser.getAll().filter(Boolean),
    });

    for (const [, data] of queriesData) {
      if (Array.isArray(data?.data)) {
        const listItem = data?.data?.find(({ id }) => id === userId);

        if (listItem) {
          const { companyId, email, firstName, id, isActive, lastName } =
            listItem;

          placeholderDataArray[0] = new User({
            companyId,
            email,
            firstName,
            id,
            isActive,
            lastName,
          });

          break;
        }
      }
    }

    return placeholderDataArray;
  }, [queryClient, userId]);

  const {
    data: user,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchUser,
  } = useQueryUserFull(userId, userCompanyId, placeholderData);

  const { mutateAsync: createUserMutation, isPending: isPendingCreateUser } =
    useMutationCreateUser({
      onError(error) {
        Log.error('createUserMutation error');
      },
    });
  const { mutateAsync: updateUserMutation, isPending: isPendingUpdateUser } =
    useMutationUpdateUser({
      onError(error) {
        Log.error('updateUserMutation error');
      },
    });
  const {
    mutateAsync: updateUserSettingsMutation,
    isPending: isPendingUpdateUserSettings,
  } = useMutationUpdateUserSettings({
    onError(error) {
      Log.error('updateUserSettingsMutation error');
    },
  });
  const {
    mutateAsync: updateUserSignatureRolesMutation,
    isPending: isPendingUpdateUserSignatureRoles,
  } = useMutationUpdateUserSignatureRoles({
    onError(error) {
      Log.error('useMutationUpdateUserSignatureRoles error');
    },
  });
  const {
    mutateAsync: updatePushNotificationSettingsMutation,
    isPending: isPendingUpdatePushNotificationSettings,
  } = useMutationUpdatePushNotificationSettings({
    onError(error) {
      Log.error('updatePushNotificationSettingsMutation error');
    },
  });

  const {
    mutateAsync: updateOrganizationalUnitsMutation,
    isPending: isPendingUpdateOrganizationalUnits,
  } = useMutationUpdateOrganizationalUnits({
    onError(error) {
      Log.error('updateOrganizationalUnitsMutation error');

      ToastService.httpError(
        ['Organisations-Gruppen konnten nicht geändert werden.'],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to update organisational groups',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
  });

  const isSubmitting =
    isPendingCreateUser ||
    isPendingUpdateOrganizationalUnits ||
    isPendingUpdatePushNotificationSettings ||
    isPendingUpdateUser ||
    isPendingUpdateUserSettings ||
    isPendingUpdateUserSignatureRoles;

  const refetchUsers = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: queryKeysUser.getAll({}),
    });
  }, [queryClient]);

  const getDefaultUser = () => {
    const user = new User();

    user.companyId = companyInfo.id;

    return user;
  };

  const [state, setState] = useState({
    user: isCreatingUser ? getDefaultUser() : user,
  });

  const handleUpdateUser = (newUser) => {
    setState((previousState) => ({
      ...previousState,
      user: newUser,
    }));
  };

  const handleChangeOrganisationalGroups = ({ added, removed }, setState) => {
    setState((previousState) => {
      const newUser = cloneDeep(previousState.user);

      const removedIds = new Set(removed.map(({ id }) => id));
      const addedIds = new Set(added.map(({ id }) => id));

      newUser.organisationalGroups = [
        ...new Set([
          ...newUser.organisationalGroups.filter((id) => !removedIds.has(id)),
          ...addedIds,
        ]),
      ];

      Log.info(
        'Change form value of organisational groups',
        {
          from: previousState.user.organisationalGroups,
          to: newUser.organisationalGroups,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change organisational groups',
        Log.FEATURE.USER,
      );

      return {
        ...previousState,
        user: newUser,
      };
    });
  };

  const handleChange = (event) => {
    onChange(event, state.user, handleUpdateUser);
  };

  const handleOpenOrganisationalGroup = (organisationalGroup) =>
    onOpenOrganisationalGroup(organisationalGroup, unsavedChanges);

  const handleChangeCompany = (companyId) => {
    setState((previousState) => {
      const newUser = cloneDeep(previousState.user);

      newUser.companyId = companyId;

      Log.info(
        'Change form value of companyId',
        {
          from: state.user.companyId,
          to: newUser.companyId,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Change company', Log.FEATURE.USER);

      return {
        ...previousState,
        user: newUser,
      };
    });
  };

  const handleChangeDefaultSignatureRole = (event) => {
    setState((previousState) => {
      const newUser = cloneDeep(previousState.user);
      newUser.signatureRoles.defaultSignatureRole = event.target.value;

      Log.info(
        'Change form value of default signature role',
        {
          from: state.user.signatureRoles.defaultSignatureRole,
          to: newUser.signatureRoles.defaultSignatureRole,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change default signature role',
        Log.FEATURE.USER,
      );

      return {
        ...previousState,
        user: newUser,
      };
    });
  };

  const resetForm = useCallback(() => {
    const newUser = isCreatingUser
      ? getDefaultUser()
      : user
        ? cloneDeep(user)
        : null;

    if (newUser) {
      setState((previousState) => ({
        ...previousState,
        user: newUser,
      }));
    }
  }, [user, isCreatingUser]);

  const resetDefaultValues = useCallback(() => {
    setState((previousState) => {
      // Don't modify state for new users or if user object is empty/invalid.
      if (isCreatingUser || !previousState.user?.companyId) {
        return previousState;
      }

      const newUser = cloneDeep(previousState.user);

      newUser.companyId ||= companyInfo.id;

      return {
        ...previousState,
        user: newUser,
      };
    });
  }, [isCreatingUser, companyInfo.id]);

  const resetDefaultSignatureRole = useCallback(() => {
    setState((previousState) => {
      // Don't modify state for new users or if user object is empty/invalid.
      if (isCreatingUser || !previousState.user?.signatureRoles) {
        return previousState;
      }

      const newUser = cloneDeep(previousState.user);
      if (newUser.signatureRoles.noSignatureRoleIsSelected?.()) {
        newUser.signatureRoles.defaultSignatureRole = null;
      } else if (
        !getDefaultSignatureRoleOptions(newUser.signatureRoles).some(
          ({ id }) => id === newUser.signatureRoles.defaultSignatureRole,
        )
      ) {
        newUser.signatureRoles.defaultSignatureRole =
          getDefaultSignatureRoleOptions(newUser.signatureRoles)[0]?.id ?? null;
      }

      return {
        ...previousState,
        user: newUser,
      };
    });
  }, [isCreatingUser]);

  useEffect(() => {
    resetForm();
  }, [isSuccess, isError, JSON.stringify(user)]);

  useEffect(() => {
    resetDefaultValues();
  }, [isError, isSuccess]);

  useEffect(() => {
    resetDefaultSignatureRole();
  }, [JSON.stringify(state.user?.signatureRoles), resetDefaultSignatureRole]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      onSubmit({
        closeForm,
        createUserMutation,
        event,
        isCreatingUser,
        originalData: user,
        queryClient,
        refetchUser,
        refetchUsers,
        resetForm,
        setState,
        updateOrganizationalUnitsMutation,
        updatePushNotificationSettingsMutation,
        updateUserMutation,
        updateUserSettingsMutation,
        updateUserSignatureRolesMutation,
        user: state.user,
      });
    },
    [
      closeForm,
      createUserMutation,
      isCreatingUser,
      queryClient,
      state.user,
      updateOrganizationalUnitsMutation,
      updatePushNotificationSettingsMutation,
      updateUserMutation,
      updateUserSettingsMutation,
      updateUserSignatureRolesMutation,
      user,
    ],
  );

  const handleCancel = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.USER);

    closeForm();
    resetForm();
  };

  const unsavedChanges = useMemo(() => {
    if (isCreatingUser) {
      return [];
    }

    if (!isSuccess || !user || !state.user) {
      return [];
    }

    return User.getDifferentValues(user, state.user);
  }, [
    JSON.stringify(user),
    JSON.stringify(state.user),
    isCreatingUser,
    isSuccess,
  ]);

  const delayedUnsavedChanges = useDelayedValue(unsavedChanges);

  return {
    getDefaultSignatureRoleOptions,
    handleCancel,
    handleChange,
    handleChangeCompany,
    handleChangeDefaultSignatureRole,
    handleChangeOrganisationalGroups: ({ added, removed }) =>
      handleChangeOrganisationalGroups({ added, removed }, setState),
    handleOpenOrganisationalGroup,
    handleSubmit,
    handleUpdateUser,
    isCreatingUser,
    isError,
    isLoading,
    isSubmitting,
    isSuccess,
    refetchUser,
    unsavedChanges: delayedUnsavedChanges,
    user: state.user,
  };
};
