import CustomerSubscription from '~/models/masterdata/CustomerSubscription';

export const selectCustomerSubscription = (data) => ({
  customerSubscriptions:
    data.customerSubscriptions.map(
      (customerSubscription) => new CustomerSubscription(customerSubscription),
    ) ?? [],
  rows:
    data.customerSubscriptions.map((customerSubscription) => ({
      ...customerSubscription,
      isActive: customerSubscription.isActive
        ? CustomerSubscription.IS_ACTIVE.YES
        : CustomerSubscription.IS_ACTIVE.NO,
    })) ?? [],
  totalCount: data.count ?? 0,
});
