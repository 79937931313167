import { type SignatureFieldsQueryParams } from './useQuerySignatureFields';

/**
 * Query key factory for customFields-related queries.
 */
export const queryKeysSignatureFields = {
  base: () => 'signatures' as const,
  getAll: (queryParams: Partial<SignatureFieldsQueryParams>) =>
    [queryKeysSignatureFields.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<SignatureFieldsQueryParams>) =>
    [queryKeysSignatureFields.base(), 'list', 'infinite', queryParams] as const,
} as const;
