import Article from '~/models/articleMaster/Article';
import Category from '~/models/articleMaster/Category';

export const selectCategories = (data, companies, sites, articles) => ({
  categories: data.items.map((category) => new Category(category)) ?? [],
  rows:
    data.items.map((category) => ({
      ...category,
      access: Article.getAccessString(category.access),
      filterCompanies: category.filterCompanies
        .map(
          (filterCompanyId) =>
            companies.find((company) => company.id === filterCompanyId)?.name ??
            '',
        )
        .join(', '),
      filterSites: category.filterSites
        .map(
          (filterSiteId) =>
            sites.find((site) => site.id === filterSiteId)?.name ?? '',
        )
        .join(', '),
      isActive: category.isActive
        ? Category.IS_ACTIVE.YES
        : Category.IS_ACTIVE.NO,
      linkedArticles: category.linkedArticles
        .map((linkedArticleId) => {
          const article = articles.find(
            (article) => article.id === linkedArticleId,
          );
          if (!article) {
            return '';
          }

          return article.articleId + ' ' + article.name;
        })
        .join(', '),
      ownerCompanies: category.ownerCompanies
        .map(
          (ownerCompanyId) =>
            companies.find((company) => company.id === ownerCompanyId)?.name ??
            '',
        )
        .join(', '),
      parentCategories: category.parentCategories
        .map(
          (parentCategoryId) =>
            data.items.find((category) => category.id === parentCategoryId)
              ?.name ?? '',
        )
        .join(', '),
      subCategories: category.subCategories
        .map(
          (subCategoryId) =>
            data.items.find((category) => category.id === subCategoryId)
              ?.name ?? '',
        )
        .join(', '),
    })) ?? [],
  totalCount: data.count ?? 0,
});
