export const SitesSettingsTestIds = {
  MODAL_FIELDS: {
    BILLING_NUMBER: 'Sites-settings-billing-number',
    CITY: 'Sites-settings-city',
    LATITUDE: 'Sites-settings-latitude',
    LONGITUDE: 'Sites-settings-longitude',
    NAME: 'Sites-settings-name',
    POST_CODE: 'Sites-settings-post-code',
    STREET_NAME: 'Sites-settings-street-name',
  },
} as const;
