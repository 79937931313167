import { createSlice } from '@reduxjs/toolkit';

const devToolsSlice = createSlice({
  initialState: {
    jsonModal: {
      json: null,
      open: false,
    },
  },
  name: 'devTools',
  reducers: {
    closeJsonModal(state, parameter) {
      state.jsonModal.open = false;
      state.jsonModal.json = null;
    },
    openJsonModal(state, parameter) {
      const { payload } = parameter;

      state.jsonModal.open = true;
      state.jsonModal.json = payload;
    },
  },
});

const { actions, reducer } = devToolsSlice;
export const { closeJsonModal, openJsonModal } = actions;
export default reducer;
