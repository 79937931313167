import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { queryKeysSite, useQuerySite } from '~/data/site';

import SiteService from '~/services/site.service';
import ToastService from '~/services/toast.service';

import Address from '~/models/masterdata/Address';
import Site from '~/models/masterdata/Site';

import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';

export const useSiteIdentifiers = (siteId) => {
  const queryClient = useQueryClient();

  const { data: site, isLoading } = useQuerySite(siteId, {
    select(data) {
      const site = new Site(data);

      return {
        ...site,
        references: site.references.map((siteReference) => {
          return {
            ...siteReference,
            address: siteReference.ignoreAddressInfo
              ? new Address()
              : siteReference.address,
            addressString: siteReference.ignoreAddressInfo
              ? ''
              : siteReference.addressString,
            id: siteReference.identifierId,
          };
        }),
      };
    },
  });

  const blacklistedSites =
    site?.references?.filter(({ isBlacklisted }) => isBlacklisted) ?? [];
  const whitelistedSites =
    site?.references?.filter(({ isBlacklisted }) => !isBlacklisted) ?? [];

  const [isInProgress, setIsInProgress] = useState(false);
  const [selected, setSelected] = useState({
    blacklistedSites: [],
    whitelistedSites: [],
  });

  const handleSelect = (event, listType) => {
    Log.info(
      'Change selection value of selected site references',
      {
        from: selected,
        to: event,
      },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select site reference',
      Log.FEATURE.SITE_MAPPING,
    );

    setSelected((previous) => ({
      ...previous,
      [listType]: event,
    }));
  };

  const performActionOnSites = async (sitesType, action) => {
    setIsInProgress(true);

    const promises = selected[sitesType].map((identifierId) =>
      action(identifierId),
    );

    const actionVerb =
      sitesType === 'whitelistedSites'
        ? 'von der Zuordnung ausgeschlossen'
        : 'zugeordnet';
    try {
      await PromiseUtils.allResolved(promises);

      SiteService.refreshSites();
      SiteService.refreshAssignedSiteReferences();
      // TODO: refresh site data properly in mutation
      queryClient.invalidateQueries({
        queryKey: queryKeysSite.get(siteId),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeysSite.getAll({}),
      });

      const message =
        promises.length > 1
          ? `Standortbezeichnungen ${actionVerb}`
          : `Standortbezeichnung ${actionVerb}`;

      ToastService.success([message]);
    } catch {
      const errorMessage =
        promises.length > 1
          ? `Standortbezeichnungen konnten nicht ${actionVerb} werden`
          : `Standortbezeichnung konnte nicht ${actionVerb} werden`;

      ToastService.error([errorMessage]);
    } finally {
      setIsInProgress(false);
    }
  };

  const handleBlacklistSites = () => {
    performActionOnSites(
      'whitelistedSites',
      (identifierId) =>
        SiteService.blacklistSiteIdentifier(siteId, identifierId, true), // TODO VGS-7004: use mutation, refetch site data
    );
  };

  const handleWhitelistSites = () => {
    performActionOnSites(
      'blacklistedSites',
      (identifierId) =>
        SiteService.blacklistSiteIdentifier(siteId, identifierId, false), // TODO VGS-7004: use mutation, refetch site data
    );
  };

  const handleDeleteMappings = async (sitesType) => {
    if (!['blacklistedSites', 'whitelistedSites'].includes(sitesType)) {
      console.error(`"${sitesType}" is not a valid sites type`);
      return;
    }

    setIsInProgress(true);

    Log.info(
      'Delete site references',
      {
        from: sitesType,
        selected: selected[sitesType],
      },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Delete site references',
      Log.FEATURE.SITE_MAPPING,
    );

    const promises = selected[sitesType].map((id) =>
      SiteService.deleteSiteReference(siteId, id) // TODO VGS-7004: use mutation, refetch site data
        .then(() => {
          SiteService.refreshSites();
          SiteService.refreshAssignedSiteReferences();
          // TODO: refresh site data properly in mutation
          queryClient.invalidateQueries({
            queryKey: queryKeysSite.get(siteId),
          });
          queryClient.invalidateQueries({
            queryKey: queryKeysSite.getAll({}),
          });
        })
        .catch((error) => {
          ToastService.httpError(
            [ToastService.MESSAGE.SITE_REFERENCE_DELETION_FAILED],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to delete site references',
            Log.FEATURE.SITE,
            Log.TYPE.ERROR,
          );
        }),
    );

    try {
      await PromiseUtils.allResolved(promises);
      ToastService.success(['Standortbezeichnungen wurden gelöscht.']);
    } catch (error) {
      ToastService.error([
        'Standortbezeichnungen konnten nicht vollständig gelöscht werden.',
      ]);

      Log.error('Failed to delete site references.', error);
      Log.productAnalyticsEvent(
        'Failed to delete site references',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
    } finally {
      setIsInProgress(false);
    }
  };

  return {
    blacklistedSites,
    handleBlacklistSites,
    handleDeleteMappings,
    handleSelect,
    handleWhitelistSites,
    isInProgress,
    isLoading,
    selected,
    whitelistedSites,
  };
};
