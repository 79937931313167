import { useMemo } from 'react';

import PermissionGrant, {
  ENTITY_TYPES,
} from '~/models/masterdata/PermissionGrant';

import UserUtils from '~/utils/userUtils';

import { PermissionGrantTableBase } from './PermissionGrantTableBase';

export const PermissionGrantSubjectTable = ({
  defaultSubjects,
  defaultSubjectType,
  fixedPicker,
  isError,
  isLoading,
  permissionGrantsOn,
  refreshData,
  title,
}) => {
  const rows = useMemo(
    () =>
      permissionGrantsOn.map(
        ({ entityId, entityName, entityType, id, permissions }) => ({
          defaultRole: permissions.getDefaultRoleName(),
          entityId,
          entityName,
          entityType: ENTITY_TYPES[entityType],
          id,
          permissions: {
            permissionGrantId: id,
            permissions,
          },
        }),
      ),
    [permissionGrantsOn],
  );

  return (
    <PermissionGrantTableBase
      columnVisibilityModel={{ entityId: UserUtils.isVestigasAccount() }}
      defaultSubjects={defaultSubjects}
      defaultSubjectType={defaultSubjectType}
      fixedPicker={fixedPicker}
      isError={isError}
      isLoading={isLoading}
      permissionGrants={permissionGrantsOn}
      refreshData={refreshData}
      rows={rows}
      title={title}
      type={PermissionGrant.TYPE.SUBJECT}
    />
  );
};

PermissionGrantSubjectTable.displayName = 'PermissionGrantSubjectTable';
