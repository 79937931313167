import {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';
import { promiseHandler } from '~/utils/promiseHandler';

import { withErrorBoundary } from '~/ui/atoms';

import PermissionGrantPickerItem from './PermissionGrantPickerItem';

export const PermissionGrantPicker = withErrorBoundary(
  forwardRef(
    (
      {
        defaultEntities,
        defaultEntityType,
        defaultSubjects,
        defaultSubjectType,
        fixedPicker,
      },
      ref,
    ) => {
      const [pickerList, setPickerList] = useState([]);
      const childsRef = useRef([]);

      useEffect(() => {
        handleAddPicker();
      }, []);

      const submit = async () => {
        const promises = childsRef.current.map((child) => {
          return child.current && typeof child.current.submit === 'function'
            ? child.current.submit()
            : Promise.resolve();
        });

        const [, error] = await promiseHandler(
          PromiseUtils.allResolved(promises),
        );

        if (error) {
          ToastService.error([
            'Berechtigungen konnten nicht vollständig vergeben werden.',
          ]);
          Log.error('Failed to grant permissions.', error);
          Log.productAnalyticsEvent(
            'Failed to grant permissions.',
            Log.FEATURE.PERMISSIONS,
            Log.TYPE.ERROR,
          );
          return;
        }

        ToastService.success(['Berechtigungen wurden vergeben.']);
      };

      useImperativeHandle(
        ref,
        () => ({
          async submit() {
            const promises = childsRef.current.map((child) => {
              return child.current && typeof child.current.submit === 'function'
                ? child.current.submit()
                : Promise.resolve();
            });

            const [, error] = await promiseHandler(
              PromiseUtils.allResolved(promises),
            );

            if (error) {
              ToastService.error([
                'Berechtigungen konnten nicht vollständig vergeben werden.',
              ]);
              Log.error('Failed to grant permissions.', error);
              Log.productAnalyticsEvent(
                'Failed to grant permissions.',
                Log.FEATURE.PERMISSIONS,
                Log.TYPE.ERROR,
              );
              return false;
            }

            ToastService.success(['Berechtigungen wurden vergeben.']);
            return true;
          },
        }),
        [],
      );

      const handleAddPicker = () => {
        const newRef = createRef();
        childsRef.current.push(newRef);

        setPickerList((previousList) => {
          const newPickerList = [
            ...previousList,
            {
              key: previousList.length,
              ref: newRef,
            },
          ];

          Log.info(
            'Add permissions picker',
            {
              values: newPickerList,
            },
            Log.BREADCRUMB.USER_ACTION.KEY,
          );

          if (previousList.length > 0) {
            // Ignore the initial picker and only track the ones actually added by user.
            Log.productAnalyticsEvent('Add picker', Log.FEATURE.PERMISSIONS);
          }

          return newPickerList;
        });
      };

      return (
        <div className="flex flex-col gap-4">
          {pickerList.map((picker) => (
            <PermissionGrantPickerItem
              key={picker.key}
              ref={picker.ref}
              fixedPicker={fixedPicker}
              defaultSubjects={defaultSubjects}
              defaultSubjectType={defaultSubjectType}
              defaultEntityType={defaultEntityType}
              defaultEntities={defaultEntities}
            />
          ))}
          <Button
            variant="outlined"
            onClick={handleAddPicker}
            startIcon={<AddIcon />}
            className="mt-4"
          >
            Weitere Berechtigung hinzufügen
          </Button>
        </div>
      );
    },
  ),
  'Daten konnten nicht geladen werden.',
);

PermissionGrantPicker.displayName = 'PermissionGrantPicker';
