import { Grid, InputLabel, TextField } from '@mui/material';

import Address from '~/models/masterdata/Address';
import PermissionGrant from '~/models/masterdata/PermissionGrant';

import UserUtils from '~/utils/userUtils';

import SearchableSelect from '~/components/baseComponents/inputs/select/SearchableSelect';
import ImageUpload from '~/components/ImageUpload';
import BasicForm from '~/components/BasicForm';

import { MultiItemsManagerOrganizationalUnits } from '~/ui/molecules/MultiItemsManager';

import { OrganizationalUnitPaths } from '../paths/OrganizationalUnitPaths';
import { PermissionGrantEntityTable } from '../permissionGrant/PermissionsTable';

import { useCompanyForm } from './useCompanyForm';

export const CompanyForm = ({
  closeForm,
  companyId,
  onOpenOrganisationalGroup,
  onUpdatedOrganisationalGroupsChange,
  open,
  type,
}) => {
  const {
    company,
    companyLogo,
    handleCancel,
    handleChangeOrganisationalGroups,
    handleInputChange,
    handleSubmit,
    isCreatingCompany,
    isLoadingCompany,
    isSubmitting,
    refetchCompany,
    setCompanyLogo,
    unsavedChanges,
  } = useCompanyForm({
    closeForm,
    companyId,
    type,
  });

  const canSubmit = false; // Only Vestigas admin users can create or update companies and only in the admin portal.
  // UserUtils.isVestigasAccount() ||
  // (isCreatingCompany
  //   ? UserUtils.isCompanyCreateAllowedUser()
  //   : UserUtils.isCompanyWriteAllowedUser());

  const shouldRenderGroupPaths =
    company?.parentOrganizationalUnitPaths?.length > 0;

  return (
    <BasicForm
      open={open}
      formSuccess={handleSubmit}
      formAbort={handleCancel}
      title={`Firma ${isCreatingCompany ? 'erstellen' : company?.name}`}
      submittingForm={isSubmitting}
      id={company?.id}
      unsavedChanges={unsavedChanges}
      missingPermissionsToSubmit={!canSubmit}
      fullWidth
    >
      <Grid container direction="row">
        {shouldRenderGroupPaths && (
          <Grid item xs={12}>
            <div className="mb-4">
              <OrganizationalUnitPaths
                id={company?.id}
                organizationalUnitPaths={company.parentOrganizationalUnitPaths}
                onOpenOrganizationalUnit={(organizationalUnit) =>
                  onOpenOrganisationalGroup(organizationalUnit, unsavedChanges)
                }
              />
            </div>
          </Grid>
        )}
        <Grid item xs={12} lg={8}>
          <Grid container direction="row" spacing={3} space={4}>
            <Grid item xs={12}>
              <h3 className="main-text mt-0">Firma</h3>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    name="name"
                    label="Name"
                    type="text"
                    value={company?.name}
                    onChange={handleInputChange}
                    autoComplete="off"
                    fullWidth
                    required
                    autoFocus
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h3 className="main-text mt-4">Details</h3>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    name="streetName"
                    label="Straße"
                    type="text"
                    value={company?.address?.streetName}
                    onChange={handleInputChange}
                    autoComplete="off"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="buildingNumber"
                    label="Hausnummer"
                    type="text"
                    value={company?.address?.buildingNumber}
                    onChange={handleInputChange}
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    name="postCode"
                    label="PLZ"
                    type="text"
                    value={company?.address?.postCode}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="city"
                    label="Ort"
                    type="text"
                    value={company?.address?.city}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel className="text-13px">Land</InputLabel>
                  <SearchableSelect
                    name="country"
                    value={company?.address?.country}
                    onChange={handleInputChange}
                    size="small"
                    options={Address.getCountryCodeOptions()}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} className="mt-12 flex flex-col">
          <InputLabel className="text-13px">Firmenlogo</InputLabel>
          <ImageUpload
            image={companyLogo}
            setImage={setCompanyLogo}
            onDelete={() => setCompanyLogo(null)}
            uploadText="Füge hier das Firmenlogo hinzu."
            missingPermissionsToWrite={!UserUtils.isCompanyWriteAllowedUser()}
          />
        </Grid>
        <Grid item xs={12}>
          <h3 className="main-text mt-4">Ist Teil von...</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerOrganizationalUnits
                isCreatingNewEntity={isCreatingCompany}
                filterByEntity={{
                  company: companyId,
                }}
                onSaveSelection={handleChangeOrganisationalGroups}
                onSelectedItemClick={(organisationalGroup) =>
                  onOpenOrganisationalGroup(organisationalGroup, unsavedChanges)
                }
                onChange={onUpdatedOrganisationalGroupsChange}
              />
            </Grid>
          </Grid>
        </Grid>

        {!isCreatingCompany && (
          <Grid item xs={12}>
            <div className="h-4" />
            <PermissionGrantEntityTable
              title="Wer ist auf diese Firma berechtigt?"
              permissionGrantsFrom={company?.permissionGrantsFrom ?? []}
              defaultEntities={[company?.id]}
              defaultEntityType={PermissionGrant.ENTITY_TYPE.COMPANY.KEY}
              fixedPicker={PermissionGrant.TYPE.ENTITY}
              refreshData={refetchCompany}
              loading={isLoadingCompany}
            />
          </Grid>
        )}
      </Grid>
    </BasicForm>
  );
};
