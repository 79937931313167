import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysCostCenter } from './queryKeys';

type CostCenterData = Record<string, unknown>;

type MutationCostCenterArguments = {
  costCenterId: UUID;
  costCenterData: CostCenterData;
};

/**
 * Updates a given cost center.
 *
 * @param {string} costCenterId - The ID of the cost center to update.
 * @param {Object} costCenterData - An object containing the cost center data to update.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Accounting-Reference/operation/update_accounting_reference_accounting_reference__acc_ref_uuid__put
 */
export const updateCostCenterData = async (
  costCenterId: UUID,
  costCenterData: CostCenterData,
) => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.COST_CENTER.UPDATE(costCenterId), {
        json: costCenterData,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating cost center settings', error);
    throw error;
  }
};

/**
 * Custom hook for updating a given cost center.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */

export const useMutationUpdateCostCenter = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      costCenterId,
      costCenterData,
    }: MutationCostCenterArguments) =>
      updateCostCenterData(costCenterId, costCenterData),
    async onError(_error) {
      Log.productAnalyticsEvent(
        'Failed to update cost center',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(_data, { costCenterId }) {
      queryClient.invalidateQueries({
        queryKey: queryKeysCostCenter.get(costCenterId),
      });
    },
  });
};
