import BilledItem from '~/models/billingState/BilledItem';

import { parseDate } from '~/utils/dateUtils';
import ObjectUtils from '~/utils/objectUtils';

const generateDeliveryNoteSearchString = (
  row,
  deliveryNote,
  deliveryNoteArticles,
) => [
  row.id,
  row.number,
  deliveryNote.processState,
  deliveryNote.acceptState,
  row.category,
  row.toSiteRecipient,
  row.toSiteSupplier,
  row.toSiteSupplierAddress,
  row.supplier,
  row.carrier,
  row.carrierId,
  row.recipient,
  row.trader,
  row.buyer,
  row.seller,
  row.costCenters,
  row.description,
  row.comments,
  row.fromSite,
  row.fromSiteIssuerAssignedId,
  row.deliveryType,
  row.movementMeans,
  row.project,
  row.licensePlate,
  row.sellerOrderReferences,
  row.buyerOrderReferences,
  row.constructionPlans,
  row.constructionComponents,
  deliveryNoteArticles.map(({ type }) => type).join(';'),
  deliveryNote.supplier.universalCommunication.email,
  deliveryNote.supplier.universalCommunication.phone,
  deliveryNote.carrier.universalCommunication.email,
  deliveryNote.carrier.universalCommunication.phone,
  deliveryNote.recipient.universalCommunication.email,
  deliveryNote.recipient.universalCommunication.phone,
  deliveryNote.customData.getValues().join(';'),
  deliveryNoteArticles
    .map(({ customData }) => customData.map(({ value }) => value).join(';'))
    .join(';'),
];

// Cache the delivery note rows to avoid re-calculating them on every render.
const deliveryNoteRowCache = new Map();

/**
 * Maps the data of one delivery row to a structured format for display.
 *
 * @param {Object} deliveryNote - The delivery note object to map.
 * @return {Object} The mapped delivery row.
 */
export const mapDeliveryRow = (deliveryNote) => {
  const cacheKey = `${deliveryNote.id}-${deliveryNote.modifiedDate}`;

  if (deliveryNoteRowCache.has(cacheKey)) {
    return deliveryNoteRowCache.get(cacheKey);
  }

  // Set the user of the process state by the latest process state.
  const processStateChangeUser =
    [
      deliveryNote.processStateChangeUser.cancelled.getName(),
      deliveryNote.processStateChangeUser.delivered.getName(),
      deliveryNote.processStateChangeUser.arrived.getName(),
      deliveryNote.processStateChangeUser.inTransport.getName(),
      deliveryNote.processStateChangeUser.readyForOutput.getName(),
    ].find(Boolean) || '';

  const permittedToSitesNames = deliveryNote.permittedToSites.map(
    ({ name }) => name,
  );
  const permittedCostCentersNames = deliveryNote.permittedCostCenters.map(
    ({ name }) => name,
  );
  const userActionsRequestSignatures =
    deliveryNote.userActions.requestSignatures.map(({ userName }) => userName);
  const userActionsShareDeliveryNote =
    deliveryNote.userActions.shareDeliveryNote.map(({ userName }) => userName);
  const deliveryNoteArticles = deliveryNote.articles.map(
    ({ customData, number, type }) => ({
      customData: ObjectUtils.entries(customData),
      number,
      type, // FIXME: this unnecessary mapping makes it MUCH harder to work with the data
    }),
  );

  const row = {
    acceptState: deliveryNote.acceptState,
    articleNumbers: deliveryNoteArticles.map(({ number }) => number),
    articles: deliveryNoteArticles.map(({ type }) => type),
    buyer: deliveryNote.buyer.name,
    buyerId: deliveryNote.buyerId,
    buyerOrderReferences: deliveryNote.buyerOrderReferences,
    carrier: deliveryNote.carrier.name,
    carrierId: deliveryNote.carrierId,
    category: deliveryNote.getCategoryDescription(),
    comments: deliveryNote.comments.join(' | '),
    constructionComponents: deliveryNote.constructionComponents,
    constructionPlans: deliveryNote.constructionPlans,
    costCenters: deliveryNote.costCenters,
    creationDate: deliveryNote.creationDate,
    deliveryListActions: `${deliveryNote.processState};${deliveryNote.toSiteRecipient.name};${permittedToSitesNames.join(', ')};${permittedCostCentersNames.join(', ')};${JSON.stringify(deliveryNote.userActions)}`,
    deliveryNoteSharedWith: userActionsShareDeliveryNote.join(', '),
    deliveryType: deliveryNote.deliveryType,
    description: deliveryNote.deliveryDescription,
    dlnDate: parseDate(deliveryNote.dlnDate),
    fromSite: deliveryNote.fromSite.name,
    fromSiteIssuerAssignedId: deliveryNote.fromSite.issuerAssignedId,
    id: deliveryNote.id,
    licensePlate: deliveryNote.carrierLicensePlate,
    mainArticleAmountUnit: deliveryNote.mainArticle.amount.unit,
    mainArticleAmountValue: deliveryNote.mainArticle.amount.value,
    mainArticleNumber: deliveryNote.mainArticle.number,
    mainArticleType: deliveryNote.mainArticle.type,
    modifiedDate: deliveryNote.modifiedDate,
    movementMeans: deliveryNote.movementMeans,
    number: deliveryNote.number,
    permittedCostCenterNames: permittedCostCentersNames,
    permittedCostCenters: deliveryNote.permittedCostCenters
      .map((costCenter) => JSON.stringify(costCenter))
      .join(';;;'),
    permittedToSiteNames: permittedToSitesNames,
    permittedToSites: deliveryNote.permittedToSites
      .map((site) => JSON.stringify(site))
      .join(';;;'),
    processState: deliveryNote.processState,
    processStateChangeUser,
    project: deliveryNote.project,
    recipient: deliveryNote.recipient.name,
    seller: deliveryNote.seller.name,
    sellerOrderReferences: deliveryNote.sellerOrderReferences,
    settledStatus: BilledItem.getSettledStatusDescription(
      deliveryNote.settledStatus,
    ),
    signaturesRequestedFrom: userActionsRequestSignatures.join(', '),
    status: `${deliveryNote.processState};${deliveryNote.acceptState};${deliveryNote.combinedState};${deliveryNote.settledStatus}`,
    supplier: deliveryNote.supplier.name,
    toSiteRecipient: deliveryNote.toSiteRecipient.name,
    toSiteSupplier: deliveryNote.toSiteSupplier.name,
    toSiteSupplierAddress:
      deliveryNote.toSiteSupplier.address.getConcatenatedAddress(),
    totalWeightGross: deliveryNote.totalWeightGross,
    totalWeightNet: deliveryNote.totalWeightNet,
    totalWeightTare: deliveryNote.totalWeightTare,
    trader: deliveryNote.trader.name,
    ...deliveryNote.customData,
  };

  for (const article of deliveryNoteArticles) {
    // Add custom data values on article level to row.
    if (article?.customData)
      for (const { key, value } of article?.customData) {
        row[key] ||= [];

        // Convert the custom data value to an array, so it can hold multiple values.
        if (!Array.isArray(row[key])) {
          row[key] = [row[key]];
        }

        if (!row[key].includes(value)) {
          row[key].push(value);
        }
      }
  }

  const searchString = generateDeliveryNoteSearchString(
    row,
    deliveryNote,
    deliveryNoteArticles,
  );

  // Actually, only users that are allowed to see the permitted to sites and cost centers, should also be able to filter via the free text filter.
  // However, calling store.getState().userinfo.userinfo.userFeatureFlags.accessPermittedSites is not working because then we would call the store inside of deliveryNotesSlice which leads to the error:
  // -> Error: You may not call store.getState() while the reducer is executing. The reducer has already received the state as an argument. Pass it down from the top reducer instead of reading it from the store.
  // As a quick fix, we remove the if condition on UserUtils.isPermittedSiteAllowedUser().
  // The downside is that now all users will also filter on permittedToSites and permittedCostCenters even if they can't see those columns.
  if (true /* UserUtils.isPermittedSiteAllowedUser() */) {
    searchString.push(...permittedToSitesNames, ...permittedCostCentersNames);
  }

  row.searchString = searchString.join(';').toLowerCase();

  deliveryNoteRowCache.set(cacheKey, row);

  return row;
};
