import UniversalCommunication from './UniversalCommunication';
import ValueGroup from '../deliveries/ValueGroup';

export default class TradeContact {
  constructor(tradeContact) {
    this.personName =
      tradeContact?.person_name ?? tradeContact?.personName ?? '';
    this.jobTitle = tradeContact?.job_title ?? tradeContact?.jobTitle ?? '';
    this.departmentName =
      tradeContact?.department_name ?? tradeContact?.departmentName ?? '';
    this.universalCommunication = new UniversalCommunication(
      tradeContact?.universal_communication ??
        tradeContact?.universalCommunication,
    );
  }

  hasPersonName() {
    return ValueGroup.getCurrentValue(this.personName);
  }

  hasContactInformation() {
    return (
      this.hasPersonName() ||
      this.universalCommunication.hasCommunicationInformation()
    );
  }

  getConcatenatedContactInformation() {
    return TradeContact.getConcatenatedContactInformation(this);
  }

  static getConcatenatedContactInformation(tradeContact) {
    return [
      ValueGroup.getCurrentValue(tradeContact.personName),
      UniversalCommunication.getConcatenatedCommunicationInformation(
        tradeContact.universalCommunication,
      ),
    ]
      .filter(Boolean)
      .join(' | ');
  }
}
