import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import {
  Add as AddIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';

import { useMutationCreateCostCenter } from '~/data/costCenter';

import {
  LightTooltip,
  MissingPermissionsTooltip,
} from '~/utils/componentUtils';
import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import BasicForm from '~/components/BasicForm';

import { withErrorBoundary } from '~/ui/atoms';
import { MultiSelectCostCenters } from '~/ui/molecules/SelectServerDriven';

export const CostCenters = withErrorBoundary(({ setSite, site }) => {
  const { mutateAsync: createCostCenter, isPending } =
    useMutationCreateCostCenter();

  const [costCenter, setCostCenter] = useState('');
  const [isCostCenterFormOpen, setIsCostCenterFormOpen] = useState(false);

  const handleChangeCostCenters = (costCenters) => {
    const newSite = cloneDeep(site);
    newSite.costCenters = costCenters.map(({ id }) => id);

    Log.info(
      'Change form value of cost centers',
      {
        from: site.costCenters,
        to: newSite.costCenters,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change cost centers in create site wizard',
      Log.FEATURE.WIZARD,
    );

    setSite(newSite);
  };

  const handleInputChange = (event) => {
    setCostCenter(event.target.value);

    Log.info(
      'Change form value of cost center name',
      {
        from: costCenter,
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    FunctionUtils.delayFunction(
      'site_create_wizard_change_cost_center_name',
      Log.productAnalyticsEvent,
      ['Change cost center name in create site wizard', Log.FEATURE.WIZARD],
    );
  };

  const handleOpenCostCenterForm = () => {
    Log.info('Open cost center form', null, Log.BREADCRUMB.FORM_OPEN.KEY);
    Log.productAnalyticsEvent(
      'Open cost center form in create site wizard',
      Log.FEATURE.WIZARD,
    );

    setIsCostCenterFormOpen(true);
  };

  const handleCloseCostCenterForm = () => {
    Log.info('Close cost center form', null, Log.BREADCRUMB.FORM_CLOSE.KEY);
    Log.productAnalyticsEvent(
      'Close cost center form in create site wizard',
      Log.FEATURE.WIZARD,
    );

    setCostCenter('');
    setIsCostCenterFormOpen(false);
  };

  const handleSubmitCostCenter = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isPending) {
      return;
    }

    Log.productAnalyticsEvent(
      'Submit cost center form in create site wizard',
      Log.FEATURE.WIZARD,
    );

    try {
      const { id: newCostCenterId } = await createCostCenter({
        companyId: site.companyId,
        name: costCenter,
      });

      const newSite = cloneDeep(site);
      newSite.costCenters = [
        ...new Set([...newSite.costCenters, newCostCenterId]),
      ];

      setSite(newSite);
      setCostCenter('');
      setIsCostCenterFormOpen(false);
    } catch (error) {
      Log.error('Failed to create cost center', error);
    }
  };

  return (
    <Grid container direction="row" spacing={3} space={4}>
      <Grid item xs={12}>
        <h3 className="main-text mt-0">
          Mit welchen Kostenstellen soll der Standort verbunden werden?
        </h3>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12} className="flex items-end justify-between">
            <MultiSelectCostCenters
              className="mt-auto w-full"
              fullWidth
              label="Kostenstellen"
              onChange={handleChangeCostCenters}
              value={site.costCenters}
            />
            {UserUtils.isCostCenterCreateAllowedUser() ? (
              <LightTooltip title="Neue Kostenstelle erstellen">
                <Button
                  className="ml-2"
                  color="primary"
                  onClick={handleOpenCostCenterForm}
                  startIcon={<AddIcon />}
                  variant="outlined"
                  disabled={isPending}
                >
                  Erstellen
                </Button>
              </LightTooltip>
            ) : (
              <MissingPermissionsTooltip>
                <Button
                  className="ml-2"
                  onClick={handleOpenCostCenterForm}
                  startIcon={<AddIcon />}
                  variant="outlined"
                  disabled
                >
                  Erstellen
                </Button>
              </MissingPermissionsTooltip>
            )}
            <BasicForm
              formAbort={handleCloseCostCenterForm}
              formSuccess={handleSubmitCostCenter}
              open={isCostCenterFormOpen}
              title="Kostenstelle erstellen"
            >
              <div className="w-96">
                <TextField
                  autoComplete="off"
                  autoFocus
                  fullWidth
                  id="name-input"
                  label="Name"
                  name="name"
                  onChange={handleInputChange}
                  required
                  type="text"
                  value={costCenter}
                  disabled={isPending}
                />
              </div>
            </BasicForm>
          </Grid>
          {site.costCenters.length === 0 ? (
            <div className="text-warningBase ml-4 mt-2 flex items-center gap-1 text-xs">
              <ErrorOutlineIcon fontSize="small" />
              Verbinde die zugehörigen Kostenstellen mit dem neuen Standort,
              damit Lieferungen richtig zugeordnet werden können.
            </div>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}, 'Daten konnten nicht geladen werden.');
