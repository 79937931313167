import FileSaver from 'file-saver';
import React from 'react';
import { connect } from 'react-redux';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import CompanyService from '~/services/company.service';
import PdfSettingsService from '~/services/pdfsettings.service';
import ToastService from '~/services/toast.service';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';

const mapDispatchToProps = () => ({});

class PdfSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyName: '',
      deletingPdfTemplate: false,
      downloadingPdfTemplate: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    // if no company id is set, the pdf template is valid for the whole company account
    if (!this.props.pdfTemplate.companyId) {
      this.setState({
        companyName: this.props.companyAccountOption.NAME,
      });

      return;
    }

    const [company, error] = await promiseHandler(
      CompanyService.getCompanyById(this.props.pdfTemplate.companyId),
    );

    if (error) {
      Log.error(
        'Failed to load company by id. id: ' +
          this.props.pdfTemplate.company.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load company',
        Log.FEATURE.PDF_TEMPLATE,
        Log.TYPE.ERROR,
      );

      this.setState({
        companyName: this.props.pdfTemplate.company.id,
      });

      return;
    }

    this.setState({
      companyName: company.name,
    });
  }

  handleDownload = () => {
    Log.info(
      'Download pdf template',
      { id: this.props.pdfTemplate.id },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Download', Log.FEATURE.PDF_TEMPLATE);

    this.setState({
      downloadingPdfTemplate: true,
    });

    PdfSettingsService.getPdfTemplate(this.props.pdfTemplate.id)
      .then((response) => {
        const file = new Blob([response.data]);
        this.setState({
          downloadingPdfTemplate: false,
        });
        FileSaver.saveAs(
          file,
          this.props.pdfTemplate.filename ?? 'VESTIGAS-template-download',
        );
      })
      .catch((error) => {
        ToastService.httpError(
          [ToastService.MESSAGE.PDF_TEMPLATE_DOWNLOAD_FAILED],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to download',
          Log.FEATURE.PDF_TEMPLATE,
          Log.TYPE.ERROR,
        );
        this.setState({
          downloadingPdfTemplate: false,
        });
        Log.error(
          'Failed to download template. id: ' + this.props.pdfTemplate.id,
          error,
        );
      });
  };
  deletePdfTemplate = async () => {
    Log.info(
      'Delete pdf template',
      { id: this.props.pdfTemplate.id },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.PDF_TEMPLATE);

    this.setState({
      deletingPdfTemplate: true,
    });

    const [response, error] = await promiseHandler(
      PdfSettingsService.deletePdfTemplate(this.props.pdfTemplate.id),
    );

    if (error) {
      ToastService.httpError(
        [ToastService.MESSAGE.PDF_TEMPLATE_DELETION_FAILED],
        error.response,
      );
      Log.error(
        'Failed to delete template. id: ' + this.props.pdfTemplate.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.PDF_TEMPLATE,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingPdfTemplate: false,
      });
      return;
    }

    this.setState({
      deletingPdfTemplate: false,
    });

    PdfSettingsService.refreshPdfTemplates();
  };

  render() {
    return (
      <div className="rounded-5px box-shadow-blue p-20px max-w-600px w-full bg-white">
        <div>Name:</div>
        <div className="bold">{this.props.pdfTemplate.filename}</div>
        <div className="mt-20px">Firma:</div>
        <div className="bold">{this.state.companyName}</div>
        <div className="flex-sb-c mt-20px">
          <Button
            variant="outlined"
            color="secondary"
            disabled={this.state.deletingPdfTemplate}
            onClick={this.deletePdfTemplate}
          >
            {this.state.deletingPdfTemplate ? (
              <Spinner title="Löschen..." />
            ) : (
              'Löschen'
            )}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={
              this.state.downloadingPdfTemplate ? null : <DownloadIcon />
            }
            disabled={this.state.downloadingPdfTemplate}
            onClick={this.handleDownload}
          >
            {this.state.downloadingPdfTemplate ? (
              <Spinner title="Download..." />
            ) : (
              'Download'
            )}
          </Button>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(null, mapDispatchToProps())(PdfSettings),
  null,
);
