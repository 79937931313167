import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import Log from '~/utils/Log';

export default class DataSubscriptionDateFilterClause {
  constructor(dateFilterClause) {
    this.filterKeyword =
      dateFilterClause?.filter_keyword ??
      dateFilterClause?.filterKeyword ??
      DataSubscriptionDateFilterClause.FILTER_KEYWORD.DLN_DATE_FROM.KEY;
    this.valueType = DataSubscriptionDateFilterClause.VALUE_TYPE;
    this.value = 'today'; // Currently only today exists
    this.incDays = dateFilterClause?.inc_days ?? dateFilterClause?.incDays ?? 0;
    this.decDays = dateFilterClause?.dec_days ?? dateFilterClause?.decDays ?? 0;
  }

  static getFilterKeywordString(filterKey) {
    const filter = Object.keys(
      DataSubscriptionDateFilterClause.FILTER_KEYWORD,
    ).find(
      (x) =>
        DataSubscriptionDateFilterClause.FILTER_KEYWORD[x].KEY === filterKey,
    );

    if (!filter) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid filter: ' + filterKey),
      );
      return null;
    }

    return DataSubscriptionDateFilterClause.FILTER_KEYWORD[filter].STRING;
  }

  static getBackendFormat(dateFilterClause) {
    return {
      dec_days: dateFilterClause.decDays,
      filter_keyword: dateFilterClause.filterKeyword,
      inc_days: dateFilterClause.incDays,
      value: dateFilterClause.value,
      value_type: dateFilterClause.valueType,
    };
  }

  static FILTER_KEYWORD = {
    CREATION_DATE_FROM: {
      KEY: 'dln_date_created_from',
      STRING: 'LFS-Erstellungsdatum von',
    },
    CREATION_DATE_TO: {
      KEY: 'dln_date_created_to',
      STRING: 'LFS-Erstellungsdatum bis',
    },
    DLN_DATE_FROM: {
      KEY: 'dln_date_from',
      STRING: 'LFS-Datum von',
    },
    DLN_DATE_TO: {
      KEY: 'dln_date_to',
      STRING: 'LFS-Datum bis',
    },
    MODIFIED_DATE_FROM: {
      KEY: 'dln_date_modified_from',
      STRING: 'LFS-Änderungsdatum von',
    },
    MODIFIED_DATE_TO: {
      KEY: 'dln_date_modified_to',
      STRING: 'LFS-Änderungsdatum bis',
    },
  };
  static VALUE_TYPE = 'timedelta';
}
