import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';
import {
  PAGINATION_PAGE_SIZE_DEFAULT,
  type PaginationSortDirection,
} from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import { type Address } from '~/types/address';
import { type UUID } from '~/types/common';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import Log from '~/utils/Log';
import { toSnakeCase } from '~/utils/string';

import { queryKeysCategory } from './queryKeys';

export type CategoriesQueryParams = {
  limit: number;
  offset: number;
  isRoot?: boolean;
  isActive?: boolean;
  searchText?: string;
  filterCategories?: string[];
  filterCompanies?: string[];
  filterArticles?: string[];
  filterCccRefs?: string[];
  filterSites?: string[];
  filterSubCategories?: string[];
  filterParentCategories?: string[];
  includeSubDepth?: number;
  includeParentDepth?: number;
  filterOwnerCompAccs: string;
  filterOwnerComps: string;
  ownerFilterUnion: boolean;
  orderBy:
    | 'articleId'
    | 'name'
    | 'description'
    | 'ean'
    | 'isActive'
    | 'virtual'
    | 'equivalentCategories'
    | 'access'
    | 'ownerCompanies'
    | 'filterCompanies'
    | 'filterSites'
    | 'filterCostCenters'
    | 'id';
  sort: PaginationSortDirection;
};

export type ArticleData = {
  addDlnsToInvoice?: boolean;
  addInvoiceProtocolToInvoice?: boolean;
  address: Address;
  dlnSimplifiedJsonsToInvoice?: boolean;
  dlnsMultiFileToInvoice?: boolean;
  emailReceivedTimeToSubject?: boolean;
  invoiceReceiverList?: any[];
  name: string;
  orgUnits?: UUID[];
  sendInvoiceExternalXml?: boolean;
};

export type CategoryListItem = {
  name: string;
  description: string | undefined;
  rank: number | undefined;
  access: 'public' | 'private';
  isActive: boolean;
  linkedArticles: string[];
  ownerCompanies: string[];
  ownerCompanyAccounts: string[];
  subCategories: string[];
  parentCategories: string[];
  accessCompanies: string[];
  filterCompanies: string[];
  filterSites: string[];
  filterAccRefs: string[];
  id: string;
  isRoot: boolean;
};

type CategoriesResponse = {
  data: readonly CategoryListItem[];
  count: number;
};

const defaultQueryParams: CategoriesQueryParams = {
  filterOwnerCompAccs: undefined,
  filterOwnerComps: undefined,
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  orderBy: 'name',
  ownerFilterUnion: true,
  searchText: undefined,
  sort: 'ASC',
};

/**
 * Generates the query options for the categories query so they can be shared between the useQuery hook and the prefetching.
 */
export const getCategoriesQueryOptions = ({
  queryParams,
  options = {},
}: {
  queryParams: Partial<CategoriesQueryParams>;
  options?: Omit<
    UseQueryOptions<CategoriesResponse, Error>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryFn: async () => fetchCategories(qp),
    queryKey: queryKeysCategory.getAll(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all categories from the API.
 * @param queryParams - The query parameters for fetching categories.
 * @returns The category data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/get_categories_article_master_category_get
 */
export const fetchCategories = async (
  queryParams: Partial<CategoriesQueryParams>,
): Promise<CategoriesResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.orderBy = toSnakeCase(qp.orderBy);
  qp.sort = qp.sort?.toUpperCase() as PaginationSortDirection;

  for (const key of Object.keys(qp)) {
    if (qp[key as keyof CategoriesQueryParams] === undefined) {
      delete qp[key as keyof CategoriesQueryParams];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.CATEGORY.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<CategoriesResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching categories', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all categories with given query parameters.
 * Handles pagination and prefetches the next page of categories for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching categories.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryCategories = (
  queryParams: Partial<CategoriesQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getCategoriesQueryOptions({
        options,
        queryParams: {
          ...queryParams,
          offset:
            (queryParams.offset ?? 0) +
            (queryParams.limit ?? PAGINATION_PAGE_SIZE_DEFAULT),
        },
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery<CategoriesResponse, Error>({
    ...getCategoriesQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all categories with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching categories.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQueryCategoeriesInfinite = (
  queryParams: Partial<CategoriesQueryParams>,
  options: InfiniteQueryOptionsType<CategoriesResponse, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async ({ pageParam: pageParameter = 0 }) =>
      fetchCategories({
        ...qp,
        offset: pageParameter,
      }),
    queryKey: queryKeysCategory.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<CategoriesResponse>(qp, infiniteQueryOptions);
};
