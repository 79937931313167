import { Check as CheckIcon } from '@mui/icons-material';
import { Chip as MuiChip } from '@mui/material';

import { cn } from '~/utils/tailwind';

const Chip = ({
  className,
  clickable,
  deletable,
  id,
  isPaginationChip,
  name,
  new: isNew,
  onClick,
  onDelete,
  onPaginationClick,
  selected,
  style,
  sx,
}) => {
  const isClickable = () => {
    if (isPaginationChip) {
      return true;
    }

    return clickable;
  };

  const isDeletable = () => {
    if (isPaginationChip) {
      return false;
    }

    return deletable;
  };

  const handleClick = () => {
    if (!isClickable()) {
      return;
    }

    if (isPaginationChip) {
      onPaginationClick();
    } else {
      onClick(id);
    }
  };

  const handleDelete = () => {
    if (!isDeletable()) {
      return;
    }

    onDelete(id);
  };

  return (
    <div
      onMouseDown={handleClick}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      className="relative"
    >
      <MuiChip
        key={id}
        label={name}
        onDelete={isDeletable() ? handleDelete : null}
        icon={selected ? <CheckIcon className="text-white" /> : null}
        className={cn(
          selected ? 'bg-primary500 text-white' : 'text-grey800 bg-white',
          className,
        )}
        sx={
          sx ?? {
            '&:hover': {
              backgroundColor:
                isClickable() && !selected ? '#E4E6ED !important' : 'white',
            },
            backgroundColor: 'white',
            border: isPaginationChip
              ? '#173C88 solid 1px'
              : '#8D94A8 solid 1px',
            // primary500, grey400
            color: isPaginationChip ? '#173C88' : null,
            cursor: isClickable() ? 'pointer' : 'default',
          }
        }
        style={style}
      />
      {isNew ? (
        <div className="absolute -right-2 -top-2.5 flex h-5 w-10 items-center justify-center rounded-full bg-gray-800 text-xs text-white">
          Neu
        </div>
      ) : null}
    </div>
  );
};

export default Chip;
