import { useQueryUserData } from '~/data/user';

import { Spinner } from '~/components/Spinner';
import { Suppliers } from '~/components/suppliers/Suppliers';

import DataExchanges from './DataExchanges';

const DataExchangePage = () => {
  const { data: currentUser, isLoading: isLoadingUserData } =
    useQueryUserData(true);
  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};

  /**
   * We don't want to show the old data exchange page whilst we are loading the company account to determine the feature flag.
   * This would only happen when you reload on the data exchange page.
   */
  if (isLoadingUserData) {
    return <Spinner />;
  }

  const showNewKanbanView = Boolean(featureFlags?.suppliersKanban);

  return showNewKanbanView ? <Suppliers /> : <DataExchanges />;
};

export default DataExchangePage;
