import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  fetchUser,
  fetchUsers,
  queryKeysUser,
  type UserListItem,
} from '~/data/user';

import UserUtils from '~/utils/userUtils';

import { type UUID } from '~/types/common';

import { OptionPrimarySecondary } from './OptionPrimarySecondary';
import { type Option, SelectServerDriven } from './SelectServerDriven';
import { createLoadOptions, createGetItemData } from './utils';

const getOptionLabel = ({ email }) => email;

const defaultFormatOptionLabel = (option: Option<UserListItem>) => (
  <OptionPrimarySecondary
    primaryInformation={option?.data?.email}
    secondaryInformation={[option?.data?.firstName, option?.data?.lastName]
      .filter(Boolean)
      .join(' ')}
  />
);

type P = {
  filterActive?: boolean;
  formatOptionLabel?: (option: Option<UserListItem>) => React.ReactNode;
  isDisabled?: boolean;
  isMultiSelect?: boolean;
  onChange: (value: Array<{ id: string }>) => void;
  placeholder?: string;
  value: string[];
} & ComponentStyling;

export const MultiSelectUsers = ({
  className,
  filterActive = true,
  formatOptionLabel = defaultFormatOptionLabel,
  isDisabled = false,
  isMultiSelect = true,
  onChange,
  placeholder = 'Benutzer',
  style,
  value,
}: P) => {
  const queryClient = useQueryClient();

  /**
   * Loads the options for the multi-select dropdown.
   * @param {string} searchString - The search string entered by the user.
   * @param {unknown} loadedOptions - The already loaded options.
   * @param {{ page: number }} - The current page number.
   */
  const loadOptions = createLoadOptions<UserListItem>({
    createQueryKey: ({ page, ...queryParams }) =>
      queryKeysUser.getAll(queryParams),
    async fetchItems({ page, ...queryParams }) {
      const { data, hasNextPage } = await fetchUsers(queryParams);

      return {
        data,
        hasNextPage,
      };
    },
    filterActive,
    getOptionLabel,
    queryClient,
  });

  /**
   * Gets user data for a user with a given userId.
   * Attempts to get it from the query cache and fetches it from the API
   * if the user data is not in the cache.
   * @param {string} userId - The ID of the user to get.
   */
  const getItemData = createGetItemData<UserListItem>({
    createQueryKey: (userId: UUID) => queryKeysUser.get(userId),
    fetchItem: fetchUser,
    queryClient,
    queryKeyBase: [queryKeysUser.base()],
  });

  // This is just a glue function b/c PermissionGrantMultiPicker currently expects to pick the ID from an object in its onChange function. Should be refactored.
  const handleChange = useCallback(
    (newValues: string[]) => {
      onChange(newValues.map((id) => ({ id })));
    },
    [onChange],
  );

  return (
    <SelectServerDriven<UserListItem>
      value={value}
      onChange={handleChange}
      className={className}
      getItemData={getItemData}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      isItemSelectionAllowed={UserUtils.isUserReadAllowedUser}
      isMultiSelect={isMultiSelect}
      loadOptions={loadOptions}
      placeholder={placeholder}
      style={style}
    />
  );
};
