import { useCallback } from 'react';

import Log from '~/utils/Log';
import { toCamelCase } from '~/utils/string';

import { formDataDefaultValues } from './useSettingsTable';

export const useFormInteractions = (setState) => {
  const onOpenForm = useCallback((type, data) => {
    Log.info(`Open ${type} edit form`, data, Log.BREADCRUMB.FORM_OPEN.KEY);
    Log.productAnalyticsEvent(
      `Open ${type} edit form`,
      Log.FEATURE.SETTINGS_TABLE,
    );

    setState((previousState) => ({
      ...previousState,
      ...formDataDefaultValues,
      [toCamelCase(type)]: data,
      formType: 'edit',
      isFormOpen: true,
    }));
  }, []);

  return {
    onOpenCompany: useCallback(
      (data) => onOpenForm('Company', data),
      [onOpenForm],
    ),
    onOpenCostCenter: useCallback(
      (data) => onOpenForm('CostCenter', data),
      [onOpenForm],
    ),
    onOpenOrganisationalGroup: useCallback(
      (data) => onOpenForm('OrganisationalGroup', data),
      [onOpenForm],
    ),
    onOpenSite: useCallback((data) => onOpenForm('Site', data), [onOpenForm]),
    onOpenUser: useCallback((data) => onOpenForm('User', data), [onOpenForm]),
    onOpenUserGroup: useCallback(
      (data) => onOpenForm('UserGroup', data),
      [onOpenForm],
    ),
    onOpenVehicle: useCallback(
      (data) => onOpenForm('Vehicle', data),
      [onOpenForm],
    ),
    setFormOpen: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          isFormOpen: value,
        })),
      [],
    ),
    setFormType: useCallback(
      (value) =>
        setState((previousState) => ({
          ...previousState,
          formType: value,
        })),
      [],
    ),
  };
};
