import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import UserUtils from '~/utils/userUtils';

import BasicForm from '~/components/BasicForm';

import { MultiItemsManagerOrganizationalUnits } from '~/ui/molecules/MultiItemsManager';
import { SelectCompany } from '~/ui/molecules/SelectServerDriven';

import { OrganizationalUnitPaths } from '../paths/OrganizationalUnitPaths';
import { PermissionGrantEntityTable } from '../permissionGrant/PermissionsTable';

import { useVehicleForm } from './useVehicleForm';

export const VehicleForm = ({
  closeForm,
  onOpenOrganisationalGroup,
  onUpdatedOrganisationalGroupsChange,
  open,
  type,
  vehicleId,
}) => {
  const {
    handleActiveCheckboxChange,
    handleCancel,
    handleChangeCompany,
    handleChangeOrganisationalGroups,
    handleInputChange,
    handleSubmit,
    isCreatingVehicle,
    isError,
    isLoading,
    isSubmitting,
    refetchVehicle,
    unsavedChanges,
    vehicle,
  } = useVehicleForm({
    closeForm,
    type,
    vehicleId,
  });

  const shouldRenderGroupPaths =
    vehicle?.parentOrganizationalUnitPaths?.length > 0;

  return (
    <BasicForm
      formAbort={handleCancel}
      formSuccess={handleSubmit}
      id={vehicle?.id}
      missingPermissionsToSubmit={
        isCreatingVehicle
          ? !UserUtils.isVehicleCreateAllowedUser()
          : !UserUtils.isVehicleWriteAllowedUser()
      }
      open={open}
      submittingForm={isSubmitting}
      title={`Fahrzeug ${isCreatingVehicle ? 'erstellen' : (vehicle.licensePlate?.name ?? '')}`}
      unsavedChanges={unsavedChanges}
      fullWidth
    >
      {shouldRenderGroupPaths ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <OrganizationalUnitPaths
                id={vehicle?.id}
                organizationalUnitPaths={vehicle?.parentOrganizationalUnitPaths}
                onOpenOrganizationalUnit={(organizationalUnit) =>
                  onOpenOrganisationalGroup(organizationalUnit, unsavedChanges)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={2} className="max-w-lg pt-2">
        <Grid item xs={3}>
          <TextField
            autoComplete="off"
            error={
              vehicle.licensePlate?.city &&
              !vehicle.licensePlate?.cityIsValid?.()
            }
            helperText={
              !vehicle.licensePlate?.city ||
              vehicle.licensePlate?.cityIsValid?.()
                ? null
                : 'Ungültige Eingabe'
            }
            label="Stadt"
            name="licensePlateCity"
            onChange={handleInputChange}
            placeholder="z. B. M"
            type="text"
            value={vehicle.licensePlate?.city}
            fullWidth
            autoFocus
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            autoComplete="off"
            error={
              vehicle.licensePlate?.letters &&
              !vehicle.licensePlate?.lettersIsValid?.()
            }
            helperText={
              !vehicle.licensePlate?.letters ||
              vehicle.licensePlate?.lettersIsValid?.()
                ? null
                : 'Ungültige Eingabe'
            }
            label="Buchstaben"
            name="licensePlateLetters"
            onChange={handleInputChange}
            placeholder="z.B. VG"
            type="text"
            value={vehicle.licensePlate?.letters}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            error={
              vehicle.licensePlate?.numbers &&
              !vehicle.licensePlate?.numbersIsValid?.()
            }
            helperText={
              !vehicle.licensePlate?.numbers ||
              vehicle.licensePlate?.numbersIsValid?.()
                ? null
                : 'Ungültige Eingabe'
            }
            label="Nummern"
            name="licensePlateNumbers"
            onChange={handleInputChange}
            placeholder="z. B. 123"
            type="text"
            value={vehicle.licensePlate?.numbers}
            autoComplete="off"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={vehicle.isActive}
                name="isActive"
                onChange={handleActiveCheckboxChange}
              />
            }
            label="Aktiv"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <SelectCompany
          className="w-96"
          value={vehicle.companyId}
          onChange={handleChangeCompany}
        />
      </Grid>
      <Grid item xs={12}>
        <h3 className="mt-4">Ist Teil von...</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <div className="min-w-[600px]">
              <MultiItemsManagerOrganizationalUnits
                isCreatingNewEntity={isCreatingVehicle}
                filterByEntity={{
                  vehicle: vehicleId,
                }}
                onChange={onUpdatedOrganisationalGroupsChange}
                onSaveSelection={handleChangeOrganisationalGroups}
                onSelectedItemClick={(organisationalGroup) =>
                  onOpenOrganisationalGroup(organisationalGroup, unsavedChanges)
                }
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      {!isCreatingVehicle && (
        <Grid item xs={12}>
          <PermissionGrantEntityTable
            defaultEntities={[vehicle.id]}
            defaultEntityType={PermissionGrant.ENTITY_TYPE.VEHICLE.KEY}
            fixedPicker={PermissionGrant.TYPE.ENTITY}
            loading={isLoading}
            permissionGrantsFrom={vehicle.permissionGrantsFrom ?? []}
            refreshData={refetchVehicle}
            title="Wer ist auf dieses Fahrzeug berechtigt?"
          />
        </Grid>
      )}
    </BasicForm>
  );
};
