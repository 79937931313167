import React, { useState, useCallback, memo } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT_STRABAG } from '~/constants/pagination';

import { useQueryCompanies } from '~/data/company';

import LocalStorageService from '~/services/localStorage.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns } from './constants';
import { selectCompanies } from './selectCompanies';

export const CompaniesTable = withErrorBoundary(
  memo(() => {
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      // Tmp change until we removed old Strabag Direktionen
      pageSize: PAGINATION_PAGE_SIZE_DEFAULT_STRABAG,
    });
    const [sortModel, setSortModel] = useState([
      {
        field: 'name',
        sort: 'asc',
      },
    ]);
    const [searchString, setSearchString] = useState(undefined);

    const { data, isFetching, isPending, isPlaceholderData, refetch } =
      useQueryCompanies(
        {
          limit: paginationModel.pageSize,
          offset: paginationModel.page * paginationModel.pageSize,
          orderBy: sortModel[0]?.field,
          searchString,
          sort: sortModel[0]?.sort,
        },
        {
          select: selectCompanies,
        },
      );

    const handlePaginationModelChange = useCallback((newModel) => {
      setPaginationModel(newModel);
    }, []);

    const handleSortModelChange = useCallback((newModel) => {
      setSortModel(newModel);
    }, []);

    const handleSearchStringChange = useCallback((newSearchString) => {
      // Reset to first page when search string changes
      setPaginationModel((previousModel) => ({
        ...previousModel,
        page: 0,
      }));
      setSearchString(newSearchString);
    }, []);

    const filterFunction = useCallback((string) => string, []); // We filter server side -> prevent the DataGrid from filtering.

    return (
      <SettingsTable
        key="CompaniesTable"
        entity="company"
        title="Firmen"
        columns={columns}
        filterFunction={filterFunction}
        items={data?.companies ?? []}
        isLoading={isPending || (isFetching && isPlaceholderData)}
        localStorageKey={LocalStorageService.COMPANY_TABLE}
        multiPermissionGrantDefaultEntityType={
          PermissionGrant.ENTITY_TYPE.COMPANY.KEY
        }
        multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
        onPaginationModelChange={handlePaginationModelChange}
        onSearchStringChange={handleSearchStringChange}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        refreshData={refetch}
        rows={data?.rows ?? []}
        sortBy="name"
        sortModel={sortModel}
        totalRowCount={data?.totalCount ?? 0}
        hasMultiPermissionGrantEditing
      />
    );
  }),
  'Firmen konnten nicht geladen werden.',
);
