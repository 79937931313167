import { memo } from 'react';

import DatagridUtils from '~/utils/datagridUtils';

import { InactiveIcon } from '~/components/InactiveIcon';

export const PermittedToCostCentersCell = memo(({ value }) => {
  const permittedToCostCenters = value
    .split(';;;')
    .map((part) => JSON.parse(part));

  if (permittedToCostCenters.every(({ active }) => active)) {
    return DatagridUtils.displayCellTooltipControlled(
      permittedToCostCenters.map(({ name }) => name).join(', '),
      permittedToCostCenters.map(({ name }) => name).join(', '),
    );
  }

  return (
    <div className="flex items-center gap-1">
      {permittedToCostCenters.map(({ active, name }) => (
        <div key={name} className="flex items-center gap-1">
          {name}
          {!active && <InactiveIcon />}
        </div>
      ))}
    </div>
  );
});
