export const haveFiltersChanged = (
  previousProps,
  currentProps,
  isArchiveMode,
) => {
  // Check all filter-related props.
  const filterProps = [
    'dateRange',
    'selectedAcceptState',
    'selectedArticle',
    'selectedArticleNumber',
    'selectedCostCenter',
    'selectedCostCenters',
    'selectedCustomFields',
    'selectedFromSite',
    'selectedPermittedCostCenters',
    'selectedPermittedToSites',
    'selectedProcessState',
    'selectedRecipient',
    'selectedSettledStatus',
    'selectedSites',
    'selectedSupplier',
    'selectedToSiteRecipient',
    'selectedToSiteSupplier',
  ];

  // Check if any props objects changed.
  const havePropsObjectsChanged = filterProps.some(
    (property) =>
      JSON.stringify(currentProps[property]) !==
      JSON.stringify(previousProps[property]),
  );

  // Check if any primitive props changed.
  const havePropsPrimitivesChanged =
    currentProps.selectField !== previousProps.selectField ||
    currentProps.query !== previousProps.query;

  // Check archive mode-specific conditions.
  const haveArchiveModePropsChanged = isArchiveMode
    ? JSON.stringify(currentProps.calculatedFilterModel) !==
      JSON.stringify(previousProps.calculatedFilterModel)
    : currentProps.deliveryNotes.filteredDeliveryNotesVersion !==
      previousProps.deliveryNotes.filteredDeliveryNotesVersion;

  return (
    havePropsObjectsChanged ||
    havePropsPrimitivesChanged ||
    haveArchiveModePropsChanged
  );
};
