import React from 'react';

import { InputLabel, Grid } from '@mui/material';

import { connect } from 'react-redux';
import NotificationPolicy from '~/models/dataSubscriptions/NotificationPolicy';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import ToastService from '~/services/toast.service';
import DataSubscriptionService from '~/services/dataSubscription.service';
import BasicForm from '~/components/BasicForm';
import cloneDeep from 'lodash/cloneDeep';
import SearchableSelect from '~/components/baseComponents/inputs/select/SearchableSelect';
import MapperService from '~/services/mapper.service';
import ArrayUtils from '~/utils/arrayUtils';

const mapStateToProps = (state) => ({
  dataSubscriptions: state.dataSubscriptions,
});
const mapDispatchToProps = () => ({});

class NotificationPolicyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deletingForm: false,
      notificationPolicy: new NotificationPolicy(),
      sortedContactPoints: [],
      sortedDataSubscriptions: [],
      submittingForm: false,
    };
  }

  componentDidMount() {
    this.resetForm();
    this.initSortedDataSubscriptions();
    this.initSortedContactPoints();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.notificationPolicy) !==
      JSON.stringify(this.props.notificationPolicy)
    ) {
      this.resetForm();
    }

    if (
      JSON.stringify(prevProps.dataSubscriptions.dataSubscriptions) !==
      JSON.stringify(this.props.dataSubscriptions.dataSubscriptions)
    ) {
      this.initSortedDataSubscriptions();
    }

    if (
      JSON.stringify(prevProps.dataSubscriptions.contactPoints) !==
      JSON.stringify(this.props.dataSubscriptions.contactPoints)
    ) {
      this.initSortedContactPoints();
    }
  }

  resetForm() {
    this.setState({
      notificationPolicy:
        this.props.notificationPolicy ?? new NotificationPolicy(),
    });
  }

  initSortedDataSubscriptions() {
    const dataSubscriptions = this.props.dataSubscriptions.dataSubscriptions
      .filter((dataSubscription) => dataSubscription.active)
      .map((dataSubscription) => {
        const newDataSubscription = {
          id: dataSubscription.id,
          name: dataSubscription.name,
        };

        return MapperService.addSearchStringWithValues(newDataSubscription, [
          dataSubscription.name,
        ]);
      });

    const sortedDataSubscriptions = ArrayUtils.sortByKey(
      dataSubscriptions,
      'name',
    );

    this.setState({
      sortedDataSubscriptions,
    });
  }

  initSortedContactPoints() {
    const contactPoints = this.props.dataSubscriptions.contactPoints.map(
      (contactPoint) => {
        const newContactPoint = {
          id: contactPoint.id,
          name: contactPoint.name,
        };

        return MapperService.addSearchStringWithValues(newContactPoint, [
          contactPoint.name,
        ]);
      },
    );

    const sortedContactPoints = ArrayUtils.sortByKey(contactPoints, 'name');

    this.setState({
      sortedContactPoints,
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    const body = {
      contact_point_id: this.state.notificationPolicy.contactPointId,
      data_subscription_id: this.state.notificationPolicy.dataSubscriptionId,
    };

    Log.info(
      'Submit notification policy form',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.DATA_SUBSCRIPTION);

    const [response, error] = await promiseHandler(
      DataSubscriptionService.createNotificationPolicy(body),
    );

    if (error) {
      Log.error('Failed to create notification policy.', error);
      ToastService.httpError(
        ['Verknüpfung konnte nicht angelegt werden.'],
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to create',
        Log.FEATURE.DATA_SUBSCRIPTION,
        Log.TYPE.ERROR,
      );
      this.setState({
        submittingForm: false,
      });
      return;
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    DataSubscriptionService.refreshNotificationPolicies();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.DATA_SUBSCRIPTION);
    this.props.closeForm();
    this.resetForm();
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete notification policy',
      { id: this.props.notificationPolicy.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.DATA_SUBSCRIPTION);

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      DataSubscriptionService.deleteNotificationPolicy(
        this.props.notificationPolicy.contactPointId,
        this.props.notificationPolicy.dataSubscriptionId,
      ),
    );

    if (error) {
      ToastService.httpError(
        ['Verknüpfung konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete notification policy.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.DATA_SUBSCRIPTION,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    await DataSubscriptionService.refreshNotificationPolicies();
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };
  handleChangeDataSubscriptionId = (event) => {
    const notificationPolicy = cloneDeep(this.state.notificationPolicy);

    notificationPolicy.dataSubscriptionId = event.target.value;

    this.setState({
      notificationPolicy,
    });
  };
  handleChangeContactPointId = (event) => {
    const notificationPolicy = cloneDeep(this.state.notificationPolicy);

    notificationPolicy.contactPointId = event.target.value;

    this.setState({
      notificationPolicy,
    });
  };

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={this.renderForCreate() ? null : this.formDelete}
        title={'Verknüpfung ' + (this.renderForCreate() ? 'erstellen' : '')}
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        disableSubmitButton={
          !this.renderForCreate() ||
          !this.state.notificationPolicy?.dataSubscriptionId ||
          !this.state.notificationPolicy?.contactPointId
        }
        id={this.props.notificationPolicy?.id}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Verknüpfung</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Regel</InputLabel>
                <SearchableSelect
                  value={this.state.notificationPolicy?.dataSubscriptionId}
                  fullWidth
                  onChange={this.handleChangeDataSubscriptionId}
                  size="small"
                  options={this.state.sortedDataSubscriptions}
                  loading={
                    this.props.dataSubscriptions.dataSubscriptionsLoading
                  }
                  disabled={!this.renderForCreate()}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Empfänger</InputLabel>
                <SearchableSelect
                  value={this.state.notificationPolicy?.contactPointId}
                  fullWidth
                  onChange={this.handleChangeContactPointId}
                  size="small"
                  options={this.state.sortedContactPoints}
                  loading={this.props.dataSubscriptions.contactPointsLoading}
                  disabled={!this.renderForCreate()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(NotificationPolicyForm);
