import { validate as uuidvalidate } from 'uuid';
import { promiseHandler } from '~/utils/promiseHandler';
import FileReaderUtils from '~/utils/fileReaderUtils';
import unitUtils from '~/utils/unitUtils';

export default class CSVValidator {
  constructor(allowedColumns, file) {
    this.allowedColumns = allowedColumns;
    this.file = file;
    this.fileColumns = [];
    this.fileRows = [];
  }

  async initFile() {
    const [string, error] = await promiseHandler(
      FileReaderUtils.readFileAsTextAsync(this.file),
    );

    if (error) {
      throw error;
    }

    // '\r\n' and '\n' and '\r' are valid new line delimiters depending on the OS.
    // Therefore, we need to cover all cases.
    let rows = string.split('\r\n');
    if (rows.length === 1) {
      rows = string.split('\n');
    }

    if (rows.length === 1) {
      rows = string.split('\r');
    }

    this.fileColumns = rows[0].split(CSVValidator.COLUMN_SEPARATOR);
    this.fileRows = rows.slice(1, rows.length); // All rows except the header row that contains the column names.
  }

  validate() {
    const [validationSuccess, validationError] = this.validateColumns();
    if (validationError) {
      return [null, validationError];
    }

    const [validationSuccess2, validationError2] = this.validateRows();
    if (validationError2) {
      return [null, validationError2];
    }

    return [true, null];
  }

  validateColumns() {
    for (let index = 0; index < this.fileColumns.length; index++) {
      const allowedColumn = this.allowedColumns.find(
        (allowedColumn) => allowedColumn.NAME === this.fileColumns[index],
      );
      if (!allowedColumn) {
        return [
          null,
          CSVValidator.VALIDATION_ERROR.UNKNOWN_COLUMN +
            ' ' +
            this.fileColumns[index],
        ];
      }
    }

    return [true, null];
  }

  validateRows() {
    for (let index = 0; index < this.fileRows.length; index++) {
      const [validationSuccess, validationError] = this.validateRow(
        this.fileRows[index],
      );
      if (validationError) {
        return [null, validationError];
      }
    }

    return [true, null];
  }

  validateRow(fileRow) {
    const fileRowArray = CSVValidator.parseCSVRow(fileRow);

    for (const [index, element] of fileRowArray.entries()) {
      if (!this.fileColumns[index]) {
        return [null, CSVValidator.VALIDATION_ERROR.TOO_MANY_VALUES];
      }

      const [validationSuccess, validationError] = this.validateValue(
        element,
        this.fileColumns[index],
      );
      if (validationError) {
        return [null, validationError];
      }
    }

    return [true, null];
  }

  validateValue(value, fileColumn) {
    const allowedColumn = this.allowedColumns.find(
      (allowedColumn) => allowedColumn.NAME === fileColumn,
    );

    switch (allowedColumn.DATA_TYPE) {
      case CSVValidator.DATA_TYPE.TEXT: {
        // Nothing to validate here.
        return [true, null];
      }

      case CSVValidator.DATA_TYPE.NUMBER: {
        if (!unitUtils.isValidNumber(Number(value))) {
          return [
            null,
            CSVValidator.VALIDATION_ERROR.INVALID_NUMBER + ' ' + value,
          ];
        }

        break;
      }

      case CSVValidator.DATA_TYPE.BOOLEAN: {
        if (value !== 'true' && value !== 'false') {
          return [
            null,
            CSVValidator.VALIDATION_ERROR.INVALID_BOOLEAN + ' ' + value,
          ];
        }

        break;
      }

      case CSVValidator.DATA_TYPE.UUID: {
        if (value && !uuidvalidate(value)) {
          return [
            null,
            CSVValidator.VALIDATION_ERROR.INVALID_UUID + ' ' + value,
          ];
        }

        break;
      }

      case CSVValidator.DATA_TYPE.LIST: {
        // Lists with a single value are don't contain the list separator anymore. Therefore, this check doesn't really make sense anymore.
        // if (!value.includes(CSVValidator.LIST_SEPARATOR)) return [null, CSVValidator.VALIDATION_ERROR.INVALID_LIST + ' ' + value];
        break;
      }

      case CSVValidator.DATA_TYPE.UUID_LIST:
      // Lists with a single value are don't contain the list separator anymore. Therefore, this check doesn't really make sense anymore.
      /* if (!value.includes(CSVValidator.LIST_SEPARATOR)) return [null, CSVValidator.VALIDATION_ERROR.INVALID_LIST + ' ' + value];
        const listValues = value.split(CSVValidator.LIST_SEPARATOR);
        if (listValues.some((listValue) => listValue && !uuidvalidate(listValue)))
          return [null, CSVValidator.VALIDATION_ERROR.INVALID_UUID_LIST + ' ' + value]; */
    }

    return [true, null];
  }

  // 'Value1,"Value2,with,commas",Value3'
  // Output: ['Value1', 'Value2,with,commas', 'Value3']
  static parseCSVRow(csvRow) {
    const values = [];
    let current = '';
    let insideQuotes = false;

    for (const char of csvRow) {
      if (char === '"') {
        insideQuotes = !insideQuotes;
      } else if (char === ',' && !insideQuotes) {
        values.push(current);
        current = '';
      } else {
        current += char;
      }
    }

    values.push(current); // Add the last value

    return values;
  }

  static COLUMN_SEPARATOR = ' § ';
  static LIST_SEPARATOR = ' | ';
  static DATA_TYPE = {
    BOOLEAN: 'boolean',
    LIST: 'list',
    NUMBER: 'number',
    TEXT: 'text',
    UUID: 'uuid',
    UUID_LIST: 'uuid_list',
  };
  static VALIDATION_ERROR = {
    INVALID_BOOLEAN: 'Ungültiger Boolean',
    INVALID_LIST: 'Liste enthält kein Tab ( | ) als Trennzeichen',
    INVALID_NUMBER: 'Ungültige Nummer',
    INVALID_UUID: 'Ungültige UUID',
    INVALID_UUID_LIST: 'Liste enthält ungültige UUIDs',
    TOO_MANY_VALUES: 'Ungültige Zeile mit mehr Einträgen als Spalten',
    UNKNOWN_COLUMN: 'Unbekannte Spalte',
  };
}
