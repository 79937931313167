import ObjectUtils from '~/utils/objectUtils';

import Attachment from './Attachment';

export default class DeliveryNoteAttachmentHandler {
  constructor() {
    this.attachments = [];
  }

  // Add the attachments from different places of the asset chain to the attachment handler.
  addAttachmentsFromChain(assetChain) {
    this.addGeneralAttachmentFromChain(assetChain);
    this.addGeneralAttachmentsFromChain(assetChain);
    this.addAcceptArticleAttachmentsFromChain_legacy(assetChain);
  }

  // The asset_chain.attachment contains the picture that has been uploaded in the "kein berechtigter vor Ort" feature.
  // Not sure why the feature uploads this to the generic asset_chain.attachment and not a more speaking prop, such as asset_chain.delivery_picture.
  addGeneralAttachmentFromChain(assetChain) {
    if (!assetChain.attachment) {
      return;
    }

    const attachment = new Attachment(
      assetChain.attachment.blob,
      assetChain._id,
      assetChain.attachment.comment,
    );

    this.attachments.push(attachment);
  }

  // The asset_chain._attachments contains all attachments that have been uploaded in this chain.
  // Those attachments are added to the attachment handler so that they are displayed in the dln meta data and dln history.
  addGeneralAttachmentsFromChain(assetChain) {
    if (!assetChain._attachments) {
      return;
    }

    for (const path of Object.keys(assetChain._attachments)) {
      const blob = assetChain._attachments[path];

      // If the chain contains a handwritten signature, we have to access meta/accept_event/attachments/*/type
      // to get the type of the signature.
      let pathArray = path.split('/');
      pathArray = pathArray.filter(
        (pathItem) => pathItem !== 'blob_' && pathItem !== 'blob',
      );
      const type = ObjectUtils.accessObjectRecursively(
        assetChain,
        pathArray,
      )?.type;

      const attachment = new Attachment(blob, assetChain._id, type ?? null);

      if (
        this.attachments.find(
          (existingAttachment) =>
            existingAttachment.digest === attachment.digest,
        )
      ) {
        continue;
      }

      this.attachments.push(attachment);
    }
  }

  // It is necessary to add attachments from the direct path of the accept articles, because not all attachments are reliably added to asset_chain._attachments.
  // For example, when adding a picture to an already declined article, the new picture is in the asset_chain.meta.accept_event.accepted_items but not in the asset_chain._attachments.
  // -> check d91b2e84-2391-47f6-8437-14de5e644b00 from polier-dev in PROD
  addAcceptArticleAttachmentsFromChain_legacy(assetChain) {
    if (assetChain.meta?.accept_event?.accepted_items)
      for (const accepted_item of assetChain.meta?.accept_event
        ?.accepted_items) {
        if (accepted_item.attachments)
          for (const chainAttachment of accepted_item.attachments) {
            const attachment = new Attachment(
              chainAttachment.blob,
              assetChain._id,
              chainAttachment.type,
            );

            if (
              this.attachments.find(
                (existingAttachment) =>
                  existingAttachment.digest === attachment.digest,
              )
            ) {
              continue;
            }

            this.attachments.push(attachment);
          }
      }
  }

  // The accept article attachments are directly loaded from the asset main to accept articles of the dln.
  // However, in this case there is no chain id for the attachments because it is unclear to which asset chain the attachment belongs.
  // Therefore, after the asset chain has been loaded and the attachment is loaded from the chain into the attachment,
  // populate the chain id from the attachment handler into the attachment of the accept articles.
  populateAcceptArticleChainIds(deliveryNote) {
    for (const article of deliveryNote.articles) {
      for (const acceptArticleAttachment of article.acceptArticleSupplier
        .attachments) {
        const attachment = deliveryNote.attachmentHandler.attachments.find(
          (attachment) => attachment.digest === acceptArticleAttachment.digest,
        );
        if (!attachment) {
          continue;
        }

        acceptArticleAttachment.chainId = attachment.chainId;
      }

      for (const acceptArticleAttachment of article.acceptArticleCarrier
        .attachments) {
        const attachment = deliveryNote.attachmentHandler.attachments.find(
          (attachment) => attachment.digest === acceptArticleAttachment.digest,
        );
        if (!attachment) {
          continue;
        }

        acceptArticleAttachment.chainId = attachment.chainId;
      }

      for (const acceptArticleAttachment of article.acceptArticleRecipient
        .attachments) {
        const attachment = deliveryNote.attachmentHandler.attachments.find(
          (attachment) => attachment.digest === acceptArticleAttachment.digest,
        );
        if (!attachment) {
          continue;
        }

        acceptArticleAttachment.chainId = attachment.chainId;
      }

      for (const acceptArticleAttachment of article
        .acceptArticleOnBehalfSupplier.attachments) {
        const attachment = deliveryNote.attachmentHandler.attachments.find(
          (attachment) => attachment.digest === acceptArticleAttachment.digest,
        );
        if (!attachment) {
          continue;
        }

        acceptArticleAttachment.chainId = attachment.chainId;
      }

      for (const acceptArticleAttachment of article.acceptArticleOnBehalfCarrier
        .attachments) {
        const attachment = deliveryNote.attachmentHandler.attachments.find(
          (attachment) => attachment.digest === acceptArticleAttachment.digest,
        );
        if (!attachment) {
          continue;
        }

        acceptArticleAttachment.chainId = attachment.chainId;
      }

      for (const acceptArticleAttachment of article
        .acceptArticleOnBehalfRecipient.attachments) {
        const attachment = deliveryNote.attachmentHandler.attachments.find(
          (attachment) => attachment.digest === acceptArticleAttachment.digest,
        );
        if (!attachment) {
          continue;
        }

        acceptArticleAttachment.chainId = attachment.chainId;
      }
    }
  }

  // Write the attachments in the respective delivery note actions so that they can be accessed more easily in the dln history.
  populateDeliveryNoteActions(deliveryNoteActions) {
    for (const deliveryNoteAction of deliveryNoteActions) {
      deliveryNoteAction.attachments = this.attachments.filter(
        (attachment) => attachment.chainId === deliveryNoteAction.id,
      );
    }
  }
}
