import { promiseHandler } from '~/utils/promiseHandler';
import AttachmentService from '~/services/attachment.service';

export default class Attachment {
  constructor(blob, chainId, type) {
    this.digest = blob?.digest ?? '';
    this.contentType = blob?.content_type ?? '';
    this.chainId = chainId ?? '';
    this.content = null; // cached content so that DeliveriesService.getAttachment doesn't have to be called every time
    this.type = type ?? '';
  }

  async getContent() {
    if (this.content) {
      return this.content;
    }

    if (this.type === Attachment.TYPE.SIGNATURE) {
      const [response, error] = await promiseHandler(
        AttachmentService.getHandwrittenSignature(this.chainId),
      );

      if (error) {
        throw error;
      }

      this.content = response;
      return this.content;
    }

    const [response2, error2] = await promiseHandler(
      AttachmentService.getAttachment(this.chainId, this.digest),
    );

    if (error2) {
      throw error2;
    }

    this.content = response2;
    return this.content;
  }

  isDeliveryPicture() {
    return this.type === Attachment.TYPE.DELIVERY_PICTURE;
  }

  static TYPE = {
    DELIVERY: 'delivery', // for accept articles
    DELIVERY_PICTURE: 'delivery_picture', // for "kein berechtigter vor Ort" feature
    SIGNATURE: 'signature', //
  };
  static CONTENT_TYPE = {
    JPEG: 'image/jpeg',
    JSON: 'application/json',
    PDF: 'application/octet-stream',
    PNG: 'image/png',
    SVG: 'image/svg+xml',
  };
}
