import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  fetchUserGroup,
  fetchUserGroups,
  queryKeysUserGroup,
} from '~/data/userGroup';

import UserUtils from '~/utils/userUtils';

import { type Option, SelectServerDriven } from './SelectServerDriven';
import { createLoadOptions, createGetItemData } from './utils';

const getOptionLabel = ({ name }) => name;

// Define a UserGroup type based on the expected structure; TODO: this should come as response type from the request.
type UserGroup = {
  id: string;
  name: string;
};

type P = {
  className?: string;
  formatOptionLabel?: (option: Option<UserGroup>) => React.ReactNode;
  isDisabled?: boolean;
  isMultiSelect?: boolean;
  onChange: (value: Array<{ id: string }>) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  value: string[];
};

export const MultiSelectUserGroups = ({
  className,
  formatOptionLabel,
  isDisabled = false,
  isMultiSelect = true,
  onChange,
  placeholder = 'Benutzer-Gruppen',
  style,
  value,
}: P) => {
  const queryClient = useQueryClient();

  /**
   * Loads the options for the multi-select dropdown.
   * @param {string} searchString - The search string entered by the user.
   * @param {unknown} loadedOptions - The already loaded options.
   * @param {{ page: number }} - The current page number.
   */
  const loadOptions = createLoadOptions<UserGroup>({
    createQueryKey: ({ page, ...queryParams }) =>
      queryKeysUserGroup.getAll(queryParams),
    async fetchItems({ page, ...queryParams }) {
      const { data, hasNextPage } = await fetchUserGroups(queryParams);

      return {
        data,
        hasNextPage,
      };
    },
    getOptionLabel,
    queryClient,
  });

  /**
   * Gets user group data for a user group with a given userGroupId.
   * Attempts to get it from the query cache and fetches it from the API
   * if the user group data is not in the cache.
   * @param {string} userGroupId - The ID of the user group to get.
   */
  const getItemData = createGetItemData<UserGroup>({
    createQueryKey: (userGroupId) => queryKeysUserGroup.get(userGroupId),
    fetchItem: fetchUserGroup,
    queryClient,
    queryKeyBase: [queryKeysUserGroup.base()],
  });

  // This is just a glue function b/c PermissionGrantMultiPicker currently expects to pick the ID from an object in its onChange function. Should be refactored.
  const handleChange = useCallback(
    (newValues: string[]) => {
      onChange(newValues.map((id) => ({ id })));
    },
    [onChange],
  );

  return (
    <SelectServerDriven<UserGroup>
      value={value}
      onChange={handleChange}
      className={className}
      formatOptionLabel={formatOptionLabel}
      getItemData={getItemData}
      getOptionLabel={getOptionLabel}
      isDisabled={isDisabled}
      isItemSelectionAllowed={UserUtils.isUserGroupReadAllowedUser}
      isMultiSelect={isMultiSelect}
      loadOptions={loadOptions}
      placeholder={placeholder}
      style={style}
    />
  );
};
