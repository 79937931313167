import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysVehicle } from './queryKeys';

type VehicleData = Record<string, unknown>;

type MutationVehicleArgument = {
  vehicleId?: UUID;
  vehicleData: VehicleData;
};

/**
 * Update an existing vehicle.
 *
 * @param {string} vehicleId - The ID of the vehicle to update.
 * @param {Object} vehicleData - An object containing the vehicle data.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Vehicle/operation/create_or_update_vehicle_vehicle__id__put
 */
export const updateVehicle = async (
  vehicleId: UUID,
  vehicleData: VehicleData,
) => {
  try {
    const response = await vestigasApi
      .put(ENDPOINT.VEHICLE.UPDATE(vehicleId), {
        json: vehicleData,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating vehicle settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating an existing vehicle.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */

export const useMutationUpdateVehicle = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ vehicleId, vehicleData }: MutationVehicleArgument) =>
      updateVehicle(vehicleId, vehicleData),
    async onError(error) {
      Log.productAnalyticsEvent(
        'Failed to update vehicle',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess(data, { vehicleId }) {
      if (vehicleId) {
        queryClient.invalidateQueries({
          queryKey: queryKeysVehicle.get(vehicleId),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeysVehicle.getAll({}),
        });
      }
    },
    ...options,
  });
};
