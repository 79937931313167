import { useMutation } from '@tanstack/react-query';

import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';

import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';

import { addToUserGroup } from './useMutationAddToUserGroup';
import { deleteFromUserGroup } from './useMutationDeleteFromUserGroup';

/**
 * Type of user group update operation.
 * - updateParentUserGroups: Add the current user group to / remove it from a list of user groups.
 * - updateUserGroupEntities: Add a list of entities to / remove it from the current user group.
 */
type UpdateType = 'updateParentUserGroups' | 'updateUserGroupEntities';

type UpdateUserGroupsParams = {
  addedMembers: UUID[];
  deletedMembers: UUID[];
  memberType: Pick<EntityType, 'user' | 'user_group'>;
  updateType: UpdateType;
  userGroupId: UUID;
};

/**
 * Updates the user group memberships for an entity by making API calls to add and remove members.
 *
 * @param {UpdateUserGroupsParams} params - Parameters for the update operation
 * @throws {Error} If the API calls fail
 * @returns {Promise<void>}
 */
const updateUserGroups = async ({
  addedMembers,
  deletedMembers,
  memberType,
  updateType = 'updateUserGroupEntities',
  userGroupId,
}: UpdateUserGroupsParams): Promise<void> => {
  try {
    let promisesAdd: Array<Promise<void>> = [];
    let promisesDelete: Array<Promise<void>> = [];

    if (updateType === 'updateParentUserGroups') {
      promisesAdd = addedMembers.map(async (parentUserGroupId) =>
        addToUserGroup(parentUserGroupId, memberType, userGroupId),
      );
      promisesDelete = deletedMembers.map(async (parentUserGroupId) =>
        deleteFromUserGroup(parentUserGroupId, memberType, userGroupId),
      );
    } else {
      promisesAdd = addedMembers.map(async (memberId) =>
        addToUserGroup(userGroupId, memberType, memberId),
      );
      promisesDelete = deletedMembers.map(async (memberId) =>
        deleteFromUserGroup(userGroupId, memberType, memberId),
      );
    }

    await Promise.all([...promisesAdd, ...promisesDelete]);
  } catch (error) {
    Log.error('Error updating user groups', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for managing user group updates using react-query mutations.
 *
 * @param {Parameters<typeof useMutation>[0]} [options] - Optional react-query mutation options
 * @returns {UseMutationResult} Mutation object for handling the update operation
 *
 * @example
 * ```tsx
 * const mutation = useMutationUpdateUserGroups();
 *
 * const handleUpdate = () => {
 *   mutation.mutate({
 *     addedMembers: ['user1', 'user2'],
 *     deletedMembers: ['user3'],
 *     memberType: 'user',
 *     updateType: 'updateUserGroupEntities',
 *     userGroupId: '123',
 *   });
 * };
 * ```
 */
export const useMutationUpdateUserGroups = (
  options?: Parameters<typeof useMutation>[0],
) =>
  useMutation({
    mutationFn: updateUserGroups,
    onError(error: unknown) {
      Log.productAnalyticsEvent(
        'Failed to update user groups',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.ERROR,
      );

      ToastService.httpError(['Failed to update user groups'], error);
    },
    ...options,
  });
