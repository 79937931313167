import { useQuery } from '@tanstack/react-query';

import CompanyService from '~/services/company.service';

import { queryKeysCompany } from './queryKeys';

// This query uses an deprecated endpoint for fetching ALL companies ever, which is not technically possible for large accounts.
export const useQueryCompaniesLegacy = (options) =>
  useQuery({
    queryFn: CompanyService.getAllCompanies,
    queryKey: queryKeysCompany.getAllLegacy(),
    ...options,
  });
