import PushService from '~/services/push.service';
import ToastService from '~/services/toast.service';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import { type UUID } from '~/types/common';

/**
 * Request signatures for the selected delivery notes and selected users and handle the response.
 *
 * @param deliveryNoteIds - The IDs of the delivery notes.
 * @param selectedUserIds - The IDs of the users.
 * @throws Error if the request fails
 */
export const handleRequestSignatures = async (
  deliveryNoteIds: UUID[],
  selectedUserIds: UUID[],
): Promise<void> => {
  const [, error] = await promiseHandler(
    PushService.requestSignature(deliveryNoteIds, selectedUserIds),
  );

  if (error) {
    Log.error('Failed to send push to request delivery note signature.', error);
    Log.productAnalyticsEvent(
      'Failed to request signatures',
      Log.FEATURE.NOTIFICATIONS,
      Log.TYPE.ERROR,
    );

    ToastService.httpError(
      [
        'Signaturen konnten nicht angefordert werden wegen eines internen Fehlers.',
      ],
      error.response,
    );

    throw new Error('Failed to request signatures');
  }

  ToastService.success([
    'Die Signaturen wurden angefordert.',
    'Es wurde eine Benachrichtigung an die ausgewählten Benutzer geschickt.',
  ]);
};
