import clsx from 'clsx';
import { type ForwardedRef, forwardRef } from 'react';

import { Button } from '@mui/material';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { FreeTextSearch } from '~/components/baseComponents/inputs/FreeTextSearch';

type P = {
  hasChanges: boolean;
  isEditing: boolean;
  onAdd: (itemId: UUID) => void;
  onCancel: () => void;
  onEdit: () => void;
  onFocus: () => void;
  onSave: () => void;
  searchString: string;
  setSearchString: (value: string) => void;
} & ComponentStyling;

export const ListActions = forwardRef(
  (
    {
      className,
      hasChanges,
      isEditing,
      onCancel,
      onEdit,
      onFocus,
      onSave,
      searchString,
      setSearchString,
      style,
    }: P,
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <div
      className={clsx('flex w-full items-end gap-2 border-b p-2', className)}
      style={style}
    >
      <FreeTextSearch
        ref={ref}
        value={searchString}
        onChange={setSearchString}
        onFocus={onFocus}
        debounceTime={200}
        productAnalyticsFeature={Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER}
      />
      <div className="flex flex-initial gap-2">
        {isEditing ? (
          <div className="flex items-center space-x-2">
            <Button
              variant="contained"
              onClick={onSave}
              disabled={!hasChanges}
              className="h-10"
            >
              Speichern
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={onCancel}
              className="h-10"
            >
              Abbrechen
            </Button>
          </div>
        ) : (
          <Button
            onMouseDown={onEdit}
            variant="outlined"
            size="medium"
            className="h-10"
          >
            Bearbeiten
          </Button>
        )}
      </div>
    </div>
  ),
);
