import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';

import DashboardService from '~/services/dashboard.service';
import FeatureService from '~/services/feature.service';

import { saveArticleColorMapping } from '~/redux/userinfoSlice';

import ColorUtils from '~/utils/colorUtils';
import UnitUtils from '~/utils/unitUtils';
import { endOfDay, startOfDay } from '~/utils/dateUtils';

import { withErrorBoundary } from '~/ui/atoms';

import ClientPortalMessage from '../salesPackages/clientPortal/clientPortalMessage';
import { PackageBasicRestrictionMessage } from '../salesPackages/packageBasicRestriction/packageBasicRestrictionMessage';

const mapStateToProps = (state) => ({
  articleColorMapping: state.userinfo.articleColorMapping,
  articleColorMappingVersion: state.userinfo.articleColorMappingVersion,
  // subscribe to companyAccount state so that update of clientPortal feature flag leads to rerender
  companyAccount: state.companyAccount,
});
const mapDispatchToProps = () => ({
  saveArticleColorMapping,
});

class DashboardChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      options: null,
    };

    this.aggregateBy = DashboardService.getAggregationFromDateRange(
      this.props.selectedDateRange,
    );
  }

  componentDidMount() {
    this.init();
  }

  // Memoization so that component is only rendered when the input data has been changed. Heavily improves performance.
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.isArchiveMode !== this.props.isArchiveMode) {
      return true;
    }

    const isNewColorMappingVersion =
      nextProps.articleColorMappingVersion !==
      this.props.articleColorMappingVersion;

    if (isNewColorMappingVersion) {
      return true;
    }

    const isNewData =
      JSON.stringify(nextState.chartData) !==
      JSON.stringify(this.state.chartData);

    if (isNewData) {
      return true;
    }

    const isNewOptions =
      JSON.stringify(nextState.options) !== JSON.stringify(this.state.options);

    if (isNewOptions) {
      return true;
    }

    const isNewDateRange =
      JSON.stringify(nextProps.selectedDateRange) !==
      JSON.stringify(this.props.selectedDateRange);

    if (isNewDateRange) {
      return true;
    }

    const isNewArchiveVersion =
      nextProps.archiveAnalyticsDataVersion !==
      this.props.archiveAnalyticsDataVersion;

    const isNewDataVersion = nextProps.dataVersion !== this.props.dataVersion;

    return nextProps.isArchiveMode ? isNewArchiveVersion : isNewDataVersion;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newChartData = cloneDeep(this.state.chartData);
    let chartDataUpdated = false;

    if (this.props.dataVersion !== prevProps.dataVersion) {
      this.aggregateBy = DashboardService.getAggregationFromDateRange(
        this.props.selectedDateRange,
      );

      newChartData = DashboardService.createBarChartDatasets(
        this.props.data,
        this.aggregateBy,
      );

      chartDataUpdated = true;
    } else if (
      this.props.archiveAnalyticsDataVersion !==
      prevProps.archiveAnalyticsDataVersion
    ) {
      this.aggregateBy = DashboardService.getAggregationFromDateRange(
        this.props.selectedDateRange,
      );

      newChartData = DashboardService.createBarChartDatasetsFromAnalyticsData(
        this.props.archiveAnalyticsData,
        this.props.selectedDateRange,
      );

      chartDataUpdated = true;
    }

    if (
      chartDataUpdated ||
      this.props.articleColorMappingVersion !==
        prevProps.articleColorMappingVersion
    ) {
      this.assignColors(newChartData);
    }

    this.setState({
      chartData: newChartData,
    });

    const isNewDateRange =
      JSON.stringify(this.props.selectedDateRange) !==
      JSON.stringify(prevProps.selectedDateRange);

    if (isNewDateRange) {
      this.aggregateBy = DashboardService.getAggregationFromDateRange(
        this.props.selectedDateRange,
      );

      this.setState({
        options: this.getTimeFrameForDateRange(),
      });
    }
  }

  init() {
    this.aggregateBy = DashboardService.getAggregationFromDateRange(
      this.props.selectedDateRange,
    );

    let newChartData = [];

    if (this.props.isArchiveMode) {
      newChartData = DashboardService.createBarChartDatasetsFromAnalyticsData(
        this.props.archiveAnalyticsData,
        this.props.selectedDateRange,
      );
    } else {
      newChartData = DashboardService.createBarChartDatasets(
        this.props.data,
        this.aggregateBy,
      );
    }

    this.assignColors(newChartData);

    this.setState({
      chartData: newChartData,
      options: this.getTimeFrameForDateRange(),
    });
  }

  assignColors(chartData) {
    const newArticleColorMapping = [...this.props.articleColorMapping];

    for (const [index, data] of chartData.entries()) {
      let color = newArticleColorMapping.find(
        ({ article }) => article === data.label,
      )?.color;

      if (!color) {
        color = ColorUtils.getRandomDashboardColor();
        newArticleColorMapping.push({
          article: data.label,
          color,
        });
      }

      chartData[index].backgroundColor = color;
    }

    if (newArticleColorMapping.length > this.props.articleColorMapping.length) {
      this.props.saveArticleColorMapping({
        mapping: newArticleColorMapping,
        saveToBackend: true,
      });
    }
  }

  getTimeFrameForDateRange() {
    const newOptions = this.getOptions();
    newOptions.scales.x.time.unit = this.aggregateBy;

    newOptions.scales.x.min = startOfDay(this.props.selectedDateRange[0]);
    newOptions.scales.x.max = endOfDay(this.props.selectedDateRange[1]);

    return newOptions;
  }

  getOptions() {
    return {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(context) {
              if (!(context.parsed.y > 0)) {
                return;
              }

              const label = context.dataset.label;
              const formattedValue =
                UnitUtils.roundAndFormatDe_safe(context.parsed.y) ?? '';

              return [label, formattedValue].join(': ');
            },
            title: this.formatTitle,
          },
          itemSort(contextA, contextB) {
            return contextB.parsed.y - contextA.parsed.y;
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          stacked: true,
          ticks: {
            callback: this.formatXAxisLabel,
          },
          time: {
            unit: this.aggregateBy,
            unitStepSize: 1,
          },
          type: 'time',
        },
        yAxisID: {
          ticks: {
            callback: (value, index, values) => {
              return (
                UnitUtils.formatDe_safe(value) +
                ' ' +
                UnitUtils.getAbbreviatedUnitString(this.props.selectedUnit)
              );
            },
          },
        },
      },
    };
  }

  formatTitle = (title) => {
    const aggregation = Object.keys(DashboardService.DATA_AGGREGATION).find(
      (x) =>
        DashboardService.DATA_AGGREGATION[x].AGGREGATE_BY === this.aggregateBy,
    );
    if (aggregation) {
      return DashboardService.DATA_AGGREGATION[aggregation].TOOLTIP_TITLE(
        title[0].label,
      );
    }
  };
  formatXAxisLabel = (label) => {
    const formatXAxisCallback = DashboardService.getXAxisFormatter(
      this.aggregateBy,
      DashboardService.DATA_AGGREGATION,
    );

    return formatXAxisCallback(label);
  };

  render() {
    if (this.state.chartData.length === 0) {
      return null;
    }

    const clientPortalOverlay = FeatureService.clientPortal() ? (
      <div className="flex-c-c absolute h-full w-full blur">
        <div className="box-shadow-blue rounded-5px p-20px bg-white">
          <ClientPortalMessage />
        </div>
      </div>
    ) : null;

    const packageBasicRestrictionOverlay =
      FeatureService.packageBasicRestriction() ? (
        <div className="flex-c-c absolute h-full w-full blur">
          <div className="box-shadow-blue rounded-5px p-20px bg-white">
            <PackageBasicRestrictionMessage />
          </div>
        </div>
      ) : null;

    return (
      <div className="min-h-400px h-40vh relative">
        {clientPortalOverlay}
        {packageBasicRestrictionOverlay}
        <Bar
          data={{ datasets: cloneDeep(this.state.chartData) }}
          options={cloneDeep(this.state.options)}
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(DashboardChart),
  'Zeitlicher Verlauf konnte nicht geladen werden.',
);
