export default class FilterContext {
  constructor(filterContext) {
    this.page = filterContext?.page ?? null;
    this.component = filterContext?.component ?? null;
    this.isCustomField = filterContext?.isCustomField ?? false;
  }

  static PAGE = {
    DASHBOARD: 'dashboard',
    DELIVERY: 'delivery',
    // The entire UserMetrics feature can not be implemented with the current paginated API endpoints.
    // Also, nobody uses it.
    // USER_METRICS: 'userMetrics',
    HOME: 'home',
  };
  static COMPONENT = {
    FILTER_GROUP: 'filterGroup',
    FILTER_MODEL: 'filterModel',
    QUERY: 'query',
  };
}
