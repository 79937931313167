import isObject from 'lodash/isObject';
import mergeWith from 'lodash/mergeWith';

import AcceptStateCalculator from '../../acceptState/AcceptStateCalculator';
import Company from '../../masterdata/Company';
import Article from '../../articles/Article';
import UnitUtils from '~/utils/unitUtils';
import store from '~/redux/store';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import Site from '../../masterdata/Site';
import SiteService from '~/services/site.service';
import { promiseHandler } from '~/utils/promiseHandler';
import { saveUserCompany } from '~/redux/userinfoSlice';
import UserService from '~/services/user.service';
import { validate as uuidvalidate } from 'uuid';

import { convertAssetChainToMain, updateAssetMain } from '~/utils/assetUtils';

import Value from '../Value';
import ValueGroup from '../ValueGroup';
import DeliveryNoteParser from '~/parser/DeliveryNoteParser';
import CostCenter from '../../masterdata/CostCenter';
import CostCenterService from '~/services/costCenter.service';

import DeliveryNoteAttachmentHandler from '../DeliveryNoteAttachmentHandler';
import CustomData from '../../customData/CustomData';
import CustomFieldService from '~/services/customField.service';
import CustomField from '../../customData/CustomField';
import cloneDeep from 'lodash/cloneDeep';
import TradeContact from '../../masterdata/TradeContact';
import PromiseUtils from '~/utils/promiseUtils';
import ArrayUtils from '~/utils/arrayUtils';
import User from '../../masterdata/User';
import ObjectUtils from '~/utils/objectUtils';
import BilledItem from '../../billingState/BilledItem';
import ShippingMark from '../ShippingMark';
import FilterProps from '../../filters/FilterProps';
import { CHANGES_LIST_INCLUDED_PATHS } from '../constants/changes-list-included-paths';

import { calculateTotalWeight } from './utils';

export default class DeliveryNote {
  constructor(assetMain) {
    DeliveryNoteParser.parseAssetMain(assetMain);

    this.id = assetMain?._id ?? '';
    this.number = assetMain?.asset_state?.body?.header?.id ?? '';
    this.dlnDate = assetMain?.asset_state?.body?.header?.date ?? '';
    this.creationDate = assetMain?.created_on ?? '';
    this.deliveryDate =
      assetMain?.ts_arrived ??
      assetMain?.ts_delivered ??
      assetMain?.created_on ??
      '';
    this.modifiedDate = assetMain?.modified_on ?? '';

    this.sellerOrderReferences = this.getSellerOrderReferences(assetMain);
    this.buyerOrderReferences = this.getBuyerOrderReferences(assetMain);
    this.constructionPlans = this.getConstructionPlans(assetMain);
    this.constructionComponents = this.getConstructionComponents(assetMain);
    this.shippingMarks =
      assetMain?.asset_state?.body?.transaction?.delivery?.shipping_marks?.map(
        (shippingMark) => new ShippingMark(shippingMark),
      );
    this.deliveryType = this.computeDeliveryType_safe(
      assetMain?.asset_state?.body?.transaction?.agreement?.delivery_terms
        ?.code,
    );
    this.fromSite = new Site({
      address:
        assetMain?.asset_state?.body?.transaction?.delivery?.ship_from
          ?.trade_address,
      issuerAssignedId:
        assetMain?.asset_state?.body?.transaction?.delivery?.ship_from
          ?.trade_address?.issuer_assigned_id ?? null,
      name:
        assetMain?.asset_state?.body?.transaction?.delivery?.ship_from
          ?.trade_address?.line_one ?? '',
      tradeContact:
        assetMain?.asset_state?.body?.transaction?.delivery?.ship_from
          ?.trade_contact,
    });
    this.toSiteSupplier = new Site({
      address:
        assetMain?.asset_state?.body?.transaction?.delivery?.ship_to
          ?.trade_address,
      id: assetMain?.asset_state?.body?.transaction?.delivery?.ship_to
        ?.trade_address?.id,
      name:
        assetMain?.asset_state?.body?.transaction?.delivery?.ship_to
          ?.trade_address?.line_one ?? '',
      tradeContact:
        assetMain?.asset_state?.body?.transaction?.delivery?.ship_to
          ?.trade_contact,
    });
    this.toSiteRecipient = this.getToSiteRecipient(assetMain);
    this.permittedToSites = this.getPermittedToSites(assetMain);
    this.permittedCostCenters = this.getPermittedCostCenters(assetMain);
    this.permittedUsers = [];
    this.movementMeans =
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from
        ?.consignment?.movement?.means ?? '';
    this.carrierLicensePlate =
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from?.consignment?.movement?.registration_id
        ?.map((registration) => registration.id)
        ?.join(', ') ?? '';
    this.carrierVehicleNumber =
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from?.consignment?.movement?.registration_id
        ?.map((registration) => registration.issuer_assigned_id)
        ?.join(', ') ?? '';
    this.driver = new TradeContact(
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from?.consignment?.movement?.driver,
    );
    this.seller = new Company(
      assetMain?.asset_state?.body?.transaction?.agreement?.seller?.legal_organization,
      assetMain?.asset_state?.body?.transaction?.agreement?.seller?.trade_contact,
    );
    this.buyer = new Company(
      assetMain?.asset_state?.body?.transaction?.agreement?.buyer?.legal_organization,
      assetMain?.asset_state?.body?.transaction?.agreement?.buyer?.trade_contact,
    );
    this.buyerId =
      assetMain?.asset_state?.body?.transaction?.settlement?.sales_account
        ?.name ?? '';
    this.project =
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_to
        ?.trade_address?.issuer_assigned_id ?? null;
    this.customData = new CustomData(
      assetMain?.asset_state?.body?.header?.additional_party_data,
    );
    this.carrierId =
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from
        ?.consignment?.carrier?.legal_organization?.issuer_assigned_id ?? '';

    this.issuer = new Company(
      assetMain?.asset_state?.body?.header?.issuer?.legal_organization,
      assetMain?.asset_state?.body?.header?.issuer?.trade_contact,
    );
    this.recipient = new Company(
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_to?.legal_organization,
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_to?.trade_contact,
    );
    this.carrier = new Company(
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from?.consignment?.carrier?.legal_organization,
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from?.consignment?.carrier?.trade_contact,
    );
    this.supplier = new Company(
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from?.legal_organization,
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from?.trade_contact,
    );
    this.trader = new Company(
      assetMain?.asset_state?.body?.transaction?.agreement?.trader?.legal_organization,
      assetMain?.asset_state?.body?.transaction?.agreement?.trader?.trade_contact,
    );
    this.freightForwarder = new Company(
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from?.consignment?.movement?.freight_forwarder?.legal_organization,
      assetMain?.asset_state?.body?.transaction?.delivery?.ship_from?.consignment?.movement?.freight_forwarder?.trade_contact,
    );

    this.processRolesOfUserCompany = this.getProcessRolesOfUserCompany();
    this.processCategory = this.getProcessCategory();

    this.articles = this.getArticles(assetMain);
    this.deliveredArticles = this.articles.filter(
      (article) => article.amount.value >= 0,
    );
    this.returnedArticles = this.articles.filter(
      (article) => article.amount.value < 0,
    );
    for (const returnedArticle of this.returnedArticles)
      returnedArticle.revertSign();
    this.mainArticle = this.getMainArticle(this.articles);

    this.totalWeightDeliveredArticles = calculateTotalWeight(
      this.deliveredArticles,
      'weight',
    );
    this.totalWeightReturnedArticles = calculateTotalWeight(
      this.returnedArticles,
      'weight',
    );
    this.totalWeightNet = calculateTotalWeight(this.articles, 'weight');
    this.totalWeightGross = calculateTotalWeight(
      this.articles,
      'weighingInformation.gross.weight',
    );
    this.totalWeightTare = calculateTotalWeight(
      this.articles,
      'weighingInformation.tare.weight',
    );

    this.deliveryDescription = this.getDeliveryDescription();

    this.processState = this.calculateProcessState(assetMain);
    this.acceptStateSupplier =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleSupplier.acceptState,
        ),
      );
    this.acceptStateCarrier =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleCarrier.acceptState,
        ),
      );
    this.acceptStateRecipient =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleRecipient.acceptState,
        ),
      );
    this.acceptStateOnBehalfSupplier =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleOnBehalfSupplier.acceptState,
        ),
      );
    this.acceptStateOnBehalfCarrier =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleOnBehalfCarrier.acceptState,
        ),
      );
    this.acceptStateOnBehalfRecipient =
      AcceptStateCalculator.calculateOverallAcceptStateFromArticles(
        this.articles.map(
          (article) => article.acceptArticleOnBehalfRecipient.acceptState,
        ),
      );
    this.acceptState =
      AcceptStateCalculator.calculateOverallAcceptStateFromParties(
        this.acceptStateSupplier,
        this.acceptStateCarrier,
        this.acceptStateRecipient,
        this.acceptStateOnBehalfSupplier,
        this.acceptStateOnBehalfCarrier,
        this.acceptStateOnBehalfRecipient,
      );
    this.referencedInvoices = ArrayUtils.removeDuplicates(
      this.articles.flatMap((article) => article.billedItem.invoiceIds),
    );
    this.settledStatus = BilledItem.calculateCombinedSettledStatus(
      this.articles.map((article) => article.billedItem.settledStatus),
    );
    this.combinedState = this.calculateCombinedState();
    this.costCenters = ArrayUtils.removeDuplicates(
      this.articles.map((article) => article.costCenter).filter(Boolean),
    );

    this.execution = {
      arrived:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution?.arrived,
      arrivedPlanned:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.arrived_planned,
      beginExecution:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.begin_execution,
      beginExecutionPlanned:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.begin_execution_planned,
      beginLoading:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.begin_loading,
      beginLoadingPlanned:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.begin_loading_planned,
      beginUnloading:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.begin_unloading,
      beginUnloadingPlanned:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.begin_unloading_planned,
      departure:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.departure,
      departurePlanned:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.departure_planned,
      endExecution:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.end_execution,
      endExecutionPlanned:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.end_execution_planned,
      endLoading:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.end_loading,
      endLoadingPlanned:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.end_loading_planned,
      endUnloading:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.end_unloading,
      endUnloadingPlanned:
        assetMain?.asset_state?.body?.transaction?.delivery?.execution
          ?.end_unloading_planned,
    };

    this.comments = [];
    this.waitingTime = 0;
    this.regieTime = 0;
    this.palletAmount = 0;
    this.assignNotes(assetMain);

    // The user that is responsible for the change of the process state.
    this.processStateChangeUser = {
      arrived: new User({ id: assetMain?.us_arrived?.split('_')?.pop() }),
      cancelled: new User({ id: assetMain?.us_cancelled?.split('_')?.pop() }),
      delivered: new User({ id: assetMain?.us_delivered?.split('_')?.pop() }),
      inTransport: new User({
        id: assetMain?.us_inTransport?.split('_')?.pop(),
      }),
      readyForOutput: new User({
        id: assetMain?.us_readyForOutput?.split('_')?.pop(),
      }),
    };

    this.assetChain = assetMain?.asset_chain ?? [];
    this.history = {};
    this.recalculatedMain = null;
    this.changes = [];
    this.changesListIncludedPaths = cloneDeep(CHANGES_LIST_INCLUDED_PATHS);

    this.attachmentHandler = new DeliveryNoteAttachmentHandler();

    this.userActions = {
      requestSignatures: [],
      shareDeliveryNote: [],
    };

    this.asyncInitiated = false;

    this.fixParserErrors();
  }

  // execute all async processes in parallel that are needed to fill the delivery note data
  async asyncInit() {
    // Don't run asyncInit multiple times as it can lead to weird bugs such as custom fields being displayed multiple time in the dln history.
    // For example, asyncInit is executed during dln bulk load and when loading dln in dln detail view.
    if (this.asyncInitiated) {
      return;
    }

    this.asyncInitiated = true;

    const processCategoryPromise = this.updateProcessCategory();
    const toSiteRecipientPromise = this.initToSiteRecipient();
    const permittedToSitesPromise = this.initPermittedToSites();
    const permittedCostCentersPromise = this.initPermittedCostCenters();
    const customDataDeliveryNotePromise = this.initCustomDataDeliveryNote();
    const customDataArticlePromise = this.initCustomDataArticles();
    const processStateChangeUserPromise = this.initProcessStateChangeUser();

    return PromiseUtils.allResolved([
      processCategoryPromise,
      toSiteRecipientPromise,
      permittedToSitesPromise,
      permittedCostCentersPromise,
      customDataDeliveryNotePromise,
      customDataArticlePromise,
      processStateChangeUserPromise,
    ]);
  }

  // execute all async processes in parallel that are needed to fill the delivery note data
  async asyncInitWithBulkEntities() {
    // Don't run asyncInit multiple times as it can lead to weird bugs such as custom fields being displayed multiple time in the dln history.
    // For example, asyncInit is executed during dln bulk load and when loading dln in dln detail view.
    if (this.asyncInitiated) {
      return;
    }

    this.asyncInitiated = true;

    const processCategoryPromise = this.updateProcessCategory();
    const toSiteRecipientPromise = this.initToSiteRecipientWithBulkSites();
    const permittedToSitesPromise = this.initPermittedToSitesWithBulkSites();
    const permittedCostCentersPromise =
      this.initPermittedCostCentersWithBulkCostCenters();
    const customDataDeliveryNotePromise = this.initCustomDataDeliveryNote();
    const customDataArticlePromise = this.initCustomDataArticles();
    const processStateChangeUserPromise =
      this.initProcessStateChangeUserWithBulkUsers();

    return PromiseUtils.allResolved([
      processCategoryPromise,
      toSiteRecipientPromise,
      permittedToSitesPromise,
      permittedCostCentersPromise,
      customDataDeliveryNotePromise,
      customDataArticlePromise,
      processStateChangeUserPromise,
    ]);
  }

  assignNotes(assetMain) {
    if (assetMain?.asset_state?.body?.transaction?.delivery?.note)
      for (const note of assetMain?.asset_state?.body?.transaction?.delivery
        ?.note) {
        if (note.type === 'comment_note') {
          this.comments.push(note.note?.content?.comment);
        }

        if (note.type === 'waiting_time_note') {
          this.waitingTime = note.note?.content?.time;
        }

        if (note.type === 'regie_time_note') {
          this.regieTime = note.note?.content?.time;
        }

        if (note.type === 'amount_pallet_note') {
          this.palletAmount = note.note?.content?.amount;
        }
      }
  }

  getArticles(assetMain) {
    if (!assetMain) {
      return [];
    }

    const articles = [];

    if (assetMain?.asset_state?.body?.transaction?.logistics_package)
      for (const [
        index,
        log_package,
      ] of assetMain?.asset_state?.body?.transaction?.logistics_package.entries()) {
        if (log_package?.line_item)
          for (const [index_, line_item] of log_package?.line_item.entries()) {
            if (!line_item) {
              continue;
            }

            const article = new Article(
              assetMain?.asset_state?.body?.transaction?.logistics_package[
                index
              ]?.line_item[index_],
              log_package.id,
              index,
              index_,
              assetMain.accept_states?.accept_items,
              assetMain.billed_items,
            );

            articles.push(article);
          }
      }

    return articles;
  }

  // the main item is displayed to the user as item in the dln list.
  // it is determined by the biggest weight or whether it is from type concrete
  getMainArticle(articles) {
    if (articles.length === 0) {
      return new Article();
    }

    // prioritize item that is from type concrete
    const concreteItem = articles.find((article) => article.isConcrete());
    if (concreteItem) {
      return concreteItem;
    }

    // return item with the biggest weight
    let mainIndex = 0;
    let mainWeight;
    let mainUnit;

    for (let index = 0; index < articles?.length; index++) {
      if (!articles[index].weight?.value || !articles[index].weight?.unit) {
        continue;
      }

      if (!mainWeight || !mainUnit) {
        mainIndex = index;
        mainWeight = articles[index]?.weight?.value;
        mainUnit = articles[index]?.weight?.unit;
      }

      const articleWeight = UnitUtils.calculateWeightInTargetUnit(
        articles[index].weight?.value,
        articles[index].weight?.unit,
        mainUnit,
      );

      if (articleWeight > mainWeight) {
        mainIndex = index;
        mainWeight = articleWeight;
      }
    }

    return articles[mainIndex];
  }

  // format the delivery description (e.g. +3 weitere Positionen   |   Gesamtgewicht: 728,8kg)
  getDeliveryDescription() {
    // Delivered articles
    let deliveredArticlesCount = '';
    if (this.deliveredArticles.length > 2) {
      const furtherItems = this.deliveredArticles.length - 1;
      deliveredArticlesCount = '+' + furtherItems + ' weitere Positionen';
    } else if (this.deliveredArticles.length > 1) {
      deliveredArticlesCount = '+1 weitere Position';
    }

    let totalWeightDeliveredArticles = this.totalWeightDeliveredArticles;
    if (totalWeightDeliveredArticles && this.deliveredArticles.length > 1) {
      totalWeightDeliveredArticles =
        'Gesamtgewicht: ' + totalWeightDeliveredArticles;
    } else {
      totalWeightDeliveredArticles = ''; // don't display a total weight in the description if it is already displayed as amount in the delivery list
    }

    // Returned articles
    let returnedArticlesCount = '';
    if (this.returnedArticles.length > 0) {
      returnedArticlesCount =
        '+' + this.returnedArticles.length + ' retournierte Artikel';
    }

    let totalWeightReturnedArticles = this.totalWeightReturnedArticles;
    totalWeightReturnedArticles &&=
      'Gesamtgewicht retournierte Artikel: ' + totalWeightReturnedArticles;

    const values = [];

    if (deliveredArticlesCount) {
      values.push(deliveredArticlesCount);
    }

    if (totalWeightDeliveredArticles) {
      values.push(totalWeightDeliveredArticles);
    }

    if (returnedArticlesCount) {
      values.push(returnedArticlesCount);
    }

    if (totalWeightReturnedArticles) {
      values.push(totalWeightReturnedArticles);
    }

    if (values.length === 0) {
      return '';
    }

    return values.join('\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0');
  }

  hasMultipleLogisticsPackages() {
    const logisticsPackage = ValueGroup.getCurrentValue(
      this.articles?.[0]?.logisticsPackage,
    );

    for (const article of this.articles) {
      if (
        ValueGroup.getCurrentValue(article.logisticsPackage) !==
        logisticsPackage
      ) {
        true;
        continue;
      }
    }

    return false;
  }

  getSellerOrderReferences(assetMain) {
    const sellerOrderReferences = [];

    if (assetMain?.asset_state?.body?.transaction?.logistics_package)
      for (const log_package of assetMain?.asset_state?.body?.transaction
        ?.logistics_package) {
        if (log_package?.line_item)
          for (const line_item of log_package?.line_item) {
            if (
              line_item?.agreement?.seller_order &&
              !sellerOrderReferences.includes(line_item.agreement?.seller_order)
            ) {
              sellerOrderReferences.push(line_item.agreement.seller_order);
            }
          }
      }

    return sellerOrderReferences.join(', ');
  }

  getBuyerOrderReferences(assetMain) {
    const buyerOrderReferences = [];

    if (assetMain?.asset_state?.body?.transaction?.logistics_package)
      for (const log_package of assetMain?.asset_state?.body?.transaction
        ?.logistics_package) {
        if (log_package?.line_item)
          for (const line_item of log_package?.line_item) {
            if (
              line_item?.agreement?.buyer_order &&
              !buyerOrderReferences.includes(line_item.agreement?.buyer_order)
            ) {
              buyerOrderReferences.push(line_item.agreement.buyer_order);
            }
          }
      }

    return buyerOrderReferences.join(', ');
  }

  getConstructionPlans(assetMain) {
    const constructionPlans = [];

    if (assetMain?.asset_state?.body?.transaction?.logistics_package)
      for (const log_package of assetMain?.asset_state?.body?.transaction
        ?.logistics_package) {
        if (log_package?.line_item)
          for (const line_item of log_package?.line_item) {
            if (
              line_item?.product?.construction_plan &&
              !constructionPlans.includes(line_item.product?.construction_plan)
            ) {
              constructionPlans.push(line_item.product.construction_plan);
            }
          }
      }

    return constructionPlans.join(', ');
  }

  getConstructionComponents(assetMain) {
    const constructionComponents = [];

    if (assetMain?.asset_state?.body?.transaction?.logistics_package)
      for (const log_package of assetMain?.asset_state?.body?.transaction
        ?.logistics_package) {
        if (log_package?.line_item)
          for (const line_item of log_package?.line_item) {
            if (
              line_item?.product?.construction_component &&
              !constructionComponents.includes(
                line_item.product?.construction_component,
              )
            ) {
              constructionComponents.push(
                line_item.product.construction_component,
              );
            }
          }
      }

    return constructionComponents.join(', ');
  }

  computeDeliveryType_safe(code) {
    if (!code) {
      return '';
    }

    return this.computeDeliveryType(code);
  }

  // computes human-readable string that can be displayed
  computeDeliveryType(code) {
    const incoterm = Object.keys(DeliveryNote.INCOTERMS).find(
      (x) => DeliveryNote.INCOTERMS[x].KEY === code,
    );

    if (!incoterm) {
      return code;
    }

    return DeliveryNote.INCOTERMS[incoterm].DESCRIPTION;
  }

  static getDeliveryTypeCode(description) {
    const incoterm = Object.keys(DeliveryNote.INCOTERMS).find(
      (x) => DeliveryNote.INCOTERMS[x].DESCRIPTION === description,
    );

    if (!incoterm) {
      return null;
    }

    return DeliveryNote.INCOTERMS[incoterm].KEY;
  }

  // the mapped site name (from the buyer site) is stored in purchase_account after the user has signed the dln before
  // that, the buyer site id can be retrieved as fallback from the sgw field

  // This is shown as "Bestätigter Lieferort" - this concept doesn't exist in our dln - we use the settlement info as a
  // proxy. this is almost always only written when the user signs. it could theoretically be written by the supplier,
  // but this is never the case.
  getToSiteRecipient(assetMain) {
    if (!assetMain) {
      return new Site();
    }

    const settlementId =
      assetMain?.asset_state?.body?.transaction?.logistics_package?.[0]
        ?.line_item?.[0]?.settlement?.purchase_account?.id;

    if (settlementId) {
      return new Site({
        id: uuidvalidate(settlementId) ? settlementId : null, // validation is necessary because earlier, an id written by customers was used in this field
        name: assetMain?.asset_state?.body?.transaction?.logistics_package?.[0]
          ?.line_item?.[0]?.settlement?.purchase_account?.name,
      });
    }

    // return empty site so nothing is shown in table
    return new Site({});
  }

  getPermittedToSites(assetMain) {
    if (!assetMain?.sgw) {
      return [];
    }

    const sgwIds = assetMain?.sgw
      ?.filter((item) => item.type === 'pr')
      ?.map((item) => item.type_id);

    return sgwIds.map((id) => new Site({ id }));
  }

  getPermittedCostCenters(assetMain) {
    if (!assetMain?.sgw) {
      return [];
    }

    const sgwIds = assetMain?.sgw
      ?.filter((item) => item.type === 'cc')
      ?.map((item) => item.type_id);

    return sgwIds.map((id) => new CostCenter({ id }));
  }

  calculateProcessState(assetMain) {
    if (!assetMain) {
      return DeliveryNote.PROCESS_STATE.DEFAULT.STRING;
    }

    if (
      assetMain?.process_state === DeliveryNote.PROCESS_STATE.SETTLED.API_KEY
    ) {
      return DeliveryNote.PROCESS_STATE.DELIVERED.STRING;
    } // move settled status away from process state

    const documentState = assetMain?.process_state;
    const status = Object.keys(DeliveryNote.PROCESS_STATE).find(
      (x) => DeliveryNote.PROCESS_STATE[x].API_KEY === documentState,
    );

    if (!status) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          'Invalid process state: ' + documentState,
        ),
      );
      return DeliveryNote.PROCESS_STATE.DEFAULT.STRING;
    }

    return DeliveryNote.PROCESS_STATE[status].STRING;
  }

  calculateCombinedState() {
    if (this.acceptState === AcceptStateCalculator.ACCEPT_STATE.DECLINED) {
      return DeliveryNote.COMBINED_STATE.DECLINED.KEY;
    }

    if (
      this.acceptState === AcceptStateCalculator.ACCEPT_STATE.OPEN &&
      this.processState === DeliveryNote.PROCESS_STATE.DELIVERED.STRING
    ) {
      return DeliveryNote.COMBINED_STATE.SIGNATURE_NEEDED.KEY;
    }

    return DeliveryNote.COMBINED_STATE.OTHER.KEY;
  }

  fixParserErrors() {
    this.carrierLicensePlate =
      this.carrierLicensePlate === 'null' ? '' : this.carrierLicensePlate;
  }

  // TODO: this is just a translation function; it could as well take the translated string from a simple object {supplier: 'Ausgehende Lieferung',}
  getCategoryDescription() {
    const processCategory = Object.keys(DeliveryNote.PROCESS_CATEGORY).find(
      (x) => DeliveryNote.PROCESS_CATEGORY[x].KEY === this.processCategory,
    );

    if (!processCategory) {
      throw new EnumValueNotFoundException(
        'Invalid process role: ' + this.processCategory,
      );
    }

    return DeliveryNote.PROCESS_CATEGORY[processCategory].STRING;
  }

  static getProcessRole(roleKey) {
    const processRole = Object.keys(DeliveryNote.PROCESS_ROLE).find(
      (x) => DeliveryNote.PROCESS_ROLE[x].KEY === roleKey,
    );

    if (!processRole) {
      throw new EnumValueNotFoundException('Invalid process role: ' + roleKey);
    }

    return DeliveryNote.PROCESS_ROLE[processRole].STRING;
  }

  static getProcessCategoryKeyFromString(category) {
    const processCategory = Object.keys(DeliveryNote.PROCESS_CATEGORY).find(
      (x) => DeliveryNote.PROCESS_CATEGORY[x].STRING === category,
    );
    return DeliveryNote.PROCESS_CATEGORY[processCategory].KEY;
  }

  getProcessRolesOfUserCompany() {
    const company = store.getState()?.userinfo?.userinfo?.company;

    const processRoles = {
      carrier: company?.id === this.carrier?.id,
      recipient: company?.id === this.recipient?.id,
      supplier: company?.id === this.supplier?.id,
    };

    if (
      processRoles.supplier ||
      processRoles.carrier ||
      processRoles.recipient
    ) {
      return processRoles;
    }

    // as fallback check via company account
    return {
      carrier: company?.companyAccount === this.carrier?.companyAccount,
      recipient: company?.companyAccount === this.recipient?.companyAccount,
      supplier: company?.companyAccount === this.supplier?.companyAccount,
    };
  }

  getProcessCategory() {
    if (
      this.processRolesOfUserCompany.supplier &&
      this.processRolesOfUserCompany.recipient
    ) {
      return DeliveryNote.PROCESS_CATEGORY.INTERNAL_DELIVERY.KEY;
    }

    if (this.processRolesOfUserCompany.supplier) {
      return DeliveryNote.PROCESS_CATEGORY.SUPPLIER.KEY;
    }

    if (this.processRolesOfUserCompany.recipient) {
      return DeliveryNote.PROCESS_CATEGORY.RECIPIENT.KEY;
    }

    // order of if statements is important
    // (e.g. if user company is supplier and carrier, then process category is supplier)
    if (this.processRolesOfUserCompany.carrier) {
      return DeliveryNote.PROCESS_CATEGORY.CARRIER.KEY;
    }

    return DeliveryNote.PROCESS_CATEGORY.NONE.KEY;
  }

  // if process category is none, this could be due to the user company not being loaded yet
  // therefore, try again asynchronously and load the user company from the backend
  async updateProcessCategory() {
    if (this.processCategory !== DeliveryNote.PROCESS_CATEGORY.NONE.KEY) {
      return;
    }

    if (!store.getState()?.userinfo?.userinfo?.company) {
      const [company, error] = await promiseHandler(UserService.getCompany());

      if (error) {
        throw error;
      }

      store.dispatch(saveUserCompany(company));
    }

    this.processRolesOfUserCompany = this.getProcessRolesOfUserCompany();
    this.processCategory = this.getProcessCategory();
  }

  async initToSiteRecipient() {
    if (this.toSiteRecipient.name || !this.toSiteRecipient.id) {
      return;
    }

    const [site, error] = await promiseHandler(
      SiteService.getSiteById(this.toSiteRecipient.id),
    );

    if (error) {
      throw error;
    }

    if (site === null) {
      return;
    }

    this.toSiteRecipient = site;
  }

  async initToSiteRecipientWithBulkSites() {
    if (this.toSiteRecipient.name || !this.toSiteRecipient.id) {
      return;
    }

    const site = SiteService.getSiteFromSitesBulk(this.toSiteRecipient.id);

    if (site === null) {
      return;
    }

    this.toSiteRecipient = site;
  }

  async initPermittedToSites() {
    for (let index = 0; index < this.permittedToSites.length; index++) {
      const [site, error] = await promiseHandler(
        SiteService.getSiteById(this.permittedToSites[index].id),
      );

      if (error) {
        throw error;
      }

      if (site === null) {
        return;
      }

      this.permittedToSites[index] = site;
    }
  }

  async initPermittedToSitesWithBulkSites() {
    for (let index = 0; index < this.permittedToSites.length; index++) {
      const site = SiteService.getSiteFromSitesBulk(
        this.permittedToSites[index].id,
      );

      if (site === null) {
        return;
      }

      this.permittedToSites[index] = site;
    }
  }

  async initPermittedCostCenters() {
    for (let index = 0; index < this.permittedCostCenters.length; index++) {
      const [costCenter, error] = await promiseHandler(
        CostCenterService.getCostCenterById(
          this.permittedCostCenters[index].id,
        ),
      );

      if (error) {
        throw error;
      }

      if (costCenter === null) {
        return;
      }

      this.permittedCostCenters[index] = costCenter;
    }
  }

  async initPermittedCostCentersWithBulkCostCenters() {
    for (let index = 0; index < this.permittedCostCenters.length; index++) {
      const costCenter = CostCenterService.getCostCenterFromCostCentersBulk(
        this.permittedCostCenters[index].id,
      );

      if (costCenter === null) {
        return;
      }

      this.permittedCostCenters[index] = costCenter;
    }
  }

  async initPermittedUsers() {
    const permittedUsers = [];

    for (const permittedToSite of this.permittedToSites) {
      const [newPermittedUsers, error] = await promiseHandler(
        UserService.getPermittedUsersOfEntity(
          permittedToSite.id,
          SiteService.getSite,
          SiteService.getSiteById,
        ),
      );

      if (error) {
        throw error;
      }

      permittedUsers.push(...newPermittedUsers);
    }

    for (const permittedCostCenter of this.permittedCostCenters) {
      const [newPermittedUsers, error] = await promiseHandler(
        UserService.getPermittedUsersOfEntity(
          permittedCostCenter.id,
          CostCenterService.getCostCenter,
          CostCenterService.getCostCenterById,
        ),
      );

      if (error) {
        throw error;
      }

      permittedUsers.push(...newPermittedUsers);
    }

    this.permittedUsers.push(...permittedUsers);
    this.permittedUsers = ArrayUtils.removeDuplicatesByKey(
      this.permittedUsers,
      'id',
    );
  }

  async initCustomDataDeliveryNote() {
    return this.initCustomData(this.customData);
  }

  async initCustomDataArticles() {
    const promises = this.articles.map((article) =>
      this.initCustomData(article.customData),
    );

    return PromiseUtils.allResolved(promises);
  }

  async initProcessStateChangeUser() {
    const entries = ObjectUtils.entries(this.processStateChangeUser);

    for (const entry of entries) {
      // If the process state hasn't been set yet by a user.
      if (!entry.value.id) {
        continue;
      }

      if (entry.value.id === User.ASSET_CREATOR_USER_ID) {
        // The "asset creator" is a special user that is used as first user in the delivery chain.
        // It is not a real user and should not be displayed in the delivery note.
        // Trying to load it from the backend will return a 403 error.
        continue;
      }

      const [user, error] = await promiseHandler(
        UserService.getUserById(entry.value.id),
      );

      if (error) {
        throw error;
      }

      // Case can happen, e.g. if the user is from another company account.
      if (!user) {
        continue;
      }

      this.processStateChangeUser[entry.key] = user;
    }
  }

  async initProcessStateChangeUserWithBulkUsers() {
    const entries = ObjectUtils.entries(this.processStateChangeUser);

    for (const entry of entries) {
      // If the process state hasn't been set yet by a user.
      if (!entry.value.id) {
        continue;
      }

      const user = UserService.getUserFromUsersBulk(entry.value.id);

      // Case can happen, e.g. if the user is from another company account.
      if (!user) {
        continue;
      }

      this.processStateChangeUser[entry.key] = user;
    }
  }

  // Mapping option ids to the display name during dln bulk load is necessary so that search function in delivery list is working properly
  async initCustomData(customData) {
    const entries = customData.getEntries();

    for (const entry of entries) {
      const customDataKey = entry[0];
      const customFieldId = CustomField.getIdFromKey(customDataKey);

      if (!customFieldId) {
        continue;
      }

      const [customField, error1] = await promiseHandler(
        CustomFieldService.getCustomFieldFromCustomFieldsBulk(customFieldId),
      );

      if (error1) {
        Log.error(
          'Failed to load custom field from bulk. custom field id: ' +
            customFieldId,
          error1,
        );
        continue;
      }

      // Remove custom fields from the delivery note if the user isn't allowed to access them (e.g. when the custom fields are from another company).
      if (!customField) {
        delete customData[customDataKey];
        continue;
      }

      // Parsing the value of the custom field to a human-readable formatted value, should actually be made in the UI layer and not when initializing the dln.
      // However, this requires changes at multiple places in the code. The quick fix is to directly parse it here.
      const formattedCustomDataValue = CustomField.getFormatter(
        customField.type,
      )(entry[1]);

      // CHANGES_LIST_INCLUDED_PATHS must be extended by the custom fields
      // customField.level can throw "Cannot read properties of null (reading 'level')" error if customField has been deleted in the meantime.
      if (customField.level === CustomField.LEVEL.DELIVERY_NOTE.KEY) {
        this.changesListIncludedPaths.push({
          NAME: customField.displayName,
          PATH: ['customData', customDataKey],
        });
      } else if (customField.level === CustomField.LEVEL.ARTICLE.KEY) {
        this.changesListIncludedPaths.push({
          NAME: 'Artikel - ' + customField.displayName,
          PATH: ['customData', customDataKey],
        });
      }

      // Remove the key if the value is an object because this leads to problems in the UI.
      if (isObject(formattedCustomDataValue)) {
        delete customData[customDataKey];
        continue;
      }

      // Remove the key if it shouldn't be displayed.
      if (
        customField.visibility === CustomField.VISIBILITY.NEVER.KEY ||
        (customField.visibility === CustomField.VISIBILITY.WHEN_SET.KEY &&
          CustomField.ValueIsEmpty(formattedCustomDataValue))
      ) {
        delete customData[customDataKey];
        continue;
      }

      const customFieldOptionId = CustomField.getIdFromKey(
        formattedCustomDataValue,
      );

      if (
        customField.type !== CustomField.TYPE.ENUMERATION.KEY ||
        !uuidvalidate(customFieldOptionId) ||
        !customField.hasOptions
      ) {
        delete customData[customDataKey];
        customData[customDataKey] = formattedCustomDataValue;
        continue;
      }

      const customFieldOption = customField.options.find(
        (option) => option.id === customFieldOptionId,
      );

      customData[customDataKey] = customFieldOption?.displayName ?? null;
    }
  }

  initUserActions() {
    this.userActions.requestSignatures =
      store.getState()?.userinfo?.userActions?.[this.id]?.requestSignatures ??
      [];
    this.userActions.shareDeliveryNote =
      store.getState()?.userinfo?.userActions?.[this.id]?.shareDeliveryNote ??
      [];
  }

  getCustomFieldIds() {
    const customFieldIds = [];

    const entries = this.customData.getEntries();

    for (const entry of entries) {
      const customDataKey = entry[0];
      const customFieldId = CustomField.getIdFromKey(customDataKey);

      if (!customFieldId) {
        continue;
      }

      customFieldIds.push(customFieldId);
    }

    for (const article of this.articles) {
      const articleCustomData = article.customData;
      const articleEntries = articleCustomData.getEntries();

      for (const articleEntry of articleEntries) {
        const customDataKey = articleEntry[0];
        const customFieldId = CustomField.getIdFromKey(customDataKey);

        if (!customFieldId) {
          continue;
        }

        customFieldIds.push(customFieldId);
      }
    }

    return ArrayUtils.removeDuplicates(customFieldIds);
  }

  static getCustomFieldIdsFromChain(chain) {
    const customFieldIds = [];

    for (const entry of ObjectUtils.entries(
      chain.body?.header?.additional_party_data ?? {},
    )) {
      const customDataKey = entry.key;
      const customFieldId = CustomField.getIdFromKey(customDataKey);

      if (!customFieldId) {
        continue;
      }

      customFieldIds.push(customFieldId);
    }

    if (chain.body?.transaction?.logistics_package)
      for (const log_package of chain.body?.transaction?.logistics_package) {
        if (log_package?.line_item)
          for (const line_item of log_package?.line_item) {
            for (const entry of ObjectUtils.entries(
              line_item?.additional_party_data ?? {},
            )) {
              const customDataKey = entry.key;
              const customFieldId = CustomField.getIdFromKey(customDataKey);

              if (!customFieldId) {
                continue;
              }

              customFieldIds.push(customFieldId);
            }
          }
      }

    return ArrayUtils.removeDuplicates(customFieldIds);
  }

  async addChainToHistory(assetChain, company, initial) {
    const assetMain = convertAssetChainToMain(assetChain);
    this.recalculatedMain = updateAssetMain(this.recalculatedMain, assetMain);

    const updatedDln = new DeliveryNote(this.recalculatedMain);

    const [, error] = await promiseHandler(updatedDln.asyncInit());

    if (error) {
      throw error;
    }

    if (initial) {
      this.history = updatedDln;
      return;
    }

    this.mergeWithContext(
      this.history,
      updatedDln,
      assetChain.created_on,
      company,
    );
  }

  mergeWithContext(currentObject, updatedObject, datetime, company) {
    mergeWith(
      currentObject,
      updatedObject,
      (currentValue, updatedValue, key) => {
        if (DeliveryNote.MERGE_EXCLUDED_KEYS.includes(key)) {
          return currentValue;
        }

        if (!Value.isSimpleValue(updatedValue)) {
          this.mergeWithContext(currentValue, updatedValue, datetime, company);
          return currentValue;
        }

        const updatedValueObject = new Value(updatedValue, datetime, company);

        if (!ValueGroup.isValueGroup(currentValue)) {
          const currentValueObject = new Value(currentValue, null, null);
          currentValue = new ValueGroup();
          currentValue.initial = currentValueObject;
        }

        if (!currentValue.latestValueEquals(updatedValue)) {
          currentValue.history.push(updatedValueObject);
        }

        return currentValue;
      },
    );
  }

  mergeHistory() {
    mergeWith(this, this.history, DeliveryNote.mergeHistoryCustomizer);
  }

  static mergeHistoryCustomizer = (currentValue, historyValue, key) => {
    // due to object being passed by reference, history and current values might have already been merged (e.g. mainArticle and article.*, or Article.amount and Article.weight)
    if (ValueGroup.isValueGroup(currentValue)) {
      return currentValue;
    }

    if (DeliveryNote.MERGE_EXCLUDED_KEYS.includes(key)) {
      return currentValue;
    }

    if (!ValueGroup.isValueGroup(historyValue)) {
      if (!Value.isSimpleValue(currentValue)) {
        mergeWith(
          currentValue,
          historyValue,
          DeliveryNote.mergeHistoryCustomizer,
        );
      }

      return currentValue;
    }

    if (
      Value.isSimpleValue(currentValue) ||
      // if the current value is an empty array and the corresponding value from the history is a value group, it is treated as a "real value".
      // this is needed for the comments prop as it can be an empty array in this (dln) so that it is not clear whether this array should be a ValueGroup (which is checked by this special condition)
      (Array.isArray(currentValue) &&
        currentValue.length === 0 &&
        ValueGroup.isValueGroup(historyValue))
    ) {
      if (!ValueGroup.isValueGroup(historyValue)) {
        return currentValue;
      }

      historyValue.current = new Value(currentValue, null);
      return historyValue;
    }
  };

  setChanges(ignoreInitialValues) {
    for (const includedPath of this.changesListIncludedPaths) {
      this.setChangesForPath(
        this.history,
        includedPath.PATH,
        includedPath.NAME,
        includedPath.FORMATTER,
        ignoreInitialValues && includedPath.IGNORE_INITIAL_VALUE,
      );
    }
  }

  setChangesForPath(object, path, name, formatter, ignoreInitialValue) {
    if (!object) {
      return;
    }

    const clonedPath = [...path];
    const currentPath = clonedPath.shift();

    if (currentPath === '*') {
      // current element is wildcard, so all keys must be iterated through
      for (const key of Object.keys(object)) {
        this.setChangesForPath(
          object[key],
          clonedPath,
          name,
          formatter,
          ignoreInitialValue,
        );
      }

      return;
    }

    if (currentPath) {
      // there is still a key in path, so we need to go a lever deeper into the object
      this.setChangesForPath(
        object[currentPath],
        clonedPath,
        name,
        formatter,
        ignoreInitialValue,
      );

      return;
    }

    // path is empty, so we can now check if the value contains a change
    const valueGroup = object;

    if (!ValueGroup.isValueGroup(valueGroup)) {
      return;
    }

    if (valueGroup.history.length === 0) {
      return;
    }

    // costCenters and toSiteRecipient are expected to be set during dln accepting.
    // Hence, they are excluded from the "real" changes, when they have been changed only once.
    if (ignoreInitialValue && valueGroup.history.length === 1) {
      return;
    }

    this.changes.push({
      formatter,
      history: valueGroup.history,
      initial: valueGroup.initial,
      name,
    });
  }

  static getPropertyString(key) {
    const property = Object.keys(DeliveryNote.PROPERTY).find(
      (x) => DeliveryNote.PROPERTY[x].KEY === key,
    );

    if (!property) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid property: ' + key),
      );
      return key;
    }

    return DeliveryNote.PROPERTY[property].STRING;
  }

  static getDataType(key) {
    const property = Object.keys(DeliveryNote.PROPERTY).find(
      (x) => DeliveryNote.PROPERTY[x].KEY === key,
    );

    if (!property) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid property: ' + key),
      );
      return key;
    }

    return (
      DeliveryNote.PROPERTY[property].DATA_TYPE ?? FilterProps.DATA_TYPE.STRING
    );
  }

  static getProcessStateString(key) {
    const processState = Object.keys(DeliveryNote.PROCESS_STATE).find(
      (x) => DeliveryNote.PROCESS_STATE[x].API_KEY === key,
    );

    if (!processState) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid process state: ' + key),
      );
      return key;
    }

    return DeliveryNote.PROCESS_STATE[processState].STRING;
  }

  static getProcessStateKeyByString(string) {
    const processState = Object.keys(DeliveryNote.PROCESS_STATE).find(
      (x) => DeliveryNote.PROCESS_STATE[x].STRING === string,
    );

    if (!processState) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid process state: ' + string),
      );
      return string;
    }

    return DeliveryNote.PROCESS_STATE[processState].API_KEY;
  }

  static getProcessStateDescByString(string) {
    const processState = Object.keys(DeliveryNote.PROCESS_STATE).find(
      (x) => DeliveryNote.PROCESS_STATE[x].STRING === string,
    );

    if (!processState) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid process state: ' + string),
      );
      return string;
    }

    return DeliveryNote.PROCESS_STATE[processState].FILTER_DESC;
  }

  static getProcessStateOptions() {
    return [
      DeliveryNote.PROCESS_STATE.READY_FOR_OUTPUT.STRING,
      DeliveryNote.PROCESS_STATE.IN_TRANSPORT.STRING,
      DeliveryNote.PROCESS_STATE.ARRIVED.STRING,
      DeliveryNote.PROCESS_STATE.CANCELLED.STRING,
      DeliveryNote.PROCESS_STATE.DELIVERED.STRING,
    ];
  }

  static getById(id) {
    return store
      .getState()
      ?.deliveryNotes.deliveryNotes.find((dln) => dln.id === id);
  }

  static getNumberById(id) {
    return store
      .getState()
      ?.deliveryNotes.deliveryNotes.find((dln) => dln.id === id)?.number;
  }

  getBackendFormat() {
    return {
      context: 'DELNTE',
      header: {
        date: this.dlnDate.toISOString(),
        id: this.number,
        issuer: this.issuer ? this.issuer.getBackendFormat() : null,
      },
      transaction: {
        agreement: {
          buyer: this.buyer ? this.buyer.getBackendFormat() : null,
          delivery_terms: this.deliveryType
            ? { code: DeliveryNote.getDeliveryTypeCode(this.deliveryType) }
            : null,
          seller: this.seller ? this.seller.getBackendFormat() : null,
        },
        delivery: {
          note: this.comments.map((comment) => {
            return {
              note: { content: { comment } },
              type: 'comment_note',
            };
          }),
          ship_from: {
            ...(this.supplier ? this.supplier.getBackendFormat() : {}),
            consignment: {
              carrier: this.carrier ? this.carrier.getBackendFormat() : null,
              movement: {
                freight_forwarder: this.freightForwarder
                  ? this.freightForwarder.getBackendFormat()
                  : null,
                means: this.movementMeans,
                registration_id:
                  this.carrierLicensePlate || this.carrierVehicleNumber
                    ? [
                        {
                          id: this.carrierLicensePlate,
                          issuer_assigned_id: this.carrierVehicleNumber,
                        },
                      ]
                    : null,
              },
            },
            trade_address: this.fromSite.name
              ? { line_one: this.fromSite.name }
              : null,
          },
          ship_to: {
            ...(this.recipient ? this.recipient.getBackendFormat() : {}),
            trade_address: {
              issuer_assigned_id: this.project,
              line_one: this.toSiteSupplier.name,
            },
          },
        },
        logistics_package: [
          {
            id: '1',
            line_item: this.articles.map((article, index) => {
              return {
                ...article.getBackendFormat(
                  this.costCenters[0],
                  this.sellerOrderReferences,
                  this.buyerOrderReferences,
                  this.constructionComponents,
                  this.constructionPlans,
                ),
                id: index + 1,
              };
            }),
          },
        ],
        settlement: {
          sales_account: {
            name: this.buyerId,
            type_code: 'AVC',
          },
        },
      },
    };
  }

  static PROCESS_STATE = {
    ARRIVED: {
      API_KEY: 'arrived',
      CLASS: 'delivery-note-status-arrived',
      FILTER_DESC:
        'Diese Lieferungen wurden vom Fahrer für vollständig erklärt und zur Signatur freigegeben.',
      STRING: 'Übergabe',
      SUPPORT_DESC:
        'Sobald ein Fahrer die Lieferung für vollständig erklärt und zur Signatur freigegeben hat, ' +
        'befindet sie sich im Status "Übergabe". Die ausstehende Signatur kann sowohl mittels normaler ' +
        'Übergabe als auch verzögerter Abgabe geschehen. Anschließend erhalten relevante ' +
        'Abnahmeberechtigte eine Benachrichtigung und können die Lieferung bestätigen.',
    },
    CANCELLED: {
      API_KEY: 'cancelled',
      CLASS: 'delivery-note-status-cancelled',
      FILTER_DESC: 'Alle Lieferungen mit dem Status "Storniert".',
      STRING: 'Storniert',
      SUPPORT_DESC:
        'Lieferungen können direkt vom Lieferanten oder Abnehmer storniert werden. Stornierte Lieferungen ' +
        'können nicht abgerechnet werden, also führen zu Fehlern in der Rechnungsprüfung.',
    },
    DEFAULT: {
      CLASS: 'delivery-note-status-default',
      // as fallback if no other process state fits
      STRING: 'Nicht Gefunden',
    },
    DELIVERED: {
      API_KEY: 'delivered',
      CLASS: 'delivery-note-status-delivered',
      FILTER_DESC:
        'Diese Lieferungen wurden von einem Abnehmer signiert und "als Geliefert" bestätigt.',
      STRING: 'Geliefert',
      SUPPORT_DESC:
        'Sobald ein Abnehmer die Lieferung bestätigt und damit signiert hat, wechselt die Lieferung in ' +
        'diesen Status. Nun wird die Lieferung in allen Statistiken erfasst und kann in einer ' +
        'Rechnung referenziert werden.',
    },
    IMPORTED: {
      API_KEY: 'imported',
      CLASS: 'delivery-note-status-imported',
      STRING: 'Importiert',
    },
    IN_TRANSPORT: {
      API_KEY: 'inTransport',
      CLASS: 'delivery-note-status-in-transport',
      FILTER_DESC: 'Diese Lieferungen wurden von einem Fahrer angenommen.',
      STRING: 'In Transport',
      SUPPORT_DESC:
        'Sobald eine Lieferung von einem Fahrer angenommen wurde, gilt sie als In Transport. Ab ' +
        'hier ist sie auch für Mitarbeiter des Abnehmers einsehbar.',
    },
    READY_FOR_OUTPUT: {
      API_KEY: 'readyForOutput',
      CLASS: 'delivery-note-status-ready-for-output',
      FILTER_DESC:
        'Diese Lieferungen wurden durch den Lieferanten erstellt, aber noch nicht von einem Fahrer angenommen. ' +
        'Sie werden automatisch dem Mitarbeiter mit dem passenden Kennzeichen zur Annahme zugestellt.',
      STRING: 'Ausgabe',
      SUPPORT_DESC:
        'Diese Lieferung wurde durch den Lieferanten erstellt, aber noch nicht von einem Fahrer ' +
        'angenommen. Sie wird automatisch dem Mitarbeiter mit dem passenden Kennzeichen zur Annahme ' +
        'zugestellt.',
    },
    SETTLED: {
      // deprecated: only used in old dlns
      API_KEY: 'settled',
    },
  };
  static COMBINED_STATE = {
    DECLINED: {
      DESCRIPTION: 'Diese Lieferung wurde abgelehnt.',
      KEY: 'declined',
    },
    OTHER: {
      KEY: 'other',
    },
    SIGNATURE_NEEDED: {
      DESCRIPTION: 'Diese Lieferung muss erneut signiert werden.',
      KEY: 'signature-needed',
    },
  };
  static PROCESS_ROLE = {
    CARRIER: {
      KEY: 'carrier',
      STRING: 'Spediteur',
    },
    RECIPIENT: {
      KEY: 'recipient',
      STRING: 'Abnehmer',
    },
    SUPPLIER: {
      KEY: 'supplier',
      STRING: 'Lieferant',
    },
  };
  static CONTRACT_ROLE = {
    BUYER: {
      KEY: 'buyer',
      STRING: 'Auftraggeber',
    },
    SELLER: {
      KEY: 'seller',
      STRING: 'Auftragnehmer',
    },
  };
  static PROCESS_CATEGORY = {
    CARRIER: {
      KEY: 'carrier',
      STRING: 'Spedition',
    },
    INTERNAL_DELIVERY: {
      KEY: 'internal-delivery',
      STRING: 'Interne Lieferung',
    },
    NONE: {
      KEY: 'none',
      STRING: 'Nicht in der Lieferung involviert',
    },
    RECIPIENT: {
      KEY: 'recipient',
      STRING: 'Eingehende Lieferung',
    },
    SUPPLIER: {
      KEY: 'supplier',
      STRING: 'Ausgehende Lieferung',
    },
  };
  static PROPERTY = {
    ACCEPT_STATE: {
      KEY: 'acceptState',
      STRING: 'Angenommen',
    },

    ARTICLE: {
      KEY: 'article',
      STRING: 'Artikel',
    },

    ARTICLE_NUMBER: {
      KEY: 'articleNumber',
      STRING: 'Artikel-Nr.',
    },

    ARTICLE_NUMBERS: {
      KEY: 'articleNumbers',
      STRING: 'Artikel-Nr.',
    },

    ARTICLES: {
      KEY: 'articles',
      STRING: 'Artikel',
    },

    CARRIER: {
      KEY: 'carrier',
      STRING: 'Spediteur',
    },

    BUYER_ORDER_REFERENCES: {
      KEY: 'buyerOrderReferences',
      STRING: 'Bestellreferenz Auftraggeber',
    },

    CATEGORY: {
      KEY: 'category',
      STRING: 'Kategorie',
    },

    COMMENTS: {
      KEY: 'comments',
      STRING: 'Kommentare',
    },

    CONSTRUCTION_COMPONENTS: {
      KEY: 'constructionComponents',
      STRING: 'Bauteil',
    },

    BUYER_ID: {
      KEY: 'buyerId',
      STRING: 'Kundennummer',
    },

    // costCenter is actually deprecated and should be removed.
    // The cleanest solution would be to migrate it to the Article model.
    // However, due to "being pragmatic" this will only be done in the future.
    // Some goes for article.
    COST_CENTER: {
      KEY: 'costCenter',
      STRING: 'Kostenstelle',
    },

    CONSTRUCTION_PLANS: {
      KEY: 'constructionPlans',
      STRING: 'Plan',
    },

    COST_CENTERS: {
      KEY: 'costCenters',
      STRING: 'Kostenstellen',
    },

    CREATION_DATE: {
      DATA_TYPE: FilterProps.DATA_TYPE.DATE,
      KEY: 'creationDate',
      STRING: 'Erstellt am',
    },

    CUSTOM_DATA: {
      KEY: 'customData',
      STRING: 'Weitere Informationen',
    },

    // delivery changes
    CHANGED_FIELDS: {
      KEY: 'changedFields',
      STRING: 'Änderungen',
    },

    DELIVERY_SHARED_WITH: {
      KEY: 'deliveryNoteSharedWith',
      STRING: 'Geteilt mit',
    },

    DELIVERY_TYPE: {
      KEY: 'deliveryType',
      STRING: 'Lieferungsart',
    },

    CHANGED_AT: {
      DATA_TYPE: FilterProps.DATA_TYPE.DATE,
      KEY: 'changedDate',
      STRING: 'Letzte Änderung am',
    },

    DESCRIPTION: {
      KEY: 'description',
      STRING: 'Beschreibung',
    },

    CHANGED_BY: {
      KEY: 'changedBy',
      STRING: 'Änderungen von',
    },

    DLN_DATE: {
      DATA_TYPE: FilterProps.DATA_TYPE.DATE,
      KEY: 'dlnDate',
      STRING: 'LFS-Datum',
    },

    CARRIER_ID: {
      KEY: 'carrierId',
      STRING: 'Spediteur-Nr',
    },

    FROM_SITE: {
      KEY: 'fromSite',
      STRING: 'Beladeort',
    },

    FROM_SITE_ISSUER_ASSIGNED_ID: {
      KEY: 'fromSiteIssuerAssignedId',
      STRING: 'Beladeort-Nummer',
    },

    MAIN_ARTICLE_AMOUNT_UNIT: {
      KEY: 'mainArticleAmountUnit',
      STRING: 'Einheit',
    },

    LICENSE_PLATE: {
      KEY: 'licensePlate',
      STRING: 'Kennzeichen',
    },

    MAIN_ARTICLE_AMOUNT_VALUE: {
      DATA_TYPE: FilterProps.DATA_TYPE.NUMBER,
      KEY: 'mainArticleAmountValue',
      STRING: 'Menge',
    },

    MAIN_ARTICLE_NUMBER: {
      KEY: 'mainArticleNumber',
      STRING: 'Artikel-Nr.',
    },

    NUMBER: {
      KEY: 'number',
      STRING: 'LFS-Nr.',
    },

    MAIN_ARTICLE_TYPE: {
      KEY: 'mainArticleType',
      STRING: 'Artikel',
    },

    PERMITTED_COST_CENTERS: {
      KEY: 'permittedCostCenters',
      STRING: 'Berechtigte Kostenstellen',
    },

    MODIFIED_DATE: {
      DATA_TYPE: FilterProps.DATA_TYPE.DATE,
      KEY: 'modifiedDate',
      STRING: 'Zuletzt geändert',
    },

    PERMITTED_TO_SITES: {
      KEY: 'permittedToSites',
      STRING: 'Berechtigte Lieferorte',
    },

    MOVEMENT_MEANS: {
      KEY: 'movementMeans',
      STRING: 'Zufuhrart',
    },

    TO_SITE_RECIPIENT: {
      KEY: 'toSiteRecipient',
      STRING: 'Bestätigter Lieferort',
    },

    PERMITTED_COST_CENTER_NAMES: {
      KEY: 'permittedCostCenterNames',
      STRING: 'Berechtigte Kostenstellen',
    },

    TO_SITE_SUPPLIER: {
      KEY: 'toSiteSupplier',
      STRING: 'Lieferort (Lieferantenbezeichnung)',
      STRING_SHORT: 'Lieferort (Lieferantenbez.)',
    },

    // Needed to access different property in mapped delivery notes.
    // i.e. filter groups in delivery and dashboard overview.
    PERMITTED_TO_SITE_NAMES: {
      KEY: 'permittedToSiteNames',
      STRING: 'Berechtigte Lieferorte',
    },

    PROCESS_STATE: {
      KEY: 'processState',
      STRING: 'Status',
    },

    TO_SITE_SUPPLIER_ADDRESS: {
      KEY: 'toSiteSupplierAddress',
      STRING: 'Lieferort (Adresse)',
    },

    PROCESS_STATE_CHANGE_USER: {
      KEY: 'processStateChangeUser',
      STRING: 'Signiert durch',
    },

    PROJECT: {
      KEY: 'project',
      STRING: 'Projekt',
    },

    RECIPIENT: {
      KEY: 'recipient',
      STRING: 'Abnehmer',
    },

    SELLER_ORDER_REFERENCES: {
      KEY: 'sellerOrderReferences',
      STRING: 'Bestellreferenz Auftragnehmer',
    },

    SETTLED_STATUS: {
      KEY: 'settledStatus',
      STRING: 'Abgerechnet',
    },

    SIGNATURES_REQUESTED_FROM: {
      KEY: 'signaturesRequestedFrom',
      STRING: 'Signaturen angefordert von',
    },

    STATUS: {
      KEY: 'status',
      STRING: 'Status',
    },
    SUPPLIER: {
      KEY: 'supplier',
      STRING: 'Lieferant',
    },
    TO_SITE_SUPPLIER_TRADE_CONTACT: {
      KEY: 'toSiteSupplierTradeContact',
      STRING: 'Ansprechperson des Lieferorts',
    },

    // total line item weights
    TOTAL_WEIGHT_GROSS: {
      KEY: 'totalWeightGross',
      STRING: 'Brutto-Gewicht',
    },

    TOTAL_WEIGHT_NET: {
      KEY: 'totalWeightNet',
      STRING: 'Netto-Gewicht',
    },
    TOTAL_WEIGHT_TARE: {
      KEY: 'totalWeightTare',
      STRING: 'Tara-Gewicht',
    },
    TRADER: {
      KEY: 'trader',
      STRING: 'Händler',
    },
  };
  static INCOTERMS = {
    CFR: {
      DESCRIPTION: 'Kosten und Fracht (CFR)',
      KEY: 'CFR',
    },
    CIF: {
      DESCRIPTION: 'Kosten, Versicherung und Fracht (CIF)',
      KEY: 'CIF',
    },
    CIP: {
      DESCRIPTION: 'Frachtfrei versichert (CIP)',
      KEY: 'CIP',
    },
    CPT: {
      DESCRIPTION: 'Frachtfrei (CPT)',
      KEY: 'CPT',
    },
    DAP: {
      DESCRIPTION: 'Geliefert benannter Ort (DAP)',
      KEY: 'DAP',
    },
    DDP: {
      DESCRIPTION: 'Geliefert verzollt (DDP)',
      KEY: 'DDP',
    },
    DPU: {
      DESCRIPTION: 'Frei Bau (DPU)',
      KEY: 'DPU',
    },
    EXW: {
      DESCRIPTION: 'Ab Werk (EXW)',
      KEY: 'EXW',
    },
    FAS: {
      DESCRIPTION: 'Frei Längsseite Schiff (FAS)',
      KEY: 'FAS',
    },
    FCA: {
      DESCRIPTION: 'Frei Frachtführer (FCA)',
      KEY: 'FCA',
    },
    FH: {
      DESCRIPTION: 'Frei Haus (FH)',
      KEY: 'FH',
    },
    FOB: {
      DESCRIPTION: 'Frei an Bord (FOB)',
      KEY: 'FOB',
    },
  };
  // paths that are excluded when calculating the dln diffs. Also works recursively, e.g. article.billedItem is also excluded.
  static MERGE_EXCLUDED_KEYS = [
    'acceptArticleSupplier',
    'acceptArticleCarrier',
    'acceptArticleRecipient',
    'acceptArticleOnBehalfSupplier',
    'acceptArticleOnBehalfCarrier',
    'acceptArticleOnBehalfRecipient',
    'acceptState',
    'acceptStateCarrier',
    'acceptStateRecipient',
    'acceptStateSupplier',
    'acceptStateOnBehalfSupplier',
    'acceptStateOnBehalfCarrier',
    'acceptStateOnBehalfRecipient',
    'assetChain',
    'billedItem',
    'category',
    'combinedState',
    'changesListIncludedPaths',
    'creationDate',
    'deliveryDate',
    'history',
    'id',
    'issuer',
    'mainArticle',
    'modifiedDate',
    'permittedCostCenters',
    'permittedToSites',
    'processCategory',
    'processRolesOfUserCompany',
    'processState',
    'recalculatedMain',
    'referencedInvoices',
    'settledStatus',
  ];
}
