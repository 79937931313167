import clsx from 'clsx';

import { LOADING_STATE } from '~/constants/LoadingState';

import { withErrorBoundary } from '~/ui/atoms';

import { Spinner } from '~/components/Spinner';

const Tile = ({
  children,
  height,
  icon,
  loading,
  rightAnnotation,
  title,
  width,
}) => {
  const gridHeight = 310;
  const gridWidth = 360;
  const gridGap = 30;
  const tilePadding = 20;
  const columnHeaderHeight = 35;

  const tileHeight = height
    ? height * gridHeight + (height - 1) * gridGap
    : gridHeight;
  const tileWidth = width
    ? width * gridWidth + (width - 1) * gridGap
    : gridWidth;
  const contentHeight = tileHeight - 2 * tilePadding - columnHeaderHeight;

  const content = () => {
    if (loading === LOADING_STATE.LOADING) {
      return (
        <div style={{ height: contentHeight }}>
          <Spinner />
        </div>
      );
    }

    if (loading === LOADING_STATE.FAILED) {
      return (
        <div style={{ height: contentHeight }}>
          <div className="flex h-full items-center justify-center font-bold text-gray-400">
            {'Daten konnten nicht geladen werden.'}
          </div>
        </div>
      );
    }

    return (
      <div
        style={{
          height: contentHeight,
        }}
      >
        {children}
      </div>
    );
  };

  return (
    <div
      className="box-shadow-blue inline-block rounded-md bg-white p-4"
      style={{
        height: tileHeight,
        width: tileWidth,
      }}
    >
      <div className="flex-sb-c">
        <div className="flex-s-c">
          {icon}
          <div className={clsx(['text-xl font-bold', { 'ml-4': icon }])}>
            {title}
          </div>
        </div>
        <div className="text-gray-400">{rightAnnotation}</div>
      </div>
      {content()}
    </div>
  );
};

export default withErrorBoundary(Tile, null);
