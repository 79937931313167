import {
  useQueries,
  useQuery,
  type UseQueryResult,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';
import { type Permission } from '~/types/permission';

import Log from '~/utils/Log';

import { queryKeysUserGroup } from './queryKeys';

export type UserGroup = {
  grantedPermissions: Array<{
    id: number;
    targetId: UUID;
    targetType:
      | 'accounting_reference'
      | 'company'
      | 'org_unit'
      | 'site'
      | 'user_group'
      | 'user'
      | 'vehicle';
    permissions: Permission[];
  }>;
  id: UUID;
  memberUserGroups: UUID[];
  name: string;
  orgUnitPaths: UUID[][];
  orgUnits: UUID[];
  parentUserGroups: UUID[];
  permissionsOn: Array<{
    id: number;
    targetId: UUID;
    targetType:
      | 'accounting_reference'
      | 'company'
      | 'org_unit'
      | 'site'
      | 'user_group'
      | 'user'
      | 'vehicle';
    permissions: Permission[];
  }>;
  userGroupPaths: UUID[];
  users: UUID[];
};

/**
 * Fetches user group data from the API.
 * @param {UUID} userGroupId - The ID of the user group to fetch.
 * @returns {Promise<UserGroup|undefined>} The user group data if successful, undefined otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/get_user_group_access_control_user_group__user_group_id__get
 */
export const fetchUserGroup = async (
  userGroupId: UUID,
): Promise<UserGroup | undefined> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER_GROUP.GET(userGroupId))
      .json<UserGroup>();

    return response;
  } catch (error) {
    Log.error('Error fetching user group', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Generates the query options for the user group query.
 *
 * @param {UUID} userGroupId - The ID of the user group to fetch.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getUserGroupQueryOptions = ({
  userGroupId,
  options,
}: {
  userGroupId: UUID;
  options?: Parameters<typeof useQuery>[0];
}) => {
  return {
    queryFn: async () => fetchUserGroup(userGroupId),
    queryKey: queryKeysUserGroup.get(userGroupId),
    ...options,
  };
};

/**
 * React Query based custom hook for getting the data for a user group with a given userGroupId.
 * @param {UUID} userGroupId - The ID of the user group to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<UserGroup | undefined>} The result of the useQuery hook.
 */
export const useQueryUserGroup = (
  userGroupId: UUID,
  options: Parameters<typeof useQuery>[0],
): UseQueryResult<UserGroup | undefined> =>
  useQuery(getUserGroupQueryOptions({ options, userGroupId }));

/**
 * React Query based custom hook for getting data for multiple user groups based on a list of user group IDs.
 * @param {UUID[]} userGroupIds - Array of user group IDs to fetch.
 * @param {Object} options - Additional options for the useQueries hook.
 * @param {Function} combine - Optional function to process the results of the different queries.
 * @returns {UseQueryResult<UserGroup | undefined>[]} Array of query results for each user group. If a combine
 *                                              function is provided, the results will be transformed
 *                                              according to that function.
 */
export const useQueriesUserGroups = (
  userGroupIds: UUID[],
  options?: Record<string, unknown>,
  combine?: (results: Array<UseQueryResult<UserGroup | undefined>>) => unknown,
) =>
  useQueries({
    combine,
    queries: userGroupIds.map((userGroupId) => ({
      enabled: Boolean(userGroupId),
      queryFn: async () => fetchUserGroup(userGroupId),
      queryKey: queryKeysUserGroup.get(userGroupId),
      ...options,
    })),
  });
