import { useState, useEffect } from 'react';

/**
 * A custom hook that returns the provided value with an initial delay.
 * Useful for debouncing rapidly changing values.
 *
 * @param value - The value to be delayed
 * @param delay - The delay duration in milliseconds (default: 300ms)
 * @returns The delayed value, which updates after the specified delay
 *
 * @example
 * const delayedChangeWarning = useDelayedValue(unsavedChangesString, 500);
 */
export const useDelayedValue = (value: unknown, delay = 400) => {
  const [delayedValue, setDelayedValue] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return delayedValue;
};
