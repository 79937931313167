import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import DatagridUtils from '~/utils/datagridUtils';
import ObjectUtils from '~/utils/objectUtils';

import Attachment from '../../attachments/Attachment';
import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';

export default function DeliveryNoteHistoryDetailPanel(props) {
  const columnProps = {
    headerClassName: 'text-14px',
    renderCell: (params) => (
      <span className="text-14px">
        {DatagridUtils.displayCellTooltip(params)}
      </span>
    ),
  };

  const getDeclinedTable = () => {
    if (!props.row.declinedArticles?.length) {
      return null;
    }

    const styledHeader = { headerClassName: 'text-14px bg-declined' };

    const columns = [
      {
        field: 'articleNumber',
        headerName: 'Artikel-Nr.',
        width: 100,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'articleType',
        headerName: 'Artikel',
        width: 300,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'partialAcceptStatusString',
        headerName: 'Grund',
        width: 300,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'reason',
        headerName: 'Kommentar',
        width: 300,
        ...columnProps,
        ...styledHeader,
      },
      { field: '', flex: 1, headerName: '', ...columnProps, ...styledHeader }, // empty column needed to fix bug with white tile in header (VGSD-1633)
    ];

    const rows = props.row.declinedArticles?.map((article, index) => {
      return {
        articleNumber: article.number,
        articleType: article.type ?? 'Artikel',
        id: index,
        partialAcceptStatusString:
          article.acceptArticle.partialAcceptStatusToString(),
        reason: article.acceptArticle.reason,
      };
    });

    return (
      <div>
        <span className="bold">Abgelehnte Artikel</span>
        <DataGrid
          columns={columns}
          rows={rows}
          rowHeight={props.rowHeight}
          hideFooter
          autoHeight
          getRowClassName={(params) => `bg-declined`}
        />
      </div>
    );
  };

  const getApprovedTable = () => {
    if (!props.row.approvedArticles?.length) {
      return null;
    }

    const styledHeader = { headerClassName: 'text-14px bg-white' };

    const columns = [
      {
        field: 'articleNumber',
        headerName: 'Artikel-Nr.',
        width: 100,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'articleType',
        headerName: 'Artikel',
        width: 300,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'reason',
        headerName: 'Kommentar',
        width: 600,
        ...columnProps,
        ...styledHeader,
      },
      { field: '', flex: 1, headerName: '', ...columnProps, ...styledHeader }, // empty column needed to fix bug with white tile in header (VGSD-1633)
    ];

    const rows = props.row.approvedArticles?.map((article, index) => {
      return {
        articleNumber: article.number,
        articleType: article.type ?? 'Artikel',
        id: index,
        reason: article.acceptArticle.reason,
      };
    });

    return (
      <div>
        <span className="bold">Angenommene Artikel</span>
        <DataGrid
          columns={columns}
          rows={rows}
          rowHeight={props.rowHeight}
          hideFooter
          autoHeight
        />
      </div>
    );
  };

  const getChangesTable = () => {
    if (props.row.changes.length === 0) {
      return null;
    }

    const columns = [
      { field: 'name', headerName: 'Eigenschaft', width: 290, ...columnProps },
      {
        field: 'oldValue',
        headerName: 'Alter Wert',
        width: 300,
        ...columnProps,
      },
      {
        field: 'newValue',
        headerName: 'Neuer Wert',
        width: 300,
        ...columnProps,
      },
    ];

    const rows = props.row.changes.map((change, index) => {
      const formatter = change.formatter ? change.formatter : (value) => value;

      return {
        id: index,
        name: change.name,
        newValue: ObjectUtils.isEmptyValue(formatter(change.newValue))
          ? '-'
          : formatter(change.newValue),
        oldValue: ObjectUtils.isEmptyValue(formatter(change.oldValue))
          ? '-'
          : formatter(change.oldValue),
      };
    });

    return (
      <div>
        <span className="bold">Änderungen</span>
        <DataGrid
          columns={columns}
          rows={rows}
          rowHeight={props.rowHeight}
          hideFooter
          autoHeight
        />
      </div>
    );
  };

  const getSignerInformation = () => {
    if (!DeliveryNoteAction.isOnBehalfAction(props.row.action)) {
      return null;
    }

    return (
      <div>
        <div className="bold">Bildschirmunterschrift durch</div>
        <div>
          {props.row.signerTradeContactPersonName +
            ' (' +
            props.row.signerCompanyName +
            ')'}
        </div>
      </div>
    );
  };

  const getAttachments = () => {
    if (props.row.attachments.length === 0) {
      return null;
    }

    return (
      <div>
        <span className="bold">Anhänge</span>
        <div className="flex-s-c gap-10px">
          {props.row.attachments.map((attachment) => (
            <Attachment key={attachment.digest} attachment={attachment} />
          ))}
        </div>
      </div>
    );
  };

  if (
    !props.row.declinedArticles?.length &&
    props.row.changes.length === 0 &&
    props.row.attachments.length === 0
  ) {
    return (
      <div className="flex-s-c pl-70px text-14px text-helvetica border-bottom h-full">
        {props.row.action}
      </div>
    );
  }

  return (
    <div className="flex-s-c border-bottom h-full w-full">
      <div className="pl-70px pr-70px text-14px text-helvetica flexdir-column gap-30px flex w-full">
        {getDeclinedTable()}
        {getApprovedTable()}
        {getChangesTable()}
        {getSignerInformation()}
        {getAttachments()}
      </div>
    </div>
  );
}
