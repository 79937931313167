import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

import {
  Archive as ArchiveIcon,
  ArrowForward as ArrowForwardIcon,
  Check as CheckIcon,
  Create as CreateIcon,
  CreateNewFolder as CreateNewFolderIcon,
  DoDisturbAlt as DoDisturbAltIcon,
  DoneAll as DoneAllIcon,
  Share as ShareIcon,
} from '@mui/icons-material';

import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';
import { withErrorBoundary } from '~/ui/atoms';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import DeliveryNoteHistoryDetailPanel from './DeliveryNoteHistoryDetailPanel';
import FeatureService from '~/services/feature.service';
import { LOADING_STATE } from '~/constants/LoadingState';

import { dateUtils, parseDate } from '~/utils/dateUtils';
import ArrayUtils from '~/utils/arrayUtils';
import DatagridUtils from '~/utils/datagridUtils';
import Log from '~/utils/Log';

import { DeclinedIconLight, DeclinedIconLightGrey } from '~/assets/icons';

class DeliveryNoteHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedRows: [],
      hoveredRow: null,
      rows: [],
      sortModel: [
        {
          field: 'datetime',
          sort: 'desc',
        },
      ],
    };

    this.PANEL_ROW_HEIGHT = 35;
  }

  componentDidMount() {
    this.loadRows();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.deliveryNoteActions) !==
      JSON.stringify(prevProps.deliveryNoteActions)
    ) {
      this.loadRows();
    }
  }

  loadRows() {
    const rows = [];
    const expandedRows = [];

    if (this.props.deliveryNoteActions)
      for (const [
        index,
        deliveryNoteAction,
      ] of this.props.deliveryNoteActions.entries()) {
        rows.push({
          acceptState: deliveryNoteAction.acceptState,
          action: deliveryNoteAction.action,
          approvedArticles: deliveryNoteAction.approvedArticles,
          attachments: deliveryNoteAction.attachments,
          changes: deliveryNoteAction.changes,
          company: deliveryNoteAction.company?.name,
          datetime: deliveryNoteAction.datetime,
          declinedArticles: deliveryNoteAction.declinedArticles,
          icon: deliveryNoteAction.icon,
          id: index,
          signerCompanyName: deliveryNoteAction.signerCompany?.name,
          signerTradeContactPersonName:
            deliveryNoteAction.signerTradeContact?.personName,
          user: deliveryNoteAction.user?.getNameAndRole()
            ? deliveryNoteAction.user?.getNameAndRole()
            : '-',
        });

        if (
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_SUPPLIER.STRING &&
            this.props.currentAcceptStateSupplier !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_CARRIER.STRING &&
            this.props.currentAcceptStateCarrier !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_RECIPIENT.STRING &&
            this.props.currentAcceptStateRecipient !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_SUPPLIER.STRING &&
            this.props.currentAcceptStateOnBehalfSupplier !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_CARRIER.STRING &&
            this.props.currentAcceptStateOnBehalfCarrier !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_RECIPIENT.STRING &&
            this.props.currentAcceptStateOnBehalfRecipient !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED)
        ) {
          expandedRows.push(index);
        } else if (
          this.props.showChanges &&
          deliveryNoteAction.changes.length > 0 &&
          !expandedRows.includes(deliveryNoteAction.id)
        ) {
          expandedRows.push(index);
        }
      }

    this.setState({
      expandedRows,
      rows,
    });
  }

  onSortModelChange = (event) => {
    this.setState({
      sortModel: event,
    });
  };
  onRowClick = (rowData) => {
    Log.productAnalyticsEvent('Open history entry', Log.FEATURE.DELIVERY_NOTE);

    let newExpandedRows = this.state.expandedRows;

    if (this.state.expandedRows.includes(rowData.id)) {
      newExpandedRows = ArrayUtils.remove(newExpandedRows, rowData.id);
    } else {
      newExpandedRows.push(rowData.id);
    }

    this.setExpandedRows(newExpandedRows);
  };
  setExpandedRows = (expandedRows) => {
    this.setState({
      expandedRows,
    });
  };
  getDetailPanelHeight = (row) => {
    if (!row) {
      return 0;
    }

    let panelHeight = 80;
    let componentCount = 0; // The number of components, which are displayed in detail panel, influence the total height of the panel.

    if (row.declinedArticles?.length > 0) {
      // 56px for the header height
      panelHeight += 56 + this.PANEL_ROW_HEIGHT * row.declinedArticles?.length;
      componentCount++;
    }

    if (
      row.approvedArticles?.length > 0 &&
      // Only increase panel height for approved articles if declined articles, changes or attachments will be displayed.
      (row.declinedArticles?.length > 0 ||
        row.changes.length > 0 ||
        row.attachments.length > 0)
    ) {
      // 56px for the header height
      panelHeight += 56 + this.PANEL_ROW_HEIGHT * row.approvedArticles?.length;
      componentCount++;
    }

    if (row.changes.length > 0) {
      // 56px for the header height
      panelHeight += 56 + this.PANEL_ROW_HEIGHT * row.changes.length;
      componentCount++;
    }

    if (row.attachments.length > 0) {
      // 150px for the height of the attachment tiles
      panelHeight += 150;
      componentCount++;
    }

    if (DeliveryNoteAction.isOnBehalfAction(row.action)) {
      // 40px for the on behalf party
      panelHeight += 40;
      componentCount++;
    }

    const spacing =
      componentCount > 0 ? componentCount * 20 + (componentCount - 1) * 30 : 0;

    return panelHeight + spacing;
  };
  getColumns = () => {
    const columns = [
      {
        field: DeliveryNoteAction.PROPERTY.ICON.KEY,
        headerName: DeliveryNoteAction.PROPERTY.ICON.STRING,
        renderCell: (params) => {
          return (
            <div className="flex h-full items-center justify-center">
              {this.getIcon(params.value)}
            </div>
          );
        },
        sortable: true,
        width: 60,
      },
      {
        field: DeliveryNoteAction.PROPERTY.DATETIME.KEY,
        headerName: DeliveryNoteAction.PROPERTY.DATETIME.STRING,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
          ),
        sortable: true,
        type: 'date',
        valueGetter: parseDate,
        width: 180,
      },
      {
        field: DeliveryNoteAction.PROPERTY.ACTION.KEY,
        headerName: DeliveryNoteAction.PROPERTY.ACTION.STRING,
        sortable: true,
        width: 430,
      },
    ];

    if (FeatureService.showUserInDlnHistory()) {
      columns.push({
        field: DeliveryNoteAction.PROPERTY.USER.KEY,
        headerName: DeliveryNoteAction.PROPERTY.USER.STRING,
        renderCell: DatagridUtils.displayCellTooltip,
        sortable: true,
        width: 270,
      });
    }

    columns.push({
      field: DeliveryNoteAction.PROPERTY.COMPANY.KEY,
      headerName: DeliveryNoteAction.PROPERTY.COMPANY.STRING,
      renderCell: DatagridUtils.displayCellTooltip,
      sortable: true,
      width: 400,
    });

    return columns;
  };
  getIcon = (icon) => {
    switch (icon) {
      case DeliveryNoteAction.ACTION.CREATED.ICON: {
        return <CreateNewFolderIcon />;
      }

      case DeliveryNoteAction.ACTION.EDITED.ICON: {
        return <CreateIcon />;
      }

      case DeliveryNoteAction.ACTION.CONFIRMED_SUPPLIER.ICON:
      case DeliveryNoteAction.ACTION.CONFIRMED_CARRIER.ICON: {
        return <CheckIcon />;
      }

      case DeliveryNoteAction.ACTION.CONFIRMED_RECIPIENT.ICON: {
        return <DoneAllIcon />;
      }

      case DeliveryNoteAction.ACTION.DECLINED_SUPPLIER.ICON: {
        return this.props.currentAcceptStateSupplier ===
          AcceptStateCalculator.ACCEPT_STATE.APPROVED ? (
          <DeclinedIconLightGrey className="h-20px" />
        ) : (
          <DeclinedIconLight className="h-20px" />
        );
      }

      case DeliveryNoteAction.ACTION.DECLINED_CARRIER.ICON: {
        return this.props.currentAcceptStateCarrier ===
          AcceptStateCalculator.ACCEPT_STATE.APPROVED ? (
          <DeclinedIconLightGrey className="h-20px" />
        ) : (
          <DeclinedIconLight className="h-20px" />
        );
      }

      case DeliveryNoteAction.ACTION.DECLINED_RECIPIENT.ICON: {
        return this.props.currentAcceptStateRecipient ===
          AcceptStateCalculator.ACCEPT_STATE.APPROVED ? (
          <DeclinedIconLightGrey className="h-20px" />
        ) : (
          <DeclinedIconLight className="h-20px" />
        );
      }

      case DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_SUPPLIER.ICON: {
        return this.props.currentAcceptStateOnBehalfSupplier ===
          AcceptStateCalculator.ACCEPT_STATE.APPROVED ? (
          <DeclinedIconLightGrey className="h-20px" />
        ) : (
          <DeclinedIconLight className="h-20px" />
        );
      }

      case DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_CARRIER.ICON: {
        return this.props.currentAcceptStateOnBehalfCarrier ===
          AcceptStateCalculator.ACCEPT_STATE.APPROVED ? (
          <DeclinedIconLightGrey className="h-20px" />
        ) : (
          <DeclinedIconLight className="h-20px" />
        );
      }

      case DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_RECIPIENT.ICON: {
        return this.props.currentAcceptStateOnBehalfRecipient ===
          AcceptStateCalculator.ACCEPT_STATE.APPROVED ? (
          <DeclinedIconLightGrey className="h-20px" />
        ) : (
          <DeclinedIconLight className="h-20px" />
        );
      }

      case DeliveryNoteAction.ACTION.ARRIVED.ICON: {
        return <ArrowForwardIcon />;
      }

      case DeliveryNoteAction.ACTION.CANCELLED.ICON: {
        return <DoDisturbAltIcon />;
      }

      case DeliveryNoteAction.ACTION.ARCHIVED.ICON:
      case DeliveryNoteAction.ACTION.UNARCHIVED.ICON: {
        return <ArchiveIcon />;
      }

      case DeliveryNoteAction.ACTION.SHARED.ICON: {
        return <ShareIcon />;
      }

      default: {
        return <CreateIcon />;
      }
    }
  };
  handleMouseEnter = (event) => {
    this.setState({
      hoveredRow: event.currentTarget.dataset.id,
    });
  };
  handleMouseLeave = () => {
    this.setState({
      hoveredRow: null,
    });
  };

  render() {
    return (
      <div className="w-full">
        <DataGridPro
          rows={this.state.rows}
          columns={this.getColumns()}
          disableRowSelectionOnClick
          autoHeight
          onSortModelChange={this.onSortModelChange}
          sortModel={this.state.sortModel}
          loading={this.props.loading === LOADING_STATE.LOADING}
          getDetailPanelContent={(rowData) => (
            <DeliveryNoteHistoryDetailPanel
              row={rowData.row}
              rowHeight={this.PANEL_ROW_HEIGHT}
            />
          )}
          getDetailPanelHeight={(rowData) =>
            this.getDetailPanelHeight(rowData.row)
          }
          detailPanelExpandedRowIds={this.state.expandedRows}
          onRowClick={this.onRowClick}
          onDetailPanelExpandedRowIdsChange={this.setExpandedRows}
          initialState={{
            columns: {
              columnVisibilityModel: { __detail_panel_toggle__: false },
            },
          }}
          // Removed style: {cursor: 'pointer'} because it leads to a bug with the expandable panels.
          slotProps={{
            row: {
              onMouseEnter: this.handleMouseEnter,
              onMouseLeave: this.handleMouseLeave,
            },
          }}
          slots={{
            noRowsOverlay: () => (
              <div className="flex-c-c h-full w-full">
                {this.props.loading === LOADING_STATE.FAILED
                  ? 'Lieferungshistorie und -änderungen konnten nicht geladen werden.'
                  : 'Keine Einträge'}
              </div>
            ),
          }}
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  DeliveryNoteHistory,
  'Lieferungshistorie konnte nicht geladen werden.',
);
