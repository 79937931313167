import snakeCaseKeys from 'snakecase-keys';

import MasterDataService from '~/services/masterData.service';
import SiteService from '~/services/site.service';
import ToastService from '~/services/toast.service';

import Coordinates from '~/models/masterdata/Coordinates';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';

export const handleSubmit = async ({
  grantPermissions,
  grantPermissionsOnCostCenters,
  permissionGrantRef,
  refetchData,
  resetWizard,
  setIsOpen,
  setIsSubmitting,
  site: { address, companyId, coords, costCenters, isActive, name, type },
}) => {
  setIsSubmitting(true);

  const body = {
    address,
    companyId,
    coords: Coordinates.getConvertedCoordinates(
      coords.latitude,
      coords.longitude,
    ),
    isActive,
    name,
    type,
  };

  if (
    MasterDataService.propertiesAreMissing(
      body,
      ['companyId'],
      Log.FEATURE.WIZARD,
    )
  ) {
    setIsSubmitting(false);
    return false;
  }

  Log.info('Submit create site wizard', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
  Log.productAnalyticsEvent('Submit create site wizard', Log.FEATURE.WIZARD);

  const [siteId, error] = await promiseHandler(
    SiteService.createSite(snakeCaseKeys(body, { deep: true })),
  );

  if (error) {
    Log.error('Failed to create site.', error);
    Log.productAnalyticsEvent(
      'Failed to create site',
      Log.FEATURE.WIZARD,
      Log.TYPE.ERROR,
    );

    setIsSubmitting(false);
    return false;
  }

  if (costCenters.length > 0) {
    const [, error4] = await promiseHandler(
      SiteService.updateAccountingReferencesOfSite(siteId, costCenters),
    );

    if (error4) {
      ToastService.httpError(
        [ToastService.MESSAGE.ACCOUNTING_REFERENCE_ASSIGNMENT_FAILED],
        error4.response,
      );
      Log.productAnalyticsEvent(
        'Failed to update assigned cost centers',
        Log.FEATURE.SITE,
        Log.TYPE.ERROR,
      );
    }
  }

  if (grantPermissions && UserUtils.isPermissionGrantAllowedUser()) {
    const [, error5] = await promiseHandler(
      permissionGrantRef.current.submit(siteId),
    );

    if (error5) {
      ToastService.error(
        'Berechtigungen konnten nicht vollständig vergeben werden.',
      );

      Log.error('Failed to grant permissions.', error5);
      Log.productAnalyticsEvent(
        'Failed to grant permissions',
        Log.FEATURE.WIZARD,
        Log.TYPE.ERROR,
      );

      setIsSubmitting(false);
      return false;
    }

    if (grantPermissionsOnCostCenters) {
      const [, error6] = await promiseHandler(
        permissionGrantRef.current.submitCostCenters(costCenters),
      );

      if (error6) {
        ToastService.error(
          'Berechtigungen konnten nicht vollständig vergeben werden.',
        );

        Log.error('Failed to grant permissions.', error6);
        Log.productAnalyticsEvent(
          'Failed to grant permissions',
          Log.FEATURE.WIZARD,
          Log.TYPE.ERROR,
        );

        setIsSubmitting(false);
        return false;
      }
    }
  }

  ToastService.success('Standort wurde angelegt.');

  setIsOpen(false);
  setIsSubmitting(false);

  resetWizard();
  refetchData();

  return true;
};
