import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysUserGroup } from './queryKeys';

export type UserGroupData = {
  name: string;
  users?: UUID[];
  memberUserGroups?: UUID[];
  parentUserGroups?: UUID[];
  orgUnits: UUID[];
  orgUnitPaths?: UUID[][];
  userGroupPaths?: UUID[][];
};

type UserGroupCreateResponse = {
  code: number;
  message: string;
  id: UUID;
};

/**
 * Creates a new user group.
 *
 * @param {Object} userGroupData - An object containing the user group data to create.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/create_user_group_access_control_user_group_post
 */
export const createUserGroup = async (userGroupData: UserGroupData) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.USER_GROUP.CREATE(), {
        json: userGroupData,
      })
      .json<UserGroupCreateResponse>();

    return response;
  } catch (error) {
    Log.error('Error creating user group', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for creating a new user group.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationCreateUserGroup = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userGroupData: UserGroupData) =>
      createUserGroup(userGroupData),
    async onError(error) {
      Log.productAnalyticsEvent(
        'Failed to create user group',
        Log.FEATURE.USER_GROUP,
        Log.TYPE.ERROR,
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeysUserGroup.getAll({}),
      });
    },
    ...options,
  });
};
