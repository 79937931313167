import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysCategory } from './queryKeys';

type CategoryData = Record<string, unknown>;

type MutationCategoryArgument = {
  categoryId?: UUID;
  categoryData: CategoryData;
};

/**
 * Creates a new category.
 *
 * @param {CreateCostCenterPayload} categoryData - The data for creating the category
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/create_category_article_master_category_post
 */
export const createCategory = async (
  categoryData: MutationCategoryArgument,
) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.CATEGORY.CREATE(), {
        json: categoryData,
      })
      .json();

    return response;
  } catch (error) {
    Log.error('Error updating create settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for creating an category.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationCreateCategory = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (categoryData: MutationCategoryArgument) =>
      createCategory(categoryData),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeysCategory.getAll({}),
      });

      Log.productAnalyticsEvent(
        'Create category success',
        Log.FEATURE.COST_CENTER,
      );
    },
    ...options,
  });
};
