import Article from '~/models/articleMaster/Article';

export const selectArticles = (data, companies, sites) => ({
  articles: data.items.map((article) => new Article(article)) ?? [],
  rows:
    data.items.map((article) => ({
      ...article,
      access: Article.getAccessString(article.access),
      equivalentArticles: article.equivalentArticles
        .map((equivalentArticleId) => {
          const article = data.items.find(
            (article) => article.id === equivalentArticleId,
          );
          if (!article) {
            return '';
          }

          return article.articleId + ' ' + article.name;
        })
        .join(', '),
      filterCompanies: article.filterCompanies
        .map(
          (filterCompanyId) =>
            companies.find((company) => company.id === filterCompanyId)?.name ??
            '',
        )
        .join(', '),
      filterSites: article.filterSites
        .map(
          (filterSiteId) =>
            sites.find((site) => site.id === filterSiteId)?.name ?? '',
        )
        .join(', '),
      isActive: article.isActive ? Article.IS_ACTIVE.YES : Article.IS_ACTIVE.NO,
      ownerCompanies: article.ownerCompanies
        .map(
          (ownerCompanyId) =>
            companies.find((company) => company.id === ownerCompanyId)?.name ??
            '',
        )
        .join(', '),
      virtual: article.virtual ? Article.IS_ACTIVE.YES : Article.IS_ACTIVE.NO,
    })) ?? [],
  totalCount: data.count ?? 0,
});
