import { queryKeysCompany } from '~/data/company';
import { queryKeysCostCenter } from '~/data/costCenter';
import { queryKeysSite } from '~/data/site';
import { queryKeysUser } from '~/data/user';
import { queryKeysUserGroup } from '~/data/userGroup';
import { queryKeysVehicle } from '~/data/vehicle';

import ToastService from '~/services/toast.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

export const handleSubmit = async ({
  closeForm,
  createOrganizationalUnitMutation,
  isCreatingOrganizationalUnit,
  // onRefreshEntities,
  organizationalUnit,
  originalData,
  queryClient,
  refetchOrganizationalUnit,
  refetchOrganizationalUnits,
  resetForm,
  updateOrganizationalUnitMutation,
  updateOrganizationalUnitsMutation,
}) => {
  if (
    !organizationalUnit?.isHighestOrganisationalGroup?.() &&
    organizationalUnit?.parentOrganisationalGroups?.length === 0
  ) {
    ToastService.warning([
      'Bitte wähle mindestens eine "Mutter"-Organisations-Gruppe aus, zu der diese Organisations-Gruppe gehört.',
    ]);

    Log.productAnalyticsEvent(
      'Missing parent organisational group',
      Log.FEATURE.ORGANISATIONAL_GROUP,
      Log.TYPE.FAILED_VALIDATION,
    );

    return;
  }

  const {
    childOrganisationalGroups,
    companies,
    costCenters,
    name,
    parentOrganisationalGroups,
    sites,
    userGroups,
    users,
    vehicles,
  } = organizationalUnit;

  const body = {
    accountingReferences: costCenters,
    childOus: childOrganisationalGroups,
    companies,
    name,
    parentOus: parentOrganisationalGroups,
    sites,
    userGroups,
    users,
    vehicles,
  };

  Log.info(
    'Submit organisational group form',
    body,
    Log.BREADCRUMB.FORM_SUBMIT.KEY,
  );
  Log.productAnalyticsEvent('Submit form', Log.FEATURE.ORGANISATIONAL_GROUP);

  let organizationalUnitId = originalData?.id;
  if (isCreatingOrganizationalUnit) {
    try {
      const { id } = await createOrganizationalUnitMutation(body);
      organizationalUnitId = id;
    } catch (error) {
      ToastService.httpError(
        ['Organisations-Gruppe konnte nicht angelegt werden.'],
        error.response,
      );

      Log.error('Failed to create organisational group.', error);
      Log.productAnalyticsEvent(
        'Failed to create',
        Log.FEATURE.ORGANISATIONAL_GROUP,
        Log.TYPE.ERROR,
      );

      return;
    }
  } else {
    try {
      await updateOrganizationalUnitMutation({
        organizationalUnitData: {
          name: body.name,
        },
        organizationalUnitId,
      });

      // Update related entities
      const entityTypes = [
        {
          key: PermissionGrant.ENTITY_TYPE.USER.KEY,
          stateKey: 'users',
        },
        {
          key: PermissionGrant.ENTITY_TYPE.SITE.KEY,
          stateKey: 'sites',
        },
        {
          key: PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
          stateKey: 'costCenters',
        },
        {
          key: PermissionGrant.ENTITY_TYPE.VEHICLE.KEY,
          stateKey: 'vehicles',
        },
        {
          key: PermissionGrant.ENTITY_TYPE.COMPANY.KEY,
          stateKey: 'companies',
        },
        {
          key: PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY,
          stateKey: 'userGroups',
        },
        {
          key: PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY,
          stateKey: 'parentOrganisationalGroups',
        },
      ];

      const updatePromises = entityTypes.map(({ key, stateKey }) => {
        const [deletedOrganizationalUnits, addedOrganizationalUnits] =
          ArrayUtils.getDifference(
            originalData[stateKey],
            organizationalUnit[stateKey],
          );

        return updateOrganizationalUnitsMutation({
          addedMembers: addedOrganizationalUnits,
          deletedMembers: deletedOrganizationalUnits,
          entityId: organizationalUnitId,
          entityType: key,
          updateType: 'updateOrganizationalUnitEntities',
        });
      });

      // Update child organizational units
      const [deletedOrganizationalUnits, addedOrganizationalUnits] =
        ArrayUtils.getDifference(
          originalData.childOrganisationalGroups,
          organizationalUnit.childOrganisationalGroups,
        );

      updatePromises.push(
        updateOrganizationalUnitsMutation({
          addedMembers: addedOrganizationalUnits,
          deletedMembers: deletedOrganizationalUnits,
          entityId: organizationalUnitId,
          entityType: PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY,
          updateType: 'updateParentOrganizationalUnits',
        }),
      );

      await Promise.all(updatePromises);
    } catch (error) {
      ToastService.httpError(
        [
          'Organisations-Gruppen konnten nicht vollständig aktualisiert werden.',
        ],
        error.response,
      );

      Log.productAnalyticsEvent(
        'Failed to update',
        Log.FEATURE.ORGANISATIONAL_GROUP,
        Log.TYPE.ERROR,
      );
      Log.error('Failed to update organisational group.', error);

      return;
    }
  }

  closeForm();
  resetForm();

  refetchOrganizationalUnit();
  refetchOrganizationalUnits();

  // Refetch MultiItemsManager queries.
  queryClient.invalidateQueries({
    queryKey: queryKeysCompany.getAll(),
    refetchType: 'all',
  });
  queryClient.invalidateQueries({
    queryKey: queryKeysCostCenter.getAll(),
    refetchType: 'all',
  });
  queryClient.invalidateQueries({
    queryKey: queryKeysSite.getAll(),
    refetchType: 'all',
  });
  queryClient.invalidateQueries({
    queryKey: queryKeysUser.getAll(),
    refetchType: 'all',
  });
  queryClient.invalidateQueries({
    queryKey: queryKeysUserGroup.getAll(),
    refetchType: 'all',
  });
  queryClient.invalidateQueries({
    queryKey: queryKeysVehicle.getAll(),
    refetchType: 'all',
  });

  // onRefreshEntities(); // TODO: is this actually required? It would fire a million requests.
};
