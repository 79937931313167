import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';
import {
  PAGINATION_PAGE_SIZE_DEFAULT,
  type PaginationSortDirection,
} from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import Log from '~/utils/Log';
import { toSnakeCase } from '~/utils/string';

import { queryKeysArticle } from './queryKeys';

export type ArticlesQueryParams = {
  limit: number;
  offset: number;
  isActive?: boolean;
  searchText?: string;
  filterCategories?: string[];
  filterCompanies?: string[];
  filterAccRefs?: string[];
  filterSites?: string[];
  filterOwnerCompAccs: string;
  filterOwnerComps: string;
  ownerFilterUnion: boolean;
  orderBy:
    | 'articleId'
    | 'name'
    | 'description'
    | 'ean'
    | 'isActive'
    | 'virtual'
    | 'equivalentArticles'
    | 'access'
    | 'ownerCompanies'
    | 'filterCompanies'
    | 'filterSites'
    | 'filterCostCenters'
    | 'id';
  sort: PaginationSortDirection;
};

export type ArticleListItem = {
  name: string;
  articleId: string;
  description: string;
  ean: string;
  additionalData: Record<string, unknown>;
  handling: Record<string, unknown>;
  product: {
    id: number;
    name: string;
    sellerAssignedId: string;
  };
  access: 'public' | 'private';
  isActive: boolean;
  isVirtual: boolean;
  ownerCompanies: string[];
  ownerCompanyAccounts: string[];
  linkedCategories: string[];
  filterCompanies: string[];
  filterSites: string[];
  filterAccRefs: string[];
  accessCompanies: string[];
  equivalentArticles: string[];
  id: string;
};

type ArticlesResponse = {
  items: readonly ArticleListItem[];
  count: number;
};

const defaultQueryParams: ArticlesQueryParams = {
  filterOwnerCompAccs: undefined,
  filterOwnerComps: undefined,
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  orderBy: 'name',
  ownerFilterUnion: true,
  searchText: undefined,
  sort: 'ASC',
};

/**
 * Generates the query options for the companies query so they can be shared between the useQuery hook and the prefetching.
 */
export const getArticlesQueryOptions = ({
  queryParams,
  options = {},
}: {
  queryParams: Partial<ArticlesQueryParams>;
  options?: Omit<
    UseQueryOptions<ArticlesResponse, Error>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryFn: async () => fetchArticles(qp),
    queryKey: queryKeysArticle.getAll(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all companies from the API.
 * @param queryParams - The query parameters for fetching companies.
 * @returns The company data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Article-Master/operation/get_articles_article_master_article_get
 */
export const fetchArticles = async (
  queryParams: Partial<ArticlesQueryParams>,
): Promise<ArticlesResponse> => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.orderBy = toSnakeCase(qp.orderBy);
  qp.sort = qp.sort?.toUpperCase() as PaginationSortDirection;

  for (const key of Object.keys(qp)) {
    if (qp[key as keyof ArticlesQueryParams] === undefined) {
      delete qp[key as keyof ArticlesQueryParams];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.ARTICLE.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<ArticlesResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching articles', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all articles with given query parameters.
 * Handles pagination and prefetches the next page of articles for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching articles.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryArticles = (
  queryParams: Partial<ArticlesQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getArticlesQueryOptions({
        options,
        queryParams: {
          ...queryParams,
          offset:
            (queryParams.offset ?? 0) +
            (queryParams.limit ?? PAGINATION_PAGE_SIZE_DEFAULT),
        },
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery<ArticlesResponse, Error>({
    ...getArticlesQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all companies with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching companies.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQueryCompaniesInfinite = (
  queryParams: Partial<ArticlesQueryParams>,
  options: InfiniteQueryOptionsType<ArticlesResponse, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async ({ pageParam: pageParameter = 0 }) =>
      fetchArticles({
        ...qp,
        offset: pageParameter,
      }),
    queryKey: queryKeysArticle.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<ArticlesResponse>(qp, infiniteQueryOptions);
};
