import clsx from 'clsx';
import { type PropsWithChildren } from 'react';

type ColumnProps = PropsWithChildren & ComponentStyling;

export const Column = ({ children, className, style }: ColumnProps) => (
  <div
    className={clsx('flex flex-col transition-all', className)}
    style={style}
  >
    {children}
  </div>
);

type ColumnHeaderProps = PropsWithChildren & ComponentStyling;

export const ColumnHeader = ({
  children,
  className,
  style,
}: ColumnHeaderProps) => (
  <h3
    className={clsx('border-b p-2 text-center text-gray-500', className)}
    style={style}
  >
    {children}
  </h3>
);
