import { useMutation } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import ToastService from '~/services/toast.service';
import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';

type DeleteFromOrganizationalUnitParams = {
  entityId: UUID;
  entityType: EntityType;
  organizationalUnitId: UUID;
};

/**
 * Deletes an entity from an organizational unit.
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/delete_entity_from_org_unit_access_control_org_unit__org_unit_id___entity_type___entity_id__delete
 * @param organizationalUnitId - UUID of the organizational unit to remove the entity from
 * @param entityId - UUID of the entity being removed
 * @param entityType - Type of entity being removed
 * @returns Promise containing the API response
 * @throws Will throw an error if the API call fails
 */
export const deleteFromOrganizationalUnit = async (
  organizationalUnitId: UUID,
  entityType: EntityType,
  entityId: UUID,
) => {
  try {
    const response = await vestigasApi
      .delete(
        ENDPOINT.ORGANIZATIONAL_UNIT.DELETE_FROM(
          organizationalUnitId,
          entityType,
          entityId,
        ),
      )
      .json();

    return response;
  } catch (error) {
    Log.error('Error deleting entity from organizational unit', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query mutation hook for removing an entity from an organizational unit.
 * @param options - Optional React Query mutation options
 * @returns Mutation object containing mutation function and state
 */
export const useMutationDeleteFromOrganizationalUnit = (
  options?: Parameters<typeof useMutation>[0],
) =>
  useMutation({
    mutationFn: async ({
      entityId,
      entityType,
      organizationalUnitId,
    }: DeleteFromOrganizationalUnitParams) =>
      deleteFromOrganizationalUnit(organizationalUnitId, entityType, entityId),
    onError(error: unknown) {
      Log.productAnalyticsEvent(
        'Failed to delete entity from organizational unit',
        Log.FEATURE.ORGANIZATIONAL_UNIT,
        Log.TYPE.ERROR,
      );

      ToastService.httpError(['Failed to delete organizational unit'], error);
    },
    ...options,
  });
