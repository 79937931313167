import React, { memo, useCallback, useState } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { useQueryCostCenters } from '~/data/costCenter';

import LocalStorageService from '~/services/localStorage.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns, columnVisibility } from './constants';
import { selectCostCenters } from './selectCostCenters';

export const CostCentersTable = withErrorBoundary(
  memo(() => {
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
    });
    const [sortModel, setSortModel] = useState([
      {
        field: 'name',
        sort: 'asc',
      },
    ]);

    const [filterActive, setFilterActive] = useState(true);

    const [searchString, setSearchString] = useState(undefined);

    const { data, isFetching, isPending, isPlaceholderData, refetch } =
      useQueryCostCenters(
        {
          limit: paginationModel.pageSize,
          offset: paginationModel.page * paginationModel.pageSize,
          orderBy: sortModel[0]?.field,
          searchString,
          sort: sortModel[0]?.sort,
          ...(filterActive !== '' && { filterActive }),
        },
        {
          select: selectCostCenters,
        },
      );

    const handleSetActiveFilter = useCallback((filterActive) => {
      setFilterActive(filterActive);
    }, []);

    const handlePaginationModelChange = useCallback((newModel) => {
      setPaginationModel(newModel);
    }, []);

    const handleSortModelChange = useCallback((newModel) => {
      setSortModel(newModel);
    }, []);

    const handleSearchStringChange = useCallback((newSearchString) => {
      // Show results from start when search string changes.
      setPaginationModel({
        ...paginationModel,
        page: 0,
      });
      setSearchString(newSearchString);
    }, []);

    const filterFunction = useCallback((string) => string, []); // We filter server side -> prevent the DataGrid from filtering.

    return (
      <SettingsTable
        key="CostCentersTable"
        columns={columns}
        columnVisibilityModel={columnVisibility}
        entity="costCenter"
        filterActive={filterActive}
        filterFunction={filterFunction}
        hasActiveStateFilter
        hasMultiPermissionGrantEditing
        isLoading={isPending || (isFetching && isPlaceholderData)}
        items={data?.costCenters ?? []}
        localStorageKey={LocalStorageService.COST_CENTER_TABLE}
        multiPermissionGrantDefaultEntityType={
          PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY
        }
        multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
        onPaginationModelChange={handlePaginationModelChange}
        onSearchStringChange={handleSearchStringChange}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        refreshData={refetch}
        rows={data?.rows ?? []}
        setActiveFilter={handleSetActiveFilter}
        sortBy="name"
        sortModel={sortModel}
        title="Kostenstellen"
        totalRowCount={data?.totalCount ?? 0}
      />
    );
  }),
  'Kostenstellen konnten nicht geladen werden.',
);
